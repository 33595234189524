angular.module('financial_entry', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('financial_entry').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('financial_entry', {
        url         : "/financial",
        templateUrl : server+"/views/m/financial_entry",
        controller  : 'financial_entry.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Financeiro'
        }
    })

    .state('financial_report', {
        url         : "/financial/report/:type",
        templateUrl : server+"/views/m/financial_entry/report_types",
        controller  : 'report',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório Financeiro'
        }
    })

    .state('financial_dashboard', {
        url         : "/financial/dashboard",
        templateUrl : server+"/views/m/financial_entry/dashboard",
        controller  : 'dashboard',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório Financeiro'
        }
    })

    .state('financial_report_group', {
        url         : "/financial/report_group",
        templateUrl : server+"/views/m/financial_entry/report_group",
        controller  : 'report.group',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório Financeiro'
        }
    })

    .state('make_boleto', {
        url         : "/financial/boleto",
        params: {
            contract_id: null,
        },
        templateUrl : server+"/views/m/financial_entry/make_boleto",
        controller  : 'boleto',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Gerar Boletos'
        }
    })

}]);

/**
* List Controller
*/
angular.module('financial_entry')
.controller('financial_entry.list', ['$scope', '$state', '$localStorage', '$http', '$auth', '$q', '$uibModal', '$financial_entry', '$financial_account', '$financial_category', '$client', '$users', '_', 'ngToast', '$rootScope', '$company', '$invoice',
function($scope, $state, $localStorage, $http, $auth, $q, $uibModal, $financial_entry, $financial_account, $financial_category, $client, $users, _, ngToast, $rootScope, $company, $invoice) {

    $scope.today                = new moment();
    $scope.real_today           = angular.copy($scope.today);
    $scope.dates                = [];
    $scope.showEditing          = null;
    $scope.newEntry             = false;
    $scope.loadingEntries       = true;
    $scope.waitingNewFinancial  = false;
    $scope.hasAccount           = false;
    $scope.current_type         = 'D';
    $scope.current_account      = {
        name : 'Todas',
        id : null
    };
    $scope.contacts             = [];
    $scope.current_tab          = 1;
    $scope.lates                = {};
    $scope.filter               = {
        period  : 'month',
        status  : 'all',
        day     : 0
    };
    $scope.arrayValues          = [];
    $scope.totals               = {};
    $scope.financial_entry      = [];
    $scope.toEdit               = {
        description: ''
    }
    if($localStorage.company){
        $scope.collapsed  = $localStorage.company.financial_basic;
        $scope.company    = $localStorage.company;
    } else {
        $company.get({id:$localStorage.user.companies[0].id}).$promise.then(function(response){
            $localStorage.company = response;
            $scope.company = response;
        });
    }

    $scope.financial_entry  = {
        credits : {
            sum : 0,
            sum_paids : 0
        },
        debits : {
            sum : 0,
            sum_paids : 0
        },
        total_paid : 0
    };

    $scope.entry_transacted_at = {
        opened: false
    };

    $scope.today_opened = {
        opened: false
    };

    $scope.dropdownTranslation = {
        buttonDefaultText: 'Selecione',
        checkAll: 'Todas',
        uncheckAll: 'Nenhuma',
        selectionCount: 'selecionada(s)',
        selectionOf: '/',
        searchPlaceholder: 'Pesquisar',
        dynamicButtonTextSuffix: 'selecionada(s)'
    };

    $scope.dropdownAccountConfig = {
        buttonClasses: 'btn btn-default btn-text',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1,
    }

    $scope.switchPainel = function(){
        $state.go('digital_account');
    }

    $scope.reloadEntry = function( current = null){
        if( current )
            $scope.current_tab = current;
            
        $scope.entry            = {
            account: $scope.current_account.id,
            contact: {},
            category: {},
            transacted_at: $scope.today.toDate(),
            installments: {
                data: [],
                installments: 1,
                frequence : "month"
            },
            paid : false,
            payment : 'in_cash'
        };
        $scope.payment = "à vista";
        $scope.newEntry = false;
        _.each($scope.form_entry, function(form){
            if( form )
                form.$submitted = true;
        });
        if(!$scope.entry.contact.id){
            $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
        }
        if(!$scope.entry.category.id){
            $scope.$broadcast('angucomplete-alt:clearInput', 'inputCategory');
        }

        if( $scope.current_tab !== 99 ) {
            $scope.current_type = _.where($scope.financial_types, {id : $scope.current_tab})[0].type;
        }

        $scope.$broadcast('angucomplete-alt:clearInput', 'inputContactCollapsed');
        $scope.$broadcast('angucomplete-alt:clearInput', 'inputCategoryCollapsed');

        $scope.createDaysOfMonth();

    }

    $scope.editEntry = function(entry){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'show_entry.modal.html',
            controller     : 'financial_entry.show',
            size           : 'lg',
            resolve        : {
                entry: function(){
                    return angular.copy(entry)
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadEntries();
        });
    }

    $scope.deleteEntry = function(entry){
        console.log(entry);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'delete_entry.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', '$q', function($scope, $uibModalInstance, $financial_entry, _, $q) {

                $scope.title = 'Excluir registro?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Excluir';
                $scope.action           = 'excluir';
                $scope.exclusionOptions = false;
                $scope.exclusion = {
                    type : 'one'
                };
                $scope.entry = entry;

                if( $scope.entry.payment == 'recurrent' || $scope.entry.payment == 'installments'){
                    $scope.title = "Esta transação se repete"
                    $scope.exclusionOptions = true;
                }

                $scope.confirm = function() {
                    $financial_entry.deleteEntry($scope.entry.id,{
                        delete_type: $scope.exclusion.type,
                        transacted_at: $scope.entry.transacted_at
                    }).then(function(response){
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
        modalInstance.result.then(function(entry) {
            $scope.loadEntries();
        });
    }

    $scope.showEntries = function(entries, type){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'entries.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', function($scope, $uibModalInstance, $financial_entry, _) {

                $scope.title        = 'Transações atrasadas';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Ok';
                $scope.type         = type;
                $scope.entries      = entries;

                $scope.att = function() {
                    var count = _.where($scope.entries, { paid: 0});
                    if( count.length == 0 ) {
                        $uibModalInstance.close();
                    }
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                };

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
        modalInstance.result.then(function() {
            $scope.loadEntries();
        },function(error) {
            $scope.loadEntries();
        });
    }

    $scope.createReceipt = function(entry){
        //Show create create Receipt modal
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'createReceipt.modal.html',
            size: 'lg',
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', '$receiptType',
            function($scope, $uibModalInstance, $financial_entry, _, $receiptType) {

                $scope.canPrint = false;

                $scope.entry = {};

                $scope.$watch('entry.type', function(type){
                    if(type != undefined)
                    {
                        // Gerar valores
                        var data = serialize({
                            from: 'financial_entry',
                            from_id: entry.id
                        });
                        $scope.entry.url = api+'receipt_type/'+type+'/generate?'+data;

                        $http.get($scope.entry.url).then(function(response){
                            $scope.canPrint = true;
                            setTimeout(function () {
                                $("#printIframe").contents().find('html').html(response.data);
                            }, 10);
                        });
                    }
                });

                $scope.print = function(){
                    window.frames["printIframe"].focus();
                    window.frames["printIframe"].print();
                }

                $receiptType.query({count:300}).$promise.then(function(response){
                    $scope.types = response.data;
                });

                $scope.confirm = function() {
                    $uibModalInstance.close();
                };

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });

    }

    $scope.$on('boleto:created', function(e){
        $scope.loadEntries();
    });

    $scope.loadEntries = function(){
        $scope.loadingEntries = true;
        var queue = [];
        if( !$scope.collapsed ){
            queue.push($financial_entry.query({financial_account_id:$scope.current_account.id, date: $scope.today.toISOString(), hasTransfer: true }).$promise.then(function(response){
                $scope.financial_entry = response.data;
            }));
        } else {
            queue.push($financial_entry.query({financial_account_id:$scope.current_account.id, date: $scope.today.toISOString(), type: 'D', hasTransfer: true }).$promise.then(function(response){
                $scope.totals.current_debits_paids = response.total_debit_paid;
                $scope.totals.current_debits_total = response.total_debit;
                $scope.financial_entry[$scope.idDebit] = response.data;
                $scope.showEditing  = null;
            }));
            queue.push($financial_entry.query({financial_account_id:$scope.current_account.id, date: $scope.today.toISOString(), type: 'C', hasTransfer: true }).$promise.then(function(response){
                $scope.totals.current_credits_paids = response.total_credit_paid;
                $scope.totals.current_credits_total = response.total_credit;
                $scope.financial_entry[$scope.idCredit] = response.data;
                $scope.showEditing  = null;
            }));
        }

        setTimeout(function(){
            $q.all( queue ).then(function(response){
                $scope.totals.total     = $scope.totals.current_credits_total - $scope.totals.current_debits_total;
                $scope.loadingEntries   = false;
                $scope.createDaysOfMonth();
            });
        },300);

        $scope.loadTransfers();
    }

    $scope.late = function(date){
        if( moment().isAfter(date, 'day') )
            return true;

        return false;
    }

    $scope.changeAccount = function(account){
        $scope.waiting = true;
        $scope.hasAccount = true;
        $scope.loadingEntries = true;
        if( account ){
            $financial_account.query({id: account.id, date: $scope.today.toISOString()}).$promise.then(function(response){
                $scope.current_account = response;
                $scope.waiting = false;
                $scope.loadEntries();
            }, function(error){
                $scope.waiting = false;
            });
        } else {
            $scope.waiting = false;
            $scope.current_account = {
                name : 'Todas',
                id : null
            };
            $scope.loadEntries();
        }

    };

    //Carregas tipos
    $financial_entry.getTypes().then(function(response){
        $scope.financial_types  = response.data;
        if( response.data.length > 0 )
            $scope.current_tab      = response.data[0].id;

        $scope.idCredit    = _.where($scope.financial_types, {type : 'C'})[0].id;
        $scope.idDebit     = _.where($scope.financial_types, {type : 'D'})[0].id;

        if( $scope.collapsed ){
            $scope.financial_types = [
                {
                    'id'    : $scope.idDebit,
                    'name'  : 'Despesas',
                    'type'  : 'D'
                },
                {
                    'id'    : $scope.idCredit,
                    'name'  : 'Receitas',
                    'type'  : 'C'
                }
            ];
            $scope.current_tab      = $scope.idDebit;
        }
    });

    //Carregar contas
    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
        $scope.loadEntries();
        $scope.reloadEntry();
    });

    $scope.loadCategories = function(){
        $financial_category.query({type: $scope.current_type, count: 1000}).$promise.then(function(response){
            $scope.categories = response.data;
        });
    }

    $client.query({count: 10000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.selectedMonth = function(int){
        $scope.today.month($scope.today.month()+parseInt(int));
        if ( $scope.hasAccount ) {
            $scope.loadingEntries = true;
            $financial_account.query({id: $scope.current_account.id, date: $scope.today.toISOString()}).$promise.then(function(response){
                $scope.current_account = response;
                $scope.loadEntries();
                $scope.loadingEntries = false;
            });
        }else{
            $scope.loadEntries();
        }
    }

    $scope.addNewEntry = function(){
        $scope.loadCategories();
        $scope.reloadEntry();
        $scope.newEntry = true;
        setTimeout(function () {
            document.getElementById("new_entry_description").focus();
        }, 10);
    }

    $scope.cancelNewEntry = function(){
        $scope.reloadEntry();
        $scope.newEntry = false;
    }

    $scope.addEntry = function(type_id){
        $scope.waitingNewFinancial  = true;
        $scope.entry.account    = $scope.current_account;
        $scope.entry.type_id    = type_id;
        var installments = $scope.entry.installments.installments;

        $scope.entry.transacted_at = moment($scope.entry.transacted_at).format('Y-M-D');

        if( installments > 1 )
        {
            var entry = angular.copy($scope.entry);
            $scope.entry.description = $scope.entry.description + ' Parcela 1/'+$scope.entry.installments.installments;
            $scope.entry.installments = installments;

            $financial_entry.post($scope.entry).$promise.then(function(response){
                for( var i = 1; i < entry.installments.installments; i ++ )
                {
                    var numberInstallment = parseInt(i+1);
                    var installment = {
                        type_id: entry.type_id,
                        account: $scope.current_account,
                        transacted_at: moment(entry.installments.data[i].transacted_at).format('Y-M-D'),
                        contact_id: entry.contact.id,
                        category: entry.category,
                        description: entry.description+' '+' Parcela '+numberInstallment+'/'+entry.installments.installments,
                        value: entry.installments.data[i].value,
                        recurrent_id: response.id,
                        installments: installments,
                        payment: entry.payment
                    }
                    $financial_entry.post(installment);
                }
                $scope.financial_entry[type_id].push(response);
                $scope.reloadEntry();
                $scope.newEntry = false;
                $scope.waitingNewFinancial  = false;
            }, function(error){
                $scope.waitingNewFinancial  = false;
            });
        }
        else
        {
            $scope.entry.installments = installments;
            $financial_entry.post($scope.entry).$promise.then(function(response){
                $scope.financial_entry[type_id].push(response);
                $scope.reloadEntry();
                $scope.loadEntries();
                $scope.newEntry = false;
                $scope.waitingNewFinancial  = false;
            }, function(error){
                $scope.waitingNewFinancial  = false;
            });
        }
    }

    $scope.updateStatusPaid = function(entry, status){

        var data = {
            paid            : status
        };

        if( entry.recurrent_days > 0 ){
            data.transacted_at = moment(entry.transacted_at).format('Y-M-D');
        }

        if( data.paid == true ){
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm_paid.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', '$suggestion', function($state, $scope, $uibModalInstance, $client, $suggestion) {

                    $scope.title = 'Confirmar pagamento';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Pagar';

                    $scope.accounts = $scope.$parent.accounts;

                    $scope.payments = [
                        {id:'money', title:'Dinheiro'},
                        {id:'credit_card', title:'Crédito'},
                        {id:'pos_debit', title:'Débito'},
                        {id:'boleto', title:'Boleto'},
                        {id:'link', title:'Link Pagamento'},
                        {id:'pix', title:'Pix'},
                        {id:'payment_later', title:'Posterior'},
                        {id:'transfer', title:'Transferência'},
                    ];

                    $suggestion.query({type: 'payment_other'}).$promise.then(function(response){
                        $scope.data_payment_suggestion = response;
                        if($scope.data_payment_suggestion.length > 0){
                            _.each($scope.data_payment_suggestion, function(suggestion){
                                $scope.payments.push({
                                    id: 'other', title: suggestion.description
                                });   
                            })     
                        }
                    });

                    $scope.paidAt = { open: false};
                    $scope.pay   = {
                        paid_at : moment.now(),
                        financial_account_id : entry.financial_account_id,
                        payment_method : ''
                    }

                    $scope.confirm = function() {
                        $scope.pay.payment_method = $scope.payment_method;
                        $uibModalInstance.close($scope.pay);
                    }

                    $scope.cancel = function() {
                        var index = _.indexOf($scope.$parent.financial_entry[entry.type_id], entry);
                        $scope.$parent.financial_entry[entry.type_id][index].paid = !status;
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });

            modalInstance.result.then(function(pay) {
                data.paid_at = moment(pay.paid_at).format('Y-M-D');
                data.financial_account_id = pay.financial_account_id;
                data.payment_method = pay.payment_method;
                $financial_entry.updateStatusPaid(entry.id, data).then(function(response){
                    ngToast.success({
                        content: 'Status atualizado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    $scope.loadEntries();
                }, function(error){
                    var index = _.indexOf($scope.financial_entry[entry.type_id], entry);
                    $scope.financial_entry[entry.type_id][index].paid = !status;
                    ngToast.danger({
                        content: 'Erro ao atulizar status',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                });
            });
        } else {
            $financial_entry.updateStatusPaid(entry.id, data).then(function(response){
                ngToast.success({
                    content: 'Status atualizado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.loadEntries();
            }, function(error){
                var index = _.indexOf($scope.financial_entry[entry.type_id], entry);
                $scope.financial_entry[entry.type_id][index].paid = !status;
                ngToast.danger({
                    content: 'Erro ao atulizar status',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }
    }

    $scope.createInstallments = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'financial_installment.modal.html',
            controller     : 'financial_installments',
            size           : 'md',
            resolve        : {
                entry: function(){
                    return angular.copy($scope.entry)
                }
            }
        });

        modalInstance.result.then(function(entry) {
            $scope.entry.installments = entry.installments;

            $scope.entry.transacted_at  = entry.installments.data[0].transacted_at;
            $scope.entry.value          = entry.installments.data[0].value;
            $scope.entry.description    = $scope.entry.description;
            $scope.entry.payment        = 'installments';
            $scope.payment              = entry.installments.installments + " x";
        });
    }

    $scope.createRecurrent = function(){
        $scope.entry.installments = {
            data: [],
            installments: 1,
            frequence : "month"
        }

        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'financial_recurrent.modal.html',
            controller     : 'recurrent',
            size           : 'md',
            resolve        : {
                entry: function(){
                    return angular.copy($scope.entry)
                }
            }
        });

        modalInstance.result.then(function(entry) {
            $scope.entry.recurrent_days         = entry.recurrent_days;
            $scope.entry.recurrent_favorite_day = entry.recurrent_favorite_day;
            $scope.entry.recurrent_ends         = entry.recurrent_ends;
            $scope.entry.recurrent_number       = entry.recurrent_number;
            $scope.entry.payment                = 'recurrent';
            $scope.payment                      = 'Recorrente';
        });
    }

    $scope.deleteInstallments = function(){
        $scope.entry.installments = {
            data: [],
            installments: 1,
            frequence : "month"
        }
        $scope.payment = "à vista";
        $scope.entry.payment = 'in_cash';
        $scope.entry.recurrent_days = null;
        $scope.entry.recurrent_favorite_day = null;
        $scope.entry.recurrent_ends = null;
    }

    $scope.openAccounts = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'accounts.modal.html',
            controller     : 'accounts',
            size           : 'md',
            resolve        : {
                account: function(){
                    return null
                }
            }
        });

        modalInstance.result.then(function(response) {
            $financial_account.query({count: 1000}).$promise.then(function(response){
                $scope.accounts = response.data;
            });
            if( response && response.id ){
                $scope.current_account = response;
            }
            $scope.loadEntries();
        });
    }

    $scope.editAccount = function(account){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'accounts.modal.html',
            controller     : 'accounts',
            size           : 'md',
            resolve        : {
                account: function(){
                    return angular.copy(account)
                }
            }

        });

        modalInstance.result.then(function(response) {
            $financial_account.query({count: 1000}).$promise.then(function(response){
                $scope.accounts = response.data;
            });
            if( response && response.id ){
                $scope.current_account = response;
            }
            $scope.loadEntries();
        });
    }

    $scope.openCategories = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'categories.modal.html',
            controller     : 'categories',
            size           : 'sm'
        });

        modalInstance.result.then(function(category) {
            $financial_category.query().$promise.then(function(response){
                $scope.categories = response.data;
            });
            $scope.entry.category.id = category.id;
        });
    }

    $scope.openContacts = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'contacts.modal.html',
            controller     : 'contacts',
            size           : 'lg'
        });

        modalInstance.result.then(function(response) {
            $scope.contacts = response.contacts;
            if( response.current_contatc.id ){
                $scope.entry.contacts = response.current_contatc;
            }
        });
    }

    $scope.changePeriodFilter = function(period){
        $scope.filter.period = period;
    }

    $scope.changeStatusFilter = function(status){
        $scope.filter.status = status;
    }

    $scope.changeDayFilter = function(day){
        $scope.filter.day = $scope.filter.day == day ? 0 : day;
        $scope.createDaysOfMonth();
    }

    $scope.clearFilters = function(){
        $scope.filter.status    = 'all';
        $scope.filter.period    = 'month';
        $scope.filter.day       = 0;
        $scope.today = angular.copy($scope.real_today);
        $scope.loadEntries();
    }

    $scope.filterTotal = function(data){
        return _.reduce(data, function(memo, row){
            if( row.total )
                return memo + parseFloat(row.total);
        }, 0);
    }

    $scope.filterEntries = function(data){
        var filtered = _.sortBy(data, function(row){
            return moment(row.paid_at).format('YYYYMMDD') + row.description;
        });

        if( $scope.filter.status != 'all' ){
            filtered = _.filter(filtered, function(entry){
                switch ($scope.filter.status) {
                    case 'future':
                        return moment(entry.paid_at).isAfter(moment(), 'day') && entry.paid == false;
                    break;
                    case 'today':
                        return moment(entry.paid_at).isSame(moment(), 'day') && entry.paid == false;
                    break;
                    case 'late':
                        return moment(entry.paid_at).isBefore(moment(), 'day') && entry.paid == false;
                    break;
                }
            });
        }
        if( $scope.filter.period != 'month' ){
            filtered = _.filter(filtered, function(entry){
                switch ($scope.filter.period) {
                    case 'week':
                        return moment(entry.paid_at).isBetween(moment().startOf('week'), moment().endOf('week'));
                    break;
                    case 'day':
                        return moment(entry.paid_at).isSame(moment(), 'day');
                    break;
                }
            });
        }
        if( $scope.filter.day != 0 ){
            filtered = _.filter(filtered, function(entry){ return parseInt(moment(entry.paid_at).format('DD')) == $scope.filter.day;});
        }

        return filtered;
    }

    $scope.selectContact = function(selected){
        if( selected ){
            if( selected.originalObject.custom ){
                $client.getTypes({name: 'contact'}).then(function(response){
                    var type = response;
                    var contact = {
                        type_id     : type.id,
                        name        : selected.title
                    };
                    $client.save(contact).$promise.then(function(response){
                        $scope.entry.contact.id         = response.id;
                        $scope.entry.contact.name       = selected.title;
                        $scope.entry.contact.type       = type;

                        $scope.contacts.push({
                            id : response.id,
                            data : {
                                name : selected.title
                            },
                            type : type
                        });
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.entry.contact = selected.originalObject;
                    setTimeout(function () {
                        document.getElementById("value").focus();
                    }, 10);
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
                }
            }
        }
    }

    $scope.selectCategory = function(selected){
        if( selected ){
            if( selected.originalObject.custom ){
                var category = {
                    name        : selected.title,
                    type        : $scope.current_type
                };
                $financial_category.post(category).$promise.then(function(response){
                    $scope.entry.category = response;
                    $scope.categories.push(response);
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.entry.category = selected.originalObject;
                    setTimeout(function () {
                        document.getElementById("payment_button").focus();
                    }, 10);
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputCategory');
                }
            }
        }
    }

    $scope.toggleCollapseTypes = function(){
        $scope.collapsed = !$scope.collapsed;
        $localStorage.financial_collapsed = $scope.collapsed;
        $scope.addOnCollapsed();
    }

    $scope.addOnCollapsed = function(){
        $scope.createDaysOfMonth();

        if( !$scope.collapsed )
            return;

        _.each($scope.financial_entry, function(type_data, key){
            if( key !== 'totals' && key != $scope.idCredit && key != $scope.idDebit )
            if( type_data.length > 0 && type_data[0].type && type_data[0].type.type == 'C'){
                _.each(type_data, function(entry){
                    $scope.financial_entry[$scope.idCredit].push(entry);
                });
            } else {
                _.each(type_data, function(entry){
                    $scope.financial_entry[$scope.idDebit].push(entry);
                });
            }
        });
    }

    $scope.createDaysOfMonth = function(){
        $scope.today= moment($scope.today);
        var financialType = _.find($scope.financial_types, {id: $scope.current_tab});

        $scope.dates = [];
        for (var i = 1; i <= $scope.today.daysInMonth(); i++) {
            switch (i) {
                case parseInt($scope.filter.day):
                    $scope.dates.push({
                        day: i,
                        class: 'selected'
                    });
                break;

                case parseInt($scope.real_today.format('DD')):
                    $scope.dates.push({
                        day: i,
                        class: 'current'
                    });
                break;

                case $scope.current_tab !== 99 && _.filter($scope.financial_entry[financialType.id], function(entry){return moment(entry.paid_at).format('DD') == i}).length > 0 ? i : 0:
                    $scope.dates.push({
                        day: i,
                        class: financialType.type == 'D' ? 'hover' : 'success'
                    });
                break;

                case $scope.current_tab === 99 && _.filter($scope.financial_transfers, function(entry){return moment(entry.paid_at).format('DD') == i}).length > 0 ? i : 0:
                    $scope.dates.push({
                        day: i,
                        class: 'warning'
                    });
                break;

                default:
                    $scope.dates.push({
                        day: i,
                        class: ''
                    });
            }
        }
    }

    $scope.editValue = function(entry){
        $scope.arrayValues[entry.id] = entry.total;
    }

    $scope.updateEntry = function(entry){
        if( $scope.arrayValues[entry.id] && $scope.arrayValues[entry.id] !== entry.total){
            entry.total = $scope.arrayValues[entry.id];
            entry.update_type = 'one';

            $financial_entry.update({ id: entry.id}, entry).$promise.then(function(response){
                ngToast.success({
                    content: 'Valor atualizado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.arrayValues[entry.id] = undefined;
                $scope.loadEntries();
                // $financial_entry.cashFlow().then(function(response){
                //     $scope.lates.debits = response.data.debit_noPaid;
                //     $scope.lates.credits = response.data.credit_noPaid;
                // })
            }, function(error){

            });
        } else {
            $scope.arrayValues[entry.id] = undefined;
        }
    }

    $scope.report = function(type){
        $state.go('financial_report', { type: type });
    }

    $scope.reportExtract = function(){
        $state.go('report.financial');
    }

    $scope.reportGroup = function(){
        $state.go('financial_report_group');
    }

    $scope.showChart = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/report/financial",
            size            : 'lg',
            controller      : 'report.financial',
            backdrop        : 'static',
            resolve         : {
                showInModal : true
            }
        });
    }

    $scope.makeInvoice = function(entry){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja realmente criar nota fiscal para esta transação?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            var data = {
                "financial_entry_id" : entry.id,
                'transacted_at' : entry.transacted_at, //Caso seja recorrente, preciso saber referente qual mes estou passando
                'paid_at' : entry.paid_at //Caso seja recorrente, preciso saber referente qual mes estou passando
            };


            $http.post(api+'invoice', data).then(function(response){
                var modalInstance = $uibModal.open({
                    animation      : true,
                    ariaLabelledBy : 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl    : server+"/views/m/invoice/show",
                    controller     : 'invoice.show',
                    size           : 'lg',
                    resolve        : {
                        invoice_id     : response.data.id,
                        showInModal    : true
                    }
                });
                $scope.loadEntries();
            });
        });

    }

    $scope.showInvoice = function(invoiceId){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/invoice/show",
            controller     : 'invoice.show',
            size           : 'lg',
            resolve        : {
                invoice_id     : invoiceId,
                showInModal    : true
            }
        });
        modalInstance.result.then(function() {
            $uibModalInstance.close();
        });

    }

    /**
     * Não achou o cliente então um popup para atualização do cliente deve aparecer
     */
    $scope.quickUpdatePerson = function(id){
        $client.get({
            id: id
        }).$promise.then(function(data) {

            var modalInstance = $uibModal.open({
                animation  : true,
                templateUrl: server+"/views/m/client/quickUpdatePerson",
                size       : 'lg',
                backdrop   : 'static',
                keyboard   : false,
                controller: ['$rootScope','$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$client','$countryState','$city','$client_address', function($rootScope,$state, $scope, $uibModalInstance, $bulletin, $http, $client, $countryState, $city, $client_address) {

                    $scope.client = data;

                    $scope.states = [];
                    $scope.cities = [];

                    $scope.address = ($scope.client.address[0]) ? $scope.client.address[0] : {
                        zip_code  : '',
                        city_id : {},
                        state_id : {},
                        type: 'Cobrança'
                    };

                    $countryState.query({count:1000}).$promise.then(function(response){
                        for( var i = 0; i < response.data.length; i++){
                            $scope.states.push({
                                id: response.data[i].id,
                                title: response.data[i].name,
                                name: response.data[i].name,
                                abbreviation: response.data[i].abbreviation
                            });
                        }
                    });

                    $scope.$watch('address.zip_code', function(){
                        if($scope.address.zip_code != undefined && $scope.address.zip_code.length == 8){
                            $http.get(api+'client/getCEP/'+$scope.address.zip_code).then(function(response){
                                $scope.address.line1        = response.data.logradouro;
                                $scope.address.neighborhood = response.data.bairro;

                                // Escolher UF
                                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                                    $scope.address.state_id = response;
                                });

                                // Escolher Cidade
                                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                                    $scope.cities = response.data;
                                    $scope.address.city_id = response.data[0];
                                });

                            });
                        }
                    });

                    setTimeout(function () {
                        $scope.formQuickUPdatePerson.$setSubmitted();
                    }, 10);

                    $scope.confirm = function() {

                        if($scope.formQuickUPdatePerson.$invalid)
                        {
                            ngToast.danger({
                                content: 'Verifique todos os campos.',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            return false;
                        }

                        // Salvar endereço
                        if( $scope.address.id == undefined && $scope.address.zip_code.length == 8 ){
                            var id = data.id;
                            var address = $scope.address;
                            address.state_id = $scope.address.state_id.id;
                            address.city_id  = $scope.address.city_id.id;
                            address.type     = 'Correspondência';
                            $client_address.save({client_id:id},address).$promise.then(function(data) {});
                        }

                        $client.update({
                            id: $scope.client.id
                        }, $scope.client).$promise.then(function(data) {
                            $rootScope.$broadcast('client_data_updated');
                            $uibModalInstance.dismiss('cancel');
                        });
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                }]
            });

        });
    }

    $scope.makeCharge = function(entry){

        if(entry.paid)
            return

        var quickUpdatePerson = $scope.quickUpdatePerson;

        var modalInstance = $uibModal.open({
            animation  : true,
            templateUrl: 'gerar_boleto.modal.html',
            size       : 'sm',
            backdrop   : 'static',
            keyboard   : false,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$client', function($state, $scope, $uibModalInstance, $bulletin, $http, $client) {

                $scope.boletoCreated = false;
                $scope.title = 'Gerar cobrança?';
                $scope.tryagain = false;

                $scope.$on('client_data_updated', function(e) {
                    $scope.confirm();
                });

                $scope.confirm = function() {
                    $scope.tryagain = false;

                    if(!$scope.boletoCreated){
                        $scope.creatingBoleto = true;
                        $scope.title = 'Aguarde...';

                        // get para verificar se os dados do cliente estão OK
                        $http.get(api+'client/'+entry.contact.id+'/check_register').then(function(response){

                            $http.post(api+'financial_entry/'+entry.id+'/make_boleto', {transacted_at: entry.transacted_at}).then(function(response){
                                $scope.creatingBoleto = false;
                                $scope.boletoCreated = true;
                                $scope.boleto = response.data;
                                $rootScope.$broadcast('boleto:created');
                            }, function(error){
                                $scope.title = 'Erro desconhecido';
                                $scope.tryagain = true;
                            });

                        }, function(error){
                            $scope.tryagain = true;
                            quickUpdatePerson(entry.contact.id);
                        });

                    } else {
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                }


            }]
        });
    }

    /**
     * Gerar segunda via
     */
    $scope.makeSecondCharge = function(entry){
        var modalInstance = $uibModal.open({
            animation  : true,
            templateUrl: '2via_do_boleto.modal.html',
            size       : 'sm',
            backdrop   : 'static',
            scope:$scope,
            keyboard   : false,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$client', '$localStorage','$rootScope',
            function($state, $scope, $uibModalInstance, $bulletin, $http, $client, $localStorage, $rootScope) {

                $scope.boletoCreated = false;
                $scope.title = 'Gerar segunda via dessa fatura?';
                $scope.tryagain = false;
                $scope.entry = entry;
                $scope.charges = {
                    entry_id : $scope.entry.id,
                    move_to : moment().add(1, 'days').toDate(),
                    boleto_penalty : $localStorage.company ? $localStorage.company.boleto_penalty : 0,
                    boleto_interest : $localStorage.company ? $localStorage.company.boleto_interest : 0,
                    boleto_penalty_type : $localStorage.company ? $localStorage.company.boleto_penalty_type : '',
                    total : $scope.entry.total
                };

                // TODO: se passar de x horas gerar só pro dia seguinte
                $scope.dateOptions = {
                    minDate: moment().toDate()
                };

                $scope.entry_transacted_at = {
                    opened: false
                };

                $scope.$on('client_data_updated', function(e) {
                    $scope.confirm();
                });

                $scope.recalcTotal = function()
                {
                    var overdueDays = parseInt( moment($scope.charges.move_to).diff($scope.entry.transaction.boleto_expiration_date, 'days') - 1 );
                    if ($scope.charges.boleto_penalty_type == 'V') {
                        var rTotal = $rootScope.sumFloat($scope.entry.total, $scope.charges.boleto_penalty);
                        $scope.charges.total = $rootScope.sumFloat(rTotal, ($scope.charges.boleto_interest * overdueDays));

                    }else{
                        $scope.charges.total =  parseFloat($scope.entry.total) + //Valor base
                                                parseFloat($scope.entry.total * parseFloat($scope.charges.boleto_penalty)) + //Multa
                                                //Juros por dia de atraso
                                                parseFloat( ($scope.entry.total * parseFloat(( $scope.charges.boleto_interest / 30) * overdueDays)  ) );
                    }
                }

                $scope.$watch("charges.move_to", function(newValue, oldValue) {
                    $scope.recalcTotal();
                });

                $scope.confirm = function() {
                    $scope.tryagain = false;

                    if(!$scope.boletoCreated){
                        $scope.boletoPenaltyValue = false;
                        $scope.boletoPenaltypercentage = false;
                        $scope.creatingBoleto = true;
                        $scope.title = 'Aguarde...';

                        $http.post(api+'financial_entry/'+entry.id+'/make_boleto?t=second', $scope.charges).then(function(response){
                            $scope.creatingBoleto = false;
                            $scope.boletoCreated = true;
                            $scope.boleto = response.data;
                            $rootScope.$broadcast('boleto:makeSecondCharge');
                            $rootScope.$broadcast('boleto:created');
                            $uibModalInstance.dismiss('cancel');
                            $scope.viewCharge(response.data.transaction);
                        }, function(error){
                            $scope.title = 'Erro desconhecido';
                            $scope.tryagain = true;
                        });

                    } else {
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                }

            }]
        });
    }

    $scope.viewCharge = function(entry){
        if( entry.paid )
            return;

        var modalInstance = $uibModal.open({
            animation  : true,
            templateUrl: 'view_charge.modal.html',
            size       : 'sm',
            backdrop   : 'static',
            scope:     $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$financial_entry',
            function($state, $scope, $uibModalInstance, $bulletin, $http, $financial_entry) {

                $scope.sending          = false;
                $scope.entry            = entry;
                $scope.transaction      = (entry.transaction == undefined) ? entry : $scope.entry.transaction;

                setTimeout(function () {
                    $scope.transaction.boleto_expiration_date = ($scope.transaction.boleto_expiration_date.date) ? $scope.transaction.boleto_expiration_date.date : $scope.transaction.boleto_expiration_date;

                    $scope.transaction.late = moment().isAfter($scope.transaction.boleto_expiration_date, 'day') && !entry.paid;
                    $scope.email            = entry.contact ? entry.contact.email : '';
                }, 10);

                $scope.sendEmail = function(email)
                {
                    $scope.sending = true;
                    $financial_entry.sendBoleto($scope.entry.id, $scope.transaction.id, email).then(function(response){
                        $uibModalInstance.close();
                    }, function(error){
                        console.log('deu error');
                    });
                }

                $rootScope.$on('boleto:makeSecondCharge', function(e){
                    $uibModalInstance.dismiss('cancel');
                });

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                }
            }]
        });

        modalInstance.result.then(function() {
            ngToast.success({
                content: 'Boleto enviado',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }

    $scope.partialPayment = function(entry){
        var modalInstance = $uibModal.open({
            animation  : true,
            templateUrl: 'pay_partial.modal.html',
            size       : 'md',
            scope:     $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$financial_entry',
            function($state, $scope, $uibModalInstance, $bulletin, $http, $financial_entry) {

                $scope.title        = 'Baixa parcial';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Ok';
                $scope.entry    = entry;
                $scope.minClausule      = moment().format('yyyy-MM-DD');

                $scope.payment  = {
                    received_at         : moment().toDate(),
                    description         : 'Pagamento parcial',
                    payment_method      : 'money',
                    value               : $scope.entry.total,
                    value_opened        : 0,
                    financial_entry_id  : $scope.entry.id,
                    transacted_at       : $scope.entry.transacted_at,
                    installments        : 1
                }

                $scope.changeValue = function(){
                    $scope.payment.value_opened = $scope.entry.total - parseFloat($scope.payment.value);
                    $scope.changeInstallments();
                }
                $scope.changeInstallments = function() {
                    $scope.entries = [];
                    if($scope.payment.value_opened > 0){
                        var total   = parseFloat($scope.payment.value_opened);
                        var value   = ($scope.payment.value_opened / $scope.payment.installments).toFixed(2);
                        var sum     = 0;
                        var date    = moment($scope.entry.paid_at).add(1, 'months');
                        for (var i = 0; i < $scope.payment.installments; i++) {
                            $scope.entries.push({
                                transacted_at   : moment(date).toDate(),
                                description     : 'Parcelamento '+(i+2)+'/'+($scope.payment.installments+1),
                                value           : value
                            });
                            sum += parseFloat(value);
                            date = date.add(1, 'months');
                        }
                        if( total != sum ) $scope.entries[0].value = parseFloat($scope.entries[0].value) + parseFloat((total - sum).toFixed(2));
                    }
                }

                $scope.confirm = function(){
                    var data = $scope.payment;
                    data.entries = $scope.entries;

                    $financial_entry.payPartial(data).then(function(response){
                        $scope.$parent.loadEntries();
                        $uibModalInstance.close();
                    }, function(error){
                        console.log('deu error');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                }
            }]
        });
    }

    $scope.makeCarne = function(entry) {
        $state.go('make_boleto', {contract_id : entry.contract_id});
    }

    $scope.newTransfer = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            size           : 'md',
            scope          : $scope,
            templateUrl    : 'new_transfer.modal.html',
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', '$financial_account', '$financial_category',
                function($scope, $uibModalInstance, $financial_entry, _, $financial_account, $financial_category) {

                $scope.title = 'Tranferência';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Confirmar';
                $scope.action           = 'Confirmar';

                $scope.transfer = {
                    value           : 0,
                    transacted_at   : moment().toDate(),
                    from            : $scope.$parent.current_account ? $scope.$parent.current_account.id : null
                }

                $scope.changeAccountTransfer = function(){
                    $financial_account.query({count: 1000}).$promise.then(function(response){
                        $scope.accounts = response.data;
                    });
                };
                $scope.changeAccountTransfer();

                $scope.confirm = function() {
                    $financial_entry.makeTransfer($scope.transfer).then(function(response){
                        // $scope.loadEntries();
                        $uibModalInstance.close();
                    }, function(error){
                    });
                    $uibModalInstance.close();
                };

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });

        modalInstance.result.then(function() {
            if ( $scope.hasAccount ) {
                $scope.loadingEntries = true;
                $financial_account.query({id: $scope.current_account.id, date: $scope.today.toISOString()}).$promise.then(function(response){
                    $scope.current_account = response;
                    $scope.loadEntries();
                    $scope.loadingEntries = false;
                });
            }else{
                $scope.loadEntries();
            }
        });

    }

    $scope.loadTransfers = function(){
        $financial_entry.getTransfers({financial_account_id:$scope.current_account.id, date: $scope.today.toISOString()}).then(function(response){
            var rTotalCredit = _.reduce(_.where(response.data, {transfer : "C"}), function(memo, row){ return $rootScope.sumFloat(memo, row.total); }, 0);
            var rTotalDebit = _.reduce(_.where(response.data, {transfer : "D"}), function(memo, row){ return $rootScope.sumFloat(memo, row.total); }, 0);
            $scope.financial_transfers = response.data;
            $scope.financial_transfers.totalCredit = rTotalCredit;
            $scope.financial_transfers.totalDebit = rTotalDebit;
        });
    }

    $scope.removeTransfer = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'delete_entry.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', '$q', function($scope, $uibModalInstance, $financial_entry, _, $q) {
                $scope.title = 'Excluir registro?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Excluir';
                $scope.action           = 'excluir';
                $scope.exclusionOptions = false;

                $scope.confirm = function() {
                    $financial_entry.deleteEntry(data.id,{
                        delete_type: 'one',
                        transacted_at: data.transacted_at
                    }).then(function(response){
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
        modalInstance.result.then(function(entry){
            if ( $scope.hasAccount ) {
                $scope.loadingEntries = true;
                $financial_account.query({id: $scope.current_account.id, date: $scope.today.toISOString()}).$promise.then(function(response){
                    $scope.current_account = response;
                    $scope.loadEntries();
                    $scope.loadingEntries = false;
                });
            }else{
                $scope.loadEntries();
            }
        });

    }

    $scope.editDescriptionTransfer = function(data){
        if (data) {
            $scope.transferId = data.id;
            $scope.showEditing = data.transfer;
            $scope.toEdit.description = data.description;
        }
    }

    $scope.confirmUpdateTransfer = function(entry) {
        if (entry && $scope.toEdit.description != entry.description) {
            entry.description = $scope.toEdit.description;
            $financial_entry.update({ id: entry.id}, entry).$promise.then(function(response){
                $scope.showEditing = null;
                $scope.transferId   = null;
                ngToast.success({
                    content: 'Descrição atualizada!',
                    dismissOnTimeout: true,
                    timeout: 1000
                });
                $scope.loadEntries();
            }, function(error){
                console.log(error);
            });
        }

    }

    $scope.closeEdit = function(data){
        $scope.showEditing  = null;
        $scope.transferId    = null;
        $scope.loadEntries();
    }

    $scope.searchEntries = function(){
        var str = document.getElementById('findEntries').value;
        if(str.length == 0){
            ngToast.danger({
                content: 'Sem dados para pesquisa!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/financial_entry/report_search_modal",
            controller     : 'report_searching',
            size           : 'lg',
            resolve        : {
                find: function(){
                return str;
                }
            }
            
        });
    }
}]);

angular.module('financial_entry')
.controller('financial_entry.show', ['$scope', '$state', '$localStorage', '$http', '$auth', '$q', '$uibModal', '$financial_entry', '$financial_account', '$financial_category', '$client', '$users', '_', 'ngToast', '$rootScope', '$company', '$invoice', 'entry', '$uibModalInstance', '$financial_comment',
function($scope, $state, $localStorage, $http, $auth, $q, $uibModal, $financial_entry, $financial_account, $financial_category, $client, $users, _, ngToast, $rootScope, $company, $invoice, entry, $uibModalInstance, $financial_comment) {

    $scope.title        = 'Editar transação';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Ok';
    $scope.edit_entry   = angular.copy(entry);
    $scope.edit_entry.recurrent_favorite_day = ''+$scope.edit_entry.recurrent_favorite_day;
    $scope.repeat           = false;
    $scope.edit             = true;
    $scope.comment          = '';
    $scope.paid             = {};
    $scope.transacted       = {};
    $scope.recurrent_ends   = {};
    $scope.send_ends        = $scope.edit_entry.recurrent_ends ? true : false;
    $scope.showReferences   = false;

    $scope.edit_entry.recurrent_ends    = moment($scope.edit_entry.recurrent_ends).toDate();
    $scope.edit_entry.paid_at           = moment($scope.edit_entry.paid_at).toDate();

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });

    //Carregar categorias
    $financial_category.query({count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });

    $client.query({count: 10000}).$promise.then(function(response){
        $scope.contacts = response.data;

        if( $scope.edit_entry.contact_id ){
            $scope.edit_entry.contact = _.where($scope.contacts, {id: $scope.edit_entry.contact_id})[0];
        }
    });

    if( $scope.edit_entry.payment == 'recurrent' || $scope.edit_entry.payment == 'installments'){
        $scope.repeat  = true;
        $financial_entry.getRelateds($scope.edit_entry.id).then(function(response){
            $scope.entries = response.data;
        });
    }

    $scope.showAllReferences = function(){
        $scope.showReferences  = true;
    }

    $scope.edit_entry.transacted_at  = moment(entry.transacted_at).toDate();

    $scope.selectEditContact = function(selected)
    {
        if( selected ){
            if( selected.originalObject.custom ){
                $client.getTypes({name: 'contact'}).then(function(response){
                    var type = response;
                    var contact = {
                        type_id     : type.id,
                        name        : selected.title
                    };
                    $client.save(contact).$promise.then(function(response){
                        $scope.edit_entry.contact.id         = response.id;
                        $scope.edit_entry.contact.name       = selected.title;
                        $scope.edit_entry.contact.type       = type;

                        $scope.contacts.push({
                            id : response.id,
                            data : {
                                name : selected.title
                            },
                            type : type
                        });
                    });
                });
            } else {
                if( selected.originalObject.id )
                    $scope.edit_entry.contact = selected.originalObject;
                else {
                    $scope.$broadcast('angucomplete-alt:clearInput');
                }
            }
        }
    }

    $scope.selectEditCategory = function(selected)
    {
        if( selected ){
            if( selected.originalObject.custom ){
                var category = {
                    name        : selected.title,
                    type        : $scope.edit_entry.type
                };
                $financial_category.post(category).$promise.then(function(response){
                    $scope.edit_entry.category = response;
                    $scope.categories.push(response);
                });
            } else {
                if( selected.originalObject.id )
                    $scope.edit_entry.category = selected.originalObject;
                else {
                    $scope.$broadcast('angucomplete-alt:clearInput');
                }
            }
        }
    }

    $financial_comment.query({financial_entry_id: $scope.edit_entry.id}).$promise.then(function(response){
        $scope.comments = response;
    });

    $scope.sendComment = function() {
        var data = {
            financial_entry_id: $scope.edit_entry.id,
            description: $scope.comment
        };
        $financial_comment.save(data).$promise.then(function(response){
            ngToast.success({
                content: 'Comentário adicionado',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $financial_comment.query({financial_entry_id: $scope.edit_entry.id}).$promise.then(function(response){
                $scope.comments = response;
            });
            $scope.comment = '';
        }, function(error){
            ngToast.danger({
                content: 'Erro ao adicionar comentário',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }

    $scope.confirm = function() {

        if( $scope.repeat ){
            var modalInstance2 = $uibModal.open({
                animation: true,
                templateUrl: 'delete_entry.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', '$q',
                function($scope, $uibModalInstance, $financial_entry, _, $q) {
                    $scope.title            = 'Esta transação se repete';
                    $scope.action           = 'editar';
                    $scope.cancel_text      = 'Cancelar';
                    $scope.success_text     = 'Ok';
                    $scope.exclusionOptions = true;
                    $scope.exclusion = {
                        type : 'one'
                    };

                    $scope.confirm = function() {
                        if(!$scope.send_ends){
                            $scope.edit_entry.recurrent_ends = null;
                        }

                        var array = [];
                        switch ($scope.exclusion.type) {

                            case 'one':
                                var entry = {
                                    financial_account_id    : $scope.edit_entry.financial_account_id,
                                    description             : $scope.edit_entry.description,
                                    contact_id              : $scope.edit_entry.contact.id,
                                    value                   : $scope.edit_entry.value,
                                    total                   : $scope.edit_entry.total,
                                    financial_category_id   : $scope.edit_entry.category.id,
                                    update_type             : 'one',
                                    transacted_at           : $scope.edit_entry.transacted_at,
                                    paid_at                 : moment($scope.edit_entry.paid_at).format('YYYY-MM-DD'),
                                    recurrent_favorite_day  : $scope.edit_entry.recurrent_favorite_day,
                                    recurrent_ends          : $scope.send_ends ? moment($scope.edit_entry.recurrent_ends).format('YYYY-MM-DD') : null,
                                    send_boleto             : $scope.edit_entry.send_boleto,
                                    send_invoice            : $scope.edit_entry.send_invoice
                                };
                                array.push($financial_entry.update({ id: $scope.edit_entry.id}, entry));
                            break;

                            case 'all':
                                if( $scope.edit_entry.payment == 'recurrent'){
                                    var entry = {
                                        financial_account_id    : $scope.edit_entry.financial_account_id,
                                        description             : $scope.edit_entry.description,
                                        contact_id              : $scope.edit_entry.contact.id,
                                        value                   : $scope.edit_entry.value,
                                        total                   : $scope.edit_entry.total,
                                        financial_category_id   : $scope.edit_entry.category.id,
                                        update_type             : 'all',
                                        transacted_at           : $scope.edit_entry.transacted_at,
                                        paid_at                 : moment($scope.edit_entry.paid_at).format('YYYY-MM-DD'),
                                        recurrent_favorite_day  : $scope.edit_entry.recurrent_favorite_day,
                                        recurrent_ends          : $scope.send_ends ? moment($scope.edit_entry.recurrent_ends).format('YYYY-MM-DD') : null,
                                        send_boleto             : $scope.edit_entry.send_boleto,
                                        send_invoice            : $scope.edit_entry.send_invoice
                                    };
                                } else {
                                    var entry = {
                                        financial_account_id    : $scope.edit_entry.financial_account_id,
                                        description             : $scope.edit_entry.description,
                                        contact_id              : $scope.edit_entry.contact.id,
                                        value                   : $scope.edit_entry.value,
                                        total                   : $scope.edit_entry.total,
                                        financial_category_id   : $scope.edit_entry.category.id,
                                        transacted_at           : $scope.edit_entry.transacted_at,
                                        update_type             : 'all',
                                        recurrent_favorite_day  : $scope.edit_entry.recurrent_favorite_day,
                                        recurrent_ends          : $scope.send_ends ? moment($scope.edit_entry.recurrent_ends).format('YYYY-MM-DD') : null,
                                        send_boleto             : $scope.edit_entry.send_boleto,
                                        send_invoice            : $scope.edit_entry.send_invoice

                                    };
                                }
                                array.push($financial_entry.update({ id: $scope.edit_entry.id}, entry));
                            break;

                            case 'future':
                                if( $scope.edit_entry.payment == 'recurrent'){
                                    var entry = {
                                        financial_account_id    : $scope.edit_entry.financial_account_id,
                                        description             : $scope.edit_entry.description,
                                        contact_id              : $scope.edit_entry.contact.id,
                                        value                   : $scope.edit_entry.value,
                                        total                   : $scope.edit_entry.total,
                                        financial_category_id             : $scope.edit_entry.category.id,
                                        update_type             : 'future',
                                        transacted_at           : $scope.edit_entry.transacted_at,
                                        paid_at                 : moment($scope.edit_entry.paid_at).format('YYYY-MM-DD'),
                                        recurrent_favorite_day  : $scope.edit_entry.recurrent_favorite_day,
                                        recurrent_ends          : $scope.send_ends ? moment($scope.edit_entry.recurrent_ends).format('YYYY-MM-DD') : null,
                                        send_boleto             : $scope.edit_entry.send_boleto,
                                        send_invoice            : $scope.edit_entry.send_invoice
                                    };
                                } else {
                                    var entry = {
                                        financial_account_id    : $scope.edit_entry.financial_account_id,
                                        description             : $scope.edit_entry.description,
                                        contact_id              : $scope.edit_entry.contact.id,
                                        value                   : $scope.edit_entry.value,
                                        total                   : $scope.edit_entry.total,
                                        financial_category_id   : $scope.edit_entry.category.id,
                                        update_type             : 'future',
                                        recurrent_favorite_day  : $scope.edit_entry.recurrent_favorite_day,
                                        recurrent_ends          : $scope.send_ends == 1 ? moment($scope.edit_entry.recurrent_ends).format('YYYY-MM-DD') : null,
                                        send_boleto             : $scope.edit_entry.send_boleto,
                                        send_invoice            : $scope.edit_entry.send_invoice
                                    };
                                }
                                array.push($financial_entry.update({ id: $scope.edit_entry.id}, entry));
                            break;
                        }
                        setTimeout(function(){
                            $q.all( array ).then(function(response){
                                $uibModalInstance.close();
                            });
                        },300);
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.close();
                    };
                }]
            });

            modalInstance2.result.then(function() {
                $uibModalInstance.close();
            });
        } else {
            var entry = {
                financial_account_id    : $scope.edit_entry.financial_account_id,
                transacted_at           : $scope.edit_entry.transacted_at,
                paid_at                 : $scope.edit_entry.paid_at,
                description             : $scope.edit_entry.description,
                contact_id              : $scope.edit_entry.contact.id,
                value                   : $scope.edit_entry.value,
                total                   : $scope.edit_entry.total,
                financial_category_id             : $scope.edit_entry.category.id,
            };

            $financial_entry.update({id: $scope.edit_entry.id}, entry).$promise.then(function(response){
                $uibModalInstance.close();
            });
        }

    };

    $scope.updateStatusPaid = function(entry, status){

        var data = {
            paid            : status
        };


        if( entry.recurrent_days > 0 ){
            data.transacted_at = moment(entry.transacted_at).format('Y-M-D');
        }

        if( data.paid == true ){
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm_paid.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                    $scope.title = 'Confirmar pagamento';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Pagar';

                    $scope.accounts = $scope.$parent.accounts;

                    $scope.paidAt = { open: false};
                    $scope.pay   = {
                        paid_at : moment.now(),
                        financial_account_id : entry.financial_account_id
                    }

                    $scope.confirm = function() {
                        $uibModalInstance.close($scope.pay);
                    }

                    $scope.cancel = function() {
                        var index = _.indexOf($scope.$parent.financial_entry[entry.type_id], entry);
                        $scope.$parent.financial_entry[entry.type_id][index].paid = !status;
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });

            modalInstance.result.then(function(pay) {
                data.paid_at = moment(pay.paid_at).format('Y-M-D');
                data.financial_account_id = pay.financial_account_id;
                $financial_entry.updateStatusPaid(entry.id, data).then(function(response){
                    ngToast.success({
                        content: 'Status atualizado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                }, function(error){
                    var index = _.indexOf($scope.financial_entry[entry.type_id], entry);
                    $scope.financial_entry[entry.type_id][index].paid = !status;
                    ngToast.danger({
                        content: 'Erro ao atulizar status',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                });
            });
        } else {
            $financial_entry.updateStatusPaid(entry.id, data).then(function(response){
                ngToast.success({
                    content: 'Status atualizado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }, function(error){
                var index = _.indexOf($scope.financial_entry[entry.type_id], entry);
                $scope.financial_entry[entry.type_id][index].paid = !status;
                ngToast.danger({
                    content: 'Erro ao atulizar status',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }
    }

    $scope.cancel = function() {
        $uibModalInstance.close();
    };
}]);

angular.module('financial_entry')
.controller('make_any_financials', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', 'entry', '$financial_account', '$financial_category', '$financial_entry',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, entry, $financial_account, $financial_category, $financial_entry) {
    $scope.title            = 'Confirmar financeiro';
    $scope.cancel_text      = 'Cancelar';
    $scope.success_text     = 'Concluído';
    $scope.completeEntries  = true;

    $scope.transacted = [];
    $scope.entry = entry;

    $financial_entry.getTypes({type: 'D'}).then(function(response){
        $scope.entry.type = response.data[0];
        $scope.entry.type_id = response.data[0].id;
        var type = response.data[0];
    });

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });

    $financial_category.query({type: 'D', count: 1000}).$promise.then(function(response) {
        $scope.categories = response.data;
    });

    $scope.selectEditCategory = function(selected) {
        if( selected ){
            if( selected.originalObject.custom ){
                var category = {
                    type        : 'D',
                    name        : selected.title
                };
                $financial_category.post(category).$promise.then(function(response){
                    $scope.entry.category = response;
                    $scope.categories.push(response);
                });
            } else {
                if( selected.originalObject.id )
                    $scope.entry.category = selected.originalObject;
                else {
                    $scope.$broadcast('angucomplete-alt:clearInput');
                }
            }
        }
    }

    $scope.skip = function(){
        $uibModalInstance.close(true);
    }

    $scope.confirm = function()
    {
        $scope.waiting  = true;
        $scope.entry.financial_category_id  = $scope.entry.category.id;
        $scope.entry.transacted_at          = moment($scope.entry.installments.data[0].transacted_at).format('Y-M-D');
        $scope.entry.value                  = $scope.entry.installments.data[0].value;
        $scope.entry.total                  = $scope.entry.installments.data[0].total;

        if( $scope.entry.installments.installments > 1 )
        {
            var entry = angular.copy($scope.entry);
            $scope.entry.description = $scope.entry.description + ' Parcela 1/'+$scope.entry.installments.installments;
            $scope.entry.installments = 1;

            $financial_entry.post($scope.entry).$promise.then(function(response){
                for( var i = 1; i < entry.installments.installments; i ++ )
                {
                    var numberInstallment = parseInt(i+1);
                    var installment = {
                        type_id: entry.type_id,
                        account: entry.financial_account_id,
                        transacted_at: moment(entry.installments.data[i].transacted_at).format('Y-M-D'),
                        contact_id: entry.contact.id,
                        category: entry.category,
                        description: entry.description+' '+' Parcela '+numberInstallment+'/'+entry.installments.installments,
                        value: entry.installments.data[i].value,
                        recurrent_id: response.id,
                        installments: numberInstallment
                    }
                    $financial_entry.post(installment);
                }
                $scope.waiting  = false;
                $uibModalInstance.close(false);
            }, function(error){
                $scope.waiting  = false;
            });
        }
        else
        {
            $scope.entry.installments = 1;
            $financial_entry.post($scope.entry).$promise.then(function(response){
                $scope.waiting  = false;
                $uibModalInstance.close(false);

            }, function(error){
                $scope.waiting  = false;
            });
        }
    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.getInstallments = function()
    {
        var total           = parseFloat($scope.entry.value);
        var qtd             = parseInt($scope.entry.installments.installments);
        var installments    = parseFloat(total/qtd).toFixed(2);
        var remainder       = 0;
        var date            = new moment($scope.entry.transacted_at).toDate();
        var day             = date.getDate();
        var nextDate        = date;

        $scope.entry.installments.data = [];

        for( var i = 1; i<= qtd; i++)
        {
            $scope.entry.installments.data.push( {
                transacted_at : nextDate,
                value : installments,
                installment : i
            });
            $scope.transacted[i] = {
                opened: false
            };

            switch ($scope.entry.installments.frequence)
            {
                case "week":
                    nextDate = moment(nextDate).add(7, 'days').toDate();
                    break;
                case "biweek":
                    nextDate = moment(nextDate).add(14, 'days').toDate();
                    break;
                case "month":
                    nextDate = moment(nextDate).add(1, 'months').toDate();
                    if( nextDate.getDate() != day ){
                        if( moment(nextDate).endOf('month').date() > day ) {
                            nextDate = moment(nextDate).date(day).toDate();
                        } else {
                            nextDate = moment(nextDate).endOf('month').toDate();
                        }
                    }
                    break;
                case "bimonth":
                    nextDate = moment(nextDate).add(2, 'months').toDate();
                    if( nextDate.getDate() != day ){
                        if( moment(nextDate).endOf('month').date() > day ) {
                            nextDate = moment(nextDate).date(day).toDate();
                        } else {
                            nextDate = moment(nextDate).endOf('month').toDate();
                        }
                    }
                    break;
                case "quarter":
                    nextDate = moment(nextDate).add(3, 'months').toDate();
                    if( nextDate.getDate() != day ){
                        if( moment(nextDate).endOf('month').date() > day ) {
                            nextDate = moment(nextDate).date(day).toDate();
                        } else {
                            nextDate = moment(nextDate).endOf('month').toDate();
                        }
                    }
                    break;
                case "half":
                    nextDate = moment(nextDate).add(6, 'months').toDate();
                    if( nextDate.getDate() != day ){
                        if( moment(nextDate).endOf('month').date() > day ) {
                            nextDate = moment(nextDate).date(day).toDate();
                        } else {
                            nextDate = moment(nextDate).endOf('month').toDate();
                        }
                    }
                    break;
                case "year":
                    nextDate = moment(nextDate).add(1, 'years').toDate();
                    if( nextDate.getDate() != day ){
                        if( moment(nextDate).endOf('month').date() > day ) {
                            nextDate = moment(nextDate).date(day).toDate();
                        } else {
                            nextDate = moment(nextDate).endOf('month').toDate();
                        }
                    }
                    break;
            }
        }
        if( qtd * installments != total ){
            remainder = parseFloat(total - qtd * installments).toFixed(2);
            if( remainder < 0 ){
                remainder = parseFloat( remainder * (-1));
                $scope.entry.installments.data[0].value = parseFloat(parseFloat($scope.entry.installments.data[0].value) - parseFloat(remainder)).toFixed(2);
            } else {
                $scope.entry.installments.data[0].value = parseFloat(parseFloat($scope.entry.installments.data[0].value) + parseFloat(remainder)).toFixed(2);
            }
        }

    };

    $scope.getInstallments();
}]);

angular.module('financial_entry')
.controller('financial_installments', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', 'entry',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, entry) {
        $scope.title        = 'Gerar Parcelas';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';

        $scope.transacted = [];
        $scope.entry = entry;

        $scope.confirm = function()
        {
            $uibModalInstance.close($scope.entry);
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.getInstallments = function()
        {
            var total           = parseFloat($scope.entry.value);
            var qtd             = parseInt($scope.entry.installments.installments);
            var installments    = parseFloat(total/qtd).toFixed(2);
            var remainder       = 0;
            var date            = new moment($scope.entry.transacted_at).toDate();
            var day             = date.getDate();
            var nextDate        = date;

            $scope.entry.installments.data = [];

            for( var i = 1; i<= qtd; i++)
            {
                $scope.entry.installments.data.push( {
                    transacted_at : nextDate,
                    value : installments,
                    installment : i
                });
                $scope.transacted[i] = {
                    opened: false
                };

                switch ($scope.entry.installments.frequence)
                {
                    case "week":
                        nextDate = moment(nextDate).add(7, 'days').toDate();
                        break;
                    case "biweek":
                        nextDate = moment(nextDate).add(14, 'days').toDate();
                        break;
                    case "month":
                        nextDate = moment(nextDate).add(1, 'months').toDate();
                        if( nextDate.getDate() != day ){
                            if( moment(nextDate).endOf('month').date() > day ) {
                                nextDate = moment(nextDate).date(day).toDate();
                            } else {
                                nextDate = moment(nextDate).endOf('month').toDate();
                            }
                        }
                        break;
                    case "bimonth":
                        nextDate = moment(nextDate).add(2, 'months').toDate();
                        if( nextDate.getDate() != day ){
                            if( moment(nextDate).endOf('month').date() > day ) {
                                nextDate = moment(nextDate).date(day).toDate();
                            } else {
                                nextDate = moment(nextDate).endOf('month').toDate();
                            }
                        }
                        break;
                    case "quarter":
                        nextDate = moment(nextDate).add(3, 'months').toDate();
                        if( nextDate.getDate() != day ){
                            if( moment(nextDate).endOf('month').date() > day ) {
                                nextDate = moment(nextDate).date(day).toDate();
                            } else {
                                nextDate = moment(nextDate).endOf('month').toDate();
                            }
                        }
                        break;
                    case "half":
                        nextDate = moment(nextDate).add(6, 'months').toDate();
                        if( nextDate.getDate() != day ){
                            if( moment(nextDate).endOf('month').date() > day ) {
                                nextDate = moment(nextDate).date(day).toDate();
                            } else {
                                nextDate = moment(nextDate).endOf('month').toDate();
                            }
                        }
                        break;
                    case "year":
                        nextDate = moment(nextDate).add(1, 'years').toDate();
                        if( nextDate.getDate() != day ){
                            if( moment(nextDate).endOf('month').date() > day ) {
                                nextDate = moment(nextDate).date(day).toDate();
                            } else {
                                nextDate = moment(nextDate).endOf('month').toDate();
                            }
                        }
                        break;
                }
            }
            if( qtd * installments != total ){
                remainder = parseFloat(total - qtd * installments).toFixed(2);
                if( remainder < 0 ){
                    remainder = parseFloat( remainder * (-1));
                    $scope.entry.installments.data[0].value = parseFloat(parseFloat($scope.entry.installments.data[0].value) - parseFloat(remainder)).toFixed(2);
                } else {
                    $scope.entry.installments.data[0].value = parseFloat(parseFloat($scope.entry.installments.data[0].value) + parseFloat(remainder)).toFixed(2);
                }
            }

        };
}]);

angular.module('financial_entry')
.controller('recurrent', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', 'entry',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, entry) {
        $scope.title        = 'Recorrencia';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';

        $scope.recurrent_ends = {
            opened: false
        };

        $scope.financial_recurrents = [
            {
                day: 30,
                name: 'Mensal'
            },
            {
                day: 60,
                name: 'Bimestral'
            },
            {
                day: 90,
                name: 'Trimestral'
            },
            {
                day: 180,
                name: 'Semestral'
            },
            {
                day: 365,
                name: 'Anual'
            }
        ];

        $scope.entry                        = entry;
        $scope.entry.recurrent_days         = 30;
        $scope.entry.recurrent_favorite_day = parseInt(moment($scope.entry.transacted_at).format('D'));
        $scope.entry.recurrent_ends         = $scope.entry.recurrent_ends;

        $scope.confirm = function()
        {
            $uibModalInstance.close($scope.entry);
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };

}]);

angular.module('financial_entry')
.controller('accounts', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$financial_account', 'account',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $financial_account, account) {
        $scope.title        = 'Adicionar conta bancária';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';
        $scope.account      = {
                                name : "",
                                favorite : false,
                                inital_value : 0,
                                type : 'CC',
                                bank_agency : "",
                                bank_number : ""
                            };
        if( account ){
            $scope.account = account;
        }

        $scope.confirm = function()
        {
            if($scope.account.id){
                $financial_account.update({id: $scope.account.id}, $scope.account).$promise.then(function(response){
                    $uibModalInstance.close(response);
                });
            } else {
                $financial_account.post($scope.account).$promise.then(function(response){
                    $uibModalInstance.close(response);
                });
            }

        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('financial_entry')
.controller('categories', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$financial_category',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $financial_category) {
        $scope.title        = 'Adicionar Categoria';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';
        $scope.category     = { name : "" };

        $scope.confirm = function()
        {
            $financial_category.post($scope.category).$promise.then(function(response){
                $uibModalInstance.close(response);
            });
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('financial_entry')
.controller('contacts', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$client',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $client) {
        $scope.title        = 'Contatos';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';
        $scope.newContact  = false;
        $scope.contact     = {
            name : "",
            cpf_cnpj : ""
        };
        $scope.current_contatc = {};

        $scope.transacted = {
            opened: false
        };

        $scope.loadContacts = function()
        {
            $client.query().$promise.then(function(response)
            {
                $scope.contacts = response.data;
            });
        }
        $scope.loadContacts();

        $scope.addNewContact = function()
        {
            $scope.contact     = {
                name : "",
                cpf_cnpj : ""
            };
            $scope.newContact   = true;
        }

        $scope.addContact = function()
        {
            $client.save($scope.contact).$promise.then(function(response){
                $scope.contact     = {
                    name : "",
                    cpf_cnpj : ""
                };
                $scope.newContact   = false;
                $scope.current_contatc = response;
                $scope.loadContacts();
            });
        }

        $scope.confirm = function()
        {
            var response = {
                contacts: $scope.contacts,
                current_contatc: $scope.current_contatc
            };
            $uibModalInstance.close(response);
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('financial_entry').
controller('report', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModal',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModal) {

    $scope.selecteds = [];
    $scope.sortable = 'transacted_at';
    $scope.financials = [];
    $scope.filter = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate(),
        account : {},
        category : {},
        contact : {},
        type : $stateParams.type
    };


    $scope.printing        = false;
    $scope.$parent.loading = false;

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.selectEntry = function(entry){
        if($scope.selecteds[entry.id]){
            $scope.selecteds.splice(entry.id, 1);
        } else {
            $scope.selecteds[entry.id] = entry;
        }
    }

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });
    $financial_category.query({type: $stateParams.type, count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });
    $client.query({count: 10000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.searchContact = function(obj) {
        $scope.filter.contact = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                $scope.filter.contact.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            }
        } 
    }

    $scope.sortEntry = function(sort)
    {
        $scope.sortable = sort;
        $scope.filterEntries();
    }

    $scope.filterEntries = function(data)
    {
        if( $scope.sortable == 'transacted_at') {
            var filtered = _.sortBy(data, function(row){
                return moment(row.transacted_at).format('YYYYMMDD') + row.description;
            });
        }
        if( $scope.sortable == 'created_at') {
            var filtered = _.sortBy(data, function(row){
                return moment(row.created_at).format('YYYYMMDD') + row.description;
            });
        }

        return filtered;
    }

    $scope.payMany = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'pay_many.modal.html',
            controller     : 'financial_pay_many',
            size           : 'lg',
            resolve        : {
                entries: function(){
                    return angular.copy($scope.selecteds)
                },
                type : function(){
                    $scope.filter.type
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadDashboard();
        });
    }

    $scope.print = function(detail = null){

        var params = {
            start   : moment($scope.filter.start_date).format('YYYY-MM-DD 00:00:00'),
            end     : moment($scope.filter.end_date).format('YYYY-MM-DD 23:59:59'),
            type    : $scope.filter.type,
            paid    : $scope.filter.paid
        }

        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;
        if (detail)
            params.detailPrint            = true;

        params.sortable = $scope.sortable;
        params.hasTransfer = true;
        $scope.printing = true;

        $http.post(api+'report/financial_print',params).then(function(response){
        $scope.printing = false;
        var w = window.open('about:blank', '_blank');
        w.document.write(response.data);
        w.print();
        w.close();

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.showEntry = function(entry)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'show_entry.modal.html',
            controller     : 'financial_entry.show',
            size           : 'lg',
            resolve        : {
                entry: function(){
                    return angular.copy(entry)
                }
            }
        });

        modalInstance.result.then(function() {

        });
    }

    $scope.loadDashboard = function(){
        $scope.$parent.loading = true;
        $scope.chart = {
            data : [],
            labels : []
        }
        var params = {
            start           : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end             : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            type            : $scope.filter.type,
            paid            : $scope.filter.paid,
            hasTransfer     : true
        }
        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;

        $financial_entry.query(params).$promise.then(function(response){

            $scope.financials           = response.data;
            $scope.total                = response.total;
            $scope.total_paid           = response.total_paid;
            $scope.total_debit          = response.total_debit;
            $scope.total_credit         = response.total_credit;
            $scope.total_debit_paid     = response.total_debit_paid;
            $scope.total_credit_paid    = response.total_credit_paid;

            $scope.groupCategory = _.groupBy($scope.financials, 'category_name');

            $scope.totals               = [{
                'name'          : 'OS',
                'total'         : 90
            },{
                'name'          : 'Outros',
                'total'         : 10
            }];
            $scope.$parent.loading = false;
        });

    }

    $scope.searchEntries = function(){
        var str = document.getElementById('findEntries').value;
        if(str.length == 0){
            ngToast.danger({
                content: 'Sem dados para pesquisa!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/financial_entry/report_search_modal",
            controller     : 'report_searching',
            size           : 'lg',
            resolve        : {
                find: function(){
                return str;
                }
            }
            
        });

        modalInstance.result.then(function() {
            $scope.loadDashboard();
        });
    }

}]);

angular.module('financial_entry').
controller('dashboard', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModal',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModal) {

    $scope.selecteds = [];
    $scope.sortable = 'transacted_at';
    $scope.filter = {
        start_date: moment().startOf('year').toDate(),
        end_date: moment().endOf('year').toDate(),
        account : {},
        category : {},
        contact : {},
        type : $stateParams.type
    };

    $scope.selectEvents = {
        onSelectionChanged: function(item)
        {
            $scope.loadDashboard();
        }
    }

    $scope.$parent.loading = false;

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.selectEntry = function(entry){
        if($scope.selecteds[entry.id]){
            $scope.selecteds.splice(entry.id, 1);
        } else {
            $scope.selecteds[entry.id] = entry;
        }
    }

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });
    $financial_category.query({type: $stateParams.type, count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });
    $client.query({count: 10000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.searchContact = function(obj) {
        $scope.filter.contact = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                $scope.filter.contact.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            }
            $scope.loadDashboard();
        } else {
            setTimeout(function () {
                $scope.loadDashboard();
            }, 300);
        }
    }

    $scope.loadDashboard = function(){
        $scope.$parent.loading = true;
        $scope.chart = {
            data : [],
            labels : []
        }
        var params = {
            start           : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end             : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            type            : $scope.filter.type,
            paid            : $scope.filter.paid
        }
        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;

        $financial_entry.getCategoryToChart(params).then(function(response){
            $scope.data = response.data;

            $scope.chart.series = $scope.data.category;
            $scope.chart.labels = $scope.data.date;
            $scope.chart.data   = $scope.data.total;
            $scope.chartOptions = {
                legend : {
                    display : true,
                    position : 'bottom',
                        labels : {
                            usePointStyle : true
                        }
                },
                responsive : true,
                tooltips : {
                    custom:function(tooltipModel){
                        tooltipModel.x=25;
                        tooltipModel.y=-20;
                        tooltipModel.position = 'absolute';
                    }
                }
            }

            $scope.$parent.loading = false;
        });

    }
    $scope.loadDashboard();

    $scope.sortEntry = function(sort)
    {
        $scope.sortable = sort;
        $scope.filterEntries();
    }

    $scope.filterEntries = function(data)
    {
        if( $scope.sortable == 'transacted_at') {
            var filtered = _.sortBy(data, function(row){
                return moment(row.transacted_at).format('YYYYMMDD') + row.description;
            });
        }
        if( $scope.sortable == 'created_at') {
            var filtered = _.sortBy(data, function(row){
                return moment(row.created_at).format('YYYYMMDD') + row.description;
            });
        }

        return filtered;
    }

    $scope.payMany = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'pay_many.modal.html',
            controller     : 'financial_pay_many',
            size           : 'lg',
            resolve        : {
                entries: function(){
                    return angular.copy($scope.selecteds)
                },
                type : function(){
                    $scope.filter.type
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadDashboard();
        });
    }

    $scope.print = function(){

        var params = {
            start   : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end     : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            type    : $scope.filter.type,
            paid    : $scope.filter.paid
        }

        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;

        params.sortable = $scope.sortable;

        $scope.printing = true;

        $http.post(api+'report/financial_print',params).then(function(response){
        $scope.printing = false;
        var w = window.open('about:blank', '_blank');
        w.document.write(response.data);
        w.print();
        w.close();

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.showEntry = function(entry)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'show_entry.modal.html',
            controller     : 'financial_entry.show',
            size           : 'lg',
            resolve        : {
                entry: function(){
                    return angular.copy(entry)
                }
            }
        });

        modalInstance.result.then(function() {

        });
    }

}]);

angular.module('financial_entry').
controller('report.group', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModal', '$localStorage', '$financial_group',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModal, $localStorage, $financial_group) {

    $scope.listGroups = [];
    $scope.companies  = $localStorage.user.companies;
    $scope.sortable   = 'transacted_at';
    $scope.today      = new moment();
    $scope.hasCurrent_account = false;
    $scope.filter     = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate(),
        company_id : null,
        account : ''
    };
    $scope.totals = {
        debit : 0,
        credit : 0
    }

    $scope.selectEvents = {
        onSelectionChanged: function(item)
        {
            $scope.loadDashboard();
        }
    }

    $scope.printing = false;

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });

    $scope.loadAccount = function(account){
        if( account ){
            $financial_account.query({id: account, date: $scope.today.toISOString()}).$promise.then(function(response){
                $scope.current_account = response;
            }, function(error){
                console.log(error);
            });
        }

    };

    $financial_group.query({count: 10000}).$promise.then(function(response){
        $scope.groups = response.data;
        $scope.loadDashboard();
    }, function(error){
        console.log(error);
    });

    $scope.loadDashboard = function(){
        $scope.totals = {
            debit : 0,
            credit : 0
        }
        $scope.listGroups = [];
        var params = {
            start           : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end             : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            paid            : $scope.filter.paid
        }
        if( $scope.filter.company_id )
            params.company_id   = $scope.filter.company_id;
        else
            params.company_id   = 'all';

        if ($scope.filter.account) {
            params.financial_account_id = $scope.filter.account;
            if ($scope.filter.account != '') {
                $scope.loadAccount($scope.filter.account);
                $scope.hasCurrent_account = true;
            }
        }else{
            $scope.hasCurrent_account = false;
        }

        $financial_entry.query(params).$promise.then(function(response){
            $scope.financials           = response.data;
            for (var x = 0; x < $scope.groups.length; x++) {
                $scope.listGroups.push({
                    id          : $scope.groups[x].id,
                    name        : $scope.groups[x].name,
                    total       : 0,
                    categories  : [],
                    type        : $scope.groups[x].type
                });
                for (var y = 0; y < $scope.groups[x].categories.length; y++) {
                    var total = _.reduce(_.where( $scope.financials, {financial_category_id :  $scope.groups[x].categories[y].id}), function(memo, row){ return parseFloat(memo) + parseFloat(row.total); }, 0);
                    $scope.listGroups[x].categories.push({
                        id      : $scope.groups[x].categories[y].id,
                        name    : $scope.groups[x].categories[y].name,
                        total   : total
                    });
                    $scope.listGroups[x].total += parseFloat(total);
                }
                if( $scope.listGroups[x].type == 'D' )
                    $scope.totals.debit += $scope.listGroups[x].total;
                if( $scope.listGroups[x].type == 'C' )
                    $scope.totals.credit += $scope.listGroups[x].total;
            }
        });
    }

}]);

angular.module('financial_entry').
controller('boleto', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModal', '_', '$q',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModal, _, $q) {

    $scope.$parent.loading = false;
    $scope.sending = [];
    $scope.today = moment();
    $scope.percentage = 0;
    $scope.start_date = {
        opened: false
    }
    $scope.end_date = {
        opened: false
    }

    $scope.filter = {
        start_date      : $scope.today.startOf('month').toDate(),
        end_date        : $scope.today.endOf('month').toDate(),
        account         : {},
        category        : {},
        contact         : {},
        type            : 'C',
        transacted_day  : "",
        contract_id     : $stateParams.contract_id ? $stateParams.contract_id : null
    };

    $scope.selectedMonth = function(int){
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.filter.start_date = $scope.today.startOf('month').toDate();
        $scope.filter.end_date   = $scope.today.endOf('month').toDate();

        $scope.loadDashboard();
    }

    $scope.selectEvents = {
        onSelectionChanged: function(item)
        {
            $scope.loadDashboard();
        }
    }

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });
    $financial_category.query({count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });
    $client.query({count: 10000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.searchContact = function(obj) {
        $scope.filter.contact = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                $scope.filter.contact.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            }
            $scope.loadDashboard();
        } else {
            setTimeout(function () {
                $scope.loadDashboard();
            }, 300);
        }
    }

    $scope.loadDashboard = function(){
        $scope.loading = true;
        var params = {
            start           : $scope.filter.transacted_day ? moment($scope.filter.start_date).date($scope.filter.transacted_day).format('YYYY-MM-DD HH:mm:ss') : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end             : $scope.filter.contract_id ? moment($scope.filter.start_date).add(2, 'years').format('YYYY-MM-DD HH:mm:ss') : ( $scope.filter.transacted_day ? moment($scope.filter.end_date).date($scope.filter.transacted_day).format('YYYY-MM-DD HH:mm:ss') : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss') ),
            type            : $scope.filter.type
        }
        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;
        if( $scope.filter.contract_id )
            params.contract_id             = $scope.filter.contract_id;

        $financial_entry.query(params).$promise.then(function(response){
            $scope.financials           = response.data;
            $scope.percentageMakeBoleto();
            $scope.loading = false;

        }, function(error){
            $scope.loading = false;
        });
    }
    $scope.loadDashboard();

    $scope.filterEntries = function(data){
        var filtered = _.sortBy(data, function(row){
            return moment(row.transacted_at).format('YYYYMMDD') + (row.contact ? row.contact.external_code : '');
        });

        return filtered;
    }

    $scope.makeBoleto = function(){
        _.each($scope.financials, function(row, index){
            if(!row.transaction && (!$scope.sending[row.id] || $scope.sending[row.id] && $scope.sending[row.id] == 'danger') ){
                $scope.sending[row.id] = 'sending';
                $http.get(api+'client/'+row.contact.id+'/check_register').then(function(response){
                    $http.post(api+'financial_entry/'+row.id+'/make_boleto', {transacted_at: row.transacted_at}).then(function(response){
                        $scope.sending[row.id] = 'success';
                        $scope.financials[index].transaction = response.data;
                        $scope.percentageMakeBoleto();
                    }, function(error){
                        console.log(error);
                        $scope.sending[row.id] = 'danger';
                    });
                }, function(error){
                    console.log(error);
                    $scope.sending[row.id] = 'danger';
                });
            }
        });
    }

    $scope.print = function(){
        $scope.$parent.loading = true;
        var queue = [];
        var htmlArray = [];
        var html = '';
        var array = $scope.filterEntries($scope.financials);
        for (var i = 0; i < array.length; i++) {
            if(array[i].transaction){
                var x = new XMLHttpRequest();
                x.open("GET", 'https://cors-anywhere.herokuapp.com/'+(array[i].transaction.url ? array[i].transaction.url : array[i].transaction.boleto_url), false);
                x.onload = function(response) {
                    htmlArray[i] = response.target.responseText;
                }
                x.send();
            }
        }
        $scope.$parent.loading = false;
        for (var y = 0; y < htmlArray.length; y++) {
            while( !htmlArray[y] );
            html += htmlArray[y];
            html += '<div style="display: block; page-break-before: always;"></div>';
        }
        $("#printIframe").contents().find('html').html(html);
        setTimeout(function () {
            window.frames["printIframe"].focus();
            window.frames["printIframe"].print();
        }, 100);
    }

    $scope.percentageMakeBoleto = function(){
        var hasTransction = _.countBy($scope.financials, {transaction: null}).false;
        $scope.percentage =  hasTransction ? (100 * hasTransction )/$scope.financials.length : 0;
    }

    $scope.quickUpdatePerson = function(id){
        var resend = $scope.makeBoleto;
        $client.get({
            id: id
        }).$promise.then(function(data) {

            var modalInstance = $uibModal.open({
                animation  : true,
                templateUrl: server+"/views/m/client/quickUpdatePerson",
                size       : 'lg',
                backdrop   : 'static',
                keyboard   : false,
                controller: ['$rootScope','$state', '$scope', '$uibModalInstance', '$bulletin', '$http', '$client','$countryState','$city','$client_address', function($rootScope,$state, $scope, $uibModalInstance, $bulletin, $http, $client, $countryState, $city, $client_address) {

                    $scope.client = data;

                    $scope.states = [];
                    $scope.cities = [];

                    $scope.address = ($scope.client.address[0]) ? $scope.client.address[0] : {
                        zip_code  : '',
                        city_id : {},
                        state_id : {},
                        type: 'Cobrança'
                    };

                    $countryState.query({count:1000}).$promise.then(function(response){
                        for( var i = 0; i < response.data.length; i++){
                            $scope.states.push({
                                id: response.data[i].id,
                                title: response.data[i].name,
                                name: response.data[i].name,
                                abbreviation: response.data[i].abbreviation
                            });
                        }
                    });

                    $scope.$watch('address.zip_code', function(){
                        if($scope.address.zip_code != undefined && $scope.address.zip_code.length == 8){
                            $http.get(api+'client/getCEP/'+$scope.address.zip_code).then(function(response){
                                $scope.address.line1        = response.data.logradouro;
                                $scope.address.neighborhood = response.data.bairro;

                                // Escolher UF
                                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                                    $scope.address.state_id = response;
                                });

                                // Escolher Cidade
                                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                                    $scope.cities = response.data;
                                    $scope.address.city_id = response.data[0];
                                });

                            });
                        }
                    });

                    setTimeout(function () {
                        $scope.formQuickUPdatePerson.$setSubmitted();
                    }, 10);

                    $scope.confirm = function() {

                        if($scope.formQuickUPdatePerson.$invalid)
                        {
                            ngToast.danger({
                                content: 'Verifique todos os campos.',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            return false;
                        }

                        // Salvar endereço
                        if( $scope.address.id == undefined && $scope.address.zip_code.length == 8 ){
                            var id = data.id;
                            var address = $scope.address;
                            address.state_id = $scope.address.state_id.id;
                            address.city_id  = $scope.address.city_id.id;
                            address.type     = 'Correspondência';
                            $client_address.save({client_id:id},address).$promise.then(function(data) {});
                        }

                        $client.update({
                            id: $scope.client.id
                        }, $scope.client).$promise.then(function(data) {
                            resend();
                            $uibModalInstance.dismiss('cancel');
                        });
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                }]
            });

        });
    }

}]);

angular.module('financial_entry').
controller('financial_pay_many', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModalInstance', '_', '$q', 'entries', 'type',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModalInstance, _, $q, entries, type) {

    $scope.title        = 'Baixar várias';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Ok';

    $scope.type    = type;
    $scope.entries = entries;
    $scope.entries = _.where($scope.entries, {paid:0});

    $scope.payment = {
        type            : $scope.type,
        payment_method  : 'money',
        description     : 'Baixa multipla',
        received_at     : moment().toDate(),
        value           : _.reduce($scope.entries, function(memo, row){ return memo + parseFloat(row.total); }, 0),
        entries         : $scope.entries
    }

    $scope.confirm = function(){
        $financial_entry.payMany($scope.payment).then(function(response){
            $uibModalInstance.close();
        });
    }

    $scope.cancel = function(){
        $uibModalInstance.dismiss('cancel');
    }

}]);

angular.module('financial_entry').
controller('report_searching', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$stateParams', '$uibModal', 'find', '$uibModalInstance',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $stateParams, $uibModal, find, $uibModalInstance) {

    $scope.title = 'Pesquisa Detalhada de contas'
    $scope.searchEntries = find;
    $scope.showInModal = true;
    $scope.filter = {
        start_date   : moment().startOf('month').toDate(),
        end_date     : moment().endOf('month').toDate(),
        type         : $stateParams.type,
        searchingAll : find
    };

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.waiting = false;

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }


    $scope.showEntry = function(entry)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'show_entry.modal.html',
            controller     : 'financial_entry.show',
            size           : 'lg',
            resolve        : {
                entry: function(){
                    return angular.copy(entry)
                }
            }
        });

        modalInstance.result.then(function() {

        });
    }

    $scope.loadDashboard = function(){
        $scope.waiting = true;
        
        var params = {
            start           : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end             : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            type            : $scope.filter.type,
            hasTransfer     : true
        }

        if ($scope.filter.searchingAll) {
            params.searchingAll = $scope.filter.searchingAll;
        }

        $financial_entry.query(params).$promise.then(function(response){
            $scope.financials           = response.data;
            $scope.total                = response.total;
            $scope.waiting = false;
        });

    }
    $scope.loadDashboard();

    $scope.closeFinancialModal = function(){
        $uibModalInstance.close();
    }

}]);

angular.module('financial_entry').
factory("$financial_entry", function($resource, $q, $http) {
    var resource = $resource(api + 'financial_entry/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    resource.getRelateds = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/'+id+'/get_relateds').then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getTypes = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/get_types', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.cashFlow = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/cash_flow', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.lates = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/lates', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateStatusPaid = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'financial_entry/'+id+'/update_status_paid', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteEntry = function( id, data = {})
    {
        var deferred = $q.defer();
        $http.delete(api+'financial_entry/'+id, {params: data}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.sendBoleto = function( id, transaction_id, email )
    {
        var data = {
            transaction_id  : transaction_id,
            email           : email
        }
        var deferred = $q.defer();
        $http.post(api+'financial_entry/'+id+'/send_boleto', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeContract = function( array )
    {
        var deferred = $q.defer();
        $http.post(api+'financial_entry/make_contract', array).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.payMany = function( data )
    {
        var deferred = $q.defer();
        $http.post(api+'financial_entry/pay_many', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.payPartial = function( data )
    {
        var deferred = $q.defer();
        $http.post(api+'financial_entry/pay_partial', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeTransfer = function( data )
    {
        var deferred = $q.defer();
        $http.post(api+'financial_entry/make_transfer', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getTransfers = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/get_transfers', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getCategoryToChart = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/get_category_chart', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateCategory = function(params)
    {
        var deferred = $q.defer();
        $http.put(api+'financial_entry/update_category',{params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteManyEntrys = function(params)
    {
        var deferred = $q.defer();
        $http.put(api+'financial_entry/delete_many_entrys', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.paymentFinancial = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'financial_entry/report_cashier', {params:params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('financial_entry').
factory("$financial_account", function($resource, $q, $http) {
    var resource = $resource(api + 'financial_account/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});

angular.module('financial_entry').
factory("$financial_comment", function($resource, $q, $http) {
    var resource = $resource(api + 'financial_comment/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});


angular.module('financial_entry').
factory("$receiptType", function($resource, $q, $http) {
    var resource = $resource(api + 'receipt_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});
