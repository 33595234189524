'use strict';

angular.module('socket', []);
angular.module('socket')

//Here LoopBackAuth service must be provided as argument for authenticating the user
.factory('socket', function($location, socketFactory){

    var myIoSocket = io.connect(socketURI,{
        path: '/socket.io',
        transports: ['websocket']
    });

    mySocket = socketFactory({
        ioSocket: myIoSocket
    });

    return mySocket;

});
