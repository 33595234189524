angular.module('auth', ['satellizer', 'ngStorage', 'ngToast']);

angular.module('auth').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    //$urlRouterProvider.otherwise("/");

    // Now set up the states
    $stateProvider
    .state('auth', {
        url: "/auth",
        templateUrl: server + "/views/public/auth",
        controller: 'auth'
    } )
        
    .state('onboarding', {
        url: "/onboarding",
        templateUrl: server + "/views/public/onboarding",
        controller: 'auth'
    })

    .state('register_step', {
        url: "/register_step",
        templateUrl: server + "/views/public/register_step",
        controller: 'auth'
    })

    .state('signup', {
        url: "/signup",
        templateUrl: server + "/views/public/signup",
        controller: 'auth',
        resolve: {
            loginRequired: function($q, $location, $rootScope, $localStorage, $state, $auth) {
                var deferred = $q.defer();
                if ($auth.isAuthenticated()) {
                    if( !$localStorage.company.plan_active && location.pathname != '/cart'){
                        $location.path('/cart');
                    } else {
                        $location.path('/');
                    }
                    deferred.resolve();
                } else {
                    deferred.resolve();
                }
                return deferred.promise;
            }
        },
        ncyBreadcrumb: {
            label: 'Registrar'
        }
    });

}]);

angular.module('auth').controller('auth', ['$rootScope', '$scope', '$http', '$auth', '$state', '$localStorage', '$interpolate', '$compile', 'ngToast', '$uibModal', '$company', '$location', '$sce', '$plans', '$countryState', '$city',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $interpolate, $compile, ngToast, $uibModal, $company, $location, $sce, $plans, $countryState, $city) {

    $scope.step = 1;
    $scope.plans = [];
    $scope.nextStep = function() {
        $scope.step++;
    }
    $scope.goBack = function() {
        $scope.step--;
    }

    $scope.registerLoading  = false;
    $scope.cpfCnpjError     = false;
    $scope.emailError       = false;
    $scope.credentials = {
        remember: true
    };

    if( $location.search().hasOwnProperty('p') ){
        $localStorage.p = $location.search()['p'];
        $scope.credentials.p = $localStorage.p;
    }

    if( $location.search().hasOwnProperty('id') ){
        $localStorage.partner_code = $location.search()['id'];
        $scope.credentials.partner_code = $localStorage.partner_code;
    }

    if( $location.search().hasOwnProperty('type') ){
        $localStorage.type = $location.search()['type'];
        $scope.credentials.type = $localStorage.type;
    }

    if( $location.search().hasOwnProperty('lead') ){
        $localStorage.lead = $location.search()['lead'];
        $scope.credentials.lead = $localStorage.lead;
    }

    $plans.query().$promise.then(function(response){
        $scope.plans = response.data;
    });

    /**
     * Check if user is authenticated
     */
    $scope.isAuthenticated = $auth.isAuthenticated();
    $scope.user = $localStorage.user;
    if($scope.user)
        $scope.company =$localStorage.company;

    /**
     * Retrieve Menu
     */
    var getMenu = function() {
        var menu_url = $auth.isAuthenticated() ? server + '/views/m/menu' : server + '/views/public/menu';
        $http.get(menu_url).then(function(response) {
            $scope.menu_data = response.data;
        });
    }

    $scope.f = $localStorage.last_transaction;

    $rootScope.$on('account_payment', function(event, data){
        $scope.f = data;
    });

    getMenu();

    /**
     * Store credentials
     */


    /**
     * Authenticate user
     * @param string provider
     * @return bool
     */
    $scope.authenticate = function(provider) {
        $auth.authenticate(provider)
            .then(function(response) {
                $rootScope.$broadcast('auth:login-success');
            })
            .catch(function(response) {
                console.error(response);
            });
    };

    $scope.goToRegister = function() {
        $state.go('signup');
    }

    /**
     * Perform login action
     * @return bool
     */
    $scope.login = function() {
        if (!$scope.credentials) {
            $auth.setStorageType('sessionStorage');
        }
        $auth.login($scope.credentials).then(function(data) {
            $rootScope.$broadcast('auth:login-success');
        }, function(error) {

            ngToast.danger({
                content: 'Usuário ou senha incorretos',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    };

    /**
     * Listen when user is authenticated
     */
    $scope.$on('auth:login-success', function(e) {
        $http.get(api + 'user/profile').then(function(e) {
            $localStorage.user     = e.data;
            $scope.user            = e.data;

            $scope.isAuthenticated = $auth.isAuthenticated();
            $localStorage.companies = e.data.companies;
            $localStorage.company   = e.data.companies[0];
            $rootScope.companies    = e.data.companies;
            $scope.company          = $localStorage.company;

            if( $scope.company.plan_active ) {
                $state.go('home',{}, {reload: true});
            } else {
                $state.go('cart',{}, {reload: true});
            }
            getMenu();
        });
    });

    /**
     * Perform logout action
     */
    $scope.logout = function() {
        $http.get(server+'/logout').then(function(){},function(error){
            console.log(error);
        });
        $auth.logout().then(function() {
            $rootScope.$broadcast('auth:logout-success');
        });
    }

    /**
     * Listen when user logged out
     */
    $scope.$on('auth:logout-success', function(e) {
        console.log($localStorage);
        $localStorage.$reset();
        $scope.company = undefined;
        getMenu();
        $state.go('auth',{}, {reload: true});
        $scope.isAuthenticated = $auth.isAuthenticated();
    });

    $countryState.query({count:1000}).$promise.then(function(response){
        $scope.states = response.data;
    });

    $scope.getCep = function(){
        if( $scope.credentials.address_zip.length > 7 ){
            $scope.waiting = true;
            var url         = 'https://viacep.com.br/ws/'+$scope.credentials.address_zip+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.credentials.address                  = response.data.logradouro;
                    $scope.credentials.address_neighborhood     = response.data.bairro;

                    // Escolher UF
                    $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                        $scope.credentials.state_id = response.id;
                    });

                    // Escolher Cidade
                    $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                        $scope.cities = response.data;
                        $scope.credentials.city_id = response.data[0].id;
                    });

                    $scope.credentials.address_complement   = '';
                    $scope.credentials.address_number       = '';
                }
                $scope.waiting = false;
            }, function(error){
                $scope.waiting = false;
            });
        }
    };

    $scope.setPlanAndRegister = function( planId ) {
        $scope.credentials.p = planId;
        $scope.register($scope.credentials);   
    }

    $scope.register = function(credentials)
    {
        $scope.registerLoading = true;
        $http.post(api+'user/signup', credentials).then(function(response){
            $auth.login($scope.credentials).then(function(data) {
                $rootScope.$broadcast('auth:login-success');

            });
        }, function(error){
            console.log(error);
            $scope.registerLoading = false;
            switch (error.data.message) {
                case 'cpf_cnpj_already_exists':
                    $scope.cpfCnpjError = true;
                    ngToast.danger({
                        content: 'CPF/CNPJ já cadastrado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                case 'email_already_exists':
                    $scope.emailError = true;
                    ngToast.danger({
                        content: 'Email já cadastrado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                default:
                    ngToast.danger({
                        content: 'Erro ao criar cadastro',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
            }
        });
    }

    /**
     * TODO: verficar se tenho permissão pra acessar essa empresa...
     */
    $scope.changeCompany = function(company)
    {
        $scope.rebaseCompanies();

        $localStorage.company = $rootScope.company = $scope.company = company;

        if($state.current.name == 'choosecompany'){
            $state.go('evaluations');
        } else  {
            $state.reload();
        }
    }

    $scope.forgot = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'forgot.modal.html',
            controller     : 'forgot',
            size           : 'md',
        });

        modalInstance.result.then(function() {

        });
    }

    $rootScope.$on('has_new_message',function(){
        $scope.hasNewMessage = ($state.current.name == 'chat') ? false : true;
    });

    $rootScope.$on('clear_new_message_badge',function(){
        $scope.hasNewMessage = false;
    });



}]);

angular.module('auth').controller('forgot', ['$rootScope', '$scope', '$http', '$auth', '$state', '$localStorage', 'ngToast', '$uibModalInstance',
function($rootScope, $scope, $http, $auth, $state, $localStorage, ngToast, $uibModalInstance ) {

    $scope.title        = 'Recuperar senha';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Enviar';
    $scope.user         = { email : '' };

    $scope.requested    = false;

    $scope.request = function() {
        $http.post(api+'auth/request_password', $scope.user).then(function(response){
            $scope.requested = true;
        },function(error){
            $scope.toast('Email não encontrado', true);
        });
    }

    $scope.reset = function() {
        $http.post(api+'auth/reset_password', $scope.user).then(function(response){
            $scope.toast('Senha redefinida', false);
            $uibModalInstance.dismiss('cancel');
        },function(error){
            if( error.data.message == "code_invalid"){
                $scope.toast('Erro no codigo de verificação', true);
            }else{
                $scope.toast('Email não encontrado', true);
            }
        });
    }

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.toast = function(msg, error)
    {
        if( error ) {
            ngToast.danger({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        } else {
            ngToast.success({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    }

}]);

angular.module('auth').directive('compile', ['$compile', function($compile) {
    return function(scope, element, attrs) {
        scope.$watch(
            function(scope) {
                // watch the 'compile' expression for changes
                return scope.$eval(attrs.compile);
            },
            function(value) {
                // when the 'compile' expression changes
                // assign it into the current DOM
                element.html(value);

                // compile the new DOM and link it to the current
                // scope.
                // NOTE: we only compile .childNodes so that
                // we don't get into infinite loop compiling ourselves
                $compile(element.contents())(scope);
            }
        );
    };
}]);

angular.module('auth').directive('hoverClass', function () {
    return {
        restrict: 'A',
        scope: {
            hoverClass: '@'
        },
        link: function (scope, element) {
            element.on('mouseenter', function() {
                element.addClass(scope.hoverClass);
            });
            element.on('mouseleave', function() {
                element.removeClass(scope.hoverClass);
            });
        }
    };
});
