var config = angular.module('config', ['ui.router', 'ngFileUpload','angular-uuid']);

config.config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {
    $stateProvider
    .state('config', {
        url: "/config",
        templateUrl: server+"/views/m/config",
        controller: 'config',
        ncyBreadcrumb: {
            parent: 'home',
            label: 'configurações'
        }
    })

    .state('billing', {
        url: "/billing",
        templateUrl: server+"/views/m/config/billing",
        controller: 'billing',
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Faturamento'
        }
    })


}]);

config.controller('billing', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', '$company', '$uibModal','Upload', '$countryState', '$city', '$tax_regime',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $company, $uibModal, Upload, $countryState, $city, $tax_regime) {
    console.log('billing');
}]);

config.controller('config', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', '$company', '$uibModal','Upload', '$countryState', '$city', '$tax_regime','uuid', 'ngToast', '$sce', '$custom_input', '$suggestion',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $company, $uibModal, Upload, $countryState, $city, $tax_regime,uuid, ngToast, $sce, $custom_input, $suggestion) {
    
    $scope.editing = false;
    $scope.changeLabel = false;
    $scope.changeLabelAlert = false;
    $scope.showFormLabel = false;
    $scope.colorOptions = {
        disabled    : true,
        format      : 'hex',
        case        : 'upper',
        inputClass  : 'form-control'
    }
    $scope.description = '';
    $scope.natOpSuggestion = '';
    $scope.newNatOp = '';
    $scope.cid = '';
    $scope.dosage = '';

    $scope.toggleEdit = function(){
        $scope.editing = !$scope.editing;
        $scope.showFormLabel = !$scope.showFormLabel;
        $scope.colorOptions.disabled = !$scope.colorOptions.disabled;
    }

    $scope.scompany = {
        theme: {},
        address_zip  : '',
        city_id : {},
        state_id : {}
    }
    $scope.showInputPaymentsOther = false;
    $scope.newTypePaymentsOther = function() {
        $scope.showInputPaymentsOther = !$scope.showInputPaymentsOther;
    }

    $scope.getCidSuggestions = function(){
        $scope.cidSuggestionsList =[];
        $suggestion.query({type: 'cidDental'}).$promise.then(function(response){
            $scope.cidSuggestionsList = response;
        });
    }
    $scope.getCidSuggestions();

    $scope.addCidSuggestion = function(description, cidValue){
        if(description){
            var data = {
                description   : description,
                value   : cidValue,
                type          : 'cidDental'
            };
            $suggestion.post(data).$promise.then(function(response){
                $scope.getCidSuggestions();
                $scope.cid = '';
                ngToast.success({
                    content: 'Cadastrado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            }, function(error){
                ngToast.danger({
                    content: 'CID já cadastrado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }; 
    }

    $scope.getDosageSuggestions = function(){
        $scope.dosageSuggestionsList =[];
        $suggestion.query({type: 'dosageDental'}).$promise.then(function(response){
            $scope.dosageSuggestionsList = response;
        });
    }
    $scope.getDosageSuggestions();

    $scope.addDosageSuggestion = function(description, posoValue){
        if(description){
            var data = {
                description   : description,
                value         : posoValue,
                type          : 'dosageDental'
            };
            $suggestion.post(data).$promise.then(function(response){
                $scope.getDosageSuggestions();
                $scope.dosage = '';
                ngToast.success({
                    content: 'Cadastrado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            }, function(error){
                ngToast.danger({
                    content: 'Posologia já cadastrada',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }; 
    }

    $scope.getDescriptionSuggestions = function(){
        $scope.descriptionSuggestionsList =[];
        $suggestion.query({type: 'payment_other'}).$promise.then(function(response){
            $scope.descriptionSuggestionsList = response;
        });
    }
    $scope.getDescriptionSuggestions();

    $scope.addTypePaymentsOther = function(description){
        if(description){
            var data = {
                description   : description,
                type          : 'payment_other'
            };
            $suggestion.post(data).$promise.then(function(response){
                $scope.getDescriptionSuggestions();
                $scope.showInputPaymentsOther = false;
                ngToast.success({
                    content: 'Cadastrado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            }, function(error){
                ngToast.danger({
                    content: 'Tipo de pagamento já cadastrado.',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            });
        }    
    }

    $scope.urlFacebook = window.location.origin + '/' +  'get_xml_facebook' + '/' + $localStorage.company.hashid;

    $scope.certificate = {};

    $scope.taxRegimes = [];
    $scope.states = [];
    $scope.cities = [];
    $scope.label = [];
    $scope.listModel = [];
    $scope.inputList = [];
    $scope.input = [];
    $scope.menu_category = [];

    $scope.input.show_in_grid = true;

    $tax_regime.query({count:1000}).$promise.then(function(response){
        $scope.taxRegimes = response.data;
    });


    $scope.getListModel = function(){
        $http.get(api+'company/label').then(function(response){
            $scope.listModel = response.data;
        }, function(error){
            
        });
    }
    $scope.getListModel();

    $scope.getListInput = function(){  
        $custom_input.query({count:1000}).$promise.then(function(response){
            $scope.inputList = response;
        }, function(error){
            
        });
    }
    $scope.getListInput();
    
    $scope.createUUID = function() {
        $scope.scompany.uuid = uuid.v4();
    }

    $scope.configs = {
        vAliq_default : 0,
        indISS_default : '2',
        PIS_CST : '07',
        PIS_pPIS : 0,
        COFINS_CST : '07',
        COFINS_pCOFINS : 0,
        password : '',
        CSC : '',
        CSCid : '',
        tokenIBPT : ''
    }

    $company.get({id:$localStorage.company.id}).$promise.then(function(response){
        $scope.scompany = response;
        $scope.dateValid = moment($scope.scompany.invoice_configs.cert_valid_to).format('DD/MM/YYYY');
        
        var partesData = $scope.dateValid.split("/");
        var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
        var countDate = moment(data).subtract(15, 'days');
            if(data < new Date()){
                $scope.changeLabel = true;
            }
            if (countDate == new Date()) {
                $scope.changeLabelAlert = true;
            }

        $scope.scompany.theme = {id:$scope.scompany.theme}
        $scope.scompany.city_id = {id: $scope.scompany.city_id, name: $scope.scompany.city_name};
        $scope.scompany.state_id = {id: $scope.scompany.state_id, name: $scope.scompany.state_abbreviation};
        if( $scope.scompany.invoice_configs )
            $scope.configs = $scope.scompany.invoice_configs;

        if( $scope.scompany.crt == 1 ){
            $scope.configs.PIS_CST = !$scope.configs.PIS_CST ? '07' : $scope.configs.PIS_CST;
            $scope.configs.PIS_pPIS = !$scope.configs.PIS_pPIS ? 0 : $scope.configs.PIS_pPIS;
            $scope.configs.COFINS_CST = !$scope.configs.COFINS_CST ? '07' : $scope.configs.COFINS_CST;
            $scope.configs.COFINS_pCOFINS = !$scope.configs.COFINS_pCOFINS ? 0 : $scope.configs.COFINS_pCOFINS;
        }

        if ($scope.scompany.editable_menu.length > 0) {
            $scope.scompany.editable_menu[0].menu_categories = JSON.parse($scope.scompany.editable_menu[0].menu_categories);
            $scope.menu_category = $scope.scompany.editable_menu[0].menu_categories;
        }
    });

    $scope.getNatOpSuggestion = function(){
        $scope.newNatOp = ''; 
        $scope.natOpSuggestionList =[];
        $suggestion.query({type: 'invoice_natOp'}).$promise.then(function(response){
            $scope.natOpSuggestionList = response;
        });
    }
    $scope.getNatOpSuggestion();

    $scope.addNatOpSuggestion = function(description){
        if(description){
            var data = {
                description   : description,
                type          : 'invoice_natOp'
            };
            $suggestion.post(data).$promise.then(function(response){
                $scope.getNatOpSuggestion();
                ngToast.success({
                    content: 'Cadastrado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            }, function(error){
                ngToast.danger({
                    content: 'Natureza da operação já cadastrada',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }; 
    }

    $scope.themes = [
        {id:'blue', description: 'Azul (padrão)', color: '#1565C0'},
        {id:'light_grey', description:'Cinza Claro', color: '#F5F5F5'},
        {id:'grey', description:'Cinza', color: '#9E9E9E'},
        {id:'light_red', description:'Vemelho Claro', color: '#FFCDD2'},
        {id:'red', description:'Vemelho', color: '#F44336'},
        {id:'light_purple', description:'Roxo Claro', color: '#E1BEE7'},
        {id:'purple', description:'Roxo', color: '#9C27B0'},
        {id:'light_green', description:'Verde Claro', color: '#C8E6C9'},
        {id:'green', description:'Verde', color: '#4CAF50'},

        {id:'light_yellow', description:'Amarelo Claro', color: '#FFF9C4'},
        {id:'yellow', description:'Amarelo', color: '#FFEB3B'},

        {id:'black', description:'Preto', color: '#000000'},
        {id:'white', description:'Branco', color: '#ffffff'},

    ];

    $scope.pags = [
        {
            id: '01',
            description: 'Dinheiro'
        },
        {
            id: '02',
            description: 'Cheque'
        },
        {
            id: '03',
            description: 'Cartão de Crédito'
        },
        {
            id: '04',
            description: 'Cartão de Débito'
        },
        {
            id: '15',
            description: 'Boleto Bancário'
        },
        {
            id: '99',
            description: 'Outros'
        }
    ];

    $scope.dropdownTranslation = {
        buttonDefaultText       : 'Selecione',
        checkAll                : 'Todas',
        uncheckAll              : 'Nenhuma',
        selectionCount          : 'selecionada(s)',
        selectionOf             : '/',
        searchPlaceholder       : 'Pesquisar',
        dynamicButtonTextSuffix : 'selecionada(s)'
    };

    $scope.colorConfig = {
        template            : '<span class ="label" style ="background-color:{{option.color}}">&nbsp;&nbsp;&nbsp;</span> {{option.description}}',
        buttonClasses       : 'btn btn-default btn-block',
        scrollable          : true,
        scrollableHeight    : '200px',
        enableSearch        : false,
        styleActive         : true,
        showCheckAll        : false,
        showUncheckAll      : false,
        displayProp         : 'description',
        idProp              : 'id',
        selectionLimit      : 1,
        closeOnSelect       : true,
        smartButtonMaxItems : 1
    }

    $scope.changeCrt = function(){
        if( $scope.scompany.crt == 1 ){
            $scope.configs.PIS_CST = !$scope.configs.PIS_CST ? '07' : $scope.configs.PIS_CST;
            $scope.configs.PIS_pPIS = !$scope.configs.PIS_pPIS ? 0 : $scope.configs.PIS_pPIS;
            $scope.configs.COFINS_CST = !$scope.configs.COFINS_CST ? '07' : $scope.configs.COFINS_CST;
            $scope.configs.COFINS_pCOFINS = !$scope.configs.COFINS_pCOFINS ? 0 : $scope.configs.COFINS_pCOFINS;
        }
    }

    $scope.$watch('scompany.address_zip', function(){
        if($scope.scompany.address_zip != undefined && $scope.scompany.address_zip.length == 8){
            var url         = 'https://viacep.com.br/ws/'+$scope.scompany.address_zip+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                $scope.scompany.address              = response.data.logradouro;
                $scope.scompany.address_neighborhood = response.data.bairro;

                // Escolher UF
                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                    $scope.states = [];
                    $scope.states.push({
                               id: response.id,
                               name: response.name
                           });
                    $scope.scompany.state_id = response;
                });

                // Escolher Cidade
                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                    $scope.cities = [];
                    $scope.cities.push({
                        id: response.data[0].id,
                        name: response.data[0].name
                    });
                    $scope.scompany.city_id = $scope.cities[0];
                });

            });
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'Upload', '$http', 'ngToast', '$q',
            function($scope, $uibModalInstance, $company, Upload, $http, ngToast, $q) {

                $scope.title = 'Deseja atualizar as configurações da empresa?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var company               = angular.copy($scope.$parent.scompany);
                $scope.input              = false;
                company.$promise          = null;
                company.$resolve          = null;
                company.theme             = company.theme.id;
                company.city_id           = company.city_id.id;
                company.state_id          = company.state_id.id;
                $scope.input = null;

                $scope.confirm = function() {
                    $company.save({id: company.id}, company).$promise.then(function(response){
                        var array = [];
                        if($scope.$parent.scompany.image){
                            array.push(Upload.upload({
                                url: api+'company/'+company.id,
                                data: {image: $scope.$parent.scompany.image}
                            }).then(function (response) {

                            }));
                        }

                        if($scope.$parent.certificate.file){
                            array.push(Upload.upload({
                                url: api+'company/'+company.id,
                                data: {certificate: $scope.$parent.certificate.file[0]}
                            }).then(function (response) {
                            }, function (response) {
                                if(response.data.message.indexOf('certificate') !== -1){
                                    ngToast.danger({
                                        content: 'Certificado ou senha inválidos!',
                                        dismissOnTimeout: true,
                                        timeout: 5000
                                    });
                                    $uibModalInstance.dismiss('cancel');
                                }
                            }));
                        }

                        setTimeout(function(){
                            $q.all( array ).then(function(response){
                                ngToast.success({
                                    content: 'Empresa atualizada!',
                                    dismissOnTimeout: true,
                                    timeout: 5000
                                });
                                $uibModalInstance.dismiss('cancel');
                                $scope.$parent.toggleEdit();
                                var user = $localStorage.user;
                                user.companies[0] = company;
                                $localStorage.user = user;
                                location.reload();
                            });
                        },600);



                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao salvar empresa',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });


                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.addShipping = function(){
        $scope.scompany.company_shipments.push({
            'name'   : '',
            'show_shipping'   : 0,
            'value'   : '',
            'state'   : ''
        });
    }

    $scope.addMenu = function(){
        $scope.hideConfirmButton = true;
        var modalInstance = $uibModal.open({
            animation : true,
            templateUrl : '/views/m/company_ecommerce_component/modal-add-submenu',
            size : 'md',
            scope : $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'Upload', '$http', 'ngToast', '$q', '$category',
            function($scope, $uibModalInstance, $company, Upload, $http, ngToast, $q, $category) {

                $scope.title = 'Selecione a categoria';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.loadCategories = function(){
                    $scope.waiting = true;
                    $category.query({count: 1000, showEcommerce: true}).$promise.then(function(response){
                        $scope.categories = response.data;
                    });  
                    $scope.waiting = false;         
                }
                $scope.loadCategories();
                

                $scope.addCategory = function(category){
                    var equal = _.find($scope.$parent.menu_category, function(row){return row.category_id === category.id});
                    if (equal) {
                        ngToast.danger({
                            content: 'Categoria ja selecionada!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        category.add_menu = false;
                        return;
                    }
                    if(category.add_menu){
                        $scope.$parent.menu_category.push({
                            category_id   : category.id,
                            name          : category.name,
                            show_category : 0,
                            group_id      : category.group_id
                        });
                    }else{
                        var index = _.find($scope.$parent.menu_category, function(row){return row.category_id == category.id;}); 
                        $scope.$parent.menu_category.splice($scope.$parent.menu_category.indexOf(index), 1); 

                    }  
                }

                $scope.confirm = function() {
                    if($scope.$parent.menu_category.length > 0){
                        if ($scope.$parent.scompany.editable_menu.length > 0) {
                            $scope.$parent.scompany.editable_menu[0].menu_categories = $scope.$parent.menu_category;
                        }else{
                            $scope.$parent.scompany.editable_menu.push({
                                'name_item_menu'   : '',
                                'show_item_menu'   : 0,
                                'link_item_menu'   : '',
                                'menu_categories'  : $scope.$parent.menu_category
                            });
                        }
                    }else{
                        $scope.menu_category = [];
                    }
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        }); 
    }

    $scope.removeMenu = function(index){
        $scope.menu_category.splice(index, 1);
        $scope.scompany.editable_menu[0].menu_categories = $scope.menu_category;
    }

    $scope.statesShipping = [
        {title:'Acre', id:'AC'},
        {title:'Alagoas', id:'AL'},
        {title:'Amazonas', id:'AM'},
        {title:'Amapá', id:'AP'},
        {title:'Bahia', id:'BA'},
        {title:'Ceará', id:'CE'},
        {title:'Distrito Federal', id:'DF'},
        {title:'Espirito Santo', id:'ES'},
        {title:'Goiás', id:'GO'},
        {title:'Maranhão', id:'MA'},
        {title:'Minas Gerais', id:'MG'},
        {title:'Mato Grosso do Sul', id:'MS'},
        {title:'Mato Grosso', id:'MT'},
        {title:'Pará', id:'PA'},
        {title:'Paraíba', id:'PB'},
        {title:'Pernambuco', id:'PE'},
        {title:'Piauí', id:'PI'},
        {title:'Paraná', id:'PR'},
        {title:'Rio de Janeiro', id:'RJ'},
        {title:'Rio Grande do Norte', id:'RN'},
        {title:'Rondônia', id:'RO'},
        {title:'Roraima', id:'RR'},
        {title:'Rio Grande do Sul', id:'RS'},
        {title:'Santa Catarina', id:'SC'},
        {title:'Sergipe', id:'SE'},
        {title:'São Paulo', id:'SP'},
        {title:'Tocantins', id:'TO'}
    ];

    $scope.removeShipping = function(id){
        if( $scope.scompany.company_shipments.length > 0 )
        var index = _.find($scope.scompany.company_shipments, function(row){return row.id == id;});
        $scope.scompany.company_shipments.splice($scope.scompany.company_shipments.indexOf(index), 1);    
    }

    $scope.addTax = function(){
        $scope.scompany.taxes.push({
            'installments'  : $scope.scompany.taxes.length + 2,
            'tax'           : 0
        });
    }

    $scope.removeTax = function(){
        if( $scope.scompany.taxes.length > 0 )
            $scope.scompany.taxes.splice(($scope.scompany.taxes.length - 1), 1);
    }

    $scope.uploadCompanyBanner = function(){
        Upload.upload({
            url: api+'company/'+$scope.scompany.id+'/upload_banner',
            data: {
                image: $scope.scompany.banners[$scope.scompany.banners.length-1]
            }
        }).then(function (response) {
            $scope.scompany.banners[$scope.scompany.banners.length-1] = response.data;
            ngToast.success({
                content: 'Banner salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function (error) {
            ngToast.danger({
                content: 'Erro no formato da imagem',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }



    $scope.removeCompanyBanner = function(id){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company',
            function($scope, $uibModalInstance, $company) {

                $scope.title = 'Deseja realmente remover banner?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $company.deleteBanner(id, $scope.$parent.scompany.id).then(function(response){
                        $scope.$parent.scompany.banners = response;
                        ngToast.success({
                            content: 'Banner removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover banner',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addVoucher = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'voucher.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'ngToast',
            function($scope, $uibModalInstance, $company, ngToast) {

                $scope.title = 'Cupom';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';

                $scope.voucher = {
                    'discount_type' : 'V',
                    'rule_type'     : 'V',
                    'rule_min'      : 0,
                    'discount'      : 0
                };

                $scope.confirm = function() {
                    $company.storeVoucher($scope.voucher, $scope.$parent.scompany.id).then(function(response){
                        ngToast.success({
                            content: 'Cupom adicionado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.scompany.vouchers.push(response);
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao adicionar cupom',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.removeVoucher = function(index){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'ngToast',
            function($scope, $uibModalInstance, $company, ngToast) {

                $scope.title = 'Remover esse desconto?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $company.deleteVoucher($scope.$parent.scompany.vouchers[index].hashid, $scope.$parent.scompany.id).then(function(response){
                        ngToast.success({
                            content: 'Cupom removido',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.scompany.vouchers.splice(index, 1);
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover cupom',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.uploadFavicon = function() {
        if($scope.scompany.favicon){
            Upload.upload({
                url: api+'company/'+$scope.scompany.id+'/favicon',
                data: {favicon: $scope.scompany.favicon}
            }).then(function (response) {

            });
        }
    }

    $scope.cancelLabel = function(){
        $scope.label.newLabelName = '';
        $scope.label.file = '';
    }


    $scope.addLabel = function(){
        if(!$scope.label.newLabelName){
            ngToast.danger({
                content: 'Informe um nome para o arquivo',
                dismissOnTimeout: true,
                timeout: 5000
            });
        };
        if(!$scope.label.file){
            ngToast.danger({
                content: 'Selecione um arquivo para upload',
                dismissOnTimeout: true,
                timeout: 5000
            });
        };

        if(!$scope.label.quantity){
            ngToast.danger({
                content: 'Informe a quantidade de etiquetas do modelo',
                dismissOnTimeout: true,
                timeout: 5000
            });
        };

        Upload.upload({
            url: api+'company/'+$scope.scompany.id+'/label',
            data: {label: $scope.label.file,
                   name : $scope.label.newLabelName,
                   quantity : $scope.label.quantity
                  }
        }).then(function (response) {
            // location.reload();
        });
        $scope.label.newLabelName = '';
        $scope.label.file = '';
        $scope.getListModel();
    }

    $scope.deleteModel = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', function($scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja realmente remover o modelo de etiqueta?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $http.delete(api+'company/'+data.company_id+'/label/'+data.id).then(function(response){
                        
                        ngToast.success({
                            content: 'Modelo de etiqueta removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.getListModel();
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover modelo de etiqueta',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.showModel = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'model_label.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', function($scope, $uibModalInstance, $company) {

                $scope.name = data.name;
                $scope.label = data.label_model;

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.toggleEditCid = function(){
        $scope.new_cid = !$scope.new_cid;
    }

    $scope.toggleEditDosage = function(){
        $scope.new_dosage = !$scope.new_dosage;
    }

    $scope.toggleEditInput = function(){
        $scope.new_input = !$scope.new_input;
    }
   
    $scope.addInput = function(){
        if(!$scope.input.label){
            ngToast.danger({
                content: 'Informe um nome',
                dismissOnTimeout: true,
                timeout: 5000
            });
        };
        var params = {
            label     : $scope.input.label,
            show_in_grid  : $scope.input.show_in_grid
        }
        $custom_input.post(params).$promise.then(function(data){
            $scope.input = [];
            $scope.getListInput();

        }, function(error){
            ngToast.danger({
                content: 'Erro inexperado',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
        
        $scope.input = [];
        $scope.getListInput();
    }

    $scope.deleteInputCustom = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                $scope.title = 'Deseja realmente remover o campo?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';
                $scope.input = false;

                $scope.confirm = function() {
                    $http.delete(api+'custom_input/'+data.company_id+'/input/'+data.id).then(function(response){
                        
                        ngToast.success({
                            content: 'Campo removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.getListInput();
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover campo',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.deleteCidAndDosage = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                $scope.title = 'Deseja realmente remover?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';
                $scope.input = false;

                $scope.confirm = function() {
                    $http.delete(api+'suggestion/'+data.id).then(function(response){
                        
                        ngToast.success({
                            content: 'Campo removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.getCidSuggestions();
                        $scope.getDosageSuggestions();
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover campo',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);

angular.module('config').
factory("$custom_input", function($resource, $q, $http) {
    var resource = $resource(api + 'custom_input', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});

angular.module('config').
factory("$suggestion", function($resource, $q, $http) {
    var resource = $resource(api + 'suggestion', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        },
        'delete': {
            method: 'DELETE'
        }
    });

    return resource;
});
