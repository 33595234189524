angular.module('company_ecommerce_component', ['ui.router', 'ngFileUpload','angular-uuid']);

angular.module('company_ecommerce_component').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('company_ecommerce_component', {
        url         : "/company_ecommerce_component",
        templateUrl : server+"/views/m/company_ecommerce_component",
        controller  : 'company_ecommerce_component.controller',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Componentes Ecommerce'
        }
    })


}]);

angular.module('company_ecommerce_component').
controller('company_ecommerce_component.controller', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company_ecommerce_component', 'ngToast', '$ecommerce_component', '$uibModal', 'Upload', '$localStorage', '$sce', '$location', '$uibModalInstance',
function($scope, $http, $auth, $q, NgTableParams, $company_ecommerce_component, ngToast, $ecommerce_component, $uibModal, Upload, $localStorage, $sce, $location, $uibModalInstance) {

    $scope.company = $localStorage.company;
    
    if (!$location.search().hasOwnProperty('i')) {
        window.location = '/company_ecommerce_component?i=' + $scope.company.id;

        var element = document.getElementById('pageReact');
        element.src = element.src;
        
    }

    $scope.colorOptions = {
        format      : 'hex',
        case        : 'upper',
        inputClass  : 'form-control'
    }

    $scope.getUrl = function () {
        var url = 'http://bornconcept.web2131.uni5.net?i=' + $scope.company.id;
        return $sce.trustAsResourceUrl(url);
    }

    $scope.companyEcommerceComponents = [];
    $scope.showComponent = null;

    $scope.getComponets = function(){
        $company_ecommerce_component.query().$promise.then(function(response){
            if( response.length > 0 ){
                response.forEach(element => {
                    element.config = JSON.parse(element.config);
                });
            }
            $scope.companyEcommerceComponents = response;
        });
    }
    $scope.getComponets();

    $scope.doShowComponent = function(component) {
        $scope.showComponent = component;
    }

    $scope.addInArray = function(position, propertyName){
        var component = $scope.companyEcommerceComponents.find((row) => row.position == position);

        if (component) {
            if(!component.config[propertyName])
                component.config[propertyName] = [];

            if (_.find(component.ecommerce_component.properties, function(row){return row.name === 'categories'})) {
                var modalInstance = $uibModal.open({
                    animation : true,
                    templateUrl : '/views/m/company_ecommerce_component/modal-add-submenu',
                    size : 'md',
                    scope : $scope,
                    controller: ['$scope', '$uibModalInstance', '$company', 'Upload', '$http', 'ngToast', '$q', '$category',
                    function($scope, $uibModalInstance, $company, Upload, $http, ngToast, $q, $category) {
        
                        $scope.title = 'Selecione a categoria';
                        $scope.cancel_text = 'Não Vincular categoria';
                        $scope.success_text = 'Vincular categoria';
                        
                        $scope.loadCategories = function(){
                            $scope.waiting = true;
                            $category.query({count: 1000}).$promise.then(function(response){
                                $scope.categories = response.data;
                            });  
                            $scope.waiting = false;         
                        }
                        $scope.loadCategories();

                        var categories = [];
                        $scope.addCategory = function(category){
                            categories.push(category);
                        }
        
                        $scope.confirm = function() {
                            $uibModalInstance.close(categories);
                        }
                        
                        $scope.cancel = function() {
                            $uibModalInstance.close(categories = null);
                        };  
                        
                    }]
                });
                modalInstance.result.then(function(data) {
                    component.config[propertyName].push({
                        "config"                    : {
                            items: data
                        }
                    });
                });
                
            }else{
                component.config[propertyName].push({
                    "config"                    : {}
                });
            }            
        }
       
    }

    $scope.addComponent = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company', '$ecommerce_component',
            function($scope, $uibModalInstance, $user, Upload, $q, $company, $ecommerce_component) {

                $scope.title = 'Selecione um componente';
                $scope.success_text = 'Confirmar';

                $scope.data = [];

                $ecommerce_component.query({count:20}).$promise.then(function(response){
                    $scope.data = response.data;
                });

                $scope.select = function(componentId){
                    $uibModalInstance.close(componentId);
                }

            }]
        });

        modalInstance.result.then(function(componentId) {
            $ecommerce_component.get({id:componentId}).$promise.then(function(response){
                var lastPosition = null;
                if( $scope.companyEcommerceComponents.length > 0 ){
                    lastPosition = $scope.companyEcommerceComponents.reduce((prev, curr) => prev.position > curr.position ? prev : curr);
                }
                $scope.companyEcommerceComponents.push({
                    "ecommerce_component_id"    : response.id,
                    "name"                      : response.name,
                    "position"                  : lastPosition ? parseInt(lastPosition.position) + 1 : 0,
                    "config"                    : {
                        "ecommerce_component_id"       : response.id
                    },
                    "ecommerce_component"       : response
                });
            });
        });
    }

    $scope.removeCategory = function(item, index){
        item.config.items.splice(index, 1);
    }

    $scope.addCategory = function(item){
        var modalInstance = $uibModal.open({
            animation : true,
            templateUrl : '/views/m/company_ecommerce_component/modal-add-submenu',
            size : 'md',
            scope : $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'Upload', '$http', 'ngToast', '$q', '$category',
            function($scope, $uibModalInstance, $company, Upload, $http, ngToast, $q, $category) {

                $scope.title = 'Selecione a categoria';
                $scope.cancel_text = 'Não Vincular categoria';
                $scope.success_text = 'Vincular categoria';
                
                $scope.loadCategories = function(){
                    $scope.waiting = true;
                    $category.query({count: 1000}).$promise.then(function(response){
                        $scope.categories = response.data;
                    });  
                    $scope.waiting = false;         
                }
                $scope.loadCategories();

                var categories = [];
                $scope.addCategory = function(category){
                    categories.push(category);
                }

                $scope.confirm = function() {
                    _.each(categories, function(row){
                        item.config.items.push(row);
                    })
                    $uibModalInstance.close();
                }
                
                $scope.cancel = function() {
                    $uibModalInstance.close();
                };  
                
            }]
        });
    }

    $scope.uploadImage = function(item, name, where, position, group = null, itemPosition = null, itemName = null) {
        Upload.upload({
            url: api+'company_ecommerce_component/upload_image',
            data: {file : item, name : name+itemName+itemPosition}
        }).then(function(response) {
            switch (where) {
                case 'component':
                    var component = $scope.companyEcommerceComponents.find((row) => row.position == position);
                    component.config[name] = response.data;
                break;
                case 'item_group':
                    var component = $scope.companyEcommerceComponents.find((row) => row.position == position);
                    component.config[name][itemPosition].config[itemName] = response.data;
                break;
            }
        });
    }

    $scope.removeImage = function(item, name, where, position, group = null, itemPosition = null, itemName = null) {
        switch (where) {
            case 'component':
                var component = $scope.companyEcommerceComponents.find((row) => row.position == position);
                $company_ecommerce_component.removeImage({name: component.config[name]}).then(function(response) {
                    component.config[name] = null;
                });
            break;
            case 'item_group':
                var component = $scope.companyEcommerceComponents.find((row) => row.position == position);
                $company_ecommerce_component.removeImage({name: component.config[name][itemPosition].config[itemName]}).then(function(response) {
                    component.config[name][itemPosition].config[itemName] = null;
                });
            break;
        }

    }

    $scope.save = function() {
        $company_ecommerce_component.save({data: $scope.companyEcommerceComponents}).$promise.then(function(response){
            $scope.getComponets();
            var element = document.getElementById('pageReact');
            element.src = element.src;
        });
    }

    $scope.reorder = function(flow, position){
        if( flow == 'up'){
            if( position == 0 )
                return;
            var indexComponentUp    = $scope.companyEcommerceComponents.findIndex((row) => row.position == parseInt(position) - 1);
            var indexComponent      = $scope.companyEcommerceComponents.findIndex((row) => row.position == position);

            $scope.companyEcommerceComponents[indexComponentUp].position    = parseInt($scope.companyEcommerceComponents[indexComponentUp].position) + 1;
            $scope.companyEcommerceComponents[indexComponent].position      = parseInt($scope.companyEcommerceComponents[indexComponent].position) - 1;
        }
        if( flow == 'down'){
            var indexComponentDown  = $scope.companyEcommerceComponents.findIndex((row) => row.position == parseInt(position) + 1);
            var indexComponent      = $scope.companyEcommerceComponents.findIndex((row) => row.position == position);

            if( indexComponentDown == -1 )
                return;

            $scope.companyEcommerceComponents[indexComponentDown].position = parseInt($scope.companyEcommerceComponents[indexComponentDown].position) - 1;
            $scope.companyEcommerceComponents[indexComponent].position = parseInt($scope.companyEcommerceComponents[indexComponent].position) + 1;
        }

        $scope.companyEcommerceComponents =  $scope.companyEcommerceComponents.sort(function (a, b) { return a.position < b.position ? -1 : 1; });
    }

    $scope.removeComponent = function(position) {
        var indexComponent      = $scope.companyEcommerceComponents.findIndex((row) => row.position == position);
        $scope.companyEcommerceComponents.forEach((item, i) => {
            if(item.position > position){
                item.position = item.position - 1;
            }
        });
        $scope.companyEcommerceComponents.splice(indexComponent, 1);
    }

    $scope.rename = function(position) {
        $scope.inputComponentName = position
    }
}]);

angular.module('company_ecommerce_component').
factory("$company_ecommerce_component", function($resource, $q, $http) {
    var resource = $resource(api + 'company_ecommerce_component/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.removeImage = function(data)
    {
        var deferred = $q.defer();
        $http.put(api+'company_ecommerce_component/remove_image', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
