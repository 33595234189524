angular.module('shopping_cart', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('shopping_cart').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('shopping_cart', {
        url         : "/scart",
        templateUrl : server+"/views/m/shopping_cart",
        controller  : 'shopping_cart.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Ofertas'
        }
    });

}]);

/**
 * List Controller
 */
angular.module('shopping_cart').
controller('shopping_cart.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams',
function($scope, $http, $auth, $q, NgTableParams) {

}]);
