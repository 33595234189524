angular.module('modality', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('modality').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('modality', {
        url         : "/modality",
        templateUrl : server+"/views/m/modality",
        controller  : 'modality.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Modalidades'
        }
    })

    .state('modality_create', {
        url         : "/modality/create",
        templateUrl : server+"/views/m/modality/create",
        controller  : 'modality.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'modality',
            label: 'Adicionar'
        }
    })

    .state('modality_show', {
        url         : "/modality/:id",
        templateUrl : server+"/views/m/modality/show",
        controller  : 'modality.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'modality',
            label: '{{modality.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('modality').
controller('modality.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$modality', '$state',
function($scope, $http, $auth, $q, NgTableParams, $modality, $state) {

  $scope.tableParams = new NgTableParams({
      page: 1,
      count: 10
  }, {
      counts: [],
      filterDelay: 0,
      getData: function(params) {
          return $modality.query(params.url()).$promise.then(function(data) {
              console.log(data);
              // return false;
              params.total(data.total);
              return data.data;
          });
      }
  });

}]);


/**
 * Create Controller
 */
angular.module('modality').
controller('modality.create', ['$scope', '$state', '$modality', '$department', 'ngToast', '$localStorage',
function($scope, $state, $modality, $department, ngToast, $localStorage) {
  $scope.save = function() {
    $modality.save($scope.modality).$promise.then(function(data) {
        $state.go('modality_show', {
            id: data.id
        });
    });
  }
}]);

/**
 * Show, Edit, Delete Controller
 */
 angular.module('modality').
 controller('modality.show', ['$modality', '$scope', '$stateParams', '$uibModal', '$modality', 'NgTableParams',
 function($modality, $scope, $modalityParams, $uibModal, $modality, NgTableParams) {
   $scope.editing = false;

   $scope.toggleEdit = function() {
       $scope.editing = !$scope.editing;
   }

   $modality.get({
       id: $modalityParams.id
   }).$promise.then(function(data) {
     $scope.modality = data;
   }, function(error) {
       if (error.status == 404) {
           $state.go('notFound');
       }
   });


      $scope.confirmUpdate = function() {
          var modalInstance = $uibModal.open({
              animation: true,
              templateUrl: 'confirm.modal.html',
              size: 'sm',
              scope: $scope,
              controller: ['$scope', '$uibModalInstance', '$modality',
              function($scope, $uibModalInstance, $modality) {
                  $scope.title = 'Deseja atualizar?';
                  $scope.cancel_text = 'Cancelar';
                  $scope.success_text = 'Concluído';

                  var modality = $scope.$parent.modality;
                  $scope.confirm = function() {
                      $modality.update({
                          id: modality.id
                      }, modality).$promise.then(function(data) {
                          $uibModalInstance.dismiss('cancel');
                          $scope.$parent.toggleEdit();
                      })
                  }

                  $scope.cancel = function() {
                      $uibModalInstance.dismiss('cancel');
                  };

              }]
          });
      };

      $scope.confirmDelete = function() {
          var modalInstance = $uibModal.open({
              animation: true,
              templateUrl: 'confirm.modal.html',
              size: 'sm',
              scope: $scope,
              controller: ['$modality', '$scope', '$uibModalInstance', '$modality',
              function($modality, $scope, $uibModalInstance, $modality) {

                  $scope.title = 'Deseja remover esse registro?';
                  $scope.cancel_text = 'Não';
                  $scope.success_text = 'Sim';

                  var modality = $scope.$parent.modality;

                  $scope.confirm = function() {
                      $modality.delete({id:modality.id}).$promise.then(function(data){
                          $uibModalInstance.dismiss('cancel');
                          $modality.go('modality');
                      })
                  }

                  $scope.cancel = function() {
                      $uibModalInstance.dismiss('cancel');
                  };

              }]
          });
      };

}]);


/**
 * Factory
 */
angular.module('modality').
factory("$modality", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'modality/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
// console.log(resource);
    return resource;

});
