angular.module('class_group', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('class_group').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('class_group', {
        url         : "/class_group",
        templateUrl : server+"/views/m/class_group",
        controller  : 'class_group.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Turmas'
        }
    })

    .state('class_group_create', {
        url         : "/class_group/create",
        templateUrl : server+"/views/m/class_group/create",
        controller  : 'class_group.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'class_group',
            label: 'Adicionar'
        }
    })

    .state('class_group_show', {
        url         : "/class_group/:id",
        templateUrl : server+"/views/m/class_group/show",
        controller  : 'class_group.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'class_group',
            label: '{{class_group.name}}'
        }
    })

}]);

/**
* List Controller
*/
angular.module('class_group').
controller('class_group.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$class_group', '$state',
function($scope, $http, $auth, $q, NgTableParams, $class_group, $state) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 15
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $class_group.query(params.url()).$promise.then(function(data) {
                data.data.map(function(classGroup){
                    classGroup.enrollments_count = classGroup.enrollments.length;
                });
                params.total(data.total);
                return data.data;
            });
        }
    });

}]);


/**
* Create Controller
*/
angular.module('class_group').
controller('class_group.create', ['$scope', '$state', '$class_group', '$department', 'ngToast', '$localStorage', '$modality', '$uibModal', '$users', 'NgTableParams',
function($scope, $state, $class_group, $department, ngToast, $localStorage, $modality, $uibModal, $users, NgTableParams,) {

    $users.get({count: 1000}).$promise.then(function(data) {
        $scope.users = data.data;
    });
    $modality.get().$promise.then(function(data) {
        $scope.modalities = data.data;
    });
    $scope.days_week = [{"day":"1","start_hour":0}];
    $scope.instructors = [];
    $scope.add_day_week = function() {
        $scope.days_week.push({"day":"1","start_hour":0});
    }
    $scope.del_day_week = function(key) {
        $scope.days_week.splice(key, 1);
    }
    $scope.add_instructor = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$class_group', '$users',
            function($scope, $uibModalInstance, $class_group, $users) {
                $scope.title = 'Selecione um Instrutor';
                $scope.title_name = 'Nome';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.data = $scope.$parent.users;

                $scope.select = function(id){
                    $users.get({id: id}).$promise.then(function(data) {
                        $scope.instructors.push(data.data);
                        $uibModalInstance.dismiss('cancel');
                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
    }
    $scope.save = function() {
        $scope.class_group.days_week = $scope.days_week;
        $scope.class_group.instructors = $scope.instructors;
        $class_group.save($scope.class_group).$promise.then(function(data) {
            $state.go('class_group_show', {
                id: data.id
            });
        });
    }
}]);

/**
* Show, Edit, Delete Controller
*/
angular.module('class_group').
controller('class_group.show', ['$class_group', '$scope', '$stateParams', '$uibModal', '$class_group', 'NgTableParams', '$modality', '$users',
function($class_group, $scope, $class_groupParams, $uibModal, $class_group, NgTableParams, $modality, $users) {
    $scope.editing = false;
    $scope.waiting = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $modality.get().$promise.then(function(data) {
        $scope.modalities = data.data;
    });

    $scope.loadClassGroup = function(){
        $scope.waiting = true;
        $class_group.get({
            id: $class_groupParams.id
        }).$promise.then(function(data) {
            $scope.instructors = [];
            for (var i = 0; i < data.instructors.length; i++) {
                data.instructors[i].users.user_id = data.instructors[i].users.id;
                $scope.instructors.push(data.instructors[i].users);
            }
            $scope.days_week = data.schedule;
            $scope.class_group = data;
            if ($scope.class_group.enrollments.length > 0) {
                _.each($scope.class_group.enrollments, function(row){
                    if (row.client) {
                        const today = new Date();
                        row.client.age = row.client.birthday ? $scope.calcAge(new Date(row.client.birthday), today) : null;
                    }
                })
            }
            $scope.waiting = false;

        }, function(error) {
            $scope.waiting = false;
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }
    $scope.loadClassGroup();


    $users.get({count: 1000}).$promise.then(function(data) {
        $scope.users = data.data;
    });

    $scope.deleteInstructor = function(key) {
        $scope.instructors.splice(key, 1);
    }
    $scope.del_day_week = function(key) {
        $scope.days_week.splice(key, 1);
    }
    $scope.add_day_week = function() {
        $scope.id_day_week = $scope.days_week[$scope.days_week.length-1];
        $scope.days_week.push({"id":$scope.id_day_week ? $scope.id_day_week.id : 1,"day":"1","start_hour":0});
    }

    $scope.add_instructor = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$class_group', '$users',
            function($scope, $uibModalInstance, $class_group, $users) {
                $scope.title = 'Selecione um Instrutor';
                $scope.title_name = 'Nome';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.data = $scope.$parent.users;

                $scope.select = function(id){
                    $users.get({id: id}).$promise.then(function(data) {
                        $scope.instructors.push(data.data);
                        $uibModalInstance.dismiss('cancel');
                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$class_group',
            function($scope, $uibModalInstance, $class_group) {
                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var class_group = $scope.$parent.class_group;
                class_group.days_week = $scope.$parent.days_week;
                class_group.instructors = $scope.$parent.instructors;
                $scope.confirm = function() {
                    $class_group.update({
                        id: class_group.id
                    }, class_group).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$class_group', '$scope', '$uibModalInstance', '$class_group',
            function($class_group, $scope, $uibModalInstance, $class_group) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var class_group = $scope.$parent.class_group;

                $scope.confirm = function() {
                    $class_group.delete({id:class_group.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $class_group.go('class_group');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.historicCheckIn = function(data) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/class_group/historic_check_in",
            size: 'lg',
            scope: $scope,
            controller: ['$class_group', '$scope', '$uibModalInstance', '$class_group', '$check_in_class_group',
            function($class_group, $scope, $uibModalInstance, $class_group, $check_in_class_group) {
                $scope.checkInClassGroup = [];
                $scope.title = 'Histórico de chamadas';
                var dateNow = new Date();
                $scope.dayWeek = dateNow.getDay();
                // $scope.start_hour = '';
                $scope.class = data;
                $scope.start_date = { opened: false };
                $scope.end_date   = { opened: false };
                $scope.filter = {
                    start_date  : moment().startOf('date').subtract(30, "days").toDate(),
                    end_date    : moment().startOf('date').toDate(),
                    // start_hour  : $scope.start_hour,
                    day         : $scope.dayWeek,
                    class_group : $scope.class.id,
                    page: 1,
                    count: 100
                };

                $scope.applyFilter = function(){
                    $check_in_class_group.getCheckInClassGroup($scope.filter).then(function(response){
                        $scope.checkInClassGroup = response.check_in;
                    });
                };

                $scope.show = function(data){
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: server+"/views/m/check_in_class_group/show_modal",
                        size: 'lg',
                        scope: $scope,
                        controller: ['$class_group', '$scope', '$uibModalInstance', '$class_group', '$check_in_class_group',
                        function($class_group, $scope, $uibModalInstance, $class_group, $check_in_class_group) {

                            $check_in_class_group.checkInChecked(data).then(function(response){
                                $scope.check_in_checked = response;
                            });
                            $scope.getCheck = function(check){
                                return check    
                            };
                            $scope.updateCheck = function(check){
                                return !check    
                            };
                            $scope.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
            
                        }]
                    });

                };

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.calcAge = function(birthday, today){
        var age = today.getFullYear() - birthday.getFullYear();
        if ( new Date(today.getFullYear(), today.getMonth(), today.getDate()) < 
             new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate()) )
             age--;
        return age;
    }

}]);

/**
* Factory
*/
angular.module('class_group').
factory("$class_group", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'class_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;

});
angular.module('class_group').
factory("$modality", function($resource, $q, $http) {
    var resource = $resource(api + 'modality/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});
