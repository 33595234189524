angular.module('roles', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);

angular.module('roles').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

        .state('roles', {
        url         : "/roles",
        templateUrl : server+"/views/m/roles",
        controller  : 'role.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'papéis'
        }
    })

    .state('roles_create',  {
        url         : "/roles/create",
        templateUrl : server+"/views/m/roles/create",
        controller  : 'role.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'roles',
            label: '{{role.display_name}}'
        }
    })

    .state('roles_show', {
        url         : "/roles/:id",
        templateUrl : server+"/views/m/roles/show",
        controller  : 'role.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'roles',
            label: '{{role.display_name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('roles').
controller('role.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$roles', function($scope, $http, $auth, $q, NgTableParams, $roles) {
   
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10000
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $roles.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                console.log(data.data);
                return data.data;
            });
        }
    });
    
}]);

/**
 * Create Controller
 */
angular.module('roles').
controller('role.create', ['$scope', '$state', '$roles', 'NgTableParams', '$permission', function($scope, $state, $roles, NgTableParams, $permission) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10000,
        group: "group",
    }, {
        counts: [],
        filterDelay: 0,
        groupOptions: {
            isExpanded: true
        },
        getData: function(params) {
            return $permission.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.save = function() {
        $roles.save($scope.role).$promise.then(function(data) {
            $state.go('roles_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('roles').
controller('role.show', ['$state', '$scope', '$stateParams', '$uibModal', '$roles', '$permission', 'NgTableParams', function($state, $scope, $stateParams, $uibModal, $roles, $permission, NgTableParams) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10000,
        role: $stateParams.id,
        group: "group",
    }, {
        counts: [],
        filterDelay: 0,
        groupOptions: {
            isExpanded: true
        },
        getData: function(params) {
            return $permission.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $roles.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.role = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$roles', function($scope, $uibModalInstance, $roles) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var role = $scope.$parent.role;

                $scope.confirm = function() {
                    $roles.update({
                        id: role.id
                    }, role).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$roles', function($state, $scope, $uibModalInstance, $roles) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var role = $scope.$parent.role;

                $scope.confirm = function() {
                    $roles.delete({id:role.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('roles');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('roles').
factory("$roles", function($resource) {
    var resource = $resource(api + 'role/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module('roles').
factory("$permission", function($resource) {
    var resource = $resource(api + 'permission/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
