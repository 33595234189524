angular.module('chat', ['ui.router', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('chat').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
        .state('chat', {
            url: "/chat",
            templateUrl: server + "/views/m/chat",
            resolve: {
                loginRequired: loginRequired
            },
            ncyBreadcrumb: {
                parent: 'home',
                label: 'Atendimentos'
            }
        })

        .state('chat_create', {
            url: "/chat/create",
            templateUrl: server + "/views/m/chat/create",
            controller: 'chat.create',
            resolve: {
                loginRequired: loginRequired
            },
            ncyBreadcrumb: {
                parent: 'chat',
                label: 'Adicionar'
            }
        });
}]);

function chatController($rootScope, $scope, $http, $auth, $q, NgTableParams, $chat, _, $timeout, $localStorage, $uibModal, $chat_status, $users,socket, $state){
    const new_chat_sound = server+'/fx/new_protocol.mp3';
    const new_message_sound = server+'/fx/new_message.mp3';

    $rootScope.$broadcast('clear_new_message_badge');

    $scope.init = function(is_dock)
    {
        /**
         ******************
         *** CHAT
         ******************
         */
        $scope._                 = _;
        $scope.active            = 0;
        $scope.loadingMessages   = false;
        $scope.conversations     = [];
        $scope.message           = "";
        $scope.new_chats         = [];
        $scope.chats             = [];
        $scope.customer_services = [];

        $scope.open_mini_chats   = [];
        $scope.online_users      = [];

        $scope.user   = $localStorage.user;

        $scope.$on('socket:error', function (ev, data) {

        });

        socket.on('newChat', function(data) {
            switch (data.type) {
                case 'chat':
                    if(is_dock)
                    {
                        var i = _.findWhere($scope.customer_services, { id: data.id });
                        if(!i){
                            var audio = new Audio(new_chat_sound);
                            audio.play();
                            $scope.customer_services.unshift(data);
                        }
                        $scope.$apply();
                    }
                break;
                case 'customer_service':
                    if(!is_dock){
                        var i = _.findWhere($scope.customer_services, { id: data.id });
                        if(!i){
                            var audio = new Audio(new_chat_sound);
                            audio.play();
                            $scope.customer_services.unshift(data);
                        }
                        $scope.$apply();
                    }
                break;
            }
        }.bind(this));

        socket.on('user-online', function(id) {
            $scope.online_users = _.union($scope.online_users, [id]);
            $scope.$apply();
        });

        socket.on('online_users', function(users) {
            $scope.online_users = _.union($scope.online_users, users);
            $scope.$apply();
        });

        socket.on('user-offline', function(id) {
            setTimeout(function () {
                var i = _.indexOf($scope.online_users, id);
                if(id && i){
                    $scope.online_users.splice(i,1);
                    $scope.$apply();
                }
            }, 500);
        });

        socket.on("receiveMessage", function(data) {

            switch (data.chat.type) {
                case 'chat':
                    //console.log('e aqui '+ is_dock);
                    if(is_dock)
                    {
                        // ver se o chat esta aberto
                        var chat = _.findWhere($scope.chats, {id:data.chat.id});
                        if(chat){

                            chat.last_message_date = new Date();
                            chat.last_message      = data.message;

                            $scope.updateConversations(chat,data.message,data.sender_id);

                            chat.last_message_date = new Date();
                            var last = _.last(chat.chats);
                            var index = _.indexOf(chat.chats, last);

                            if(data.sender_id != $localStorage.user.id)
                            {

                                if(!chat.active){
                                    //$rootScope.site_title = '(1) ';
                                    chat.new_message = true;
                                }

                                if (last && last.sender_id == data.sender_id) {
                                    chat.chats[index].messages.push(data);
                                }
                                else if(chat.chats != undefined) {
                                    chat.chats.push({
                                        sender_id: data.sender_id,
                                        sender: data.sender,
                                        messages: [data]
                                    });
                                    $scope.$digest();
                                }
                            }

                        } else {
                            $scope.openChat(data.chat,true).then(function(chat){
                                chat.last_message_date = new Date();
                                chat.new_message = true;
                                $scope.updateConversations(chat,data.message,data.sender_id);
                            });
                        }

                        if(data.sender_id != $scope.user.id && (!chat || !chat.active)){
                            $scope.playNewChatSound();
                        }
                    }
                break;

                case 'customer_service':

                    var conversation = _.findWhere($scope.customer_services, {
                        id: data.chat_id
                    });

                    if( conversation ) {
                        conversation.last_message_date = new Date();
                        conversation.last_message = data.message;
                        conversation.open = true;
                        var last = _.last(conversation.chats);
                        var index = _.indexOf(conversation.chats, last);

                        if(data.sender_id != $localStorage.user.id)
                        {
                            if (last && last.sender_id == data.sender_id) {
                                conversation.chats[index].messages.push(data);
                            } else if(conversation.chats != undefined) {
                                conversation.chats.push({
                                    sender_id: data.sender_id,
                                    sender: data.sender,
                                    messages: [data]
                                });
                            }
                        }

                        // Play sound
                        if(data.sender_id != $scope.user.id){
                            $scope.playNewChatSound();
                            $rootScope.$broadcast('has_new_message');
                            conversation.new_message = true;
                        }
                    }
                break;
            }
            $scope.$digest();

        });

        /**
         * O Chat foi atendido
         */
        socket.on('chatAnswered', function(data)
        {
            // TODO: diz que o usuário negou o atendimento pra esse chat
            // //$rootScope.setDismisses(protocol_id);
            var chat =  _.find($scope.customer_services, {id: data.chat.id});
            var i = _.indexOf($scope.customer_services, chat);
            $scope.customer_services.splice(i,1);
            $scope.$apply();
        });

        /**
         * If message was read by other users
         */
        socket.on('messageRead', function(data){
            var chat = _.findWhere($scope.chats, {id:data.id});
            if(chat){
                // TODO: fazer isso de forma assincrona sem ir no servidor pegar as mensagens
                $scope.loadMessages(chat);
            }
        });

        /**
         * Socket connected
         */
        socket.on('connect', function() {

            var user = $scope.user;

            if(user != undefined){
                setTimeout(function () {

                    // set user online
                    socket.emit('online', user);

                    /**
                     * Join to department room
                     */
                    if(user && user.data && user.data.department_id)
                    {
                        var department_id = 'department_'+user.data.department_id;
                        socket.emit('join', department_id);
                    }

                    socket.emit('join', 'user_status');
                }, 0);
            }

        });

        /**
         * Status de chats
         */
        $chat_status.query().$promise.then(function(response)
        {
            $scope.chat_statuses = response.data;
        });

        $scope.getDataStatus = function(data)
        {
            if(data)
                return data.map(data => ({ id: data.id, title: data.name }));
        }

        /**
         * Load Customer Services
         */
        if(!is_dock)
        {
            $http.get(api + 'chat','{count:1000}').then(function(response){
                $scope.customer_services = response.data.data;
                _.each($scope.customer_services, function(message,key){
                    socket.emit('join', message.number);
                    if(message.last_message != null)
                        message.last_message_date = new Date(message.last_message_date);
                });
                $scope.customer_services = _.sortBy($scope.customer_services, 'last_message_date');
                if($scope.customer_services.length > 0){
                    $scope.loadMessages($scope.customer_services[$scope.customer_services.length-1]);
                    $scope.chat = $scope.customer_services[$scope.customer_services.length-1];
                }
            });

            socket.emit('online', $scope.user);
        }

        /**
         * Load contacts
        */
        $scope.loadContacts = function()
        {
            $chat.getContacts().then(function(data)
            {
                _.each(data,function(chat,key){
                    var talk_to = _.reject(chat.participants, function(participant){
                        return participant.id == $scope.user.id;
                    });
                    data[key].last_message_date = moment(chat.last_message_date).toDate();
                    data[key].talk_to = talk_to[0];
                });

                $scope.conversations = _.sortBy(data, 'last_message_date'); //data;
            });
        }

        $scope.loadContacts();

        /**
         * Update message status
         */
        $scope.updateStatus = function(chat, status)
        {

            var modalStatus = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                    $scope.title = '<span class="text-center">Atualizar o status para: <br/><strong>'+status.name+'</strong>?</span>';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.chat = {};

                    $scope.confirm = function() {

                        $chat.update({
                            id: chat.id,
                            status_id: status.id
                        }, $scope.chat).$promise.then(function(data) {
                            $uibModalInstance.dismiss('cancel');
                            chat.status = status;
                            chat.status_id = status.id;
                            if(!status.allow_chat){
                                $scope.closeChat(chat);
                            }
                        });
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                }]
            });
        }

        /**
         * Transfer chat to another attendant
         */
        $scope.transferChat = function(chat)
        {

            var modalStatus = $uibModal.open({
                animation: true,
                templateUrl: 'transfer_chat.modal.html',
                size: 'lg',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                    $scope.status = [
                        {id:undefined, title:'Todos'},
                        {id:'1', title:'Ativos'},
                        {id:'0', title:'Inativos'}
                    ];

                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: 10
                    }, {
                        counts: [],
                        filterDelay: 0,
                        getData: function(params) {
                            return $users.query(params.url()).$promise.then(function(data) {
                                params.total(data.total);
                                return data.data;
                            });
                        }
                    });

                    $scope.title            = 'Transferir atendimento';
                    $scope.cancel_text      = 'Cancelar';
                    $scope.success_text     = 'Transferir';

                    $scope.confirm = function(row) {

                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: 'confirm.modal.html',
                            size: 'sm',
                            scope: $scope,
                            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                                $scope.title                    = 'Confirma a transferência?';
                                $scope.cancel_text              = 'Cancelar';
                                $scope.success_text             = 'Concluído';

                                $scope.confirm = function() {
                                    $chat.transfer({id:chat.id,user_id: row.id}).then(function(response){
                                        var i = $scope.customer_services.indexOf(chat);
                                        $scope.customer_services.splice(i,1);
                                        $uibModalInstance.dismiss('cancel');

                                        if($scope.customer_services.length > 0)

                                            // achar o ultimo pela data
                                            var last =  _.sortBy($scope.customer_services, 'last_message_date').reverse();
                                            console.log(last);
                                            $scope.openCustomerService(last[0]);
                                        $scope.$parent.cancel();
                                    });
                                }
                                $scope.cancel = function() {
                                    $uibModalInstance.dismiss('cancel');
                                };
                            }]
                        });
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });
        }


        $scope.setDismisses = function(chat_id)
        {
            var user = $localStorage.user.id;
            $localStorage.dismissies[user] = chat_id;
        }

        /**
         * Load messages
         */
        $scope.loadMessages = function(row)
        {
            $chat.getMessages({
                id: row.id
            }).then(function(result) {
                row.messages = result.data;
                row.chats = [];
                _.map(result.data, function(value, key) {

                    value.is_read = !_.contains(_.pluck(_.reject(value.recipients, function(recipient){
                        return recipient.user_id == $scope.user.id;
                    }),'is_read'),0);

                    value.created_at = Date.parse(value.created_at);
                    value.updated_at = Date.parse(value.updated_at);
                    var last = _.last(row.chats);
                    if (last && last.sender_id == value.sender_id) {
                        last.messages.push(value);
                    } else {
                        row.chats.push({
                            sender_id: value.sender_id,
                            sender: value.sender,
                            messages: [value]
                        });
                    }
                });
            });
        }

        /**
         * Open message
         */
        $scope.openCustomerService = function(row)
        {
            $scope.chat             = row;
            row.open                = true;
            socket.emit('join', row.number);
            $scope.chat.new_message = false;
            _.extend(_.find($scope.customer_services, {id: row.id}), row);
            $scope.loadMessages(row);
            $timeout(function() {
                $scope.loadingMessages = false;
                var input_send = document.getElementById("input_send");
                if(input_send)
                    input_send.focus();
            }, 10);
        };

        /**
         * Find chat based on user
         */
        $scope.findChat = function(c)
        {
            c.new_message = false;

            // prevent double clicks
            if ($scope.lastClick && ((new Date() - $scope.lastClick) < 500)) {
                return;
            }
            $scope.lastClick = new Date();

            // find chat on opened chats
            var chat = _.findWhere($scope.chats, {id:c.id});

            if(chat){
                document.getElementById("chat_"+chat.id).focus();
            } else {
                $scope.openChat(c).then(function(chat){
                    setTimeout(function () {
                        document.getElementById("chat_"+chat.id).focus();
                    }, 10);
                });
            }
        }

        $scope.newChat = function()
        {

            var modalStatus = $uibModal.open({
                animation: true,
                templateUrl: 'transfer_chat.modal.html',
                size: 'lg',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                    $scope.title            = 'Nova Conversa';
                    $scope.cancel_text      = 'Cancelar';
                    $scope.success_text     = 'Iniciar';

                    $scope.status = [
                        {id:undefined, title:'Todos'},
                        {id:'1', title:'Ativos'},
                        {id:'0', title:'Inativos'}
                    ];

                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: 10
                    }, {
                        counts: [],
                        filterDelay: 0,
                        getData: function(params) {
                            return $users.query(params.url()).$promise.then(function(data) {
                                params.total(data.total);
                                return data.data;
                            });
                        }
                    });

                    $scope.confirm = function(row) {

                        $chat.findChat($scope.user.id, row.id).then(function(data){
                            $scope.openChat(data).then(function(chat){
                                setTimeout(function () {
                                    document.getElementById('chat_'+chat.id).focus();
                                }, 10);
                            });
                            $uibModalInstance.dismiss('cancel');
                        }, function(error){
                            var new_chat = {
                                type: 'chat',
                                from: $scope.user.id,
                                to: row.id
                            };
                            $chat.save(new_chat).$promise.then(function(data) {
                                $scope.openChat(data).then(function(chat){
                                    $scope.loadContacts();
                                    setTimeout(function () {
                                        document.getElementById('chat_'+chat.id).focus();
                                    }, 10);
                                });
                                $uibModalInstance.dismiss('cancel');
                            });
                        });
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });


        }

        /**
         * open message on minichat
         */
        $scope.openChat = function(row,new_message)
        {
            var deferred = $q.defer();

            if($scope.chats.length == 2){
                $scope.chats.splice(0,1);
            }

            if(!_.findWhere($scope.chats, {id:row.id}))
            {
                $chat.get({
                    id: row.id
                }).$promise.then(function(data) {
                    var title = "";
                    _.each(data.participants, function(participant, key){
                        if( participant.id != $scope.user.id)
                            title += participant.name;
                    });
                    data.title       = title;
                    data.new_message = new_message;
                    var talk_to = _.reject(data.participants, function(participant){
                        return participant.id == $scope.user.id;
                    });
                    data.talk_to = talk_to[0];
                    $scope.loadMessages(data);
                    $scope.chats.push(data);
                    deferred.resolve(data);

                }, function(error) {
                    console.log('erro');
                    console.log(error);
                });
            } else {
                deferred.resolve(true);
            }

            $scope.search = "";

            return deferred.promise;
        }

        /**
         * Close chat messages
         */
        $scope.closeChat = function(row)
        {
            var i = _.indexOf($scope.chats, row);
            $scope.chats.splice(i,1);
        }

        /**
         * Send message to a specific room
         */
        $scope.sendMessage = function(row, message, type = 'text', link = null)
        {

            if (message == '') {
                $scope.message = "";
                return;
            }

            var message = encodeURI(message);

            $chat.sendMessage({chat_id:row.id,message:message, type:type, link:link}).then(function(response){

                var msg = {
                    room: row.number,
                    message: decodeURI(message),
                    type: type,
                    link: link,
                    sender_id: $localStorage.user.id
                }
                var last = _.last(row.chats);

                if (last && last.sender_id == $localStorage.user.id) {
                    last.messages.push(msg);
                } else {
                    row.chats.push({
                        sender_id: $localStorage.user.id,
                        messages: [msg]
                    });
                }
                $scope.message = "";
            });
        }

        $scope.playNewChatSound = function()
        {
            var new_message_audio = new Audio(new_message_sound);
            new_message_audio.play();
        }

        $scope.updateConversations = function(chat,message, from)
        {
            if(!_.findWhere($scope.conversations, {id:chat.id})){
                $scope.conversations.push(chat);
            };

            var conversation = _.findWhere($scope.conversations, {id:chat.id});
            conversation.last_message_date = new Date();
            conversation.last_message = message;

            if($scope.user.id != from && !chat.active)
                conversation.new_message = true;
        }

        /**
         * Antender um chamado
         */
        $scope.answerCall = function(chat)
        {

            var openChat            = $scope.openChat;
            var openCustomerService = $scope.openCustomerService;
            var sendMessage         = $scope.sendMessage;
            var socket_instance     = socket;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', '$client', function($scope, $uibModalInstance, $client) {

                    $scope.title = 'Deseja responder esse atendimento?';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Concluído';

                    $scope.confirm = function() {
                        chat.attendant_id = $localStorage.user.id;

                        // TODO: ajustar isso
                        if(chat.status == undefined)
                            chat.status = {};

                        chat.status.allow_chat = true;

                        $chat.update({
                            id: chat.id
                        }, chat).$promise.then(function(chat) {

                            socket_instance.emit('answerProtocol', {
                                room: 'department_'+$localStorage.user.data.department_id,
                                chat: chat,
                                attendant_id: $localStorage.user.id,
                            });

                            var input = document.getElementById("input_send");
                            if(input)
                                input.focus();

                            if(chat.type == "customer_service"){
                                chat = _.findWhere($scope.customer_services,{id:chat.id});
                                openCustomerService(chat);
                            } else {
                                openChat(chat);
                            }
                            sendMessage(chat,'Olá, em que posso ajudar?');
                            $uibModalInstance.dismiss('cancel');
                        })
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

        /**
         * Move to next status step
         * for now its updating chat to status 3 (ended)
         */
        $scope.next = function()
        {
            $chat.next($scope.chat.id).then(function(response){
                // Do something
            });
        }

        $scope.setActive = function(chat)
        {
            _.each($scope.chats, function(chat){
                chat.active = false;
            });
            chat.active = true;
            chat.new_message = false;

            _.findWhere($scope.conversations,{id:chat.id}).new_message = false;

            $chat.readMessages(chat).then(function(data){
                console.log(data);
            });

            document.getElementById("chat_"+chat.id).focus();
        }

        $scope.setInactive = function(chat)
        {
            chat.active = false;
            chat.new_message = false;
        }

        $scope.sayImTyping = function(chat)
        {
            _.each(chat.participants, function(participant,key){
                socket.emit('user_typing', {
                    chat:chat.id,
                    user:participant.id
                });
            });
        }
    }
}

chatController.$inject = ['$rootScope', '$scope', '$http', '$auth', '$q', 'NgTableParams', '$chat', '_', '$timeout', '$localStorage', '$uibModal', '$chat_status', '$users','socket', '$state'];

/**
 * List Controller
 */
angular.module('chat').controller('chat_index', chatController);
angular.module('chat').controller('chat_dock', chatController);

/**
 * Format date directive
 */
angular.module('chat')
.directive('timeAgo', function($timeout, dateFilter) {
    // return the directive link function. (compile function not needed)
    return function(scope, element, attrs) {
        var date,  // date format
        timeoutId; // timeoutId, so that we can cancel the time updates

        // used to update the UI
        function updateTime() {

            var today = new moment();

            if(date != 'Novo atendimento'){

                var diff = today.diff(date, 'days') // 1
                var diff_minutes = today.diff(date, 'minutes');

                var text = date.format('HH:mm');

                if(diff_minutes < 1)
                    text = 'agora';

                if(diff > 6)
                    text = date.format('DD de MMMM');

                if(diff > 1)
                    text = date.format('ddd');
            } else {
                    text = today.format('HH:mm');
            }

            element.text(text);
        }

        // watch the expression, and update the UI on change.
        scope.$watch(attrs.timeAgo, function(value) {

            date = (value != undefined) ? moment(value) : 'Novo atendimento';

            updateTime();
        });

        // schedule update in one second
        function updateLater() {
            // save the timeoutId for canceling
            timeoutId = $timeout(function() {
                updateTime(); // update DOM
                updateLater(); // schedule another update
            }, 1000);
        }

        // listen on DOM destroy (removal) event, and cancel the next UI update
        // to prevent updating time ofter the DOM element was removed.
        element.bind('$destroy', function() {
            $timeout.cancel(timeoutId);
        });

        updateLater(); // kick off the UI update process.
    }
});

/**
 * Toggle open close class
 */
app.directive('toggleClass', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('click', function() {
                console.log(attrs.toggleClass);
                element.parent().parent().toggleClass(attrs.toggleClass);
            });
        }
    };
});


/**
 * Set focus on input
 */
app.directive('setFocus', function(){
    return{
        scope: {setFocus: '='},
        link: function(scope, element){
            if(scope.setFocus) element[0].focus();
        }
    };
});

/**
 * Create Controller
 */
angular.module('chat').
controller('chat.create', ['$scope', '$state', '$chat', '$countryState', '$city', function($scope, $state, $chat, $countryState, $city) {
    $scope.chat = {
        state_id: {},
        city_id: {}
    }

    $countryState.query().$promise.then(function(response) {
        $scope.states = response.data;
    });

    $scope.save = function() {
        $chat.save($scope.chat).$promise.then(function(data) {
            $state.go('chat_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Factory
 */
angular.module('chat').
factory("$chat", function($resource, $q, $http, $localStorage, $rootScope) {

    var resource = $resource(api + 'chat/:id', {
        id: '@id'
    }, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.findChat = function(user_one, user_two)
    {
        var deferred = $q.defer();
        $http.get(api + 'chat/find',{params:{'user_one':user_one, 'user_two':user_two}}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.getContacts = function(params) {
        var deferred = $q.defer();
        $http.get(api+'chat/conversations').then(function(response){
            deferred.resolve(response.data);
        }, function(error){
            deferred.reject(error);
        });
        return deferred.promise;
    }

    resource.getMessages = function(params) {
        var deferred = $q.defer();
        $http.get(api + 'chat/' + params.id + '/messages').then(function(response) {
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.sendMessage = function(params){
        var deferred = $q.defer();
        $http.post(api+'chat/'+params.chat_id+'/messages/send',params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.getMessage = function(params){
        var deferred = $q.defer();
        $http.get(api + 'chat/'+params.id+'/message/'+params.message_id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.transfer = function(params){
        var deferred = $q.defer();
        $http.put(api+'chat/'+params.id+'/transfer',params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.readMessages = function(params){
        var deferred = $q.defer();
        $http.put(api+'chat/'+params.id+'/read_messages',params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    return resource;
});
