angular.module('user_notification', ['ui.router', 'ngResource', 'ngTable', 'ui.bootstrap']);


angular.module('user_notification').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('notifications', {
        url         : "/notifications",
        templateUrl : server+"/views/m/user_notifications",
        controller  : 'notifications.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Notificações'
        }
    })

}]);

/**
* Controllers
*/


/**
* Notifications page
*/
angular.module('user_notification').
controller('notifications.list', ['$timeout','$scope', '$http', '$auth', '$q', 'NgTableParams', '$user_notification','$rootScope', '$window',
function($timeout, $scope, $http, $auth, $q, NgTableParams, $user_notification, $rootScope, $window) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $user_notification.getNotificationsPage(params.url()).then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    setTimeout(function(){ $scope.tableParams.reload(); }, 300);
    setInterval(function(){ $scope.tableParams.reload(); }, 15000);

}]);



/**
* Notifications navbar, notifications unread on home page
*/
angular.module('user_notification').
controller('notifications_show', ['$state', '$scope', '$stateParams', '$uibModal', '$user_notification', '_', '$http','$q','$rootScope',
function($state, $scope, $stateParams, $uibModal, $user_notification, _, $http, $q, $rootScope) {

      $scope.getNotifications = function()
      {
        $user_notification.getNotifications().then(function(response) {
            $scope.notifications = response.data;
            $scope.total_notifications = response.totalNotViewed;

            if($scope.total_notifications < 1){
              $scope.total_notifications = null;
            }

            if($scope.notifications.read_at != null){
              notification.read_at = 'read';
            }

        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
      }

      $scope.getNotifications();

      $scope.$on('get-notifications', function(event, args) {
        $scope.getNotifications();
      });

      $scope.update_viewed_at = function(){
        $user_notification.setViewedAt().then(function(){
          $scope.total_notifications = null;
        });
      }

      $scope.update_read_at = function(notification){
        notification.read_at = 'read';
        $user_notification.setReadAt(notification.id);
      }



}]);

/**
 * Factory
 */
angular.module('user_notification').
factory("$user_notification", function($resource, $q, $http) {

  var resource = $resource(api + 'user_notification/:id', null, {
      'query': {
          method: 'GET',
          isArray: false
      },
      'update': {
          method: 'PUT'
      }
  });


  //pageNotifications
  resource.getNotificationsPage = function(params){
      var deferred = $q.defer();
      $http.get(api+'user_notification/pageNotifications', {params:params}).then(function(response){
          deferred.resolve(response.data);
      }, function(response) {
          deferred.reject(response);
      });
      return deferred.promise;
  }

  //notifications navbar
  resource.getNotifications = function()
  {
      var deferred = $q.defer();
      $http.get(api+'user_notification').then(function(response){
          deferred.resolve(response.data);
      }, function(response) {
          deferred.reject(response);
      });
      return deferred.promise;
  }

  //notifications unread home page
  resource.getNotificationsUnread = function()
  {
      var deferred = $q.defer();
      $http.get(api+'user_notification/getUnread').then(function(response){
          deferred.resolve(response.data);
      }, function(response) {
          deferred.reject(response);
      });
      return deferred.promise;
  }

  //set notifications viewed
  resource.setViewedAt = function()
  {
    var deferred = $q.defer();
    $http.post(api+'user_notification/visualised').then(function(response){
        deferred.resolve(response.data);
    }, function(response) {
        deferred.reject(response);
    });
    return deferred.promise;
  }

  //set notification read
  resource.setReadAt = function(id)
  {
    var deferred = $q.defer();
    $http.post(api+'user_notification/readed/'+id).then(function(response){
        deferred.resolve(response.data);
    }, function(response) {
        deferred.reject(response);
    });
    return deferred.promise;
  }

    return resource;
});
