
var home = angular.module('home', ['ui.router']);

home.config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {
    $stateProvider
    .state('home', {
        url: "/home",
        templateUrl: server+"/views/m/home",
        controller: 'home',
        ncyBreadcrumb: {
            label: 'Início'
        },
        resolve : {
            loginRequired: loginRequired
        }
    })
}]);

home.controller('home', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage','$timeout', '$user_notification', '$financial_entry', 'ngToast', '_', '$uibModal', '$client', '$invoice_import', '$transaction', '$system_alert', '$company', '$service',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $timeout, $user_notification, $financial_entry, ngToast, _, $uibModal, $client, $invoice_import, $transaction, $system_alert, $company, $service){


    $scope.today = moment();
    $scope.dateValid = '';
    $scope.changeLabel = false;
    $scope.changeLabelAlert = false;

    $scope.loadDashboardFinancial = function(){
        var params = {
            start   : moment().add('days', -1).format('YYYY-MM-DD HH:mm:ss'),
            end     : moment().format('YYYY-MM-DD HH:mm:ss'),
            type    : 'D'
        }

        // $financial_entry.cashFlow().then(function(response){
        //     $scope.lates = response.data.debit_noPaid;
        // });

        // $financial_entry.query(params).$promise.then(function(response){
        //     $scope.today           = response.data;
        // });
    }
    $scope.loadDashboardFinancial();

    $scope.filter = function(data){
        data = _.sortBy(data, function(row){
            return moment(row.transacted_at).format('YYYYMMDD') + row.description;
        });
        return  _.where( data, {paid: 0});
    }

    $scope.showEntries = function(entries, type)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'entries.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '_', function($scope, $uibModalInstance, $financial_entry, _) {

                $scope.title        = 'Transações atrasadas';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Ok';
                $scope.type         = type;
                $scope.entries      = entries;

                $scope.att = function() {
                    var count = _.where($scope.entries, { paid: 0});
                    if( count.length == 0 ) {
                        $uibModalInstance.close();
                    }
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                };

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
        modalInstance.result.then(function() {
            $scope.loadEntries();
        },function(error) {
            $scope.loadEntries();
        });
    }

    var transactionParams = {
        company_id  : $localStorage.company.id,
        status      : 'pending'
    };

    $transaction.query(transactionParams).$promise.then(function(response){
        $scope.boletos = response;
    });

    $scope.showBoleto = function(boleto){
        var link = angular.element('<a href="' + boleto.boleto_url + '" target="_blank"></a>');
        angular.element(document.body).append(link);
        link[0].click();
        link.remove();
    };

    $service.stockAlert({}).then(function(response){
        $scope.servicesStock = response;
    });

    $scope.showServices = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl:  server+"/views/m/service/stock_alert_modal",
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                $scope.title        = 'Alerta de estoque';
                $scope.servicesStockList = $scope.servicesStock;

                $scope.confirm = function() {
                    $state.go('purchase_suggestion', {stock_alert : true});
                    $uibModalInstance.close();
                };

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    };

    $scope.printRetportAlertStock = function(){
        $service.stockAlert({print : true}).then(function(response){
            $rootScope.print(response);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $system_alert.showAll().then(function(response){
        $scope.alerts = response;
    });

    $scope.getCertsToInvalided = function()
    {
        $client.getCertsToInvalided().then(function(response){
            $scope.clientsCerts = response;
        });
    }
    $scope.getCertsToInvalided();

    $scope.getCompanyEndDateCertificate = function()
    {
        $company.getCompanyEndDateCertificate().then(function(response){
            $scope.companyEndDateCerts = response;
            $scope.dateValid = moment($scope.companyEndDateCerts.cert_valid_to).format('DD/MM/YYYY');
            
            var partesData = $scope.dateValid.split("/");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            var countDate = moment(data).subtract(30, 'days');
            if(data < new Date()){
                $scope.changeLabel = true;
            }
            if (countDate == new Date()) {
                $scope.changeLabelAlert = true;
            }
        });
    }
    $scope.getCompanyEndDateCertificate();

    $scope.showClients = function(clients = [], showCert = false)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'clients.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client', function($scope, $uibModalInstance, $client) {

                $scope.title            = 'Clientes';
                $scope.showValidCert    = showCert;
                $scope.clients          = clients;

                $scope.disable = function(index) {
                    $client.disableAlertCert($scope.clients[index].id).then(function(response){
                        $scope.clients.splice(index, 1);
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    }

    $scope.getUnconfirmedItems = function()
    {
        var data1 = {
            date : $scope.today.toISOString(),
            tax_type : '1568',
            has_tax_collected : '0'
        }
        $invoice_import.getUnconfirmedItems(data1).then(function(response) {
            $scope.unConfirmedItems = response;
        });

        var data2 = {
            date : $scope.today.toISOString(),
            tax_type : null
        }
        $invoice_import.getUnconfirmedItems(data2).then(function(response) {
            $scope.unClassfiedItems = response;
        });
    }
    $scope.getUnconfirmedItems();

    $scope.updateStatusPaid = function(entry, status)
    {
        var data = {
            paid            : status
        };

        if( entry.recurrent_days > 0 ){
            data.transacted_at = moment(entry.transacted_at).format('Y-M-D');
        }

        $financial_entry.updateStatusPaid(entry.id, data).then(function(response){
            ngToast.success({
                content: 'Status atualizado',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.loadEntries();
        }, function(error){
            var index = _.indexOf($scope.financial_entry[entry.type_id], entry);
            $scope.financial_entry[entry.type_id][index].paid = !status;
            ngToast.danger({
                content: 'Erro ao atulizar status',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }
}]);
