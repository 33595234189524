angular.module('provider', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('provider').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('provider', {
        url         : "/provider",
        templateUrl : server+"/views/m/provider",
        controller  : 'provider.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Provider'
        }
    })

    .state('provider_create', {
        url         : "/provider/create",
        templateUrl : server+"/views/m/provider/create",
        controller  : 'provider.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'provider',
            label: 'Adicionar'
        }
    })

    .state('provider_show', {
        url         : "/provider/:id",
        templateUrl : server+"/views/m/provider/show",
        controller  : 'provider.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'provider',
            label: '{{provider.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('provider').
controller('provider.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$provider', function($scope, $http, $auth, $q, NgTableParams, $provider) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $provider.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('provider').
controller('provider.create', ['$scope', '$state', '$provider', '$countryState', '$city', function($scope, $state, $provider, $countryState, $city) {

    $scope.provider = {
        state_id: {},
        city_id: {}
    }

    $countryState.query().$promise.then(function(response){
        $scope.states = response.data;
    });

    $scope.save = function() {
        $provider.save($scope.provider).$promise.then(function(data) {
            $state.go('provider_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('provider').
controller('provider.show', ['$state', '$scope', '$stateParams', '$uibModal', '$provider', function($state, $scope, $stateParams, $uibModal, $provider) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $provider.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.provider = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$provider', function($scope, $uibModalInstance, $provider) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var provider = $scope.$parent.provider;

                $scope.confirm = function() {
                    $provider.update({
                        id: provider.id
                    }, provider).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$provider', function($state, $scope, $uibModalInstance, $provider) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var provider = $scope.$parent.provider;

                $scope.confirm = function() {
                    $provider.delete({id:provider.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('provider');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('provider').
factory("$provider", function($resource) {
    var resource = $resource(api + 'provider/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
