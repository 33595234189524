angular.module('billing', ['satellizer', 'ngStorage', 'ngToast']);

angular.module('billing').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    //$urlRouterProvider.otherwise("/");

    // Now set up the states
    $stateProvider
    .state('trust_payment', {
        // url         : "/invoice",
        templateUrl : server+"/views/m/billing/trust_payment",
        controller  : 'billing',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: ''
        }
    })

}]);

angular.module('billing').controller('billing', ['$rootScope', '$scope', '$http', '$auth', '$state', '$localStorage', '$interpolate', '$compile', 'ngToast', '$uibModal', '$company', '$location',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $interpolate, $compile, ngToast, $uibModal, $company, $location) {

    $scope.f = $localStorage.last_transaction;

    $rootScope.$on('account_payment', function(event, data){
        $scope.f = data;
    });

    $scope.trustPayment = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$bulletin', function($scope, $uibModalInstance, $bulletin) {

                $scope.title = 'A liberação em confiança possui um prazo de 2 dias...(melhorar texto)';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Liberar';

                $scope.confirm = function() {
                    $http.post(api+'trust_payment').$promise.then(function(response){
                        console.log(response);

                        $scope.company = response;

                        var last_transaction = (response.last_transaction[0] != undefined) ? response.last_transaction[0] : undefined;

                        if(last_transaction){
                          last_transaction.boleto_expiration_date = moment(last_transaction.boleto_expiration_date);
                          last_transaction.days_from_expiration = Math.round(moment.duration(moment().diff(last_transaction.boleto_expiration_date)).asDays());
                          last_transaction.expired = (last_transaction.boleto_expiration_date < moment() && last_transaction.status == 'waiting_payment');
                          last_transaction.explanation = "Sua fatura esta pronta "+last_transaction.days_from_expiration+".";
                          if(last_transaction.expired){
                            last_transaction.explanation = "Sua fatura esta vencida.";
                            if(response.trust_payment == false && last_transaction.days_from_expiration > 2){
                                console.log('trust_payment');
                            }
                            if(response.trust_payment == true && last_transaction.days_from_expiration > 2)
                            {
                              console.log('blocked_payment');
                            }
                          }
                        }
                        $localStorage.last_transaction = last_transaction;

                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);
