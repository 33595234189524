angular.module('cart', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('cart').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('cart', {
        url         : "/cart?p",
        templateUrl : server+"/views/m/cart",
        controller  : 'cart',
        resolve     : {
            loginRequired: function($q, $location, $auth, $rootScope, $localStorage, $state, $http) {
                var deferred = $q.defer();

                if ($auth.isAuthenticated()) {
                    $http.get(api + 'user/profile').then(function(e) {
                        if(e.data.companies[0].plan_active){
                            $rootScope.$broadcast('auth:login-success');
                        }
                    });
                    deferred.resolve();
                } else {
                    $rootScope.$broadcast('auth:logout-success');
                    $location.path('/auth');
                }
                return deferred.promise;
            }
        }
    })
}]);

/**
 * List Controller
 */
angular.module('cart').
controller('cart', ['$scope', '$http', '$auth', '$q', '$plans', '$stateParams','$localStorage', '$company','$uibModal', '$state', '$transaction', '$system_alert',
function($scope, $http, $auth, $q, $plans, $stateParams, $localStorage, $company, $uibModal, $state, $transaction, $system_alert) {

    var transactionParams = {
        company_id  : $localStorage.company.id,
        status      : 'pending'
    };
    $transaction.query(transactionParams).$promise.then(function(response){
        $scope.boletos = response;
    });
    $scope.showBoleto = function(boleto){
        var link = angular.element('<a href="' + boleto.boleto_url + '" target="_blank"></a>');
        angular.element(document.body).append(link);
        link[0].click();
        link.remove();
    }
    $system_alert.showAll().then(function(response){
        $scope.alerts = response;
    });

    $scope.annual_discount = 15;

    $scope.paymentForm;

    $scope.cart = {
        payment_method: 'boleto',
        periodicity   : 'annual',
        amount        : 0,
        card          : {},
        address       : {},
    };

    if( $stateParams.p )
        $localStorage.p = $stateParams.p;

    $scope.plan_id = $localStorage.p ? $localStorage.p : 1;

    $plans.get({
        id: $scope.plan_id
    }).$promise.then(function(data) {
        $scope.plan = data;
        $scope.plan.plan_price = data.price;
        $scope.calcPlan();
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $company.get({id: $localStorage.company.id}).$promise.then(function(data){
        console.log(data);
        $scope.company = data;
    });

    $scope.calcPlan = function()
    {
        $scope.plan.price = ($scope.cart.periodicity == 'annual') ? $scope.plan.plan_price * (1-($scope.annual_discount/100)) : $scope.plan.plan_price;
        $scope.cart.amount = ($scope.plan.price) * 12;
        $scope.cart.plan = $scope.plan;
    }

    $scope.$watch('cart.address.zipcode', function(){
        if($scope.cart.address.zipcode != undefined && $scope.cart.address.zipcode.length == 8){
            $http.get(api+'client/getCEP/'+$scope.cart.address.zipcode).then(function(response){
                $scope.cart.address.street        = response.data.logradouro;
                $scope.cart.address.neighborhood  = response.data.bairro;
                $scope.cart.address.zipcode       = response.data.cep;
                $scope.cart.address.complementary = response.data.complemento;
                $scope.cart.address.city          = response.data.localidade;
                $scope.cart.address.state         = response.data.uf;
                $scope.cart.address.country       = 'Brasil';
            });
        }
    });

    /**
     * Checkout
     */
    $scope.checkout = function()
    {
        if($scope.paymentForm.$invalid){
            return false;
        }

        var modalInstance = $uibModal.open({
            animation  : true,
            templateUrl: 'checkout_success.modal.html',
            size       : 'sm',
            scope      : $scope,
            backdrop   : 'static',
            keyboard   : false,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', function($state, $scope, $uibModalInstance, $bulletin, $http) {

                $scope.loadingCheckout = true;

                $company.checkout($scope.cart).then(function(result){
                    $scope.checkoutResp = result;
                    $scope.checkoutStatus = result.status;
                    $scope.loadingCheckout = false;
                    $scope.company.gateway_subscription_id = result.id;
                });

                $scope.confirm = function() {
                    $uibModalInstance.dismiss('cancel');
                    $stete.go('',{}, {reload: true});
                }
            }]
        });


    }

}]);

/**
 * Factory
 */
angular.module('cart').
factory("$plans", function($resource, $http, $q) {
    var resource = $resource(api + 'plan/:id', {id: '@id'}, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.report = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'plan/report', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
