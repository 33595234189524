angular.module('account_entry', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('account_entry').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('account_entry', {
        url         : "/account_entry",
        templateUrl : server+"/views/m/account_entry",
        controller  : 'account_entry.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'AccountEntry'
        }
    })

    .state('account_entry_create', {
        url         : "/account_entry/create",
        templateUrl : server+"/views/m/account_entry/create",
        controller  : 'account_entry.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'account_entry',
            label: 'Adicionar'
        }
    })

    .state('account_entry_show', {
        url         : "/account_entry/:id",
        templateUrl : server+"/views/m/account_entry/show",
        controller  : 'account_entry.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'account_entry',
            label: '{{account_entry.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('account_entry').
controller('account_entry.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$account_entry', function($scope, $http, $auth, $q, NgTableParams, $account_entry) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $account_entry.query(encodeURI(params.url())).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('account_entry').
controller('account_entry.create', ['$scope', '$state', '$account_entry', function($scope, $state, $account_entry) {
    $scope.save = function() {
        $account_entry.save($scope.account_entry).$promise.then(function(data) {
            $state.go('account_entry_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('account_entry').
controller('account_entry.show', ['$state', '$scope', '$stateParams', '$uibModal', '$account_entry', function($state, $scope, $stateParams, $uibModal, $account_entry) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $account_entry.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.account_entry = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$account_entry', function($scope, $uibModalInstance, $account_entry) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var account_entry = $scope.$parent.account_entry;

                $scope.confirm = function() {
                    $account_entry.update({
                        id: account_entry.id
                    }, account_entry).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$account_entry', function($state, $scope, $uibModalInstance, $account_entry) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var account_entry = $scope.$parent.account_entry;

                $scope.confirm = function() {
                    $account_entry.delete({id:account_entry.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('account_entry');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);

/**
 * Factory
 */
angular.module('account_entry').
factory("$account_entry", function($resource, $q, $http) {
    var resource = $resource(api + 'account_entry/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.cash_flow = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'account_entry/cash_flow', params).then(function(response){

            _.each(response.data.entries, function(data, key){
                response.data.entries[key].transacted_at = new Date(data.transacted_at);
            });

            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
