angular.module('accounts_plan', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'treeGrid']);

angular.module('accounts_plan').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('accounts_plan', {
        url         : "/accounts_plan",
        templateUrl : server+"/views/m/accounts_plan",
        controller  : 'accounts_plan.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Plano de Contas'
        }
    })

    .state('accounts_plan_create', {
        url         : "/accounts_plan/create",
        templateUrl : server+"/views/m/accounts_plan/create",
        controller  : 'accounts_plan.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'accounts_plan',
            label: 'Adicionar'
        }
    })

    .state('accounts_plan_show', {
        url         : "/accounts_plan/:account",
        templateUrl : server+"/views/m/accounts_plan/show",
        controller  : 'accounts_plan.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'accounts_plan',
            label: '{{accounts_plan.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('accounts_plan').
controller('accounts_plan.list', ['$scope', '$http', '$accounts_plan', '$timeout', '$state', function($scope, $http, $accounts_plan, $timeout, $state) {

    var tree;
    $scope.my_tree = tree = {};

    $scope.expanding_property = { field: "name", displayName:"Conta", filterable: true};

    $scope.col_defs = [
        { field: "code", displayName:"Código", headerClass:"text-left", colClass:"col-md-1"},
        { field: "opening_balance", displayName:"Saldo inicial", headerClass:"text-center", colClass:"col-md-1 text-right", cellTemplate: "{{ row.branch[col.field] | finance }}"},
        { field: "debits", displayName:"Entrada", headerClass:"text-center", colClass:"col-md-1 text-success text-right", cellTemplate: "<span ng-if=\"row.branch[col.field] > 0\">{{ row.branch[col.field] | finance }}</span>"},
        { field: "credits", displayName:"Saída", headerClass:"text-center", colClass:"col-md-1 text-danger text-right", cellTemplate: "<span ng-if=\"row.branch[col.field] > 0\">{{ row.branch[col.field] | finance }}</span>"},
        { field: "total", displayName:"Saldo", headerClass:"text-center", colClass:"col-md-1 text-right", cellTemplate: "<strong ng-class=\"{'text-success':row.branch[col.field] >= 0, 'text-danger': row.branch[col.field] < 0}\">{{ row.branch[col.field] | finance }}</strong>"}
    ];

    $scope.my_tree_handler = function(data)
    {
        $state.go('accounts_plan_show', {
            account: data.id
        });
    }

    $http.get(api+'accounts_plan/tree').then(function(response){
        $scope.accounts = response.data;
        $timeout(function(){
            $scope.my_tree.expand_all();
        },50)
    });
}]);

/**
 * Create Controller
 */
angular.module('accounts_plan').
controller('accounts_plan.create', ['$scope', '$state', '$accounts_plan','$http', function($scope, $state, $accounts_plan, $http) {

    $scope.accounts_plan = {
        parent_id: {},
        opening_balance: 0
    };

    $accounts_plan.query({count:1000}).$promise.then(function(response) {
        $scope.parents = response.data;
    });

    $scope.$watch('accounts_plan.parent_id.id', function(){
        if($scope.accounts_plan.parent_id.id != undefined)
        {
            $http.get(api+'accounts_plan/'+$scope.accounts_plan.parent_id.id+'/nextcode').then(function(response){
                $scope.accounts_plan.code = response.data;
            });
        }
    });

    $scope.dropdownTranslation = {
        buttonDefaultText       : 'Selecione',
        checkAll                : 'Todas',
        uncheckAll              : 'Nenhuma',
        selectionCount          : 'selecionada(s)',
        selectionOf             : '/',
        searchPlaceholder       : 'Pesquisar',
        dynamicButtonTextSuffix : 'selecionada(s)'
    };

    $scope.dropdownConfig = {
        buttonClasses       : 'btn btn-default btn-block',
        scrollable          : true,
        scrollableHeight    : '200px',
        enableSearch        : true,
        styleActive         : true,
        showCheckAll        : false,
        showUncheckAll      : false,
        displayProp         : 'name',
        idProp              : 'id',
        selectionLimit      : 1,
        closeOnSelect       : true,
        smartButtonMaxItems : 1
    }

    $scope.save = function() {
        $scope.accounts_plan.parent_id = $scope.accounts_plan.parent_id.id;
        $accounts_plan.save($scope.accounts_plan).$promise.then(function(data) {
            $state.go('accounts_plan_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('accounts_plan').
controller('accounts_plan.show', ['$state', '$scope', '$stateParams', '$uibModal', '$accounts_plan', function($state, $scope, $stateParams, $uibModal, $accounts_plan) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $accounts_plan.get({
        id: $stateParams.account
    }).$promise.then(function(data) {
        $scope.accounts_plan = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$accounts_plan', function($scope, $uibModalInstance, $accounts_plan) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var accounts_plan = $scope.$parent.accounts_plan;

                $scope.confirm = function() {
                    $accounts_plan.update({
                        id: accounts_plan.id
                    }, accounts_plan).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$accounts_plan', function($state, $scope, $uibModalInstance, $accounts_plan) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var accounts_plan = $scope.$parent.accounts_plan;

                $scope.confirm = function() {
                    $accounts_plan.delete({id:accounts_plan.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('accounts_plan');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('accounts_plan').
factory("$accounts_plan", function($resource) {
    var resource = $resource(api + 'accounts_plan/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.traverse = function(params) {
        var deferred = $q.defer();
        $http.get(api+'accounts_plan/:id', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    return resource;
});
