angular.module('client', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap','angular-uuid','ngclipboard']);

angular.module('client').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('client', {
        url         : "/client",
        templateUrl : server+"/views/m/client",
        controller  : 'client.list',
        resolve     : {
            loginRequired   : loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Clientes / Pacientes'
        }
    })

    .state('client_create', {
        url         : "/client/create",
        templateUrl : server+"/views/m/client/create",
        controller  : 'client.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: 'Adicionar'
        }
    })

    .state('client_show', {
        url         : "/client/:id",
        templateUrl : server+"/views/m/client/show",
        controller  : 'client.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: '{{client.name}}'
        }
    })

    .state('show_provider', {
        url         : "/provider/:id",
        templateUrl : server+"/views/m/client/show_provider",
        controller  : 'client.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: '{{client.name}}'
        }
    })

    .state('create_provider', {
        url         : "/create/provider",
        templateUrl : server+"/views/m/client/create_provider",
        controller  : 'client.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: 'Adicionar'
        }
    })

}]);

angular.module('client').
controller('client.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$client', '$client_status', '$localStorage', 'showInModal', '$uibModal', '$uibModalInstance', '$state', 'find', 'company_id',
function($scope, $http, $auth, $q, NgTableParams, $client, $client_status, $localStorage, showInModal, $uibModal, $uibModalInstance, $state, find, company_id) {

    $scope.showInModal  = showInModal;
    $scope.title        = 'Selecione o cliente';
    $scope.subTitle        = 'Clientes';
    $scope.locale       = $state.current;

    $scope.company = $localStorage.company ? $localStorage.company : null;

    $scope.createClient = function(){
        if( $scope.showInModal ){
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/client/create",
                size            : 'lg',
                controller      : 'client.create',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true
                }
            });
            modalInstance.result.then(function(data) {
                $uibModalInstance.close(data);
            });
        } else {
            $state.go('client_create');
        }
    }

    $scope.createProvider = function(){
        if( $scope.showInModal ){
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/client/create_provider",
                size            : 'lg',
                controller      : 'client.create',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true
                }
            });
            modalInstance.result.then(function(data) {
                $uibModalInstance.close(data);
            });
        } else {
            $state.go('create_provider');
        }
    }

    $scope.selectClient = function(data){
        if( company_id ) {
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/client/show",//'os.modal.html',
                size            : 'lg',
                controller      : 'client.show',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true,
                    company_id  : $scope.company_id,
                    id          : data.id
                }
            });
            modalInstance.result.then(function(data) {

            });
            $uibModalInstance.close(data);
        }
        if($scope.showInModal) {
            $uibModalInstance.close(data);
        } else{
            $state.go('client_show',{id: data.id});
        }
    }

    $scope.selectProvider = function(data){
        $state.go('show_provider',{id: data.id});
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }

    if( !$localStorage.clientParams ){
        $localStorage.clientParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'client'
            }
        }
    }

    if( !$localStorage.patientParams ){
        $localStorage.patientParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'patient'
            }
        }
    }

    if( find ){
        $localStorage.clientParams.filter.find = find;
    } else {
        delete $localStorage.clientParams.filter.find;
    }

    if( company_id ){
        $localStorage.clientParams.company_id = company_id;
    } else {
        delete $localStorage.clientParams.company_id;
    }

    if( !$localStorage.contactParams ){
        $localStorage.contactParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'contact'
            }
        }
    }

    if( !$localStorage.webParams ){
        $localStorage.webParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'buyer'
            }
        }
    }

    if( !$localStorage.employeeParams ){
        $localStorage.employeeParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'employee'
            }
        }
    }

    if( !$localStorage.newsletterParams ){
        $localStorage.newsletterParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'newsletter'
            }
        }
    }

    if( !$localStorage.providersParams ){
        $localStorage.providersParams = {
            page : 1,
            count : 15,
            filter : {
                'status': 1,
                'type': 'provider'
            }
        }
    }


    $scope.statuses = [
        {id:'', name:'Todos'}
    ];

    $client_status.query({count:1000, company_id: $localStorage.clientParams.company_id ? $localStorage.clientParams.company_id : ''}).$promise.then(function(response) {
        _.each(response.data, function(data){
            $scope.statuses.push({id:data.id, name:data.name});
        });
        $scope.clientsParams.reload();
    });

    $scope.webParams = new NgTableParams({
        page: $localStorage.webParams.page,
        count: $localStorage.webParams.count,
        filter: $localStorage.webParams.filter
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.webParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.newsletterParams = new NgTableParams({
        page: $localStorage.newsletterParams.page,
        count: $localStorage.newsletterParams.count,
        filter: $localStorage.newsletterParams.filter
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.newsletterParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.patient = new NgTableParams({
        page: $localStorage.patientParams.page,
        count: $localStorage.patientParams.count,
        filter: $localStorage.patientParams.filter,
        company_id : $localStorage.patientParams.company_id ? $localStorage.patientParams.company_id : ''
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.patientParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.clientsParams = new NgTableParams({
        page: $localStorage.clientParams.page,
        count: $localStorage.clientParams.count,
        filter: $localStorage.clientParams.filter,
        company_id : $localStorage.clientParams.company_id ? $localStorage.clientParams.company_id : ''
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.clientParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.providersParams = new NgTableParams({
        page: $localStorage.providersParams.page,
        count: $localStorage.providersParams.count,
        filter: $localStorage.providersParams.filter
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.clientParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.contactsParams = new NgTableParams({
        page: $localStorage.contactParams.page,
        count: $localStorage.contactParams.count,
        filter: $localStorage.contactParams.filter
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.contactParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.employeesParams = new NgTableParams({
        page: $localStorage.employeeParams.page,
        count: $localStorage.employeeParams.count,
        filter: $localStorage.employeeParams.filter
    },
    {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $localStorage.employeeParams = params._params;
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('client').
controller('client.create', ['$scope', '$state', '$client', '$client_status', '$http', '$countryState', '$city', '$tax_regime','$tax_regime_status', '$client_address', 'ngToast', 'showInModal', 'WizardHandler', '$uibModal', '$uibModalInstance', 'Upload', '$sce', 'find', '$localStorage', '$stateParams',
function($scope, $state, $client, $client_status, $http, $countryState, $city, $tax_regime, $tax_regime_status, $client_address, ngToast, showInModal, WizardHandler, $uibModal, $uibModalInstance, Upload, $sce, find, $localStorage, $stateParams) {

    $scope.client       = {
        cpf_cnpj : ''
    }
    $scope.title        = 'Cadastro de Cliente';
    $scope.editing = true;
    $scope.certificate  = {};
    $scope.showInModal  = showInModal;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.PreviousWizard = function(){
        WizardHandler.wizard().previous();
    }
    $scope.NextWizard = function(){
        WizardHandler.wizard().next();
    }

    $scope.checkDocument = function() {
        if( $scope.client.cpf_cnpj ){
            $client.query({cpf_cnpj : $scope.client.cpf_cnpj, count: 1}).$promise.then(function(response){
                if(response.data.length == 1){
                    if( $scope.showInModal ){
                        $uibModalInstance.close(response.data[0]);
                    } else {
                        $state.go('client_show', {
                            id: response.data[0].id
                        });
                    }
                } else {
                    WizardHandler.wizard().next();
                }
            });
        } else {
            WizardHandler.wizard().next();
        }
    }

    $scope.client = {
        client_status_id: {},
        tax_regime_id: {},
        tax_regime_status_id: {},
        status: '1',
        has_pIRRFRet : 0,
        has_pISSRet : 0,
        has_pPrevRet : 0,
        has_pCSLLRet : 0,
        has_pCOFINSRet : 0,
        has_pPISRet :0,
        phone       : find ? find : ''
    }

    $scope.address = {
        zip_code  : '',
        city_id : {},
        state_id : {},
        type     : 'Correspondência'
    }

    $client_status.query({count:1000}).$promise.then(function(response){
        $scope.situation = response.data;
    });

    $countryState.query({count:1000}).$promise.then(function(response){
        $scope.states = response.data;
    });

    $tax_regime.query({count:1000}).$promise.then(function(response){
        $scope.tax_regime = response.data;
    });

    $tax_regime_status.query({count:1000}).$promise.then(function(response){
        $scope.tax_regime_status = response.data;
    });

    $countryState.query({count:1000}).$promise.then(function(response){
        for( var i = 0; i < response.data.length; i++){
            $scope.states.push({
                id: response.data[i].id,
                title: response.data[i].name,
                name: response.data[i].name,
                abbreviation: response.data[i].abbreviation
            });
        }
    });

    $scope.$watch('address.zip_code', function(){
        if($scope.address.zip_code != undefined && $scope.address.zip_code.length == 8){
            // $http.get(api+'client/getCEP/'+ $scope.address.zip_code).then(function(response){
            //     $scope.address.line1        = response.data.logradouro;
            //     $scope.address.neighborhood = response.data.bairro;
            var url         = 'https://viacep.com.br/ws/'+$scope.address.zip_code+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.disable = false;
                    $scope.address.line1        = response.data.logradouro;
                    $scope.address.neighborhood = response.data.bairro;

                }

                // Escolher UF
                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                    $scope.address.state_id = response;
                });

                // Escolher Cidade
                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                    $scope.cities = response.data;
                    $scope.address.city_id = response.data[0];
                });
            });
        }

    });

    $scope.save = function(alertWithoutAddress = false) {
        if( alertWithoutAddress ){
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                    $scope.title = 'Ao cadastrar cliente com dados incompletos impede a emissão de boleto! Deseja continuar?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.confirm = function() {
                        $uibModalInstance.close(true);
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });

            modalInstance.result.then(function(data) {
                if( data === true ){
                    var client = $scope.client;

                    client.client_status_id = $scope.client.client_status_id.id;
                    client.tax_regime_id = $scope.client.tax_regime_id.id;
                    client.tax_regime_status_id = $scope.client.tax_regime_status_id.id;

                    $client.save(client).$promise.then(function(data) {
                        if( $scope.showInModal ){
                            $uibModalInstance.close(data);
                        } else {
                            $state.go('client_show', {
                                id: data.id
                            });
                        }
                    });
                }
            });
        } else {
            var client = $scope.client;

            if (client.indIEDest == 1 && !client.ie) {
                ngToast.danger({
                    content: 'Cliente contribinte a inscrição estadual é obrigatória!',
                    dismissOnTimeout: true,
                    timeout: 6000
                });
                return;
            }

            client.client_status_id = $scope.client.client_status_id.id;
            client.tax_regime_id = $scope.client.tax_regime_id.id;
            client.tax_regime_status_id = $scope.client.tax_regime_status_id.id;
            if ($state.current.name == 'create_provider') {
                client.type_name = true;
            }

            $client.save(client).$promise.then(function(data) {
                if( $scope.address.zip_code.length == 8 ){
                    var client = data;
                    var id = data.id;
                    var address = $scope.address;
                    address.state_id = $scope.address.state_id.id;
                    address.city_id  = $scope.address.city_id.id;
                    address.type     = 'Correspondência';

                    $client_address.save({client_id:id},address).$promise.then(function(data) {
                        if( $scope.showInModal ){
                            $uibModalInstance.close(client);
                        } else {
                            $state.go('client_show', {
                                id: client.id
                            });
                        }
                    });
                } else {
                    if( $scope.showInModal ){
                        $uibModalInstance.close(data.id);
                    } else {
                        $state.go('client_show', {
                            id: data.id
                        });
                    }
                }
            });
        }
    }

    $scope.uploadWithCert = function(){
        if(!$scope.certificate.file)
            return;

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client',
            function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Digite a senha do certificado para continuar';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.input = {
                    label   : 'Senha',
                    type    : 'password',
                    value   : null
                }

                $scope.confirm = function() {
                    $uibModalInstance.close($scope.input);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(data) {
            Upload.upload({
                url: api+'client/with_cert',
                data: {
                    certificate : $scope.certificate.file[0],
                    password    : data.value
                }
            }).then(function (response) {
                $state.go('client_show', {
                    id: response.data.id
                });
            }, function (response) {
                if(response.data.message.indexOf('certificate') !== -1){
                    ngToast.danger({
                        content: 'Certificado ou senha inválidos!',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    $uibModalInstance.dismiss('cancel');
                }
            });
        });
    }

    $scope.confirm = function(){
        $scope.save();
        $uibModalInstance.close($scope.client);
    }

    $scope.cancel = function() {
        if( $scope.showInModal )
            $uibModalInstance.dismiss('cancel');
        else
            $state.go('client',{});
    };
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('client').
controller('client.show', ['$state', '$rootScope','$localStorage', '$scope', '$stateParams', '$uibModal', '$client', '$accounts_plan', '$http', '$account_entry', 'ngToast', '$accounts_plan', '$timeout','$client_status', '$countryState', '$city', '$tax_regime','$tax_regime_status','NgTableParams','$client_address', '$users', '$client_contract','uuid', 'id', 'showInModal', '$uibModalInstance', '$sale', 'Upload', '$clientEvaluation', '$schedule', '$scheduleService', '$clientDentalPlan', '$location', '$client_enrollment', '$sce', 'find', '$client_document_type', '$client_anamnesis', '$clientDentalPlanService',
function($state,$rootScope, $localStorage, $scope, $stateParams, $uibModal, $client, $accounts_plan, $http, $account_entry, ngToast, $accounts_plan, $timeout, $client_status, $countryState, $city, $tax_regime, $tax_regime_status, NgTableParams, $client_address, $users, $client_contract,uuid, id, showInModal, $uibModalInstance, $sale, Upload, $clientEvaluation, $schedule, $scheduleService, $clientDentalPlan, $location, $client_enrollment, $sce, find, $client_document_type, $client_anamnesis, $clientDentalPlanService) {

    $scope.title            = 'Dados do Cliente';
    $scope.showInModal      = showInModal;
    $scope.today            = new Date();
    $scope.selected_month   = $scope.today.getMonth()+1;
    $scope.selected_year    = $scope.today.getFullYear();
    $scope.filter           = {state_id:''};
    $scope.certificate      = {};
    $scope.states = [];
    $scope.cities = [];
    $scope.cities_from = [];
    $scope.tax_regime = [];
    $scope.tax_regime_status = [];
    $scope.sales = [];
    // Schedule variables
    $scope.evaluations  = [];
    $scope.dentalPlans  = [];
    $scope.clientDentalPlans    = [];
    $scope.editing = true;
    $scope.isChangeded = false;
    $scope.waiting          = false;
    $scope.company = $localStorage.company ? $localStorage.company : null;
    $scope.selectedItemsDentals = [];
    $scope.selectedAproves = [];
    $scope.toggleEvaluation = false;
    $scope.changeEnrollments = false;

    $scope.scheduleInProgress = null;

    $tax_regime.query({count:1000}).$promise.then(function(response){
        $scope.tax_regime = response.data;
    });

    $tax_regime_status.query({count:1000}).$promise.then(function(response){
        $scope.tax_regime_status = response.data;
    });

    $client.query({count:10000, client: true}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.client = {
        client_status_id : {},
        tax_regime_id: {},
        tax_regime_status_id: {},
    }

    $scope.client_status_id_options = [
        {id:'11', title:'Adimplente'},
        {id:'12', title:'Inadimplente'}
    ];

    $scope.dropdownConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1,
        keyboardControls: true
    };

    $scope.current_account = {};

    // $scope.$watch('current_account.id', function() {
    //     $scope.loadEntries();
    // });

    $scope.transacted = {
        opened: false
    };

    $scope.entry = {
        type: 'D',
        value: 0,
        transacted_at: new Date(),
        another_account: {}
    }

    $client_status.query({count:1000}).$promise.then(function(response){
        $scope.situation = response.data;
    });

    $scope.id = id;
    $client.get({
        id: ($scope.id ? $scope.id : $stateParams.id)
    }).$promise.then(function(data) {
        data.client_status_id = data.client_status_id ? {id:data.client_status_id} : {};
        data.tax_regime_id = data.tax_regime_id ? {id:data.tax_regime_id} : {};
        data.tax_regime_status_id = data.tax_regime_status_id ? {id:data.tax_regime_status_id} : {};
        data.from_city_id = data.from_city_id ? {id:data.from_city_id} : {};
        data.from_state_id = data.from_state_id ? {id:data.from_state_id} : {};
        data.office_state_id = data.office_state_id ? {id:data.office_state_id} : {};
        data.office_city_id = data.office_city_id ? {id:data.office_city_id} : {};
        data.birthday = moment(data.birthday).toDate();
        data.occupation_since = moment(data.occupation_since).toDate();
        $scope.client = data;

        $scope.loadClientDocumentType();
        $scope.loadClientAnamnesis();
        if( $scope.company.company_type_id == 3 ){
            $scope.loadEvaluations();
            $scope.loadDentalPlans();
            $scope.loadSchedules();
            $scope.getInProgressSchedule();
            $scope.getWaitingSchedule();
            $users.query({count:1000}).$promise.then(function(response){
                $scope.users = response.data;
            })
        }
        //$scope.loadAccountsPlans();
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.userParams = new NgTableParams({
        page: 1,
        count: 15,
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.getUsers($stateParams.id, params.url()).then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.enrollmentParams = new NgTableParams({
        page: 1,
        count: 15,
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.getEnrollments($stateParams.id, params.url()).then(function(data) {
                params.total(data.total);
                data.data.enrollment = {id:data.data.id}
                return data.data;
            });
        }
    });

    $scope.contractsParams = new NgTableParams({
        page: 1,
        count: 15,
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.getContracts($stateParams.id, params.url()).then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.createGuardianToken = function(){
        $scope.client.guardian_token = uuid.v4();
    }

    $scope.copyGuardianToken = function(){

    }

    $scope.addUser = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'add_user.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$user','$stateParams', '$role', 'ngToast',
            function($state, $scope, $uibModalInstance, $user, $stateParams, $role, ngToast) {

                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';
                $scope.passRequired = true;
                $scope.data = {};
                $scope.client = {
                    permission : ''
                };
                $role.query({count:1000, client:true}).$promise.then(function(response) {
                    $scope.roles = response.data;
                });


                $scope.confirm = function() {
                    var permission = {};
                    permission[$scope.client.permission] = true;
                    $users.save(
                        {
                            name       : $scope.data.name,
                            email      : $scope.data.email,
                            password   : $scope.data.password,
                            status     : 1,
                            client_id  : parseInt($stateParams.id),
                            data       : $scope.data,
                            permissions: permission
                        }
                    ).$promise.then(function(data) {
                        $scope.$parent.userParams.reload();
                        $uibModalInstance.dismiss('cancel');
                    }, function(error){
                        switch (error.data.message) {
                            case 'cpf_cnpj_already_exists':
                                $scope.form_add_user.cpf.$setValidity("required",false);
                                ngToast.danger({
                                    content: 'CPF/CNPJ já cadastrado',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                                break;
                            case 'email_already_exists':
                                $scope.form_add_user.email.$setValidity("required",false);
                                ngToast.danger({
                                    content: 'Email já cadastrado',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                                break;
                            default:
                                ngToast.danger({
                                    content: 'Erro ao criar cadastro',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                        }
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.editUser = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'add_user.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$user','$stateParams', '$role', 'ngToast',
            function($state, $scope, $uibModalInstance, $user, $stateParams, $role, ngToast) {

                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';
                $scope.passRequired = false;
                $scope.data = {};
                $scope.client = {
                    permission : ''
                };

                $user.get({id: id}).$promise.then(function(response){
                    $scope.data.id  = response.data.id;
                    $scope.data.name = response.data.name;
                    $scope.data.email = response.email;
                    $scope.data.cpf = response.data.cpf;
                    $scope.data.phone_number = response.data.phone_number;
                    $scope.client = {
                        permission : response.permissions[0].id.toString()
                    };
                });

                $role.query({count:1000, client:true}).$promise.then(function(response) {
                    $scope.roles = response.data;
                });


                $scope.confirm = function() {
                    var permission = {};
                    permission[$scope.client.permission] = true;
                    $users.update( {id: id},
                        {
                            name       : $scope.data.name,
                            email      : $scope.data.email,
                            password   : $scope.data.password,
                            status     : 1,
                            client_id  : parseInt($stateParams.id),
                            data       : $scope.data,
                            permissions: permission
                        }
                    ).$promise.then(function(data) {
                        $scope.$parent.userParams.reload();
                        $uibModalInstance.dismiss('cancel');
                    }, function(error){
                        switch (error.data.message) {
                            case 'cpf_cnpj_already_exists':
                                $scope.form_add_user.cpf.$setValidity("required",false);
                                ngToast.danger({
                                    content: 'CPF/CNPJ já cadastrado',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                                break;
                            case 'email_already_exists':
                                $scope.form_add_user.email.$setValidity("required",false);
                                ngToast.danger({
                                    content: 'Email já cadastrado',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                                break;
                            default:
                                ngToast.danger({
                                    content: 'Erro ao criar cadastro',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                        }
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.selected_users = [];

    $scope.selectUser = function(id)
    {
        if(_.contains($scope.selected_users, id))
        {
            var i = _.indexOf($scope.selected_users, id);
            $scope.selected_users.splice(i,1);
        } else {
            $scope.selected_users.push(id);
        }
    }

    $scope.removeUsers = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja remover esses usuários?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    _.each($scope.$parent.selected_users, function(id, key){
                        $users.delete({
                            id: id
                        }).$promise.then(function(data) {
                            $uibModalInstance.dismiss('cancel');
                            $scope.$parent.userParams.reload();
                        })
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.openEnrollment = function(item = null){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'enrollment.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$http', '$uibModalInstance', '_', '$class_group', '$client_contract', '$client_enrollment',
            function($scope, $http, $uibModalInstance, _, $class_group, $client_contract, $client_enrollment) {
                $scope.changeValue    = false;
                $scope.cancel_text    = 'Cancelar';
                $scope.success_text   = 'Criar matricula';
                $scope.student        = {client:{id:$scope.client.id}};
                $scope.enrollment     = item;

                $scope.template_client = {
                    buttonDefaultText       : 'Escolha o aluno',
                    checkAll                : 'Todas',
                    uncheckAll              : 'Nenhuma',
                    selectionCount          : 'selecionada(s)',
                    selectionOf             : '/',
                    searchPlaceholder       : 'Pesquisar',
                    dynamicButtonTextSuffix : 'selecionada(s)'
                };
                $scope.selectConfig = {
                    template            : '{{option.cpf_cnpj | brCpfCnpj}} - {{option.name}}',
                    buttonClasses       : 'btn btn-default btn-block',
                    scrollable          : true,
                    scrollableHeight    : '200px',
                    enableSearch        : true,
                    styleActive         : true,
                    showCheckAll        : false,
                    showUncheckAll      : false,
                    displayProp         : 'name',
                    idProp              : 'id',
                    selectionLimit      : 1,
                    closeOnSelect       : true,
                    smartButtonMaxItems : 1,
                }

                $scope.addClient = function(){
                    var modalInstance = $uibModal.open({
                        animation       : true,
                        templateUrl     : server+"/views/m/client/create",
                        size            : 'lg',
                        controller      : 'client.create',
                        backdrop        : 'static',
                        resolve         : {
                            showInModal : true
                        }
                    });
                    modalInstance.result.then(function(data) {
                        $scope.clients.push(data);
                        $scope.protocol_type    = 'client';
                        $scope.client           = data;
                        $scope.student.client.id = $scope.client.id;
                    });
                }
                $scope.getClientContract = function() {
                    $client_contract.query({
                        status: 'opened',
                        client_id: $scope.client.id,
                        hasEnrollment: false
                    }).$promise.then(function(response){
                        $scope.client_contracts = null;
                        $scope.client_contracts = response.data;
                    });
                }
                $scope.getClientContract();

                $class_group.query({count : 1000}).$promise.then(function(response){
                    $scope.class_groups = response.data;
                });

                $scope.confirm = function() {
                    $scope.enrollment.client_id = $scope.student.client.id;
                    $scope.enrollment.status = 'confirmed';
                    $client_enrollment.save($scope.enrollment).$promise.then(function(response){
                        if (response.limit_class == 'full') {
                            return false;
                        }
                        $scope.enrollmentParams.reload();
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

                $scope.openContract = function(contract)
                {
                    var modalInstance = $uibModal.open({
                        animation      : true,
                        ariaLabelledBy : 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl    : 'contract.modal.html',
                        controller     : 'contract',
                        size           : 'lg',
                        resolve        : {
                            client: function(){
                                return angular.copy($scope.client)
                            },
                            contract: contract
                        }
                    });

                    modalInstance.result.then(function(response) {
                        $scope.getClientContract();
                    });

                }
            }]
        });
    }   

    $scope.changeEnrollment = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'enrollment.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$http', '$uibModalInstance', '_', '$class_group', '$client_contract', '$client_enrollment',
            function($scope, $http, $uibModalInstance, _, $class_group, $client_contract, $client_enrollment) {
                $scope.changeValue       = false;
                $scope.cancel_text       = 'Cancelar';
                $scope.success_text      = 'Trocar Turma';
                $scope.student           = {client:{id:$scope.client.id}};
                $scope.enrollment        = data.contract;
                $scope.changeEnrollments = true;

                $scope.template_client = {
                    buttonDefaultText       : 'Escolha o aluno',
                    checkAll                : 'Todas',
                    uncheckAll              : 'Nenhuma',
                    selectionCount          : 'selecionada(s)',
                    selectionOf             : '/',
                    searchPlaceholder       : 'Pesquisar',
                    dynamicButtonTextSuffix : 'selecionada(s)'
                };
                $scope.selectConfig = {
                    template            : '{{option.cpf_cnpj | brCpfCnpj}} - {{option.name}}',
                    buttonClasses       : 'btn btn-default btn-block',
                    scrollable          : true,
                    scrollableHeight    : '200px',
                    enableSearch        : true,
                    styleActive         : true,
                    showCheckAll        : false,
                    showUncheckAll      : false,
                    displayProp         : 'name',
                    idProp              : 'id',
                    selectionLimit      : 1,
                    closeOnSelect       : true,
                    smartButtonMaxItems : 1,
                }

                $class_group.query({count : 1000}).$promise.then(function(response){
                    $scope.class_groups = response.data;
                });

                $scope.confirm = function() {
                    $client_enrollment.changeClassGroup({class_group_id: $scope.enrollment.class_group_id, enrollment_id: $scope.enrollment.enrollment.id}).then(function(response){
                        console.log(response);
                        $scope.changeEnrollments = false;
                        $scope.enrollmentParams.reload();
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };
            }]
        });
    }

    $scope.addDocumentType = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'newDocumentType.modal.html',
            controller     : 'new_document_type',
            size           : 'lg',
            resolve        : {
                client: function(){
                    return angular.copy($scope.client)
                }
            }
        });

        modalInstance.result.then(function(data) {
            $scope.waiting = true;
            $scope.documentSave = data;
            $scope.documentSave.onlyShow = true;
            $client_document_type.getInitialDocument($scope.documentSave).then(function(response){
                if(response){
                    $scope.waiting = false;
                    $scope.showDocument(response, true, true);
                }
            });
        });

    }

    $scope.deleteClientDocumentType = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$user', 'ngToast', '$client_document_type',
            function($state, $scope, $uibModalInstance, $user, ngToast, $client_document_type) {

                $scope.title = 'Deseja remover o documento?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $users.query({count: 1000}).$promise.then(function(response){
                    $scope.users = response.data;
                });

                $scope.bCredntials  = true;
                $scope.credentials = {
                    user_id     : '',
                    password    : ''
                }

                $scope.confirm = function() {
                    $http.post(api+'auth/authenticate_password', $scope.credentials).then(function(response){
                        $scope.passwordConfirm = response.data.password;
                        if ($scope.passwordConfirm == true) {
                            $users.get({ id: $scope.credentials.user_id, checkPermissionSchedule: true}).$promise.then(function(data){
                                $client_document_type.delete({id: id}).$promise.then(function(response){
                                    ngToast.success({
                                        content: 'Documento excluído!',
                                        dismissOnTimeout: true,
                                        timeout: 3000
                                    });
                                    $uibModalInstance.close(response);
                                    $scope.$parent.cancel();
                                });
                            }, function(error) {
                                ngToast.danger({
                                    content: error.data.message,
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                            });
                        }
                    },function(error){
                        ngToast.danger({
                            content: 'Senha inválida',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $uibModalInstance.close();
                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addAnamnesisType = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'newAnamnesisType.modal.html',
            controller     : 'new_anamnesis_type',
            size           : 'lg',
            resolve        : {
                client: function(){
                    return angular.copy($scope.client)
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadClientAnamnesis();
        });

    }

    $scope.openContract = function(contract)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'contract.modal.html',
            controller     : 'contract',
            size           : 'lg',
            resolve        : {
                client: function(){
                    return angular.copy($scope.client)
                },
                contract: contract
            }
        });

        modalInstance.result.then(function(response) {
            $scope.contractsParams.reload();
        });

    }

    $scope.selected_contracts = [];

    $scope.selectContract = function(id)
    {
        if(_.contains($scope.selected_contracts, id))
        {
            var i = _.indexOf($scope.selected_contracts, id);
            $scope.selected_contracts.splice(i,1);
        } else {
            $scope.selected_contracts.push(id);
        }
    }

    $scope.confirmContract = function(contract)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'confirm_contract.modal.html',
            controller     : 'confirm_contract',
            size           : 'lg',
            resolve        : {
                contract: function(){
                    return angular.copy(contract)
                }
            }
        });

        modalInstance.result.then(function(response) {
            $scope.contractsParams.reload();
        });
    }

    $scope.deleteContract = function(contract){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_contract', '$client_enrollment',
            function($state, $scope, $uibModalInstance, $client_contract, $client_enrollment) {

                $scope.title = 'Deseja remover esse contrato?<br><h4>Isso removerá os recebíveis referentes a este contrato</h4>';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    if (contract.enrollment) {
                        $client_enrollment.delete({id : contract.enrollment.id}).$promise.then(function(response){
                            $scope.enrollmentParams.reload();
                        }, function(error){
                            $uibModalInstance.dismiss('cancel');
                        });
                    }
                    $client_contract.delete({id : contract.id}).$promise.then(function(response){
                        $scope.$parent.contractsParams.reload();
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printContract = function(contract)
    {
        $http.post(api+'client_contract/'+contract.id+'/print',{},{responseType: 'arraybuffer'}).then(function(response){
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

            $scope.printing = false;
        });
    }

    $countryState.query({count:1000}).$promise.then(function(response){
        for( var i = 0; i < response.data.length; i++){
            $scope.states.push({
                id: response.data[i].id,
                title: response.data[i].name,
                name: response.data[i].name,
                abbreviation: response.data[i].abbreviation
            });
        }
    });

    $scope.$watch('filter.state_id', function(){
        if($scope.filter.state_id != ''){
            $scope.loadCities({count:9000, state_id:$scope.filter.state_id });
        }
    });

    $scope.teste = function(){
        if($scope.filter.state_id != ''){
            return {city_id : 'select'}
        }
        else{
            //corrigir
            return '<span class="glyphicon glyphicon-minus"></span>';
        }
    }

    //loagind cities
    $scope.loadCities = function(query)
    {
        $city.query(query).$promise.then(function(response){
            $scope.cities.splice(0, $scope.cities.length);
            for( var i = 0; i < response.data.length; i++){
                $scope.cities.push({
                    id: response.data[i].id,
                    title: response.data[i].name,
                    name: response.data[i].name
                });
            }
        });
    }

    $scope.$watch('client.from_state_id.id', function(){
        if($scope.client.from_state_id && $scope.client.from_state_id.id){
            $city.query({count:9000, state_id:$scope.client.from_state_id.id}).$promise.then(function(response){
                $scope.cities_from.splice(0, $scope.cities_from.length);
                for( var i = 0; i < response.data.length; i++){
                    $scope.cities_from.push({
                        id: response.data[i].id,
                        title: response.data[i].name,
                        name: response.data[i].name
                    });
                }
            });
        }
    });

    $scope.$watch('client.office_state_id.id', function(){
        if($scope.client.office_state_id && $scope.client.office_state_id.id){
            $scope.loadCities({count:9000, state_id:$scope.client.office_state_id });
            $city.query({count:9000, state_id:$scope.client.office_state_id.id}).$promise.then(function(response){
                $scope.cities.splice(0, $scope.cities.length);
                for( var i = 0; i < response.data.length; i++){
                    $scope.cities.push({
                        id: response.data[i].id,
                        title: response.data[i].name,
                        name: response.data[i].name
                    });
                }
            });
        }
    });

    $scope.updateOfficeAddress = function(){
        if($scope.client.office_zip != undefined){
            var url         = 'https://viacep.com.br/ws/'+$scope.client.office_zip+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.client.office_address    = response.data.logradouro;
                    $scope.client.office_neighborhood = response.data.bairro;
                    // Escolher UF
                    $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                        $scope.client.office_state_id = response;
                    });
                    // Escolher Cidade
                    $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                        $scope.cities = response.data;
                        $scope.client.office_city_id = response.data[0];
                    });
                    $scope.isChangeded = true;
                    $scope.confirmUpdate();
                }else{
                    ngToast.danger({
                       content: 'Por favor informe um CEP válido',
                       dismissOnTimeout: true,
                       timeout: 5000
                    });
                };
            });
        }
    };

    /**
    * ADDRESS
    */
    //ADD Address
    $scope.addAddress = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'add_address.modal.html',
            size: 'md',
            scope: $scope,
            controller: 'add_address',
            resolve        : {
                client_id: function(){
                    return angular.copy($scope.client.id)
                }
            }
        });
    }

    //TABLE Address
    $scope.addressTable = new NgTableParams({
        page: 1,
        count: 15,
        client_id: ($scope.id ? $scope.id : $stateParams.id)
    },
    {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client_address.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                if( params._params.filter.state_id ){
                    $scope.filter.state_id = params._params.filter.state_id
                }
                return data.data;
            });
        }
    });

    //DELETE Address
    $scope.confirmDeleteAddress = function(address_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: 'delete_address',
            resolve        : {
                client_id: function(){
                    return angular.copy($scope.client.id)
                },
                address_id : function(){
                    return angular.copy(address_id);
                }
            }
        });
    };

    //UPDATE address
    $scope.openModalUpdateAddress = function(address_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'add_address.modal.html',
            size: 'lg',
            scope: $scope,
            controller: 'update_address',
            resolve        : {
                client_id: function(){
                    return angular.copy($stateParams.id)
                },
                address_id : function(){
                    return angular.copy(address_id);
                }
            }
        });
    }

    $scope.toggleIsChangeded = function(){
        $scope.isChangeded = true;
    }

    $scope.confirmUpdate = function() {
        if ($scope.isChangeded) {
            $scope.waiting = true;
            var client = angular.copy($scope.client);
            client.client_status_id = client.client_status_id.id;
            client.tax_regime_id = client.tax_regime_id.id;
            client.tax_regime_status_id = client.tax_regime_status_id.id;
            client.from_state_id = client.from_state_id.id;
            client.from_city_id = client.from_city_id.id;
            client.office_city_id = client.office_city_id.id;
            client.office_state_id = client.office_state_id.id;
            var array = [];
            array.push($client.update({
                id: client.id
            }, client).$promise.then(function(data) {
                $scope.waiting = false;
                $scope.isChangeded = false;
                if( $scope.certificate.file ) {
                    array.push(Upload.upload({
                        url: api+'client/'+client.id+'/save_cert',
                        data: {certificate: $scope.certificate.file[0]}
                    }).then(function (response) {

                    }, function (response) {
                        if(response.data.message.indexOf('certificate') !== -1){
                            ngToast.danger({
                                content: 'Certificado ou senha inválidos!',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });

                        }
                    }));
                }
            }));
        }
    }


    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client = $scope.$parent.client;

                $scope.confirm = function() {
                    $client.delete({id:client.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('client');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.makeFinancial = function(contract) {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'financial_contract.modal.html',
            controller     : 'financial_contract',
            size           : 'lg',
            resolve        : {
                contract: function(){
                    return angular.copy(contract)
                }
            }
        });

        modalInstance.result.then(function(response) {
            $scope.enrollmentParams.reload();
            $scope.contractsParams.reload();
        });
    }

    $scope.showFinancialContract = function(contract) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/client/modal_financial_contract",
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '$client_contract', '_', '$q', function($scope, $uibModalInstance, $financial_entry, $client_contract, _, $q) {
                $scope.selectedEntrys = [];
   
                $scope.title = 'Histórico financeiro';
                $scope.cancel_text = 'Cancelar';
                $scope.entry = contract.financials;

                $scope.confirm = function() {
                    $client_contract.finishContract(contract.id).then(function(response){
                        $scope.$parent.enrollmentParams.reload();
                        $scope.$parent.contractsParams.reload();
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });

                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                //Modal para informar pagamento
                $scope.updateStatusPaid = function(entry, item){
                    var data = {
                        paid            : 1
                    };
            
                    if( entry.recurrent_days > 0 ){
                        data.transacted_at = moment(entry.transacted_at).format('Y-M-D');
                    }
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm_paid.modal.html',
                        size: 'sm',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance', '$client', '$financial_entry', '$financial_account', '$suggestion' , function($state, $scope, $uibModalInstance, $client, $financial_entry, $financial_account, $suggestion) {
        
                            $scope.title = 'Confirmar pagamento';
                            $scope.cancel_text = 'Cancelar';
                            $scope.success_text = 'Pagar';
                            $scope.payment_method = '';

                            $scope.payments = [
                                {id:'money', title:'Dinheiro'},
                                {id:'credit_card', title:'Crédito'},
                                {id:'pos_debit', title:'Débito'},
                                {id:'Boleto', title:'Boleto'},
                                {id:'link', title:'Link Pagamento'},
                                {id:'Pix', title:'Pix'},
                                {id:'payment_later', title:'Posterior'},
                            ];

                            $suggestion.query({type: 'payment_other'}).$promise.then(function(response){
                                $scope.data_payment_suggestion = response;
                                if($scope.data_payment_suggestion.length > 0){
                                    _.each($scope.data_payment_suggestion, function(suggestion){
                                        $scope.payments.push({
                                            id: 'other', title: suggestion.description
                                        });   
                                    })     
                                }
                            });
        
                            //Carregar contas
                            $financial_account.query().$promise.then(function(response){
                                $scope.accounts = response.data;
                            });

                            $scope.pay   = {
                                paid_at : moment.now(),
                                financial_account_id : entry.financial_account_id,
                                payment_method : ''
                            }
        
                            $scope.confirm = function() {
                                $scope.pay.payment_method = $scope.payment_method;
                                $uibModalInstance.close($scope.pay);
                            }
        
                            $scope.cancel = function() {
                                item.paid = 0;
                                $uibModalInstance.dismiss('cancel');
                            };
        
                        }]
                    });
        
                    modalInstance.result.then(function(pay) {
                        data.paid_at = moment(pay.paid_at).format('Y-M-D');
                        data.financial_account_id = pay.financial_account_id;
                        data.payment_method = pay.payment_method;
                        $financial_entry.updateStatusPaid(item.id, data).then(function(response){
                            ngToast.success({
                                content: 'Status atualizado',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            // $scope.entry = contract.financials;
                        }, function(error){
                            var index = _.indexOf($scope.entry[entry.type_id], entry);
                            $scope.entry[entry.type_id][index].paid = 0;
                            ngToast.danger({
                                content: 'Erro ao atulizar status',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                        });
                    });
                }    

            }]
        });
    }

    $scope.finishContract = function(contract){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_entry', '$client_contract', '_', '$q', function($scope, $uibModalInstance, $financial_entry, $client_contract, _, $q) {

                $scope.title = 'Deseja encerrar esse contrato?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client = $scope.$parent.client;

                $scope.confirm = function() {
                    $client_contract.finishContract(contract.id).then(function(response){
                        $scope.$parent.enrollmentParams.reload();
                        $scope.$parent.contractsParams.reload();
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
        
    }

    $scope.confirm = function(){
        $uibModalInstance.close($scope.client);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.showSale = function(){
        $sale.query({client_id: $scope.client.id}).$promise.then(function(data){

            var salePaidFail = _.filter(data.data, function(num){ return num.status === 'opened'; });
            var sumF = _.reduce(salePaidFail, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidFail = sumF;

            var salePaidCanceled = _.filter(data.data, function(num){ return num.status === 'canceled'; });
            var sumC = _.reduce(salePaidCanceled, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidCanceled = sumC;

            var salePaidOut = _.filter(data.data, function(num){ return num.status === 'succeeded'; });
            var sum = _.reduce(salePaidOut, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidOut = parseFloat(sum);

            var saleNotPaidPaymentLater = _.filter(data.data, function(num){ return num.status_formated === 'Posterior' && num.status != 'canceled'&& (parseFloat(num.payment_later_value) != parseFloat(num.amount)); });
            var paidPaymentLater        = _.reduce(saleNotPaidPaymentLater, function(memo, row){return memo + parseFloat(row.payment_later_value); }, 0);
            var sumP = _.reduce(saleNotPaidPaymentLater, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.saleNotPaidPaymentLater = parseFloat(sumP) - parseFloat(paidPaymentLater);
            $scope.salesTableParams.reload();
        });
    }

    $scope.salesTableParams = new NgTableParams({
        page: 1,
        count: Infinity,
        client_id: $stateParams.id
    },
    {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $sale.query(params.url()).$promise.then(function(data) {
                return data.data;
            });
        }
    });

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'opened', title:'Aberta'},
        {id:'canceled', title:'Cancelada'},
        {id:'succeeded', title:'Pago'}
    ];

    $scope.payments = [
        {id:'', title:'Todos'},
        {id:'Dinheiro', title:'Dinheiro'},
        {id:'Credito', title:'Crédito'},
        {id:'Debito', title:'Débito'},
        {id:'Boleto', title:'Boleto'},
        {id:'Outros', title:'Outros'},
        {id:'Pix', title:'Pix'},
        {id:'payment_later', title:'Posterior'},
    ];

    $scope.selectedStatus = function(id){
        $scope.status = [{id: id}];
    }

    $scope.viewPayments = function(id) {
        $sale.get({id : id}).$promise.then(function(response){
            var sale = response;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'view_payments.modal.html',
                size: 'lg',
                scope: $scope,
                backdrop        : 'static',
                controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$q',
                function($scope, $uibModalInstance, $client, Upload, $q) {

                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'OK';
                    $scope.server = server;


                    $scope.sale = sale;
                    if ($scope.sale.created_at) {
                        $scope.hour = $scope.sale.created_at.substr(11);
                    }

                    $scope.copy = function() {
                        var copyText = document.getElementById("linkSale");

                        copyText.select();
                        copyText.setSelectionRange(0, 99999);

                        document.execCommand("copy");
                    }

                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.close();
                    };

                }]
            });
        });
    }

    $scope.print = function(row){
        $scope.printing = true;
        $http.get(api+'sale/'+row+'/print').then(function(response){
            $scope.printing = false;
            $rootScope.print(response.data);
            if($scope.company.category_print == 1){
              setTimeout(function () {
                  window.onfocus = $scope.printCategory(row);
              }, 100);
            }
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });

    }

    $scope.showInvoice = function(invoice){
        if( invoice.status != 'authorized' ){
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : server+"/views/m/invoice/show",
                controller     : 'invoice.show',
                size           : 'lg',
                backdrop        : 'static',
                resolve        : {
                    invoice_id     : invoice.id,
                    showInModal    : true
                }
            });
            modalInstance.result.then(function() {
                $scope.tableParams.reload();
                $uibModalInstance.close();
            });
        } else {
            $invoice.print(invoice.id).then(function(response){
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);

                // Create a link element on body
                // var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
                var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');

                angular.element(document.body).append(link);
                link[0].click();
                link.remove();
            });
        }
    }

    $scope.loadClientDocumentType = function() {
        $client_document_type.get({client_id :$scope.client.id}).$promise.then(function(response) {
            $scope.clientDocumentTypes = response.data;
        });
    }

    $scope.loadClientAnamnesis = function() {
        $client_anamnesis.get({client_id :$scope.client.id}).$promise.then(function(response) {
            $scope.clientAnamnesis = response.data;
        });
    }

    $scope.showDocument = function(doc, edit = false, save = false) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showDoc.modal.html',
            size: 'lg',
            backdrop        : 'static',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.editing = edit ? true : false;
                $scope.title = 'Documento';
                $scope.document = doc;
                var type = {
                    id: $scope.document.type
                }
                $scope.document.type = type;
                
                $scope.editDocument = function(){
                    $scope.editing = !$scope.editing;
                    $scope.title = 'Editar Documento';
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.save = function(){
                    $scope.waiting = true;
                    if (save) {
                        console.log($scope.document);
                        $client_document_type.save($scope.document).$promise.then(function(response){
                            $scope.loadClientDocumentType();
                            $uibModalInstance.close();
                            ngToast.success({
                                content: 'Documento salvo com sucesso!',
                                dismissOnTimeout: true,
                                timeout: 1000
                            });
                            $scope.waiting = false;
                        }, function(error) {
                            ngToast.danger({
                                content: 'Erro ao salvar',
                                dismissOnTimeout: true,
                                timeout: 1500
                            });
                            $scope.waiting = false;
                        });
                    } else{
                        $client_document_type.update({id: $scope.document.id}, $scope.document).$promise.then(function(response){
                            $scope.loadClientDocumentType();
                            $uibModalInstance.close();
                            ngToast.success({
                                content: 'Edição salva com sucesso!',
                                dismissOnTimeout: true,
                                timeout: 1000
                            });
                            $scope.waiting = false;
                        }, function(error) {
                            ngToast.danger({
                                content: 'Erro ao salvar',
                                dismissOnTimeout: true,
                                timeout: 1500
                            });
                            $scope.waiting = false;
                        });
                    }
                }

                $scope.print = function() {
                    $scope.printing = true;
                    $client_document_type.print($scope.document.id).then(function(response){
                        $rootScope.print(response);
                        $scope.printing = false;
                    }, function(error) {
                        ngToast.danger({
                            content: 'Sem dados para impressão!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $scope.printing = false;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });

        modalInstance.result.then(function() {
            $scope.loadClientDocumentType();
        });
    }

    $scope.showAnamnesis = function(anamnesis) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showAnamnesis.modal.html',
            size: 'lg',
            backdrop        : 'static',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Anamnese';

                $scope.answers_list = anamnesis.answers_list;

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.print = function() {
                    $scope.printing = true;
                        $client_anamnesis.print(anamnesis.id).then(function(response){
                            console.log(response);
                            $scope.printing = false;
                            $rootScope.print(response);
                            
                        }, function(error) {
                            ngToast.danger({
                                content: 'Sem dados para impressão!',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            $scope.printing = false;
                        });
                    return;
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });

        modalInstance.result.then(function() {
            $scope.loadClientDocumentType();
        });
    }

    $scope.loadEvaluations = function() {
        $clientEvaluation.getEvaluations({client_id: $scope.client.id}).then(function(response){
            $scope.evaluations = response;
        });
    }

    $scope.loadDentalPlans = function() {
        $clientDentalPlan.query({client_id: $scope.client.id}).$promise.then(function(response){
            $scope.dentalPlans = response;
        });
    }

    $scope.loadSchedules = function() {
        $schedule.query({params : { patient_id : $scope.client.id}}).$promise.then(function(response){
            $scope.clientDentalPlans = response;
        });
    }

    $scope.viewEvaluation = function(row){
        $clientEvaluation.query({client_id: $scope.client.id, date: row.evaluationCreated, professional_id: row.id, count: 1000}).$promise.then(function(response){
            row.selected = true;
            row.evaluation = response.data;
        }, function(error){
            row.selected = false;
            row.evaluation = [];
            ngToast.danger({
                content: 'Sem avaliações!',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.closeEvaluation = function(row){
        row.selected = false;
    }


    $scope.viewSchedule = function(id) {
        $schedule.get({id : id}).$promise.then(function(response){
            var start_at = new Date(response.start_at);
            var end_at   = new Date(response.end_at);
            var schedule = {
                id              : response.id,
                status          : response.status,
                description     : response.description,
                patient         : response.patient.name,
                professional    : response.professional,
                value           : response.value,
                attended        : null,
                services        : response.services,
                payment_type    : response.payment_type,
                start_at        : start_at,
                end_at          : end_at
            }
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    :  server+'/views/m/schedule/schedule',
                controller     : 'schedule.show',
                size           : 'lg',
                resolve        : {
                    event: schedule,
                    origin : function(){
                        return 'client';
                    }
                }
            });
        });
    }

    $scope.addEvaluation = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+'/views/m/client/modal_evaluation',
            size: 'lg',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$q', '$clientEvaluation', '$service', '$suggestion',
            function($scope, $uibModalInstance, $client, Upload, $q, $clientEvaluation, $service, $suggestion) {

                $scope.type = "month";
                $scope.dropdownTranslation = {
                    buttonDefaultText: 'Selecione',
                    checkAll: 'Todas',
                    uncheckAll: 'Nenhuma',
                    selectionCount: 'selecionada(s)',
                    selectionOf: '/',
                    searchPlaceholder: 'Pesquisar',
                    dynamicButtonTextSuffix: 'selecionada(s)'
                };

                $scope.dropdownConfigFace = {
                    buttonClasses: 'btn btn-default btn-block',
                    scrollable: true,
                    scrollableHeight: '200px',
                    enableSearch: true,
                    styleActive: true,
                    showCheckAll: false,
                    showUncheckAll: true,
                    displayProp: 'name',
                    idProp: 'name',
                    selectionLimit: 5,
                    closeOnSelect: true,
                    smartButtonMaxItems: 1,
                    keyboardControls: true
                };

                $scope.dropdownConfigService = {
                    buttonClasses: 'btn btn-default btn-block',
                    scrollable: true,
                    scrollableHeight: '200px',
                    enableSearch: true,
                    styleActive: true,
                    showCheckAll: false,
                    showUncheckAll: true,
                    displayProp: 'name',
                    idProp: 'id',
                    selectionLimit: 1,
                    closeOnSelect: true,
                    smartButtonMaxItems: 1,
                    keyboardControls: true
                };

                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';
                $scope.dentalPlan = false;

                $scope.evaluations = [];
                $scope.faces = [
                    { name: 'Distal' },
                    { name: 'Mesial' },
                    { name: 'Vestibular' },
                    { name: 'Lingual/Palatal' },
                    { name: 'Oclusal' }
                ];

                $suggestion.query({type: 'evaluation'}).$promise.then(function(response){
                    $scope.evaluationDescriptions = response;
                });

                $scope.selectTeeth = function(teeth) {
                    $scope.evaluations.push({
                        where: teeth,
                        client_id: $scope.client.id,
                        description_id : null,
                        description    : null,
                        faces : []
                    });
                }

                $scope.removeTeeth = function(index) {
                    $scope.evaluations.splice(index, 1);
                }

                $scope.selectDescription = function(selected, row){
                    if( selected ){
                        var index = $scope.evaluations.findIndex((evaluation) => {return evaluation.where == row.where;});
                        if( selected.originalObject.custom ){
                            var data = {
                                description : selected.title,
                                type        : 'evaluation'
                            }
                            $suggestion.save(data).$promise.then(function(response){
                                $scope.evaluationDescriptions.push(response);
                                $scope.evaluations[index].description = response;
                                $scope.evaluations[index].description_id = response.id;
                            });
                        } else {
                            if( selected.originalObject.id ){
                                if( index >= 0 )
                                    $scope.evaluations[index].description = selected.originalObject;
                                    $scope.evaluations[index].description_id = selected.originalObject.id;
                            } else {
                                $scope.$broadcast('angucomplete-alt:clearInput', 'inputDescription');
                            }
                        }
                    }
                }

                $scope.confirm = function() {
                    var data = $scope.evaluations;
                    $clientEvaluation.save({evaluations : data}).$promise.then(function(response){
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss();
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.loadEvaluations();
        });
    }

    $scope.removeEvaluations = function(){
        var data = $scope.selectedItemsDentals;
        if (data.length > 0) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', '$http', 'ngToast', '$user_role', '$user', '$localStorage',
                function($state, $scope, $uibModalInstance, $client, $http, ngToast, $user_role, $user, $localStorage) {
    
                    $scope.title = 'Usuários com permissão para excluir avaliações!';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Confirmar';

                    $scope.filter = {
                        count : 10,
                        permission_name : 'evaluation_delete'
                    };

                     $scope.credentials = {
                        user_id     : '',
                        password    : ''
                    }

                    $users.permissions($scope.filter).then(function(response){
                        $scope.permissionsHeight = response;

                        $scope.logedUser = _.find($scope.permissionsHeight, function(row){ return row.id === $localStorage.user.id; });

                        if ($scope.logedUser) {
                            $scope.credentials.logedUserHasPermission = true;
                            $scope.pCredntials  = false;
                            $scope.bReason      = true;
                            $scope.title        = 'Aponte o motivo da exclusão!';
                        }else{
                            $scope.pCredntials  = true;
                            $scope.bReason      = true;
                        }
                    });
                    
                    $scope.confirm = function() {
                        $users.confirm($scope.credentials).then(function(response){
                            _.each(data, function(row){
                                $scope.waiting  = true;
                                if (row.id) {
                                    $clientEvaluation.delete(row.id).then(function(response){
                                        $uibModalInstance.close();
                                        $scope.loadEvaluations();
                                        $scope.waiting  = false;
                                    });
                                }
                            })
                        }, function(error) {
                            ngToast.danger({
                                content: 'Erro ao remover item',
                                dismissOnTimeout: true,
                                timeout: 1000
                            });
                        });  
                    }
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });
        }
    }

    $scope.selectedServicesDentals = function(row){
        if (row) {
            var same = _.find($scope.selectedItemsDentals, function(item){ return item.id == row.id;});
            if (same) {
                var index = $scope.selectedItemsDentals.map(function(x) {return x.id; }).indexOf(row.id);
                if( index >= 0 )
                    $scope.selectedItemsDentals.splice(index, 1);
                ngToast.danger({
                    content: 'Avaliação removida!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                return;
            }else{
                $scope.selectedItemsDentals.push(row);
            }
        }
    }

    $scope.makeDentalPlan = function(){
        if ($scope.selectedItemsDentals.length > 0) {
            console.log($localStorage.company);
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : server+"/views/m/client/modal_dental_plan",
                controller     : 'dental_plan_controller',
                size           : 'md',
                backdrop        : 'static',
                resolve        : {
                    dentalPlan     : function(){
                        return {
                            client_id   : $scope.client.id,
                            evaluations : $scope.selectedItemsDentals,
                            description  : $localStorage.company.dental_plan_description
                        };
                    },
                    action   : function(){
                        return 'create';
                    }
                }
            });

            modalInstance.result.then(function() {
                $scope.selectedItemsDentals = [];
                $scope.loadEvaluations();
            });
        } else {
            ngToast.danger({
                content: 'Selecione avaliações para gerar o orçamento',
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    }

    $scope.editDentalPlan = function(row){
        row.edit = true;
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/client/modal_dental_plan",
            controller     : 'dental_plan_controller',
            size           : 'md',
            backdrop        : 'static',
            resolve        : {
                dentalPlan     : function(){
                    return row;
                },
                find           : function(){
                    return 'editDentalPlan';
                },
                action   : function(){
                    return 'edit';
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadDentalPlans();
        });
    }

    $scope.sendEmail = function(row) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm_email.modal.html',
            size: 'sm',
            backdrop        : 'static',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title_text = 'Enviar por email?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar';
                $scope.email = row.client ? row.client.email : '';

                $scope.confirm = function() {
                    var data = {
                        email : $scope.email,
                        name  : row.client ? row.client.name : 'Consumidor'
                    };
                    $clientDentalPlan.sendEmail(row.id, data).then(function(response){
                        ngToast.success({
                            content: 'Email enviado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao enviar email',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printDentalPlan = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
            function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                $scope.title = 'Parametros de impressão';
                $scope.title_name = 'Nome';
                $scope.success_text = 'Confirmar';
                $scope.dentalParams = true;
                $scope.params_print = [];

                $scope.printParams = [
                    {id:'items_value_true', name:'Valor dos procedimentos'},
                    {id:'total_value_true', name:'Valor total'},
                    {id:'professional_true', name:'Dentista'},
                    {id:'payment_type_true', name:'Forma de pagamento'}
                ];
                _.each($scope.printParams, function(item){
                    $scope.params_print.push(item.id);
                })
                $scope.data = $scope.printParams;

                $scope.select = function(id){
                    var item = _.find($scope.params_print, function(row){
                        return row === id;
                    });
                    var index = $scope.params_print.indexOf(item);
                    if (item) {
                        $scope.params_print.splice(index, 1);
                    }else{
                        $scope.params_print.push(id);
                    }
                }
                
                $scope.confirm = function(){
                    $uibModalInstance.close($scope.params_print);
                }

            }]
        });
        modalInstance.result.then(function(data) {
            if( data ) {
                $scope.dentalPrintParams = data;
            }

            $scope.printing = true;
            $clientDentalPlan.print(id, {params: $scope.dentalPrintParams}).then(function(response){
                $rootScope.print(response);
            }, function(error){
                ngToast.danger({
                    content: 'Sem dados para impressão!',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.printing = false;
            });
        });
    }

    $scope.replicate = function(plan){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state','$scope', '$uibModalInstance', '$clientDentalPlan', function($state, $scope, $uibModalInstance, $clientDentalPlan) {

                $scope.title = 'Deseja Replicar o orçamento?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $clientDentalPlan.replicate(plan.id, plan).then(function(data) {
                        $scope.loadDentalPlans();
                        $uibModalInstance.close();
                    });
                    
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]   
        });
    }

    $scope.selectedRow = function(id){
        if (id) {
            $scope.selectedAproves.push(id);
        }
    }

    $scope.updateDentalPlan = function(row, status){

        if (status == 'succeeded') {

            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : server+"/views/m/client/modal_dental_plan",
                controller     : 'dental_plan_controller',
                size           : 'md',
                backdrop        : 'static',
                resolve        : {
                    dentalPlan     : function(){
                        return row;
                    },
                    find           : function(){
                        return 'approvalDentalPlan';
                    },
                    action   : function(){
                        return 'approval';
                    }
                    
                }
            });

            modalInstance.result.then(function() {
                $scope.loadDentalPlans();
            });
        }else{
            var data = {
                status : status
            }
            $clientDentalPlan.update({id: row.id ? row.id : row}, data).$promise.then(function(response){
                ngToast.success({
                    content: 'Orçamento atualizado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.selectedAproves = [];
                $scope.loadDentalPlans();
            }, function(error) {
                ngToast.danger({
                    content: 'Erro ao atualizar orçamento!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }
       
        
    }

    $scope.addScheduleService = function(){
        var origin = 'schedule';
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'add_service.modal.html',
            controller      : 'add_service',
            controllerAs    : '$ctrl',
            size            : 'lg',
            backdrop        : 'static',
            resolve         : {
                qtd : function(){
                    return 1;
                },
                origin : function(){
                    return origin;
                }
            }
        });

        modalInstance.result.then(function(data){
            if(!Array.isArray(data)){
                var array = [];
                array.push(data);
                data = array;
            }
            var aToSave = [];
            _.each(data, function(item){
                var toPost = {
                    'service_id'            : item.obj.id,
                    'external_reference'    : item.obj.external_reference,
                    'name'                  : item.name,
                    'description'           : item.name,
                    'value'                 : item.value > 0 ? item.value : item.obj.value_calculed,
                    'quantity'              : item.quantity > 0 ? item.quantity : 1,
                    'total'                 : item.total > 0 ? item.total : item.obj.value_calculed * item.quantity,
                    'note'                  : item.note,
                    'schedule_id'           : $scope.scheduleInProgress.schedule_id
                };
                aToSave.push(toPost);
            });
            $schedule.update(
                {id : $scope.scheduleInProgress.id},
                {services : aToSave}
            ).$promise.then(function(response) {
                $scope.getInProgressSchedule();
                $scope.getWaitingSchedule();
            });
        })
    }

    $scope.checkScheduleService = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            backdrop        : 'static',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Concluiu o procedimento Dr.?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.bReason      = true;
                $scope.titleReason  = 'Alguma observação?';

                $scope.credentials = {
                    reason : ''
                }

                $scope.confirm = function() {
                    $uibModalInstance.close($scope.credentials.reason);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });

        modalInstance.result.then(function(data){
            var data = {
                status : 'finished',
                finish_note : data
            }
            $scheduleService.update({id : id}, data).$promise.then(function(response) {
                    $scope.getInProgressSchedule();
                ngToast.success({
                    content: 'Procedimento finalizado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }, function(error) {
                ngToast.danger({
                    content: 'Erro ao finalizar procedimento!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
            // $clientDentalPlanService.update({id : id}, data).$promise.then(function(response) {
            //     $scope.getInProgressSchedule();
            //     ngToast.success({
            //         content: 'Procedimento finalizado com sucesso!',
            //         dismissOnTimeout: true,
            //         timeout: 3000
            //     });
            // }, function(error) {
            //     ngToast.danger({
            //         content: 'Erro ao finalizar procedimento!',
            //         dismissOnTimeout: true,
            //         timeout: 3000
            //     });
            // });
        })
    }

    $scope.getInProgressSchedule = function() {
        $schedule.query({params: { patient_id : $scope.client.id, status : 'in_progress'}}).$promise.then(function(response){
            if( response.length > 0 ) {
                $scope.scheduleInProgress = response[0];
                if ($scope.scheduleInProgress.services.length > 0 && (!$scope.scheduleInProgress.services.some(row => row.status == 'opened' || row.status == null))) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm.modal.html',
                        size: 'sm',
                        backdrop        : 'static',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance', 
                            function($state, $scope, $uibModalInstance) {
                                $scope.title = 'Todos os procedimentos foram finalizados, deseja finalizar o atendimento?';
                                $scope.cancel_text = 'Não';
                                $scope.success_text = 'Sim';

                                $scope.confirm = function() {
                                    $uibModalInstance.close();
                                }

                                $scope.cancel = function() {
                                    $uibModalInstance.dismiss('cancel');
                                };
                            }
                        ]
                    });

                    modalInstance.result.then(function(){
                        var data = {
                            status : 'finished'
                        }
                        
                        $schedule.update({id : $scope.scheduleInProgress.id}, data).$promise.then(function(response) {
                            $scope.getInProgressSchedule();
                            ngToast.success({
                                content: 'Atendimento finalizado com sucesso!',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                        }, function(error) {
                            ngToast.danger({
                                content: 'Erro ao finalizar atendimento!',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                        });
                    });
                }
                // $clientDentalPlan.query({ schedule_id: $scope.scheduleInProgress.id }).$promise.then(function(response) {
                //     if( response.length > 0 ) {
                //         $scope.dentalPlan = response[0];
                //         $scope.dentalId = response[0].id;
                //         $scope.dentalPlanServices = response[0].services;
                
                //     }
                // });

            } else {
                $scope.scheduleInProgress = null;
            }
        });
    }

    $scope.getWaitingSchedule = function() {
        $schedule.query({params: { patient_id : $scope.client.id, status : 'checked'}}).$promise.then(function(response){
            if( response.length > 0 ) {
                $scope.scheduleWaiting = response[0];
            } else {
                $scope.scheduleWaiting = null;
            }
        });
    }

}]);

/**
* ADD, DELETE, UPDATE ADDRESS CLIENT
*/
angular.module('client')
.controller('add_address', ['$scope', '$http', '$countryState', '$city', '$uibModalInstance', '$client_address', 'client_id', '$sce',
function($scope, $http, $countryState, $city, $uibModalInstance, $client_address, client_id, $sce) {

    $scope.cancel_text = 'Cancelar';
    $scope.success_text = 'Concluído';

    $scope.data = {
        city_id : {},
        state_id : {},
        type     : 'Correspondência'
    };


    $scope.$watch('data.zip_code', function(){
        if($scope.data.zip_code != undefined){
            var url         = 'https://viacep.com.br/ws/'+$scope.data.zip_code+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
            // $http.get(api+'client/getCEP/'+ $scope.data.zip_code).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.data.line1        = response.data.logradouro;
                    $scope.data.neighborhood = response.data.bairro;

                    // Escolher UF
                    $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                        $scope.data.state_id = response;
                        $scope.data.state_id.id = response.id;
                    });

                    document.getElementById('NR').focus();
                    
                    // Escolher Cidade
                    $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                        $scope.cities = response.data;
                        $scope.data.city_id = response.data[0];
                        $scope.data.city_id.id = response.data[0].id;
                    });
                }else{
                    alert('Dados do cep incompletos, por favor complete o endereço');
                };


            });
        }
    });

    $scope.$watch('data.state_id.id', function(){
        if($scope.data.state_id && $scope.data.state_id.id){
            $city.query({count:9000, state_id:$scope.data.state_id.id}).$promise.then(function(response){
                $scope.cities.splice(0, $scope.cities.length);
                for( var i = 0; i < response.data.length; i++){
                    $scope.cities.push({
                        id: response.data[i].id,
                        title: response.data[i].name,
                        name: response.data[i].name
                    });
                }
            });
        }
    });

    $scope.confirm = function() {

        $scope.data.state_id = $scope.data.state_id.id;
        $scope.data.city_id = $scope.data.city_id.id;

        $client_address.save({client_id:client_id},$scope.data).$promise.then(function(data) {
            $scope.addressTable.reload();
            $uibModalInstance.dismiss('cancel');
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('client')
.controller('contract', ['$scope', '$http', '$countryState', '$city', '$uibModalInstance', '$client_contract', 'client', 'contract', '_', '$contract_type',
function($scope, $http, $countryState, $city, $uibModalInstance, $client_contract, client, contract, _, $contract_type) {

    $scope.changeValue = false;
    $scope.cancel_text = 'Cancelar';
    $scope.success_text = 'Criar contrato';
    $scope.client = client;
    $scope.datePicker = {
        start_at : {},
        deadline : {}
    }
    if( contract ){
        $scope.contract = {
            id              : contract.id,
            type_id         : contract.type_id,
            start_at        : new moment(contract.start_at)._d,
            deadline        : new moment(contract.deadline)._d,
            total           : contract.total,
            status          : contract.status,
            favorite_day    : contract.favorite_day
        };
    } else {
        $scope.contract = {
            type_id         : '',
            start_at        : new moment()._d,
            deadline        : new moment(moment.now()).add(1, 'year')._d,
            total           : 0,
            status          : 'opened',
            favorite_day    : ''
        };
    }

    $contract_type.query({count: 1000}).$promise.then(function(response){
        $scope.types = response.data;
    });

    $scope.selectedDateStart = function(){
        var type = _.find($scope.types, { id : $scope.contract.type_id});
        if (type) {
            if (type.make_financial_prepaid == 1) {
                $scope.contract.deadline = new moment($scope.contract.start_at).add(type.expiration_days, 'day')._d;
            }
        }

    }

    $scope.selectedType = function(){
        var type = _.find($scope.types, { id : $scope.contract.type_id});
        $scope.contract.total = type.calculed_value;
        if (type.make_financial_prepaid == 1) {
            $scope.contract.start_at = new moment().startOf('month')._d
            $scope.contract.deadline = new moment($scope.contract.start_at).add(type.expiration_days, 'day')._d;
        }
        $scope.changeValue = ( type.value_type == 'manual');
    }

    $scope.confirm = function() {
        if( $scope.contract.id ){
            $client_contract.update({ id: $scope.contract.id }, $scope.contract).$promise.then(function(response){
                $uibModalInstance.close();
            });
        } else {
            var contract = {
                client_id       : $scope.client.id,
                value           : $scope.contract.total,
                total           : $scope.contract.total,
                status          : 'opened',
                type_id         : $scope.contract.type_id,
                start_at        : $scope.contract.start_at,
                deadline        : $scope.contract.deadline,
                payment_method  : 'in_cash',
                favorite_day    : $scope.contract.favorite_day
            }
            $client_contract.save(contract).$promise.then(function(response){
                $uibModalInstance.close();
            });
        }
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('client')
.controller('financial_contract', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', 'contract', '$financial_entry', '$financial_category', '$financial_account', '_',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, contract, $financial_entry, $financial_category, $financial_account, _) {
    $scope.title        = 'Confirmar dados de contrato';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';
    $scope.frequence    = 'months';
    $scope.contract = contract;
    $scope.financials = [];
    $scope.transacted = [];
    $scope.financial_account_id = null;
    $scope.category = {};
    $scope.optionsPicker = {
        // minDate: moment().toDate(),
    };

    $financial_category.query({type: 'C', count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });

    $financial_account.query().$promise.then(function(response){
        $scope.accounts = response.data;
        $scope.financial_account_id = _.where($scope.accounts, {favorite: 1})[0].id;
    }, function(error){
        console.log(error);
    });

    $scope.confirm = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Tem certeza que deseja confirmar este contrato?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    // $scope.enrollmentParams.reload();
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            var data = {
                installments            : $scope.financials,
                financial_account_id    : $scope.financial_account_id,
                financial_category_id   : $scope.category.id
            };
            $financial_entry.makeContract(data).then(function(response){
                $uibModalInstance.close(response);
            }, function(error)  {
                console.log(error);
            });
        });

    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.installment_status = 'frequence';
    $scope.contract.installment = 1;

    $scope.getInstallments = function()
    {
        $scope.financials = [];

        var total = parseFloat($scope.contract.total);
        var duration = moment.duration(moment($scope.contract.deadline).diff(moment($scope.contract.start_at))).months() + 1;
        var deadline = $scope.contract.deadline;
        $scope.installments = [];
        if ($scope.installment_status == 'installment') {
            for (var i = 1; i <= duration; i++) {
                $scope.installments.push(i);
            }
            total = total / $scope.contract.installment;
            $scope.frequence = 'month';
            deadline = moment($scope.contract.start_at).add($scope.contract.installment, 'month').format('YYYY-MM-DD HH:mm:ss');
            if($scope.contract.installment == 1) $scope.frequence = 'one';
        }
        var date_start      = moment($scope.contract.start_at);
        deadline            = moment(deadline);
        // var deadline        = moment($scope.contract.deadline);
        var count           = 1;
        var sumTotal        = 0;
        var installments    = $scope.contract.installment;

        if( date_start.date() > $scope.contract.favorite_day ){
            date_start = date_start.add(1, 'month');
        }
        date_start.date($scope.contract.favorite_day);
        if(date_start.isoWeekday() == 'saturday') date_start.add(2, 'days');
        if(date_start.isoWeekday() == 'sunday') date_start.add(1, 'days');
        if(!$scope.contract.type.make_financial_prepaid){
            date_start = date_start.add(1, 'M');
        }

        $scope.transacted[count] = {};
        $scope.financials.push({
            contact_id      : $scope.contract.client_id,
            contract_id     : $scope.contract.id,
            count           : count,
            transacted_at   : date_start.toDate(),
            paid_at         : date_start.toDate(),
            value           : total,
            description     : $scope.contract.type.name+' #'+$scope.contract.id
        });

        count++;
        sumTotal = sumTotal + parseFloat(total);
        if( $scope.frequence != 'one' )
            date_start.add(1, $scope.frequence);
        date_start.date($scope.contract.favorite_day);
        if(date_start.isoWeekday() == 6) date_start.add(2, 'days');
        if(date_start.isoWeekday() == 7) date_start.add(1, 'days');


        if($scope.frequence != 'one' ) while( date_start < deadline &&  $scope.frequence != 'one' ) {
            $scope.transacted[count] = {};
            $scope.financials.push({
                contact_id      :  $scope.contract.client_id,
                contract_id     : $scope.contract.id,
                count           : count,
                transacted_at   : date_start > moment() ? date_start.toDate() : null,
                paid_at         : date_start > moment() ? date_start.toDate() : null,
                value           : total,
                description     : $scope.contract.type.name+' #'+$scope.contract.id
            });
            count++;
            sumTotal = sumTotal + parseFloat(total);
            if( $scope.frequence != 'one' )
                date_start.add(1, $scope.frequence);
            date_start.date($scope.contract.favorite_day);
            if(date_start.isoWeekday() == 6) date_start.add(2, 'days');
            if(date_start.isoWeekday() == 7) date_start.add(1, 'days');
        }

        if(!$scope.contract.type.make_financial_prepaid && $scope.frequence != 'one'){
            $scope.transacted[count] = {};
            $scope.financials.push({
                contact_id      :  $scope.contract.client_id,
                contract_id     : $scope.contract.id,
                count           : count,
                transacted_at   : date_start > moment() ? date_start.toDate() : null,
                paid_at         : date_start > moment() ? date_start.toDate() : null,
                value           : total,
                description     : $scope.contract.type.name+' #'+$scope.contract.id
            });
            count++;
            sumTotal = sumTotal + parseFloat(total);
            if( $scope.frequence != 'one' )
                date_start.add(1, $scope.frequence);
            date_start.date($scope.contract.favorite_day);
            if(date_start.isoWeekday() == 6) date_start.add(2, 'days');
            if(date_start.isoWeekday() == 7) date_start.add(1, 'days');
        }

        if( $scope.contract.type.has_additional_financial == 1 ){
            var numberAdditionals = $scope.contract.type.number_additional_financial;
            var sum               = 0;

            // Calcular 13, levando em consideração 1 ano, revisar!!
            sumTotal = ( total / 12 ) * ( count - 1 );

            for (var i = 1; i <= numberAdditionals; i++) {
                var financial = angular.copy($scope.financials[$scope.financials.length-i]);
                financial.count = financial.count + i;
                financial.value = sumTotal/numberAdditionals;
                sum = sum + parseFloat(financial.value);
                $scope.transacted[financial.count] = {};
                $scope.financials.push(financial);
            }
            if(sum != sumTotal){
                if( sum > sumTotal ){
                    $scope.financials[$scope.financials.length-i].value = $scope.financials[$scope.financials.length-i].value + parseFloat(sum-sumTotal);
                } else {
                    $scope.financials[$scope.financials.length-i].value = $scope.financials[$scope.financials.length-i].value + parseFloat(sumTotal-sum);
                }
            }
        }
    }

    $scope.getInstallments();

    $scope.selectEditCategory = function(selected)
    {
        if( selected ){
            if( selected.originalObject.custom ){
                var category = {
                    type        : 'C',
                    name        : selected.title
                };
                $financial_category.post(category).$promise.then(function(response){
                    $scope.category = response;
                    $scope.categories.push(response);
                });
            } else {
                if( selected.originalObject.id )
                    $scope.category = selected.originalObject;
                else {
                    $scope.$broadcast('angucomplete-alt:clearInput');
                }
            }
        }
    }
}]);

angular.module('client')
.controller('confirm_contract', ['$scope', '$http', '$countryState', '$city', '$uibModalInstance', '$client_contract', 'contract',
function($scope, $http, $countryState, $city, $uibModalInstance, $client_contract, contract) {

    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Confirmar';
    $scope.title_text   = 'Contrato';

    $scope.contract = contract;

    $scope.confirm = function() {
        $client_contract.confirm($scope.contract.id).then(function(response){
            $uibModalInstance.close();
            // $scope.enrollmentParams.reload();

        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('client')
.controller('delete_address', ['$state', '$scope', '$uibModalInstance', '$client_address', 'client_id', 'address_id',function($state, $scope, $uibModalInstance, $client_address, client_id, address_id) {

    $scope.title = 'Deseja remover esse endereço?';
    $scope.cancel_text = 'Não';
    $scope.success_text = 'Sim';

    $scope.confirm = function() {
        $client_address.delete({client_id:client_id, id:address_id},$scope.data).$promise.then(function(data) {
            $scope.addressTable.reload();
            $uibModalInstance.dismiss('cancel');
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('client')
.controller('update_address',['$scope','$http','$countryState', '$uibModalInstance','$city','$state','$client_address', 'client_id','address_id', '$sce',function($scope, $http, $countryState, $uibModalInstance, $city, $state, $client_address, client_id, address_id, $sce) {

    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';
    $scope.states       = [];
    $scope.data = {
        city_id : {
            id: ''
        },
        state_id : {
            id: ''
        },
        type     : 'Correspondência'
    };

    $countryState.query({count:1000}).$promise.then(function(response){
        for( var i = 0; i < response.data.length; i++){
            $scope.states.push({
                id: response.data[i].id,
                title: response.data[i].name,
                name: response.data[i].name,
                abbreviation: response.data[i].abbreviation
            });
        }
    });

    //Get address
    $client_address.get({client_id: client_id, id: address_id}).$promise.then(function(data) {
        $scope.data.zip_code     = data.zip_code;
        $scope.data.line1        = data.line1;
        $scope.data.number       = data.number;
        $scope.data.line2        = data.line2;
        $scope.data.neighborhood = data.neighborhood;
        $scope.data.city_id.id   = data.city_id;
        $scope.data.type         = data.type;
        $scope.data.state_id.id  = data.state_id;
        
    });

    $scope.$watch('data.zip_code', function(){
        if($scope.data.zip_code != undefined){
            var url         = 'https://viacep.com.br/ws/'+$scope.data.zip_code+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
            // $http.get(api+'client/getCEP/'+ $scope.data.zip_code).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.data.line1        = response.data.logradouro;
                    $scope.data.neighborhood = response.data.bairro;
                    $scope.data.city_name    = response.data.localidade;
        
                    // Escolher UF
                    $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                        $scope.data.state_id = response;
                        $scope.data.state_id.id = response.id;
                    });
        
                    document.getElementById('NR').focus();
                    
                    // Escolher Cidade
                    $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                        $scope.cities = response.data;
                        $scope.data.city_id = response.data[0];
                        $scope.data.city_id.id = response.data[0].id;
                    });
                }else{
                    alert('Dados do cep incompletos, por favor complete o endereço');
                };

            });
        }

    });

    $scope.$watch('data.state_id.id', function(){
        if($scope.data.state_id && $scope.data.state_id.id){
            $city.query({count:9000, state_id:$scope.data.state_id.id}).$promise.then(function(response){
                $scope.cities.splice(0, $scope.cities.length);
                for( var i = 0; i < response.data.length; i++){
                    $scope.cities.push({
                        id: response.data[i].id,
                        title: response.data[i].name,
                        name: response.data[i].name
                    });
                }
            });
        }
    });


    $scope.confirm = function() {
        $scope.data.zip      = $scope.data.zip_code;
        $scope.data.state_id = $scope.data.state_id.id;
        $scope.data.city_id  = $scope.data.city_id.id;
        
        $client_address.update({client_id:client_id, id:address_id},$scope.data).$promise.then(function(data) {
            $scope.addressTable.reload();
            $uibModalInstance.dismiss('cancel');
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

angular.module('client')
.controller('dental_plan_controller',
['$state', '$scope', '$uibModalInstance', '$client', 'dentalPlan', '$clientDentalPlan', '$uibModal', 'ngToast', '$localStorage', 'find', 'action',
function($state, $scope, $uibModalInstance, $client, dentalPlan, $clientDentalPlan, $uibModal, ngToast, $localStorage, find, action) {

    
    $scope.cancel_text = 'Cancelar';
    $scope.action = action;
    switch ($scope.action) {
        case 'approval':
            $scope.title = 'Quais serão aprovados?';
            $scope.success_text = 'Aprovar';
            break;

        case 'create':
            $scope.title = 'Orçamento';
            $scope.success_text = 'Salvar';
            break;

        case 'edit':
            $scope.title = 'Editar orçamento';
            $scope.success_text = 'Salvar';
            break;
    
        default:
            $scope.title = 'Editar orçamento';
            $scope.success_text = 'Salvar';
            break;
    }

    $scope.evaluationSelected = [];
    $scope.servicesPlanDental = [];
    $scope.company            = $localStorage.company;
    $scope.dentalPlan         = dentalPlan;

    if( !$scope.dentalPlan.services )
        $scope.dentalPlan.services = [];

    $scope.loadDentalPlan = function() {
        $clientDentalPlan.get({id: $scope.dentalPlan.id}).$promise.then(function(response){
            if (response.status === 'succeeded') 
                $scope.title = 'Orçamento aprovado';
            
            $scope.dentalPlan = response;
            if (dentalPlan.description != null) {
                $scope.dentalPlan.description = dentalPlan.description;
            }else{
                $scope.dentalPlan.description = $scope.company.dental_plan_description;
            }
            if( !$scope.dentalPlan.payments ){
                $scope.dentalPlan.payments = [];
            }else{
                $scope.dentalPlan.payments = response.payments ? JSON.parse(response.payments) : [];
            }
        });
    }
    $scope.loadDentalPlan();

    $scope.filterServices = function(id) {
        return $scope.dentalPlan.services.filter((row) => { return row.client_evaluation_id == id });
    }

    $scope.countServices = function(id) {
        return $scope.dentalPlan.services.filter((row) => { return row.client_evaluation_id == id }).length;
    }

    $scope.getSuccessText = function() {
        if ($scope.action == 'approval'){ 
            if ($scope.dentalPlan.services.filter((row) => { return row.approved;}).length == $scope.dentalPlan.services.length ){
                return 'Aprovar';
            } else {
                return 'Aprovar parcial';
            }
        }

        return $scope.success_text; 
    }

    $scope.addService = function(row){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'add_service.modal.html',
            controller      : 'add_service',
            controllerAs    : '$ctrl',
            size            : 'lg',
            backdrop        : 'static',
            resolve         : {
                origin : function(){
                    return 'client_evaluation';
                }
            }
        });

        modalInstance.result.then(function(data){

            if(!Array.isArray(data)){
                var array = [];
                array.push(data);
                data = array;
            }
            _.each(data, function(item){
                $scope.dentalPlan.services.push({
                    'client_evaluation_id'  : row ? row.id : null,
                    'service_id'            : item.obj.id,
                    'external_reference'    : item.obj.external_reference,
                    'name'                  : item.name,
                    'value'                 : item.value > 0 ? item.value : item.obj.value_calculed,
                    'quantity'              : item.quantity > 0 ? item.quantity : 1,
                    'total'                 : item.total > 0 ? item.total : item.obj.value_calculed * item.quantity,
                    'description'           : item.note ? item.note : '-',
                    'discount'              : parseFloat(item.obj.value_calculed - item.value)
                });
            });

            $scope.evaluationSelected.push($scope.selected);
        });
    }

    $scope.confirmDental = function() {
        if ($scope.dentalPlan.services.length <= 0) {
            ngToast.danger({
                content: 'Insira o procedimento!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }
        if( $scope.dentalPlan.id ){
            if ($scope.action == 'approval'){
                $scope.dentalPlan.status = 'succeeded';
            }
            $clientDentalPlan.update({id : $scope.dentalPlan.id}, $scope.dentalPlan).$promise.then(function(response){
                ngToast.success({
                    content: 'Orçamento atualizado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.selectedItemsDentals = [];
                $uibModalInstance.close($scope.dentalPlan);

            }, function(error) {
                ngToast.danger({
                    content: 'Erro ao salvar orçamento!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        } else {
            $clientDentalPlan.save($scope.dentalPlan).$promise.then(function(response){
                ngToast.success({
                    content: 'Orçamento criado com sucesso!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $uibModalInstance.close();

            }, function(error) {
                ngToast.danger({
                    content: 'Erro ao salvar orçamento!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        }
    }

    $scope.removeService = function(row) {
        var index = $scope.dentalPlan.services.indexOf(row);
        if( index >= 0 )
            $scope.dentalPlan.services.splice(index, 1);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.addPayment = function(){
        if (!$scope.dentalPlan.id) {
            $scope.dentalPlan.payments = [];
            $scope.dentalPlan.value_total = _.reduce($scope.dentalPlan.services, function(memo, row){ return parseFloat(memo) + parseFloat(row.value); }, 0);
        }
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/cashier/payments",
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance','_', 'ngToast', '$rootScope',
            function($scope, $uibModalInstance, _, ngToast, $rootScope) {

                $scope.title            = 'Forma de pagamento';
                $scope.cancel_text      = 'Cancelar';
                $scope.success_text     = 'Selecionar';
                $scope.minClausule      = moment().format('yyyy-MM-DD');
                $scope.newPayments      = [];
                $scope.has_new_payment  = false;

                $scope.company          = $scope.$parent.company;

                $scope.remainder = function(){
                    return _.reduce($scope.$parent.dentalPlan.payments, function(memo, row){ return parseFloat(memo) + parseFloat(row.value); }, 0);
                }

                $scope.startValues = function(){
                    $scope.boletos  = [];
                    $scope.payments = $scope.$parent.dentalPlan.payments;
                    $scope.payment  = {
                        payment_method      : null,
                        payment_formated    : 'Outros',
                        payment_description : 'Outros',
                        value               : $scope.$parent.dentalPlan.value_total - $scope.remainder(),
                        charges             : 1,
                        payment_other_id    : null
                    }
                    $scope.totalPayment = $scope.remainder();
                }
                $scope.startValues();

                $scope.removePayment = function(index, bCredntials){
                    if( bCredntials) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: 'confirm.modal.html',
                            size: 'sm',
                            scope: $scope,
                            controller: ['$state', '$scope', '$uibModalInstance', '$client', '$http', 'ngToast', 
                            function($state, $scope, $uibModalInstance, $client, $http, ngToast) {
                
                                $scope.title = 'Usuários com maiores permissões de desconto!';
                                $scope.cancel_text = 'Cancelar';
                                $scope.success_text = 'Confirmar';
                                $scope.permissionsHeight = [];

                                $users.query({'permission_name' : 'cashier_delete', count: 100}).$promise.then(function(response){
                                    $scope.permissionsHeight = response.data;
                                });
                
                                $scope.pCredntials  = true;
                                $scope.credentials = {
                                    user_id     : '',
                                    password    : ''
                                }
                
                                $scope.confirm = function() {
                                    $users.confirm($scope.credentials).then(function(response){
                                        $scope.$parent.dentalPlan.payments.splice(index,1);
                                        $scope.startValues();
                                        ngToast.success({
                                            content: 'Pagamento removido',
                                            dismissOnTimeout: true,
                                            timeout: 1000
                                        });
                                        $uibModalInstance.close();
                                    }, function(error) {
                                        ngToast.danger({
                                            content: 'Erro ao remover pagamento',
                                            dismissOnTimeout: true,
                                            timeout: 1000
                                        });
                                    });
                                    
                                }

                                $scope.cancel = function() {
                                    $uibModalInstance.dismiss('cancel');
                                };
                
                            }]
                        });
                    } else {
                        $scope.$parent.dentalPlan.payments.splice(index,1);
                        $scope.startValues();
                    }
                }


                $scope.selectPayment = function(payment, payment_formated = null,){
                    if( $scope.payment.value < 0 ){
                        ngToast.danger({
                            content: 'Não é possivel inserir novas formas de pagamento',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        return;
                    }

                    $scope.payment.amount_paid  = $scope.payment.value;

                    if( payment == 'boleto'){
                        if(!$scope.$parent.dentalPlan.client){
                            ngToast.danger({
                                content: 'Boleto não disponível para consumidor',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                            return;
                        }
                        $http.get(api+'client/'+$scope.$parent.dentalPlan.client_id+'/check_register').then(function(response){
                            $scope.payment.payment_method       = payment;
                            $scope.payment.payment_description  = payment_formated;
                            $scope.payment.payment_formated     = payment_formated;
                            $scope.changeCharges();
                        }, function(error){
                            ngToast.danger({
                                content: 'Boleto não disponível para este cliente',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                            return;
                        });
                    } else {
                        $scope.payment.payment_method           = payment;
                        $scope.payment.payment_formated         = payment_formated;
                        $scope.payment.payment_description      = payment_formated;

                        if ($scope.payment.payment_method == 'money'){
                            $rootScope.setFocusID('valueAmount_paid');
                        }
                        if ($scope.payment.payment_method == 'pix'){
                            $rootScope.setFocusID('valueAmount_paid');
                        }
                        if ($scope.payment.payment_method == 'pos_credit' ){
                            $rootScope.setFocusID('chargesField');
                        }
                        if ($scope.payment.payment_method == 'pos_debit' ){
                            $rootScope.setFocusID('valueMoney');
                        }
                        if ($scope.payment.payment_method == 'others'){
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: 'selectItem.modal.html',
                                size: 'sm',
                                scope: $scope,
                                controller: ['$state', '$scope', '$uibModalInstance', '$client', '$http', 'ngToast', 
                                function($state, $scope, $uibModalInstance, $client, $http, ngToast) {
                                    
                                    $scope.title = 'Outras formas de pagamento';
                                    $scope.descriptionSuggestionsList =[];
                                    $suggestion.query({type: 'payment_other'}).$promise.then(function(response){
                                        $scope.data = response;
                                    });

                                    $scope.selectRow = function(data){
                                        if(data){
                                            $scope.payment.payment_method           = payment;
                                            $scope.payment.payment_formated         = data.description;
                                            $scope.payment.payment_description      = data.description;
                                            $scope.payment.payment_other_id         = data.id;
                                        }
                                        $uibModalInstance.dismiss('cancel');
                                    }
            
                                    $scope.cancel = function() {
                                        $uibModalInstance.dismiss('cancel');
                                    };
                    
                                }]
                            });
                        }

                        $scope.changeCharges();
                    }
                }

                $scope.changeCharges = function(){

                    $scope.boletos = [];
                    if($scope.payment.payment_method !== 'boleto' && $scope.payment.payment_method !== 'others')
                        return;

                    var date        = moment().hour(0).add(1,'days');
                    var sumValue    = 0;
                    var value       = ($scope.payment.value / $scope.payment.charges).toFixed(2);

                    for (var i = 0; i < $scope.payment.charges; i++) {
                        $scope.boletos.push({
                            payment_description     : $scope.payment.payment_description + ' ' +(parseInt(i)+1),
                            value                   : value,
                            boleto_expiration_date  : date.toDate()
                        });
                        date = moment(date).hour(0).add(1, 'months');
                        sumValue = $rootScope.sumFloat(sumValue, value);
                    }

                    if(sumValue > $scope.payment.value)
                        $scope.boletos[0].value = $scope.boletos[0].value - (sumValue - $scope.payment.value).toFixed(2);

                    if(sumValue < $scope.payment.value)
                        $scope.boletos[0].value = $rootScope.sumFloat( $scope.boletos[0].value, ($scope.payment.value - sumValue).toFixed(2) );
                }

                $scope.confirm = function() {
                    if( !$scope.payment.payment_method ||
                        $scope.payment.value <= 0 ||
                        $scope.payment.charges < 1 
                    ) return;

                    $scope.payment.payment_description = $scope.payment.payment_formated;
                    if( $scope.payment.payment_method == 'boleto'  || $scope.payment.payment_method == 'others'){
                        if(_.find($scope.boletos, function(obj){return parseFloat(obj.value) < 0.01})){
                            ngToast.danger({
                                content: 'O valor da parcela não pode ser igual a zero!',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                            return;
                        }
                        _.each( $scope.boletos, function(boleto){
                            $scope.$parent.dentalPlan.payments.push({
                                payment_description     : $scope.payment.payment_description,
                                payment_formated        : $scope.payment.payment_formated,
                                payment_method          : $scope.payment.payment_method,
                                value                   : boleto.value,
                                boleto_expiration_date  : boleto.boleto_expiration_date,
                                payment_other_id       : $scope.payment.payment_other_id,
                                charges                 : 1
                            });
                        });
                        $scope.startValues();
                        if( $scope.payment.value == 0 ){
                            $uibModalInstance.close();
                        }
                        return;
                    }

                    if($scope.payment.payment_method == 'money' && $scope.payment.amount_paid != $scope.payment.value || $scope.payment.payment_method == 'pix' && $scope.payment.amount_paid != $scope.payment.value){

                        if ($scope.payment.amount_paid < $scope.payment.value){
                            $scope.payment.value   = $scope.payment.amount_paid;
                        }

                        if ($scope.payment.amount_paid > $scope.payment.value){
                            $scope.payment.change_value  = $scope.payment.amount_paid - $scope.payment.value;
                            $scope.$parent.newSale.change = $scope.payment.change_value;
                        }

                    }

                    if ($scope.payment.payment_method != 'money' && $scope.payment.amount_paid < $scope.payment.value || $scope.payment.payment_method != 'pix' && $scope.payment.amount_paid < $scope.payment.value){
                        $scope.payment.value = $scope.payment.amount_paid;
                        ngToast.danger({
                            content: 'Não é possivel fazer um pagamento maior que R$' + $scope.payment.value,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        return;
                    }

                    $scope.$parent.dentalPlan.payments.push($scope.payment);
                    $scope.startValues();
                    
                    if( $scope.payment.value == 0 ){
                        $uibModalInstance.close();
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);

angular.module('client').
factory("$clientEvaluation", function($resource, $q, $http) {
    var resource = $resource(api + 'client_evaluation/resource/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getEvaluations = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'client_evaluation/get', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.delete = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'client_evaluation/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('client').
factory("$client", function($resource, $q, $http) {
    var resource = $resource(api + 'client/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getUsers = function(id, params)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/get_users', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getTypes = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'client/get_types', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getContracts = function(id, params)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/get_contracts', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getEnrollments = function(id, params)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/get_enrollments', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getClientEnrollments = function(id, params)
    {
        var deferred = $q.defer();
        $http.save(api+'client_enrollment', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    resource.getCards = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/get_cards').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.storeCards = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/store_card', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getCertsToInvalided = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'client/cert_to_invalided', {'params' : params }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.disableAlertCert = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'client/'+id+'/disable_alert_cert').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('client').
factory("$client_enrollment", function($resource, $q, $http) {
    var resource = $resource(api + 'client_enrollment/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.changeClassGroup = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'client_enrollment/change_class_group', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('client').
factory("$clientDentalPlan", function($resource, $q, $http) {
    var resource = $resource(api + 'client_dental_plans/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getSuggestionService = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'client_dental_plans/get_suggestion_service', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.sendEmail = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'client_dental_plans/'+id+'/send_email', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.print = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'client_dental_plans/'+id+'/print', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.replicate = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'client_dental_plans/'+id+'/replicate_dental_plan', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('client').
factory("$clientDentalPlanService", function($resource, $q, $http) {
    var resource = $resource(api + 'client_dental_plan_service/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});

angular.module('client').
factory("$suggestion", function($resource, $q, $http) {

    var resource = $resource(api + 'suggestion', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }

    });
    return resource;
});
