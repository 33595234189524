angular.module('ecommerce_component', ['ui.router', 'ngFileUpload','angular-uuid']);

angular.module('ecommerce_component').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('ecommerce_component', {
        url         : "/ecommerce_component",
        templateUrl : server+"/views/m/ecommerce_component",
        controller  : 'ecommerce_component.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Componentes Ecommerce'
        }
    })

    .state('ecommerce_component_create', {
        url         : "/ecommerce_component/create",
        templateUrl : server+"/views/m/ecommerce_component/create",
        controller  : 'ecommerce_component.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'ecommerce_component',
            label: 'Adicionar'
        }
    })

    .state('ecommerce_component_show', {
        url         : "/ecommerce_component/:id",
        templateUrl : server+"/views/m/ecommerce_component/show",
        controller  : 'ecommerce_component.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'ecommerce_component',
            label: '{{ecommerce_component.name}}'
        }
    })


}]);

angular.module('ecommerce_component').
controller('ecommerce_component.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$ecommerce_component', function($scope, $http, $auth, $q, NgTableParams, $ecommerce_component) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $ecommerce_component.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

}]);

/**
 * Create Controller
 */
angular.module('ecommerce_component').
controller('ecommerce_component.create', ['$scope', '$state', '$ecommerce_component', 'ngToast', function($scope, $state, $ecommerce_component, ngToast) {
    $scope.ecommerce_component = {
        name : '',
        config : ''
    }
    $scope.save = function() {
        $ecommerce_component.save($scope.ecommerce_component).$promise.then(function(data) {
            console.log(data);
            ngToast.success({
                content: 'Component salvo com sucesso!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $state.go('ecommerce_component_show', {
                id: data.id,
                name: data.name
            });
        }, function(error){
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        });
    }
}]);

 /**
 * Show, Edit, Delete Controller
 */
angular.module('ecommerce_component').
controller('ecommerce_component.show', ['$state', '$scope', '$stateParams', '$uibModal', '$ecommerce_component', function($state, $scope, $stateParams, $uibModal, $ecommerce_component) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $ecommerce_component.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.ecommerce_component = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$ecommerce_component', 'ngToast', function($scope, $uibModalInstance, $ecommerce_component, ngToast) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var ecommerce_component = $scope.$parent.ecommerce_component;

                $scope.confirm = function() {
                    $ecommerce_component.update({
                        id: ecommerce_component.id
                    }, ecommerce_component).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                        ngToast.success({
                            content: 'Component salvo com sucesso!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }, function(error){
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        return;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$ecommerce_component', 'ngToast', function($state, $scope, $uibModalInstance, $ecommerce_component, ngToast) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var ecommerce_component = $scope.$parent.ecommerce_component;

                $scope.confirm = function() {
                    $ecommerce_component.delete({id:ecommerce_component.id}).$promise.then(function(data){
                        ngToast.success({
                            content: 'Component deletado com sucesso!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                        $state.go('ecommerce_component');
                    }, function(error){
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        return;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


angular.module('ecommerce_component').
factory("$ecommerce_component", function($resource, $q, $http) {
    var resource = $resource(api + 'ecommerce_component/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});
