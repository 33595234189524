angular.module('purchase', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('purchase').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('purchase', {
        url         : "/purchase",
        templateUrl : server+"/views/m/purchase",
        controller  : 'purchase.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Compras'
        }
    })

    .state('purchase_suggestion', {
        url         : "/purchase/suggestion",
        templateUrl : server+"/views/m/purchase/suggestion",
        controller  : 'purchase.suggestion',
        params: {
            stock_alert : false
        },
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'purchase',
            label: 'Relatório'
        }
    })

    .state('purchase_create', {
        url         : "/purchase/create",
        templateUrl : server+"/views/m/purchase/create",
        controller  : 'purchase.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'purchase',
            label: 'Adicionar'
        }
    })

    .state('purchase_show', {
        url         : "/purchase/:id",
        templateUrl : server+"/views/m/purchase/show",
        controller  : 'purchase.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'purchase',
            label: '{{purchase.infNFe}}'
        }
    })

}]);

angular.module('purchase').
controller('purchase.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$companyInvoice', 'Upload', '$uibModal', 'ngToast',
function($scope, $http, $auth, $q, NgTableParams, $companyInvoice, Upload, $uibModal, ngToast) {

    $scope.sending = false;
    $scope.import  = {};
    $scope.infNFe  = '';

    $scope.statuses = [
        {id:'', title:'Todas'},
        {id:'waiting', title: 'Aguardando'},
        {id:'in_progress', title: 'Em progresso'},
        {id:'finished', title: 'Finalizada'}
    ];

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 20
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $companyInvoice.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.print = function(row) {
        $http.post(api + 'company_invoice/'+row.id+'/print', {}, {responseType: 'arraybuffer'}).then(function(response){
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            // Create a link element on body
            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        });
    }

    $scope.selectImportFile = function() {
        $scope.sending = true;
        var data = {
            xml : $scope.import.file[0]
        }
        Upload.upload({
            url: api+'company_invoice',
            data: data
        }).then(function (response) {
            $scope.sending = false;
            $scope.tableParams.reload();
        }, function(error){
            $scope.sending = false;
        });
    }

    $scope.confirmEntry = function(row) {
        var entry       = {};
        $companyInvoice.getPayments(row.id).then(function(response){
            entry.company_invoice_id = row.id;
            entry.value = row.total;
            entry.total = row.total;
            entry.description = 'Compra '+row.provider.name;
            entry.contact = row.provider;
            entry.installments  = {
                installments    : response.length > 0 ? response.length : 1,
                frequence       : 'month'
            }
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : 'financial_installment.modal.html',
                controller     : 'make_any_financials',
                size           : 'lg',
                resolve        : {
                    entry : function(){
                        return entry;
                    }
                }
            });

            modalInstance.result.then(function(bSkip) {
                if( bSkip ) {
                    var data = {
                        internal_status : 'in_progress'
                    }
                    $companyInvoice.update({id : row.id}, data).$promise.then(function(response){
                        ngToast.success({
                            content: 'Nota confirmada com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.tableParams.reload();
                    });
                } else {
                    ngToast.success({
                        content: 'Nota confirmada com sucesso',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    $scope.tableParams.reload();
                }
            });
        });
    }

    $scope.consumptionInvoice = function(invoice){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$companyInvoice', 'ngToast', '$q',
            function($scope, $uibModalInstance, $companyInvoice, ngToast, $q) {

                $scope.title = 'Deseja marcar todos os itens dessa nota como consumo e finalizar?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $companyInvoice.consumption(invoice.id).then(function(response){
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            ngToast.success({
                content: 'Nota marcado para consumo',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.tableParams.reload();
        });
    }

    $scope.searchNFe = function(){
        $scope.waiting = true;
        $companyInvoice.searchNFe({infNFe : $scope.infNFe}).then(function(response){
            ngToast.success({
                content: 'Nota baixada com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.tableParams.reload();
        }, function(error){
            ngToast.danger({
                content: 'Nota não baixada',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.deleteInvoice = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$companyInvoice', 'ngToast', '$q',
            function($scope, $uibModalInstance, $companyInvoice, ngToast, $q) {

                $scope.title = 'Deseja excluir nota de entrada e finalizar?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $companyInvoice.delete(id).then(function(response){
                        $uibModalInstance.close();
                        ngToast.success({
                            content: 'Nota excluida com sucesso!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.tableParams.reload();
                    }, function(error){
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }
}]);

angular.module('purchase').
controller('purchase.suggestion', ['$scope', '$rootScope', '$http', '$auth', '$q', 'NgTableParams','find', '$companyInvoice', 'Upload', '$uibModal', 'ngToast', '$provider', '$service', '$category', '$purchaseList', 'company_id', '$uibModalInstance', '$state', '$stateParams',
function($scope, $rootScope, $http, $auth, $q, NgTableParams, find, $companyInvoice, Upload, $uibModal, ngToast, $provider, $service, $category, $purchaseList, company_id, $uibModalInstance, $state, $stateParams ) {
    $scope.start_date     = { opened: false };
    $scope.end_date       = { opened: false };
    $scope.days_to_sale = 0;
    $scope.filter = {
        start_date  : moment().startOf('date').subtract(30, "days").toDate(),
        end_date    : moment().startOf('date').toDate(),
        provider    : {},
        category    : {},
        days_to_sale: 30,
        suggestion  : false,
        stock_alert : $stateParams.stock_alert,
        consider_stock : true,
        orderBy     : 'count',
        page: 1,
        count: 100
    };

    $scope.disableFilters = false;

    if($stateParams.stock_alert == true){
        $scope.disableFilters = true;
        setTimeout(() => {
            $scope.loadListPurchase();
        }, 400);
    }
    
    $scope.ngParams = angular.copy($scope.filter);
    $scope.ngParams.start_date = moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss');
    $scope.ngParams.end_date = moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss');

    $scope.enableSalePurchaseList = false;

    $scope.tabsetIndex = 0;

    $scope.newPurchase = {
        items : []
    }

    $scope.printing = false;

    $purchaseList.provider({count:10000}).then(function(response){
        $scope.providers = response;
    });

    $category.query({count:10000}).$promise.then(function(response){
        $scope.categories = response.data;
    });

    $scope.getListPurchase = function(){
        $purchaseList.query().$promise.then(function(response){
            $scope.purchasesList = response.data;
        });
    }
    $scope.getListPurchase();

    $scope.loadSuggestion = function(){
        _.each(Object.keys($scope.filter), function(key){
            $scope.ngParams[key] = $scope.filter[key];
        });
        $scope.ngParams.start_date      = moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.ngParams.end_date        = moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.loadListPurchase();
    }

    $scope.calcDays = function() {
        $scope.filter.days_to_sale = moment($scope.filter.end_date).diff(moment($scope.filter.start_date), 'days');
    }

    $scope.loadListPurchase = function(){
        $scope.purchaseParams = new NgTableParams($scope.ngParams, {
            counts: [],
            filterDelay: 100,
            getData: function(params){
                $scope.loadingSuggestion = true;
                return $purchaseList.loadServiceSuggestion(params.url()).then(function(response){
                    if ($scope.ngParams.list_all_service && $scope.ngParams.list_all_service === true) {
                        $scope.ngParams.list_all_service = '';
                        $scope.allServices = _.filter(response, function(row){return row.purchase_suggestion > 0; });
                        $scope.loadingSuggestion = false;
                        $scope.reloadTableParams();
                    } else {
                        $scope.ngParams.list_all_service = '';
                        $scope.ngParams = params._params;
                        $scope.services = response;
                        $scope.filterPurchase = _.filter(response.data, function(row){ return row.purchase_suggestion > 0});
                        if($scope.filter.suggestion){
                            response.data = $scope.filterPurchase;
                            $scope.ngParams.count = 1000;
                        }
                        if($scope.filterPurchase.length > 0){
                            $scope.enableSalePurchaseList = true;
                        }else{
                            $scope.enableSalePurchaseList = false;
                        }
                        params.total(response.total);
                        $scope.loadingSuggestion = false;
                        return response.data;
                    }
                });
            }
        });
    }

    $scope.getAllServices = function(){
        if ($scope.ngParams.suggestion) {
            $scope.allServices = $scope.filterPurchase;
            $scope.reloadTableParams();
        } else {
            $scope.ngParams.list_all_service = true;
            $scope.purchaseParams.reload();
        }
    }

    $scope.reloadTableParams = function(){
        $scope.buySuggestion();
        $scope.purchaseParams.reload();
    }

    $scope.filterSuggestion = function(){
        if($scope.filter.stock_alert == true){
            $scope.disableFilters = true;
            $scope.enableSalePurchaseList = true;
        }else{
            $scope.disableFilters = false;
        }
        $scope.loadSuggestion();
    }

    $scope.buySuggestion = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'item_purchase.modal.html',
            size: 'md',
            scope: $scope,
            controller     : 'purchase.create',
            backdrop        : 'static',
            resolve         : {
                suggestion  : function(){
                    return $scope.allServices;
                }
            }
        });
        modalInstance.result.then(function() {
            $scope.getListPurchase();
        });
    }

    $scope.addItem = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'item_purchase.modal.html',
            size: 'md',
            scope: $scope,
            controller     : 'purchase.create',
            backdrop        : 'static',
            resolve         : {
                suggestion : data
            }
        });
        modalInstance.result.then(function() {
            $scope.getListPurchase();
        });
    }

    $scope.showPurchaseOrder = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'show_purchase_order.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {
                $purchaseList.query(id).$promise.then(function(response){
                     $scope.purchaseList = response.data;
                });
                $scope.id = id;

                $scope.loadList = function (){
                    $purchaseList.showItemsPurchaseList(id).then(function(response){
                        $scope.purchaseListItems = response;
                        $scope.title    = $scope.purchaseListItems.data[0].purchase.name;
                        $scope.status   = $scope.purchaseListItems.data[0].purchase.status;
                        $scope.userName = $scope.purchaseListItems.user[0].name;
                        $scope.dateList = moment($scope.purchaseListItems.data[0].purchase.date).format('DD/MM/YYYY HH:mm');
                        $scope.total    = $scope.purchaseListItems.data[0].purchase.purchase_total;
                        $scope.purchase_id = $scope.purchaseListItems.data[0].purchase.id;
                        $scope.itemsTotalQtd = response.data.length;
                    });
                }
                $scope.loadList();

                $scope.removeItem = function(row){
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm.modal.html',
                        size: 'sm',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance',
                        function($state, $scope, $uibModalInstance) {
                            $scope.title = 'Deseja remover esse item?';
                            $scope.cancel_text = 'Não';
                            $scope.success_text = 'Sim';
                            $scope.confirm = function() {
                                $purchaseList.removeItem(row.id).then(function(response){
                                    ngToast.danger({
                                        content: 'Item removido',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $uibModalInstance.close();
                                    $scope.loadList();
                                }, function(error){
                                    ngToast.danger({
                                        content: 'Erro na operação',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $uibModalInstance.close();
                                });
                            }
                            $scope.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }]
                    });
                }

                $scope.removeAllItems = function(id){
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm.modal.html',
                        size: 'sm',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance',
                        function($state, $scope, $uibModalInstance) {
                            $scope.title = 'Deseja remover todos os itens?';
                            $scope.cancel_text = 'Não';
                            $scope.success_text = 'Sim';
                            $scope.confirm = function() {
                                $purchaseList.removeAllItems(id).then(function(response){
                                    ngToast.danger({
                                        content: 'Itens removidos',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $scope.loadList();
                                    $uibModalInstance.close();
                                }, function(error){
                                    ngToast.danger({
                                        content: 'Erro na operação',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $uibModalInstance.close();
                                });
                            }
                            $scope.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }]
                    });
                    modalInstance.result.then(function(data) {
                        $uibModalInstance.close(true);
                    });
                }

                $scope.getProvider = function(){ 
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'select_provider.modal.html',
                        size: 'md',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance',
                        function($state, $scope, $uibModalInstance) {
                            $scope.title = 'Selecione um fornecedor';
                            $scope.cancel_text = 'Cancelar';
                            $scope.success_text = 'Confirma';
                            $scope.purchaseListProvider = [];
                            $scope.confirm = function() {
                                $uibModalInstance.close();
                            }
                            $scope.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }]
                    });   
                }

                $scope.updateStatus = function(status){
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm.modal.html',
                        size: 'sm',
                        scope: $scope,
                        controller: ['$state', '$scope', '$uibModalInstance',
                        function($state, $scope, $uibModalInstance) {
                            $scope.title = 'Essa lista não poderá mais ser modificada, Deseja continuar?';
                            $scope.cancel_text = 'Não';
                            $scope.success_text = 'Sim';
                            $scope.confirm = function() {
                                $purchaseList.updateStatus(id,status).then(function(response){
                                    ngToast.success({
                                        content: 'Lista atualizada',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $uibModalInstance.close();
                                }, function(error){
                                    ngToast.danger({
                                        content: 'Erro na operação',
                                        dismissOnTimeout: true,
                                        timeout: 2000
                                    });
                                    $uibModalInstance.close();
                                });
                            }
                            $scope.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }]
                    });

                    modalInstance.result.then(function(data) {
                        $uibModalInstance.close(true);
                    });
                }

                $scope.printPurchaseList = function(){
                    $http.get(api+'purchase_list/'+$scope.id+'/print').then(function(response){
                        $scope.printing = false;

                        $rootScope.print(response.data);

                    }, function(error) {
                        ngToast.danger({
                            content: 'Sem dados para impressão!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $scope.printing = false;
                    });
                }

                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Confirmar';

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(bReloadList) {
            if( bReloadList )
                $scope.getListPurchase();
        });
    }

    $scope.showList = 'opened';
    $scope.showMore = function(list){
        $scope.showList = list;
    }  

    $scope.selectService = function(data){
        $scope.showInModal = false;
        $rootScope.selectService(data, $scope.showInModal);
    }

}]);

angular.module('purchase').
controller('purchase.show', ['$rootScope', '$scope', '$state', '$auth', '$q', 'NgTableParams', '$companyInvoice', '$stateParams', '$uibModal', '$companyInvoiceItem', 'ngToast', '$localStorage', '$service', '$http',
function($rootScope, $scope, $state, $auth, $q, NgTableParams, $companyInvoice, $stateParams, $uibModal, $companyInvoiceItem, ngToast, $localStorage, $service, $http) {


    $scope.company       = $localStorage.company;
    $scope.decimals      = $scope.company.decimals_param;
    $scope.confirmedItem = false;
    $scope.date          = new Date();
    $scope.getInvoice = function() {
        $companyInvoice.get({id : $stateParams.id}).$promise.then(function(response){
            $scope.companyInvoice = response;
        });
    }
    $scope.getInvoice();

    $scope.itemClick = function(row){
        $rootScope.itemSelected = row;
        $rootScope.itemSelected.name = row.description;
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/service",
            size            : 'lg',
            controller      : 'service.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true
            }
        });
        modalInstance.result.then(function(data) {
            $companyInvoiceItem.associate({'service_id' : data.id}, row.id).then(function(response){
                $scope.getInvoice();
            }, function(error){
                console.log(error);
            });
        });
    }

    $scope.itemConfirm = function(row){
        $rootScope.itemSelected = row;
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/service/modal",
            size            : 'lg',
            controller      : 'service.show',
            backdrop        : 'static',
            resolve         : {
                id : row.service_id,
                showInModal : true
            }
        });
        modalInstance.result.then(function() {
            $companyInvoiceItem.confirm(row.id).then(function(response){
                $scope.getInvoice();
            }, function(error){
                console.log(error);
            });
        });
    }

    $scope.showSuggestion = function(cean){
        $rootScope.cean = cean;
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/service",
            size            : 'lg',
            controller      : 'service.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true
            }
        });
        modalInstance.result.then(function(data) {
            $companyInvoiceItem.associate({'service_id' : data.id}, row.id).then(function(response){
                $scope.getInvoice();
            }, function(error){
                console.log(error);
            });
        });
    }

    $scope.openService = function(row){
        if(row.service_id)
            $state.go('service_show', {id: row.service_id});
    }

    $scope.getClass = function(status){
        switch (status) {
            case 'confirmed':
                return 'text-success';
                break;
            case 'waiting':
                return 'text-info';
                break;
            case 'consumption':
                return 'text-warning';
                break;
            default:
                return 'text-danger';
                break;
        }
    }

    $scope.confirmEntry = function() {

        var entry       = {};
        $companyInvoice.getPayments($scope.companyInvoice.id).then(function(response){
            entry.company_invoice_id = $scope.companyInvoice.id;
            entry.value = $scope.companyInvoice.total;
            entry.total = $scope.companyInvoice.total;
            entry.description = 'Compra '+$scope.companyInvoice.provider.name;
            entry.contact = $scope.companyInvoice.provider;
            entry.installments  = {
                installments    : response.length > 0 ? response.length : 1,
                frequence       : 'month'
            }
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : 'financial_installment.modal.html',
                controller     : 'make_any_financials',
                size           : 'lg',
                resolve        : {
                    entry : function(){
                        return entry;
                    }
                }
            });

            modalInstance.result.then(function() {
                ngToast.success({
                    content: 'Nota confirmada com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $state.go('purchase');
            });
        });
    }

    $scope.consumptionItem = function(row){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$companyInvoiceItem', 'ngToast', '$q',
            function($scope, $uibModalInstance, $companyInvoiceItem, ngToast, $q) {

                $scope.title = 'Deseja definir este produto como consumo?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $companyInvoiceItem.consumption(row.id).then(function(response){
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            ngToast.success({
                content: 'Item marcado para consumo',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.getInvoice();
        });
    }

    $scope.printLabel = function(origin = 'purchase'){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve        : {
                origin : function(){
                    return origin;
                }
            }
        });
        $scope.labelItems = [];
        modalInstance.result.then(function(data){
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $scope.labelItems.push({
                        'service_id'            : item.service,
                        'name'                  : item.name,
                        'ref'                   : item.obj.external_reference,
                        'value'                 : item.obj.value,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'ean'                   : item.obj.cEAN,
                        'group'                 : item.obj.service_group_id
                    });
                });
            }else{
                $scope.labelItems.push({
                    'service_id'            : data.service,
                    'name'                  : data.name,
                    'ref'                   : data.obj.external_reference,
                    'value'                 : data.obj.value,
                    'quantity'              : data.quantity > 0 ? data.quantity : 1,
                    'ean'                   : data.obj.cEAN,
                    'group'                 : data.obj.service_group_id
                });
            }
            if($scope.labelItems.length > 0){
                $scope.selectModelLabel();
            }
        });
    }

    $scope.selectModelLabel = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectList.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.label = true;

                $scope.title = 'Selecione o modelo da etiqueta';

                $scope.heads = [
                    {
                        field : 'name',
                    },
                ];

                $http.get(api+'company/label').then(function(response){
                    $scope.list = response.data;
                }, function(error){

                });

                $scope.confirm_gondola = function(){
                    $scope.generateTagGondola();
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.confirm = function(model){
                    if(model.label_model === ''){
                        ngToast.danger({
                            content: 'Sem dados de modelo definido',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }else{
                        $scope.generateFile(model);
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.generateTagGondola = function(){
        $service.makeLabelGondola($scope.labelItems).then(function(response){
            $rootScope.print(response);

            $scope.printing = false;

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.generateFile = function(model){
        var params = {
            model     : model,
            itemList  : $scope.labelItems
        }

        $http.post(api+'service/make_label', params).then(function(response){
            var link = angular.element('<a href="' + response.data + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

        }, function(error) {
            $scope.waiting = false;
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout    : false,
                dismissButton       : true,
            });

        });
    }

    $scope.printReport = function(){
        var params = {
            date          : moment($scope.date).format('DD-MM-YYYY'),
            id            : $stateParams.id,
            title         : 'Total de vendas por cliente'
        }

        $scope.printing = true;
        $http.put(api+'company_invoice/print_report',{params}).then(function(response){

        $rootScope.print(response.data);

        $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        }); 
    }


}]);

angular.module('purchase')
.controller('purchase.create', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$purchaseList', '$localStorage','suggestion',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $purchaseList, $localStorage, suggestion) {
    
    if (suggestion && suggestion.length && suggestion.length > 0) {
        $scope.data = suggestion;
        $scope.allItems = [];
    }else{
        $scope.suggestion   = suggestion;
    }
    $scope.input        = {};
    $scope.item         = {};
    $scope.purchases    = [];
    $scope.title        = 'Adicionar item';
    $scope.cancel_text  = 'Não';
    $scope.success_text = 'Sim';
    
    if($scope.suggestion){
        if ($scope.suggestion.purchase_suggestion > 0){
            $scope.input.value = $scope.suggestion.purchase_suggestion
        }else{
            $scope.input.value = 1;
        }
    }

    $purchaseList.query({count: 1000}).$promise.then(function(response){
        $scope.purchasesList = response.data.purchaseOpened;
        if($localStorage.purchaseList){
            $scope.list = $localStorage.purchaseList;
        }
    });

    $scope.selectedPurchaseList = function(selected){
        if( selected ){
            if( selected.originalObject.custom ){
                var list = {
                    name   : selected.originalObject.name,
                    status : 'opened'
                };
                $purchaseList.post(list).$promise.then(function(response){
                    $scope.list = response;
                    $scope.purchasesList.push(response);
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.list = selected.originalObject;
                    $localStorage.purchaseList = $scope.list;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputList');
                }
            }
        }
    }

    $scope.confirm = function(){
        if($scope.suggestion){
            $scope.item = {
                'purchase_id'      : $scope.list.id,
                'service_id'       : suggestion.id,
                'quantity'         : $scope.input.value,
                'quotation_value'  : suggestion.purchase_price,
                'purchase_value'   : suggestion.purchase_price,
                'purchase_total'   : suggestion.purchase_price * $scope.input.value,
                'quotation_total'  : suggestion.purchase_price * $scope.input.value
            }

            $purchaseList.addServicePurchaseList($scope.item).then(function(response){
                $scope.data = response.data;
                $uibModalInstance.close();
            });
        } else {
            _.each($scope.data, function(item){
                $scope.item = {
                    'purchase_id'      : $scope.list.id,
                    'service_id'       : item.id,
                    'quantity'         : item.purchase_suggestion,
                    'quotation_value'  : item.purchase_price,
                    'purchase_value'   : item.purchase_price,
                    'purchase_total'   : item.purchase_price * item.purchase_suggestion,
                    'quotation_total'  : item.purchase_price * item.purchase_suggestion
                }

                $scope.allItems.push($scope.item)

            });
            $purchaseList.addServicePurchaseList({data: $scope.allItems}).then(function(response){
                $scope.data = response.data;
            });
            $uibModalInstance.close();
        }
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);


angular.module('purchase').
factory("$purchaseList", function($resource, $q, $http) {
    var resource = $resource(api + 'purchase_list/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    resource.loadServiceSuggestion = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'purchase/suggestion', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response){
          deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.addServicePurchaseList = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'purchase_list/add_service', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response){
          deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.showItemsPurchaseList = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'purchase_list/show_items/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response){
          deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.provider = function()
    {
        var deferred = $q.defer();
        $http.get(api+'purchase_list/provider/').then(function(response){
            deferred.resolve(response.data);
        }, function(response){
          deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeItem = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'purchase_list/delete_item/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeAllItems = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'purchase_list/delete_all_items/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateStatus = function(id,status)
    {
        var deferred = $q.defer();
        $http.put(api+'purchase_list/'+id+'/update_status/'+status).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('purchase').
factory("$companyInvoice", function($resource, $q, $http) {
    var resource = $resource(api + 'company_invoice/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getPayments = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'company_invoice/'+id+'/get_payments').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.consumption = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'company_invoice/'+id+'/consumption').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.searchNFe = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company_invoice/search_nfe', {params : data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.delete = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'company_invoice/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
    
});

angular.module('purchase').
factory("$companyInvoiceItem", function($resource, $q, $http) {
    var resource = $resource(api + 'company_invoice_item/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.associate = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'company_invoice_item/'+id+'/associate', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.confirm = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'company_invoice_item/'+id+'/confirm').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.consumption = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'company_invoice_item/'+id+'/consumption').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
