angular.module('company', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('company').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('company', {
        url         : "/company",
        templateUrl : server+"/views/m/company",
        controller  : 'company.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Empresas'
        }
    })

    .state('company_show', {
        url         : "/company/:id",
        templateUrl : server+"/views/m/company/show",
        controller  : 'company_show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'company',
            label: '{{company.name}}'
        }
    })

    .state('company_debit', {
        url         : "/company_debit",
        templateUrl : server+"/views/m/company/debit",
        controller  : 'company_debit.report',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Empréstimos'
        }
    })

    .state('company_debit_transfer', {
        url         : "/company_debit",
        templateUrl : server+"/views/m/company/debit",
        controller  : 'company_debit_transfer',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Empréstimos'
        }
    })

}]);

angular.module('company').
controller('company.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$uibModal', '$localStorage', '$plans',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $uibModal, $localStorage, $plans) {

    if( !$localStorage.companyParams ){
        $localStorage.companyParams = {
            page : 1
        }
    }

    $scope.filters = {
        plan_id : null
    }

    $scope.totals = {
        plans       : 0
    }

    $plans.query({count: 1000}).$promise.then(function(response){
        $scope.plans = response.data;
    });

    $scope.getData = function() {
        if( $scope.filters.plan_id )
            $scope.tableParams._params.filter.plan_id = $scope.filters.plan_id;
        $scope.tableParams.reload();
    }

    $scope.tableParams = new NgTableParams({
        page    : $localStorage.companyParams.page,
        count   : 20
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $company.query(params.url()).$promise.then(function(data) {
                $localStorage.companyParams = params._params;
                $scope.totals.plans = data.total_master > 0 ? data.total_master : data.total_partner;

                params.total(data.companies.total);
                return data.companies.data;
            });
        }
    });


    $scope.updateStatus = function(company, status){
        var data = {
            plan_active : status
        };
        $company.update({id: company.id}, data).$promise.then(function(response){
            ngToast.success({
                content: 'Empresa salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro ao salvar empresa',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

}]);

angular.module('company').
controller('company_show', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', '$company', '$uibModal','Upload', '$countryState', '$city', '$tax_regime','uuid', '$stateParams', '$transaction', 'ngToast', '$gatewayPlan', '$sce', '$companyDebit', '$client', '$invoice', '$sale', '$companyDebitTransfer',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $company, $uibModal, Upload, $countryState, $city, $tax_regime,uuid, $stateParams, $transaction, ngToast, $gatewayPlan, $sce, $companyDebit, $client, $invoice, $sale, $companyDebitTransfer) {

    $scope.totalPlan = 0;
    $scope.$parent.loading = false;
    $scope.editing = false;
    $scope.addingNewDebit = false;
    $scope.waiting = false;
    $scope.changeLabel = false;
    $scope.changeLabelAlert = false;
    $scope.toggleEdit = function(){
        $scope.editing = !$scope.editing;
        $scope.colorOptions.disabled = !$scope.colorOptions.disabled;
    }

    $scope.locale       = $state.current;
    $scope.urlFacebook = window.location.origin + '/' +  'get_xml_facebook' + '/' + $localStorage.company.hashid;

    $scope.company = {
        theme: {},
        address_zip  : '',
        city_id : '',
        state_id : ''
    }

    $scope.colorOptions = {
        disabled    : true,
        format      : 'hex',
        case        : 'upper',
        inputClass  : 'form-control'
    }

    $scope.certificate = {};
    $scope.today       = moment();
    $scope.taxRegimes  = [];
    $scope.states      = [];
    $scope.cities      = [];
    $scope.city_id     = '';
    $scope.state_id    = '';

    $scope.addNewDebit = function(){
        $scope.newDebit = {
            'company_id' : $stateParams.id
        };
        $scope.addingNewDebit = !$scope.addingNewDebit;
    }

    $scope.addDebit = function(){
        if( $scope.newDebit.id ) {
            $companyDebit.update({id: $scope.newDebit.id}, $scope.newDebit).$promise.then(function(response){
                console.log(response);
                ngToast.success({
                    content: 'Emprestimo salvo com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.addNewDebit();
                $scope.loadDebits();
            }, function(error){
                ngToast.danger({
                    content: 'Erro ao salvar!',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        } else {
            $companyDebit.save($scope.newDebit).$promise.then(function(response){
                console.log(response);
                ngToast.success({
                    content: 'Emprestimo salvo com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.addNewDebit();
                $scope.loadDebits();
            }, function(error){
                ngToast.danger({
                    content: 'Erro ao salvar!',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        }
    }

    $scope.editCompanyDebit = function(debit) {
        $scope.newDebit = debit;
         $scope.addingNewDebit = !$scope.addingNewDebit;
    }

    $scope.removeCompanyDebit = function(id) {
        console.log(id);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$companyDebit', 'ngToast',
            function($scope, $uibModalInstance, $companyDebit, ngToast) {

                $scope.title = 'Deseja realmente remover empréstimo?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function(){
                     $companyDebit.delete({id :id}).$promise.then(function(response){
                        ngToast.success({
                            content: 'Emprestimo removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao salvar!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                }
            }]
        });

        modalInstance.result.then(function() {
            $scope.loadDebits();
        });
    }

    $scope.loadDebits = function(){
        $companyDebit.query({company_id : $stateParams.id}).$promise.then(function(response){
            $scope.debits = response;
        });
    }

    $gatewayPlan.query().$promise.then(function(response){
        $scope.gatewayPlans = response;
    });

    $tax_regime.query({count:1000}).$promise.then(function(response){
        $scope.taxRegimes = response.data;
    });

    $scope.createUUID = function() {
        $scope.company.uuid = uuid.v4();
    }

    $scope.changePlan = function(plan){
        $scope.company.plan_price = plan.price;
    }

    $company.getTypes().then(function(response){
        $scope.types = response;
    });

    $scope.filtersUse = {
        start_date : moment().startOf('month').toDate(),
        end_date : moment().endOf('month').toDate(),
    }

    $countryState.query().$promise.then(function(response){
        $scope.states = response.data;
    });

    $scope.showUse = function() {
        if( $scope.filtersUse )
        $scope.waiting = true;
        $company.getUse($stateParams.id, $scope.filtersUse).then(function(response){
            $scope.useTotals = response;
            $scope.waiting = false;
        });
    }

    $scope.locale = $state.current;

    $scope.configs = {
        vAliq_default : 0,
        indISS_default : '2',
        PIS_CST : '07',
        PIS_pPIS : 0,
        COFINS_CST : '07',
        COFINS_pCOFINS : 0,
        password : '',
        CSC : '',
        CSCid : '',
        tokenIBPT : ''
    }

    $scope.getData = function() {
        $company.get({id:$stateParams.id}).$promise.then(function(response){
            $scope.company = response;
            $scope.company.theme = {id:$scope.company.theme}
            if($scope.company.invoice_configs){
                $scope.configs = $scope.company.invoice_configs;
                console.log($scope.configs);
                $scope.dateValid = moment($scope.company.invoice_configs.cert_valid_to).format('DD/MM/YYYY');
                var partesData = $scope.dateValid.split("/");
                var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
                var countDate = moment(data).subtract(30, 'days');
                if(data < new Date()){
                    $scope.changeLabel = true;
                }
                if (countDate == new Date()) {
                    $scope.changeLabelAlert = true;
                }
            }

            if( $scope.company.crt == 1 ){
                $scope.configs.PIS_CST = !$scope.configs.PIS_CST ? '07' : $scope.configs.PIS_CST;
                $scope.configs.PIS_pPIS = !$scope.configs.PIS_pPIS ? 0 : $scope.configs.PIS_pPIS;
                $scope.configs.COFINS_CST = !$scope.configs.COFINS_CST ? '07' : $scope.configs.COFINS_CST;
                $scope.configs.COFINS_pCOFINS = !$scope.configs.COFINS_pCOFINS ? 0 : $scope.configs.COFINS_pCOFINS;
            }

            $scope.reloadTransactions();
            $scope.changeStates();
            $scope.totalPlan = _.reduce($scope.company.plans, function(memo, row){ return $rootScope.sumFloat(memo, row.pivot.amount); },0 );

            $scope.loadDebits();
            if( $scope.company.gateway_recipient_id )
                $scope.getGatewayBalance();
        });
    }
    $scope.getData();

    $scope.getGatewayBalance = function() {
        $company.gatewayBalance($scope.company.id).then(function(response){
            $scope.balance = response;
        }, function(error){
            console.log(error);
        });
    }

    $scope.adjustmentAmount = function() {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'make_transfer.modal.html',
            controller     : 'transfer.create',
            size           : 'sm',
            resolve        : {
                company: function(){
                    return angular.copy($scope.company);
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.getGatewayBalance();
        });
    }

    $scope.themes = [
        {id:'blue', description: 'Azul (padrão)', color: '#1565C0'},
        {id:'light_grey', description:'Cinza Claro', color: '#F5F5F5'},
        {id:'grey', description:'Cinza', color: '#9E9E9E'},
        {id:'light_red', description:'Vemelho Claro', color: '#FFCDD2'},
        {id:'red', description:'Vemelho', color: '#F44336'},
        {id:'light_purple', description:'Roxo Claro', color: '#E1BEE7'},
        {id:'purple', description:'Roxo', color: '#9C27B0'},
        {id:'light_green', description:'Verde Claro', color: '#C8E6C9'},
        {id:'green', description:'Verde', color: '#4CAF50'},

        {id:'light_yellow', description:'Amarelo Claro', color: '#FFF9C4'},
        {id:'yellow', description:'Amarelo', color: '#FFEB3B'},

        {id:'black', description:'Preto', color: '#000000'},
        {id:'white', description:'Branco', color: '#ffffff'},

    ];

    $scope.pags = [
        {
            id: '01',
            description: 'Dinheiro'
        },
        {
            id: '02',
            description: 'Cheque'
        },
        {
            id: '03',
            description: 'Cartão de Crédito'
        },
        {
            id: '04',
            description: 'Cartão de Débito'
        },
        {
            id: '15',
            description: 'Boleto Bancário'
        },
        {
            id: '99',
            description: 'Outros'
        }
    ];

    $scope.dropdownTranslation = {
        buttonDefaultText       : 'Selecione',
        checkAll                : 'Todas',
        uncheckAll              : 'Nenhuma',
        selectionCount          : 'selecionada(s)',
        selectionOf             : '/',
        searchPlaceholder       : 'Pesquisar',
        dynamicButtonTextSuffix : 'selecionada(s)'
    };

    $scope.colorConfig = {
        template            : '<span class ="label" style ="background-color:{{option.color}}">&nbsp;&nbsp;&nbsp;</span> {{option.description}}',
        buttonClasses       : 'btn btn-default btn-block',
        scrollable          : true,
        scrollableHeight    : '200px',
        enableSearch        : false,
        styleActive         : true,
        showCheckAll        : false,
        showUncheckAll      : false,
        displayProp         : 'description',
        idProp              : 'id',
        selectionLimit      : 1,
        closeOnSelect       : true,
        smartButtonMaxItems : 1
    }

    $scope.changeCrt = function(){
        if( $scope.company.crt == 1 ){
            $scope.configs.PIS_CST = !$scope.configs.PIS_CST ? '07' : $scope.configs.PIS_CST;
            $scope.configs.PIS_pPIS = !$scope.configs.PIS_pPIS ? 0 : $scope.configs.PIS_pPIS;
            $scope.configs.COFINS_CST = !$scope.configs.COFINS_CST ? '07' : $scope.configs.COFINS_CST;
            $scope.configs.COFINS_pCOFINS = !$scope.configs.COFINS_pCOFINS ? 0 : $scope.configs.COFINS_pCOFINS;
        }
    }

    $scope.changeStates = function(){
        $city.query({state_id:$scope.company.state_id}).$promise.then(function(response){
            $scope.cities = response.data;
        });
    }

    $scope.changeCep = function(){
        if($scope.company.address_zip != undefined && $scope.company.address_zip.length == 8){
            var url         = 'https://viacep.com.br/ws/'+$scope.company.address_zip+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                var data = response.data;
                $scope.company.address              = data.logradouro;
                $scope.company.address_neighborhood = data.bairro;

                // Escolher UF
                $countryState.query({abbreviation:data.uf}).$promise.then(function(statesResp){
                    $scope.company.state_id = statesResp.id;
                    $scope.state_id         = statesResp.id;
                    $scope.changeStates();
                    $city.query({ibge:data.ibge}).$promise.then(function(citiesResp){
                        if (citiesResp) {
                            $scope.company.city_id = citiesResp.data[0].id;
                            $scope.city_id         = citiesResp.data[0].id;
                        }
                    });
                });

            });
        }
    }

    $scope.uploadFavicon = function() {
        if($scope.company.favicon){
            Upload.upload({
                url: api+'company/'+$scope.company.id+'/favicon',
                data: {favicon: $scope.company.favicon}
            }).then(function(response) {
                location.reload();
            });

        }
    }

    $scope.addMenu = function(){
        $scope.company.editable_menu.push({
            'name_item_menu'   : '',
            'show_item_menu'   : 0,
            'link_item_menu'   : ''
        });
    }

    $scope.removeMenu = function(){
    if( $scope.company.editable_menu.length > 0 )
        $scope.company.editable_menu.pop(($scope.company.editable_menu.lenght - 1), 1);
    }

    $scope.addShipping = function(){
        $scope.company.company_shipments.push({
            'name'   : '',
            'show_shipping'   : 0,
            'value'   : '',
            'state'   : ''
        });
    }

    $scope.statesShipping = [
        {title:'Acre', id:'AC'},
        {title:'Alagoas', id:'AL'},
        {title:'Amazonas', id:'AM'},
        {title:'Amapá', id:'AP'},
        {title:'Bahia', id:'BA'},
        {title:'Ceará', id:'CE'},
        {title:'Distrito Federal', id:'DF'},
        {title:'Espirito Santo', id:'ES'},
        {title:'Goiás', id:'GO'},
        {title:'Maranhão', id:'MA'},
        {title:'Minas Gerais', id:'MG'},
        {title:'Mato Grosso do Sul', id:'MS'},
        {title:'Mato Grosso', id:'MT'},
        {title:'Pará', id:'PA'},
        {title:'Paraíba', id:'PB'},
        {title:'Pernambuco', id:'PE'},
        {title:'Piauí', id:'PI'},
        {title:'Paraná', id:'PR'},
        {title:'Rio de Janeiro', id:'RJ'},
        {title:'Rio Grande do Norte', id:'RN'},
        {title:'Rondônia', id:'RO'},
        {title:'Roraima', id:'RR'},
        {title:'Rio Grande do Sul', id:'RS'},
        {title:'Santa Catarina', id:'SC'},
        {title:'Sergipe', id:'SE'},
        {title:'São Paulo', id:'SP'},
        {title:'Tocantins', id:'TO'}
    ];

    $scope.removeShipping = function(id){
        if( $scope.company.company_shipments.length > 0 )
        var index = _.find($scope.company.company_shipments, function(row){return row.id == id;});
        $scope.company.company_shipments.splice($scope.company.company_shipments.indexOf(index), 1);
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'Upload', '$http', 'ngToast', '$q',
            function($scope, $uibModalInstance, $company, Upload, $http, ngToast, $q) {

                $scope.title = 'Deseja atualizar as configurações da empresa?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var company             = angular.copy($scope.company); console.log(company);
                company.$promise        = null;
                company.$resolve        = null;
                company.theme           = company.theme.id;
                company.city_id         = company.city_id ? company.city_id : $scope.city_id;
                company.state_id        = company.state_id ? company.state_id : $scope.state_id;
                company.invoice_configs = angular.copy($scope.configs);
                company.start_at_schedule = company.start_at_schedule ? moment(company.start_at_schedule).format('HH:mm') : null;
                company.end_at_schedule   = company.end_at_schedule ? moment(company.end_at_schedule).format('HH:mm') : null;

                $scope.confirm = function() {
                    $company.save({id: company.id}, company).$promise.then(function(response){
                        var array = [];
                        if($scope.company.image){
                            array.push(Upload.upload({
                                url: api+'company/'+company.id,
                                data: {image: $scope.$parent.company.image}
                            }).then(function (response) {

                            }));
                        }

                        if($scope.certificate.file){
                            array.push(Upload.upload({
                                url: api+'company/'+company.id,
                                data: {certificate: $scope.certificate.file[0]}
                            }).then(function (response) {

                            }, function (response) {
                                if(response.data.message.indexOf('certificate') !== -1){
                                    ngToast.danger({
                                        content: 'Certificado ou senha inválidos!',
                                        dismissOnTimeout: true,
                                        timeout: 5000
                                    });
                                    $uibModalInstance.dismiss('cancel');
                                }
                            }));
                        }

                        setTimeout(function(){
                            $q.all( array ).then(function(response){
                                ngToast.success({
                                    content: 'Empresa atualizada!',
                                    dismissOnTimeout: true,
                                    timeout: 5000
                                });
                                $uibModalInstance.dismiss('cancel');
                                $scope.$parent.toggleEdit();
                                var user = $localStorage.user;
                                user.companies[0] = company;
                                $localStorage.user = user;
                                location.reload();
                            });
                        },500);



                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao salvar empresa',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });


                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.showBoleto = function(boleto){
        var link = angular.element('<a href="' + boleto.boleto_url + '" target="_blank"></a>');
        angular.element(document.body).append(link);
        link[0].click();
        link.remove();
    }

    $scope.isLate = function(date){
        return moment(date) < moment();
    }

    $scope.reloadTransactions = function(){
        var transactionParams = {
            company_id  : $scope.company.id
        };
        $transaction.query(transactionParams).$promise.then(function(response){
            $scope.transactions = response;
            $scope.$parent.loading = false;
        });
    }

    $scope.payManually = function(transaction){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$transaction', 'Upload', '$http', 'ngToast', '$q',
            function($scope, $uibModalInstance, $transaction, Upload, $http, ngToast, $q) {

                $scope.title = 'Deseja realmente baixar boleto?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Baixar';

                $scope.confirm = function() {
                    $scope.$parent.loading = true;
                    $transaction.payManually(transaction.id).then(function(response){
                        $scope.$parent.loading = false;
                        $uibModalInstance.close();
                    }, function(error){
                        $scope.$parent.loading = false;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.reloadTransactions();
        });
    }

    $scope.updateTransaction = function(transaction){
        $scope.$parent.loading = true;
        $scope.params = {
            date : moment().add(1, 'days').toDate(),
            reference : transaction.reference,
            id : transaction.id
        }
        $company.makeBoleto($scope.params, transaction.company.id).then(function(response){
            ngToast.success({
                content: 'Boleto Gerado',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.reloadTransactions();
        }, function(error){
            ngToast.danger({
                content: 'Erro ao gerar boleto',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.$parent.loading = false;
        });
    }

    $scope.deleteTransaction = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$transaction', 'Upload', '$http', 'ngToast', '$q',
            function($scope, $uibModalInstance, $transaction, Upload, $http, ngToast, $q) {

                $scope.title = 'Deseja realmente deletar transação?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Deletar';

                $scope.confirm = function() {
                    $transaction.delete({id: id}).$promise.then(function(response){
                        ngToast.success({
                            content: 'Transação deletada com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao deletar transação',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });


                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.reloadTransactions();
        });
    }

    $scope.makeBoleto = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'make_boleto.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', '_', '$q','ngToast',
            function($scope, $uibModalInstance, $company, _, $q, ngToast) {

                $scope.title = 'Gerar boleto';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Gerar';

                $scope.params = {
                    type        : 'manual',
                    date        : moment().toDate(),
                    amount      : 0,
                    reference   : moment().toDate()
                }

                $scope.confirm = function() {
                    if( $scope.params.amount < 10 ){
                        ngToast.danger({
                            content: 'Valor minimo é R$ 10,00',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }

                    $company.makeBoleto($scope.params, $scope.$parent.company.id).then(function(response){
                        ngToast.success({
                            content: 'Boleto Gerado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao gerar boleto',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
        modalInstance.result.then(function() {
            $scope.reloadTransactions();
        });
    }

    $scope.addTax = function(){
        $scope.company.taxes.push({
            'installments'  : $scope.company.taxes.length + 2,
            'tax'           : 0
        });
    }

    $scope.removeTax = function(){
        if( $scope.company.taxes.length > 0 )
            $scope.company.taxes.splice(($scope.company.taxes.length - 1), 1);
    }

    $scope.uploadCompanyBanner = function(){
        Upload.upload({
            url: api+'company/'+$scope.company.id+'/upload_banner',
            data: {
                image: $scope.company.banners[$scope.company.banners.length-1]
            }
        }).then(function (response) {
            $scope.company.banners[$scope.company.banners.length-1] = response.data;
            ngToast.success({
                content: 'Banner salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function (error) {
            ngToast.danger({
                content: 'Erro no formato da imagem',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.removeCompanyBanner = function(id){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company',
            function($scope, $uibModalInstance, $company) {

                $scope.title = 'Deseja realmente remover banner?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $company.deleteBanner(id, $scope.$parent.company.id).then(function(response){
                        $scope.$parent.company.banners = response;
                        ngToast.success({
                            content: 'Banner removido com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover banner',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addVoucher = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'voucher.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'ngToast',
            function($scope, $uibModalInstance, $company, ngToast) {

                $scope.title = 'Cupom';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';

                $scope.voucher = {
                    'discount_type' : 'V',
                    'rule_type'     : 'V',
                    'rule_min'      : 0,
                    'discount'      : 0
                };

                $scope.confirm = function() {
                    $company.storeVoucher($scope.voucher, $scope.$parent.company.id).then(function(response){
                        ngToast.success({
                            content: 'Cupom adicionado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.company.vouchers.push(response);
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao adicionar cupom',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.removeVoucher = function(index){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'ngToast',
            function($scope, $uibModalInstance, $company, ngToast) {

                $scope.title = 'Remover esse desconto?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $company.deleteVoucher($scope.$parent.company.vouchers[index].hashid, $scope.$parent.company.id).then(function(response){
                        ngToast.success({
                            content: 'Cupom removido',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.company.vouchers.splice(index, 1);
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover cupom',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.updateStatus = function(){
        var data = {
            plan_active : $scope.company.plan_active,
            is_client   : $scope.company.is_client
        };
        $company.update({id: $scope.company.id}, data).$promise.then(function(response){
            ngToast.success({
                content: 'Empresa salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro ao salvar empresa',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.addPlan = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'add_plan.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$plans', 'ngToast', '$company',
            function($scope, $uibModalInstance, $plans, ngToast, $company) {

                $scope.title = 'Adicionar plano';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.plan = {};

                $plans.query({count: 1000}).$promise.then(function(response){
                    $scope.plans = response.data;
                });

                $scope.select = function(row){
                    $scope.plan = {
                        id : row.id,
                        amount :row.price
                    }
                }

                $scope.confirm = function() {
                    $company.addPlan($scope.$parent.company.id, $scope.plan.id, $scope.plan).then(function(response){
                        ngToast.success({
                            content: 'Plano adicionado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao adicionar plano',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.getData();
        });
    }

    $scope.removePlan = function(plan_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', 'ngToast',
            function($scope, $uibModalInstance, $company, ngToast) {

                $scope.title = 'Remover plano?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $company.removePlan($scope.$parent.company.id, plan_id).then(function(response){
                        ngToast.success({
                            content: 'Plano removido',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover plano',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.getData();
        });
    }

    $scope.forceImportInvoice = function(){
        $scope.disableForceImportInvoice = true;
        ngToast.success({
            content: 'Importação em andamento',
            dismissOnTimeout: true,
            timeout: 5000
        });
        $company.forceImportInvoice($scope.company.id).then(function(response){
            ngToast.success({
                content: 'Não existem novas notas',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.disableForceImportInvoice = false;
        }, function(error){
            ngToast.danger({
                content: error,
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.disableForceImportInvoice = false;
        });
    }

    $scope.showClients = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/client",
            size            : 'lg',
            controller      : 'client.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id  : $scope.company.id
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }

    $scope.showUsers = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/users/modal",
            size            : 'lg',
            controller      : 'user.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id  : $scope.company.id
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }

    $scope.showInvoices = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/invoice",//'os.modal.html',
            size            : 'lg',
            controller      : 'invoice.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id     : $scope.company.id,
                dates : $scope.filtersUse
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }

    $scope.showSales = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : 'search.modal.html',
            size            : 'lg',
            controller      : 'searching_sale',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id  : $scope.company.id
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }

    $scope.showSalesPayments = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/report/sale",
            size            : 'lg',
            controller      : 'report.sale',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id  : $scope.company.id,
                dates : $scope.filtersUse
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }


    $scope.showServices = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/service",
            size            : 'lg',
            controller      : 'service.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true,
                company_id  : $scope.company.id
            }
        });
        modalInstance.result.then(function(data) {
            console.log(data);
        });
    }

    $scope.return = function(){
        $uibModalInstance.close();
    }

}]);

angular.module('company').
controller('company_debit.report', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$companyDebit', '$uibModal', '$localStorage', '$plans', '$companyDebitTransfer',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $companyDebit, $uibModal, $localStorage, $plans, $companyDebitTransfer) {

    $scope.totals = {
        'repass'    : 0,
        'trasacted' : 0
    };

    $scope.addingNewTransfer = false;
    $scope.waiting = false;
    $scope.showDescription = false;

    $scope.today = moment().toDate();

    $scope.newTransfer = {
        gateway : '',
        company_id : '',
        value : '',
        created_at : $scope.today
    }

    $scope.filters = {
        startDate       : moment().startOf('day').toDate(),
        endDate         : moment().startOf('day').toDate(),
        company_name    : '',
        debit_name      : '',
        gateway         : ''
    }

    $companyDebit.query().$promise.then(function(response){
        $scope.debits = response;
    });

    $scope.getData = function() {
        $scope.waiting = true;
        $companyDebit.getTransfers($scope.filters).then(function(response){
            $scope.transfers    = response.data;
            $scope.totals       = {
                'repass'    : response.totalRepass,
                'trasacted' : response.totalTrasacted
            };
            $scope.waiting = false;
        });
    }
    $scope.getData();

    $scope.addNewTransfer = function(){
        $scope.addingNewTransfer = !$scope.addingNewTransfer;
    }

    $scope.getIdDebitCompany = function(){
        var id = $scope.newTransfer.company_id;
        $scope.waiting = true;
        $companyDebit.query({company_id: id}).$promise.then(function(response){
            $scope.waiting = false;
            $scope.showDescription = true;
            $scope.idResults = response;
        });
    }

    $scope.addTransfer = function(){
        $scope.waiting = true;
        $companyDebitTransfer.makeDebitTransfer($scope.newTransfer).then(function(response){
            console.log(response);
            $scope.waiting = false;
            ngToast.success({
                content: 'Transferência feita com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.addingNewTransfer = false;
            $scope.newTransfer = {};
            window.location.reload();
        }, function(error){
            ngToast.danger({
                content: 'Erro ao Tranferir!',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }
}]);

angular.module('company').
factory("$gatewayPlan", function($resource, $q, $http) {
    var resource = $resource(api + 'gateway_plan/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});

angular.module('company').
factory("$companyDebit", function($resource, $q, $http) {
    var resource = $resource(api + 'company_debit/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getTransfers = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company_debit/transfers', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeTransfer = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'company_debit/make_transfer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('company').
factory("$companyDebitTransfer", function($resource, $q, $http) {
    var resource = $resource(api + 'company_debit_transfer/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.makeDebitTransfer = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'company_debit_transfer/make_transfer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('company').
factory("$company", function($resource, $q, $http) {
    var resource = $resource(api + 'company/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.forceImportInvoice = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'company/get_invoices', {company_id : id}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getTypes = function()
    {
        var deferred = $q.defer();
        $http.get(api+'company/get_types').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeTransfer = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'company/'+id+'/transfer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getBanks = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'company/'+id+'/banks').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getBalance = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/balance', {params:data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.gatewayBalance = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'company/'+id+'/gateway_balance').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.report = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/report', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkout = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'company/checkout',data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeBoleto = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'company/'+id+'/boleto',data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteBanner = function(bannerId, id)
    {
        var deferred = $q.defer();
        $http.delete(api+'company/'+id+'/delete_banner/'+bannerId).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.storeVoucher = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'company/'+id+'/store_voucher', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteVoucher = function(voucherId, id)
    {
        var deferred = $q.defer();
        $http.delete(api+'company/'+id+'/delete_voucher/'+voucherId).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.addPlan = function(id, plan_id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'company/'+id+'/plan/'+plan_id, data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removePlan = function(id, plan_id)
    {
        var deferred = $q.defer();
        $http.delete(api+'company/'+id+'/plan/'+plan_id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getUse = function(id, data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/'+id+'/get_use', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getCompanyEndDateCertificate = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/get_end_date_cert', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
