angular.module('bulletin', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('bulletin').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('bulletin', {
        url         : "/bulletin",
        templateUrl : server+"/views/m/bulletin",
        controller  : 'bulletin.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Comunicados'
        }
    })

    .state('bulletin_create', {
        url         : "/bulletin/create",
        templateUrl : server+"/views/m/bulletin/create",
        controller  : 'bulletin.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'bulletin',
            label: 'Adicionar'
        }
    })

    .state('bulletin_show', {
        url         : "/bulletin/:id",
        templateUrl : server+"/views/m/bulletin/show",
        controller  : 'bulletin.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'bulletin',
            label: '{{bulletin.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('bulletin').
controller('bulletin.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$bulletin', function($scope, $http, $auth, $q, NgTableParams, $bulletin) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $bulletin.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('bulletin').
controller('bulletin.create', ['$scope', '$state', '$bulletin', function($scope, $state, $bulletin) {
    $scope.save = function() {
        $bulletin.save($scope.bulletin).$promise.then(function(data) {
            $state.go('bulletin_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('bulletin').
controller('bulletin.show', ['$state', '$scope', '$stateParams', '$uibModal', '$bulletin', function($state, $scope, $stateParams, $uibModal, $bulletin) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $bulletin.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.bulletin = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$bulletin', function($scope, $uibModalInstance, $bulletin) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var bulletin = $scope.$parent.bulletin;

                $scope.confirm = function() {
                    $bulletin.update({
                        id: bulletin.id
                    }, bulletin).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', function($state, $scope, $uibModalInstance, $bulletin) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var bulletin = $scope.$parent.bulletin;

                $scope.confirm = function() {
                    $bulletin.delete({id:bulletin.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('bulletin');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.send = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http', function($state, $scope, $uibModalInstance, $bulletin, $http) {

                $scope.title = 'Deseja enviar esse comunicado para todos os usuários?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var bulletin = $scope.$parent.bulletin;

                $scope.confirm = function() {
                    $http.post(api+'bulletin/'+bulletin.id+'/send').then(function(response){
                        console.log(response);
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }


    $scope.sendIndividual = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'send_individual.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http','NgTableParams','$client','$user_role','_', function($state, $scope, $uibModalInstance, $bulletin, $http, NgTableParams, $client, $user_role,_) {

                $scope.title = 'Deseja enviar esse comunicado?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar';

                $scope.send_to = [];
                $scope._ = _;

                $scope.toggleSelection = function(row){
                    var i = _.indexOf($scope.send_to, row);
                    if(i != -1){
                        $scope.send_to.splice(i,1);
                    } else {
                        $scope.send_to.push(row);
                    }
                }

                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 10,
                    filter: {
                        email: 'not-null',
                        client: true
                    }
                }, {
                    counts: [],
                    filterDelay: 0,
                    getData: function(params) {
                        return $client.query(params.url()).$promise.then(function(data) {
                            params.total(data.total);
                            return data.data;
                        });
                    }
                });

                $scope.status = [
                    {id:undefined, title:'Todos'},
                    {id:'1', title:'Ativos'},
                    {id:'0', title:'Inativos'}
                ];

                var bulletin = $scope.$parent.bulletin;

                $scope.confirm = function() {
                    $http.post(api+'bulletin/'+bulletin.id+'/send?to='+$scope.send_to).then(function(response){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printIndividual = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'send_individual.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$bulletin', '$http','NgTableParams','$client','$user_role','_', function($state, $scope, $uibModalInstance, $bulletin, $http, NgTableParams, $client, $user_role,_) {

                $scope.title = 'Deseja imprimir esse comunicado?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar';

                $scope.send_to = [];
                $scope._ = _;

                $scope.toggleSelection = function(row){
                    var i = _.indexOf($scope.send_to, row);
                    if(i != -1){
                        $scope.send_to.splice(i,1);
                    } else {
                        $scope.send_to.push(row);
                    }
                }

                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 10,
                    filter: {
                        client: true
                    }
                }, {
                    counts: [],
                    filterDelay: 0,
                    getData: function(params) {
                        return $client.query(params.url()).$promise.then(function(data) {
                            params.total(data.total);
                            return data.data;
                        });
                    }
                });

                $scope.status = [
                    {id:undefined, title:'Todos'},
                    {id:'1', title:'Ativos'},
                    {id:'0', title:'Inativos'}
                ];

                var bulletin = $scope.$parent.bulletin;

                $scope.confirm = function() {
                    $http.post(api+'bulletin/'+bulletin.id+'/send?to='+$scope.send_to, { print: true },{responseType: 'arraybuffer'}).then(function(response){
                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);
                        // Create a link element on body
                        var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
                        angular.element(document.body).append(link);
                        link[0].click();
                        link.remove();
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);


/**
 * Factory
 */
angular.module('bulletin').
factory("$bulletin", function($resource) {
    var resource = $resource(api + 'bulletin/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
