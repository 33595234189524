angular.module('financial_group', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('financial_group').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('financial_group', {
        url         : "/financial_group",
        templateUrl : server+"/views/m/financial_group",
        controller  : 'financial_group.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'base_data',
            label: 'Plano de contas'
        }
    })

    .state('financial_group_create', {
        url         : "/financial_group/create",
        templateUrl : server+"/views/m/financial_group/create",
        controller  : 'financial_group.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'financial_group',
            label: 'Adicionar'
        }
    })

    .state('financial_group_show', {
        url         : "/financial_group/:id",
        templateUrl : server+"/views/m/financial_group/show",
        controller  : 'financial_group.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'financial_group',
            label: '{{group.name}}'
        }
    })

    .state('financial_categories', {
        url         : "/financial_categories",
        templateUrl : server+"/views/m/financial_categories",
        controller  : 'financial_categories.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Categorias do financeiro'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('financial_group').
controller('financial_group.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$financial_group', function($scope, $http, $auth, $q, NgTableParams, $financial_group) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $financial_group.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('financial_group').
controller('financial_group.create', ['$scope', '$state', '$financial_group',
function($scope, $state, $financial_group) {
    $scope.save = function() {
        $financial_group.save($scope.group).$promise.then(function(data) {
            $state.go('financial_group_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('financial_group').
controller('financial_group.show', ['$state', '$scope', '$stateParams', '$uibModal', '$financial_group',
function($state, $scope, $stateParams, $uibModal, $financial_group) {
    $scope.editing = false;
    $scope.categoriesSelecteds = [];

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $financial_group.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.group = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.selectCategory = function(category){
        if($scope.categoriesSelecteds[category.id]){
            $scope.categoriesSelecteds.splice(category.id, 1);
        } else {
            $scope.categoriesSelecteds[category.id] = category;
        }
        console.log($scope.categoriesSelecteds);
    }

    $scope.removeCategories = function(){
        var categories = $scope.categoriesSelecteds;
        $financial_group.removeCategories($stateParams.id, categories).then(function(data) {
            location.reload();
        })
    }

    $scope.addCategories = function(type)  {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'financial_categories.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_group', '$financial_category', '$state',
            function($scope, $uibModalInstance, $financial_group, $financial_category, $state) {

                $scope.title = 'Categorias';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Adicionar';

                $scope.categoriesSelecteds = [];

                $financial_category.query({type: type, count: 1000, hasGroup: true}).$promise.then(function(response){
                    $scope.categories = response.data;
                });

                $scope.selectCategory = function(category){
                    if($scope.categoriesSelecteds[category.id]){
                        $scope.categoriesSelecteds.splice(category.id, 1);
                    } else {
                        $scope.categoriesSelecteds[category.id] = category;
                    }
                }

                $scope.confirm = function() {
                    var categories = $scope.categoriesSelecteds;
                    $financial_group.addCategories($stateParams.id, categories).then(function(data) {
                        location.reload();
                        // $uibModalInstance.dismiss('cancel');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$financial_group', function($scope, $uibModalInstance, $financial_group) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var group = $scope.$parent.group;

                $scope.confirm = function() {
                    $financial_group.update({
                        id: group.id
                    }, group).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$financial_group', function($state, $scope, $uibModalInstance, $financial_group) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var group = $scope.$parent.group;

                $scope.confirm = function() {
                    $financial_group.delete({id:group.id}).$promise.then(function(data){
                        $state.go('financial_group');
                        $uibModalInstance.dismiss('cancel');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };
}]);

angular.module('financial_group').
controller('financial_categories.list', ['$scope', '$state', '$financial_category', '$financial_entry', 'ngToast', '$uibModal',
function($scope, $state, $financial_category, $financial_entry, ngToast, $uibModal) {
    
    $scope.categorySelecteds = [];
    $scope.addNew            = false;
    $scope.newCategory       = {
        name : '',
        type : ''
    }

    $scope.getCategories = function() {
        $scope.categorySelecteds = [];
        $financial_category.query({entries : true, count: 1000}).$promise.then(function(data) {
            $scope.financial_category = data.data;
        });
    }
    $scope.getCategories();

    $scope.selectCategory = function(row){
        if (row) {
            var next = _.find($scope.categorySelecteds, function(item){
                return item === row;
            });
            if (next) {
                $scope.categorySelecteds.pop(row);
            }else{
                $scope.categorySelecteds.push(row);
            }
        }
    }

    $scope.add = function(){
        $scope.addNew = true;
    }

    $scope.addNewCategory = function(){
        $scope.addNew = false;
        $financial_category.addNewCategories($scope.newCategory).then(function(response){
            console.log(response);
            ngToast.success({
                content: 'Nova Categoria salva com sucesso!!!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.getCategories();
        }, function(error){
            console.log(error);
            $scope.getCategories();
        });
    }

    $scope.removeCategory = function(){
        if ($scope.categorySelecteds.length > 0) {
            _.each($scope.categorySelecteds, function(item){ 
                if (item.financial_entry.length > 0) {
                    $scope.openFinancialEntries(item);
                    ngToast.danger({
                        content: 'Mude primeiro os lançamentos do financeiro da categoria ' + item.name,
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return;
                }else{
                    $financial_category.delete({id: item.id}).$promise.then(function(response){
                        ngToast.success({
                            content: 'Categoria ' + item.name + ' excluída com sucesso!!!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        console.log(response);
                        $scope.getCategories();
                    }, function(error){
                        console.log(error);
                    });
                }
            });
        }else{
            ngToast.danger({
                content: 'Selecione primeiro a(s) categoria(s)!!!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }

    }

    $scope.openFinancialEntries = function(row){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'viewFinancialAndEdit.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$financial_entry', '$financial_category',
            function($state, $scope, $uibModalInstance, $financial_entry, $financial_category) {

                $scope.title          = 'Editar entradas do financeiro';
                $scope.cancel_text    = 'Cancelar';
                $scope.success_text   = 'Confirmar';
                $scope.selectedEntrys = [];
                $scope.allCategories  = $scope.$parent.financial_category;
                $scope.id             = [];
                $scope.exclude        = false;
                $scope.change         = false;
                $scope.values         = false;
                $scope.selectAll      = false;

                $scope.getCategory = function(){
                    $financial_category.query({entries : true, count: 1000, category_id: row.id}).$promise.then(function(response){
                        $scope.categorie = response.data[0];
                    });
                }
                $scope.getCategory();

                $scope.selectEntry = function(id){
                    if (id) {
                        var next = _.find($scope.selectedEntrys, function(item){
                            return item === id;
                        });
                        if (next) {
                            $scope.selectedEntrys.pop(id);
                        }else{
                            $scope.selectedEntrys.push(id);
                        }
                    }
                }
                $scope.changeValue = function(bTrue = null){
                    if (bTrue) {
                        $scope.exclude = true;
                        $scope.values   = true;
                    }else{
                        $scope.change = true;
                        $scope.values   = true;
                    }
                }
                $scope.back = function(){
                    $scope.exclude = false;
                    $scope.change = false;
                    $scope.values = false;
                }
                $scope.removeFinancialEntry = function(){
                    var params = {
                        data : $scope.selectedEntrys
                    }
                    $financial_entry.deleteManyEntrys(params).then(function(response){
                        $scope.getCategories();
                        ngToast.success({
                            content: 'Entradas deletadas com sucesso',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao deletar',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        $uibModalInstance.close();
                    });
                }
                $scope.updateFinancialEntry = function(){
                    if ($scope.selectedEntrys.length == 0) {
                        ngToast.danger({
                            content: 'Selecione as entradas primeiro!',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        return;
                    }
                    var params = {
                        id: $scope.id.item.id,
                        data: $scope.selectedEntrys
                    }
                    $financial_entry.updateCategory(params).then(function(response){
                        $scope.getCategory();
                        $scope.$parent.getCategories();
                        ngToast.success({
                            content: 'Categoria modificada com sucesso',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro na operação',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.selectAllEntrys = function(){
                    _.each($scope.categorie.financial_entry, function(row){
                        $scope.selectedEntrys.push(row.id);
                    });
                    $scope.selectAll = true;
                }

                $scope.unselectAllEntrys = function(){
                    $scope.selectedEntrys = [];
                    $scope.selectAll = false;
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                    location.reload();
                };

            }]
        });
    }
}]);

angular.module('financial_group').
factory("$financial_category", function($resource, $q, $http) {
    var resource = $resource(api + 'financial_category/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        },
        'delete': {
            method: 'DELETE'
        } 
    });

    resource.addNewCategories = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'financial_category', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('financial_group').
factory("$financial_group", function($resource, $q, $http) {
    var resource = $resource(api + 'financial_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    resource.addCategories = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'financial_group/'+id+'/add_categories', {categories : data}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeCategories = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'financial_group/'+id+'/remove_categories', {categories : data}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    return resource;
});
