angular.module('users', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch', 'angularjs-dropdown-multiselect', 'ui.utils.masks']);

angular.module('users').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('users', {
        url         : "/users",
        templateUrl : server+"/views/m/users",
        controller  : 'user.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Pessoas'
        }
    })

    .state('users_create', {
        url         : "/users/create",
        templateUrl : server+"/views/m/users/create",
        controller  : 'user.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'users',
            label: 'Adicionar'
        }
    })

    .state('users_show', {
        url         : "/users/:id",
        templateUrl : server+"/views/m/users/show",
        controller  : 'user.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'users',
            label: '{{user.data.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('users').
controller('user.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$users', '$user_role', '$uibModalInstance', 'company_id', 'ngToast', function($scope, $http, $auth, $q, NgTableParams, $users, $user_role, $uibModalInstance, company_id, ngToast) {

    $scope.title = 'Usuários';
 
    $scope.user_roles = [
        {id:undefined, title:'Todos'}
    ];

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'1', title:'Ativos'},
        {id:'0', title:'Inativos'}
    ];

    $scope.filter = {
        allUsers : true,
        status   : '' 
    }

    $user_role.query({count:1000}).$promise.then(function(response){
        _.each(response.data, function(form){
            $scope.user_roles.push({id:form.id, title:form.name});
        });
    });

    $scope.exportSpreadsheet = function()
    {
        $http.get(api+'user/export').then(function(response){
            window.open(response.data,'_blank');
        });
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        filter : $scope.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $users.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }

    $scope.listUserCompany = new NgTableParams({
        page: 1,
        count: 100,
        company_id: company_id
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $users.query(params.url()).$promise.then(function(data) {
                return data.data;
            });
        }
    });

    $scope.addCompanyUser = function(data){
        var params = {
            email      : data,
            company_id : company_id,
            command    : 'attach'
        }
        $users.syncUser(params).then(function(data) {
            ngToast.success({
                content: 'Adicionado com sucesso',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.listUserCompany.reload();
        }, function(error){
            switch (error.data.message) {
                case 'email_not_exists':
                    ngToast.danger({
                        content: 'Email não cadastrado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                case 'email_already_exists':
                    ngToast.danger({
                        content: 'Email já cadastrado nessa loja',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                default:
                    ngToast.danger({
                        content: 'Erro ao adicionar usuário',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
            }  
        });
        
    }

    $scope.removeCompanyUser = function(data){
        var params = {
            user_id      : data.id,
            company_id : company_id,
            command    : 'detach'
        }
        $users.syncUser(params).then(function(data){
            ngToast.danger({
                content: 'Adicionado com sucesso',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.listUserCompany.reload();
        });
    }

}]);

/**
 * Create Controller
 */
angular.module('users').
controller('user.create', ['$scope', '$state', '$users', 'NgTableParams', '$role', '$user_role', '$department', 'ngToast',
function($scope, $state, $users, NgTableParams, $role, $user_role, $department, ngToast) {

    $scope.user = {
        status: '1',
        permissions: {},
        data: {
            department_id: {},
            user_role: {},
            manager: {},
            evaluate: '1',
            commission_value: 0
        }
    };

    $scope.admission_date = {
        opened: false
    };

    $scope.transfer_date = {
        opened: false
    };

    $scope.position_date = {
        opened: false
    };

    $scope.openDate = function(widget) {
        widget.opened = true;
    };

    $user_role.query().$promise.then(function(response){
        $scope.user_roles = response.data;
    });

    $users.query({count:1000}).$promise.then(function(response){
        $scope.managers = response.data;
    });

    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

    $scope.days_week = [];

    $scope.addDayWeek = function() {
        $scope.days_week.push({"day": "2","init_hour": '00:00', "final_hour": '00:00'});
    }

    $scope.delDayWeek = function(key) {
        $scope.days_week.splice(key, 1);
    }

    $scope.validate = function()
    {
        var permissions = false;
        angular.forEach($scope.user.permissions, function(value, key){
            if (value === true)
                permissions = true;
        });

        if($scope.user.password == $scope.user.confirmation && permissions){
            return false;
        }
        return true;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $role.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.save = function() {
        var user  = angular.copy($scope.user);
        user.data.department_id = $scope.user.data.department_id ? $scope.user.data.department_id.id : null;
        user.data.days_week     = $scope.days_week ? $scope.days_week : null;
        $users.save(user).$promise.then(function(data) {
            $state.go('users_show', {
                id: data.id
            });
        }, function(error){
            $scope.registerLoading = false;
            switch (error.data.message) {
                case 'cpf_cnpj_already_exists':
                    $scope.form_create.cpf.$setValidity("required",false);
                    ngToast.danger({
                        content: 'CPF já cadastrado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                case 'email_already_exists':
                    $scope.form_create.email.$setValidity("required",false);
                    ngToast.danger({
                        content: 'Email já cadastrado',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    break;
                default:
                    ngToast.danger({
                        content: 'Erro ao criar cadastro',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
            }
        });
    }
    
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('users').
controller('user.show', ['$state', '$scope', '$stateParams', '$uibModal', '$users', '$role', 'NgTableParams', '$user_role', '$department', 'ngToast', '$localStorage',
function($state, $scope, $stateParams, $uibModal, $users, $role, NgTableParams, $user_role, $department, ngToast, $localStorage) {

    $scope.editing = false;

    $scope.company = $localStorage.company;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.user = {
        status: '1',
        permissions: {},
        data: {
            department_id: {},
            user_role_id: {},
            manager_id: {},
            evaluate: '1'
        }
    };
    $scope.days_week        = [];

    $scope.comissionDefault = {
        commission_type : 'percent_value',
        commission_value : 0
    }

    $scope.admission_date = {
        opened: false
    };

    $scope.transfer_date = {
        opened: false
    };

    $scope.position_date = {
        opened: false
    };

    $scope.openDate = function(widget) {
        widget.opened = true;
    };

    $user_role.query().$promise.then(function(response){
        $scope.user_roles = response.data;
    });

    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

    // TODO: colocar filtro pelo cargo... então no cadastro de cargos colocar uma opção "Cargo de gestão?"
    $users.query({count:1000}).$promise.then(function(response){
        $scope.managers = response.data;
    });

    $scope.dropdownConfig = {
        buttonClasses       : 'btn btn-default btn-block',
        scrollable          : true,
        scrollableHeight    : '200px',
        enableSearch        : true,
        styleActive         : true,
        showCheckAll        : false,
        showUncheckAll      : false,
        displayProp         : 'name',
        idProp              : 'id',
        selectionLimit      : 1,
        closeOnSelect       : true,
        smartButtonMaxItems : 1
    }

    $scope.validate = function()
    {
        var permissions = false;
        angular.forEach($scope.user.permissions, function(value, key){
            if (value === true)
                permissions = true;
        });

        if($scope.user.password == $scope.user.confirmation && permissions){
            return false;
        }
        return true;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        user: $stateParams.id,
        has_permissions : true
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $role.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $users.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        data.data.user_role_id                                = {id:data.data.user_role_id};
        data.data.manager_id                                  = {id:data.data.manager_id};
        data.data.department_id                               = {id: data.data.department_id};
        if(data.data.admission_date) data.data.admission_date = new Date(data.data.admission_date);
        if(data.data.transfer_date) data.data.transfer_date   = new Date(data.data.transfer_date);
        if(data.data.position_date) data.data.position_date   = new Date(data.data.position_date);
        data.data.evaluate                                    = data.data.evaluate ? '1':'0';
        data.status                                           = data.status ? '1'       :'0';
        var days_week = data.data.days_week ? JSON.parse(data.data.days_week) : [];
        $scope.days_week = days_week;
        var permissions = [];
        _.each(data.permissions, function(per){
            permissions[per.id] = true;
        });
        data.permissions = permissions;
        $scope.user      = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.addDayWeek = function() {
        $scope.days_week.push({"day": "2","init_hour": '00:00', "final_hour": '00:00'});
    }

    $scope.delDayWeek = function(key) {
        $scope.days_week.splice(key, 1);
    }

    $scope.confirmUpdate = function(update = null) {
        if (update) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', '$users', 'ngToast', function($scope, $uibModalInstance, $users, ngToast) {
    
                    $scope.title = 'Confirma atualização?';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Concluído';
    
                    var user = angular.copy($scope.$parent.user);
                    user.data.department_id = user.data.department_id.id;
                    user.data.days_week          = $scope.$parent.days_week;
    
                    $scope.confirm = function() {
                        $users.update({
                            id: user.id
                        }, user).$promise.then(function(data) {
                            $uibModalInstance.close();
                        }, function(error){
                            $scope.registerLoading = false;
                            switch (error.data.message) {
                                case 'cpf_cnpj_already_exists':
                                    $scope.form_update.cpf.$setValidity("required",false);
                                    ngToast.danger({
                                        content: 'CPF já cadastrado',
                                        dismissOnTimeout: true,
                                        timeout: 3000
                                    });
                                    break;
                                case 'email_already_exists':
                                    $scope.form_update.email.$setValidity("required",false);
                                    ngToast.danger({
                                        content: 'Email já cadastrado',
                                        dismissOnTimeout: true,
                                        timeout: 3000
                                    });
                                    break;
                                default:
                                    ngToast.danger({
                                        content: 'Erro ao salvar',
                                        dismissOnTimeout: true,
                                        timeout: 3000
                                    });
                            }
                            $uibModalInstance.dismiss('cancel');
                        });
                    }
    
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
    
                }]
            });
            
        }
        modalInstance.result.then(function() {
            $scope.toggleEdit();
        });
    };


    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$users', function($state, $scope, $uibModalInstance, $users) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var user = $scope.$parent.user;

                $scope.confirm = function() {
                    $users.delete({id:user.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('users');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.comissionForAll = function() {
        $scope.user.service_commissions.forEach(comission => {
            comission.commission_type = $scope.comissionDefault.commission_type;
            comission.commission_value = $scope.comissionDefault.commission_value;
        });
    }

    $scope.addService = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl', 
            size           : 'lg',
            resolve        : {
                origin : function(){
                    return 'users';
                }
            }
        });

        modalInstance.result.then(function(data) {
            var array = [];
            if(!Array.isArray(data)){
                array = [];
                array.push(data);
            } else {
                array = data;
            }
            array.forEach(function(data){
                var has_service = _.find($scope.user.service_commissions, function(row){return row.service_id == data.obj.id;});
                if (has_service) {
                    ngToast.danger({
                        content: 'Comissão já cadastrada para esse item!',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                } else {
                    $scope.user.service_commissions.push({
                        service_id       : data.obj.id,
                        user_id          : $scope.user.id,
                        name             : data.name,
                        value            : data.value,
                        commission_type  : 'fixed_value',
                        commission_value : 0    
                    });
                }
            });
        });
    }


}]);


/**
 * Factory
 */
angular.module('users').
factory("$users", function($resource, $q, $http) {
    var resource = $resource(api + 'user/:id', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(data, key){
                    response.data[key].status = data.status ? 'Ativo' : 'Inativo';
                });
                return response;
            }
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.syncUser = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'user/sync_user',params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.confirm = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'user/confirm',params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.permissions = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'user/permissions',{params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkPermissions = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'user/check_permission',{params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    
    return resource;
});

angular.module('users').
factory("$role", function($resource) {
    var resource = $resource(api + 'role/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
