angular.module('client_address', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);



/**
 * Show, Edit, Delete Controller
 */
angular.module('client_address').
controller('client_address.show', ['$state', '$scope', '$stateParams', '$uibModal', '$client_address', function($state, $scope, $stateParams, $uibModal, $client_address) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $client_address.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.client_address = data.client_address;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client_address', function($scope, $uibModalInstance, $client_address) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var client_address = $scope.$parent.client_address;

                $scope.confirm = function() {
                    $client_address.update({
                        id: client_address.id
                    }, client_address).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_address', function($state, $scope, $uibModalInstance, $client_address) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client_address = $scope.$parent.client_address;

                $scope.confirm = function() {
                    $client_address.delete({id:client_address.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('client_address');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('client_address').
factory("$client_address", function($resource) {
    var resource = $resource(api + 'client/:client_id/address/:id', {client_id: '@client_id', id: '@id'}, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
