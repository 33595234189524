angular.module('schedule', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('schedule').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('schedule', {
        url         : "/schedule",
        templateUrl : server+"/views/m/schedule",
        controller  : 'schedule.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Agenda'
        }
    })

}]);

angular.module('schedule').
controller('schedule.list', ['$scope', '$rootScope', '$http', '$auth', '$q', '$schedule', '$uibModal', 'uiCalendarConfig', '$localStorage', '$user', 'ngToast', '$client', '$scheduleQueueClient', '$state',
function($scope, $rootScope, $http, $auth, $q, $schedule, $uibModal, uiCalendarConfig, $localStorage, $user, ngToast, $client, $scheduleQueueClient, $state) {

    $scope.eventSources     = [];
    var windowWidth = window.innerWidth;
    $scope.professional_id  = $localStorage.user.id;
    $scope.filter           = {
        professional_id     : '',
        status              : ''
    }

    $scope.company            = $localStorage.company;
    $scope.today              = new moment();
    $scope.addList            = false;
    $scope.waiting            = false;
    $scope.moreFunctions      = false;
    $scope.scheduleReturnList = [];
    $scope.daysName           = [];
    $scope.waitingClient      = {
        client: {},
        client_id: null,
        professional_id: null,
        date: '',
        hour: '',
        days_of_week: [],
        description: '',
        type: ''
    }

    $user.query({count: 1000, doctors: true}).$promise.then(function(response){
        $scope.professionals = response.data;
    });

    $client.query({count: 100000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.loadWaitingSchedules = function(){
        $scope.waiting = true;
        $scheduleQueueClient.query().$promise.then(function(response){
            $scope.waiting = false;
            if (response.length > 0) {
                $scope.scheduleReturnList = _.filter(response, function(row){ return row.type == 'return';});
                $scope.scheduleWaitingList = _.filter(response, function(item){ return item.type == 'waiting';});
                _.each($scope.scheduleWaitingList, function(q){ 
                    q.days_of_week = JSON.parse(q.days_of_week);
                    q.hasAlert = false;
                });
            }
            if ($scope.scheduleWaitingList) {
                var dt = moment($scope.today).isoWeekday();
                _.each($scope.scheduleWaitingList, function(row){
                    if (row.days_of_week.length > 0) {
                        _.each(row.days_of_week, function(item){
                            if (parseFloat(item.day) === dt) {
                                row.hasAlert = true;
                            } 
                        })
                    }
                })

            }
        });
    }
    $scope.loadWaitingSchedules();

    $scope.calendarConfig   = {
        config : {
            themeSystem : 'bootstrap4',
            height: 450,
            editable: false,
            selectable : true,
            selectHelper : true,
            allDaySlot: false,
            header: {
                left: 'agendaWeek agendaDay',
                center: 'title',
                right: 'today prev,next'
            },
            columnFormat: {
                month: 'ddd',
                week: 'ddd DD/MM',
                day: 'dddd DD/MM'
            },
            defaultView: "agendaWeek",
            slotLabelFormat:"HH:mm",
            axisFormat: 'H:mm',
            timeFormat: 'HH:mm',
            minTime: $scope.company.start_at_schedule ? $scope.company.start_at_schedule : '08:00',
            maxTime: $scope.company.end_at_schedule ? $scope.company.end_at_schedule : '20:00',
            buttonText: {
                today   : 'Hoje',
                month   : 'Mês',
                week    : 'Semana',
                day     : 'Dia',
                list    : 'Lista'
            },
            eventClick: function(event){$scope.selectEvent(event);},
            select: function(date){return $scope.selectDay(date);},
            eventRender: function (event, element) {},
            eventAfterRender: function(event, element){
                // element.draggable();
            },
            dayClick: function(date){
                if(windowWidth < 750){
                    return $scope.selectDay(date);
                }
            }
        }
    };

    $scope.loadEvents = function(status = null) {
        
        $scope.waiting = true;

        if (status) {
            $scope.filter.status = status;
            var params = {
                status : $scope.filter.status
            }
        }else{
            $scope.filter.status = '';
        }

        if ($scope.filter.professional_id) {
            var params = {
                filterProfissional_id : $scope.filter.professional_id
            }
            $scope.professional = _.find($scope.professionals, function(row){return row.id == $scope.filter.professional_id;});
        }
        $schedule.query(params).$promise.then(function(response){
            $scope.waiting = false;
            uiCalendarConfig.calendars.scheduleCalendar.fullCalendar('removeEvents');
            $scope.eventSources = [];
            var events = [];

            for (var i = 0; i < response.length; i++) {
                events.push({
                    id                            : response[i].id,
                    status                        : response[i].status,
                    className                     : [response[i].status],
                    title                         : response[i].patient && response[i].status != 'block' ? response[i].patient.name : 'Agenda bloqueada' + '\n' + (response[i].description ? response[i].description : ''),
                    start                         : moment(response[i].start_at).toDate(),
                    end                           : moment(response[i].end_at).toDate(),
                    patient                       : response[i].patient,
                    professional                  : response[i].professional,
                    value                         : response[i].value,
                    attended                      : response[i].attended,
                    services                      : response[i].services,
                    stick                         : true,
                    description                   : response[i].description, 
                    payment_type                  : response[i].payment_type,
                    employee_nomination_id        : response[i].employee_nomination_id,
                    employee_nomination_client_id : response[i].employee_nomination_client_id,
                    recommendation_type           : response[i].recommendation_type,
                    recommendation_description    : response[i].recommendation_description,
                    block                         : response[i].status == 'block' ? true : false
                });
            }
            $scope.eventSources.push(events);

            uiCalendarConfig.calendars.scheduleCalendar.fullCalendar('addEventSource', events);
            // uiCalendarConfig.calendars.scheduleCalendar.fullCalendar('refetchEvents');

            $rootScope.$broadcast('schedule:reload');
        }, function(error){
            $scope.waiting = false;   
        });
    }
    $scope.loadEvents();

    $scope.selectEvent = function(event){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    :  server+'/views/m/schedule/schedule',
            controller     : 'schedule.show',
            size           : 'lg',
            resolve        : {
                event: event
            }
        });

        modalInstance.result.then(function(response) {
            $scope.loadEvents();
            if( response.status == 'finished' ){
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'select.modal.html',
                    size: 'md',
                    scope: $scope,
                    controller: ['$state', '$scope', '$uibModalInstance',
                    function($state, $scope, $uibModalInstance) {

                        $scope.cancel_text = 'Cancelar';
                        $scope.title = 'O que deseja fazer?';
                        $scope.optionLeft = {
                            label   : 'Agendar um retorno',
                            value   : 'return'
                        }
                        $scope.optionRight = {
                            label   : 'Criar lembrete de retorno',
                            value   : 'remainder'
                        }

                        $scope.confirm = function(data) {
                            if( data == 'return' ){
                                var date = moment().add(1, 'month');
                                var schedule = {
                                    description : '',
                                    patient : response.patient,
                                    start_at : date.toDate(),
                                    end_at : date.add(30, 'minutes').toDate(),
                                    value : 0,
                                    attended : false,
                                    professional : response.professional,
                                    services : [],
                                    payment_type : null,
                                    hasProfessional: true
                                }
                                $scope.selectDay(date.toDate(), schedule);

                            }
                            if( data == 'remainder' ) {
                                $scope.addNewWaitingClient(response);
                            }

                            $uibModalInstance.close(data);
                        }

                        $scope.cancel = function() {
                            $uibModalInstance.close(null);
                        };

                    }]
                });
            }
        });
    }

    $scope.selectDay = function(date, data = null){
        // if (moment(date) < $scope.today.startOf('day') ) {
        //     ngToast.danger({
        //         content: 'Não é possivel agendamento do dia anterior!',
        //         dismissOnTimeout: true,
        //         timeout: 3000
        //     });
        //     return;
        // }
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    :  server+'/views/m/schedule/schedule',
            controller     : 'schedule.create',
            size           : 'lg',
            resolve        : {
                date: date,
                dates: data,
                find : function(){
                    return $scope.professional ? $scope.professional : null;
                },
            }
        });

        modalInstance.result.then(function(response) {
            if( $scope.queueList ){
                $scheduleQueueClient.update({id : $scope.queueList },{status : 'scheduled'}).$promise.then(function(response){
                    $scope.loadWaitingSchedules;
                    $scope.queueList = null;
                });
            }
            $scope.loadEvents();
        });
    }

    $scope.addNewWaitingClient = function(data = null){
       
        $scope.waitingClient      = {
            patient: data ? data.patient : {},
            client_id: data ? data.patient.id : null,
            professional_id: data ? data.professional_id : null,
            date: '',
            hour: '',
            days_of_week: [],
            description: '',
            type: ''
        }
        $scope.addList = !$scope.addList;
        setTimeout(() => {
            document.getElementById("waitingDateInput").scrollIntoView();
            if( $scope.waitingClient.client_id ) {
                $rootScope.setFocusID('waitingDateInput');
            } 
        }, 500);
         
    }

    $scope.removeWaitingClient = function(id){
        $scheduleQueueClient.delete({id: id}).$promise.then(function(response){
            $scope.addNewWaitingClient();
            $scope.loadWaitingSchedules();
            ngToast.success({
                content: 'Marcação removida com sucesso!',
                dismissOnTimeout: true,
                timeout: 3000
            })
        });
    }

    $scope.selectWaitingContact = function(selected){
        if( selected ){
            if( selected.originalObject.custom ){
                $client.getTypes({name: 'client'}).then(function(response){
                    var type = response;
                    var contact = {
                        type_id     : type.id,
                        name        : selected.title
                    };
                    $client.save(contact).$promise.then(function(response){
                        $scope.waitingClient = {
                            client_id    : response.id,
                        };
                        $scope.contacts.push({
                            id : response.id,
                            data : {
                                name : selected.title
                            },
                            type : type
                        });
                    });
                });
            } else {
                if( selected.originalObject ){
                    $scope.waitingClient = {
                        client_id    : selected.originalObject.id,
                    };
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
                }
            }
        }
    }

    $scope.makeWaitingSchedule = function(origin = null){
        if( $scope.waiting )
            return;
        $scope.waiting = true;
        if (origin) {
            $scope.waitingClient.type = origin;
        }
        if ((!origin && (!$scope.waitingClient.client_id || !$scope.waitingClient.days_of_week)) || (origin && (!$scope.waitingClient.client_id || !$scope.waitingClient.date ))) {
            ngToast.danger({
                content: 'Completar preenchimento dos dados!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.waiting = false;
            return;
        }
        var waitingClient = {
            client_id: $scope.waitingClient.client_id,
            date: $scope.waitingClient.date ? moment($scope.waitingClient.date).format('DD/MM/YYYY') : null,
            hour: $scope.waitingClient.hour ? moment($scope.waitingClient.hour).format('HH:mm') : null,
            professional_id: $scope.waitingClient.professional_id,
            days_of_week: $scope.waitingClient.days_of_week ? $scope.waitingClient.days_of_week : null,
            description: $scope.waitingClient.description ? $scope.waitingClient.description : null,
            type: $scope.waitingClient.type ? $scope.waitingClient.type : null
        }
        $scheduleQueueClient.save(waitingClient).$promise.then(function(response){
            $scope.addNewWaitingClient();
            $scope.loadWaitingSchedules();
            ngToast.success({
                content: 'Item de espera adicionada com sucesso!',
                dismissOnTimeout: true,
                timeout: 3000
            })
            $scope.waiting = false;

        }, function(error){
              $scope.waiting = false; 
        });
    }

    $scope.selectDaysOfWeek = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
            function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                $scope.title = 'Dias da semana!';
                $scope.title_name = 'Dia';
                $scope.success_text = 'Confirmar';

                var data = [
                    {id:'domingo', name:'Domingo'},
                    {id:'segunda', name:'Segunda'},
                    {id:'terca', name:'Terça'},
                    {id:'quarta', name:'Quarta'},
                    {id:'quinta', name:'Quinta'},
                    {id:'sexta', name:'Sexta'},
                    {id:'sabado', name:'Sábado'}
                ];

                $scope.data = data;
                $scope.daysList = [];
                $scope.select = function(id){
                    switch (id){
                        case 'domingo':
                            $scope.daysList.push({"day": '0' , "name": 'Domingo'});
                        break;
                        case 'segunda':
                            $scope.daysList.push({"day": '1' , "name": 'Segunda'});
                        break;
                        case 'terca':
                            $scope.daysList.push({"day": '2' , "name": 'Terça'});
                        break;
                        case 'quarta':
                            $scope.daysList.push({"day": '3' , "name": 'Quarta'});
                        break;
                        case 'quinta':
                            $scope.daysList.push({"day": '4' , "name": 'Quinta'});
                        break;
                        case 'sexta':
                            $scope.daysList.push({"day": '5' , "name": 'Sexta'});
                        break;
                        case 'sabado':
                            $scope.daysList.push({"day": '6', "name": 'Sábado'});
                        break;

                    }
                    
                }

                $scope.remove = function(row) {
                    var index = $scope.daysList.indexOf(row);
                    $scope.daysList.splice(index, 1);
                }

                $scope.confirm = function(){
                    $scope.$parent.waitingClient.days_of_week = $scope.daysList;
                    $scope.$parent.waitingClient.type = 'waiting'
                    $uibModalInstance.dismiss('cancel');
                }

            }]
        });
    }

    $scope.makeSchedule = function(row){
        var dateFormat = moment($scope.today.toDate());
        dateFormat = dateFormat.format("DD/MM/YYYY");
        var hourFormat = moment($scope.today.toDate());
        hourFormat = hourFormat.format("HH:MM");
        var date = row.date ? moment(row.date, "DD/MM/YYYY") : moment(dateFormat, "DD/MM/YYYY");
        var hours = row.hour ? row.hour.split(':') : hourFormat.split(':');
        date = date.hour(hours[0]);
        date = date.minute(hours[1]);
        if( date < moment() )
            date = moment();

        $scope.schedule = {
            description : '',
            patient : row.client,
            start_at : date.toDate(),
            end_at : date.add(30, 'minutes').toDate(),
            value : 0,
            attended : false,
            professional : row.professional,
            services : [],
            payment_type : null,
            hasProfessional: true
        }
        $scope.queueList = row.id;
        $scope.selectDay(date.toDate(), $scope.schedule);
        
    }

    $scope.getWaintingNotificationBell = function(){
        var days = [];
        if ($scope.scheduleWaitingList) {
            _.each($scope.scheduleWaitingList, function(row){
                 days.push(row.days_of_week);
             })
        }
    }

    $scope.alertSchedule = function(scheduleReturnList){
        if( moment(scheduleReturnList.date, 'DD/MM/YYYY').startOf('day') == moment().startOf('day') ){
            return 'warning';
        }
        if( moment(scheduleReturnList.date, 'DD/MM/YYYY').startOf('day') < moment().startOf('day') ){
            return 'danger';
        }
        return null;
    }
}]);

angular.module('schedule').
controller('schedule.create', ['$scope', '$http', '$auth', '$q', '$schedule', 'date', '$uibModalInstance', '$client', '$service', '$user', '$uibModal', '$clientEvaluation', '$localStorage', 'ngToast', '$clientDentalPlan', 'find', 'dates',
function($scope, $http, $auth, $q, $schedule, date, $uibModalInstance, $client, $service, $user, $uibModal, $clientEvaluation, $localStorage, ngToast, $clientDentalPlan, find, dates) {
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Criar agendamento';
    $scope.title_text   = 'Agendamento';
    $scope.saving       = false;

    $scope.sugestionList = [];
    $scope.hasProfessional = false;

    $scope.date = moment(date).hour(moment(date).hour() + 3);

    $scope.company = $localStorage.company;
    $scope.user = $localStorage.user;
    $scope.duration_procedure = $scope.company.procedure_duration_schedule ? parseInt($scope.company.procedure_duration_schedule) : 30;

    $scope.schedule = {
        description : '',
        client : {},
        start_at : $scope.date.toDate(),
        end_at : $scope.date.add($scope.duration_procedure, 'minutes').toDate(),
        value : 0,
        attended : false,
        professional : find ? find : {},
        services : [],
        payment_type : null
    }

    if (dates) {
        $scope.schedule = dates;
        $scope.hasProfessional = true;
    }

    $user.query({count: 1000, doctors: true}).$promise.then(function(response){
        $scope.professionals = response.data;
        $scope.professionals.company_type_id = $scope.company.company_type_id;
        if ($scope.professionals.length === 0) {
            ngToast.danger({
                content: 'Ops! Parece que todos os Doutores estão com agenda bloqueada para este dia...',
                dismissOnTimeout: true,
                timeout: 6000
            });
        }
    });

    $user.query({count: 1000, doctors: true}).$promise.then(function(response){
        $scope.professionalsRecommendation = response.data;
    });

    $client.query({count: 100000}).$promise.then(function(response){
        $scope.contacts = response.data;
        $scope.contacts.company_type_id = $scope.company.company_type_id;
    });

    $scope.sendWhatsapp = function(){
        if (!$scope.company.phone && !$scope.company.contact) {
            ngToast.danger({
                content: 'Ops! Está faltando preencher o telefone da clínica nas configurações da empresa...',
                dismissOnTimeout: true,
                timeout: 6000
            });
            return;
        }
        var commpanyPhone = $scope.company.phone ? $scope.company.phone : $scope.company.contact;
        var companyName = $scope.company.name;
        var patient = $scope.schedule.patient.name;
        var professional = $scope.schedule.professional.name;
        var patientPhone = $scope.schedule.patient.phone_completed ? $scope.schedule.patient.phone_completed : $scope.schedule.patient.secondary_phone;
        var userName = $scope.user.name;
        var date = moment($scope.date.toDate()).format('DD/MM/YYYY HH:mm');
      
        var textWhatsapp = "Olá, " + patient + "!" + " Estamos felizes em poder te receber ! \n Segue o lembrete da sua consulta com a/o Dr(a) " 
        + professional + "!\n às " + date +"!\n Tudo certo ? Estou a disposição! \n Clínica " + companyName;
        if( commpanyPhone && commpanyPhone !== 'null') {
            textWhatsapp = textWhatsapp + "\n Telefone: " + commpanyPhone;
        } 
        textWhatsapp = textWhatsapp + "\n Atenciosamente, \n " + userName + "!";

        var texto = window.encodeURIComponent(textWhatsapp);
      
      window.open("https://api.whatsapp.com/send?phone=" + 55 + patientPhone + "&text=" + texto, "_blank");
    }

    $scope.confirm = function() {
        $scope.saving = true;
        if(!moment($scope.schedule.start_at).isValid() || !moment($scope.schedule.end_at).isValid()){
            ngToast.danger({
                content: 'Ops! Está faltando a data...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.saving = false;

            return;
        }

        if( !$scope.schedule.block &&
            (   moment($scope.schedule.start_at).hour() < ( $scope.company.start_at_schedule ? $scope.company.start_at_schedule : '08' )
            ||  moment($scope.schedule.start_at).hour() >  ( $scope.company.end_at_schedule ? $scope.company.end_at_schedule : '19' )
            ||  moment($scope.schedule.end_at).hour() < ( $scope.company.start_at_schedule ? $scope.company.start_at_schedule : '08' )
            ||  moment($scope.schedule.end_at).hour() > ( $scope.company.end_at_schedule ? $scope.company.end_at_schedule : '19' ) )
        ) {
            ngToast.danger({
                content: 'Ops! Este horário não estamos abertos...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.saving = false;

            return;
        }

        if( !$scope.schedule.block && moment($scope.schedule.start_at).hour() > moment($scope.schedule.end_at).hour() ) {
            ngToast.danger({
                content: 'Ops! Horário final não pode ser menor que inicial...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.saving = false;

            return;
        }

        if(!$scope.schedule.patient && !$scope.schedule.block){
            ngToast.danger({
                content: 'Ops! Está faltando o paciente...',
                dismissOnTimeout: true,
                timeout: 3500
            });
            $scope.saving = false;

            return;
        }

        var schedule = {
            description                   : $scope.schedule.description,
            patient_id                    : $scope.schedule.patient ? $scope.schedule.patient.id : null,
            patient                       : $scope.schedule.patient,
            professional_id               : $scope.schedule.professional ? $scope.schedule.professional.id : null,
            employee_nomination_id        : $scope.schedule.employee_nomination_id ? $scope.schedule.employee_nomination_id : null,
            employee_nomination_client_id : $scope.schedule.employee_nomination_client_id ? $scope.schedule.employee_nomination_client_id : null,
            recommendation_type           : $scope.schedule.recommendation_type ? $scope.schedule.recommendation_type : null,
            recommendation_description    : $scope.schedule.recommendation_description ? $scope.schedule.recommendation_description : null,
            start_at                      : moment($scope.schedule.start_at).format('Y-M-D H:m:s'),
            end_at                        : moment($scope.schedule.end_at).format('Y-M-D H:m:s'),
            services                      : $scope.schedule.services ? $scope.schedule.services : [],
            payment_type                  : $scope.schedule.payment_type
        }

        if( $scope.schedule.block ){
            schedule.patient_id = null;
            schedule.services = [];
            schedule.status = 'block';
        }

        if (schedule.services.length <= 0 && !$scope.schedule.block ) {
            ngToast.danger({
                content: 'Adicionar serviço/procedimento para salvar agendamento!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.saving = false;

            return;
        }
        $schedule.save(schedule).$promise.then(function(response){
            $scope.saving = false;

            $uibModalInstance.close(response);
        });
    }

    $scope.addService = function(service = null) {
        if( service ) {
            $scope.schedule.services.push({
                'service_id'                    : service.service_id,
                'name'                          : service.name,
                'value'                         : service.value,
                'quantity'                      : 1,
                'total'                         : service.value,
                'client_dental_plan_service_id' : service.id
            });
        } else {
            var origin = 'schedule';
            var modalInstance = $uibModal.open({
                animation       : true,
                ariaLabelledBy  : 'modal-title',
                ariaDescribedBy : 'modal-body',
                templateUrl     : 'add_service.modal.html',
                controller      : 'add_service',
                controllerAs    : '$ctrl',
                size            : 'lg',
                backdrop        : 'static',
                resolve         : {
                    find : function(){
                        return null;
                    },
                    qtd : function(){
                        return 1;
                    },
                    origin : function(){
                        return 'schedule';
                    },
                    movement_type : function (){
                        return null
                    }
                }
            });

            modalInstance.result.then(function(data){
                if(!Array.isArray(data)){
                    var array = [];
                    array.push(data);
                    data = array;
                }

                _.each(data, function(item){
                    $scope.schedule.services.push({
                        'service_id'            : item.obj.id,
                        'external_reference'    : item.obj.external_reference,
                        'name'                  : item.name,
                        'value'                 : item.value > 0 ? item.value : item.obj.value_calculed,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'total'                 : item.total > 0 ? item.total : item.obj.value_calculed * item.quantity,
                        'note'                  : item.note
                    });
                });
            });
        }
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

     $scope.removeService = function(id) {
        var index = $scope.schedule.services.map(function(x) {return x.id; }).indexOf(id)
        if( index >= 0 )
        $scope.schedule.services.splice(index, 1);
    }

    $scope.selectContact = function(selected){
        if( selected ){

            if( selected.originalObject.custom ){
                $client.getTypes({name: 'client'}).then(function(response){
                    var type = response;
                    var contact = {
                        type_id     : type.id,
                        name        : selected.title
                    };
                    $client.save(contact).$promise.then(function(response){
                        $scope.schedule.patient = {
                            id      : response.id,
                            name    : selected.title,
                            type    : type,
                            phone   : '',
                            email   : ''

                        };
                        $scope.contacts.push({
                            id : response.id,
                            data : {
                                name : selected.title
                            },
                            type : type
                        });
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.schedule.patient = selected.originalObject;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
                }
            }

            if( $scope.schedule.patient && $scope.schedule.patient.id ){
                $clientDentalPlan.getSuggestionService({client_id: $scope.schedule.patient.id}).then(function(response){
                    $scope.sugestionList = [];
                    if(response.length > 0 ){
                        $scope.sugestionList = response;
                    }
                });
            }
        }
    }

    $scope.changeDate = function() {
        if( $scope.schedule.block )
            return;

        var endAt = moment($scope.schedule.end_at);
        var startAt = moment($scope.schedule.start_at);

        $scope.schedule.end_at = startAt.set({
            hour: endAt.hour(),
            minute : endAt.minute()
        }).toDate();
    }
}]);

angular.module('schedule').
controller('schedule.show', ['$scope', '$http', '$auth', '$q', '$schedule', 'event', '$uibModalInstance', '$client', '$user', '$uibModal', '$sale', 'ngToast', '$localStorage', '$clientDentalPlan', 'origin', '$state',
function($scope, $http, $auth, $q, $schedule, event, $uibModalInstance, $client, $user, $uibModal, $sale, ngToast, $localStorage, $clientDentalPlan, origin, $state) {
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Atualizar agendamento';
    $scope.title_text   = 'Agendamento';

    $scope.company         = $localStorage.company;
    $scope.user            = $localStorage.user;
    $scope.hasProfessional = true;
    $scope.disabled        = false;
    $scope.itemsDeleted    = [];
    $scope.now             = moment();

    $client.query({count: 100000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $user.query({count: 1000, doctors: true}).$promise.then(function(response){
        $scope.professionalsRecommendation = response.data;
    });

    if (origin && origin == 'client') {
        $scope.disabled = true;
    }

    if (event) {
        $scope.schedule = {
            id                              : event.id,
            status                          : event.status,
            description                     : event.description,
            patient                         : event.patient,
            professional                    : event.professional,
            value                           : event.value,
            attended                        : event.attended,
            services                        : event.services,
            payment_type                    : event.payment_type,
            start_at                        : origin == 'client' ? event.start_at : moment(event.start).toDate(),
            end_at                          : origin == 'client' ? event.end_at : moment(event.end).toDate(),
            recommendation_type             : event.recommendation_type,
            employee_nomination_id          : event.employee_nomination_id,
            employee_nomination_client_id   : event.employee_nomination_client_id,
            recommendation_description      : event.recommendation_description,
            block                           : event.block,
            hasRecommendationType           : event.recommendation_type ? true : false
        }
    }
    if( $scope.schedule.patient && $scope.schedule.patient.id ){
        $clientDentalPlan.getSuggestionService({client_id: $scope.schedule.patient.id}).then(function(response){
            $scope.sugestionList = [];
            if(response.length > 0 ){
                $scope.sugestionList = response;
            }
        });
    }

    $scope.changeProfessional = function(){
        $scope.hasProfessional = false;
        $user.query({count: 1000, doctors: true}).$promise.then(function(response){
            $scope.professionals = response.data;
        });
        $scope.schedule.professional = $scope.professional;
    }

    $scope.removeService = function(id) {
        var index = $scope.schedule.services.map(function(x) {return x.id; }).indexOf(id);
        $scope.itemsDeleted.push(id);
        if( index >= 0 )
            $scope.schedule.services.splice(index, 1);
    }

    $scope.sendWhatsapp = function(){
        if (!$scope.company.phone && !$scope.company.contact) {
            ngToast.danger({
                content: 'Ops! Está faltando preencher o telefone da clínica nas configurações da empresa...',
                dismissOnTimeout: true,
                timeout: 6000
            });
            return;
        }
        var commpanyPhone = $scope.company.phone ? $scope.company.phone : $scope.company.contact;
        var companyName = $scope.company.name;
        var patient = $scope.schedule.patient.name;
        var professional = $scope.schedule.professional.name;
        var patientPhone = $scope.schedule.patient.phone_completed ? $scope.schedule.patient.phone_completed : $scope.schedule.patient.secondary_phone;
        var userName = $scope.user.name;
        var date = moment($scope.schedule.start_at).format('DD/MM/YYYY HH:mm');
      
        var textWhatsapp = "Olá, " + patient + "!" + " Estamos felizes em poder te receber ! \n Segue o lembrete da sua consulta com a/o Dr(a) " 
        + professional + "!\n às " + date +"!\n Tudo certo ? Estou a disposição! \n Clínica " + companyName;
        if( commpanyPhone && commpanyPhone !== 'null') {
            textWhatsapp = textWhatsapp + "\n Telefone: " + commpanyPhone;
        } 
        textWhatsapp = textWhatsapp + "\n Atenciosamente, \n " + userName + "!";
        var texto = window.encodeURIComponent(textWhatsapp);
      
        window.open("https://api.whatsapp.com/send?phone=" + 55 + patientPhone + "&text=" + texto, "_blank");
    }

    $scope.confirm = function() {
        if(!moment($scope.schedule.start_at).isValid() || !moment($scope.schedule.end_at).isValid()){
            ngToast.danger({
                content: 'Ops! Está faltando a data...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }

        if( !$scope.schedule.block &&
            (   moment($scope.schedule.start_at).hour() < ( $scope.company.start_at_schedule ? $scope.company.start_at_schedule : '08' )
            ||  moment($scope.schedule.start_at).hour() >  ( $scope.company.end_at_schedule ? $scope.company.end_at_schedule : '19' )
            ||  moment($scope.schedule.end_at).hour() < ( $scope.company.start_at_schedule ? $scope.company.start_at_schedule : '08' )
            ||  moment($scope.schedule.end_at).hour() > ( $scope.company.end_at_schedule ? $scope.company.end_at_schedule : '19' ) )
        ) {
            ngToast.danger({
                content: 'Ops! Este horário não estamos abertos...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }

        if( !$scope.schedule.block && moment($scope.schedule.start_at).hour() > moment($scope.schedule.end_at).hour() ) {
            ngToast.danger({
                content: 'Ops! Horário final não pode ser menor que inicial...',
                dismissOnTimeout: true,
                timeout: 3000
            });
            return;
        }

        var schedule = {
            description                     : $scope.schedule.description,
            patient                         : $scope.schedule.patient,
            professional_id                 : $scope.schedule.professional.id,
            start_at                        : moment($scope.schedule.start_at).format('Y-M-D H:m:s'),
            end_at                          : moment($scope.schedule.end_at).format('Y-M-D H:m:s'),
            value                           : $scope.schedule.value,
            attended                        : $scope.schedule.attended,
            services                        : $scope.schedule.services,
            patient_id                      : $scope.schedule.patient ? $scope.schedule.patient.id : null,
            employee_nomination_id          : $scope.schedule.employee_nomination_id ? $scope.schedule.employee_nomination_id : null,
            employee_nomination_client_id   : $scope.schedule.employee_nomination_client_id ? $scope.schedule.employee_nomination_client_id : null,
            recommendation_type             : $scope.schedule.recommendation_type ? $scope.schedule.recommendation_type : null,
            recommendation_description      : $scope.schedule.recommendation_description ? $scope.schedule.recommendation_description : null,
            status                          : $scope.schedule.block && $scope.schedule.block === true ? 'block' : $scope.schedule.status && $scope.schedule.status != 'block' ? $scope.schedule.status : 'opened',
            payment_type                    : $scope.schedule.payment_type ? $scope.schedule.payment_type : null

        }
        if ($scope.itemsDeleted) {
            schedule.itemsDeleted = $scope.itemsDeleted;
        }
        $schedule.update({id: $scope.schedule.id}, schedule).$promise.then(function(response){
            $uibModalInstance.close(schedule);
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.close($scope.schedule);
    };


    $scope.selectContact = function(selected){
        if( selected ){

            if( selected.originalObject.custom ){
                $client.getTypes({name: 'client'}).then(function(response){
                    var type = response;
                    var contact = {
                        type_id     : type.id,
                        name        : selected.title
                    };
                    $client.save(contact).$promise.then(function(response){
                        $scope.schedule.patient.id         = response.id;
                        $scope.schedule.patient.name       = selected.title;
                        $scope.schedule.patient.type       = type;

                        $scope.contacts.push({
                            id : response.id,
                            data : {
                                name : selected.title
                            },
                            type : type
                        });
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.schedule.patient = selected.originalObject;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
                }
            }
            
        }
    }

    $scope.selectProfessional = function(selected){
        if( selected ){

            if( selected.originalObject.custom ){
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            } else {
                if( selected.originalObject.id ){
                    $scope.schedule.client = selected.originalObject;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
                }
            }
        }
    }

    $scope.updateStatus = function(status) {

        if (!$scope.schedule.block && $scope.schedule.services.length <= 0) {
            ngToast.danger({
                content: 'Adicionar serviço/procedimento para iniciar atendimento!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }

        switch (status) {
            case 'finished':
                var send = {
                    status : status,
                    services : $scope.schedule.services,
                    from : 'schedule'
                }
                $schedule.update({id: $scope.schedule.id}, send).$promise.then(function(response){
                    $uibModalInstance.close(response);
                });
            break;

            case 'in_progress':
                if( moment().day() < moment($scope.schedule.start_at).day() ) {
                    ngToast.danger({
                        content: 'Não é possível atender agendamento futuro',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return;
                }

                var send = {
                    status : status
                }
                $schedule.update({id: $scope.schedule.id}, send).$promise.then(function(response){
                    $uibModalInstance.close(response);
                });
            break;

            case 'canceled':
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'confirm.modal.html',
                    size: 'sm',
                    scope: $scope,
                    controller: ['$state', '$scope', '$uibModalInstance', '$users', 'ngToast', '$schedule',
                    function($state, $scope, $uibModalInstance, $users, ngToast, $schedule) {

                        $scope.title = 'Deseja remover esse agendamento?';
                        $scope.cancel_text = 'Não';
                        $scope.success_text = 'Sim';

                        $user.query({count: 1000}).$promise.then(function(response){
                            $scope.users = response.data;
                        });

                        $scope.bCredntials  = true;
                        $scope.credentials = {
                            user_id     : '',
                            password    : ''
                        }

                        $scope.confirm = function() {
                            $http.post(api+'auth/authenticate_password', $scope.credentials).then(function(response){
                                $scope.passwordConfirm = response.data.password;
                                if ($scope.passwordConfirm == true) {
                                    $users.get({ id: $scope.credentials.user_id, checkPermissionSchedule: true}).$promise.then(function(data){
                                        $schedule.delete({id: $scope.schedule.id}).$promise.then(function(response){
                                            ngToast.success({
                                                content: 'Agendamento excluído!',
                                                dismissOnTimeout: true,
                                                timeout: 3000
                                            });
                                            $uibModalInstance.close(response);
                                            $scope.$parent.cancel();
                                        });
                                    }, function(error) {
                                        ngToast.danger({
                                            content: error.data.message,
                                            dismissOnTimeout: true,
                                            timeout: 3000
                                        });
                                    });
                                }
                            },function(error){
                                ngToast.danger({
                                    content: 'Senha inválida',
                                    dismissOnTimeout: true,
                                    timeout: 3000
                                });
                                $uibModalInstance.close();
                            });
                        }
                        $scope.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };

                    }]
                });
            break;

            default:
                var send = {
                    status : status
                }
                $schedule.update({id: $scope.schedule.id}, send).$promise.then(function(response){
                    $uibModalInstance.close(response);
                });
            break;
        }

    }

    $scope.addService = function(service = null) {
        if( service ) {
            $scope.schedule.services.push({
                'service_id'                    : service.service_id,
                'name': service.name,
                'note': service.face_name ? service.face_name : null,
                'value'                         : service.value,
                'quantity'                      : 1,
                'total'                         : service.value,
                'client_dental_plan_service_id' : service.id
            });
        } else {
            var origin = 'schedule';
            var modalInstance = $uibModal.open({
                animation       : true,
                ariaLabelledBy  : 'modal-title',
                ariaDescribedBy : 'modal-body',
                templateUrl     : 'add_service.modal.html',
                controller      : 'add_service',
                controllerAs    : '$ctrl',
                size            : 'lg',
                backdrop        : 'static',
                resolve         : {
                    qtd : function(){
                        return 1;
                    },
                    origin : function(){
                        return origin;
                    }
                }
            });

            modalInstance.result.then(function(data){
                if(!Array.isArray(data)){
                    var array = [];
                    array.push(data);
                    data = array;
                }

                _.each(data, function(item){
                    $scope.schedule.services.push({
                        'service_id'            : item.obj.id,
                        'external_reference'    : item.obj.external_reference,
                        'name'                  : item.name,
                        'value'                 : item.value > 0 ? item.value : item.obj.value_calculed,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'total'                 : item.total > 0 ? item.total : item.obj.value_calculed * item.quantity,
                        'note'                  : item.note
                    });
                });
            });
        }
    }

    $scope.changeDate = function() {
        if( $scope.schedule.block )
            return;

        var endAt = moment($scope.schedule.end_at);
        var startAt = moment($scope.schedule.start_at);

        $scope.schedule.end_at = startAt.set({
            hour: endAt.hour(),
            minute : endAt.minute()
        }).toDate();
    }
}]);

angular.module('schedule').
controller('schedule_dock', ['$rootScope', '$scope', '$http', '$auth', '$q', '$localStorage', 'showInModal', '$uibModal', '$uibModalInstance', '$state', '$schedule',
function($rootScope, $scope, $http, $auth, $q, $localStorage, showInModal, $uibModal, $uibModalInstance, $state, $schedule) {

    $scope.schedules = [];
    $scope.user = $localStorage.user;

    $scope.getSchedules = function() {
        if( $scope.user ) {
            var params = {
                professional_id : $scope.user.id,
                start           : moment().hour(moment().hour() - 6).toDate(),
                end             : moment().endOf('day').toDate(),
                dock            : true
            }
            $schedule.query({ params: params, orderBySchedule: 'asc' }).$promise.then(function(response){
                $scope.schedules = response;
            });
        }
    }

    $scope.getSchedules();


    $scope.selectClient = function(data){
        $state.go('client_show',{id: data.id});
    }

    $rootScope.$on('schedule:reload', function() {
        $scope.getSchedules();
    });

}]);

/**
 * Factory
 */
angular.module('schedule').
factory("$schedule", function($resource) {
    var resource = $resource(api + 'schedule/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module('schedule').
factory("$scheduleService", function($resource) {
    var resource = $resource(api + 'schedule_service/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module('schedule').
factory("$scheduleQueueClient", function($resource) {
    var resource = $resource(api + 'schedule_queue_client/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
