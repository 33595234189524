angular.module('financial_balance', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('financial_balance').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('financial_balance', {
        url         : "/balance",
        templateUrl : server+"/views/m/financial_entry/balance",
        controller  : 'balance',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Financeiro'
        }
    })

}]);


/**
* List Controller
*/
angular.module('financial_balance')
.controller('balance', ['$scope', '$state', '$localStorage', '$http', '$auth', '$q', '$uibModal', '$financial_entry', '$financial_account', '$financial_category', '$client', '$users', '_', 'ngToast', '$rootScope', '$company',
function($scope, $state, $localStorage, $http, $auth, $q, $uibModal, $financial_entry, $financial_account, $financial_category, $client, $users, _, ngToast, $rootScope, $company) {

    // fix locale for moment
    moment.locale('pt-br');

    $scope.loadingEntries = false;

    $scope.filter = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate(),
        status: 'available'
    };

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    // Get balance
    $scope.getBalance = function(status = 'available')
    {
        if($scope.loadingEntries) return false;

        $scope.loadingEntries = true;

        $scope.operations = [];

        var start_date = moment($scope.filter.start_date).unix()+'000';
        var end_date = moment($scope.filter.end_date).unix()+'999';

        if(status == 'waiting_funds'){
            start_date = moment().unix()+'000';
            end_date = moment().add(30, 'days').unix()+'999';
        }

        $scope.filter.status = status;

        $http.get(api+'balance').then(function(response){
            $scope.balance = {
                available: (response.data.available.amount)/100,
                transferred: (response.data.transferred.amount)/100,
                waiting_funds: (response.data.waiting_funds.amount)/100
            }
        });
        $http.get(api+'balance/operations?start_date='+start_date+'&end_date='+end_date+'&status='+status).then(function(response){
            $scope.operations = response.data.reverse();
            _.each($scope.operations,function(value,key){
                $scope.operations[key]['moment'] = moment(value['date']);
            });

            $scope.loadingEntries = false;
        });
    }

    $scope.getBalance();

    // Get balance
    $scope.createTransfer = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'createTransfer.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja realizar o saque do saldo disponível?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    // Get balance
                    $http.post(api+'balance/transfer').then(function(response){
                        $scope.getBalance();
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    // Show operations
    $scope.showOperations = function(op)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showOperations.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.op = op;

                var start_day = moment(op.date).startOf('day').unix()+'000';
                var end_day = moment(op.date).endOf('day').unix()+'999';

                // Get balance
                $http.get(api+'balance/operations/detailed?start_date='+start_day+'&end_date='+end_day+'&status='+$scope.filter.status).then(function(response){

                    $scope.operations = response.data;
                    _.each($scope.operations, function(value,key){

                        if($scope.operations[key]["transaction"]){
                            var amount = parseInt($scope.operations[key]["transaction"]["amount"]);
                            $scope.operations[key]["transaction"]["amount"] = amount;

                            var valor_local = parseFloat($scope.operations[key]["transaction"]["entry"]["value"]);
                            var taxa_total = (value.fee_falefy)/100;
                            var valor_pagarme = (value.amount)/100;
                            var soma = (taxa_total+valor_pagarme).toFixed(2) * 1;
                            $scope.operations[key]["difference"] = valor_local-soma;
                            // $scope.operations[key]["difference"] = ((amount /100) - ((value.fee_falefy/100))) - (((value.amount)/100)-(value.fee/100));
                        }

                        $scope.operations[key]["moment"] = moment(value["date_created"]);
                        if(value["type"] == "payable")
                            $scope.operations[key]["ref_date"] = moment(value["transaction"]["entry"]["transacted_at"]);


                    });
                });

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);
