/**
*
* Module to control client files
*
* Created by  Raphael Godoi
* 10/03/2018
*/
angular.module('client_document', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);


angular.module('client_document')
.controller('client_document', ['$state', '$scope', '$stateParams', '$uibModal', 'NgTableParams','$documents', '$timeout', '$auth',
function($state, $scope, $stateParams, $uibModal, NgTableParams, $documents, $timeout, $auth){

    $scope.today = moment();
    $scope.filter = {
        // start_date : moment($scope.today).startOf('month').format('YYYY-MM-DD'),
        // end_date   : moment($scope.today).endOf('month').format('YYYY-MM-DD')
    }

    $scope.file_types = [{
            id: undefined,
            title: 'Todos'
        },
        {
            id: 'png,jpg,jpeg,gif',
            title: 'Imagens'
        },
        {
            id: 'doc,docx',
            title: 'Word'
        },
        {
            id: 'xls,xlsx',
            title: 'Excel'
        },
        {
            id: 'pdf',
            title: 'PDF'
        }
    ];

    $scope.selectedMonth = function(int){
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.filter.start_date = moment($scope.today).startOf('month').format('YYYY-MM-DD');
        $scope.filter.end_date   = moment($scope.today).endOf('month').format('YYYY-MM-DD');
        $scope.tableParams.reload();
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        filter : $scope.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            var data = params.url();
            data.client = $stateParams.id;
            return $documents.query(data).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.askForFiles = function() {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'sendfile.modal.html',
                size: 'lg',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', '$client', 'Upload', function($scope, $uibModalInstance, $client, Upload) {

                    $scope.title = 'Enviar arquivo';
                    $scope.cancel_text = 'Cancelar';
                    $scope.success_text = 'Concluído';

                    var client = $scope.$parent.client;

                    $scope.upload_files = [];
                    $scope.dragOverClassObj = {
                        accept: 'dragover',
                        reject: 'dragover-err',
                        pattern: '*'
                    };
                    $scope.multiple = true;

                    $scope.getReqParams = function() {
                        return $scope.generateErrorOnServer ? '?errorCode=' + $scope.serverErrorCode +
                            '&errorMessage=' + $scope.serverErrorMsg : '';
                    };

                    function upload(file, resumable) {
                        var url = api + 'client/' + client.id + '/documents' + $scope.getReqParams();
                        file.upload = Upload.upload({
                            url: url,
                            resumeSizeUrl: resumable ? 'https://angular-file-upload-cors-srv.appspot.com/upload?name=' + encodeURIComponent(file.name) : null,
                            resumeChunkSize: resumable ? $scope.chunkSize : null,
                            headers: {
                                'optional-header': 'header-value'
                            },
                            data: {
                                username: $scope.username,
                                file: file,

                            }
                        });

                        file.upload.then(function(response) {
                            $timeout(function() {
                                file.result = response.data;
                                // AQUI ISSO ESTA CERTO??
                                $scope.tableParams.reload();
                                $uibModalInstance.dismiss('cancel');
                            });
                        }, function(response) {
                            if (response.status > 0)
                                $scope.errorMsg = response.status + ': ' + response.data;
                        }, function(evt) {
                            // Math.min is to fix IE which reports 200% sometimes
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });

                        file.upload.xhr(function(xhr) {
                            // xhr.upload.addEventListener('abort', function(){console.log('abort complete')}, false);
                        });
                    }

                    $scope.upload = function(file, resumable) {
                        $scope.errorMsg = null;
                        upload(file, false);
                        //uploadS3(file);
                    };

                    $scope.$watch('upload_files', function(files) {
                        $scope.formUpload = false;
                        if (files != null) {
                            // make files array for not multiple to be able to be used in ng-repeat in the ui
                            if (!angular.isArray(files)) {
                                $timeout(function() {
                                    $scope.upload_files = files = [files];
                                });
                                return;
                            }
                            for (var i = 0; i < files.length; i++) {
                                $scope.errorMsg = null;
                                (function(f) {
                                    $scope.upload(f);
                                })(files[i]);
                            }
                        }
                    });

                    $scope.confirm = function() {

                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

        /**
         * Download files
         */
        $scope.download = function(file) {
            var x = new XMLHttpRequest();
            x.open("GET", api + 'client/' + $stateParams.id + '/documents/' + file.hash, true);
            x.setRequestHeader("Authorization", 'Bearer ' + $auth.getToken());
            x.responseType = 'blob';
            x.onload = function(e) {
                download(x.response, file.name, file.type);
            }
            x.send();
        }

        $scope.info = function(data) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'info.modal.html',
                size: 'lg',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                    $scope.title = 'Informações sobre o arquivo';
                    $scope.success_text = 'OK';

                    $scope.info = data;
                    console.log(data);

                    $scope.confirm = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

        $scope.selected_files = [];

        $scope.toggleSelection = function toggleSelection(id) {
            var idx = $scope.selected_files.indexOf(id);
            // Is currently selected
            if (idx > -1) {
                $scope.selected_files.splice(idx, 1);
            }
            // Is newly selected
            else {
                $scope.selected_files.push(id);
            }
        };

        $scope.showDeleted = false;
        $scope.toggleDeleted = function() {
            $scope.showDeleted = !$scope.showDeleted;
            $scope.tableParams.filter()['show_deleted'] = $scope.showDeleted;
        }

        $scope.delete = function(id) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                    $scope.title = 'Deseja remover esse arquivo?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.confirm = function() {
                        $scope.unlink(id);
                        $uibModalInstance.dismiss('cancel');
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

        $scope.unlink = function(id) {
            $documents.delete({
                client: $stateParams.id,
                id: id
            }, function(response) {
                $timeout(function() {
                    $scope.tableParams.reload();
                }, 100);
            });
        }

        $scope.restore = function(id) {
            $documents.restore({
                client: $stateParams.id,
                id: id
            }, function(response) {
                $timeout(function() {
                    $scope.tableParams.reload();
                }, 100);
            });
        }

        $scope.deleteBatch = function() {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                    $scope.title = 'Deseja remover esses arquivos?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.confirm = function() {
                        _.each($scope.$parent.selected_files, function(value) {
                            $scope.unlink(value);
                        });
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.selected_files = [];
                        $scope.showDeleted = !$scope.showDeleted;
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

        $scope.restoreBatch = function() {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$client', '$timeout', function($state, $scope, $uibModalInstance, $client, $timeout) {

                    $scope.title = 'Deseja restaurar esses arquivos?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.confirm = function() {
                        _.each($scope.$parent.selected_files, function(value) {
                            $scope.restore(value);
                        });
                        $uibModalInstance.dismiss('cancel');
                        $scope.selected_files = [];
                        $scope.$parent.selected_files = [];
                        $scope.toggleDeleted();
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }

}]);


angular.module('client_document').
factory("$documents", function($resource, $q, $http) {
    var resource = $resource(api + 'client/:client/documents/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.requestDownload = function() {
        var deferred = $q.defer();
        $http.put(api + 'client/').then(function(response) {
            console.log(response);
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.restore = function(params) {
        var deferred = $q.defer();
        $http.put(api + 'client/' + params.client + '/documents/' + params.id + '/restore').then(function(response) {
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    resource.list = function(params) {
        var deferred = $q.defer();
        $http.get(api + 'client/client_documents/',{params:params}).then(function(response) {
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });

        return deferred.promise;
    }


    return resource;
});
