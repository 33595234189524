
angular.module('client_room', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);
angular.module('client_room').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

        .state('client_room', {
        url         : "/client_room",
        templateUrl : server+"/views/m/client_room",
        controller  : 'client_room.document',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Meus Arquivos'
        }
    })

}]);

angular.module('client_room').
controller('client_room.document', ['$scope', '$state', 'NgTableParams', '$department', '$uibModal', '$documents', '$stateParams', '$auth',
function($scope, $state,  NgTableParams, $department, $uibModal, $documents, $stateParams, $auth) {

    $scope.today                = new moment();
    $scope.newEntry             = false;
    $scope.real_today           = angular.copy($scope.today);
    $scope.dates                = [];
    $scope.filter               = {
      department: {}
    };

    $scope.loadDocuments = function(department=null) {
      if(department != null){
        $scope.filter.department_id=department.id;
        $scope.filter.date = $scope.today.toDate();
        $scope.department_name = department.name;
      }
      $documents.list($scope.filter).then(function(response) {
         $scope.list= response.data;
      });
      $scope.newEntry = true;
//      console.log($scope.filter.department_id);
    }

    $scope.getLastDocuments = function() {
      $scope.filter.department_id=null;
      $documents.list({limit:5, 'sorting[created_at]':'desc'}).then(function(response) {
         $scope.lastDocuments= response.data;
      });
    }

    $scope.reloadPage = function(){
      location.reload();
    }


    $scope.getLastDocuments();

//    $scope.loadDocuments();

    $scope.selectedMonth = function(int){
      $scope.today.month($scope.today.month()+parseInt(int));
      $scope.filter.date = $scope.today.toDate();
      $scope.loadDocuments();
    };


    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

     /**
     * Download files
     **/
    $scope.download = function(file) {

        console.log(file);

        var x = new XMLHttpRequest();

        x.open("GET", api + 'client/' + file.client_id + '/documents/' + file.hash, true);
        x.setRequestHeader('Authorization','Bearer ' + $auth.getToken() );
        x.responseType = 'blob';
        x.onload = function(e) {
            download(x.response, file.name, file.type);
        }
        x.send();
    }

    $scope.info = function(data) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'info.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Informações sobre o arquivo';
                $scope.success_text = 'OK';

                $scope.info = data;
                console.log(data);

                $scope.confirm = function() {
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.delete = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {
                $scope.title = 'Deseja remover esse arquivo?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';
                $scope.confirm = function() {
                    $scope.unlink(id);
                    $uibModalInstance.dismiss('cancel');
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
    $scope.loadDocuments();
    }

    $scope.unlink = function(id) {
        $documents.delete({
            client: $stateParams.id,
            id: id
        }, function(response) {
            $timeout(function() {
                  $scope.loadDocuments();
            }, 100);
        });
    }

    $scope.save = function() {
        $scope.departament.department_id = $scope.departament.department_id.id;
        $client_room.save($scope.departament).$promise.then(function(data) {
            $state.go('client_room.show', {
                id: data.id
            });
        });
    }


    $scope.askForFiles = function() {
      var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'sendfile.modal.html',
          size: 'lg',
          scope: $scope,
          controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$department', function($scope, $uibModalInstance, $client, Upload, $department) {

              $scope.title = 'Enviar arquivo';
              $scope.cancel_text = 'Cancelar';
              $scope.success_text = 'Concluído';
              $scope.date_open={};

              $scope.department = $scope.department_name;
              $scope.data = {
                  date_file : $scope.today.toDate()
              };

              var client = $scope.$parent.client;

              $scope.upload_files = [];
              $scope.dragOverClassObj = {
                  accept: 'dragover',
                  reject: 'dragover-err',
                  pattern: '*'
              };
              $scope.multiple = true;

              $scope.getReqParams = function() {
                  return $scope.generateErrorOnServer ? '?errorCode=' + $scope.serverErrorCode +
                      '&errorMessage=' + $scope.serverErrorMsg : '';
              };

              function upload(file, resumable, department) {
                  var url = api + 'client_documents/upload';
                  file.upload = Upload.upload({
                      url: url,
                      resumeSizeUrl: resumable ? 'https://angular-file-upload-cors-srv.appspot.com/upload?name=' + encodeURIComponent(file.name) : null,
                      resumeChunkSize: resumable ? $scope.chunkSize : null,
                      headers: {
                          'optional-header': 'header-value'
                      },

                      data: {
                          username: $scope.username,
                          file: file,
                          department_id: $scope.filter.department_id,
                          date: $scope.data.date_file,
                      }
                  });

                  file.upload.then(function(response) {
                      $timeout(function() {
                          file.result = response.data;
                          // AQUI ISSO ESTA CERTO??
                          $scope.tableParams.reload();
                          $uibModalInstance.dismiss('cancel');
                      });
                  }, function(response) {
                      if (response.status > 0)
                          $scope.errorMsg = response.status + ': ' + response.data;
                  }, function(evt) {
                      // Math.min is to fix IE which reports 200% sometimes
                      file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                  });

                  file.upload.xhr(function(xhr) {
                      // xhr.upload.addEventListener('abort', function(){console.log('abort complete')}, false);
                  });
              }

              $scope.upload = function(file, resumable) {
                  $scope.errorMsg = null;
                  upload(file, false);
                  //uploadS3(file);
              };

              $scope.$watch('upload_files', function(files) {
                  $scope.formUpload = false;
                  if (files != null) {
                      // make files array for not multiple to be able to be used in ng-repeat in the ui
                      if (!angular.isArray(files)) {
                          $timeout(function() {
                              $scope.upload_files = files = [files];
                          });
                          return;
                      }
                      for (var i = 0; i < files.length; i++) {
                          $scope.errorMsg = null;
                          (function(f) {
                              $scope.upload(f);
                          })(files[i]);
                      }
                  }
              });

              $scope.confirm = function() {
                $scope.loadDocuments();
                $uibModalInstance.dismiss('closeModal');
              }

              $scope.cancel = function() {
                $scope.loadDocuments();
                $uibModalInstance.dismiss('closeModal');
              };

          }]
      });
    }

}]);
