angular.module('service', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('service').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('service', {
        url         : "/service",
        templateUrl : server+"/views/m/service",
        controller  : 'service.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Produtos & Serviços'
        }
    })

    .state('service_create', {
        url         : "/service/create",
        templateUrl : server+"/views/m/service/create",
        controller  : 'service.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'service',
            label: 'Adicionar'
        }
    })

    .state('service_show', {
        url         : "/service/:id",
        templateUrl : server+"/views/m/service/show",
        controller  : 'service.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'service',
            label: '{{service.name}}'
        }
    })

    .state('service_group_show', {
        url         : "/serviceg/:id",
        templateUrl : server+"/views/m/service/group",
        controller  : 'service.group',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'service',
            label: '{{group.name}}'
        }
    })

    .state('price',{
        url         : "/price",
        templateUrl : server+"/views/m/price",
        controller  : 'service.price',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Ajuste de Preços'
        }
    })

    .state('stock_movement',{
        url         : "/stock_movement",
        templateUrl : server+"/views/m/service/stock_movement",
        controller  : 'service.movement',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Movimentação de estoque'
        }
    })
}]);

/**
 * List Controller
 */
angular.module('service').
controller('service.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$service', '$serviceGroup', 'showInModal', '$uibModal', '$uibModalInstance', '$state', '$stateParams', '$rootScope', '$localStorage', 'company_id', 'ngToast', '$service_category_group',
function($scope, $http, $auth, $q, NgTableParams, $service, $serviceGroup, showInModal, $uibModal, $uibModalInstance, $state, $stateParams, $rootScope, $localStorage, company_id, ngToast, $service_category_group) {


    $scope.company         = $localStorage.company;
    $scope.decimals        = $scope.company.decimals_param;
    $scope.showInModal     = showInModal;
    $scope.showStockTotals = false;
    $scope.waiting         = false;
    if(!$scope.showInModal) {
        $rootScope.itemSelected = null;
    }
    if( $rootScope.itemSelected ) {
        $scope.itemSelected = angular.copy($rootScope.itemSelected);
    }
    $scope.title        = $scope.itemSelected ? $scope.itemSelected.description+' ('+$scope.itemSelected.qCom+' x R$ '+$scope.itemSelected.vUnCom+')' : 'Selecione o item';
    $scope.subTitle = 'Produtos';
    $scope.createService = function(){
        if( $scope.showInModal ){
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/service/create",
                size            : 'lg',
                controller      : 'service.create',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true
                }
            });
            modalInstance.result.then(function(data) {
                if(data) {
                    $uibModalInstance.close(data);
                } else {
                    $scope.tableParams.reload();
                }
            });
        } else {
            $state.go('service_create');
        }
    }


    if( !$localStorage.filterTableParams ){
        $localStorage.filterTableParams = {
            page : 1,
            count : 15,
            filter : {

            }
        }
    }

    // if( !$localStorage.filterServieGroup ){
        $scope.filterServieGroup = {
            total : 341,
            page : 1,
            count : 20,
            // filter : [
            //     { name : ''}
            // ]
        }
    // }

    if( company_id ){
        $localStorage.filterTableParams.company_id = company_id;
    } else {
        delete $localStorage.filterTableParams.company_id;
    }

    $scope.selectService = function(data){
        if (company_id) {
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/service/show",
                size            : 'lg',
                controller      : 'service.show',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true,
                    company_id  : company_id,
                    id          : data.id
                }
            });
            modalInstance.result.then(function(data) {
            });
        }

        if( $scope.showInModal) {
            $uibModalInstance.close(data);
        } else {
            $state.go('service_show',{id: data.id});
        }
    }

    $scope.type = [
        {id:'', title:'Todos'},
        {id:'P', title:'Produtos'},
        {id:'S', title:'Serviços'}
    ];

    if( $state.current.name == 'purchase_show' ){
        $localStorage.filterTableParams.filter.type = 'P';
        if( $rootScope.cean ) {
            $localStorage.filterTableParams.filter.cEAN = $rootScope.cean;
            $rootScope.cean = null;
        }
    }

    $scope.tableParams = new NgTableParams({
        page: $localStorage.filterTableParams.page,
        count: $localStorage.filterTableParams.count,
        filter: $localStorage.filterTableParams.filter,
        company_id : $localStorage.filterTableParams.company_id ? $localStorage.filterTableParams.company_id : ''
    }, {
        counts: [],
        filterDelay: 100,
        getData: function(params) {
            return $service.query(params.url()).$promise.then(function(data) {
                $localStorage.filterTableParams = params._params;
                params.total(data.total);
                return data.data;

            });
        }
    });

    $scope.getListGroupCategories = function(){
        $service_category_group.query({count:10000, show_in_grid: true}).$promise.then(function(response){
            $scope.groupCategories = response;
        });
    }
    $scope.getListGroupCategories();

    $scope.getStockTotals = function(){
        $scope.waiting = true;
        $service.getStock().then(function(response){
            $scope.totals = response;
            $scope.showStockTotals = true;
            $scope.waiting = false;
        })
    }

    $scope.loadServiceGroup = function(){
        $scope.tableGroupParams = new NgTableParams({
            page: 1,
            count: 20
        }, {
            counts: [],
            filterDelay: 0,
            getData: function(params) {
                return $serviceGroup.query(params.url()).$promise.then(function(data) {
                    params.total(data.total);
                    return data.data;
                });
            }
        });
        // $serviceGroup.query($scope.filterServieGroup).$promise.then(function(response) {
        //     $scope.filterServieGroup.total = response.total;
        //     $scope.dataGroup = response.data;
        //     console.log($scope.filterServieGroup);
        // });
    }

    $scope.generateFile = function(model){
        var params = {
            model     : model,
            itemList  : $scope.labelItems
        }
        $http.post(api+'service/make_label', params).then(function(response){
            var link = angular.element('<a href="' + response.data + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

        }, function(error) {
            $scope.waiting = false;
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout    : false,
                dismissButton       : true,
            });

        });
    }


    $scope.generateTagGondola = function(){
        $service.makeLabelGondola($scope.labelItems).then(function(response){
            $rootScope.print(response);

            $scope.printing = false;

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.selectModelLabel = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectList.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.label = true;

                $scope.title = 'Selecione o modelo da etiqueta';

                $scope.heads = [
                    {
                        field : 'name',
                    },
                ];

                $http.get(api+'company/label').then(function(response){
                    $scope.list = response.data;
                }, function(error){

                });

                $scope.confirm_gondola = function(){
                    $scope.generateTagGondola();
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.confirm = function(model){
                    if(model.label_model === ''){
                        ngToast.danger({
                            content: 'Sem dados de modelo definido',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }else{
                        $scope.generateFile(model);
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printLabel = function(find = null, qtd = null, origin = 'print_label'){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve         : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                }
            }
        });
        $scope.labelItems = [];
        modalInstance.result.then(function(data){
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $scope.labelItems.push({
                        'service_id'            : item.service_id,
                        'name'                  : item.name,
                        'ref'                   : item.obj.external_reference,
                        'value'                 : item.obj.value,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'ean'                   : item.obj.cEAN,
                        'group'                 : item.obj.service_group_id
                    });
                });
            }else{
                $scope.labelItems.push({
                    'service_id'            : data.service,
                    'name'                  : data.name,
                    'ref'                   : data.obj.external_reference,
                    'value'                 : data.obj.value,
                    'quantity'              : data.quantity > 0 ? data.quantity : 1,
                    'ean'                   : data.obj.cEAN,
                    'group'                 : data.obj.service_group_id
                });
            }
            if($scope.labelItems.length > 0){
                $scope.selectModelLabel();
            }
        });
    }

    $scope.print = function(){
        if( $scope.printing ){
            return;
        }

        $scope.printing = true;

        $http.get(api+'service/print_inventory').then(function(response){
            console.log(response.data);
            $rootScope.print(response.data);

            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };


    $scope.adjustmentStock =  function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    :  server+"/views/m/service/stock_movement",
            controller     : 'service.movement',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve        : {
                showInModal : true,
            }
        });
        $scope.labelItems = [];
        modalInstance.result.then(function(data){


        });

    }

}]);

/**
 * Prince Controller
 */
angular.module('service').
controller('service.price', ['$scope', '$http', '$auth', '$q', '$service', 'ngToast','$serviceGroup', 'showInModal', '$uibModal', '$uibModalInstance', '$state', '$rootScope', '$localStorage',  '$category', '$service_category_group', '$stateParams', '$purchaseList', '$timeout',
function($scope, $http, $auth, $q, $service, ngToast,$serviceGroup, showInModal, $uibModal, $uibModalInstance, $state, $rootScope, $localStorage, $category, $service_category_group, $stateParams, $purchaseList, $timeout) {

    $scope.filter = {
        provider    : null,
    };

    $scope.group = {
        categories : []
    }

    $scope.readjustment = {
        valueReadjustment : 0,
        typeReadjustment : "percentage"
    }

    $scope.showButtons = false;
    $scope.showOptions = false;

    $scope.serviceList = [];
    $scope.filtered = false;

    $purchaseList.provider({count:10000}).then(function(response){
        $scope.providers = response;
    });

    $category.query({count:10000}).$promise.then(function(response){
        $scope.categories = response.data;
    });

    $service_category_group.query({service_id : $stateParams.id}).$promise.then(function(response){
        $scope.serviceCategoryGroups = response;
    });

    $scope.loadService = function(){
        $scope.loadingPrice = true;
        $scope.serviceList = [];
        var params = $scope.filter;
        params.category = JSON.stringify(_.pluck(_.filter($scope.serviceCategoryGroups, function(row) { return row.selected; }), 'selected'));

        $service.query(params).$promise.then(function(data){
            $scope.serviceList = data.data;
            $scope.loadingPrice = false;
        });
        $scope.showOptions = true;
    }

    $scope.filteredList = function(data){
        var datafiltered = data;
        if( $scope.filter.name && $scope.filter.name != '')
            datafiltered = _.filter(data, function(row){ return row.name.toUpperCase().indexOf($scope.filter.name.toUpperCase()) >= 0 });
            $scope.filtered = true;
            $scope.serviceListFiltered = datafiltered;
        return datafiltered;
    }

    $scope.cancel = function(){
        $scope.serviceData = "";
        $scope.loadService();
        $scope.showButtons = false;
        // $scope.showOptions = false;
    }

    $scope.selectService = function(data){
        $scope.serviceData = data;
    }

    $scope.calcPrice = function(){
        if( $scope.serviceData.valueReadjustment && $scope.serviceData.valueReadjustment > 0){
            if($scope.readjustment.typeReadjustment == "percentage"){
                //Somar percentual atual ao percentual do reajuste
                $scope.readjustment_fee = parseFloat($scope.serviceData.sale_fee) + parseFloat($scope.serviceData.valueReadjustment);
                $scope.serviceData.newValue = (
                    parseFloat($scope.serviceData.value_calculed) +
                    parseFloat($scope.serviceData.value_calculed * $scope.serviceData.valueReadjustment)
                ).toFixed(2);

                $scope.serviceData.fee_applied = $scope.serviceData.valueReadjustment;
                $scope.serviceData.typeReadjustmentItem = "percentage";

                $scope.serviceData.newSale_fee = (
                    ( ( (parseFloat($scope.serviceData.newValue) * 100) /
                    parseFloat($scope.serviceData.sale_total) ) - 100 ) /
                    100
                ).toFixed(4);
                $scope.serviceData.edited = true;
            } else {
                if($scope.readjustment.typeReadjustment == "value"){
                    $scope.readjustment_value = parseFloat($scope.serviceData.sale_total) + parseFloat($scope.serviceData.valueReadjustment);
                    $scope.serviceData.newValue = (
                        parseFloat($scope.serviceData.value_calculed + $scope.serviceData.valueReadjustment)
                    ).toFixed(2);
                    $scope.serviceData.value_applied = $scope.serviceData.valueReadjustment;
                    $scope.serviceData.typeReadjustmentItem = "value";
                    $scope.serviceData.newSale_fee = (
                        ( ( (parseFloat($scope.serviceData.newValue) * 100) /
                        parseFloat($scope.serviceData.sale_total) ) - 100 ) /
                        100
                    ).toFixed(4);
                    $scope.serviceData.edited = true;
                }
            }
        }
    }

    $scope.calcPriceServiceSelected = function(){
        if( $scope.serviceData.valueReadjustment && $scope.serviceData.valueReadjustment > 0){
            $scope.readjustment_value = parseFloat($scope.serviceData.sale_total) + parseFloat($scope.serviceData.valueReadjustment);
            $scope.serviceData.newValue = (
                parseFloat($scope.serviceData.value_calculed + $scope.serviceData.valueReadjustment)
            ).toFixed(2);
            $scope.serviceData.value_applied = $scope.serviceData.valueReadjustment;
            $scope.serviceData.typeReadjustmentItem = "value";
            $scope.serviceData.newSale_fee = (
                ( ( (parseFloat($scope.serviceData.newValue) * 100) /
                parseFloat($scope.serviceData.sale_total) ) - 100 ) /
                100
            ).toFixed(4);
            $scope.serviceData.edited = true;
        }
    }

    $scope.round = function(){
        _.each($scope.services, function(item){
            if(item.newValue && item.newValue > 0){
                item.newValue = parseFloat(item.newValue).toFixed(1);
            }
        });
    }

    $scope.updateItem = function(){
        $scope.selectService($scope.serviceData);
        $scope.calcPriceServiceSelected();
        $scope.showButtons = true;
        $scope.services = $scope.serviceList;
    }

    $scope.makeReadjustment = function(){
        $scope.loadingPrice = true;
        if($scope.filtered  && $scope.filtered == true){
            $scope.services = $scope.serviceListFiltered;
        }

        if($scope.services.length > 0) {
            $timeout(function () {
                _.each($scope.services,function(item){
                    item.valueReadjustment = $scope.readjustment.valueReadjustment;
                    $scope.selectService(item);
                    $scope.calcPrice();
                });

                $scope.loadingPrice = false;

                $scope.showButtons = true;
                $scope.filter.name = '';
                $scope.filteredList();
                $scope.services = $scope.serviceList;
            }, 300);
        }else{
            $scope.loadingPrice = false;
            ngToast.danger({
                content: 'Selecione os itens para aplicar o reajuste',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }
    }

    $scope.confirmReadjustment = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm-danger.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', function($scope, $uibModalInstance, $service) {
                $scope.waiting = false;
                $scope.title = 'Essa alteração não poderá ser desfeita, Deseja continuar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Confirmar';
                var listToUpdate = [];
                $scope.confirm = function(){
                    $scope.waiting = true;
                    _.each($scope.services, function(item){
                        $scope.service ={};
                        if(item.newValue && item.newValue > 0){
                            $scope.service.id          = item.id;
                            $scope.service.newSale_fee = item.newSale_fee;
                            $scope.service.newValue    = item.newValue;
                            listToUpdate.push($scope.service);
                        }
                    });
                    $service.readjustmentPrice({listToUpdate}).then(function(response){
                        $scope.loadService();
                        $uibModalInstance.close();
                    }, function(error){

                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
        modalInstance.result.then(function() {
            if( $scope.showInModal )
            $uibModalInstance.close();
        });
    };

}]);

angular.module('service').
controller('service.group', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$service', '$serviceGroup', '$stateParams', 'ngToast', '$uibModal', '$category', 'Upload', '$serviceParam', '$serviceParamItem', '$timeout', '$service_category_group', 'showInModal', 'company_id', 'id', 'find', '$uibModalInstance', '$localStorage', '$rootScope',
function($scope, $http, $auth, $q, NgTableParams, $service, $serviceGroup, $stateParams, ngToast, $uibModal, $category, Upload, $serviceParam, $serviceParamItem, $timeout, $service_category_group, showInModal, company_id, id, find, $uibModalInstance, $localStorage, $rootScope) {

    $scope.showInModal  = showInModal;
    $scope.title = 'Grupo';
    $scope.addingOffer      = false;
    $scope.waiting          = false;
    $scope.editingGroup     = false;
    $scope.categories       = [];
    $scope.editingNameItem  = false;
    $scope.labelItems       = [];
    $scope.editingFiscals   = true;
    $scope.crt = $localStorage.company ? $localStorage.company.crt : null;

    $scope.group = {
        categories : []
    }

    $scope.colorOptions = {
        format      : 'hex',
        case        : 'upper',
        inputClass  : 'form-control'
    }

    if (company_id) {
        $scope.company_id = company_id;
        $scope.service_id = id;
        $scope.id = find;
    }

    $scope.loadEdit = function(){
        $scope.editingItem = {};
        $scope.toUpdate = {};
        $scope.editing  = '';
        $scope.toEdit   = {
            name                    : $scope.group.name,
            external_reference      : $scope.group.external_reference,
            purchase_price          : $scope.group.purchase_price,
            value_type              : $scope.group.value_type,
            value                   : $scope.group.value,
            description             : $scope.group.description,
            service_category_id     : $scope.group.service_category_id,
            group_external_reference: $scope.group.group_external_reference
        }
    }


    $service_category_group.query({service_id : $scope.service_id ? $scope.service_id : $stateParams.id, company_id: $scope.company_id, show_sugestion: false}).$promise.then(function(response){
        $scope.serviceCategoryGroups = response;
    });

    $scope.loadGroup = function(){
        $serviceGroup.get({id: $scope.id ? $scope.id : $stateParams.id, company_id: $scope.company_id}).$promise.then(function(response){
            $scope.group    = response;
            $scope.group.services = _.sortBy(response.services, 'name');
            $scope.link     = server + '/oshow/' + $scope.group.services[0].hashid;
            $scope.loadEdit();
        });
    }
    $scope.loadGroup();

    $scope.updateParamItem = function(param){
        $serviceParam.update({id: param.id}, param).$promise.then(function(response){
            $scope.loadGroup();
        });
    }

    $scope.addNewItemParam = function(param){
        $serviceParamItem.newParamItem(param.id).then(function(response){
            $scope.loadGroup();
        });
    }

    $scope.addNewParam = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$serviceParam',
            function($state, $scope, $uibModalInstance, $serviceParam) {
                $scope.title = 'Adicionar novo parâmetro';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';
                $scope.input = {
                    label : 'Nome',
                    type  : 'text',
                    value : null
                }
                $scope.confirm = function() {
                    $serviceParam.newParam({group_id: $scope.$parent.group.id, name: $scope.input.value}).then(function(response){
                        $scope.loadGroup();
                        $uibModalInstance.dismiss('cancel');
                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
    }

    $scope.deleteParam = function(param){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm-danger.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$serviceParam',
            function($state, $scope, $uibModalInstance, $serviceParam) {
                $scope.title = 'Deseja realmente excluir o parametro? Essa alteração pode causar mudanças em todos os produtos da grade.';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';
                $scope.confirm = function() {
                    $serviceParam.deleteParam(param.id).then(function(response){
                        $scope.loadGroup();
                        $uibModalInstance.dismiss('cancel');
                    });
                }
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };
            }]
        });
    }
    $scope.toggleIsChangeded = function() {
        $scope.isChangeded = true;
    }

    var _timeout;
    $scope.saveParamItem = function(item){
        $scope.waiting          = true;
        if( $scope.isChangeded ){
            if(_timeout) {
                $timeout.cancel(_timeout);
            }
            _timeout = $timeout(function () {
                $serviceParamItem.update({id: item.id}, item).$promise.then(function(response){
                    $scope.loadGroup();
                    $scope.waiting          = false;
                });
            }, 300);
        }else{
            $serviceParamItem.update({id: item.id}, item).$promise.then(function(response){
                $scope.waiting          = false;
            });
        }
    }

    $scope.deleteParamItem = function(item){
        var data = _.find($scope.group.params, {id: item.service_param_id});
        if(data.items.length <= 1){
            ngToast.danger({
                content: 'Falha ao excliur: O paramêtro deve ter pelo menos uma variavel',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }else{
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$state', '$scope', '$uibModalInstance', '$serviceParamItem',
                function($state, $scope, $uibModalInstance, $serviceParamItem) {
                    $scope.title = 'Deseja remover esse registro?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';
                    $scope.confirm = function() {
                        $serviceParamItem.delete({id: item.id}).$promise.then(function(data){
                            $scope.loadGroup();
                            $uibModalInstance.dismiss('cancel');
                        })
                    }
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
            });
        }
    }

    $scope.changeEditing = function(data){
        $scope.editing = data;
    }

    $scope.toggleEditItem = function(obj){
        $scope.editingItem = obj;
    }

    $scope.toggleEdit = function(){
        $scope.editingGroup = !$scope.editingGroup;
    }

    $scope.confirmUpdate = function(){
        var params = {
            description : $scope.group.description,
            show_ecommerce : $scope.group.show_ecommerce
        }
        $serviceGroup.saveToAll(params, $stateParams.id).then(function(response){
            ngToast.success({
                content: 'Dado salvo para todos itens',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.loadGroup();
            $scope.toggleEdit();
        }, function(error){
            ngToast.danger({
                content: 'Erro ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.saveToAll = function(data){
        $scope.waiting = true;
        var params = {};
        params[data] = $scope.toEdit[data];
        $serviceGroup.saveToAll(params, $stateParams.id).then(function(response){
            $scope.waiting = false;
            ngToast.success({
                content: 'Dado salvo para todos itens',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.loadGroup();
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.saveExternalReferenceGroup = function(data){
        $scope.waiting = true;
        var params = {};
        params[data] = $scope.toEdit.group_external_reference;
        $serviceGroup.saveExternalReferenceGroup(params, $stateParams.id).then(function(response){
            $scope.waiting = false;
            $scope.loadGroup();
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.saveItem = function(field, service){
        var params = {};
        params[field] = $scope.toUpdate[field];
        $service.update({id: service.id}, params).$promise.then(function(response){
            ngToast.success({
                content: 'Item salvo',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.loadGroup();
        }, function(error){
            ngToast.danger({
                content: 'Erro ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$serviceGroup',
            function($state, $scope, $uibModalInstance, $serviceGroup) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var group = $scope.$parent.group;

                $scope.confirm = function() {
                    $serviceGroup.delete({id:group.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('service');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.generateFile = function(model){
        var params = {
            model     : model,
            itemList  : $scope.labelItems
        }

        $http.post(api+'service/make_label', params).then(function(response){
            var link = angular.element('<a href="' + response.data + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

        }, function(error) {
            $scope.waiting = false;
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout    : false,
                dismissButton       : true,
            });

        });
    }

    $scope.generateTagGondola = function(){
        $service.makeLabelGondola($scope.labelItems).then(function(response){
            $rootScope.print(response);

            $scope.printing = false;

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.selectModelLabel = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectList.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.title = 'Selecione o modelo da etiqueta';

                $scope.label = true;

                $scope.heads = [
                    {
                        field : 'name',
                    },
                ];

                $http.get(api+'company/label').then(function(response){
                    $scope.list = response.data;
                }, function(error){

                });
                $scope.confirm_gondola = function(){
                    $scope.generateTagGondola();
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.confirm = function(model){
                    if(model.label_model === ''){
                        ngToast.danger({
                            content: 'Sem dados de modelo definido',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }else{
                        $scope.generateFile(model);
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printLabel = function(origin = 'service_group_show'){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve        : {
                origin : function(){
                    return origin;
                }
            }
        });
        $scope.labelItems = [];
        modalInstance.result.then(function(data){
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $scope.labelItems.push({
                        'service_id'            : item.service_id,
                        'name'                  : item.name,
                        'ref'                   : item.obj.external_reference,
                        'value'                 : item.obj.value,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'ean'                   : item.obj.cEAN,
                        'group'                 : item.obj.service_group_id
                    });
                });
            }else{
                $scope.labelItems.push({
                    'service_id'            : data.service_id,
                    'name'                  : data.name,
                    'ref'                   : data.obj.external_reference,
                    'value'                 : data.obj.value,
                    'quantity'              : data.quantity > 0 ? data.quantity : 1,
                    'ean'                   : data.obj.cEAN,
                    'group'                 : data.obj.service_group_id
                });
            }
            if($scope.labelItems.length > 0){
                $scope.selectModelLabel();
            }
        });
    }

    $scope.uploadProductImage = function(service){
        Upload.upload({
            url: api+'service/'+service.id+'/upload_image',
            data: {
                image: service.images[service.images.length-1]
            }
        }).then(function (response) {
            service.images[service.images.length-1] = response.data;
            ngToast.success({
                content: 'Imagem salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function (error) {
            service.images.splice(service.images.length-1, 1);
            ngToast.danger({
                content: 'Erro no formato da imagem',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.removeProductImage = function(service, id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', function($scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja realmente remover imagem?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $service.deleteImage(id, service.id).then(function(response){
                        service.images = response;
                        ngToast.success({
                            content: 'Imagem removida com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover imagem',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addStock = function(service){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'movement_create.modal.html',
            controller      : 'movement.create',
            backdrop        : 'static',
            size            : 'sm',
            resolve         : {
                service_id  : service.id
            }
        });

        modalInstance.result.then(function() {
            $scope.loadGroup();
        });
    }

    $scope.addStockGroup = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'movement_create.modal.html',
            controller      : 'movement_many.create',
            backdrop        : 'static',
            size            : 'sm',
            resolve         : {
                service_id  : function(){
                    return $scope.group.id;
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.loadGroup();
        });
    }

    $scope.addNew = function(){
        $scope.addingOffer = !$scope.addingOffer;
        $scope.newOffer = {
            description : '',
            quantity    : null,
            start_at    : null,
            end_at      : null,
            price       : null
        }
    }

    $scope.addOffer = function(newOffer){
        $scope.waiting = true;
        $serviceGroup.saveOffer(newOffer, $scope.group.id).then(function(response){
            $scope.waiting = false;
            $scope.addingOffer = !$scope.addingOffer;
            $scope.loadGroup();
        }, function(error){
            $scope.waiting = false;
        });

    }

    $scope.endOffer = function(offerId, serviceId){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', 'ngToast',
            function($scope, $uibModalInstance, $service, ngToast) {

                $scope.title = 'Deseja encerrar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $service.endOffer(offerId, serviceId).then(function(response){
                        $scope.$parent.loadGroup();
                        $uibModalInstance.close();
                        ngToast.success({
                            content: 'Encerrado com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }, function(error){
                        $uibModalInstance.close();
                        ngToast.danger({
                            content: 'Não foi possível encerrar',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.endAllOffer = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$serviceGroup', 'ngToast',
            function($scope, $uibModalInstance, $serviceGroup, ngToast) {

                $scope.title = 'Deseja encerrar todos?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $serviceGroup.endAllOffer($scope.$parent.group.id).then(function(response){
                        $scope.$parent.loadGroup();
                        $uibModalInstance.close();
                        ngToast.success({
                            content: 'Encerrado com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }, function(error){
                        $uibModalInstance.close();
                        ngToast.danger({
                            content: 'Não foi possível encerrar',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.selectedServiceCategory = function(selected, index){
        if( selected ){
            if( selected.originalObject.custom ){
                var custom = {
                    name        : selected.title,
                    group_id    : $scope.serviceCategoryGroups[index].id
                }
                $category.save(custom).$promise.then(function(response){
                    $scope.serviceCategoryGroups[index].categories.push(response);
                    $scope.syncCategories(response, $scope.serviceCategoryGroups[index].id);
                }, function(error){

                });
            } else {
                if( selected.originalObject.id ){
                    $scope.syncCategories(selected.originalObject , $scope.serviceCategoryGroups[index].id);
                }
            }
        }
    }

    $scope.getInitialData = function(index){
        var category = _.find($scope.group.categories, {group_id : $scope.serviceCategoryGroups[index].id});
        return category ? category.name : '';
    }

    $scope.syncCategories = function(category, groupId) {
        var index = _.findIndex($scope.group.categories, {group_id : groupId});
        if(index >= 0){
            $scope.group.categories.splice(index, 1);
        }
        $scope.group.categories.push(category);
        var toUptade = {
            id : $scope.group.id,
            categories : $scope.group.categories
        }
        $serviceGroup.saveToAll(toUptade, toUptade.id).then(function(){
            $scope.loadGroup();
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.confirmFiscalsParams = function(){
        $scope.waiting = true;
        var group = {
            array : $scope.group,
            hasFiscalsParams : true
        }
        $serviceGroup.saveToAll(group, $stateParams.id).then(function(){
            $scope.loadGroup();
            $scope.waiting = false;
            $scope.editingFiscals = true;
        });
    }

    $scope.confirmShippingParams = function(){
        $scope.waiting = true;
        var group = {
            array : $scope.group,
            hasShippingParams : true
        }
        $serviceGroup.saveToAll(group, $stateParams.id).then(function(){
            $scope.loadGroup();
            $scope.waiting = false;
        });
    }

    $scope.makeEan = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', 'ngToast',
            function($scope, $uibModalInstance, ngToast) {

                $scope.title = 'Deseja realmente gerar novos Cod. Barra para todos produtos?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $serviceGroup.makeEan($stateParams.id).then(function(response){
                $scope.loadGroup();
            });
        });
    }

}]);

/**
 * Create Controller
 */
angular.module('service').
controller('service.create', ['$rootScope', '$scope', '$state', '$service', '$department', 'ngToast', '$localStorage', 'WizardHandler', 'showInModal', '$uibModalInstance', '$service_category_group', '$category', '$uibModal', '$uibModalInstance',
function($rootScope, $scope, $state, $service, $department, ngToast, $localStorage, WizardHandler, showInModal, $uibModalInstance, $service_category_group, $category, $uibModal, $uibModalInstance) {

    $scope.showInModal  = showInModal;
    if(!$scope.showInModal) {
        $rootScope.itemSelected = null;
        $rootScope.itemSelected  = null;
    }

    $scope.title        = 'Cadastro novo item';

    $scope.waiting = false;
    $scope.showMakeItems = false;
    $scope.inputItems = false;
    $scope.hasSugestion = false;
    $scope.hasNewSugestion = false;
    $scope.showSugestion = false;
    $scope.service = {
        type : null,
        name : $rootScope.itemSelected ? $rootScope.itemSelected.name : null
    }
    $scope.addingParam = true;
    $scope.addingOffer = true;
    $scope.showItems = false;
    $scope.change = false;
    $scope.checked = false;
    $scope.params = [];
    $scope.offers = [];
    $scope.items = [];
    $scope.paramsName = [];
    $scope.paramsItems = [];
    $scope.ids = [];
    $scope.newCategory = {name: ''};
    $scope.hasNewCategory = false;
    $scope.newParam = {
        description : '',
        quantity    : null
    }

    $scope.getCategories = function(){
        $service_category_group.query().$promise.then(function(data){
            $scope.serviceCategoryGroups = data;
        });
        $scope.showMakeItems = true;
    }

    $scope.PreviousWizard = function(){
        WizardHandler.wizard().previous();
    }
    $scope.NextWizard = function(){
        if($scope.service.type == 'S'){
            $scope.save();
            return;
        }
        WizardHandler.wizard().next();
    }

    if( $scope.showInModal && $state.current.name == 'purchase_show' ){
        setTimeout(function () {
            $scope.service.type = 'P';
            WizardHandler.wizard().next();
        }, 100);
    }

    $scope.selectType = function(type){
        $scope.service.type = type;
        WizardHandler.wizard().next();
    }

    $scope.addNewParam = function(){
        $scope.addingParam = !$scope.addingParam;
        $scope.addingOffer = !$scope.addingOffer;
        $scope.hasSugestion = false;
        $scope.hasNewSugestion = true;
        $scope.newParam = {
            description : '',
            quantity    : null,
            items       : []
        }
        $scope.newOffer = {
            description : '',
            quantity    : null,
            start_at    : null,
            end_at      : null,
            price       : null
        }
    }

    $scope.addOffer = function(newOffer){

        newOffer.start_at = moment(newOffer.start_at).format('YYYY-MM-DD HH:mm');
        newOffer.end_at = moment(newOffer.end_at).format('YYYY-MM-DD HH:mm');
        if (newOffer.end_at < newOffer.start_at) {
            ngToast.danger({
                content: 'Não foi possível adicionar lote, data de final não pode ser menor que de início',
                dismissOnTimeout: true,
                timeout: 5000
            });

        } else {
            $scope.offers.push(newOffer);
            $scope.addingOffer = !$scope.addingOffer;
        }
    }

    $scope.addParam = function(newParam){
        if (newParam.description != '' && !$scope.hasSugestion){
            _.each($scope.serviceCategoryGroups, function(row){
                if (newParam.description.toUpperCase() == row.name.toUpperCase()) {
                    ngToast.danger({
                        content: 'Já existe um Grupo com esse nome!',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    $scope.newParam = {
                        description : '',
                        quantity    : null,
                        items       : []
                    }
                    $scope.addParam();
                    return;
                }
            });
        }

        if (!$scope.hasSugestion )
        newParam.items = [];
        var seq;
        if( $scope.params.length > 0 ){
            seq = _.max($scope.params[$scope.params.length - 1].items, function(param){ return param.seq; }).seq;
        } else {
            seq = 0;
        }
        if(!$scope.hasSugestion ) for (var i = 1; i <= $scope.newParam.quantity; i++) {
            seq++;
            newParam.items.push({
                seq         : seq,
                description : $scope.newParam.description+' '+i
            });
        }

        $scope.params.push(newParam);
        $scope.addingParam = !$scope.addingParam;
        $scope.makeItems();
    }

    $scope.addParamSugestion = function(){
        if ($scope.params.length > 0) {
            $scope.items = [];
        }
        var newParam = {
            items       : $scope.paramsItems,
            quantity    : $scope.paramsItems.length,
            sugestion   : true
        };
        newParam.description = $scope.paramsName;
        $scope.addingParam = !$scope.addingParam;
        $scope.addParam(newParam);
    }

    $scope.makeItems = function(){

        var count = 1;
        var array = [];
        var key   = 0;
        var w     = 0;

        $scope.items = [];
        // Se der problema deleta e faz de novo!
        // Aqui montamos a grade do jeito que vai salvar!
        for (var i = 0; i < $scope.params.length; i++) {
            w       = 0;
            count   = count * $scope.params[i].quantity;
            array   = [];

            if( i == 0 ){
                for (var y = 0; y < $scope.params[i].quantity; y++) {
                    array.push({
                        param_seq   : $scope.params[i].items[y].seq+',',
                        description : $scope.service.name + ' ' + $scope.params[i].items[y].description,
                        id          : $scope.params[i].items[y].idsCat ? $scope.params[i].items[y].idsCat+',' : ''
                    });
                }
                $scope.items = array;
            } else {
                key = 0;
                while (w < count) {
                    for (var x = 0; x < ($scope.params[i].quantity); x++) {
                        array.push({
                            param_seq   : $scope.items[key].param_seq+$scope.params[i].items[x].seq+',',
                            description : $scope.items[key].description + ' ' + $scope.params[i].items[x].description,
                            id          : $scope.params[i].items[x].idsCat ? $scope.items[key].id+$scope.params[i].items[x].idsCat+',' : ''
                        });

                        w++;
                    }
                    key++;
                }
            }
            $scope.items = array;
        }
        _.each($scope.items, function(row){
            row.param_seq = row.param_seq.split(",", $scope.params.length);
            row.id = row.id.split(",", $scope.params.length);
        });
        $scope.waiting = false;
    }

    $scope.addGroup = function(group = null, id = null){
        $scope.group_id = id;
        $scope.categorySelected = group;
        $scope.inputItems = true;
        $scope.showItems = true;
        $scope.paramsName = $scope.categorySelected;
        $scope.paramsItems = [];
        $scope.getcategory();

    }

    $scope.getcategory = function(){
        var params = {count: 1000, group_id: $scope.group_id}
        $category.get(params).$promise.then(function(data) {
            $scope.categories = data;
        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }

    $scope.addItemGroup = function(item = null, id = null){
        $scope.inputItems = true;
        if ($scope.params.length == 0) {
            $scope.paramsItems.push({
                description : item,
                seq         : $scope.paramsItems.length > 0 ? parseInt($scope.paramsItems[$scope.paramsItems.length - 1].seq) + 1 : 0,
                idsCat      : id
            });
        }else{
            var seq;
            seq = _.max($scope.params[$scope.params.length - 1].items, function(param){ return param.seq; }).seq;
            $scope.paramsItems.push({
                description : item,
                seq         : $scope.paramsItems.length > 0 ? parseInt($scope.paramsItems[$scope.paramsItems.length - 1].seq) + 1 : seq + 1,
                idsCat      : id
            });
        }
    }

    $scope.addNewCategory = function(cat = null, selectG = null){
        _.find($scope.serviceCategoryGroups, function(row){
            if(row.name == selectG){
                $scope.waiting = true;
                var category = {
                    group_id : row.id,
                    name     : cat
                }
                $category.save(category).$promise.then(function(response){
                    $scope.waiting = false;
                    $scope.getcategory();
                    $scope.addItemGroup(response.name, response.id);
                });
                $scope.hasNewCategory = false;
            }
        });

    }

    $scope.adding = function(){
        $scope.hasNewCategory = true;
    }

    $scope.confirmSugestion = function(){
        $scope.waiting = true;
        $scope.hasSugestion = true;
        $scope.showSugestion = true;
        $scope.inputItems = false;
        $scope.addingParam = !$scope.addingParam;
        $scope.addParamSugestion();
    }

    $scope.close = function(){
        $scope.hasSugestion = false;
        $scope.inputItems = false;
        $scope.paramsName = [];
    }

    $scope.save = function() {
        if( $scope.waiting )
            return; 
            
        if( !$scope.service.name )
            return;

        $scope.waiting = true;

        var service = $scope.service;
        if( $scope.params.length > 0 )
            service.params = $scope.params;
        if( $scope.items.length > 0 )
            service.items  = $scope.items;
        if( $scope.offers.length > 0 )
            service.offers  = $scope.offers;

        var notSugestion = [];
        _.each(service.params, function(row){
            if (!row.sugestion) {
                notSugestion.push(row);
            }
        })

        if ( notSugestion.length > 0 ) {
            service.paramGroup = notSugestion;
        }
        $service.save(service).$promise.then(function(data) {
            $scope.waiting = false;
            if( $scope.params.length > 0 ){
                if( $scope.showInModal ){
                    $uibModalInstance.close(false);
                } else {
                    $state.go('service_group_show', {
                        id: data.id
                    });
                }
            } else {
                if( $scope.showInModal ){
                    $uibModalInstance.close(data);
                } else {
                    $state.go('service_show', {
                        id: data.id
                    });
                }
            }
        }, function(error){
            $scope.waiting = false;

        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('service').
controller('service.show', ['$rootScope', '$state', '$scope', '$stateParams', '$uibModal', '$service', '$department', '$localStorage', '$category', '$movement', 'Upload', 'ngToast', 'id', '$uibModalInstance', 'showInModal', 'NgTableParams', '$service_category_group', 'company_id', '$customInput', '$serviceSupply',
function($rootScope, $state, $scope, $stateParams, $uibModal, $service, $department, $localStorage, $category, $movement, Upload, ngToast, id, $uibModalInstance, showInModal, NgTableParams, $service_category_group, company_id, $customInput, $serviceSupply) {

    $scope.showInModal  = showInModal;
    $scope.title = 'Produtos';
    $scope.isPurchase   = $state.current.name == 'purchase_show' ? true : false;
    if(!$scope.showInModal) {
        $rootScope.itemSelected = null;
    }
    
    $scope.waiting = false;
    $scope.isChangeded = false;

    $scope.loadItemSelected = function() {
        if( $rootScope.itemSelected ) {
            $scope.itemSelected = angular.copy($rootScope.itemSelected);
            $scope.serviceId = $scope.itemSelected.id;
            $scope.itemSelected.vDesc   = $scope.itemSelected.vDesc     / $scope.itemSelected.qCom;
            $scope.itemSelected.vFrete  = $scope.itemSelected.vFrete    / $scope.itemSelected.qCom;
            $scope.itemSelected.vIPI    = $scope.itemSelected.vIPI      / $scope.itemSelected.qCom;
            $scope.itemSelected.vOutro  = $scope.itemSelected.vOutro    / $scope.itemSelected.qCom;

            if( $scope.itemSelected.measure_converter_new > $scope.itemSelected.measure_converter_original ) {
                var min        = ($scope.itemSelected.measure_converter_new / $scope.itemSelected.measure_converter_original).toFixed(3);
                var quantity   = ($scope.itemSelected.qCom * min).toFixed(4);

                $scope.itemSelected.vUnCom   = ($scope.itemSelected.vUnCom  / min).toFixed(2);
                $scope.itemSelected.vDesc    = ($scope.itemSelected.vDesc   / min).toFixed(2);
                $scope.itemSelected.vFrete   = ($scope.itemSelected.vFrete  / min).toFixed(2);
                $scope.itemSelected.vIPI     = ($scope.itemSelected.vIPI    / min).toFixed(2);
                $scope.itemSelected.vOutro   = ($scope.itemSelected.vOutro  / min).toFixed(2);

            }
            if( $scope.itemSelected.measure_converter_original > $scope.itemSelected.measure_converter_new ) {
                var min        = ($scope.itemSelected.measure_converter_original / $scope.itemSelected.measure_converter_new).toFixed(3);
                var quantity   = ($scope.itemSelected.qCom / min).toFixed(4);

                $scope.itemSelected.vUnCom  = ($scope.itemSelected.vUnCom  * min).toFixed(2);
                $scope.itemSelected.vDesc   = ($scope.itemSelected.vDesc   * min).toFixed(2);
                $scope.itemSelected.vFrete  = ($scope.itemSelected.vFrete  * min).toFixed(2);
                $scope.itemSelected.vIPI    = ($scope.itemSelected.vIPI    * min).toFixed(2);
                $scope.itemSelected.vOutro  = ($scope.itemSelected.vOutro  * min).toFixed(2);
            }

            $scope.service.uCom                     = $scope.service.uCom ? $scope.service.uCom : $scope.itemSelected.uCom;
            $scope.service.cEAN                     = $scope.service.cEAN ? $scope.service.cEAN : $scope.itemSelected.cean;
            $scope.service.fiscals.cfop_internal    = $scope.service.fiscals.cfop_internal ? $scope.service.fiscals.cfop_internal : ( $scope.itemSelected.cfop > 5999 ? $scope.itemSelected.cfop - 1000 : $scope.itemSelected.cfop);
            $scope.service.fiscals.cfop_external    = $scope.service.fiscals.cfop_external ? $scope.service.fiscals.cfop_external : ( $scope.itemSelected.cfop > 5999 ? $scope.itemSelected.cfop : parseInt($scope.itemSelected.cfop) + 1000);
            $scope.service.fiscals.ncm              = $scope.service.fiscals.ncm ? $scope.service.fiscals.ncm : $scope.itemSelected.ncm;
            $scope.service.fiscals.orig             = $scope.service.fiscals.orig ? $scope.service.fiscals.orig : $scope.itemSelected.orig;
        }
        $scope.title        = $scope.itemSelected ? $scope.itemSelected.description+' ('+$scope.itemSelected.qCom+' x R$ '+$scope.itemSelected.vUnCom+')' : 'Produto';
    }

    $scope.id = id;
    $scope.crt = $localStorage.company ? $localStorage.company.crt : null;
    $scope.editing = true;
    $scope.addingStock = false;
    $scope.categories = [];
    $scope.departments = [];
    $scope.inputList =[];
    $scope.company = $localStorage.company;
    $scope.addingServiceCategoryGroup = false;
    $scope.totalSupplies = 0;
    $scope.service_supplies = [];

    if (company_id) {
        $scope.company_id = company_id;
        $scope.id = id;
    }

    $scope.dropdownConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.service = {
        department_id: {},
        service_category_id : {}
    }

    $scope.inputList = [];

    $scope.getListInput = function(){
        $customInput.query({service_id : $scope.service.id}).$promise.then(function(response){
            $scope.inputList = response;
            _.each($scope.inputList, function(data, key){
                $scope.service.custom_inputs = _.sortBy($scope.service.custom_inputs, 'custom_input_id');
                if($scope.service.custom_inputs.length > 0){
                    $scope.inputList[key].value = $scope.service.custom_inputs[key] ? $scope.service.custom_inputs[key].value : '';
                }
            });
        });
    }

    $scope.getMovements = function() {
        $scope.tableMovementParams = new NgTableParams({
            page: 1,
            count: 15,
            service_id : $scope.service.id,
            company_id : company_id ? company_id : ''
        }, {
            counts: [],
            filterDelay: 10,
            getData: function(params) {
                return $movement.get(params.url()).$promise.then(function(data) {
                    params.total(data.total);
                    return data.data;
                });
            }

        });
    }

    $scope.getService = function(){
        $service.get({
            id : ($scope.id ? $scope.id : $stateParams.id), company_id: $scope.company_id
        }).$promise.then(function(data) {
            data.department_id = {id: data.department_id};
            data.service_category_id = {id: data.service_category_id};
            data.early_receive = data.early_receive ? '1':'0';
            $scope.service = data;
            $scope.oldValue = data.value;
            $scope.service_supplies = $scope.service.service_supplies;
            if ($scope.service_supplies.length > 0) {
                _.each($scope.service_supplies, function(row){
                    row.total = parseFloat(row.service.value_calculed) * row.quantity; 
                })
            }
            $scope.hasOfferAvailable = _.find($scope.service.offers, function(row){ return row.available === true;});
            $scope.getMovements();
            if( $scope.service.fiscals == null ) 
                $scope.service.fiscals = {};
            setTimeout(function () {
                $scope.loadItemSelected();
                $scope.calcPricing();
                $scope.$digest();
                $scope.getListInput();
                $scope.totalSupplies = $scope.calcTotalsserviceSupply();
            }, 300);

        }, function(error) {
            if (error.status == 404) {
                if( $scope.showInModal )
                    $uibModalInstance.dismiss('cancel');
                else
                    $state.go('service');

            }
        });
    }
    $scope.getService();

    $scope.showMoreFiscalFields = false;
    $scope.showMoreFields = function(){
        $scope.showMoreFiscalFields = !$scope.showMoreFiscalFields;
    }

    $scope.showMoreOptions = false;
    $scope.showMore = function(){
        $scope.showMoreOptions = !$scope.showMoreOptions;
    }

    $scope.showGroup = function(){
        if (company_id) {
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/service/group",
                size            : 'lg',
                controller      : 'service.group',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true,
                    company_id  : company_id,
                    id          : $scope.service.group.id,
                    find        : $scope.service.id
                }
            });
            $uibModalInstance.dismiss('cancel');
            modalInstance.result.then(function(data) {

            });
        } else{
            $state.go('service_group_show', {id: $scope.service.group.id});
        }
    }

    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

    $scope.loadServiceCategoryGroup = function(){
        $service_category_group.query({service_id : ($scope.id ? $scope.id : $stateParams.id), company_id: company_id ? company_id : null}).$promise.then(function(response){
            $scope.serviceCategoryGroups = response;
        });
    }
    $scope.loadServiceCategoryGroup();

    $scope.uploadProductImage = function(){
        Upload.upload({
            url: api+'service/'+$scope.service.id+'/upload_image',
            data: {
                image: $scope.service.images[$scope.service.images.length-1]
            }
        }).then(function (response) {
            $scope.service.images[$scope.service.images.length-1] = response.data;
            ngToast.success({
                content: 'Imagem salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function (error) {
            $scope.service.images.splice($scope.service.images.length-1, 1);
            ngToast.danger({
                content: 'Erro no formato da imagem',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.removeProductImage = function(id){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', function($scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja realmente remover imagem?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $service.deleteImage(id, $scope.$parent.service.id).then(function(response){
                        $scope.$parent.service.images = response;
                        ngToast.success({
                            content: 'Imagem removida com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao remover imagem',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.validateTaxInformation = function(){
        if(!$scope.service.value && $scope.service.value_type !== 'manual'){
            ngToast.danger({
                content: 'Erro! Defina um valor de venda',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return false;
        }

        if ($scope.itemSelected){    
            if($scope.itemSelected.sale_fee <= 0 && $scope.service.type == 'P') {
                ngToast.danger({
                    content: 'Erro! Preço de compra maior que preço de venda.',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                return false;
            }

        }else{
            if($scope.service.sale_fee <= 0 && $scope.service.type == 'P') {
                ngToast.danger({
                    content: 'Erro! Preço de compra maior que preço de venda.',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                return false;
            }
        }
         
        if ($scope.service.required_tax_information == 1) {

            if($scope.service.type == 'P'){

                if(!$scope.service.fiscals.csosn && $scope.crt == 1) {
                    ngToast.danger({
                        content: 'Preencha o campo CSOSN.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;
                }

                if(!$scope.service.fiscals.CST && $scope.crt != 1) {
                    ngToast.danger({
                        content: 'Preencha o campo CST.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;
                }


                if (!$scope.service.fiscals.orig) {
                    ngToast.danger({
                        content: 'Preencha o campo Origem.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;

                }

                if (!$scope.service.fiscals.ncm) {
                    ngToast.danger({
                        content: 'Preencha o campo NCM.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;
                }
            }

            if($scope.service.type == 'S'){

                if (!$scope.service.fiscals.indISS) {
                    ngToast.danger({
                        content: 'Preencha o campo de indicador de ISS.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;
                }


                if(!$scope.service.fiscals.cListServ || $scope.service.fiscals.cListServ  < 0 ) {
                    ngToast.danger({
                        content: 'Preencha o Coódigo de serviço.',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    return FALSE;
                }

                if ($scope.service.fiscals.nbs === undefined) {
                    $scope.service.fiscals.nbs = null;
                }

            }
        }
        return true;
    }

    $scope.replicate = function(){
        var bContinue = $scope.validateTaxInformation();
        if ( !bContinue )
            return;
        if($scope.service.group){
            ngToast.danger({
                content: 'Não é possivel replicar produto de grade.',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }
        var idNewService = 0;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state','$scope', '$uibModalInstance', '$service', function($state, $scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja Replicar o produto';
                $scope.subTitle = $scope.$parent.service.name+' ?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var service         = angular.copy($scope.$parent.service);
                service.department_id = service.department_id.id;
                service.service_category_id = service.service_category_id.id;

                $scope.confirm = function() {
                    $service.replicate({
                        id: service.id
                    }, service).then(function(data) {
                        idNewService = data.id;
                        $uibModalInstance.close();
                    });
                    
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]   
        });
        modalInstance.result.then(function() {
            if( $scope.showInModal ){
            $uibModalInstance.close();
            }else{
                $state.go('service_show',{id: idNewService});
            }
        });
    }


    $scope.confirmUpdate = function() {
        var bContinue = $scope.validateTaxInformation();
        if ( !bContinue )
            return;

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', function($scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var service         = angular.copy($scope.$parent.service);
                var oldValue        = angular.copy($scope.$parent.oldValue);

                var itemSelected    = angular.copy($scope.$parent.itemSelected);
                service.department_id = service.department_id.id;
                service.service_category_id = service.service_category_id.id;

                $scope.confirm = function() {
                    if( itemSelected ){
                        service.purchase_price  = itemSelected.purchase_price;
                        service.purchase_vProd  = itemSelected.vUnCom;
                        service.purchase_vDesc  = itemSelected.vDesc;
                        service.purchase_vFrete = itemSelected.vFrete;
                        service.purchase_vIPI   = itemSelected.vIPI;
                        service.purchase_vOutro = itemSelected.vOutro;

                        service.markup          = itemSelected.markup;
                        service.sale_total      = itemSelected.sale_total;

                        service.sale_fee        = itemSelected.sale_fee;
                    }

                    if (parseFloat(service.value) != parseFloat(oldValue)) {
                        service.updated_price = true;
                    }
                    $service.update({
                        id            : service.id
                    }, service).$promise.then(function(data) {
                        $scope.getService();
                        $uibModalInstance.close();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            if( $scope.showInModal )
            $uibModalInstance.close();
        });

    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$service', function($state, $scope, $uibModalInstance, $service) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var service = $scope.$parent.service;

                $scope.confirm = function() {
                    $service.delete({id:service.id}).$promise.then(function(data){
                        $uibModalInstance.close();
                        $state.go('service');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.checkIsChange = function(){
        $scope.service.commission_value = 0;
    }

    $scope.addStock = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'movement_create.modal.html',
            controller      : 'movement.create',
            backdrop        : 'static',
            size            : 'sm',
            resolve         : {
                service_id  : $scope.service.id
            }
        });

        modalInstance.result.then(function() {
            var service = angular.copy($scope.service);
            service.department_id = service.department_id.id;
            service.service_category_id = service.service_category_id.id;
            $service.update({id: service.id}, service).$promise.then(function(data) {
                $scope.getService();
            });
        });
    }

    $scope.toggleStockControl = function() {
        var real_stock = $scope.service.stocks.find(function(row) { return row.company_id == $scope.company.id;} );
        if( real_stock )
            real_stock = real_stock.real_stock;
        else
            real_stock = 0;
        var service = angular.copy($scope.service);
        service.department_id = service.department_id.id;
        service.service_category_id = service.service_category_id.id;

        if( real_stock == 0 ){
            $service.update({
                id: $scope.service.id
            }, service).$promise.then(function(data) {
                $scope.getService();
            });
        }
        if( real_stock < 0 ){
            $service.update({
                id: $scope.service.id
            }, service).$promise.then(function(data) {
                var newMovement = {
                    quantity    : real_stock * (-1),
                    service_id  : $scope.service.id
                }

                var array = [];
                array.push(newMovement);
                $movement.save({array: array, type: 'credit', description: 'Ajuste automático'}).$promise.then(function(response){
                    $scope.getService();
                });
            });
        }
    }

    $scope.addNewParam = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'service_offer.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$service', 'ngToast',
            function($state, $scope, $uibModalInstance, $service, ngToast) {

                $scope.title = 'Adicionar oferta';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Salvar';

                $scope.offer = {
                    description : '',
                    quantity    : null,
                    minimum_quantity : 1,
                    start_at    : null,
                    end_at      : null,
                    price_type  : 'percentage_value',
                    price       : null
                }

                $scope.confirm = function() {
                    var newOffer = $scope.offer;
                    newOffer.start_at = moment(newOffer.start_at).format('YYYY-MM-DD HH:mm');
                    newOffer.end_at = moment(newOffer.end_at).format('YYYY-MM-DD HH:mm');

                    if (newOffer.end_at < newOffer.start_at) {
                        ngToast.danger({
                            content: 'Não foi possível adicionar lote, data de final não pode ser menor que de início',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });

                    } else {
                        $service.addOffer(newOffer, $scope.service.id).then(function(response){
                            $scope.$parent.service.offers = response;
                            ngToast.success({
                                content: 'Lote adicionado',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                            $scope.getService();
                            $uibModalInstance.close();
                        }, function(error){
                            ngToast.danger({
                                content: 'Não foi possível adicionar lote',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                        });
                    }
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.endOffer = function(offerId){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service', 'ngToast',
            function($scope, $uibModalInstance, $service, ngToast) {

                $scope.title = 'Deseja encerrar este lote?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var service = angular.copy($scope.$parent.service);

                $scope.confirm = function() {
                    $service.endOffer(offerId, service.id).then(function(response){
                        $scope.service.offers = response;
                        $uibModalInstance.close();
                        $scope.getService();
                        ngToast.success({
                            content: 'Lote encerrado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }, function(error){
                        $uibModalInstance.close();
                        ngToast.danger({
                            content: 'Não foi possível encerrar lote',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addTax = function(){
        $scope.service.taxes.push({
            'installments'  : $scope.service.taxes.length + 2,
            'tax'           : 0
        });
    }

    $scope.removeTax = function(){
        if( $scope.service.taxes.length > 0 )
            $scope.service.taxes.splice(($scope.service.taxes.length - 1), 1);
    }

    $scope.toggleAllowTaxes = function(){
        if( !$scope.service.allow_installments || $scope.service.allow_installments == 0 )
            $scope.service.taxes = [];
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    if( !$scope.service.purchase_vProd && $scope.service.purchase_price )
        $scope.service.purchase_vProd = $scope.service.purchase_price;

    $scope.calcPercent = function(item,percent){
        if( $scope.service.type != 'P' )
            return;
        if(item == 'purchase_vDesc'){
            if($scope.itemSelected && $scope.itemSelected.vUnCom > 0){
                $scope.itemSelected.vDesc = (percent * $scope.itemSelected.vUnCom).toFixed(2);   
            }else{
                $scope.service.purchase_vDesc = (percent * $scope.service.purchase_vProd).toFixed(2);
            }   
        }
        
        if(item == 'purchase_vFrete'){
            if($scope.itemSelected && $scope.itemSelected.vUnCom > 0){
                $scope.itemSelected.vFrete = (percent * $scope.itemSelected.vUnCom).toFixed(2);   
            }else{
                $scope.service.purchase_vFrete = (percent * $scope.service.purchase_vProd).toFixed(2);
            }  
        }    

        if(item == 'purchase_vIPI'){
            if($scope.itemSelected && $scope.itemSelected.vUnCom > 0){
                $scope.itemSelected.vIPI = (percent * $scope.itemSelected.vUnCom).toFixed(2);   
            }else{
                $scope.service.purchase_vIPI = (percent * $scope.service.purchase_vProd).toFixed(2); 
            }
        }

        if(item == 'purchase_vOutro'){
            if($scope.itemSelected && $scope.itemSelected.vUnCom > 0){
                $scope.itemSelected.vOutro = (percent * $scope.itemSelected.vUnCom).toFixed(2);   
            }else{
                $scope.service.purchase_vOutro = (percent * $scope.service.purchase_vProd).toFixed(2); 
            } 
        }
        $scope.calcPricing();
          
    }    

    $scope.calcPricing = function() {
        if( $scope.service.type != 'P' )
            return;

        $scope.service.purchase_price = (
            parseFloat($scope.service.purchase_vProd) +
            parseFloat( - $scope.service.purchase_vDesc) +
            parseFloat($scope.service.purchase_vFrete) +
            parseFloat($scope.service.purchase_vIPI) +
            parseFloat($scope.service.purchase_vOutro)
        ).toFixed(2);
        if($scope.service.purchase_vDesc > 0){
            $scope.percentDesc = ((parseFloat($scope.service.purchase_vDesc) * 100 / parseFloat($scope.service.purchase_vProd))/100).toFixed(2);;
        }else{
            $scope.percentDesc = 0; 
        }
        if($scope.service.purchase_vFrete > 0){
            $scope.percentFrete = ((parseFloat($scope.service.purchase_vFrete) * 100 / parseFloat($scope.service.purchase_vProd))/100).toFixed(2);
        }else{
            $scope.percentFrete = 0;  
        }
        if($scope.service.purchase_vIPI > 0){
            $scope.percentIPI = ((parseFloat($scope.service.purchase_vIPI) * 100 / parseFloat($scope.service.purchase_vProd))/100).toFixed(2);
        }else{
            $scope.percentIPI = 0; 
        }
        if($scope.service.purchase_vOutro > 0){
            $scope.percentOutro = ((parseFloat($scope.service.purchase_vOutro) * 100 / parseFloat($scope.service.purchase_vProd))/100).toFixed(2);
        }else{
            $scope.percentOutro = 0; 
        }

        $scope.service.markup = (
            parseFloat($scope.service.purchase_price) +
            parseFloat($scope.service.purchase_price * $scope.service.markup_percentage ) +
            parseFloat($scope.service.markup_fee)
        ).toFixed(2);

        $scope.service.sale_total = (
            parseFloat($scope.service.markup) +
            parseFloat($scope.service.value * $scope.service.pICMS_price ) +
            parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.PIS_pPIS > 0 ? $scope.company.invoice_configs.PIS_pPIS / 100 : 0) ) +
            parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.COFINS_pCOFINS > 0 ? $scope.company.invoice_configs.COFINS_pCOFINS / 100 : 0) ) +
            parseFloat($scope.service.value * $scope.service.sale_others)
        ).toFixed(2);

        if( $scope.itemSelected ){
            $scope.itemSelected.purchase_price = (
                parseFloat($scope.itemSelected.vUnCom) +
                parseFloat( - $scope.itemSelected.vDesc) +
                parseFloat($scope.itemSelected.vFrete) +
                parseFloat($scope.itemSelected.vIPI) +
                parseFloat($scope.itemSelected.vOutro)
            ).toFixed(2);

            $scope.itemSelected.markup = (
                parseFloat($scope.itemSelected.purchase_price) +
                parseFloat($scope.itemSelected.purchase_price * $scope.service.markup_percentage ) +
                parseFloat($scope.service.markup_fee)
            ).toFixed(2);

            $scope.itemSelected.sale_total = (
                parseFloat($scope.itemSelected.markup) +
                parseFloat($scope.service.value * $scope.service.pICMS_price) +
                parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.PIS_pPIS > 0 ? $scope.company.invoice_configs.PIS_pPIS / 100 : 0) ) +
                parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.COFINS_pCOFINS > 0? $scope.company.invoice_configs.COFINS_pCOFINS / 100 : 0) ) +
                parseFloat($scope.service.value * $scope.service.sale_others)
            ).toFixed(2);
        }

        $scope.calcReverseFee();
    }


    $scope.calcReverseFee = function() {
        $scope.service.sale_total = (
            parseFloat($scope.service.markup) +
            parseFloat($scope.service.value * $scope.service.pICMS_price ) +
            parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.PIS_pPIS > 0 ? $scope.company.invoice_configs.PIS_pPIS / 100 : 0) ) +
            parseFloat($scope.service.value * ($scope.company && $scope.company.invoice_configs && $scope.company.invoice_configs.COFINS_pCOFINS > 0 ? $scope.company.invoice_configs.COFINS_pCOFIN / 100 : 0) ) +
            parseFloat($scope.service.value * $scope.service.sale_others)
        ).toFixed(2);
        
        $scope.service.sale_fee = (
            ( ( (parseFloat($scope.service.value) * 100) /
            parseFloat($scope.service.sale_total) ) - 100 )/
            100
        ).toFixed(4);
        if( $scope.itemSelected ){
            $scope.itemSelected.sale_fee = (
                ( ( (parseFloat($scope.service.value) * 100) /
                parseFloat($scope.itemSelected.sale_total) ) - 100 )/
                100
            ).toFixed(4);
        }
    }

    $scope.calcFee = function() {
        $scope.service.value = (
            parseFloat($scope.service.sale_total) +
            parseFloat($scope.service.sale_total * $scope.service.sale_fee)
        ).toFixed(2);
        if( $scope.itemSelected ){
            $scope.service.value = (
                parseFloat($scope.itemSelected.sale_total) +
                parseFloat($scope.itemSelected.sale_total * $scope.itemSelected.sale_fee)
            ).toFixed(2);
        }
    }

    $scope.measureConvert = function() {
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : 'measureConvert.modal.html',
            controller      : 'measureConvert',
            backdrop        : 'static',
            size            : 'sm',
            resolve         : {
                service     : $scope.service
            }
        });

        modalInstance.result.then(function(item) {
            $rootScope.itemSelected = item;
            $scope.itemSelected = item;
            $scope.service.uCom = item.measure_converter_uCom_new;

            if( $scope.itemSelected.measure_converter_new > $scope.itemSelected.measure_converter_original ) {
                var min        = ($scope.itemSelected.measure_converter_new / $scope.itemSelected.measure_converter_original).toFixed(3);
                var quantity   = ($scope.itemSelected.qCom * min).toFixed(4);

                $scope.itemSelected.vUnCom   = ($scope.itemSelected.vUnCom  / min).toFixed(2);
                $scope.itemSelected.vDesc    = ($scope.itemSelected.vDesc   / min).toFixed(2);
                $scope.itemSelected.vFrete   = ($scope.itemSelected.vFrete  / min).toFixed(2);
                $scope.itemSelected.vIPI     = ($scope.itemSelected.vIPI    / min).toFixed(2);
                $scope.itemSelected.vOutro   = ($scope.itemSelected.vOutro  / min).toFixed(2);

            }
            if( $scope.itemSelected.measure_converter_original > $scope.itemSelected.measure_converter_new ) {
                var min        = ($scope.itemSelected.measure_converter_original / $scope.itemSelected.measure_converter_new).toFixed(3);
                var quantity   = ($scope.itemSelected.qCom / min).toFixed(4);

                $scope.itemSelected.vUnCom  = ($scope.itemSelected.vUnCom  * min).toFixed(2);
                $scope.itemSelected.vDesc   = ($scope.itemSelected.vDesc   * min).toFixed(2);
                $scope.itemSelected.vFrete  = ($scope.itemSelected.vFrete  * min).toFixed(2);
                $scope.itemSelected.vIPI    = ($scope.itemSelected.vIPI    * min).toFixed(2);
                $scope.itemSelected.vOutro  = ($scope.itemSelected.vOutro  * min).toFixed(2);
            }

            $scope.calcPricing();
        });
    }

    $scope.listProviders = function() {
        $service.listProviders($scope.service.id).then(function(response){
            $scope.providers = response;
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.syncCategories = function(category, groupId) {
        var index = _.findIndex($scope.service.categories, {group_id : groupId});
        if(index >= 0){
            $scope.service.categories.splice(index, 1);
        }
        $scope.service.categories.push(category);
    }

    $scope.selectedServiceCategory = function(selected, index){
        if( selected ){
            if( selected.originalObject.custom ){
                var custom = {
                    name        : selected.title,
                    group_id    : $scope.serviceCategoryGroups[index].id
                }
                $category.save(custom).$promise.then(function(response){
                    $scope.serviceCategoryGroups[index].categories.push(response);
                    $scope.syncCategories(response, $scope.serviceCategoryGroups[index].id);
                }, function(error){

                });
            } else {
                if( selected.originalObject.id ){
                    $scope.syncCategories(selected.originalObject , $scope.serviceCategoryGroups[index].id);
                }
            }
        }
    }
    
    $scope.removeCategory = function(category ,index){ 
        if(category.categories && category.categories.length > 0){
            var toDelete = {
                id : $scope.service.id,
                category : _.find($scope.service.categories, {group_id : $scope.serviceCategoryGroups[index].id})
            }
            $service.removeCategoryService(toDelete.category.id, toDelete.id).then(function(response){
                document.location.reload(true);
            }, function(error){

            });

        }
    }

    $scope.getInitialData = function(index){
        var category = _.find($scope.service.categories, {group_id : $scope.serviceCategoryGroups[index].id});
        return category ? category.name : '';
    }

    $scope.addNewServiceCategoryGroup = function(){
        $scope.newServiceCategoryGroup = {
            'name' : ''
        }
        $scope.addingServiceCategoryGroup = !$scope.addingServiceCategoryGroup;
    }

    $scope.saveNewServiceCategoryGroup = function(){
        if( $scope.newServiceCategoryGroup.name == '' )
            return;

        $service_category_group.save($scope.newServiceCategoryGroup).$promise.then(function(response){
            $scope.serviceCategoryGroups.push(response);
            $scope.addNewServiceCategoryGroup();
        });
    }

    $scope.toggleUpdateValue = function() {
        $scope.updateValue = true;
    }

    $scope.updateValueInputCustom = function(data, id){
        $customInput.updateValue(data, id).$promise.then(function(response){
            $scope.waiting = false;
            $scope.updateValue = false;
        }, function(error){
            $scope.waiting = false;
            $scope.updateValue = false;
        });
    }

    $scope.makeEan = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', 'ngToast',
            function($scope, $uibModalInstance, ngToast) {

                $scope.title = 'Deseja realmente gerar novos Cod. Barra para todos produtos?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $service.makeEan(id).then(function(response){
                // $scope.getService();
                $scope.service.cEAN = response.cEAN;
            });
        });
    }

    $scope.addNewProduct = function(origin = 'serviceSupply', type = 'is_input'){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : server+"/views/m/common/add_service",
            controller      : 'add_service',
            controllerAs    : '$ctrl',
            size            : 'lg',
            backdrop        : 'static',
            resolve         : {
                type : function(){
                    return type;
                },
                origin : function(){
                    return origin;
                }
            }
        });

        modalInstance.result.then(function(data){
            console.log(data);
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $scope.service_supplies.push({
                        'service_id'            : item.service_id,
                        'service_production_id' : $scope.service.id,
                        'name'                  : item.name,
                        'description'           : item.obj.description,
                        'value'                 : item.value > 0 ? item.value : item.service.value_calculed,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'discount'              : 0,
                        'reduction_value'       : 0,
                        'note'                  : item.note,
                        'total'                 : item.total > 0 ? item.total : item.obj.value_calculed * item.quantity,
                        'offer_id'              : item.offer_id,
                        'external_reference'    : item.obj.external_reference,
                        'allow_discount'        : item.allow_discount,
                        'service_value_type'    : item.obj.value_type,
                        'old_value'             : item.obj ? item.obj.value_calculed : item.value,
                        'shipping_value'        : 0,
                        'service'               : item.obj,
                        'production_capacity'   : item.typeProductionQtd === 0 ? 'Indeterminado' : (item.typeProductionQtd === 1 ? 'Padrão' : (item.typeProductionQtd === 'custom' ? item.quantityProduction : '')),
                        'type_production_qtd'   : item.typeProductionQtd,
                        'is_number'             : item.typeProductionQtd === 'custom' ? true : false
                    });
                }); 
            }else{
                $scope.service_supplies.push({ 
                    'service_id'            : data.service,
                    'service_production_id' : $scope.service.id,
                    'name'                  : data.name,
                    'description'           : data.obj.description,
                    'value'                 : data.value > 0 ? data.value : data.obj.value_calculed,
                    'quantity'              : data.quantity > 0 ? data.quantity : 1,
                    'discount'              : 0,
                    'reduction_value'       : 0,
                    'note'                  : data.note,
                    'total'                 : data.total > 0 ? data.total : data.obj.value_calculed * data.quantity,
                    'offer_id'              : data.offer_id,
                    'external_reference'    : data.obj.external_reference,
                    'allow_discount'        : data.allow_discount,
                    'service_value_type'    : data.obj.value_type,
                    'old_value'             : data.obj ? data.obj.value_calculed : data.value,
                    'shipping_value'        : 0,
                    'service'               : data.obj,
                    'production_capacity'   : data.typeProductionQtd === 0 ? 'Indeterminado' : (data.typeProductionQtd === 1 ? 'Padrão' : (data.typeProductionQtd === 'custom' ? data.quantityProduction : '')),
                    'type_production_qtd'   : data.typeProductionQtd ,
                    'is_number'             : data.typeProductionQtd === 'custom' ? true : false
                });
            };
            
            $scope.totalSupplies = $scope.calcTotalsserviceSupply();
        });
    }

    $scope.calcTotalsserviceSupply = function(){
       return _.reduce($scope.service_supplies, function(memo, row){ return memo + (parseFloat(row.service.value_calculed) *  row.quantity); }, 0);
    }

    $scope.saveServiceSupply = function(){
        $scope.waiting  = true;
        var data = {
            service_supplies : $scope.service_supplies
        }
        $serviceSupply.post(data).$promise.then(function(response){
            $scope.getService();
            ngToast.success({
                content: 'Dados salvos com sucesso!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.waiting  = false;
        }), function(error){
            $scope.waiting  = false;
            ngToast.danger({
                content: 'Error ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }
    }

    $scope.removeServiceSupply = function(id){
        $scope.waiting  = true;
        $serviceSupply.deleteSupply(id).then(function(response){
            $scope.getService();
            ngToast.success({
                content: 'Item excluído com sucesso!',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.waiting  = false;
        }), function(error){
            $scope.waiting  = false;
            ngToast.danger({
                content: 'Error ao excluir',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }
    }


}]);

angular.module('service').
controller('measureConvert', ['$rootScope', '$state', '$scope', '$stateParams', '$uibModal', '$service', '$localStorage', 'ngToast', '$uibModalInstance', 'showInModal', 'service', '$companyInvoiceItem',
function($rootScope, $state, $scope, $stateParams, $uibModal, $service, $localStorage, ngToast, $uibModalInstance, showInModal, service, $companyInvoiceItem) {

    $scope.title = 'Conversão de medidas';
    $scope.cancel_text = 'Cancelar';
    $scope.success_text = 'Salvar';

    $scope.service  = service;
    $scope.item     = $rootScope.itemSelected;

    $scope.confirm = function() {
        $companyInvoiceItem.update(
            {id : $rootScope.itemSelected.id},
            $scope.item
        ).$promise.then(function(response){
            $uibModalInstance.close($scope.item);
        }, function(error){

        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);


angular.module('service').
controller('items.updateImage', ['$scope', '$uibModalInstance','Upload', '$timeout',
function($scope,$uibModalInstance, Upload, $timeout) {


    $scope.image;
    $scope.progress = 0;

    $scope.upload = function (dataUrl, name) {
        Upload.upload({
            url: api+'items/'+'/uploadImage',
            data: {
                image: Upload.dataUrltoBlob(dataUrl, name)
            },
        }).then(function (response) {
            $timeout(function () {
                $uibModalInstance.close(response.data.image);
            }, 500);
        }, function (response) {
            if (response.status > 0) console.log(response.status + ': ' + response.data);
        }, function (evt) {
            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
        });
    }
}]);

angular.module('service').
controller('service.movement', ['$rootScope', '$state', '$scope', '$uibModal',  '$localStorage', 'ngToast', '$uibModalInstance', 'showInModal', '$service', '$movement', '$timeout', '$http', '$suggestion', 'type',
function($rootScope, $state, $scope, $uibModal, $localStorage, ngToast, $uibModalInstance, showInModal, $service, $movement, $timeout, $http, $suggestion, type) {

    $scope.newMovement = {
        array : [],
        type : ''
    }

    $scope.showInModal  = showInModal;

    $scope.confirm = false;

    $scope.newMovement.type = 'credit';

    $scope.searchProduct = function(){
        var str = document.getElementById('codFinder').value;
        if(str.length == 0)
            return;

        var qtd = 1;
        if( str.includes('*') ){
            var splitQtd = str.split('*');
            qtd = splitQtd[0].trim();
            str = splitQtd[1].trim();
        }

        $service.codOrRef({'find': str}).then(function(data){
            if( data ){
                if( data.length == 1 ){
                    if($scope.newMovement.type == 'debit'){
                        if( data[0].stock_control == 1 && parseFloat(data[0].real_stock) < parseFloat(qtd) ){
                            ngToast.danger({
                                content             : 'Estoque insuficiente!',
                                dismissOnTimeout    : true,
                                timeout             : 5000
                            });
                            return;
                        }
                    }
                    if(_.where($scope.newMovement.array, {service_id: data[0].id}).length > 0 ) {
                        ngToast.warning({
                            content             : 'O item '+data[0].name +' já foi adicionado <i class="fa fa-exclamation-triangle"/>',
                            dismissOnTimeout    : true,
                            timeout             : 4000
                        });
                    }
                    else{
                        $scope.newMovement.array.push({
                            'external_reference'    : data[0].external_reference,
                            'service_id'            : data[0].id,
                            'name'                  : data[0].name,
                            'description'           : data[0].description,
                            'quantity'              : qtd
                        });
                    }
                } else {
                    $scope.addItem(str, qtd, 'movement_stock');
                }
            } else {
                $scope.addItem(str, qtd, 'movement_stock');
            }
        }, function(error){
            $scope.addItem(str, qtd, 'movement_stock');
        });
        document.getElementById('codFinder').value = '';
    }

    type = $scope.newMovement.type;
    $scope.addItem = function(find = null, qtd = null, origin = 'movement_stock', type = $scope.newMovement.type){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'add_service.modal.html',
            controller      : 'add_service',
            controllerAs    : '$ctrl',
            size            : 'lg',
            backdrop        : 'static',
            resolve         : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                },
                type : function(){
                    return type;
                }
            }
        });

        modalInstance.result.then(function(data){
            if(Array.isArray(data)){
                _.each(data, function(item){
                    if(_.where($scope.newMovement.array, {service_id: item.service_id}).length > 0 ) {
                        ngToast.warning({
                            content             : 'O item'+item.name +' já foi adicionado <i class="fa fa-exclamation-triangle"/>',
                            dismissOnTimeout    : true,
                            timeout             : 4000
                        });
                    }else{
                        $scope.newMovement.array.push({
                            'service_id'            : item.service_id,
                            'name'                  : item.name,
                            'description'           : item.obj.description,
                            'value'                 : item.value > 0 ? item.value : item.obj.value_calculed,
                            'quantity'              : item.quantity > 0 ? item.quantity : 1,
                            'external_reference'    : item.obj.external_reference
                        });
                    }
                });
            }else{
                if(_.where($scope.newMovement.array, {service_id: data.service}).length > 0 ) {
                    ngToast.warning({
                        content             : 'O item'+data.name +' já foi adicionado <i class="fa fa-exclamation-triangle"/>',
                        dismissOnTimeout    : true,
                        timeout             : 4000
                    });
                }else{
                    $scope.newMovement.array.push({
                        'service_id'            : data.service,
                        'name'                  : data.name,
                        'description'           : data.obj.description,
                        'value'                 : data.value > 0 ? data.value : data.obj.value_calculed,
                        'quantity'              : data.quantity > 0 ? data.quantity : 1,
                        'external_reference'    : data.obj.external_reference
                    });
                }
            };
            $scope.newMovement.total_items = _.reduce($scope.newMovement.array, function(memo, row){ return memo + parseFloat(row.quantity); }, 0);
        });

    }

    $scope.removeItem = function(index){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Deseja remover esse item?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $scope.$parent.newMovement.array.splice(index, 1);
                    $scope.$parent.newMovement.total_items = _.reduce($scope.$parent.newMovement.array, function(memo, row){ return memo + parseFloat(row.quantity); }, 0);
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

    }

    $scope.descriptionSuggestionsList =[];

    $suggestion.query({type: 'movement_stock'}).$promise.then(function(response){
        $scope.descriptionSuggestionsList = response;
    });

    $scope.selectDescriptionSuggestions = function(selected){
        if( selected ){
            if( selected.originalObject.custom ){
                var data = {
                    description   : selected.title,
                    type          : 'movement_stock'
                };
                $suggestion.post(data).$promise.then(function(response){
                    $scope.descriptionSuggestionsList.push(response);
                    $scope.newMovement.description = response.description;
                    ngToast.success({
                        content: 'Cadastrado com sucesso!',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                }, function(error){
                    ngToast.danger({
                        content: 'Tipo de movimento já cadastrado.',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.newMovement.description = selected.title;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputMovementStock');
                }
            }
        }
    }

    $scope.confirmMovement = function(){
        if($scope.newMovement.description != ''){
            if( $scope.newMovement.array.length > 0){
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'confirm.modal.html',
                    size: 'sm',
                    scope: $scope,
                    controller: ['$state', '$scope', '$uibModalInstance',
                    function($state, $scope, $uibModalInstance) {

                        $scope.title = 'Deseja continuar com a movimentação?';
                        $scope.cancel_text = 'Não';
                        $scope.success_text = 'Sim';
                        $scope.confirm = function() {
                            $uibModalInstance.close();
                        }
                        $scope.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }]
                });

                modalInstance.result.then(function(){
                    var data = $scope.newMovement;
                    $movement.save(data).$promise.then(function(response){
                        $movement.print(response.id).then(function(response){
                            $rootScope.print(response);
                            $scope.printing = false;
                            $scope.newMovement = {
                                array : [],
                                type : ''
                            }
                        }, function(error) {
                            ngToast.danger({
                                content: 'Sem dados para impressão!',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            $scope.printing = false;
                        });

                    });
                });

            }else{
                ngToast.danger({
                    content: 'Nenhum produto selecionado!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
        }else{
            ngToast.danger({
                content: 'Informe o motivo!',
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    };

}]);

/**
 * Factory
 */
angular.module('service').
factory("$service", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service/:id', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(data, key){
                    response.data[key].value = $filter('finance')(response.data[key].value, true);
                });
                return response;
            }
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.addOffer = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'service/'+id+'/offer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.endOffer = function(offerId, id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service/'+id+'/offer/'+offerId).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.codOrRef = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'service/cof_or_ref', {params : data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.listProviders = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'service/'+id+'/list_providers').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteImage = function(imageId, id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service/'+id+'/delete_image/'+imageId).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getStock = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'service/stock', {params : params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.readjustmentPrice = function(params)
    {
        var deferred = $q.defer();
        $http.put(api+'service/readjustment', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeEan = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'service/'+id+'/make_ean').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.exportInventory = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'service/export_inventory', {params : params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeLabelGondola = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'service/make_label_gondola', {params : params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeCategoryService = function(categoryId, id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service/'+id+'/category/'+categoryId).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.replicate = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'service/'+id+'/replicate', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response){
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.stockAlert = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'service/stock_alert', {params : params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('service').
factory("$serviceGroup", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.saveToAll = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'service_group/'+id+'/update_all', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.makeEan = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'service_group/'+id+'/make_ean').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.saveExternalReferenceGroup = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'service_group/'+id+'/update_ref', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.saveOffer = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'service_group/'+id+'/save_offer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.endAllOffer = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service_group/'+id+'/end_offer').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('service').
factory("$serviceParam", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service_param/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.newParam = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'service_param', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteParam = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service_param/'+id+'/delete_param').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('service').
factory("$serviceParamItem", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service_param_item/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.newParamItem = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'service_param_item/'+id+'/add_new_param_item').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('service').
factory("$suggestion", function($resource, $q, $http) {

    var resource = $resource(api + 'suggestion', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }

    });
    return resource;
});

angular.module('config').
factory("$customInput", function($resource, $q, $http) {
    var resource = $resource(api + 'custom_input', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    resource.updateValue = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'custom_input/'+id+'/update_value', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('service').
factory("$serviceSupply", function($resource, $q, $http) {

    var resource = $resource(api + 'service_supply', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }

    });

    resource.deleteSupply = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'service_supply/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
