angular.module('service_category_group', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('service_category_group').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('service_category_group', {
        url         : "/service_category_group",
        templateUrl : server+"/views/m/service_category_group",
        controller  : 'service_category_group.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Categoria de venda'
        }
    })

    .state('service_category_group_create', {
        url         : "/service_category_group/create",
        templateUrl : server+"/views/m/service_category_group/create",
        controller  : 'service_category_group.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'service_category_group',
            label: 'Adicionar'
        }
    })

    .state('service_category_group_show', {
        url         : "/service_category_group/:id",
        templateUrl : server+"/views/m/service_category_group/show",
        controller  : 'service_category_group.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'service_category_group',
            label: '{{service_category_group.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('service_category_group').
controller('service_category_group.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$service_category_group', '$category', '$state',
function($scope, $http, $auth, $q, NgTableParams, $service_category_group, $category, $state) {
    $scope.waiting = true;
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 20
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $service_category_group.query(params.url()).$promise.then(function(data) {
                $scope.waiting = false;
                return data;
            });
        }
    });

    $scope.getCategory = function(id = null){
        $state.go('service_category_group_show', {id: id});
    }
}]);


/**
 * Create Controller
 */
angular.module('service_category_group').
controller('service_category_group.create', ['$scope', '$state', '$service_category_group', '$department', 'ngToast', '$localStorage',
function($scope, $state, $service_category_group, $department, ngToast, $localStorage) {

    $scope.service_category_group = {
        name : ''
    }

    $scope.save = function() {
        if( !$scope.service_category_group.name )
            return;

        var service_category_group = $scope.service_category_group;

        $service_category_group.save(service_category_group).$promise.then(function(data) {
            $state.go('service_category_group_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('service_category_group').
controller('service_category_group.show', ['$state', '$scope', '$stateParams', '$uibModal', '$service_category_group', '$category', 'NgTableParams', 'ngToast',
function($state, $scope, $stateParams, $uibModal, $service_category_group, $category, NgTableParams, ngToast) {

    $scope.editing = true;
    $scope.waiting = true;
    $scope.addingServiceCategory = false;

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'full_name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.dropdownTranslation = {
        buttonDefaultText: 'Sem Pai',
        checkAll: 'Todas',
        uncheckAll: 'Nenhuma',
        selectionCount: 'selecionada(s)',
        selectionOf: '/',
        searchPlaceholder: 'Pesquisar',
        dynamicButtonTextSuffix: 'selecionada(s)'
    };

    $scope.getData = function(){
        $service_category_group.get({
            id: $stateParams.id, count: 10000
        }).$promise.then(function(data) {
            $scope.service_category_group = data;
            $scope.waiting = false;
        }, function(error) {
            $scope.waiting = false;
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }
    $scope.getData();

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 15,
        group_id   : $stateParams.id
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $category.query(params.url()).$promise.then(function(data) {
                $scope.waiting = false;
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$service_category_group', function($scope, $uibModalInstance, $service_category_group) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var service_category_group = angular.copy($scope.$parent.service_category_group);

                $scope.confirm = function() {
                    $service_category_group.update({
                        id: service_category_group.id
                    }, service_category_group).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$service_category_group', function($state, $scope, $uibModalInstance, $service_category_group) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var service_category_group = $scope.$parent.service_category_group;

                $scope.confirm = function() {
                    $service_category_group.delete({id:service_category_group.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('service_category_group');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.addNewServiceCategory = function(){
        $scope.addingServiceCategory = !$scope.addingServiceCategory;
        $scope.newServiceCategory = {
            group_id    : $scope.service_category_group.id,
            name        : ''
        }
    }

    $scope.saveNewServiceCategory = function() {
        $category.save($scope.newServiceCategory).$promise.then(function(response){
            $scope.addNewServiceCategory();
            $scope.tableParams.reload();
            ngToast.success({
                content: 'Item salvo com sucesso!',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro ao salvar item',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.showCategory = function(categoryId) {
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/category/show",
            size            : 'md',
            controller      : 'category.show',
            backdrop        : 'static',
            resolve         : {
                id : function(){
                    return categoryId;
                },
            }
        });
        modalInstance.result.then(function(data) {
            // $scope.getData();
        });
    }

}]);


/**
 * Factory
 */
angular.module('service_category_group').
factory("$service_category_group", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service_category_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});
