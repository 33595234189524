
angular.module('templates', []);

angular.module('templates').run(function($templateCache) {
    $templateCache.put('clients.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<table class="table table-responsive">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left" style="width: 10%">Cod.</th>'+
            '            <th class="text-left" style="width: 50%">Nome</th>'+
            '            <th class="text-left" style="width: 20%">CPF / CNPJ</th>'+
            '            <th class="text-left" style="width: 15%" ng-if="showValidCert">Vencimento</th>'+
            '            <th class="text-left" style="width: 5%" ng-if="showValidCert"></th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="client in clients track by $index">'+
            '            <td class="text-left" style="width: 10%">{{client.external_code}}</td>'+
            '            <td class="text-left truncate" style="width: 50%" ui-sref="client_show({id: client.id})">{{client.name }}</td>'+
            '            <td class="text-left" style="width: 20%">{{client.cpf_cnpj | brCpfCnpj}}</td>'+
            '            <td class="text-left" style="width: 15%" ng-if="showValidCert">{{client.cert_valid_to | getDate | date :\'dd/MM\'}}</td>'+
            '            <td class="text-left" style="width: 5%" ng-if="showValidCert" ng-click="disable($index)"><i class="fas fa-trash"/></td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
        '</form>'
    );

    $templateCache.put('companies.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<table class="table table-responsive">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left" style="width: 10%">#</th>'+
            '            <th class="text-left" style="width: 20%">CPF / CNPJ</th>'+
            '            <th class="text-left" style="width: 50%">Nome</th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="data in listCompanies" ui-sref="company_show({id: data.id})">'+
            '            <td class="text-left" style="width: 10%">{{data.id}}</td>'+
            '            <td class="text-left truncate" style="width: 10%">{{data.cnpj | brCpfCnpj}}</td>'+
            '            <td class="text-left" style="width: 50%">{{data.name}}</td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
        '</form>'
    );

    $templateCache.put('confirmUser.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body text-center">' +
                '<div class="row">'+
                    '<div class="col-md-6" ng-repeat="seller in sellers">'+
                        '<div class="miniChart" ng-click="selectUser(seller)" ng-class="credentials.user_id == seller.id ? \'active\' : \'\'">'+
                            '<h3><i class="fa fa-user"></i></h3>'+
                            '<p>{{seller.name}}</p>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<hr>'+
                    '</div>'+
                '</div>'+
                '<div class="row" ng-if="seller">'+
                    '<div class="col-md-6 col-md-offset-3">'+
                        '<label>Senha</label>'+
                        '<input id="passwordInput" type="password" class="form-control" ng-model="credentials.password" ng-keyup="$event.keyCode == 13 ? confirm() : null"/>'+
                    '</div>'+
                '</div>'+
            '</div>'+
            '<div class="modal-footer" ng-if="seller">' +
            '    <button class="btn btn-success" type="submit" ng-click="confirm()" promise-btn>{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        '<div class="text-center" ng-bind-html="title"></div>' +
        '<div class="text-center"><p></p></div>' +
        '<div class="text-center" ng-bind-html="subTitle"></div>' +
        '</div>' +
        '<div class="modal-body form-group" ng-if="input">' +
            '<label>{{input.label}}</label>'+
            '<input type="{{input.type}}" class="form-control" ng-model="input.value" />' +
        '</div>' +
        '<div class="modal-body form-group">' +
            '<div class="row" ng-if="bCredntials">'+
                '<div class="col-md-12">' +
                    '<label>Usuário</label>'+
                    '<select class="form-control" ng-model="credentials.user_id">' +
                        '<option value="">Selecione...</option>'+
                        '<option ng-repeat="user in users" ng-value="user.id">{{user.name}}</option>' +
                    '</select>'+
                '</div>' +
                '<div class="col-md-12">' +
                    '<label>Senha</label>'+
                    '<input type="password" class="form-control" ng-model="credentials.password" />' +
                '</div>' +
            '</div>' +
            '<div class="row" ng-if="pCredntials">'+
                '<div class="col-md-12">' +
                    '<label>Usuário</label>'+
                    '<select class="form-control" ng-model="credentials.user_id">' +
                        '<option value="">Selecione...</option>'+
                        '<option ng-repeat="user in permissionsHeight" ng-value="user.id">{{user.name}}</option>' +
                    '</select>'+
                '</div>' +
                '<div class="col-md-12">' +
                    '<label>Senha</label>'+
                    '<input type="password" class="form-control" ng-model="credentials.password" />' +
                '</div>' +
            '</div>' +
            '<div class="row" ng-if="bReason">'+
                '<div class="col-md-12 form-group">'+
                    '<label>{{titleReason ? titleReason : \'Motivo\'}}</label>'+
                    '<textarea name="reason" ng-model="credentials.reason" cols="30" rows="2" class="form-control" required></textarea>'+
                '</div>'+
            '</div>'+
            '<div class="row" ng-if="class_groups">'+
                '<div class="col-md-12">' +
                    '<label>Turmas</label>'+
                    '<select class="form-control" ng-model="check_in_class_group.class_group_id">' +
                        // '<option value="">Selecione...</option>'+
                        '<option ng-repeat="class_group in class_groups" ng-value="class_group.id">{{class_group.name}}</option>' +
                    '</select>'+
                '</div>' +
            '</div>'+
        '</div>' +
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '   <button ng-click="confirm()" ng-disabled="sending || (input && !input.value)" type="button" class="btn btn-success">'+
        '       <span ng-if="!sending"> {{success_text}}</span>'+
        '       <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('confirm-danger.modal.html',
        '<div ng-show="waiting">'+
            '<div class="backgroundLoader"></div>'+
            '<div class="loader"></div>'+
        '</div>'+
        '<div class="modal-body">' +
        '<div class="text-center"><p></p></div>' +
        '<div class="text-center"><label><i class="fa fa-exclamation-triangle fa-4x" aria-hidden="true"  style="color:#d9534f;"></i></label></div>' +
        '<div class="text-center"style="font-size: 30px; font-weight: bold" ng-bind-html="title"></div>' +
        '<div class="text-center"><p></p></div>' +
        '<div class="text-center" ng-bind-html="subTitle"></div>' +
        '</div>' +
        '<div class="modal-body form-group" ng-if="input">' +
            '<span style="font-size: 30px">{{input.label}}</span>'+
            '<input type="{{input.type}}" class="form-control" ng-model="input.value" />' +
        '</div>' +
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '   <button ng-click="confirm()" ng-disabled="sending || (input && !input.value)" type="button" class="btn btn-danger">'+
        '       <span ng-if="!sending"> {{success_text}}</span>'+
        '       <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('select.modal.html',
        '<div class="modal-body">' +
        '<div ng-bind-html="title"></div>' +
        '</div>' +
        '<form name="form">'+
            '<div class="modal-body form-group" ng-if="input">' +
                '<label>{{input.label}}</label>'+
                '<input id="cpfField" name="input" type="{{input.type}}" class="form-control" ng-model="input.value"  ng-keyup="$event.keyCode == 13 ? confirm(optionRight.value) : null" autocomplete="off"/>' +
            '</div>' +
            '<div class="modal-footer btn-group">' +
            '   <button class="btn btn-link" type="button" ng-click="confirm(optionLeft.value)">{{optionLeft.label}}</button>' +
            '   <button class="btn btn-link" type="button" ng-click="confirm(optionRight.value)" ng-disabled="input && form.input.$invalid">{{optionRight.label}}</button>' +
            '</div>' +
        '</form>'+
        '<div class="modal-footer">' +
        '   <button class="btn btn-danger btn-block" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '</div>'
    );

    $templateCache.put('selectCurrency.modal.html',
        '<div class="modal-body">' +
        '<div ng-bind-html="title"></div>' +
        '</div>' +
        '<form name="form">'+
            '<div class="modal-body form-group" ng-if="input">' +
                    '<label>{{input.label}}</label>'+
                    '<input id="valueField" name="valueField" type="{{input.type}}" class="form-control" ng-model="input.value" ui-money-mask ng-keyup="$event.keyCode == 13 && input.value > 0 ? confirm() : null" />' +
            '</div>' +
        '</form>'+
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '   <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="input.value <= 0">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('selectAndConfirm.modal.html',
        '<div ng-show="waiting">'+
            '<div class="backgroundLoader"></div>'+
            '<div class="loader"></div>'+
        '</div>'+
        '<div class="modal-body">' +
        '<div ng-bind-html="title"></div>' +
        '</div>' +
        '<form name="form">'+
            '<div class="modal-body form-group">' +
                    '<label>{{label}}</label>'+
            '</div>' +
            '<div class="modal-footer btn-group">' +
            '   <button class="btn btn-link" type="button" ng-click="confirm()">{{confirm_text}}</button>' +
            '</div>' +
        '</form>'+
        '<div class="modal-footer">' +
        '   <button class="btn btn-danger btn-block" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '</div>'
    );

    $templateCache.put('filter-disabled.html',
        '<span class="fa fa-minus"></span>'
    );

    $templateCache.put('update_status.modal.html',
        '<div class="modal-body text-center">' +
            '<div ng-bind-html="title"></div>' +
            '<div class="row" ng-if="bReason">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Motivo</label>'+
                    '<textarea name="reason" ng-model="protocol.reason" cols="30" rows="2" class="form-control" required></textarea>'+
                    '<label style="color:#d9534f;">Mínimo de 15 caracteres</label>'+
                '</div>'+
            '</div>'+

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="bReason && !protocol.reason || protocol.reason.length < 14" ng-click="confirm()" promise-btn>{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('cancel_nfse.modal.html',
        '<div class="modal-body text-center">' +
            '<div ng-bind-html="title"></div>' +
            '<div class="row" ng-if="bReason">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Motivo</label>'+
                    '<select class="form-control" ng-model="protocol.reason">'+
                        '<option value="1">Erro na emissão</option>'+
                        '<option value="2">Serviço não prestado</option>'+
                        '<option value="4">Duplicidade da nota</option>'+
                    '</select>'+
                '</div>'+
            '</div>'+

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="bReason && !protocol.reason" ng-click="confirm()" promise-btn>' +
        '       <span ng-if="!sending"> {{success_text}}</span>'+
        '       <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('sendfile.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">Enviar arquivos</h4>'+
        '</div>'+
        '<div class="modal-body upload-form text-center">' +
            '<div ng-if="department">'+
            '<div class="col-md-12 text-center">'+
                '<h5>Departamento: {{department}}</h5>'+
            '</div>'+
            '<div class="col-md-4 text-center"> </div>'+
            '<div class="col-md-4 text-center">'+
                '<input type="text" class="form-control cant-cancel text-center"'+
                '   uib-datepicker-popup="dd/MM/yyyy" '+
                '   ng-model="date"'+
                '   is-open="date_open.opened"'+
                '   close-text="Fechar"'+
                '   current-text="Hoje"'+
                '   clear-text="Limpar"'+
                '   ng-focus="openDate(date_open)"'+
                '   alt-input-formats="altInputFormats"'+
                '   tabindex="0"'+
                '   ng-required="true"'+
                '   name="transacted_at"'+
                '   enter'+
                '/>'+
            '</div>'+
            '<div class="col-md-4 text-center"> </div>'+
            '<div class="col-md-12 text-center">  </br></br></div>'+
            '</div>'+
            '<div class="drop-box" required name="upload_files" ng-model="upload_files" ngf-select ngf-drop accept="*" ngf-max-size="10MB" ngf-drop-available="true" ngf-multiple="multiple" multiple="multiple" ngf-keep="true" ngf-model-invalid="errorFile" ngf-max-files="5" ngf-drag-over-class="dragOverClassObj" align="center">'+
                '<button type="button" class="btn-upload"><i class="fa fa-upload"></i>  Selecione os arquivos ou arraste.</button>'+
            '</div>'+

            '<ul class="response">'+
                '<li class="sel-file" ng-repeat="f in invalidFiles">'+
                    '<div>Invalid File: {{f.$errorMessages}} {{f.$errorParam}}, {{f.name}} - size: {{f.size}}B - type: {{f.type}}</div>'+
                '</li>'+
                '</br>'+
            '</ul>'+
        '</div>'+

        '<table class="table table-striped table-condensed upload-form">'+
            '<tbody>'+
                '<tr ng-repeat="f in upload_files">'+
                    '<td class="col-md-8">{{f.name}}</td>'+
                    '<td class="col-md-4"><uib-progressbar ng-show="f.progress >= 0" value="f.progress">{{f.progress}}%</uib-progressbar></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('info.modal.html',
        '<div class="modal-header text-center">' +
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +
        '<div class="modal-body">' +
            '<table class="table table-condensed table-striped">' +
                '<tbody>' +
                    '<tr>' +
                        '<td class="col-md-2"><strong>Arquivo:</strong></td>' +
                        '<td class="col-md-10">{{info.name}}</td>' +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Extensão:</strong></td>' +
                        '<td class="col-md-10">{{info.extension}}</td>' +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Criado em:</strong></td>' +
                        "<td class=\"col-md-10\">{{info.created_at | date:'dd/MM/yyyy'}}</td>" +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Criado por:</strong></td>' +
                        '<td class="col-md-10">{{info.user.name}}</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('add_service.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div id="small-screen" class="button-small-screen" ng-show="listService.items.length > 0" ng-click="showListServiceModal()" uib-tooltip="Ver lista">' +
                    '<i class="fa fa-list icon-button" ng-if="!showListServices"/>' +
                    '<i class="fa fa-times icon-button" ng-if="showListServices"/>' +
            '</div>' +
            '<div ng-if="showListServices" ng-show="listService.items.length > 0" class="col-md-3 background-list">' +
                '<h5>Adicionado(s)</h5>'+
                '<ul>'+
                    '<li ng-repeat="item in listService.items">'+
                        '<i class="fas fa-check" style="color:#57A300;"></i>     |    '+
                        '<span>{{item.service_id}}</span>  -  '+
                        '<b>{{item.name}}</b>  -  '+
                        '<span>{{item.total | currency:\'R$\'}}</span>'+
                        '<b>  - Qtd {{item.quantity}}</b>'+
                    '</li>'+
                    '<hr>'+
                    '<li><b class="total-quantity">Total itens: {{list_items_total_quantity}}</b></li>'+
                    '<li ng-show="!hide_params"><b class="total">Total: {{list_items_total | currency:\'R$\'}}</b></li>'+
                '</ul>'+
            '</div>' +
            '<div class="col-md-12">'+
                '<div class="col-md-4 pull-right">'+
                    '<h5 style="margin-bottom: 5px;">Pesquisa geral</h5>'+
                    '<input type="text" class="form-control" ng-model="tableParams._params.filter.findAny" ng-change="tableParams.reload(), tableParams.page(1)" autocomplete="off"/>'+
                '</div>'+
            '</div>'+
            '<div class="modal-body small-screen-modal-body">' +
                '<div class="row modal-body modal-content-scroll scroll-inner">'+
                    '<table id="tableService" ng-table="tableParams" show-filter="false"  class="table table-condensed table-striped table-pointer table-hover table-scroll" style="width: {{width_table}}px;">' +
                        '<tr>'+
                            '<th class="col-md-1 text-center">Grupo</th>'+
                            '<th class="col-md-1 text-center">Ref</th>'+
                            '<th class="col-md-3 text-center">Nome</th>'+
                            '<th class="col-md-1 text-right">Estoque</th>'+
                            '<th class="col-md-1 text-right">Valor</th>'+
                            '<th class="col-md-1 text-right">Oferta</th>'+
                            //Preenche os titulos da tabela com os custom_intputs
                            '<th ng-if="inputList.length > 0" ng-repeat="label in inputList" class="text-center">{{label.label}}</th>'+
                            //Preenche os titulos da tabela com os grupos de categorias
                            '<th ng-if="groupCategories.length > 0" ng-repeat="label in groupCategories" class="text-center">{{label.name}}</th>'+

                        '</tr>'+
                        '<tr>'+
                            '<th class="col-md-1 text-center"><input type="text" class="form-control" ng-model="tableParams._params.filter.group_external_reference" ng-change="tableParams.reload(), tableParams.page(1)"/></th>'+
                            '<th class="col-md-1 text-center"><input type="text" class="form-control" ng-model="tableParams._params.filter.external_reference" ng-change="tableParams.reload(), tableParams.page(1)" /></th>'+
                            '<th class="col-md-3"><input id="searchNameService" type="text" class="form-control" ng-model="tableParams._params.filter.name" ng-change="tableParams.reload(), tableParams.page(1)" autocomplete="off"/></th>'+
                            '<th class="col-md-1"></th>'+
                            '<th  class="col-md-1"></th>'+
                            '<th  class="col-md-1"></th>'+
                            ///Preenche a area de filtos da tabela com os custom_intputs (só pra não quebrar o layout)
                            '<th ng-if="inputList.length > 0" ng-repeat="label in inputList"></th>'+
                            ///Preenche a area de filtos da tabela com os custom_intputs (só pra não quebrar o layout)
                            '<th ng-if="groupCategories.length > 0" ng-repeat="label in groupCategories"></th>'+

                        '</tr>'+
                        '<tr ng-repeat="row in $data track by row.id" ng-click="selectService(row)" ng-class="{active:selected_service==row.id}">' +
                            '<td title="\'Grupo\'" sortable="\'group.external_reference\'" filter="{group_external_reference: \'text\'}" class="col-md-1 text-center">{{row.group.external_reference}}</td>' +
                            '<td title="\'Ref\'" sortable="\'external_reference\'" filter="{external_reference: \'text\'}" class="col-md-1 text-center">{{row.external_reference}}</td>' +
                            '<td title="\'Nome\'" sortable="\'name\'" filter="{name: \'text\'}" class="col-md-3">'+
                                '{{row.name}}'+
                                '&nbsp;<span ng-show="{{row.images[0]}}"><i class="fa fa-camera"></i></span>'+
                            '</td>' +
                            '<td title="\'Estoque\'" sortable="\'real_stock\'" class="col-md-1 text-right"><i class="fas fa-plus-circle pull-right" style="margin-top:3px; margin-left:3px;" ng-if="row.stocks.length > 1" uib-tooltip="Consultar estoque de outras lojas" ng-click="showStocks(row.stocks)"></i><span ng-if="row.type !=\'E\'">{{ row.real_stock | number: decimals}}</span></td>' +
                            '<td title="\'Valor\'" sortable="\'value\'" class="col-md-1 text-right">'+
                                '{{row.value_calculed | currency:\'R$\'}}'+
                                '&nbsp;<i class="fas fa-tag fa-lg" style="margin-top:3px; margin-left:3px; color:#d9534f" ng-if="validadeOffer(row.offers)" uib-tooltip="Produto possui oferta"></i>'+
                                '&nbsp;<i class="fas fa-lock fa-lg" style="margin-top:3px; margin-left:3px;" ng-if="row.value_type == \'blocked\'" uib-tooltip="Valor bloqueado para desconto"></i>'+
                            '</td>' +
                            '<td title="\'Oferta\'" sortable="\'price_calculed\'" class="col-md-1 text-right" >{{ valueOffer(row.offers) | currency:\'R$\'}} <i class="fas fa-plus-circle pull-right" style="margin-top:3px; margin-left:3px;" ng-if="validadeOffer(row.offers) && validadeQtdOffer(row.offers)" ng-click="showOffers(row.offers)"></i></td>' +
                            // '<td title="\'Oferta\'" sortable="\'price_calculed\'" class="col-md-1 text-right" ng-if="!validadeOffer(row.offers)"> </td>' +
                            //Preenche os valores dos custom_intputs
                            '<td ng-if="row.custom_inputs.length > 0" ng-repeat="label in inputList" class="text-center" ><span ng-repeat="custom in row.custom_inputs">{{label.id == custom.custom_input_id ? custom.value : \'\' }}</span></td>' +
                            '<td ng-if="!row.custom_inputs.length > 0" ng-repeat="custom in inputList" class="text-center" >--</td>' +
                            //Preenche os valores dos custom_intputs
                            '<td ng-if="row.categories.length > 0" ng-repeat="label in groupCategories" class="text-center" ><span ng-repeat="category in row.categories">{{label.id == category.group_id ? category.name : \'\' }}</span></td>' +
                            '<td ng-if="!row.categories.length > 0" ng-repeat="category in groupCategories" class="text-center" >--</td>' +

                        '</tr>' +
                    '</table>' +
                '</div>'+

                '<div class="row">' +
                    '<div class="col-md-2">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Quantidade</label>' +
                            '<input id="quantity" name="quantity" type="text"  class="form-control" ng-model="quantity" required ui-number-mask="{{decimals}}" autocomplete="off"/>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-2" ng-show="show_quantity && !hide_params">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Valor</label>' +
                            '<input name="value" type="text" class="form-control" ng-class="{\'text-danger\': blockConfirm, \'text-default\': !blockConfirm}" ng-model="value" ng-blur="getMaxValue()" ng-disabled="hasDiscountProtocol" required ui-money-mask/>' +
                            '<span ng-if="blockConfirm" class="text-danger">Desconto inválido</span>'+
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-2" ng-show="show_addItem">' +
                        '<div class="form-group">' +
                            '<label class="control-label">&nbsp; &nbsp;</label>' +
                            '<button type="button" class="btn btn-primary" ng-click="add_items()">{{textButton}}</button>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-2 pull-right" ng-show="all_label_group">' +
                        '<div class="form-group">' +
                            '<label class="control-label">&nbsp; &nbsp;</label>' +
                            '<button type="button" class="btn btn-default" ng-click="add_all_items()">Adicionar todos</button>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-2" ng-show="show_quantity && !hide_params">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Desconto</label>' +
                            '<button type="button" class="btn btn-success" ng-click="makeDiscountManual()" ng-disabled="hasDiscountValue">Aplicar Desconto</button>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-2" ng-show="show_quantity && !hide_params">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Total</label>' +
                            '<input type="text" class="form-control" readonly="readonly" ng-model="total" required ui-money-mask/>' +
                        '</div>' +
                    '</div>' +
//                  '<div class="col-md-3" ng-show="service.type == \'E\' && service.offers.length > 0">' +
                    '<div class="col-md-2" ng-show="service.offers.length > 0 && !hide_params">' +
                        '<div class="form-group">' +
                            '<label class="control-label" ng-show="service.type == \'E\' ">Lote</label>' +
                            '<label class="control-label" ng-show="service.type == \'P\' ">Promoção</label>' +
                            '<label class="control-label" ng-show="service.type == \'S\' ">Promoção</label>' +
                            '<select class="form-control" ng-model="selectedOffer" ng-change="selectOffer()">' +
                                '<option value="">Selecione</option>'+
                                '<option ng-repeat="offer in service.offers" ng-value="offer" ng-if="offer.available">{{offer.description}}</option>'+
                            '</select>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-12" ng-show="!hide_params">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Observação</label>' +
                            '<textarea id="note" name="note" ng-model="note" cols="30" rows="2" class="form-control"></textarea>'+
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-3 background-thumbnail-show-modal" ng-show="service.images.length > 0" style="margin-right: 900px !important; magin-left: 0 !important;">' +
                        '<div class="picture thumbnail-modal">'+
                            '<img ng-src="{{service.images[0].src}}"/>'+
                        '</div>'+
                    '</div>' +
                '</div>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn" ng-class="company.company_type_id == 3 ? \'button-duda\' : \'btn-success\'" ng-disabled="!selected_service || quantity == 0 || (service.type == \'E\' && service.offers.length > 0 && !selectedOffer)" type="submit" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );


    //template add e update address
    $templateCache.put('add_address.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Adicionar endereço</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_step">'+
                '<div class="row">'+
                    '<div class="col-md-3 form-group" show-errors>'+
                        '<label class="control-label">CEP</label>'+
                        '<input type="text" ng-model="data.zip_code"  id="CEP" class="form-control" name="PEC" autocomplete="off" ui-br-cep-mask>'+
                    '</div>'+

                    '<div class="col-md-9 form-group" show-errors>'+
                        '<label class="control-label">Endereço</label>'+
                        '<input type="text" ng-model="data.line1" autocomplete="nope" class="form-control" autocomplete="off" required>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-2 form-group" show-errors>'+
                        '<label class="control-label">Número</label>'+
                        '<input type="text" ng-model="data.number" id="NR" class="form-control" autocomplete="off" required>'+
                    '</div>'+

                    '<div class="col-md-6 form-group" show-errors>'+
                        '<label class="control-label">Complemento</label>'+
                        '<input type="text" ng-model="data.line2" class="form-control" autocomplete="off">'+
                    '</div>'+

                    '<div class="col-md-4 form-group" show-errors>'+
                        '<label class="control-label">Bairro</label>'+
                        '<input type="text" ng-model="data.neighborhood" required class="form-control" autocomplete="off">'+
                    '</div>'+
                '</div>'+

                '<div class="row">'+
                    '<div class="col-md-4 form-group" show-errors>'+
                        '<label class="control-label">Estado</label>'+
                        // '<div ng-if="states" ng-required="true" ng-dropdown-multiselect options="states" selected-model="data.state_id" extra-settings="dropdownConfig" translation-texts="dropdownTranslation" ng-change="updateCities(state_id)"></div>'+
                        '<select class="form-control" ng-model="data.state_id.id" ng-change="updateCities(state_id.id)" >'+
                            '<option ng-value="state.id" ng-repeat="state in states" selected="data.state_id.id">{{state.name}}</option>'+
                        '</select>'+
                    '</div>'+

                    '<div class="col-md-4 form-group" show-errors>'+
                        '<label class="control-label">Cidade</label>'+
                        // '<div ng-required="true" ng-dropdown-multiselect options="cities" selected-model="data.city_id" extra-settings="dropdownConfig" translation-texts="dropdownTranslation"></div>'+
                        '<select class="form-control" ng-model="data.city_id.id">'+
                            '<option ng-value="city.id" ng-repeat="city in cities" selected="data.city_id.id">{{city.name}}</option>'+
                        '</select>'+
                    '</div>'+

                    '<div class="col-md-4 form-group" show-errors>'+
                        '<label class="control-label">Tipo</label>'+
                        '<select type="text" ng-model="data.type" class="form-control">'+
                            '<option value="Correspondência" selected="selected">Correspondência</option>'+
                            '<option value="Cobrança">Cobrança</option>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
            '</form>'+
        '</div>'+

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        // '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_step.$invalid || data.discount <= 0 || data.discount > data.value">{{success_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('discount.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Solicitar desconto</h4>'+
            '<button class="btn btn-link btn-info pull-right" ng-if="form_step.discount.$error.max || form_step.percentage.$error.max" type="button" ng-click="getPermissionDiscount()">Solicitar liberação</button>' +
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_step">'+
                '<div class="row">'+
                    '<div class="col-md-3 form-group">'+
                        '<label>Valor <span class="text-danger">*</span></label>'+
                        '<p class="text-right">{{protocol.value | finance:true}}</p>'+
                    '</div>'+

                    '<div class="col-md-3 form-group" show-errors>'+
                        '<label class="control-label">Tipo <span class="text-danger">*</span></label>'+
                        '<select class="form-control" ng-model="discount.type" ng-change="updateTotal()">'+
                            '<option ng-value="\'value\'">Valor</option>'+
                            '<option ng-value="\'percentage\'">Porcentagem</option>'+
                        '</select>'+
                    '</div>'+

                    '<div class="col-md-3 form-group" show-errors ng-if="discount.type == \'value\'">'+
                        '<label class="control-label">Desconto <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control text-right" ng-change="updateTotal()" ng-model="protocol.discount" name="discount" id="getDiscount" max="rLimitDiscount" ui-money-mask required/>'+
                        '<span ng-if="form_step.discount.$error.max" class="help-block">Desconto inválido</span>'+
                    '</div>'+

                    '<div class="col-md-3 form-group" show-errors ng-if="discount.type == \'percentage\'">'+
                        '<label class="control-label">Desconto <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control text-right" ng-change="updateTotal()" ng-model="discount.percentage" name="percentage" max="rLimitDiscount/100" ui-percentage-mask required/>'+
                         '<span ng-if="form_step.percentage.$error.max" class="help-block">Desconto inválido</span>'+
                    '</div>'+

                    '<div class="col-md-3 form-group">'+
                        '<label>Total <span class="text-danger">*</span></label>'+
                        '<p class="text-right">{{protocol.total | finance:true}}</p>'+
                    '</div>'+
                '</div>'+

                '<div class="row" ng-if="showReason">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Motivo</label>'+
                        '<textarea name="reason" ng-model="protocol.reason" cols="30" rows="2" class="form-control" required></textarea>'+
                    '</div>'+
                '</div>'+

            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_step.$invalid || protocol.discount <= 0 || protocol.discount > protocol.value">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('voucher.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Solicitar desconto</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_step">'+
                '<div class="row">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Nome</label>'+
                        '<input type="text" class="form-control" ng-model="voucher.name" required />'+
                    '</div>'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Cod. Desconto</label>'+
                        '<input type="text" class="form-control" ng-model="voucher.code" required />'+
                    '</div>'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Descrição</label>'+
                        '<textarea class="form-control" ng-model="voucher.description" />'+
                    '</div>'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Tipo de desconto</label>'+
                        '<select class="form-control" ng-model="voucher.discount_type">'+
                            '<option ng-value="\'V\'">Valor</option>'+
                            '<option ng-value="\'P\'">Porcentagem</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Desconto <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="voucher.discount" ui-money-mask ng-if="voucher.discount_type == \'V\'" required />'+
                        '<input type="text" class="form-control" ng-model="voucher.discount" ui-percentage-mask ng-if="voucher.discount_type == \'P\'" required />'+
                    '</div>'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Tipo de regra</label>'+
                        '<select class="form-control" ng-model="voucher.rule_type">'+
                            '<option ng-value="\'V\'">Valor</option>'+
                            '<option ng-value="\'Q\'">Quantidade</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Minimo <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="voucher.rule_min" ui-money-mask ng-if="voucher.rule_type == \'V\'" required >'+
                        '<input type="text" class="form-control" ng-model="voucher.rule_min" ui-number-mask="0" ng-if="voucher.rule_type == \'Q\'" required >'+
                    '</div>'+
                '</div>'+
            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
            '<button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '<button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_step.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('os.modal.html',
        '<div class="modal-body">' +

            '<div class="row">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Destinatario</label><br>'+
                    '<label class="radio-inline"><input type="radio" ng-model="protocol_type" value="client" ng-click="changeType(\'client\')">Cliente Cadastrado</label>'+
                    '<label class="radio-inline"><input type="radio" ng-model="protocol_type" value="consumer" ng-click="changeType(\'consumer\')">Consumidor</label>'+
                '</div>'+
            '</div>'+
            '<div class="row" ng-if="protocol_type == \'client\'">'+
                '<div class="col-md-8 form-group">'+
                    '<label>Cliente</label>'+
                    '<div ng-dropdown-multiselect options="clients" selected-model="client" '+
                    'extra-settings="selectConfig" translation-texts="translation"></div>'+
                '</div>'+
                '<div class="col-md-4 form-group">'+
                    '<label>&nbsp;</label>'+
                    '<button class="btn btn-default btn-block" ng-click="addClient()">Cadastrar</button>'+
                '</div>'+
            '</div>'+
            '<div class="row" ng-if="protocol_type == \'consumer\'">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Nome</label>'+
                    '<input type="text" class="form-control" ng-model="newClient.name" ng-if="newClient.hasNewClient" />'+
                    '<angucomplete-alt id="inputContact" ng-if="!newClient.hasNewClient"'+
                    '    input-name="inputContact"'+
                    '    pause="10"'+
                    '    selected-object="selectNameClient"'+
                    '    selected-object-data="row"'+
                    '    local-data="clients"'+
                    '    search-fields="data.name"'+
                    '    title-field="data.name"'+
                    '    description-field="cpf_cnpj"'+
                    '    override-suggestions="false"'+
                    '    field-required="true"'+
                    '    minlength="2"'+
                    '    auto-match="false"'+
                    '    template-url="select_template.html"'+
                    '    input-class="form-control cant-cancel"'+
                    '    field-tabindex="2"'+
                    '    text-no-results=""'+
                    '    focus-first="true"/>'+
                '</div>'+
            '</div>'+
            '<div class="row" ng-if="protocol_type == \'consumer\'">'+
                '<div class="col-md-6 form-group">'+
                    '<label>Telefone</label>'+
                    '<input type="text" class="form-control" ng-model="newClient.phone" ng-if="newClient.hasNewClient" ui-br-phone-number-mask />'+
                    '<angucomplete-alt id="inputContact" ng-if="!newClient.hasNewClient"'+
                    '    input-name="inputContact"'+
                    '    pause="10"'+
                    '    selected-object="selectPhoneClient"'+
                    '    selected-object-data="row"'+
                    '    local-data="clients"'+
                    '    search-fields="phone"'+
                    '    title-field="data.name"'+
                    '    description-field="phone"'+
                    '    override-suggestions="false"'+
                    '    field-required="true"'+
                    '    minlength="2"'+
                    '    auto-match="false"'+
                    '    template-url="select_template.html"'+
                    '    input-class="form-control cant-cancel"'+
                    '    field-tabindex="2"'+
                    '    text-no-results=""'+
                    '    focus-first="true"/>'+
                '</div>'+
                '<div class="col-md-6 form-group">'+
                    '<label>CPF/CNPJ</label>'+
                    '<input type="text" class="form-control" ng-model="newClient.cpf_cnpj" ng-if="newClient.hasNewClient" ui-br-cpfcnpj-mask />'+
                    '<angucomplete-alt id="inputContact" ng-if="!newClient.hasNewClient"'+
                    '    input-name="inputContact"'+
                    '    pause="10"'+
                    '    selected-object="selectDocumentClient"'+
                    '    selected-object-data="row"'+
                    '    local-data="clients"'+
                    '    search-fields="cpf_cnpj"'+
                    '    title-field="data.name"'+
                    '    description-field="cpf_cnpj"'+
                    '    override-suggestions="false"'+
                    '    field-required="true"'+
                    '    minlength="2"'+
                    '    auto-match="false"'+
                    '    template-url="select_template.html"'+
                    '    input-class="form-control cant-cancel"'+
                    '    field-tabindex="2"'+
                    '    text-no-results=""'+
                    '    focus-first="true"/>'+
                '</div>'+
            '</div>'+

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="(protocol_type == \'client\' && !client.id) || opening" ng-click="confirm()" >' +
        '       <span ng-if="!opening">{{success_text}}</span>'+
        '       <span ng-if="opening">Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put("uib/template/rating/rating.html",
        "<span ng-mouseleave=\"reset()\" ng-keydown=\"onKeydown($event)\" tabindex=\"0\" role=\"slider\" aria-valuemin=\"0\" aria-valuemax=\"{{range.length}}\" aria-valuenow=\"{{value}}\" aria-valuetext=\"{{title}}\">\n" +
            "<span ng-repeat-start=\"r in range track by $index\" class=\"sr-only\">({{ $index < value ? '*' : ' ' }})</span>\n" +

            "<span ng-repeat-end class=\"fa-stack fa-3x\" ng-mouseenter=\"enter($index + 1)\" ng-click=\"rate($index + 1)\">"+
                "<i class=\"fa fa-stack-2x\" ng-class=\"$index < value && (r.stateOn || 'glyphicon-star') || (r.stateOff || 'glyphicon-star-empty')\" ng-attr-title=\"{{r.title}}\"></i>\n"+
                "<strong class=\"fa fa-stack-1x\" ng-class=\"$index < value && ('fa-inverse') || ('')\">{{$index+1}}</strong>"+
            "</span>"+

        "</span>\n" +
    "");

    $templateCache.put('windowCentered.modal.html',
        "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" +
        "    uib-modal-animation-class=\"fade\"\n" +
        "    modal-in-class=\"in\"\n" +
        "    ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\">\n" +
            '<div class="vertical-alignment-helper">'+
                '<div class="vertical-align-center">'+
                    "<div class=\"modal-dialog {{size ? 'modal-' + size : ''}}\"><div class=\"modal-content avatar-modal\" uib-modal-transclude></div></div>\n" +
                '</div>'+
            '</div>'+
        "</div>\n"
    );

    $templateCache.put('accounts_table.html',
        "<div class=\"table-responsive\">\n" +
        " <table class=\"table table-condensed table-hover table-pointer tree-grid\">\n" +
        "   <thead>\n" +
        "     <tr>\n" +
        "       <th><a ng-if=\"expandingProperty.sortable\" ng-click=\"sortBy(expandingProperty)\">{{expandingProperty.displayName || expandingProperty.field || expandingProperty}}</a><span ng-if=\"!expandingProperty.sortable\">{{expandingProperty.displayName || expandingProperty.field || expandingProperty}}</span><i ng-if=\"expandingProperty.sorted\" class=\"{{expandingProperty.sortingIcon}} pull-right\"></i></th>\n" +
        "       <th class=\"{{col.headerClass}}\" ng-repeat=\"col in colDefinitions\"><a ng-if=\"col.sortable\" ng-click=\"sortBy(col)\">{{col.displayName || col.field}}</a><span ng-if=\"!col.sortable\">{{col.displayName || col.field}}</span><i ng-if=\"col.sorted\" class=\"{{col.sortingIcon}} pull-right\"></i></th>\n" +
        "     </tr>\n" +
        "   </thead>\n" +
        "   <tbody>\n" +
        "     <tr ng-repeat=\"row in tree_rows | searchFor:$parent.filterString:expandingProperty:colDefinitions track by row.branch.uid\"\n" +
        "       ng-class=\"'level-' + {{ row.level }} + (row.branch.selected ? ' active':'')\" class=\"tree-grid-row\">\n" +
        "       <td><a ng-click=\"user_clicks_branch(row.branch)\"><i ng-class=\"row.tree_icon\"\n" +
        "              ng-click=\"row.branch.expanded = !row.branch.expanded\"\n" +
        "              class=\"indented tree-icon\"></i></a><span ng-if=\"expandingProperty.cellTemplate\" class=\"indented tree-label\" " +
        "              ng-click=\"on_user_click(row.branch)\" compile=\"expandingProperty.cellTemplate\"></span>" +
        "              <span  ng-if=\"!expandingProperty.cellTemplate\" class=\"indented tree-label\" ng-click=\"on_user_click(row.branch)\">\n" +
        "             {{row.branch[expandingProperty.field] || row.branch[expandingProperty]}}</span>\n" +
        "       </td>\n" +
        "       <td class=\"{{col.colClass}}\" ng-repeat=\"col in colDefinitions\">\n" +
        "         <div ng-if=\"col.cellTemplate\" compile=\"col.cellTemplate\" cell-template-scope=\"col.cellTemplateScope\"></div>\n" +
        "         <div ng-if=\"!col.cellTemplate\">{{row.branch[col.field]}}</div>\n" +
        "       </td>\n" +
        "     </tr>\n" +
        "   </tbody>\n" +
        " </table>\n" +
        "</div>\n" +
        "");

    $templateCache.put('chat_statuses.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
            '<ul class="list-group">' +
                '<li class="list-group-item" ng-repeat="status in chat_status">' +
                    '<label><input type="radio" name="status_id" ng-model="chat.status_id" ng-value="status.id">' +
                        '&nbsp;<span style="background-color:{{status.color}}" class="label pull-right">{{status.name}}</span>'+
                    '</label>' +
                '</li>' +
            '</ul>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('transfer_chat.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '   <form>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
                        '<table ng-table="tableParams" show-filter="true" class="table table-condensed table-striped table-pointer table-hover"> ' +
                        '   <tr ng-click="confirm(row)" ng-repeat="row in $data">' +
                        '       <td style="width:15px; text-align:center" sortable="\'online\'">' +
                        '           <i class="fa fa-circle" ng-class="{\'text-success\': row.online, \'text-danger\': row.online != true}"></i>' +
                        '       </td>' +
                        '       <td class="col-md-2" title="\'CPF\'" filter="{\'cpf\': \'text\'}" sortable="\'cpf\'">{{row.cpf | brCpf}}</td>' +
                        '       <td title="\'Nome\'" filter="{\'name\': \'text\'}">{{row.name}}</td>' +
                        '       <td class="col-md-3" title="\'Departamento\'">{{row.department}}</td>' +
                        '       <td class="col-md-1 text-center" title="\'Status\'" filter="{\'status\':\'select\'}" filter-data="status">{{row.status}}</td>' +
                        '   </tr>' +
                        '</table>' +
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>'
    );

    $templateCache.put('financial_installment.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '   <form>' +
        '       <div class="row" ng-if="completeEntries">' +
        '           <div class="col-md-12 form-group">' +
                        '<div class="alert alert-info text-center" role="alert">' +
                            '<a href="" ng-click="skip()"><i class="fa fa-exclamation-triangle"/> Clique aqui para confirmar sem gerar financeiro</a>'+
                        '</div>'+
        '           </div>' +
        '           <div class="col-md-12 form-group">' +
        '               <div class="col-md-4">'+
                            '<label>Conta</label>'+
                            '<select class="form-control" ng-model="entry.financial_account_id">'+
                                '<option ng-value="account.id" ng-repeat="account in accounts">{{account.name}}</option>'+
                            '</select>'+
                        '</div>'+
        '               <div class="col-md-4 form-group">'+
        '                   <label>Descrição</label>'+
        '                   <input type="text" class="form-control text-right" ng-model="entry.description">'+
        '               </div>'+
                        '<div class="col-md-4">'+
                            '<label>Categoria</label>'+
                            '<angucomplete-alt id="inputCategory"'+
                            '  pause="10"'+
                            '  selected-object="selectEditCategory"'+
                            '  selected-object-data="row"'+
                            '  local-data="categories"'+
                            '  initial-value="entry.category"'+
                            '  search-fields="name"'+
                            '  title-field="name"'+
                            '  override-suggestions="false"'+
                            '  field-required="true"'+
                            '  minlength="1"'+
                            '  auto-match="false"'+
                            '  template-url="select_template.html"'+
                            '  input-class="form-control"/>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <div class="col-md-4 form-group">'+
        '                   <label>Valor Total</label>'+
        '                   <input type="text" class="form-control text-right" ng-model="entry.value" ui-money-mask ng-change="getInstallments()" ng-disabled="true">'+
        '               </div>'+
        '               <div class="col-md-4 form-group">'+
        '                   <label>Nro de Parcelas</label>'+
        '                   <input type="text" class="form-control text-right" ng-model="entry.installments.installments" ng-change="getInstallments()" required>'+
        '               </div>'+
        '               <div class="col-md-4 form-group">'+
        '                   <label>Frequencia</label>'+
        '                   <select class="form-control" ng-model="entry.installments.frequence" ng-change="getInstallments()">'+
        '                       <option value="week">Semanal</option>'+
        '                       <option value="biweek">Quinzenal</option>'+
        '                       <option value="month" selected="selected">Mensal</option>'+
        '                       <option value="bimonth">Bimestral</option>'+
        '                       <option value="quarter">Trimestral</option>'+
        '                       <option value="half">Semestral</option>'+
        '                       <option value="year">Anual</option>'+
        '                   </select>'+
        '               </div>'+
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '   <table class="table">' +
        '       <tbody>' +
        '           <tr ng-repeat="row in entry.installments.data track by $index">' +
        '               <th>' +
        '                   #{{row.installment}}' +
        '               </th>' +
        '               <td>' +
        '                   <div class="input-group">' +
        '                       <input type="text" class="form-control"' +
        '                           uib-datepicker-popup="dd/MM/yyyy"' +
        '                           ng-model="row.transacted_at"' +
        '                           is-open="transacted[row.installment].opened"' +
        '                           close-text="Fechar"' +
        '                           current-text="Hoje"' +
        '                           clear-text="Limpar"' +
        '                           ng-focus="openDate(transacted[row.installment])"' +
        '                           alt-input-formats="altInputFormats"' +
        '                       />' +
        '                       <span class="input-group-btn">' +
        '                           <button type="button" class="btn btn-default" ng-click="openDate(transacted[row.installment])">' +
        '                               <i class="fa fa-calendar"></i>' +
        '                           </button>' +
        '                       </span>' +
        '                   </div>' +
        '               </td>' +
        '               <td>' +
        '                   <input type="text" class="form-control text-right" ng-model="row.value" ui-money-mask required>'+
        '               </td>' +
        '           </tr>' +
        '       </tbody>' +
        '   </table>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="entry.installments.data.length < 1 || (completeEntries && !entry.category)" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('financial_recurrent.modal.html',
        '<form name="recurrent">' +
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body">' +
            '   <div class="row">' +
            '       <div class="col-md-4 form-group">'+
            '           <label>Valor</label>'+
            '           <input type="text" class="form-control text-right" ng-model="entry.value" ui-money-mask disabled required>'+
            '       </div>'+
            '       <div class="col-md-4 form-group">'+
            '           <label>Recorrencia</label>'+
            '           <select class="form-control" ng-model="entry.recurrent_days" ng-options="option.day as option.name for option in financial_recurrents" />'+
            '       </div>'+
            '       <div class="col-md-4 form-group">'+
            '           <label>Numero da parcela (opcional)</label>'+
            '           <input type="text" class="form-control text-right" ng-model="entry.recurrent_number" required>'+
            '       </div>'+
            '   </div>'+
            '   <div class="row">' +
            '       <div class="col-md-6 form-group">'+
            '           <label>Dia de preferência</label>'+
            '           <input type="number" class="form-control" ng-model="entry.recurrent_favorite_day" min="1" max="31" required />'+
            '       </div>'+
            '       <div class="col-md-6 form-group">'+
            '           <label>Data final (opcional)</label>'+
            '           <div class="input-group">' +
            '               <input type="text" class="form-control"' +
            '                   uib-datepicker-popup="dd/MM/yyyy"' +
            '                   ng-model="entry.recurrent_ends"' +
            '                   is-open="recurrent_ends.opened"' +
            '                   close-text="Fechar"' +
            '                   current-text="Hoje"' +
            '                   clear-text="Limpar"' +
            '                   ng-focus="openDate(recurrent_ends)"' +
            '                   alt-input-formats="altInputFormats"' +
            '               />' +
            '               <span class="input-group-btn">' +
            '                   <button type="button" class="btn btn-default" ng-click="openDate(recurrent_ends)">' +
            '                       <i class="fa fa-calendar" ng-click="openDate(recurrent_ends)"></i>' +
            '                   </button>' +
            '               </span>' +
            '           </div>' +
            '       </div>' +
            '   </div>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
            '</div>' +
        '</form>'
    );

    $templateCache.put('accounts.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body financial">' +
        '   <form>' +
        '       <div class="row">' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Descrição</label>'+
        '               <input type="text" ng-model="account.name" class="form-control" />' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Valor Inicial</label>'+
        '               <input type="text" ng-model="account.initial_value" class="form-control" ui-money-mask/>' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-4 form-group">' +
        '               <label>Tipo</label>'+
        '               <select ng-model="account.type" class="form-control" >' +
        '                   <option value="CC">Conta Corrente</opstion>' +
        '                   <option value="CP">Conta Poupança</option>' +
        '               </select>' +
        '           </div>' +
        '           <div class="col-md-4 form-group">' +
        '               <label>Agência</label>'+
        '               <input type="text" ng-model="account.bank_agency" class="form-control" />' +
        '           </div>' +
        '           <div class="col-md-4 form-group">' +
        '               <label>Conta</label>'+
        '               <input type="text" ng-model="account.bank_number" class="form-control" />' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-4 form-group">' +
        '               <label>&nbsp;</label>'+
        '               <label class="checkbox-inline"><input ng-model="account.favorite" type="checkbox" value="">Conta principal</label>' +
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('categories.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body financial">' +
        '   <form>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <input type="text" ng-model="category.name" class="form-control" placeholder="Nome"/>' +
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="category.name == \'\'" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('financial_categories.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '   <table class="table"> '+
        '       <tr ng-repeat="row in categories" ng-click="selectCategory(row)">'+
        '           <td><input type="checkbox" ng-value="categoriesSelecteds[row.id]" ng-checked="categoriesSelecteds[row.id] != undefined"></td>'+
        '           <td>{{row.name}}</td>'+
        '       </tr>'+
        '   </table>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="category.name == \'\'" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('contacts.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body financial">' +
        '   <form>' +
        '       <div class="row">' +
        '           <div class="col-md-8 col-md-offset-2 form-group"> ' +
        '               <button ng-if="!newContact" type="button" ng-click="addNewContact()" class="btn btn-primary btn-block btn-entry">Adicionar</button>' +
        '           </div>' +
        '       </div>' +
        '       <div class="row" ng-if="newContact">' +
        '           <div class="col-md-6 form-group">' +
        '               <input type="text" ng-model="contact.name" class="form-control" placeholder="Nome"/>' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <input type="text" ng-model="contact.cpf_cnpj" class="form-control" placeholder="CPF ou CPNJ"/>' +
        '           </div>' +
        '           <div class="col-md-4 form-group">' +
        '               <button type="button" class="btn btn-primary" ng-disabled="contact.name == \'\' || contatc.cpf_cnpj == \'\'" ng-click="addContact()">Adicionar</button>' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <ul class="list-group">' +
        '                   <li ng-repeat="data in contacts" class="list-group-item">{{data.name}} - {{data.cpf_cnpj}}</li>' +
        '               </ul>' +
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('send_individual.modal.html',
        '<div class="modal-body">' +
        '<form name="send_form">'+

            '<table ng-table="tableParams" show-filter="true" class="table table-condensed table-striped table-pointer table-hover">'+
                '<tr ng-repeat="row in $data track by row.id">'+
                    '<td class="col-md-1"><input type="checkbox" ng-checked="_.contains(send_to,row.id)" ng-click="toggleSelection(row.id)" /></td>'+
                    '<td class="col-md-1" title="\'Cod.\'"  filter="{external_code: \'text\'}" sortable="\'external_code\'">{{row.external_code}}</td>'+
                    '<td class="col-md-2" title="\'CPF/CNPJ\'" filter="{cpf_cnpj: \'text\'}" sortable="\'cpf_cnpj\'">{{row.cpf_cnpj | brCpfCnpj}}</td>'+
                    '<td title="\'Nome\'" filter="{name: \'text\'}">{{row.name | limitTo:50}}</td>'+
                    '<td class="col-md-1 text-center" title="\'Status\'" filter="{status:\'select\'}" filter-data="status">{{row.status}}</td>'+
                '</tr>'+
            '</table>'+

        '</div>' +
        '</form>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="send_to.length <= 0">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('delete_entry.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body" ng-if="exclusionOptions">' +
                '<p class="text-center">Você deseja {{action}} os demais itens?</p>'+
                '<hr>'+

                '<div class="radio">'+
                    '<label>'+
                        '<input type="radio" name="optionsRadios" id="optionsRadios1" value="one" checked ng-model="exclusion.type">'+
                        '&nbsp;<b>Não</b>, {{action}} somente este'+
                    '</label>'+
                '</div>'+

                // '<div class="radio">'+
                //     '<label>'+
                //         '<input type="radio" name="optionsRadios" id="optionsRadios1" value="future" checked ng-model="exclusion.type">'+
                //         '&nbsp;<b>Sim</b>, {{action}} os próximos'+
                //     '</label>'+
                // '</div>'+

                '<div class="radio">'+
                    '<label>'+
                        '<input type="radio" name="optionsRadios" id="optionsRadios1" value="all" checked ng-model="exclusion.type">'+
                        '&nbsp;<b>Sim</b>, todas não pagas'+
                    '</label>'+
                '</div>'+

            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-{{action == \'excluir\' ? \'danger\' : \'success\'}}" type="button" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('entries.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<table class="table table-responsive">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left" style="width: 10%">Data</th>'+
            '            <th class="text-left" style="width: 22%">Descrição</th>'+
            '            <th class="text-left" style="width: 15%">{{(type == \'D\' ? \'Pago a\' : \'Recebido de\')}}</th>'+
            '            <th class="text-left" style="width: 10%">Valor</th>'+
            '            <th class="text-left" style="width: 15%">Categoria</th>'+
            '            <th class="text-left" style="width: 5%">Pagamento</th>'+
            '            <th class="text-left" style="width: 5%">Pago</th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="entry in entries" ng-if="!entry.paid">'+
            '            <td class="text-left" style="width: 10%">{{entry.transacted_at | getDate | date :\'dd/MM\'}}</td>'+
            '            <td class="text-left truncate" style="width: 22%">&nbsp;{{entry.description }}</td>'+
            '            <td class="text-left truncate" style="width: 15%">{{(entry.contact.name ? entry.contact.name : entry.employee.name) }}</td>'+
            '            <td class="text-left" style="width: 10%" ng-class="{\'text-success\': type == \'C\', \'text-danger\': type == \'D\'}"><strong>{{entry.value|finance:true}}</strong></td>'+
            '            <td class="text-left" style="width: 15%">{{entry.category.name}}</td>'+
            '            <td class="text-left" style="width: 5%">{{entry.payment == \'installments\' ? \'Parcelado\' : \'À vista\'}}</td>'+
            '            <td class="text-left" style="width: 5%">'+
            '                <toggle-switch ng-model="entry.paid" ng-init="entry.paid" on-label="" off-label="" ng-change="updateStatusPaid(entry, entry.paid);att()"><toggle-switch>'+
            '            </td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
            '<div class="modal-footer">' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('confirm_paid.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<div class="row">' +
                    '<div class="col-md-12">'+
                        '<label>Data de pagamento</label>'+
                        '<div class="input">'+
                            '<input type="text" class="form-control"'+
                                'uib-datepicker-popup="dd/MM/yyyy"'+
                                    'ng-model="pay.paid_at"'+
                                'is-open="paidAt.opened"'+
                                'close-text="Fechar"'+
                                'current-text="Hoje"'+
                                'clear-text="Limpar"'+
                                'ng-focus="openDate(paidAt)"'+
                                'alt-input-formats="altInputFormats"'+
                            '/>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label>Conta</label>'+
                        '<select class="form-control" ng-model="pay.financial_account_id">'+
                            '<option ng-value="account.id" ng-repeat="account in accounts">{{account.name}}</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">Forma de pagamento</label>'+
                        '<select class="form-control" ng-model="payment_method">'+
                            '<option ng-value="payment.id" ng-repeat="payment in payments">{{payment.title}}</option>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
            '</div>'+

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('show_entry.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<form name="form_entry">'+
                    '<div class="row">'+
                        '<div class="col-md-2">'+
                            '<label>N:</label>'+
                            '<input type="text" name="id" class="form-control" ng-model="edit_entry.id" ng-disabled="true"/>'+
                        '</div>'+

                        '<div class="col-md-2">'+
                            '<label>Venc. Orignal</label>'+
                            '<div class="input">'+
                                '<input type="text" class="form-control"'+
                                    'uib-datepicker-popup="dd/MM/yyyy"'+
                                    'ng-model="edit_entry.transacted_at"'+
                                    'is-open="transacted.opened"'+
                                    'close-text="Fechar"'+
                                    'current-text="Hoje"'+
                                    'clear-text="Limpar"'+
                                    'ng-disabled="true"'+
                                    'ng-focus="openDate(transacted)"'+
                                    'alt-input-formats="altInputFormats"'+
                                '/>'+
                            '</div>'+
                        '</div>'+

                        '<div class="col-md-2">'+
                            '<label>Pagamento</label>'+
                            '<div class="input">'+
                                '<input type="text" class="form-control"'+
                                    'uib-datepicker-popup="dd/MM/yyyy"'+
                                    'ng-model="edit_entry.paid_at"'+
                                    'is-open="paid.opened"'+
                                    'close-text="Fechar"'+
                                    'current-text="Hoje"'+
                                    'clear-text="Limpar"'+
                                    'ng-disabled="edit_entry.transaction.id"'+
                                    'ng-focus="openDate(paid)"'+
                                    'alt-input-formats="altInputFormats"'+
                                '/>'+
                            '</div>'+
                        '</div>'+

                        '<div class="col-md-6">'+
                            '<label>Descrição</label>'+
                            '<input type="text" name="description" class="form-control" ng-model="edit_entry.description" placeholder="Descrição" required />'+
                        '</div>'+
                    '</div>'+

                    '<div class="row">'+
                        '<div class="col-md-4">'+
                            '<label>{{ edit_entry.type.type == \'D\' ? \'Pago a\' : \'Recebido de\' }}</label>'+
                            '<angucomplete-alt id="inputEditContact"'+
                            '  pause="10"'+
                            '  selected-object="selectEditContact"'+
                            '  selected-object-data="row"'+
                            '  local-data="contacts"'+
                            '  initial-value="edit_entry.contact"'+
                            '  search-fields="data.name"'+
                            '  title-field="data.name"'+
                            '  description-field="type.display_name"'+
                            '  override-suggestions="false"'+
                            '  field-required="true"'+
                            '  minlength="2"'+
                            '  auto-match="false"'+
                            '  template-url="select_template.html"'+
                            '  input-class="form-control"/>'+
                        '</div>'+

                        '<div class="col-md-4">'+
                            '<label>Categoria</label>'+
                            '<angucomplete-alt id="inputCategory"'+
                            '  pause="10"'+
                            '  selected-object="selectEditCategory"'+
                            '  selected-object-data="row"'+
                            '  local-data="categories"'+
                            '  initial-value="edit_entry.category"'+
                            '  search-fields="name"'+
                            '  title-field="name"'+
                            '  override-suggestions="false"'+
                            '  field-required="true"'+
                            '  minlength="1"'+
                            '  auto-match="false"'+
                            '  template-url="select_template.html"'+
                            '  input-class="form-control"/>'+
                        '</div>'+

                        '<div class="col-md-2">'+
                            '<label>Valor</label>'+
                            '<input type="text" name="value" class="form-control text-right" ng-model="edit_entry.value" ui-number-mask placeholder="Valor" required>'+
                        '</div>'+

                        '<div class="col-md-2">'+
                            '<label>Valor Pago</label>'+
                            '<input type="text" name="total" class="form-control text-right" ng-model="edit_entry.total" ui-number-mask placeholder="Total" required>'+
                        '</div>'+
                    '</div>'+

                    '<div class="row">'+
                        '<hr>'+
                        '<div class="col-md-4">'+
                            '<label>Conta</label>'+
                            '<select class="form-control" ng-model="edit_entry.financial_account_id" required>'+
                                '<option ng-repeat="account in accounts" ng-value="account.id">{{account.name}}</option>'+
                            '</select>'+
                        '</div>'+
                        '<div class="col-md-4" ng-if="edit_entry.payment == \'recurrent\'">'+
                            '<label>Dia de vencimento recorrencia</label>'+
                            '<select class="form-control" ng-model="edit_entry.recurrent_favorite_day" required>'+
                                '<option ng-repeat="day in daysOfMonth" value="{{day}}">{{day}}</option>'+
                            '</select>'+
                        '</div>'+
                        '<div class="col-md-4 ng-if="edit_entry.payment == \'recurrent\'">'+
                            '<label>Data final (opcional) <input type="checkbox" ng-value="1" ng-model="send_ends" /></label>'+
                            '<div class="input-group">' +
                                '<input type="text" class="form-control"' +
                                '   uib-datepicker-popup="dd/MM/yyyy"' +
                                '   ng-model="edit_entry.recurrent_ends"' +
                                '   is-open="recurrent_ends.opened"' +
                                '   close-text="Fechar"' +
                                '   current-text="Hoje"' +
                                '   clear-text="Limpar"' +
                                '   ng-disabled="!send_ends"' +
                                '   ng-focus="openDate(recurrent_ends)"' +
                                '   alt-input-formats="altInputFormats"' +
                                '/>' +
                                '<span class="input-group-btn">' +
                                    '<button type="button" class="btn btn-default" ng-click="openDate(recurrent_ends)">' +
                                        '<i class="fa fa-calendar" ng-click="openDate(recurrent_ends)"></i>' +
                                    '</button>' +
                                '</span>' +
                            '</div>' +
                        '</div>' +
                    '</div>'+
                    '<div class="row" ng-if="edit_entry.type.type == \'C\'">'+
                        '<div class="col-md-4">'+
                            '<label>Enviar Boleto Automático?</label>'+
                            '<select class="form-control" ng-model="edit_entry.send_boleto" >'+
                                '<option ng-value="0">Não</option>'+
                                '<option ng-value="1">Sim</option>'+
                            '</select>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        ' <br/>'+
                    '</div>'+
                    '<div class="row" ng-show="!showReferences">'+
                        '<div class="col-md-12">'+
                            '<div class="col-md-12">'+
                                '<button class="btn btn-primary" type="button" ng-click="showAllReferences()">Ver todos os lançamentos</button>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                    // '<div class="row" ng-show="showReferences">'+
                    //     '<div class="col-md-12">'+
                    //         '<div class="col-md-12">'+
                    //             '<button class="btn btn-default" type="button" ng-click="print()">Imprimir referências</button>'+
                    //         '</div>'+
                    //     '</div>'+
                    // '</div>'+
                    '<div class="row" ng-show="showReferences">'+
                        '<hr>'+
                        '<div class="col-md-12">'+
                            '<label>Todas referências</label>'+
                            '<table class="table table-responsive">'+
                            '    <thead>'+
                            '        <tr>'+
                            '            <th class="text-left" style="width: 10%">Data</th>'+
                            '            <th class="text-left" style="width: 22%">Descrição</th>'+
                            '            <th class="text-left" style="width: 15%">{{(type == \'D\' ? \'Pago a\' : \'Recebido de\')}}</th>'+
                            '            <th class="text-left" style="width: 10%">Valor</th>'+
                            '            <th class="text-left" style="width: 15%">Categoria</th>'+
                            '            <th class="text-left" style="width: 5%">Pagamento</th>'+
                            '            <th class="text-left" style="width: 5%">Pago</th>'+
                            '        </tr>'+
                            '    </thead>'+
                            '    <tbody>'+
                            '        <tr ng-repeat="entry in entries">'+
                            '            <td class="text-left" style="width: 10%">{{entry.transacted_at | getDate | date :\'dd/MM\'}}</td>'+
                            '            <td class="text-left truncate" style="width: 22%">&nbsp;{{entry.description }}</td>'+
                            '            <td class="text-left truncate" style="width: 15%">{{(entry.contact.name ? entry.contact.name : entry.employee.name) }}</td>'+
                            '            <td class="text-left" style="width: 10%" ng-class="{\'text-success\': type == \'C\', \'text-danger\': type == \'D\'}"><strong>{{entry.value|finance:true}}</strong></td>'+
                            '            <td class="text-left" style="width: 15%">{{entry.category.name}}</td>'+
                            '            <td class="text-left" style="width: 5%">{{entry.payment == \'installments\' ? \'Parcelado\' : \'À vista\'}}</td>'+
                            '            <td class="text-left" style="width: 5%">'+
                            '                <toggle-switch ng-model="entry.paid" ng-init="entry.paid" on-label="" off-label="" ng-change="updateStatusPaid(entry, entry.paid)"><toggle-switch>'+
                            '            </td>'+
                            '        </tr>'+
                            '    </tbody>'+
                            '</table>'+
                        '</div>'+
                    '</div>'+
                    '<div class="row" ng-if="edit_entry.protocol">'+
                        '<hr>'+
                        '<div class="col-md-8">'+
                            '<label>Ordem de serviço n˚ {{edit_entry.protocol.number}}</label>'+
                            '<table class="table table-responsive">'+
                                '<thead>'+
                                    '<tr>'+
                                        '<th>N˚</th>'+
                                        '<th>Serviço</th>'+
                                        '<th class="text-center">Valor unitário</th>'+
                                        '<th>QTD</th>'+
                                        '<th class="text-center">Desconto</th>'+
                                        '<th class="text-center">Valor total</th>'+
                                    '</tr>'+
                                '</thead>'+
                                '<tbody>'+
                                    '<tr ng-repeat="service in edit_entry.protocol.services">'+
                                        '<td class="col-md-1 text-center">{{$index+1}}</td>'+
                                        '<td class="col-md-4">{{service.name}}</td>'+
                                        '<td class="col-md-2 text-right">{{service.value | finance:true}}</td>'+
                                        '<td class="col-md-1 text-center">{{service.quantity}}</td>'+
                                        '<td class="col-md-2 text-right">{{service.discount | finance:true}}</td>'+
                                        '<td class="col-md-2 text-right">{{service.total | finance:true}}</td>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</div>'+
                        '<div class="col-md-4">'+
                            '<table class="table">'+
                                '<thead>'+
                                    '<tr>'+
                                        '<td>Valor da OS</td>'+
                                        '<td class="text-right">{{edit_entry.protocol.value |finance:true }}</td>'+
                                    '</tr>'+
                                '</thead>'+
                                '<tbody>'+
                                    '<tr>'+
                                        '<td>Desconto</td>'+
                                        '<td class="text-right">'+
                                            '<span ng-if="edit_entry.protocol.discount > 0">{{edit_entry.protocol.discount |finance:true }}</span>'+
                                        '</td>'+
                                    '</tr>'+
                                    '<tr>'+
                                        '<td>Total</td>'+
                                        '<th class="text-right">{{edit_entry.protocol.total |finance:true }}</th>'+
                                    '</tr>'+
                                '</tbody>'+
                            '</table>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        ' <br/>'+
                    '</div>'+
                '</form>'+
                '<div class="row">'+
                    '<hr>'+
                    '<div class="col-md-12 ">'+
                        '<label>Histórico</label>'+
                        '<div>'+
                            '<div class="form-group">'+
                                '<label>Adicionar comentário</label>'+
                                '<div class="input-group">'+
                                    '<input type="text" class="form-control" ng-model="comment" placeholder="Escreva aqui seu comentário">'+
                                    '<span class="input-group-btn">'+
                                        '<button class="btn btn-primary" type="button" ng-click="sendComment()">Enviar</button>'+
                                    '</span>'+
                                '</div>'+
                            '</div>'+
                            '<div ng-repeat="comment in comments" class="panel panel-default">'+
                                '<div class="panel-body">'+
                                    '<p>{{comment.description}}</p>'+
                                '</div>'+
                                '<div class="panel-footer">'+
                                    '<small class="text-right">{{comment.user.name}} - {{comment.created_at | getDate | date:\'dd/MM/yyyy hh:mm\'}}</small>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>'+

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('add_user.modal.html',
        '<form name="form_add_user" autocomplete="off" ng-submit="form_add_user.$valid && confirm()">'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="$ctrl.cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title">Adicionar usuário</h4>'+
            '</div>'+

            '<div class="modal-body">'+
                '<div class="row">'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Nome <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="data.name" name="name" required/>'+
                    '</div>'+

                    '<div class="col-md-6 form-group" show-errors>'+
                        '<label class="control-label">E-mail <span class="text-danger">*</span></label>'+
                        '<input type="email" class="form-control" ng-model="data.email" name="email" required/>'+
                        '<span ng-show="(form_add_user.email.$touched && form_add_user.email.$invalid)" class="help-block ng-hide"><small>Email já cadastrado</small></span>'+
                    '</div>'+
                '</div>'+

                '<div class="row">'+
                    '<div class="col-md-3 form-group" show-errors>'+
                        '<label class="control-label">CPF <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="data.cpf" name="cpf" ui-br-cpf-mask required/>'+
                        '<span ng-show="(form_add_user.cpf.$touched && form_add_user.cpf.$invalid)" class="help-block ng-hide"><small>CPF já cadastrado</small></span>'+
                    '</div>'+

                    '<div class="col-md-3 form-group">'+
                        '<label>Telefone <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="data.phone_number" name="phone_number" ui-br-phone-number-mask required/>'+
                    '</div>'+

                    '<div class="col-md-3 form-group" show-errors>'+
                        '<label>Senha <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="data.password" name="password" ng-minlength="6" ng-required="passRequired"/>'+
                        '<span ng-show="(form_add_user.password.$touched && form_add_user.password.$invalid)" class="help-block ng-hide"><small>Senha muito curta</small></span>'+
                    '</div>'+

                    '<div class="col-md-3 form-group">'+
                        '<label>Permissão<span class="text-danger">*</span></label>'+
                        '<select class="form-control" ng-if="roles" ng-model="client.permission" name="role" required>'+
                            '<option ng-repeat="row in roles" value="{{row.id}}">{{row.display_name}}</option>'+
                        '</select>'+
                    '</div>'+

                '</div>'+
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="submit">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('enrollment.modal.html',
        '<div class="modal-body">' +
          '<form name="form_enrollment">'+
              '<div class="row">'+
                  '<div class="form-group" ng-class="changeEnrollments ? \'col-md-12\' : \'col-md-8\' ">'+
                      '<label>Cliente</label>'+
                      '<div ng-dropdown-multiselect options="clients" selected-model="student.client"'+
                      'extra-settings="selectConfig" translation-texts="template_client" disabled="changeEnrollments"></div>'+
                  '</div>'+
                  '<div class="col-md-4 form-group" ng-show="!changeEnrollments">'+
                      '<label>&nbsp;</label>'+
                      '<button class="btn btn-default btn-block" ng-click="addClient()">Cadastrar Aluno</button>'+
                  '</div>'+
              '</div>'+
              '<div class="row">'+
                '<div class="col-md-12 form-group">'+
                    '<label ng-show="!changeEnrollments">Turma<span class="text-danger">*</span></label>'+
                    '<label ng-show="changeEnrollments">Nova Turma<span class="text-danger">*</span></label>'+
                    '<select class="form-control" '+
                    '   ng-options="class_group.id as class_group.name for class_group in class_groups"'+
                    '   ng-model="enrollment.class_group_id" '+
                    '   ng-change="scheduleClass(enrollment.class_group_id)" >'+
                    '</select>'+
                '</div>'+
              '</div>'+
              '<div class="row" ng-show="changeEnrollments">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Contrato Atual<span class="text-danger">*</span></label>'+
                    '<input type="text" class="form-control" ng-model="enrollment.type.name" ng-disabled="true"/>'+
                '</div>'+
              '</div>'+
              '<div class="row" ng-show="!changeEnrollments">'+
                '<div class="col-md-8 form-group">'+
                  '<label>Contrato<span class="text-danger">*</span></label>'+
                  ' <select class="form-control" '+
                  '   ng-options="client_contract.id as client_contract.type.name for client_contract in client_contracts"'+
                  '   ng-model="enrollment.client_contract_id"'+
                  '   ng-disabled="contract.status == \'closed\'"></select>'+
                '</div>'+
                '<div class="col-md-4 form-group">'+
                    '<label>&nbsp;</label>'+
                    '<button class="btn btn-default btn-block" ng-click="openContract(null)">Novo Contrato</button>'+
                '</div>'+
              '</div>'+
          '</form>'+
        '</div>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="(protocol_type == \'client\' && !client.id) || opening" ng-click="confirm()" >' +
        '       <span ng-if="!opening">{{success_text}}</span>'+
        '       <span ng-if="opening">Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('newAnamnesisType.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Anamnese</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_contract">'+

                '<div class="row">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Modelo<span class="text-danger">*</span></label>'+
                        '<select class="form-control" '+
                        '   ng-options="docType as docType.name for docType in types"'+
                        '   ng-model="document.type"'+
                        '   ng-change="selectType(document.type)"'+
                        '   ></select>'+
                    '</div>'+
                '</div>'+

                '<div class="row" ng-repeat="row in document.type.questions_list track by $index">'+
                    '<div ng-if="row.value_type != \'session\'">'+
                        '<div ng-class="row.value_type == \'yesnoText\' ? \'col-md-6\' : \'col-md-12\'" class="form-group">'+
                            '<label>{{row.value}}<span class="text-danger">*</span></label>'+
                            '<select class="form-control" ng-model="document.answers[$index].answer" ng-if="row.value_type == \'yesno\' || row.value_type == \'yesnoText\'">'+
                                '<option value="">Selecione</option>'+
                                '<option value="yes">Sim</option>'+
                                '<option value="no">Não</option>'+
                            '</select>'+
                            '<input type="text" class="form-control" ng-model="document.answers[$index].answer" ng-if="row.value_type == \'text\'" />'+
                        '</div>'+
                        '<div ng-show="row.value_type == \'yesnoText\'" class="col-md-6 form-group">'+
                            '<label>Especifique<span class="text-danger">*</span></label>'+
                            '<input type="text" class="form-control" ng-model="document.answers[$index].answerDetail"/>'+
                        '</div>'+
                    '</div>'+
                    '<div ng-if="row.value_type == \'session\'">'+
                        '<div class="col-md-12 form-group">'+
                            '<h4>{{row.value}}</h4>'+
                        '</div>'+   
                    '</div>'+
                '</div>'+

            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_contract.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('newDocumentType.modal.html',
        '<div ng-show="waiting">'+
            '<div class="backgroundLoader"></div>'+
            '<div class="loader"></div>'+
        '</div>'+
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Documento</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_contract">'+

                '<div class="row">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Modelo<span class="text-danger">*</span></label>'+
                        '<select class="form-control" '+
                        '   ng-options="docType as docType.name for docType in types"'+
                        '   ng-model="document.type"'+
                        '   ></select>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-4 form-group" ng-if="checkTags(\'docsStartAt\')">'+
                        '<label>Inicio<span class="text-danger">*</span></label>'+
                        '<input type="date" class="form-control" ng-model="document.docsStartAt" />'+
                    '</div>'+
                    '<div class="col-md-2 form-group" ng-if="checkTags(\'docsStartAt\')">'+
                        '<label>&nbsp;<span class="text-danger">*</span></label>'+
                        '<input type="time" class="form-control" ng-model="document.docsStartAt" />'+
                    '</div>'+
                    '<div class="col-md-4 form-group" ng-if="checkTags(\'docEndAt\')">'+
                        '<label>Fim<span class="text-danger">*</span></label>'+
                        '<input type="date" class="form-control" ng-model="document.docEndAt" />'+
                    '</div>'+
                    '<div class="col-md-2 form-group" ng-if="checkTags(\'docEndAt\')">'+
                        '<label>&nbsp;<span class="text-danger">*</span></label>'+
                        '<input type="time" class="form-control" ng-model="document.docEndAt" />'+
                    '</div>'+
                    '<div class="col-md-2 form-group" ng-if="checkTags(\'docDismissal\')">'+
                        '<label>Dias de duração<span class="text-danger">*</span></label>'+
                        '<input type="number" class="form-control" ng-model="document.docDismissal"/>'+
                    '</div>'+
                    '<div class="col-md-12 form-group" ng-if="checkTags(\'CID\')">'+
                        '<label>CID<span class="text-danger">*</span></label>'+
                        '<select class="form-control" '+
                        '   ng-options="desc.id as desc.description+\' \'+desc.value for desc in cidSuggestionsList"'+
                        '   ng-model="document.cid"'+
                        '   ></select>'+
                    '</div>'+
                    '<div class="col-md-12 form-group" ng-if="checkTags(\'docEscort\')">'+
                        '<label>Acompanhante<span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="document.docEscort"/>'+
                    '</div>'+
                '</div>'+
                '<div class="row" ng-if="checkTags(\'posologia\')">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Medicamento e posologia<span class="text-danger">*</span></label>'+ 
                        '<table class="table">'+
                            '<tr ng-if="!addingDosage">'+
                                '<td colspan="2">'+
                                    '<button type="button" ng-click="addDosage()" class="btn btn-success btn-sm btn-block btn-add-entry">'+
                                        'Adicionar' +
                                    '</button>'+
                                '</td>'+
                            '</tr>'+
                            '<tr ng-if="addingDosage">'+
                                '<td>'+
                                    '<select class="form-control" ng-model="newDosage">'+
                                    '   <option value="">Selecione</option>'+
                                    '   <option value="{{row.id}}" ng-repeat="row in dosgeList">{{row.description}} - {{row.value}}</option>'+
                                    '</select>'+
                                '</td>'+
                                '<td>'+
                                    '<button type="button" ng-click="saveNewDosage(newDosage)" class="btn btn-success btn-sm btn-block btn-add-entry">'+
                                        'Salvar' +
                                    '</button>'+
                                '</td>'+
                            '</tr>'+
                            '<tr ng-repeat="row in document.dosageList">'+
                                '<td>{{row.description}}</td>'+
                                '<td>{{row.value}}</td>'+
                                '<td></td>'+
                            '</tr>'+
                        '</table>'+
                    '</div>'+
                '</div>'+
            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_contract.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('contract.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Contrato</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_contract">'+

                '<div class="row">'+
                    '<div class="col-md-6 form-group">'+
                        '<label>Tipo<span class="text-danger">*</span></label>'+
                        '<select class="form-control" '+
                        '   ng-options="type.id as type.name for type in types"'+
                        '   ng-model="contract.type_id"'+
                        '   ng-change="selectedType()"'+
                        '   ng-disabled="contract.status == \'closed\'"></select>'+
                    '</div>'+
                    '<div class="col-md-2 form-group">'+
                        '<label>Inicio<span class="text-danger">*</span></label>'+
                        '<div class="input">'+
                            '<input type="text" class="form-control"'+
                                'uib-datepicker-popup="dd/MM/yyyy"'+
                                'ng-model="contract.start_at"'+
                                'is-open="datePicker.start_at.opened"'+
                                'close-text="Fechar"'+
                                'current-text="Hoje"'+
                                'clear-text="Limpar"'+
                                'ng-change="selectedDateStart()"'+
                                'ng-focus="openDate(datePicker.start_at)"'+
                                'alt-input-formats="altInputFormats"'+
                                'ng-disabled="contract.status == \'closed\'"'+
                            '/>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-2 form-group">'+
                        '<label>Fim<span class="text-danger">*</span></label>'+
                        '<div class="input">'+
                            '<input type="text" class="form-control"'+
                                'uib-datepicker-popup="dd/MM/yyyy"'+
                                'ng-model="contract.deadline"'+
                                'is-open="datePicker.deadline.opened"'+
                                'close-text="Fechar"'+
                                'current-text="Hoje"'+
                                'clear-text="Limpar"'+
                                'ng-focus="openDate(datePicker.deadline)"'+
                                'alt-input-formats="altInputFormats"'+
                                'ng-disabled="contract.status == \'closed\'"'+
                            '/>'+
                        '</div>'+
                    '</div>'+

                    '<div class="col-md-2 form-group" >'+
                        '<label>Dia de vencimento <span class="text-danger">*</span></label>'+
                        '<select class="form-control" ng-options="day for day in daysOfMonth" ng-model="contract.favorite_day" ng-disabled="contract.status == \'closed\'" required></select>'+
                    '</div>'+
                '</div>'+

                '<div class="row">'+
                    '<div class="col-md-4 form-group">'+
                        '<label>Valor do contrato <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control" ng-model="contract.total" ui-money-mask ng-disabled="contract.status == \'closed\' || !changeValue"/>'+
                    '</div>'+
                    // '<div class="col-md-4 form-group" ng-if="vigency == \'fixed\'">'+
                    //     '<label>Pagamento <span class="text-danger">*</span></label>'+
                    //
                    // '</div>'+
                '</div>'+

            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_contract.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('financial_contract.modal.html',
        '<form name="form_contract">' +
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <div class="col-md-2 form-group">'+
        '                   <label>Valor Contrato</label>'+
        '                   <input type="text" class="form-control text-right" ng-model="contract.total" ui-money-mask ng-change="getInstallments()" ng-disabled="true">'+
        '               </div>'+
        '               <div class="col-md-2 form-group">'+
        '                   <label>Pagamento por</label>'+
        '                   <select class="form-control" ng-model="installment_status" ng-change="getInstallments()">'+
        '                       <option value="frequence">Frequencia</option>'+
        '                       <option value="installment">Parcelado</option>'+
        '                   </select>'+
        '               </div>'+
        '               <div class="col-md-2 form-group" ng-show="installment_status == \'frequence\'">'+
        '                   <label>Frequencia</label>'+
        '                   <select class="form-control" ng-model="frequence" ng-change="getInstallments()">'+
        '                       <option value="one">Unico</option>'+
        // '                       <option value="week">Semanal</option>'+
        // '                       <option value="biweek">Quinzenal</option>'+
        '                       <option value="months">Mensal</option>'+
        '                       <option value="quarters">Trimestral</option>'+
        // '                       <option value="half">Semestral</option>'+
        '                       <option value="years">Anual</option>'+
        '                   </select>'+
        '               </div>'+
        '               <div class="col-md-2 form-group" ng-show="installment_status == \'installment\'">'+
        '                   <label>Parcelas</label>'+
        // '                   <input type="text" class="form-control text-right" ng-model="contract.installment"'+
        '                   <select class="form-control" ng-model="contract.installment" ng-change="getInstallments()">'+
        '                       <option ng-repeat="installment in installments" ng-value="installment">{{installment}}</option>'+
        '                   </select>'+
        '               </div>'+
        '               <div class="col-md-3">'+
        '                   <label>Conta</label>'+
        '                   <select class="form-control" ng-model="financial_account_id" required>'+
        '                       <option ng-repeat="account in accounts" ng-value="account.id">{{account.name}}</option>'+
        '                   </select>'+
        '               </div>'+
        '               <div class="col-md-3">'+
        '                   <label>Categoria</label>'+
                            '<angucomplete-alt id="inputCategory"'+
                            '  pause="10"'+
                            '  selected-object="selectEditCategory"'+
                            '  selected-object-data="row"'+
                            '  local-data="categories"'+
                            '  initial-value="category"'+
                            '  search-fields="name"'+
                            '  title-field="name"'+
                            '  override-suggestions="false"'+
                            '  field-required="true"'+
                            '  minlength="1"'+
                            '  auto-match="false"'+
                            '  template-url="select_template.html"'+
                            '  input-class="form-control" required />'+
        '               </div>'+
        '           </div>' +
        '       </div>' +
        '   <table class="table">' +
        '       <tbody>' +
        '           <tr ng-repeat="row in financials track by $index">' +
        '               <th>' +
        '                   #{{row.count}}' +
        '               </th>' +
        '               <td>' +
        '                   <div class="input-group">' +
        '                       <input type="text" class="form-control"' +
        '                           uib-datepicker-popup="dd/MM/yyyy"' +
        '                           ng-model="row.transacted_at"' +
        '                           is-open="transacted[row.count].opened"' +
        '                           close-text="Fechar"' +
        '                           current-text="Hoje"' +
        '                           clear-text="Limpar"' +
        '                           ng-focus="openDate(transacted[row.count])"' +
        '                           alt-input-formats="altInputFormats"' +
        '                       required />' +
        '                       <span class="input-group-btn">' +
        '                           <button type="button" class="btn btn-default" ng-click="openDate(transacted[row.count])">' +
        '                               <i class="fa fa-calendar"></i>' +
        '                           </button>' +
        '                       </span>' +
        '                   </div>' +
        '               </td>' +
        '               <td>' +
        '                   <input type="text" class="form-control" ng-model="row.description" required />'+
        '               </td>' +
        '               <td>' +
        '                   <input type="text" class="form-control text-right" ng-model="row.value" ui-money-mask ng-disabled="true" required>'+
        '               </td>' +
        '           </tr>' +
        '       </tbody>' +
        '   </table>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="form_contract.$invalid" ng-click="confirm()">{{success_text}}</button>' +
        '</div>' +

        '</form>'
    );

    $templateCache.put('confirm_contract.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">{{title_text}}</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<div class="row">'+
                '<div class="col-md-12" ng-bind-html="contract.type.clauses">'+
                '</div>'+
            '</div>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form_contract.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('showDoc.modal.html',
        '<div ng-show="waiting">'+
            '<div class="backgroundLoader"></div>'+
            '<div class="loader"></div>'+
        '</div>'+
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
            '<button class="btn btn-primary" type="button" ng-click="editDocument()">Editar</button>' +
        '</div>'+

        '<div class="modal-body" ng-if="!editing">'+
            '<div class="row">'+
                '<div class="col-md-12" ng-bind-html="document.document">'+
                '</div>'+
            '</div>'+
        '</div>' +

        '<div class="modal-body" ng-if="editing">'+
            '<div class="row" style="padding: 10px;">'+
                '<div class="form-control" id="doc" ckeditor="options" ng-model="document.document" ready="onReady()" required></div>'+
            '</div>'+
        '</div>'+

        '<div class="modal-footer" ng-if="!editing">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="print()">Imprimir</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">Fechar</button>' +
        '</div>'+

        '<div class="modal-footer" ng-if="editing">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Cancelar</button>' +
        '    <button class="btn btn-success" type="button" ng-click="save()">Salvar</button>' +
        '</div>'
    );

    $templateCache.put('showAnamnesis.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">{{title_text}}</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<div class="row" ng-repeat="row in answers_list track by $index">'+
                        '<div ng-if="row.value_type != \'session\'">'+
                            '<div ng-class="row.value_type == \'yesnoText\' ? \'col-md-6\' : \'col-md-12\'" class="form-group">'+
                                '<label>{{row.value}}<span class="text-danger">*</span></label>'+
                                '<select class="form-control" ng-model="row.answer" ng-if="row.value_type == \'yesno\' || row.value_type == \'yesnoText\'" disabled="true">'+
                                    '<option value="">Selecione</option>'+
                                    '<option value="yes">Sim</option>'+
                                    '<option value="no">Não</option>'+
                                '</select>'+
                                '<input type="text" class="form-control" ng-model="row.answer" ng-if="row.value_type == \'text\'" disabled="true" />'+
                            '</div>'+
                            '<div ng-show="row.value_type == \'yesnoText\'" class="col-md-6 form-group">'+
                                '<label>Especifique<span class="text-danger">*</span></label>'+
                                '<input type="text" class="form-control" ng-model="row.answerDetail" disabled="true"/>'+
                            '</div>'+
                        '</div>'+
                        '<div ng-if="row.value_type == \'session\'">'+
                            '<div class="col-md-12 form-group">'+
                                '<h4>{{row.value}}</h4>'+
                            '</div>'+   
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="print()">Imprimir</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">Fechar</button>' +
        '</div>'
    );

    $templateCache.put('select_template.html',
        '<div class="angucomplete-holder" ng-class="{\'angucomplete-dropdown-visible\': showDropdown}">'+
            '<input ng-model="searchStr"'+
            '   ng-disabled="disableInput"'+
            '   type="text"'+
            '   placeholder="{{placeholder}}"'+
            '   ng-focus="onFocusHandler()"'+
            '   class="{{inputClass}}"'+
            '   ng-focus="resetHideResults()"'+
            '   ng-blur="hideResults($event)"'+
            '   autocapitalize="off"'+
            '   autocorrect="off"'+
            '   autocomplete="off"'+
            '   ng-required="fieldRequired"'+
            '   name="{{inputName}}"'+
            '   tabindex="{{fieldTabindex}}"'+
            '   ng-change="inputChangeHandler(searchStr)" enter/>'+
            '<div class="angucomplete-dropdown" ng-show="showDropdown">'+
                '<div class="angucomplete-searching" ng-show="searching" ng-bind="textSearching"></div>'+
                '<div class="angucomplete-searching" ng-show="!searching && (!results || results.length == 0)" ng-bind="textNoResults"></div>'+
                '<div class="augucomplete-list">'+
                    '<div class="angucomplete-row" ng-repeat="result in results" ng-click="selectResult(result)" ng-mouseenter="hoverRow($index)" ng-class="{\'angucomplete-selected-row\': $index == currentIndex}">'+
                        '<div ng-if="imageField" class="angucomplete-image-holder">'+
                            '<img ng-if="result.image && result.image != \'\'" ng-src="{{result.image}}" class="angucomplete-image"/>'+
                            '<div ng-if="!result.image && result.image != \'\'" class="angucomplete-image-default"></div>'+
                            '</div>'+
                        '<div ng-if="matchClass && result.description && result.description != \'\'" class="angucomplete-description" ng-bind-html="result.description"></div>'+
                        '<div ng-if="!matchClass && result.description && result.description != \'\'" class="angucomplete-description">{{result.description}}</div>'+
                        '<div class="angucomplete-title truncate" ng-if="matchClass" ng-bind-html="result.title"></div>'+
                        '<div class="angucomplete-title truncate" ng-if="!matchClass">{{ result.title }}</div>'+
                    '</div>'+
                '</div>'+
                '<div class="angucomplete-row btn-create-wrap" ng-click="selectResult({title: searchStr, originalObject: { name: searchStr, custom: true }})" ng-mouseenter="hoverRow(results.length)" ng-class="{\'angucomplete-selected-row\': results.length == currentIndex}">'+
                    '<div class="angucomplete-title btn btn-success">Adicionar: \'{{ searchStr }}\'</div>'+
                '</div>'+
            '</div>'+
        '</div>'
    );

    $templateCache.put('forgot.modal.html',
        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +
        '<div class="modal-body">' +
            '<div class="row" ng-if="!requested">' +
                '<form name="form_step" ng-submit="request()">'+
                    '<div class="col-md-10">'+
                        '<label>Digite seu email cadastrado: </label>' +
                        '<input type="text" class="form-control" ng-model="user.email" required />' +
                    '</div>'+
                    '<div class="col-md-2">'+
                        '<label>&nbsp;</label><br>' +
                        '<button class="btn btn-success" type="button" ng-disabled="form_step.$invalid" ng-click="request()">{{success_text}}</button>' +
                    '</div>'+
                '</form>'+
            '</div>' +
            '<div class="row" ng-if="requested">' +
                '<form name="form_step" ng-submit="reset()">'+
                    '<div class="col-md-12">'+
                        '<div class="alert alert-info text-center" role="alert">'+
                            'Enviamos um email com o codigo de confirmação, verifique sua caixa de entrada!'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label>Código: </label>' +
                        '<input type="text" class="form-control" ng-model="user.code" required />' +
                    '</div>'+
                    '<div class="col-md-6">'+
                        '<label>Senha: </label>' +
                        '<input type="password" class="form-control" ng-model="user.password" required />' +
                    '</div>'+
                    '<div class="col-md-6">'+
                        '<label>Repetir senha: </label>' +
                        '<input type="password" class="form-control" ng-model="user.confirm" required />' +
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label>&nbsp;</label><br>' +
                        '<button class="btn btn-success" type="button" ng-disabled="form_step.$invalid || user.password != user.confirm" ng-click="reset()">{{success_text}}</button>' +
                    '</div>'+
                '</form>'+
            '</div>' +
        '</div>'
    );

    $templateCache.put('confirm_email.modal.html',
        '<form>'+
            '<div class="modal-header text-center">' +
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h1 class="title">{{title_text}}</h1>'+
            '</div>' +

            '<div class="modal-body">' +
                '<div class="row">'+
                    '<div class="col-md-12 form-group">'+
                        '<input type="email" class="form-control" ng-model="email" required enter />'+
                    '</div>'+
                '</div>'+
            '</div>'+

            '</div>' +
            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn" ng-class="company.company_type_id == 3 ? \'button-duda\' : \'btn-success\'" type="button" ng-disabled="!email || waiting" ng-click="confirm()" promise-btn>{{success_text}}</button>' +
            '</div>' +
        '</form>'
    );

    $templateCache.put('export_invoices.modal.html',
        '<div class="modal-body">' +
        '<div ng-bind-html="title"></div>' +

        '<div class="row">'+
            '<div class="col-md-6 form-group">'+
                '<label>Mês</label>'+
                '<select class="form-control" ng-model="exports.month">'+
                    '<option ng-repeat="month in months" ng-value="month.id">{{month.description}}</option>'+
                '</select>'+
            '</div>'+
            '<div class="col-md-6 form-group">'+
                '<label>Ano</label>'+
                '<select class="form-control" ng-model="exports.year">'+
                    '<option ng-repeat="year in years" ng-value="year">{{year}}</option>'+
                '</select>'+
            '</div>'+
        '</div>'+

        '<div class="row">'+
            '<div class="col-md-12 form-group">'+
                '<label>E-mail</label>'+
                '<input name="email" type="email" ng-model="exports.email" class="form-control" required />'+
            '</div>'+
        '</div>'+

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="exports.email == \'\'" ng-click="confirm()" promise-btn>{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('checkout_success.modal.html',
        '<div class="modal-body text-center">' +
            '<img ng-if="loadingCheckout" src="img/another_loading.gif" style="width:70px" alt="" />'+
            '<div ng-if="loadingCheckout">'+
                '<h4>Aguarde..</h4>'+
            '</div>'+
            '<div ng-if="checkoutStatus == \'paid\'">'+
                '<h4>Pagamento efetuado..</h4>'+
            '</div>'+

            '<div ng-if="checkoutStatus != \'unpaid\' || checkoutStatus != \'waiting_payment\'">'+
                '<h4>Boleto gerado</h4>'+
                '<br/>'+
                '<a target="_blank" href="{{checkoutResp.boleto_url}}" class="btn btn-lg btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
            '</div>'+
        '</div>' +
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-success" type="button" ng-disabled="loadingCheckout" ng-click="confirm()">'+
        '       <span ng-if="loadingCheckout">Aguarde</span>'+
        '       <span ng-if="!loadingCheckout">OK</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('gerar_boleto.modal.html',

        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 ng-if="!boletoCreated">{{title}}</h4>'+
        '</div>' +

        '<div class="modal-body text-center">' +
            '<div ng-if="!tryagain">'+
                '<img ng-if="creatingBoleto" src="img/another_loading.gif" style="width:70px" alt="" />'+
                '<div ng-if="boleto.url">'+
                    '<a target="_blank" href="{{boleto.url}}" class="btn btn-lg btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
                '</div>'+
            '</div>'+
        '</div>'+

        '<div class="modal-footer" ng-if="!boletoCreated && !creatingBoleto">'+
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Não</button>'+
        '   <button class="btn btn-success" type="button" ng-disabled="creatingBoleto" ng-click="confirm()">Sim</button>'+
        '</div>'+

        '<div class="modal-footer" ng-if="tryagain">'+
        '   <button class="btn btn-success" type="button" ng-click="confirm()">Tentar novamente</button>'+
        '</div>'+

        '<div class="modal-footer" ng-if="boletoCreated">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Fechar</button>' +
        '   </button>' +
        '</div>'
    );

    $templateCache.put('view_charge.modal.html',

        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4>Cobrança</h4>'+
        '</div>' +

        '<div class="modal-body text-center">' +

            '<div ng-if="transaction.late">'+
                '<div class="alert alert-danger text-center">Atenção boleto vencido</div>'+
                '<a ng-click="makeSecondCharge(entry)" class="btn btn-lg btn-block btn-default"><i class="fas fa-file-download"></i> Emitir 2ª via</a><br>'+
            '</div>'+

            '<div ng-if="transaction.boleto_url && !transaction.late">'+
                '<a target="_blank" href="{{transaction.boleto_url}}" class="btn btn-lg btn-block btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
            '</div>'+
            '<div class="row" ng-if="!transaction.late">'+
                '<div class="col-md-12">'+
                    '<hr>'+
                    '<label>Enviar por email</label>'+
                '</div>'+
                '<div class="col-md-12 from-group">'+
                    '<input type="email" class="form-control" ng-model="email" required />'+
                '</div>'+
                '<div class="col-md-12 from-group">'+
                    '&nbsp;'+
                '</div>'+
                '<div class="col-md-12">'+
                    '<button class="btn btn-block btn-success" type="button" ng-disabled="!email || sending" ng-click="sendEmail(email)" promise-btn>'+
                        '<span ng-if="!sending">Enviar por email</span>'+
                        '<span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Enviando</span>'+
                    '</button>' +
                '</div>'+
            '</div>'+
        '</div>'
    );

    $templateCache.put('view_payments.modal.html',
        '<div ng-show="waiting">'+
            '<div class="backgroundLoader"></div>'+
            '<div class="loader"></div>'+
        '</div>'+
        '<div class="modal-header text-center">' +
            '<div class="col-md-12">' +
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '</div>' +
            '<div class="col-md-12">'+
                '<div class="col-md-4">' +
                    '<h4>{{title}}</h4>' +
                    '<div class="col-md-12 ng-if="sale.status">' +
                        '<span class="label label-default"'+
                        'ng-class="{'+
                            '\'label-warning\': sale.status == \'opened\','+
                            '\'label-success\': (sale.status == \'succeeded\' && sale.status_formated != \'Posterior\'),'+
                            '\'label-danger\' : sale.status == \'canceled\','+
                            '\'label-primary\': (sale.status == \'succeeded\' && sale.status_formated == \'Posterior\')'+
                        '}"'+
                    '>{{sale.status_formated}}</span>'+
                    '</div>' +
                '</div>' +
                '<div class="col-md-4">' +
                    '<h4>{{sub_title}}</h4>'+
                '</div>' +
                '<div class="col-md-4">' +
                    '<h4>{{sub_title_2}}</h4>'+
                '</div>' +
            '</div>' +  
        '</div>' +
        '<div class="modal-body text-center">' +
            '<div class="row">'+
                '<div class="col-md-2">'+
                    '<label>Cod. Venda</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.hashid" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Hora Venda</label>'+
                    '<input type="text" name="hour" class="form-control" ng-model="hour" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Cliente</label>'+
                    '<input type="text" name="buyer" class="form-control trucante" ng-model="sale.buyer.name" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Desconto</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.discount" disabled="true" ui-money-mask />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Frete</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.shipping_value" disabled="true" ui-money-mask />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Total</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.amount" disabled="true" ui-money-mask />'+
                '</div>'+
            '</div>' +
            '<div class="row">'+
                '<div class="col-md-6">'+
                    '<label>Data de criação</label>'+
                    '<p class="text-center form-control" disabled="true">{{sale.created_at | getDate | date:\'dd/MM/yy hh:mm:ss\'}}</p>'+
                '</div>'+
                '<div class="col-md-6">'+
                    '<label>Data de fechamento</label>'+
                    '<p class="text-center form-control" disabled="true">{{sale.closed_at | getDate | date:\'dd/MM/yy hh:mm:ss\'}}</p>'+
                '</div>'+
            '</div>'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label>Pagamentos</label>'+
                    '<div class="col-md-12" ng-repeat="payment in sale.payments track by $index" ng-switch on="payment.payment_method">'+

                        '<div ng-switch-when="money">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Dinheiro | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pix">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Pix | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="credit_card">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Cartão de crédito | {{payment.amount | currency:\'R$\'}} Em {{payment.charges ? payment.charges : 1}} X'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Maquina de cartão | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos_debit">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Maquina de cartão (Débito) | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos_credit">'+
                            '<p>{{payment.created_at | getDate | date:\'dd/MM/yy\'}} | Maquina de cartão (Crédito) | {{payment.amount | currency:\'R$\'}} Em {{payment.charges ? payment.charges : 1}} X'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="boleto">'+
                            '<p>Boleto #{{$index + 1}} | {{payment.amount | currency:\'R$\'}} | {{payment.boleto_expiration_date | getDate | date :\'dd/MM/yy\'}}'+
                            '<a target="_blank" href="{{payment.boleto_url}}" class="btn btn-lg btn-block btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="link">'+
                            '<p>Link de pagamento</p>'+
                            '<div class="col-md-10">' +
                                '<input type="text" class="form-control" value="{{server + \'/checkout/\' + sale.hashid}}" id="linkSale" readonly="true" ng-click="copy()"/>' +
                            '</div>' +
                            '<div class="col-md-2">' +
                                '<i class="far fa-copy" ng-click="copy()"></i>' +
                            '</div>' +
                        '</div>'+
                        '<div ng-switch-when="others">'+
                            '<p>{{payment.payment_description}} | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="col-md-12">'+
                    '<label>Itens</label>'+
                    '<table class="table">'+
                        '<tr>'+
                            '<th ng-show="showDevolution">Selecionar</th>'+
                            '<th>Descrição</th>'+
                            '<th>Valor</th>'+
                            '<th>Quantidade</th>'+
                            '<th>Desconto</th>'+
                            '<th>Devolução</th>'+
                            '<th>Frete</th>'+
                            '<th>Total</th>'+
                            '<th></th>'+
                        '</tr>'+
                        '<tr class="text-left" ng-repeat="item in sale.items track by item.id">'+
                            '<td style="width:35px" class="text-center" ng-show="showDevolution" ng-click="goDevolution(item)">'+
                            '    <span class="label label-success" ng-class="{'+
                            '        \'label-primary\': rowSelected == false'+
                            '       }" style="font-size: 12px;" uib-tooltip="Adicionar item para devolução!" tooltip-append-to-body="true">+'+
                            '   </span>'+
                            '</td>'+
                            '<td>{{item.name}}</td>'+
                            '<td>{{item.value | currency:"R$"}}</td>'+
                            '<td>{{item.quantity | number:"4"}}</td>'+
                            '<td>{{item.discount | currency:"R$"}}</td>'+
                            '<td>{{item.reduction_value | currency:"R$"}}</td>'+
                            '<td>{{item.shipping_value | currency:"R$"}}</td>'+
                            '<td>{{item.total | currency:"R$"}}</td>'+
                            '<td></td>'+
                        '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>'+
           '<div class="col-md-3 background-list"  ng-show="showDevolution && devolution.items.length > 0">'+
           '    <a class="pull-right" ng-click="clearList()" style="cursor: pointer;">x</a>'+
           '    <h5>Adicionado(s)</h5>'+
           '    <ul>'+
           '        <li ng-repeat="row in devolution.items track by row.id">'+
           '            <i class="fas fa-check" style="color:#57A300;"></i>'+
           '            <span>{{row.name}}</span><br/>'+
           '            <span>Total: {{row.value | currency:\'R$\'}}</span>'+
           '            <span>QTD: {{row.quantity ? row.quantity : \'0.000\'}}</span>'+
           '           <input type="text" class="form-control col-md-2" ng-model="row.quantity" ng-show="qtdMod" />'+
           '        </li>'+
           '    </ul>'+
           '    <button class="btn btn-primary btn-sm text-center" style="margin-bottom: 8px;" ng-click="modifyQuantity()" ng-show="!qtdMod"><i class="fas fa-check-circle"></i> Mudar quantidade</button>'+
           '    <button class="btn btn-primary btn-sm text-center" style="margin-bottom: 8px;" ng-click="applyChange()" ng-show="qtdMod"><i class="fas fa-check-circle"></i> Aplicar</button>'+
           '    <button class="btn btn-success btn-sm text-center" style="margin-bottom: 8px;" ng-click="confirmDevolution()" ng-show="!qtdMod"><i class="fas fa-check-circle"></i> Confirmar devolução</button>'+
           '</div>'+
           '<div class="col-md-3 background-list" ng-show="!showDevolution && (sale.sale_item_devolutions.length > 0 || sale.service_devolutions.length > 0)" style="right: -190px">'+
           '    <h5>Item(s) Devolução</h5>'+
           '    <ul ng-show="sale.sale_item_devolutions.lenght > 0">'+
           '        <li ng-repeat="row in sale.sale_item_devolutions">'+
           '            <i class="fas fa-check" style="color:#57A300;"></i>'+
           '            <span>{{row.name}}</span><br/>'+
           '            <span>Total: {{row.value | currency:\'R$\'}}</span>'+
           '            <span ng-show="row.quantity">QTD: {{row.quantity}}</span>'+
           '        </li>'+
           '    </ul>'+
           '    <ul ng-show="sale.service_devolutions.length > 0">'+
           '        <li ng-repeat="row in sale.service_devolutions">'+
            '        <div ng-repeat="item in row.services">'+
                '            <i class="fas fa-check" style="color:#57A300;"></i>'+
                '            <span>{{item.name}}</span><br/>'+
                '            <span>Total: {{item.value | currency:\'R$\'}}</span>'+
                '            <span ng-show="item.quantity">QTD: {{item.quantity}}</span>'+
            '        </div>'+
           '        </li>'+
           '    </ul>'+
           '</div>'+
        '</div>'
    );

    $templateCache.put('view_shipping.modal.html',

        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4>Venda</h4>'+
        '</div>' +

        '<div class="modal-body text-center">' +
            '<div class="row">'+
                '<div class="col-md-2">'+
                    '<label>Cod. Venda</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.hashid" disabled="true" />'+
                '</div>'+
                '<div class="col-md-4">'+
                    '<label>Cliente</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.name" disabled="true" />'+
                '</div>'+
                '<div class="col-md-4">'+
                    '<label>Email</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.email" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Telefone</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.phone_completed" disabled="true" />'+
                '</div>'+
                '<div class="col-md-6">'+
                    '<label>Endereço de entrega</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.address[0].line1" disabled="true" />'+
                '</div>'+
                '<div class="col-md-6">'+
                    '<label>Complemento</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.address[0].line2" disabled="true" />'+
                '</div>'+
                '<div class="col-md-4">'+
                    '<label>Cidade</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.address[0].city_name" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Estado</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.address[0].state.name" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Cep</label>'+
                    '<input type="text" name="buyer" class="form-control" ng-model="sale.buyer.address[0].zip_code" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Desconto</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.discount" disabled="true" ui-money-mask />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Total</label>'+
                    '<input type="text" name="hashid" class="form-control" ng-model="sale.amount" disabled="true" ui-money-mask />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Código do Envio</label>'+
                    '<input type="text" name="shipping_code" class="form-control" ng-model="sale.shipping_code" disabled="true" />'+
                '</div>'+
                '<div class="col-md-2">'+
                    '<label>Local da Venda</label>'+
                    '<input type="text" name="from" class="form-control" ng-model="sale.from_formated" disabled="true" />'+
                '</div>'+
            '</div>' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label>Pagamentos</label>'+
                    '<div class="col-md-12" ng-repeat="payment in sale.payments track by $index" ng-switch on="payment.payment_method">'+

                        '<div ng-switch-when="money">'+
                            '<p>Dinheiro | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="credit_card">'+
                            '<p>Cartão de crédito | {{payment.amount | currency:\'R$\'}} Em {{payment.charges ? payment.charges : 1}} X'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos">'+
                            '<p>Maquina de cartão | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos_debit">'+
                            '<p>Maquina de cartão (Débito) | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="pos_credit">'+
                            '<p>Maquina de cartão (Crédito) | {{payment.amount | currency:\'R$\'}} Em {{payment.charges ? payment.charges : 1}} X'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="boleto">'+
                            '<p>Boleto #{{$index + 1}} | {{payment.amount | currency:\'R$\'}} | {{payment.boleto_expiration_date | getDate | date :\'dd/MM/yy\'}}'+
                            '<a target="_blank" href="{{payment.boleto_url}}" class="btn btn-lg btn-block btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
                            '<hr>'+
                        '</div>'+
                        '<div ng-switch-when="link">'+
                            '<p>Link de pagamento</p>'+
                            '<div class="col-md-10">' +
                                '<input type="text" class="form-control" value="{{server + \'/checkout/\' + sale.hashid}}" id="linkSale" readonly="true" ng-click="copy()"/>' +
                            '</div>' +
                            '<div class="col-md-2">' +
                                '<i class="far fa-copy" ng-click="copy()"></i>' +
                            '</div>' +
                        '</div>'+
                        '<div ng-switch-when="others">'+
                            '<p>{{payment.payment_description}} | {{payment.amount | currency:\'R$\'}}'+
                            '<hr>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="col-md-12">'+
                    '<label>Itens</label>'+
                    '<table class="table">'+
                        '<tr>'+
                            '<th>Descrição</th>'+
                            '<th>Valor</th>'+
                            '<th>Quantidade</th>'+
                            '<th>Desconto</th>'+
                            '<th>Frete</th>'+
                            '<th>Total</th>'+
                            '<th></th>'+
                        '</tr>'+
                        '<tr class="text-left" ng-repeat="item in sale.items">'+
                            '<td>{{item.name}}</td>'+
                            '<td>{{item.value | currency:"R$"}}</td>'+
                            '<td>{{item.quantity}}</td>'+
                            '<td>{{item.discount | currency:"R$"}}</td>'+
                            '<td>{{item.shipping_value | currency:"R$"}}</td>'+
                            '<td>{{item.total | currency:"R$"}}</td>'+
                            '<td></td>'+
                        '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>'+
        '</div>'
    );

    $templateCache.put('edit_shipping.modal.html',
        '<div class="modal-header">' +

        '<div class="modal-body">' +
        '<div class="col-md-12 form-group">' +
        '    <label class="control-label">Status da Entrega</label>' +
        '    <select class="form-control" ng-model="shipping.shipping_status">' +
        '        <option value="">Todos</option>' +
        '        <option value="waiting">Aguardando Envio</option>' +
        '        <option value="posted">Postado</option>' +
        '        <option value="delivering">Enviado</option>' +
        '        <option value="delivered">Entregue</option>' +
        '    </select>' +
        '</div>' +
        '</div>' +
        '       <label class="control-label" style="margin-left: 33px" ng-model="shipping.shipping_status" ng-if="shipping.shipping_status == \'posted\'">Cod. da Postagem</label>' +
        '    <div class="col-md-12" ng-model="shipping.shipping_status" ng-if="shipping.shipping_status == \'posted\'"> ' +
        '       <input class="col-md-6" style="margin-left: 18px" type="text" ng-model="shipping.shipping_code"></input>'+
        '    </div>' +

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button"  ng-click="confirm()" promise-btn>{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('select_data.modal.html',
        '<div class="modal-header text-center">' +
            '<h4>{{title}}</h4>'+
        '</div>' +
        '<div class="modal-body">' +
            '<table class="table table-condensed table-striped table-pointer table-hover">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left">{{title_name}}</th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="row in data" >'+
            '            <td class="text-left" style="width: 10%" ng-model="row.name" ng-value="row.id"'+
            '            ng-click="select(row.id)"><input type="checkbox" ng-if="dentalParams" style="margin-right: 3px;" checked/>{{row.name}}<span ng-if="row.value">R$: {{row.value}}<span/></td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
            '<div ng-if="daysList.length > 0" class="col-md-3 background-list">' +
                '<h5>Adicionado(s)</h5>'+
                '<ul>'+
                    '<li ng-repeat="item in daysList">'+
                        '<i class="fas fa-check" style="color:#57A300;"></i>     |    '+
                        '<span>{{item.name}} <i class="fas fa-times pull-right" ng-click="remove(item)"></i></span>'+
                    '</li>'+
                '</ul>'+
            '</div>' +
        '</div>' +
        '<div class="modal-footer" style="padding: 15px;">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="confirm()">{{success_text}}</button>'+
        '</div>'
    );

    $templateCache.put('viewFinancialAndEdit.modal.html',
        '<div class="modal-header text-center">' +
        '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4>{{title}}</h4>'+
        '</div>' +
        '<div class="modal-body">' +
        '<a ng-click="back()" ng-if="change || exclude" style="cursor: pointer; "><i class="fas fa-undo"></i>Voltar</a><br/>'+
            '<div class="btn-group" ng-if="!values">' +
            // '    <button class="btn btn-default" type="button"  ng-click="changeValue(true)" promise-btn uib-tooltip="Clique para selecionar entradas da categoria a serem excluídas" tooltip-append-to-body="true">Excluir entradas do financeiro</button>' +
            '    <button class="btn btn-default" type="button"  ng-click="changeValue(false)" promise-btn uib-tooltip="Clique para selecionar entradas a serem modificadas" tooltip-append-to-body="true">Mudar categoria das entradas do financeiro</button>' +
            '</div>'+
            '<div class="col-md-12 text-center" ng-if="change">'+
                '<div class="col-md-4">'+
                    '<label class="label-control" style="margin-left: 10px;">Mudar categoria</label>'+
                    '<select class="form-control" ng-model="id.item.id">'+
                        '<option ng-repeat="item in allCategories track by item.id" ng-value="item.id">{{item.name}}</option>'+
                    '</select>'+

                '</div>'+
                '<div class="col-md-4" style="margin-top:23px; margin-left: -20px;">'+
                    '<button class="btn btn-md btn-success" type="button" ng-click="updateFinancialEntry()">Confirmar mudança de categoria</button>' +
                '</div>'+
            '</div>'+
            '<div class="col-md-12" ng-if="change">'+
                '<h3 class="text-center">Selecione as categorias</h3>' +
            '</div>'+
            '<div class="col-md-6 btn-group" user="group" ng-if="change">'+
                '<button class="btn btn-default" type="button" ng-click="selectAllEntrys()">Selecionar todas</button>' +
                '<button class="btn btn-primary" type="button" ng-click="unselectAllEntrys()">Desmarcar todas</button>' +
            '</div>'+
            '<div ng-if="exclude">' +
                '<label class="label-control">Selecione as entradas que serão excluidas</label><br/>'+
            '    <button class="btn btn-danger" type="button"  ng-click="removeFinancialEntry()" promise-btn>Excluir entradas do financeiro</button>' +
            '</div>'+
            '<table class="table table-condensed table-striped table-pointer table-hover">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left" ng-if="change || exclude"></th>'+
            '            <th class="text-left">Descrição</th>'+
            '            <th>Pago á</th>'+
            '            <th>Categoria</th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="row in categorie.financial_entry">'+
            '            <td ng-if="change || exclude"><input type="checkbox" ng-value="selectedEntrys[row.id]" ng-checked="selectedEntrys[row.id] == row.id || selectAll" ng-click="selectEntry(row.id)" ng-disabled="selectAll"></td>'+
            '            <td class="text-left">{{row.description}}</td>'+
            '            <td class="text-left">{{row.contact.data.name}}</td>'+
            '            <td class="text-left">{{categorie.name}}</td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
        '</div>' +
        '<div class="modal-footer" style="padding: 15px;">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>'+
        '</div>'
    );

    $templateCache.put('selectDate.modal.html',

        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4>{{title}}</h4>'+
        '</div>' +

        '<div class="modal-body">' +

            '<div class="form-group">'+
                '<label class="label-control">Vencimento</label>'+
                '<input type="date" name="selectDate" id="selectDate" class="form-control" ng-model="selected.date" required >'+
            '</div>'+
        '</div>'+

        '<div class="modal-footer" ng-if="!boletoCreated && !creatingBoleto">'+
        '   <button class="btn btn-success" type="button" ng-disabled="creatingBoleto" ng-click="confirm()">Confirmar</button>'+
        '</div>'
    );

    $templateCache.put('2via_do_boleto.modal.html',

        '<div class="modal-header text-center">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 ng-if="!boletoCreated">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated">'+
                '<label class="label-control">Vencimento</label>'+
                '<input type="text" class="form-control ng-change="recalcTotal()" cant-cancel" uib-datepicker-popup="dd/MM/yyyy" datepicker-options="dateOptions" ng-model="charges.move_to" is-open="entry_transacted_at.opened" close-text="Fechar" current-text="Hoje" clear-text="Limpar" ng-focus="openDate(entry_transacted_at)" alt-input-formats="altInputFormats" tabindex="0" ng-required="true" name="transacted_at"/>'+
            '</div>'+
            //Boleto com juros por valor R$
            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated && charges.boleto_penalty_type ==\'V\'">'+
                '<label class="label-control">Multa</label>'+
                '<input type="text" class="form-control cant-cancel" ng-model="charges.boleto_penalty" tabindex="2" ng-change="recalcTotal()" ng-required="true" ui-money-mask name="boleto_penalty"/>'+
            '</div>'+

            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated && charges.boleto_penalty_type ==\'V\' ">'+
                '<label class="label-control">Juros</label>'+
                '<input type="text" class="form-control cant-cancel" ng-model="charges.boleto_interest" tabindex="1" ng-change="recalcTotal()" ng-required="true" ui-money-mask name="boleto_interest"/>'+
            '</div>'+
            //
            //Boleto com juros por porcentagem %
            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated && charges.boleto_penalty_type ==\'P\'">'+
                '<label class="label-control">Multa</label>'+
                '<input type="text" class="form-control cant-cancel" ng-model="charges.boleto_penalty" tabindex="2" ng-change="recalcTotal()" ng-required="true" ui-percentage-mask name="boleto_penalty"/>'+
            '</div>'+

            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated && charges.boleto_penalty_type ==\'P\'">'+
                '<label class="label-control">Juros</label>'+
                '<input type="text" class="form-control cant-cancel" ng-model="charges.boleto_interest" tabindex="1" ng-change="recalcTotal()" ng-required="true" ui-percentage-mask name="boleto_interest"/>'+
            '</div>'+
            //
            '<div class="form-group" ng-if="!creatingBoleto && !boletoCreated">'+
                '<label class="label-control">Total</label>'+
                '<input type="text" class="form-control cant-cancel" ng-model="charges.total" ng-disabled="true" ng-required="true" ui-money-mask name="total"/>'+
            '</div>'+

            '<div class="text-center" ng-if="!tryagain">'+
                '<img ng-if="creatingBoleto" src="img/another_loading.gif" style="width:70px" alt="" />'+
                '<div ng-if="boleto.url">'+
                    '<a target="_blank" href="{{boleto.url}}" class="btn btn-lg btn-default"><i class="fas fa-file-download"></i> Clique aqui para baixar o boleto</a>'+
                '</div>'+
            '</div>'+
        '</div>'+

        '<div class="modal-footer" ng-if="!boletoCreated && !creatingBoleto">'+
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Não</button>'+
        '   <button class="btn btn-success" type="button" ng-disabled="creatingBoleto" ng-click="confirm()">Sim</button>'+
        '</div>'+

        '<div class="modal-footer" ng-if="tryagain">'+
        '   <button class="btn btn-success" type="button" ng-click="confirm()">Tentar novamente</button>'+
        '</div>'+

        '<div class="modal-footer" ng-if="boletoCreated">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Fechar</button>' +
        '   </button>' +
        '</div>'
    );

    $templateCache.put('createReceipt.modal.html',
        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Emitir recibo</h4>'+
        '</div>' +

        '<div class="modal-body">' +
            '<div class="form-group" ng-if="types">'+
                '<label class="label-control">Tipo</label>'+
                '<select class="form-control" ng-model="entry.type" ng-options="option.id as option.name for option in types" />'+
            '</div>'+

            '<iframe id="printIframe" name="printIframe" ng-if="canPrint" width="100%" height="400"></iframe>'+

        '</div>'+

        '<div class="modal-footer">'+
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Fechar</button>'+
        '   <button class="btn btn-success" type="button" ng-disabled="!canPrint" ng-click="print()">Imprimir</button>'+
        '</div>'
    );

    $templateCache.put('pay_many.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<div class="row">'+
                    '<div class="col-md-3">'+
                        '<label class="label-control">Data</label>'+
                        '<div class="input">'+
                            '<input type="text" class="form-control"'+
                                'uib-datepicker-popup="dd/MM/yyyy"'+
                                'ng-model="payment.received_at"'+
                                'is-open="transacted.opened"'+
                                'close-text="Fechar"'+
                                'current-text="Hoje"'+
                                'clear-text="Limpar"'+
                                'ng-focus="openDate(transacted)"'+
                                'alt-input-formats="altInputFormats"'+
                            '/>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Descrição</label>'+
                        '<input type="text" class="form-control" ng-model="payment.description" required />'+
                    '</div>'+
                    '<div class="col-md-3">'+
                        '<label class="label-control">Forma de pagamento</label>'+
                        '<select class="form-control" ng-model="payment.payment_method">'+
                            '<option value="money">Dinheiro</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-2">'+
                        '<label class="label-control">Valor</label>'+
                        '<input type="text" class="form-control" ng-model="payment.value" ui-money-mask required ng-disabled="true" />'+
                    '</div>'+
                '</div>'+
                '<div class="row" ng-if="entries.length > 0">'+
                    '<div class="col-md-12">'+
                        '<table class="table table-responsive">'+
                        '    <thead>'+
                        '        <tr>'+
                        '            <th class="text-left" style="width: 10%">Data</th>'+
                        '            <th class="text-left" style="width: 22%">Descrição</th>'+
                        '            <th class="text-left" style="width: 15%">{{(type == \'D\' ? \'Pago a\' : \'Recebido de\')}}</th>'+
                        '            <th class="text-left" style="width: 10%">Valor</th>'+
                        '            <th class="text-left" style="width: 15%">Categoria</th>'+
                        '        </tr>'+
                        '    </thead>'+
                        '    <tbody>'+
                        '        <tr ng-repeat="entry in entries">'+
                        '            <td class="text-left" style="width: 10%">{{entry.transacted_at | getDate | date :\'dd/MM\'}}</td>'+
                        '            <td class="text-left truncate" style="width: 22%">&nbsp;{{entry.description }}</td>'+
                        '            <td class="text-left truncate" style="width: 15%">{{(entry.contact.name ? entry.contact.name : entry.employee.name) }}</td>'+
                        '            <td class="text-left" style="width: 10%" ng-class="{\'text-success\': type == \'C\', \'text-danger\': type == \'D\'}"><strong>{{entry.value|finance:true}}</strong></td>'+
                        '            <td class="text-left" style="width: 15%">{{entry.category.name}}</td>'+
                        '        </tr>'+
                        '    </tbody>'+
                        '</table>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '&nbsp;'+
                    '</div>'+
                '</div>'+
                '<div ng-if="entries.length == 0" class="alert alert-warning text-center" role="alert">Nenhuma entrada em aberto</div>'+
            '</div>'+
            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="entries.length == 0">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('pay_partial.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<div class="row">'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Data</label>'+
                        '<div class="input">'+
                            '<input type="text" class="form-control"'+
                                'uib-datepicker-popup="dd/MM/yyyy"'+
                                'ng-model="payment.received_at"'+
                                'is-open="transacted.opened"'+
                                'close-text="Fechar"'+
                                'current-text="Hoje"'+
                                'clear-text="Limpar"'+
                                'ng-focus="openDate(transacted)"'+
                                'alt-input-formats="altInputFormats"'+
                            '/>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Descrição</label>'+
                        '<input type="text" class="form-control" ng-model="payment.description" required />'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Forma de pagamento</label>'+
                        '<select class="form-control" ng-model="payment.payment_method">'+
                            '<option value="money">Dinheiro</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Valor a pagar</label>'+
                        '<input type="text" class="form-control" ng-model="payment.value" ui-money-mask ng-change="changeValue()" required />'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Valor em aberto</label>'+
                        '<input type="text" class="form-control" ng-model="payment.value_opened" ui-money-mask ng-disabled="true" required />'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label class="label-control">Numero de parcelas</label>'+
                        '<select class="form-control" ng-model="payment.installments" ng-change="changeInstallments()" required>'+
                            '<option ng-repeat="row in arrayInstallments" ng-value="row">{{row}}</option>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
                '<div class="row" ng-if="entries.length > 0">'+
                    '<div class="col-md-12">'+
                        '<table class="table table-responsive">'+
                        '    <thead>'+
                        '        <tr>'+
                        '            <th class="text-left">Data</th>'+
                        '            <th class="text-left">Descrição</th>'+
                        '            <th class="text-left">Valor</th>'+
                        '        </tr>'+
                        '    </thead>'+
                        '    <tbody>'+
                        '        <tr ng-repeat="pEntry in entries">'+
                        '            <td class="text-left" style="width: 10%">'+
                        '               <input type="date" class="form-control" ng-model="pEntry.transacted_at" min="{{minClausule}}" />' +
                        '            </td>'+
                        '            <td class="text-left truncate" style="width: 22%">'+
                        '               <input type="text" class="form-control" ng-model="pEntry.description" />'+
                        '            </td>'+
                        '            <td class="text-left" style="width: 10%" ng-class="{\'text-success\': type == \'C\', \'text-danger\': type == \'D\'}"><strong>{{pEntry.value|finance:true}}</strong></td>'+
                        '        </tr>'+
                        '    </tbody>'+
                        '</table>'+
                    '</div>'+
                '</div>'+
            '</div>'+
            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="payment.value_opened == 0">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('createTransfer.modal.html',
        '<div class="modal-header text-center"><h4 class="modal-title" ng-bind-html="title"></h4></div>'+
        '<div class="modal-body">' +
            '<div ></div><br/>' +
            '<table class="table table-striped table-condensed">' +
                '<tbody>' +
                    '<tr>' +
                        '<td><small>Disponível</small></td>' +
                        '<td class="text-right">{{balance.available | finance:true}}</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td><small>TED</small></td>' +
                        '<td class="text-right">R$ 3,67</td>' +
                    '</tr>' +
                    '<tr class=" text-success">' +
                        '<td>Valor à receber</td>' +
                        '<td class="text-right"><strong>{{  (((balance.available*100)-367)/100)   | finance:true}}</strong></td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</div>' +
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '   <button ng-click="confirm()" ng-disabled="sending" type="button" class="btn btn-success">'+
        '       <span ng-if="!sending"> {{success_text}}</span>'+
        '       <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('showOperations.modal.html',
        '<div class="modal-body">' +
            '<p>Saldo do dia <strong>{{ (op.available.amount.balance-op.available.fee.balance)/100 | finance:true }}</strong></p>'+
            '<table class="table table-striped table-condensed">' +
                '<thead><tr><th>Operação</th><th>Ref.</th><th>Valor</th><th>Taxa</th><th>Total</th><th>obs</th></tr></thead>'+
                '<tbody>' +
                    '<tr ng-repeat="operation in operations">' +

                        '<td ng-if="operation.movement_object.object == \'transfer\'">Saque (TED)</td>' +
                        '<td ng-if="operation.movement_object.object == \'payable\'">Boleto | {{operation.transaction.entry.description}}<br/><small style="color:#89b7e5; font-weight:bold">{{operation.transaction.entry.contact.name}}</small></td>' +

                        '<td ><span ng-show="operation.ref_date">{{operation.ref_date.format(\'DD\')}}/{{operation.ref_date.format(\'MMM\')}}</span></td>'+

                        '<td class="col-md-2 text-danger" ng-if="operation.movement_object.object == \'transfer\'">'+
                            '- {{((operation.amount*-1)/100) | finance:true}}'+
                        '</td>' +
                        '<td class="col-md-2 text-success" ng-if="operation.movement_object.object == \'payable\'">'+
                            '+ {{operation.transaction.entry.value | finance:true}}'+
                        '</td>' +

                        '<td class="col-md-2" ng-if="operation.movement_object.object == \'transfer\'">+ {{(operation.fee/100) | finance:true}}</td>'+
                        '<td class="col-md-2" ng-if="operation.movement_object.object == \'payable\'">- {{(operation.fee/100)+(operation.fee_falefy/100) | finance:true}}</td>'+

                        '<td class="col-md-2 text-danger" ng-if="operation.movement_object.object == \'transfer\'">'+
                            '- {{( (operation.amount*-1)/100)+(operation.fee/100) | finance:true}}'+
                        '</td>' +
                        '<td class="col-md-2 text-success" ng-if="operation.movement_object.object == \'payable\'">'+
                            '{{ ((operation.amount)/100)-(operation.fee/100) | finance:true}}'+
                        '</td>' +

                        '<td class="col-md-1">'+
                            '<strong class="text-danger" ng-if="operation.difference" uib-tooltip="Boleto pago com um valor menor" uib-tooltip-append-to-body="true">{{ operation.difference | finance:true}}</small></strong>'+
                        '</td>'+

                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</div>' +
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Fechar</button>' +
        '</div>'
    );

    $templateCache.put('invoice_keys.modal.html',
        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +

        '<div class="modal-body">' +
            '<table class="table table-striped table-condensed">' +
                '<thead>'+
                    '<tr>'+
                        '<th class="text-center">Numeração</th>'+
                        '<th class="text-center">Inutilizar</th>'+
                    '</tr>'+
                '</thead>'+
                '<tbody>' +
                    '<tr ng-repeat="row in gaps">' +
                        '<td class="text-center">{{row.nNF }}</td>' +
                        '<td class="text-center"><i class="fa fa-times"/></td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</div>'+

        '</div>'
    );

    $templateCache.put('product_import.modal.html',
        '<form name="form">'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">Descrição</label>'+
                        '<input type="text" class="form-control" ng-model="product.description" />'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">EAN (GTIN)</label>'+
                        '<input type="text" class="form-control" ng-model="product.cean" ng-disabled="true" />'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">Classificação</label>'+
                        '<select class="form-control" ng-model="product.tax_type" required>'+
                            '<option value="">Selecione</option>'+
                            '<option value="0000">0000 - Não Incidente</option>'+
                            '<option value="1317">1317 - ICMS Normal (DIFAL)</option>'+
                            '<option value="1566">1566 - ICMS Antecipado</option>'+
                            '<option value="1568">1568 - ICMS Subistuição tributária ST</option>'+
                            '<option value="0000">Item para consumo</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-3" ng-if="product.tax_type == \'1568\' || product.tax_type == \'1566\'">'+
                        '<label class="label-control">UF</label>'+
                        '<input type="text" class="form-control" ng-model="product.uf" required ng-disabled="true" />'+
                    '</div>'+
                    '<div class="col-md-3" ng-if="product.tax_type == \'1566\' || product.tax_type == \'1568\' ">'+
                        '<label class="label-control">Redução BC</label>'+
                        '<input type="text" class="form-control" ng-model="product.redBC" ui-percentage-mask required />'+
                    '</div>'+
                    '<div class="col-md-3" ng-if="product.tax_type != \'0000\'">'+
                        '<label class="label-control">Alíquota Nota</label>'+
                        '<input type="text" class="form-control" ng-model="product.pICMS" ng-disabled="true" ui-percentage-mask required />'+
                    '</div>'+
                    '<div class="col-md-3" ng-if="product.tax_type != \'0000\'">'+
                        '<label class="label-control">Alíquota</label>'+
                        '<input type="text" class="form-control" ng-model="product.redAliq" ui-percentage-mask required />'+
                    '</div>'+
                    '<div class="col-md-3" ng-if="product.tax_type && product.tax_type != \'0000\' && product.tax_type != \'1317\'">'+
                        '<label class="label-control">MVA</label>'+
                        '<input type="text" class="form-control" ng-model="product.mva" ui-percentage-mask required />'+
                    '</div>'+
                '</div>'+
            '</div>'+
            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form.$invalid">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('make_gnre.modal.html',
        '<form name="form">'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<div class="row">'+
                    '<div class="col-md-6">'+
                        '<label class="label-control">Tipo de imposto</label>'+
                        '<select class="form-control" ng-model="filter.tax_type" ng-change="changeFilter(true)" required>'+
                            '<option value="">Selecione</option>'+
                            '<option ng-value="1317">1317 - ICMS Normal (DIFAL)</option>'+
                            '<option ng-value="1566">1566 - ICMS Antecipado</option>'+
                            '<option ng-value="1568">1568 - ICMS Subistuição tributária ST</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-6" ng-show="filter.tax_type == \'1568\'">'+
                        '<label class="label-control">Período</label>'+
                        '<select class="form-control" ng-model="filter.period" ng-change="changeFilter(true)" required>'+
                            '<option value="1" selected>De 1 de {{today | getDate | date:\'MMMM\'}} a 15 de {{today | getDate | date:\'MMMM\'}}</option>'+
                            '<option value="2">De 16 de {{today | getDate | date:\'MMMM\'}} a {{ lastDay | getDate | date:\'dd\'}} de {{today | getDate | date:\'MMMM\'}}</option>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
                '<br>'+
                '<div class="row financial_entry" ng-show="filter.tax_type && filter.tax_type != \'1317\'">'+
                    '<div class="col-md-12 text-center">'+
                        '<div class="entry-filters">'+
                            '<div class="month-picker {{row.class}}" ng-repeat="row in ufs" ng-click="selectUF(row)">'+
                                '<div class="day">{{row.name}}</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<h1 ng-if="!filter.tax_type">Selecione o tipo de imposto</h1>'+
                        '<h1 ng-if="filter.tax_type && items.length == 0">Nenhum item encontrado</h1>'+
                        '<table class="table table-striped table-condensed" ng-show="items.length > 0">' +
                            '<thead>'+
                                '<tr>'+
                                    '<th class="text-center"></th>'+
                                    '<th class="text-center">Data</th>'+
                                    '<th class="text-center">Nota</th>'+
                                    '<th class="text-center">Descrição</th>'+
                                    '<th class="text-center">CFOP</th>'+
                                    '<th class="text-center">NCM</th>'+
                                    '<th class="text-center">BC</th>'+
                                    '<th class="text-center">MVA</th>'+
                                    '<th class="text-center">Alíquota</th>'+
                                    '<th class="text-center">Alq Orig</th>'+
                                    '<th class="text-center">Imposto</th>'+
                                '</tr>'+
                            '</thead>'+
                            '<tbody>' +
                                '<tr ng-repeat="row in items">' +
                                    '<td class="text-center"><input ng-model="account.favorite" type="checkbox" value=""></td>' +
                                    '<td class="text-center">{{row.dhEmi | getDate | date:\'d/M/y\' }}</td>' +
                                    '<td class="text-center">{{row.nNF }}</td>' +
                                    '<td class="text-center">{{row.description }}</td>' +
                                    '<td class="text-center">{{row.cfop }}</td>' +
                                    '<td class="text-center">{{row.ncm }}</td>' +
                                    '<td class="text-center">{{row.total | currency:"R$"}}</td>' +
                                    '<td class="text-center">{{row.mva * 100| number :2 }}%</td>' +
                                    '<td class="text-center">{{row.aliq * 100| number :2 }}%</td>' +
                                    '<td class="text-center">{{row.pICMS| number :2 }}%</td>' +
                                    '<td class="text-center">{{row.tax | currency:"R$"}}</td>' +
                                '</tr>' +
                            '</tbody>' +
                        '</table>' +
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">Total</label>'+
                        '<input type="text" class="form-control" ng-model="total" ui-money-mask ng-disabled="true" required />'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-12">'+
                        '<label class="label-control">&nbsp;</label>'+

                    '</div>'+
                '</div>'+
            '</div>'+
            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="total == 0">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('make_dar.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="row">'+
            '<div class="col-md-4 col-md-offset-4">'+
                '<table class="table">'+
                    '<tr><td class="text-center" colspan="2">Preencha a DAR com os seguintes dados</td></tr>'+
                    '<tr><td class="text-center col-md-1">CNPj: </td><td class="text-left"><strong>{{dar.cnpj}}</strong></td></tr>'+
                    '<tr><td class="text-center col-md-1">Cod: </td><td class="text-left">'+
                        '<strong>{{dar.tax_type}} {{dar.tax_type == \'1317\' ? \'DIFAL\' : (dar.tax_type == \'1566\' ? \'Antecipado\' : \'Substituição Tributária\')}}</strong>'+
                    '</td></tr>'+
                    '<tr"><td class="text-center col-md-1">Total: </td><td class="text-left"><strong>{{dar.total | currency:"R$"}}</strong></td></tr>'+
                '</table>'+
            '</div>'+
        '</div>'+
        '<div class="row">'+
            '<div class="col-md-8 col-md-offset-2">'+
                '<table class="table table-striped table-condensed" ng-show="invoiceItems.length > 0">' +
                    '<thead>'+
                        '<tr>'+
                            '<th class="text-center">Data</th>'+
                            '<th class="text-center">Nota</th>'+
                            '<th class="text-center">Imposto</th>'+
                            '<th class="text-center">Vencimento</th>'+
                        '</tr>'+
                    '</thead>'+
                    '<tbody>' +
                        '<tr ng-repeat="row in invoiceItems">' +
                            '<td class="text-center">{{row.dhEmi | getDate | date:\'d/M/y\' }}</td>' +
                            '<td class="text-center">{{row.nNF }}</td>' +
                            '<td class="text-center">{{row.tax | currency:"R$"}}</td>' +
                            '<td class="text-center">{{row.payday | getDate | date:\'d/M/y\' }}</td>' +
                        '</tr>' +
                    '</tbody>' +
                '</table>' +
            '</div>'+
        '</div>'+
        '<div class="row">'+
            '<div class="col-md-12">'+
                '<iframe name="sefaz" id="sefaz" src="https://www2.agencianet.fazenda.df.gov.br/DarAvulso/" style="height:500px;width:100%; border:none;" />'+
            '</div>'+
        '</div>'
    );

    $templateCache.put('confirm_items.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-4">'+
                    '<table class="table">'+
                        '<tr><td class="text-center col-md-1">CNPj: </td><td class="text-left"><strong>{{dar.cnpj}}</strong></td></tr>'+
                        '<tr><td class="text-center col-md-1">Cod: </td><td class="text-left">'+
                            '<strong>{{dar.tax_type}} {{dar.tax_type == \'1317\' ? \'DIFAL\' : (dar.tax_type == \'1566\' ? \'Antecipado\' : \'Substituição Tributária\')}}</strong>'+
                        '</td></tr>'+
                        '<tr"><td class="text-center col-md-1">Total: </td><td class="text-left"><strong>{{dar.total | currency:"R$"}}</strong></td></tr>'+
                    '</table>'+
                '</div>'+
            '</div>'+
            '<div class="row">'+
                '<div class="col-md-8">'+
                    '<table class="table table-striped table-condensed" ng-show="invoiceItems.length > 0">' +
                        '<thead>'+
                            '<tr>'+
                                '<th class="text-center">Data</th>'+
                                '<th class="text-center">Nota</th>'+
                                '<th class="text-center">Imposto</th>'+
                                '<th class="text-center">Vencimento</th>'+
                            '</tr>'+
                        '</thead>'+
                        '<tbody>' +
                            '<tr ng-repeat="row in invoiceItems">' +
                                '<td class="text-center">{{row.dhEmi | getDate | date:\'d/M/y\' }}</td>' +
                                '<td class="text-center">{{row.nNF }}</td>' +
                                '<td class="text-center">{{row.tax | currency:"R$"}}</td>' +
                                '<td class="text-center">{{row.payday | getDate | date:\'d/M/y\' }}</td>' +
                            '</tr>' +
                        '</tbody>' +
                    '</table>' +
                '</div>'+
            '</div>'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Observações</label>'+
                    '<textarea class="form-control" ng-model="dar.description">'+
                    '</textarea>'+
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="form.$invalid">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('movement_create.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body service">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Tipo</label>'+
                    '<select class="form-control" ng-model="type">' +
                        '<option value="credit">Entrada</option>'+
                        '<option value="debit">Saída</option>'+
                    '</select>'+
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Motivo<a style="color: red;">*<a/></label>'+
                        '<angucomplete-alt id="inputServiceMovement"'+
                        'input-name="inputServiceMovement"'+
                        'pause="10"'+
                        'selected-object="selectedDescription"'+
                        'local-data="descriptionSuggestionsList"'+
                        'search-fields="description"'+
                        'title-field="description"'+
                        'override-suggestions="false"'+
                        'field-required="true"'+
                        'minlength="0"'+
                        'auto-match="false"'+
                        'template-url="select_template.html"'+
                        'input-class="form-control cant-cancel"'+
                        'text-no-results=""'+
                        'focus-first="true"/>'+
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Quantidade<a style="color: red;">*<a/></label>'+
                    '<input type="text" id="quantity" name="quantity" ng-model="newMovement.quantity" class="form-control" ui-number-mask="{{decimals}}" autofocus ng-required="true" minlength="1"/>' +
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '   <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '   <button ng-click="confirm()" ng-disabled="sending || !description || newMovement.quantity == 0" type="button" class="btn btn-success">'+
        '       <span ng-if="!sending"> {{success_text}}</span>'+
        '       <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '   </button>' +
        '</div>'
    );

    $templateCache.put('make_transfer.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<li class="list-group-item" ng-repeat="bank in banks">' +
                        '<div class="row">'+
                            '<div class="col-md-1">'+
                                '<input type="radio" name="account_id_radio" ng-value="bank.id" ng-model="params.account_id" >'+
                            '</div>'+
                            '<div class="col-md-11">'+
                                '{{bank.holder_name}} <br>'+
                                'CPF/CNPJ: {{bank.taxpayer_id | brCpfCnpj}} <br>'+
                                '{{bank.bank_code}} - {{bank.bank_name}} <br>'+
                                'Agencia {{bank.routing_number}} <br>'+
                                'Conta {{bank.account_number}}'+
                            '</div>'+
                        '</div>'+
                    '</li>' +
                    '<li class="list-group-item">' +
                        'Saldo disponível: {{total | currency:\'R$\'}}'+
                    '</li>' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Valor</label>'+
                    '<input type="text" ng-model="params.amount" class="form-control" ui-number-mask />' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Senha</label>'+
                    '<input type="password" ng-model="params.password" class="form-control" />' +
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="params.amount <= 0 || params.amount > total || !params.password">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('make_transfer.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<li class="list-group-item" ng-repeat="bank in banks">' +
                        '<div class="row">'+
                            '<div class="col-md-1">'+
                                '<input type="radio" name="account_id_radio" ng-value="bank.id" ng-model="params.account_id" >'+
                            '</div>'+
                            '<div class="col-md-11">'+
                                '{{bank.holder_name}} <br>'+
                                'CPF/CNPJ: {{bank.taxpayer_id | brCpfCnpj}} <br>'+
                                '{{bank.bank_code}} - {{bank.bank_name}} <br>'+
                                'Agencia {{bank.routing_number}} <br>'+
                                'Conta {{bank.account_number}}'+
                            '</div>'+
                        '</div>'+
                    '</li>' +
                    '<li class="list-group-item">' +
                        'Saldo disponível: {{total | currency:\'R$\'}}'+
                    '</li>' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Descrição</label>'+
                    '<input type="text" ng-model="params.description" class="form-control" />' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Valor</label>'+
                    '<input type="text" ng-model="params.amount" class="form-control" ui-number-mask />' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Senha</label>'+
                    '<input type="password" ng-model="params.password" class="form-control" />' +
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" ng-disabled="params.description == \'\' || params.amount <= 0 || params.amount > total || !params.password">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('make_boleto.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Vencimento</label>'+
                    '<input type="date" ng-model="params.date" class="form-control" />' +
                '</div>'+
                '<div class="col-md-12">'+
                    '<label class="label-control">Valor</label>'+
                    '<input type="text" ng-model="params.amount" class="form-control" ui-money-mask="R$" />' +
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" >{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('show_paymentLink.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-10">' +
                    '<input type="text" class="form-control" ng-model="link" id="linkSale" readonly="true" ng-click="copy()"/>' +
                '</div>' +
                '<div class="col-md-2">' +
                    '<i class="far fa-copy" ng-click="copy()"></i>' +
                '</div>' +
            '</div>' +
        '</div>' +
        '<div class="modal-footer">' +
        '   <button ng-click="confirm()" type="button" class="btn btn-success"> {{success_text}} </button>' +
        '</div>'
    );

    $templateCache.put('select_client_card.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12 text-center">'+
                    '<div class="alert alert-warning text-center" role="alert" ng-if="cards.length == 0">'+
                        '<i class="fa fa-exclamation-triangle"/> Nenhum cartão cadastrado para esse cliente'+
                    '</div>'+
                    '<ul class="list-group">'+
                        '<li class="list-group-item" ng-repeat="card in cards">'+
                            '<label class="radio-inline" ng-click="selectCard(card.gateway_id)">'+
                                '<i class="fab fa-cc-{{card.brand.toLowerCase()}}"></i> •••• •••• •••• {{card.last_digits}}'+
                            '</label>'+
                        '</li>'+
                        '<li class="list-group-item">'+
                            '<label class="radio-inline" ng-click="addCard()">'+
                                '<i class="fa fa-plus"></i> Adicionar cartão'+
                            '</label>'+
                        '</li>'+
                    '</ul>'+
                '</div>'+
            '</div>'+
            '<div ng-if="newCard" class="row">'+
                '<div class="col-md-12 form-group">'+
                    '<div class="row text-center">'+
                        '<div class="card-wrapper" id="card-container"></div>'+
                    '</div>'+
                '</div>'+
                '<div class="col-md-12 form-group">'+
                    '<form name="payment" method="post" card width="350" card-container="#card-container" placeholders="placeholders" messages="messages">'+
                        '<div class="row">'+
                            '<div class="col-md-8 form-group" show-errors>'+
                                '<label for="number" class="control-label">Número do cartão</label>'+
                                '<input class="form-control" type="text" id="number" name="number" ui-validate-async="\'validCard($value)\'" card-number ng-model="card.number" required />'+
                                '<span ng-show="card.number.$touched && card.number.$error.validatorAsync" class="help-block ng-hide"><small>Cartão inválido</small></span>'+
                            '</div>'+
                            '<div class="col-md-4 form-group" show-errors>'+
                                '<label class="control-label">Expiração</label>'+
                                '<input placeholder="MM/AAAA" class="form-control" ui-validate="\'validDate($value)\'" type="text" name="cc-exp"  autocomplete="cc-exp" card-expiry ng-model="card.expiration"  required/>'+
                                '<span ng-show="card.expiration.$touched && card.expiration.$error.validator" class="help-block ng-hide"><small>Data inválida</small></span>'+
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div class="col-md-8 form-group" show-errors>'+
                                '<label class="control-label">Nome do titular</label>'+
                                '<input class="form-control"  type="text" name="ccname" card-name ng-model="card.name" autocomplete="cc-name" required/>'+
                            '</div>'+
                            '<div class="col-md-4 form-group" show-errors>'+
                                '<label class="control-label"><small>Código de segurança</small></label>'+
                                '<input class="form-control"  type="text" name="cvc" card-cvc ng-model="card.cvc" autocomplete="cc-csc" required/>'+
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div class="col-md-12">'+
                                '{{payment.CardNumber | json}}'+
                            '</div>'+
                        '</div>'+
                    '</form>'+
                    '<div class="row">'+
                        '<div class="col-md-12">'+
                            '<button ng-click="confirm()" class="btn btn-success btn-block">'+
                                '<span ng-if="is_card_saving"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
                                '<span ng-if="!is_card_saving">Cadastrar</span>'+
                            '</button>'+
                        '</div>'+
                    '</div>'+
                    '<div class="row">'+
                        '<div class="col-md-12 form-group">'+
                            '&nbsp;'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>' +
        '</div>'
    );

    $templateCache.put('showTerms.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">Termos e condições</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<p ng-bind-html="terms"></p>'+
                '</div>' +
            '</div>' +
        '</div>'
    );

    $templateCache.put('transactions_totals.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">' +
                '<div class="col-md-12">' +
                    '<div class="col-md-3">' +
                        '<div class="miniChart text-info">' +
                            '<h3>{{totals.total | currency:"R$"}}</h3>' +
                            '<p>Total Transacionado</p>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-3">' +
                        '<div class="miniChart text-success">' +
                            '<h3>{{totals.total_paid | currency:"R$"}}</h3>' +
                            '<p>Total Pago</p>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-3">' +
                        '<div class="miniChart text-warning">' +
                            '<h3>{{totals.total_noPaid | currency:"R$"}}</h3>' +
                            '<p>Total Pendente</p>' +
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-3">' +
                        '<div class="miniChart text-warning">' +
                            '<h3>{{totals.total_toAnticipation | currency:"R$"}}</h3>' +
                            '<p>Total Antecipado</p>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>' +
            '<div class="row">'+
                '&nbsp;'+
            '</div>' +
            '<div class="filters">' +
                '<div class="col-md-12">' +
                    '<span ng-if="showAll != \'anticipation\'" ng-click="toggleShowAll(\'anticipation\')"> <i class="fas fa-caret-right"/> Ver todas as antecipações</span>' +
                    '<span ng-if="showAll == \'anticipation\'" ng-click="toggleShowAll(null)"> <i class="fas fa-caret-down"/> Ocultar todas antecipações</span>' +
                '</div>' +
            '</div>' +
            '<div class="row">'+
                '&nbsp;'+
            '</div>' +
            '<div class="filters">' +
                '<div class="col-md-12">' +
                    '<span ng-if="showAll != \'receivable\'" ng-click="toggleShowAll(\'receivable\')"> <i class="fas fa-caret-right"/> Ver todos recebíveis</span>' +
                    '<span ng-if="showAll == \'receivable\'" ng-click="toggleShowAll(null)"> <i class="fas fa-caret-down"/> Ocultar todos recebíveis</span>' +
                '</div>' +
            '</div>' +
            '<div class="row">'+
                '&nbsp;'+
            '</div>' +
            '<div class="row" ng-if="showAll == \'anticipation\'">'+
                '<div class="col-md-12">'+
                    '<table class="table">'+
                        '<tr>'+
                            '<th>Data</th>'+
                            '<th class="text-right">Valor antecipado</th>'+
                            '<th class="text-right">Valor a retornar</th>'+
                        '</tr>'+
                        '<tr ng-repeat="antecipation in anticipations">'+
                            '<td>{{antecipation.created_at | getDate | date:\'dd/MM/yy\'}}</td>'+
                            '<td class="text-right">{{antecipation.anticipated_value | currency:\'R$\'}}</td>'+
                            '<td class="text-right">{{antecipation.total | currency:\'R$\'}}</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>' +
            '</div>' +
            '<div class="row" ng-if="showAll == \'receivable\'">'+
                '<div class="col-md-12">'+
                    '<table class="table">'+
                        '<tr>'+
                            '<th>Data</th>'+
                            '<th>Previsão</th>'+
                            '<th class="text-right">Valor Bruto</th>'+
                            '<th class="text-right">Valor Líquido</th>'+
                            '<th class="text-right">Valor Final</th>'+
                        '</tr>'+
                        '<tr ng-repeat="receivable in receivables">'+
                            '<td>{{receivable.created_at | getDate | date:\'dd/MM/yy\'}}</td>'+
                            '<td>{{receivable.expected_on | getDate | date:\'dd/MM/yy\'}}</td>'+
                            '<td class="text-right">{{receivable.gross_amount | currency:\'R$\'}}</td>'+
                            '<td class="text-right">{{receivable.amount | currency:\'R$\'}}</td>'+
                            '<td class="text-right">{{receivable.amount + ( receivable.internal_anticipation_fee ? receivable.internal_anticipation_fee : 0) | currency:\'R$\'}}</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>' +
            '</div>' +
        '</div>'+
        '<div class="modal-footer">' +
        '   <button ng-click="confirm()" type="button" class="btn btn-success" ng-disabled="listToAnticipation.length == 0"> {{listToAnticipation.length > 0 ? \'Antecipar tudo \' : \'Não há valor para antecipar\'}} </button>' +
        '</div>'
    );

    $templateCache.put('continueShopping.modal.html',
        '<div class="modal-header">'+
            '<h4 class="modal-title text-success text-center">Item adicionado ao carrinho com sucesso</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12 text-center">'+
                    '<a href="#" class="fr__btn color_secondary" ng-click="continue()">Continuar comprando</a>'+
                '</div>' +
                '<div class="col-md-12">'+
                    '&nbsp;'+
                '</div>' +
                '<div class="col-md-12 text-center">'+
                    '<a href="" ng-click="checkout()">Ir para carrinho</a>'+
                '</div>' +
            '</div>' +
        '</div>'
    );

    $templateCache.put('show_contract_payments.modal.html',

        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4>Venda</h4>'+
        '</div>' +

        '<div class="modal-body text-center">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<table class="table">'+
                        '<tr>'+
                            '<th class="text-left">Status</th>'+
                            '<th class="text-left">Data</th>'+
                            '<th class="text-right">Valor</th>'+
                        '</tr>'+
                        '<tr ng-repeat="payment in payments">'+
                            '<td class="text-left">{{payment.status_formated}}</td>'+
                            '<td class="text-left">{{payment.created_at | getDate | date:\'dd/MM/yyyy\'}}</td>'+
                            '<td class="text-right">{{payment.amount | currency:\'R$\'}}</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>'+
        '</div>'
    );

    $templateCache.put('add_plan.modal.html',
        '<form>'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+
            '<div class="modal-body">' +
                '<table class="table table-condensed table-striped table-pointer table-hover">' +
                    '<tr>'+
                        '<th>Nome</th>'+
                        '<th>Valor</th>'+
                    '</tr>'+
                   '<tr ng-repeat="row in plans track by row.id" ng-click="select(row)" ng-class="{active:plan.id==row.id}">' +
                      '<td title="\'Nome\'" sortable="\'name\'" filter="{name: \'text\'}">{{row.name}}</td>' +
                      '<td title="\'Valor\'" sortable="\'value\'" class="col-md-2 text-right">{{row.price | currency:\'R$\'}}</td>' +
                   '</tr>' +
                '</table>' +

                '<div class="row">' +
                    '<div class="col-md-3">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Valor</label>' +
                            '<input name="value" type="text" class="form-control" ng-model="plan.amount" required ui-money-mask/>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('measureConvert.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">' +
                '<div class="col-md-6">' +
                    '<div class="form-group">' +
                        '<label class="control-label">De</label>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_uCom_original" required/>' +
                        '<hr>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_original" required/>' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6">' +
                    '<div class="form-group">' +
                        '<label class="control-label">Para</label>' +
                        '<input name="value" type="text" class="form-control" ng-model="item.measure_converter_uCom_new" required/>' +
                        '<hr>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_new" required/>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('update_movement.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">' +
                '<div class="col-md-6">' +
                    '<div class="form-group">' +
                        '<label class="control-label">De</label>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_uCom_original" required/>' +
                        '<hr>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_original" required/>' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6">' +
                    '<div class="form-group">' +
                        '<label class="control-label">Para</label>' +
                        '<input name="value" type="text" class="form-control" ng-model="item.measure_converter_uCom_new" required/>' +
                        '<hr>' +
                        '<input name="text" type="text" class="form-control" ng-model="item.measure_converter_new" required/>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('new_transfer.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<form>'+
                '<div class="row">' +
                    '<div class="col-md-6">' +
                        '<div class="form-group">' +
                            '<label class="control-label">De</label>' +
                            '<select class="form-control" ng-model="transfer.from">'+
                                '<option value="">Selecione</option>'+
                                '<option ng-value="account.id" ng-repeat="account in accounts">{{account.name}}</option>'+
                            '</select>'+
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-6">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Para</label>' +
                            '<select class="form-control" ng-model="transfer.to">'+
                                '<option value="">Selecione</option>'+
                                '<option ng-value="account.id" ng-repeat="account in accounts">{{account.name}}</option>'+
                            '</select>'+
                        '</div>' +
                    '</div>' +
                    '<div class="col-md-6">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Data</label>' +
                            '<input name="transacted_at" type="date" class="form-control" ng-model="transfer.transacted_at" required />' +
                        '</div>' +
                    '</div>' +

                    '<div class="col-md-6">' +
                        '<div class="form-group">' +
                            '<label class="control-label">Valor</label>' +
                            '<input name="value" type="text" class="form-control" ng-model="transfer.value" required ui-money-mask/>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<div class="row">'+
                    '<div class="form-group">' +
                        '<div class="col-md-12">'+
                            '<label class="label-control">Descrição</label>'+
                            '<textarea class="form-control" ng-model="transfer.description" required >'+
                            '</textarea>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '</br>'+
            '</form>'+
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" ng-click="confirm()" ng-disabled="transfer.value == 0 || !transfer.to || !transfer.from">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('note_item.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<textarea class="form-control" ng-model="item.note">'+
                    '</textarea>'+
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        //'    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" >{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('show_purchase_order.modal.html',
        '<div class="modal-header">'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '</div>'+
            '</div>'+
            '<div class="row">'+
                '&nbsp;'+
            '</div>' +
            '<div class="row">'+
                '<div class="col-md-4">'+
                    '<p class="modal-title text-left">Criada por: {{userName}}</p>'+
                '</div>'+
                '<div class="col-md-4">'+
                '</div>'+
                '<div class="col-md-4">'+
                    '<p class="modal-title text-right">Criada em: {{dateList | date:\'dd/MM/yyyy hh:mm\'}}</p>'+
                '</div>'+
            '</div>'+
            '<hr>'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<h2 class="modal-title text-center">{{title}}</h2>'+
                    '<p class="text-center">Total de itens: {{itemsTotalQtd}}</p>'+
                '</div>'+
            '</div>'+
            '<div class="col-md-4 pull-right">'+
                '<p class="text-danger text-right" style="cursor: pointer">Limpar lista <i class="fas fa-trash" ng-click="removeAllItems(purchase_id)"/></p>'+
            '</div>'+
        '</div>'+
        '<div class="modal-body">' +
            '<table class="table table-responsive">'+
            '    <thead>'+
            '        <tr>'+
            '            <th class="text-left" style="width: 10%">Cod</th>'+
            '            <th class="text-left" style="width: 47%">Nome</th>'+
            '            <th class="text-left" style="width: 20%">Preço Compra</th>'+
            '            <th class="text-left" style="width: 20%">Qtd</th>'+
            '            <th class="text-left" style="width: 20%">Total</th>'+
            '            <th class="text-left" style="width: 3%"></th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="item in purchaseListItems.data">'+
            '            <td class="text-left" style="width: 10%">{{item.service_id}}</td>'+
            '            <td class="text-left truncate" style="width: 47%">{{item.service.name}}</td>'+
            '            <td class="text-left" style="width: 20%">{{item.quotation_value}}</td>'+
            '            <td class="text-left" style="width: 20%">{{item.quantity}}</td>'+
            '            <td class="text-left" style="width: 20%">{{item.quotation_total}}</td>'+
            '            <td class="text-left" style="width: 3%"><i class="fas fa-trash" ng-click="removeItem(item)"/></td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'+
            '<div class="row">'+
                '<div class="col-md-12">'+
                '<h3 class="text-right">Total | {{total | currency:\'R$\'}}</h3>'+
            '</div>'+
        '</div>' +
        '<hr>'+
        '<div class="col-md-4" ng-if="status == \'opened\'">' +
            '<label> </label>' +
            '<button class="btn btn-default btn-block" ng-click="updateStatus(\'in_progress\')" style="color:#57A300"><i class="fas fa-list" style="color:#57A300"></i> Enviar para compra</button>' +
        '</div>' +
        '<div class="col-md-4" ng-if="status == \'in_progress\' || status == \'opened\'">' +
            '<label> </label>' +
            '<button class="btn btn-default btn-block" ng-click="updateStatus(\'finished\')" style="color:#f44336"><i class="fas fa-list-ul" style="color:#f44336"></i> Finalizar Lista</button>' +
        '</div>' +
        '<div class="col-md-4" ng-if="status == \'finished\'">' +
            '<label> </label>' +
            '<button class="btn btn-primary btn-block" ng-click="getProvider()"><i class="fas fa-list-ul"></i> Enviar para entrada de compra</button>' +
        '</div>' +
        '<div class="col-md-4">' +
            '<label> </label>' +
            '<button class="btn btn-default btn-block" ng-click="printPurchaseList()"><i class="fas fa-print"></i> Imprimir</button>' +
        '</div>' +
        '<div class="row">'+
            '&nbsp;'+
        '</div>' +
        '</div>' +
        '<div class="row">'+
            '&nbsp;'+
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('item_purchase.modal.html',
        '<div class="modal-header">' +
            '<div ng-bind-html="title"></div>' +
        '</div>' +
        '<div class="modal-body">' +
            '<div class="row">' +
                '<div class="col-md-12">' +
                    '<div class="form-group">' +
                        '<label>Selecione lista</label>'+
                        '<angucomplete-alt id="inputList"'   +
                        ' input-name="inputList"' +
                        ' pause="0"' +
                        ' selected-object="selectedPurchaseList"' +
                        ' selected-object-data="row"' +
                        ' local-data="purchasesList"' +
                        ' search-fields="name"' +
                        ' initial-value="list"'+
                        ' title-field="name"' +
                        ' override-suggestions="false"' +
                        ' field-required="true"' +
                        ' minlength="0"' +
                        ' auto-match="false"' +
                        ' template-url="select_template.html"' +
                        ' input-class="form-control cant-cancel"' +
                        ' field-tabindex="0"' +
                        ' text-no-results=""' +
                        ' focus-first="true" />' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-12" ng-if="suggestion">' +
                    '<div class="form-group">' +
                        '<label>Produto</label>' +
                        '<input type="text" class="form-control" ng-model="suggestion.name" ng-disabled="true"/>' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6" ng-if="suggestion">' +
                    '<div class="form-group">' +
                        '<label>Estoque Atual</label>'+
                        '<input type="text" class="form-control" ng-model="suggestion.real_stock" ng-disabled="true"/>' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6" ng-if="suggestion">' +
                    '<div class="form-group">' +
                        '<label>Preço de compra</label>'+
                        '<input type="text" name="purchase_price" class="form-control" ng-model="suggestion.purchase_price" ui-money-mask />' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6" ng-if="suggestion">' +
                    '<div class="form-group">' +
                        '<label ng-if="suggestion.purchase_suggestion">Quantidade sugerida</label>'+
                        '<input type="text" class="form-control" ng-model="suggestion.purchase_suggestion" ng-disabled="true" ui-number-mask="0" />' +
                    '</div>' +
                '</div>' +
                '<div class="col-md-6" ng-if="suggestion">' +
                    '<div class="form-group">' +
                        '<label>Quantidade</label>'+
                        '<input type="text" class="form-control" ng-model="input.value" ui-number-mask="0" />' +
                    '</div>' +
                '</div>' +
            '</div>' +
       '</div>' +
       '<div class="modal-footer">' +
       '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
       '    <button ng-if="suggestion" ng-click="confirm()" type="button" class="btn btn-success">'+
       '        <span ng-if="!sending"> {{success_text}}</span>'+
       '        <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
       '    </button>' +
       '    <button ng-if="!suggestion" ng-click="confirm()" type="button" class="btn btn-success">'+
       '        <span ng-if="!sending"> {{success_text}}</span>'+
       '        <span ng-if="sending"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
       '    </button>' +
       '</div>'
    );

    $templateCache.put('select_provider.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label>Fornecedores</label>'+
                    '<select class="form-control" ng-model="provider">'+
                        '<option ng-value="provider" ng-repeat="provider in providers">{{provider.name}}</option>'+
                    '</select>'+
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        //'    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()" >{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('search.modal.html',
        '<div class="modal-header text-center" style="font-size: 22px; font-weight: bold;">' +
        '<button type="button" class="close" ng-click="return()"><span aria-hidden="true">&times;</span></button>'+
            '<div ng-bind-html="title"></div>' +
        '</div>' +
       ' <div ng-show="waiting">' +
       '    <div class="backgroundLoader"></div>' +
       '    <div class="loader"></div>' +
       '</div>' +
        '<div class="modal-body">' +
        '    <div class="row">' +
        '        <div class="col-md-3 form-group">' +
        '            <label class="control-label">De</label>' +
        '            <div class="input-group">' +
        '                <input type="text" class="form-control"' +
        '                    uib-datepicker-popup="dd/MM/yyyy"' +
        '                    ng-model="params.start_date"' +
        '                    is-open="start_date.opened"' +
        '                    close-text="Fechar"' +
        '                    current-text="Hoje"' +
        '                    clear-text="Limpar"' +
        '                    ng-focus="openDate(start_date)"' +
        '                    alt-input-formats="altInputFormats"' +
        '                />' +
        '                <span class="input-group-btn">' +
        '                    <button type="button" class="btn btn-default" ng-click="openDate(start_date)">' +
        '                        <i class="fa fa-calendar"></i>' +
        '                    </button>' +
        '                </span>' +
        '            </div>' +
        '       </div>' +

        '        <div class="col-md-3 form-group">' +
        '            <label class="control-label">Até</label>' +
        '            <div class="input-group">' +
        '                <input type="text" class="form-control"' +
        '                    uib-datepicker-popup="dd/MM/yyyy"' +
        '                    ng-model="params.end_date"' +
        '                    is-open="end_date.opened"' +
        '                    close-text="Fechar"' +
        '                    current-text="Hoje"' +
        '                    clear-text="Limpar"' +
         '                   ng-focus="openDate(end_date)"' +
         '                   alt-input-formats="altInputFormats"' +
        '                />' +
        '                <span class="input-group-btn">' +
        '                    <button type="button" class="btn btn-default" ng-click="openDate(end_date)">' +
        '                        <i class="fa fa-calendar"></i>' +
        '                    </button>' +
        '                </span>' +
        '            </div>' +
        '        </div>' +
        '        <div class="col-md-4 form-group" role="group" style="margin-bottom: 0px">' +
        '           <div class="col-md-10 input-group">' +
        '            <label class="control-label">Pesquisa</label><br/>' +
        '              <input type="text" placeholder="Pesquise por Clientes, vendas, notas ..." ng-model="params.searchFinder" class="form-control text-left" id="str" name="str" ng-keyup="$event.keyCode == 3 && searchingDetail($value)" />' +
        '            </div>' +
        '       </div>' +
        '       <div class="col-md-2 form-group">' +
        '            <label class="control-label">&nbsp;</label><br/>' +
        '            <a ng-click="search()" class="btn btn-default btn-block">Filtrar</a>' +
        '        </div>' +
        '       <div class="col-md-12" style="padding-bottom: 5px">' +
        '            <label class="control-label">&nbsp;</label><br/>' +
        '            <div class="btn-group" role="group">' +
        '                <input type="button" class="btn btn-default" ng-class="{\'btn btn-success\': params.from == \'cashier\' || params.status == \'budget\', \'btn btn-default\': params.from == \'ecommerce\'}" id="cashier" name="cashier" value="Caixa" ng-click="filterFromDevolution(\'cashier\')" />' +
        '                <input type="button" class="btn btn-default" ng-class="{\'btn btn-success\': params.from == \'ecommerce\', \'btn btn-default\': params.from == \'cashier\'|| params.status == \'budget\'}" id="ecommerce" name="ecommerce" value="E-commerce" ng-click="filterFromDevolution(\'ecommerce\')" />' +
        '                <input type="button" class="btn btn-default" ng-class="{\'btn btn-primary\': params.status == \'budget\' && params.from == \'cashier\', \'btn btn-default\': params.from == \'cashier\' || params.from == \'ecommerce\'}" id="budget" value="Orçamento" name="budget" ng-click="filterStatusDevolution(\'budget\')" />' +
        '            </div>' +
        '        </div>' +
        '    </div>' +
            '<div class="scrollable">'+
            '<table class="table">'+
            '    <tr>'+
            '        <th class="text-center">Número</th>'+
            '        <th class="text-center">Senha</th>'+
            '        <th class="text-center">Data</th>'+
            '        <th class="col-md-2 text-center">Nome</th>'+
            '        <th class="text-center">R$ </th>'+
            '        <th class="text-center">Status</th>'+
            '        <th class="text-center">Local da venda</th>'+
            '        <th class="text-center">Informações</th>'+
            '    </tr>'+
            '    <tr ng-repeat="row in resultsSearch track by row.hashid">'+
            '        <td title="\'Numero\'">{{row.number }}</td>'+
            '        <td class="text-center" title="Senha">{{row.call_number }}</td>'+
            '        <td class="text-center" title="Data">{{row.closed_at | getDate | date:\'dd/MM\'}}</td>  '+
            '        <td class="text-center truncate" title="Comprador">{{ row.buyer ? row.buyer.name : \'Consumidor\'}}</td>'+
            '        <td class="text-center" title="Valor">{{row.amount | currency:"R$"}}</td>'+
            '        <td class="text-center" title="\'Status\'" filter-data="status">'+
            '            <span class="label label-default"'+
            '                ng-class="{'+
            '                    \'label-warning\': row.status == \'opened\','+
            '                    \'label-success\': row.status == \'succeeded\','+
            '                    \'label-danger\': row.status == \'canceled\''+
            '                }"'+
            '            >{{row.status_formated}}</span>'+
            '        </td>'+
            '        <td class="text-center" title="\'Local da venda\'" filter-data="from">'+
            '            <span class="label label-default"'+
            '                ng-class="{'+
            '                    \'label-warning\': row.from == \'cashier\','+
            '                    \'label-success\': row.from == \'ecommerce\''+
            '                }"'+
            '            >{{row.from == \'cashier\' ? \'Caixa\' : \'E-commerce\'}}</span>'+
            '        </td>'+
            '        <td class="text-center" title="\'Abrir venda\'" ng-if="params.devolution">'+
            '                <a class="btn btn-sm btn-default action_button" ng-click="viewPaymentsDevolution(row)" uib-tooltip="Devolução" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-eye"></i>'+
            '                </a>'+
            '        </td>'+
            '        <td class="col-md-3 text-center" title="\'Informações\'" filter-data="payments" ng-if="!params.devolution">'+
            '            <div class="btn-group">'+
            '                <a ng-if="row.payment_later == 1 ? (row.payment_later_value != row.amount) : (row.status == \'opened\' || row.status == \'budget\')" class="btn btn-sm btn-default action_button" ng-click="openSale(row)" uib-tooltip="Ver venda" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-share-square"></i>'+
            '                </a>'+
            '                <a ng-if="row.payment_later == 1 ? (row.payment_later_value == row.amount) : (row.payments.length > 0 && row.status == \'succeeded\' || row.payments.payment_method == \'link\' || row.payments.payment_method == \'boleto\' || row.payments.payment_method == \'others\')" '+
            '                class="btn btn-sm btn-default action_button" ng-click="viewPayments(row)" uib-tooltip="Ver cobrança" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-eye"></i>'+
            '                </a>'+
            '                <a class="btn btn-sm btn-default action_button" ng-click="print(row.hashid)" uib-tooltip="Imprimir" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-print"></i>'+
            '                </a>'+
            '                <a ng-if="!row.invoice && row.status != \'opened\' && row.status != \'canceled\'" ng-click="makeInvoice(row.hashid)" class="btn btn-sm btn-default action_button" uib-tooltip="Emitir nota fiscal" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-file-invoice-dollar"></i>'+
            '                </a>'+
            '                <a ng-if="row.invoice" ng-click="showInvoice(row.invoice)"class="btn btn-sm btn-default action_button" uib-tooltip="Visualizar nota fiscal" tooltip-append-to-body="true">'+
            '                    <i class="fas fa-file-invoice-dollar"></i>'+
            '                </a>'+
            '                <button class="btn btn-sm btn-default dropdown-toggle" type="button" ng-if="row.status != \'canceled\'" id="dropdownMenuButton-@{{data.id}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">'+
            '                    <i class="fa fa-angle-down" />'+
            '                </button>'+
            '                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton-@{{data.id}}" ng-if="row.status != \'canceled\'">'+
            '                    <li><a href="#" ng-click="sendToshipping(row)" ng-if="row.status == \'succeeded\' && row.shipping_type != \'delivering\'">Enviar para Entrega</a></li>'+
            '                        <li ng-if="row.status != \'canceled\'"><a href="#" ng-click="canceleSale(row.hashid, false)">Cancelar</a></li>'+
            '                </ul>'+
            '            </div>'+
            '        </td>'+
            '    </tr>'+
            '</table>'+
            '</div>'+
            '<div class="row">' +
            '    <div class="col-md-4 col-md-offset-4 form-group text-center">' +
            '        <label class="control-label">&nbsp;</label><br/>' +
            '        <a  class="btn btn-primary btn-block" ng-click="return()"><i class="fas fa-undo-alt"></i> Voltar</a>' +
            '    </div>' +
            '</div>' +
        '</div>'
    );

    $templateCache.put('service_offer.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '   <form>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <label>Descrição</label>'+
        '               <input type="text" ng-model="offer.description" class="form-control" />' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Quantidade disponível</label>'+
        '               <input type="text" ng-model="offer.quantity" class="form-control" ui-number-mask="0" />' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Quantidade minima</label>'+
        '               <input type="text" ng-model="offer.minimum_quantity" class="form-control" ui-number-mask="0" />' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Data Inicio</label>'+
        '               <input type="date" class="form-control" ng-model="offer.start_at" />' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Hr/Inicio</label>'+
        '               <input type="time" class="form-control" ng-model="offer.start_at" />' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Data Fim</label>'+
        '               <input type="date" class="form-control" ng-model="offer.end_at" />' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Hr/Fim</label>'+
        '               <input type="time" class="form-control" ng-model="offer.end_at" />' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Tipo de oferta</label>'+
        '               <select class="form-control" ng-model="offer.price_type">'+
        '                   <option value="total_value">Definir valor</option>'+
        '                   <option value="percentage_value">Desconto em porcentagem</option>'+
        '                   <option value="dicount_value">Desconto em reais</option>'+
        '               </select>' +
        '           </div>' +
        '           <div class="col-md-6 form-group">' +
        '               <label>Valor</label>'+
        '               <input type="text" ng-if="offer.price_type == \'total_value\' || offer.price_type == \'dicount_value\'" class="form-control" ng-model="offer.price" ui-money-mask />'+
        '               <input type="text" ng-if="offer.price_type == \'percentage_value\'" class="form-control" ng-model="offer.price" ui-percentage-mask /> '+
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('model_label.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{name}}</h4>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<textarea class="form-control" rows="30">'+
                    '{{label}}'+
                    '</textarea>'+
                '</div>'+
            '</div>'+
        '</div>'+
        '<div class="modal-footer">' +
        '</div>'
    );

    $templateCache.put('selectList.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h6 class="modal-title text-center">{{title}}</h6>'+
        '</div>'+
        '<div class="modal-body">' +
            '<div class="row">'+
                '<div class="col-md-12 form-group">' +
                    '<table class="table-condensed table-striped table-pointer table-hover">'+
                        '<tr ng-if="heads.name">'+
                            '<td ng-repeat="head in heads">{{head.name}}</td>'+
                        '</tr>'+
                        '<tr ng-if="label">'+
                            '<td ng-click="confirm_gondola()">MODELO PARA GONDOLA - A4</td>'+
                        '</tr>'+
                        '<tr ng-repeat="row in list">'+
                            '<td ng-repeat="head in heads" ng-click="confirm(row)">{{head.relationship ? row[head.relationship][head.field] : row[head.field] }}</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>' +
            '</div>'+
        '</div>'
    );

    $templateCache.put('selectItem.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h5 class="modal-title text-center">{{title}}</h5>'+
        '</div>'+

        '<div class="modal-body">' +
        '   <table class="table table-condensed table-hover table-pointer"> '+
        '       <tr ng-repeat="row in data" ng-click="selectRow(row)">'+
        '           <td>{{row.description}}</td>'+
        '       </tr>'+
        '   </table>'+
        '</div>'
    );

});
