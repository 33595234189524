angular.module('contract_type', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('contract_type').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('contract_type', {
        url         : "/contract_type",
        templateUrl : server+"/views/m/contract_type",
        controller  : 'contract_type.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'base_data',
            label: 'Tipos de contrato'
        }
    })

    .state('contract_type_create', {
        url         : "/contract_type/create",
        templateUrl : server+"/views/m/contract_type/create",
        controller  : 'contract_type.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'contract_type',
            label: 'Adicionar'
        }
    })

    .state('contract_type_show', {
        url         : "/contract_type/:id",
        templateUrl : server+"/views/m/contract_type/show",
        controller  : 'contract_type.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'contract_type',
            label: '{{contract_type.id}}'
        }
    })

    .state('contract_checkout', {
        url           : "/dcontract/:contract_type_id",
        templateUrl   :  server+"/views/public/contract_checkout",
        controller    : 'contract_type.checkout'
    })

}]);

/**
 * List Controller
 */

angular.module('contract_type').
controller('contract_type.checkout', ['$scope', 'WizardHandler', '$http', '$auth', '$q', '$contract_type', '$sce', '$countryState', '$city', '$stateParams', '$client_contract', 'ngToast', '$client', '$uibModal', '$rootScope',
function($scope, WizardHandler, $http, $auth, $q, $contract_type, $sce, $countryState, $city, $stateParams, $client_contract, ngToast, $client, $uibModal, $rootScope) {

    $scope.placeholders = {
        number: '•••• •••• •••• ••••',
        name: 'Nome do titular'
    }

    $scope.messages = {
        validDate: 'válido\naté',
        monthYear: 'mês/ano',
    }

    $scope.card = {
        card_number: '',
        holder_name: '',
        expiration: '',
        security_code: ''
    }

    $scope.client_contract = {
        client: {
            address: [
                {
                    zip_code: ''
                }
            ]
        }
    };

    $scope.cards = [];

    $contract_type.getOne({hashid : $stateParams.contract_type_id}).then(function(response){
        if(response){
            $scope.client_contract.type_id  = response.id;
            $scope.client_contract.type     = response;
        }
    }, function(error) {

    });

    $scope.searchClient = function(invalid){
        if(invalid)
            return;

        $client_contract.checkClient({
            type_id     : $scope.client_contract.type_id,
            cpf_cnpj    : $scope.client_contract.client.cpf_cnpj
        }).then(function(response){
            $scope.client_contract = response;
            $scope.queryCities();
            WizardHandler.wizard().next();
            if( $scope.client_contract.client.completed_registration == 1 ){
                WizardHandler.wizard().next();
            }
        }, function(error){
            ngToast.danger({
                content: 'Preencha todos os campos corretamente',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
        console.log($scope.client_contract);
    }

    $countryState.query({count:100}).$promise.then(function(response){
        $scope.states = response.data;
    });

    $scope.queryCities = function() {
        if( $scope.client_contract.client && $scope.client_contract.client.address.length > 0 )
            $city.query({city_id: $scope.client_contract.client.address[0].city_id}).$promise.then(function(response){
                $scope.cities = response.data;
            });
    }

    $scope.getCep = function(){
      if( $scope.client_contract.client && $scope.client_contract.client.address[0] != undefined && $scope.client_contract.client.address[0].zip_code.length > 7 ){
          $scope.waiting = true;
          var url         = 'https://viacep.com.br/ws/'+$scope.client_contract.client.address[0].zip_code+'/json';
          var trustedUrl  = $sce.trustAsResourceUrl(url);
          $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
              if (response.data.logradouro != undefined && response.data.bairro != undefined){
                  $scope.client_contract.client.address[0].line1        = response.data.logradouro;
                  $scope.client_contract.client.address[0].neighborhood = response.data.bairro;

                  // Escolher UF
                  $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                      $scope.client_contract.client.address[0].state_id = response.id;
                  });

                  // Escolher Cidade
                  $city.query({ibge: response.data.ibge}).$promise.then(function(response){
                      $scope.cities = response.data;
                      $scope.client_contract.client.address[0].city_id = response.data[0].id;
                  });

                  $scope.client_contract.client.address[0].line2  = '';
                  $scope.client_contract.client.address[0].number = '';
              }
              $scope.waiting = false;
          }, function(error){
              $scope.waiting = false;
          });
      }
    };

    $scope.saveData = function(form) {
        if(form.$invalid){
            ngToast.danger({
                content: 'Preencha todos os campos corretamente',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }
        $client_contract.updateClient($scope.client_contract.id, $scope.client_contract.client).then(function(response){
            $scope.client_contract = response;
            WizardHandler.wizard().next();
        }, function(error) {
            ngToast.danger({
                content: 'Preencha todos os campos corretamente',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.addCard = function() {
        $scope.newCard = true;
    }

    $scope.createCard = function() {
        $scope.waiting = true;
        $scope.is_card_saving = true;
        var exp = $scope.card.expiration.split('/');
        var exp = exp[0].trim() + exp[1].trim();

        $scope.card.expiration_month = exp.substring(0,2);
        $scope.card.expiration_year  = exp.substring(2);

        $client.storeCards($scope.client_contract.client.id, $scope.card).then(function(response){
            $scope.cards.push(response);
            $scope.client_contract.client_card_id = response.id;
            $scope.is_card_saving = false;
            $scope.newCard = false;
            $scope.waiting = false;
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao adicionar cartão',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.is_card_saving = false;
        });
    }

    $scope.validCard = function(value) {
        var deferred = $q.defer();
        $timeout(function() {
            var valid = angular.element('#number').hasClass('jp-card-valid');
            if (valid) {
                deferred.resolve(true);
            } else {
                deferred.reject(false);
            }
        }, 500);
        return deferred.promise;
    };

    $scope.printContract = function(){
        $rootScope.print($scope.client_contract.type.clauses);
    };

    $scope.confirmUpdate = function() {
        if( $scope.client_contract.type.value_type == 'manual' ) {
            $client_contract.updateValue($scope.client_contract.id, $scope.client_contract).then(function(response){
                WizardHandler.wizard().next();
                $scope.client_contract.value = $scope.client_contract.total;
            }, function(error) {
                ngToast.danger({
                    content: 'Preencha o valor corretamente',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        } else {
            WizardHandler.wizard().next();
        }

        $scope.saveData();
    }



    $scope.changeCharges = function() {
        var tax = _.where($scope.client_contract.taxes, {installments: $scope.client_contract.charges})[0];
        $scope.client_contract.total = tax.total;
    }

    $scope.checkout = function() {
        $scope.waiting = true;
        $client_contract.checkout($scope.client_contract.id, $scope.client_contract).then(function(response){
            $scope.waiting = false;
            $scope.client_contract = response;
            ngToast.success({
                content: 'Compra realizada com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });

        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao realizar compra. Tente outro cartão ou entre em contato com sua operadora',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });

    }
}]);

angular.module('contract_type').
controller('contract_type.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$contract_type', function($scope, $http, $auth, $q, NgTableParams, $contract_type) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $contract_type.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('contract_type').
controller('contract_type.create', ['$scope', '$state', '$contract_type', function($scope, $state, $contract_type) {

    $scope.options = {
        language: 'pt',
        name: 'clauses'
    };

    $scope.contract_type = {
        has_additional_financial : 0,
        number_additional_financial : 1,
        make_financial_prepaid : 0
    };

    $scope.addTag = function(tag){
        var editor = CKEDITOR.instances.clauses;
        editor.insertText(tag);
    }

    $scope.save = function() {
        $scope.waiting = true;
        $contract_type.save($scope.contract_type).$promise.then(function(data) {
            $scope.waiting = false;
            $state.go('contract_type_show', {
                id: data.id
            });
        }, function(error){
            $scope.waiting = false;
        });
    }

    $scope.addTax = function(){
        if(!$scope.contract_type.taxes)
            $scope.contract_type.taxes = [];

        $scope.contract_type.taxes.push({
            'installments'  : $scope.contract_type.taxes.length + 2,
            'tax'           : 0
        });
    }

    $scope.removeTax = function(){
        if( $scope.contract_type.taxes.length > 0 )
            $scope.contract_type.taxes.splice(($scope.contract_type.taxes.length - 1), 1);
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('contract_type').
controller('contract_type.show', ['$state', '$scope', '$stateParams', '$uibModal', '$contract_type', function($state, $scope, $stateParams, $uibModal, $contract_type) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $contract_type.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.contract_type = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.addTag = function(tag){
        var editor = CKEDITOR.instances.clauses;
        editor.insertText(tag);
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$contract_type', function($scope, $uibModalInstance, $contract_type) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var contract_type = $scope.$parent.contract_type;

                $scope.confirm = function() {
                    $contract_type.update({
                        id: contract_type.id
                    }, contract_type).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$contract_type', function($state, $scope, $uibModalInstance, $contract_type) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var contract_type = $scope.$parent.contract_type;

                $scope.confirm = function() {
                    $contract_type.delete({id:contract_type.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('contract_type');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.addTax = function(){
        if(!$scope.contract_type.taxes)
            $scope.contract_type.taxes = [];

        $scope.contract_type.taxes.push({
            'installments'  : $scope.contract_type.taxes.length + 2,
            'tax'           : 0
        });
    }

    $scope.removeTax = function(){
        if( $scope.contract_type.taxes.length > 0 )
            $scope.contract_type.taxes.splice(($scope.contract_type.taxes.length - 1), 1);
    }
}]);


/**
 * Factory
 */
angular.module('contract_type').
factory("$contract_type", function($resource, $http, $q) {
    var resource = $resource(api + 'contract_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getOne = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'contract_type/get_one', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
