angular.module('client_contract', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('client_contract').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('client_contract', {
        url         : "/client_contract",
        templateUrl : server+"/views/m/client_contract",
        controller  : 'client_contract.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'ClientContract'
        }
    })

    .state('client_contract_create', {
        url         : "/client_contract/create",
        templateUrl : server+"/views/m/client_contract/create",
        controller  : 'client_contract.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client_contract',
            label: 'Adicionar'
        }
    })

    .state('client_contract_show', {
        url         : "/client_contract/:id",
        templateUrl : server+"/views/m/client_contract/show",
        controller  : 'client_contract.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client_contract',
            label: '{{client_contract.id}}'
        }
    })

}]);


/**
 * List Controller
 */
angular.module('client_contract').
controller('client_contract.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$client_contract', function($scope, $http, $auth, $q, NgTableParams, $client_contract) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client_contract.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('client_contract').
controller('client_contract.create', ['$scope', '$rootScope', '$state', '$client_contract', function($scope, $rootScope, $state, $client_contract) {

    //dates definitions
    $scope.dates = {
      start_date: moment().startOf('month').toDate(),
      deadline: moment().endOf('month').toDate()
    }

    //open false in start_date
    $scope.start_date = {
        opened: false
    };

    //open false in start_date
    $scope.deadline = {
        opened: false
    };

    $scope.save = function() {
        $client_contract.save($scope.client_contract).$promise.then(function(data) {
            $state.go('client_contract_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('client_contract').
controller('client_contract.show', ['$state', '$scope', '$stateParams', '$uibModal', '$client_contract', function($state, $scope, $stateParams, $uibModal, $client_contract) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $client_contract.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.client_contract = data.client_contract;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client_contract', function($scope, $uibModalInstance, $client_contract) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var client_contract = $scope.$parent.client_contract;

                $scope.confirm = function() {
                    $client_contract.update({
                        id: client_contract.id
                    }, client_contract).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_contract', function($state, $scope, $uibModalInstance, $client_contract) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client_contract = $scope.$parent.client_contract;

                $scope.confirm = function() {
                    $client_contract.delete({id:client_contract.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('client_contract');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('client_contract').
factory("$client_contract", function($resource, $q, $http) {
    var resource = $resource(api + 'client_contract/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.confirm = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'client_contract/'+id+'/confirm').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkClient = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'client_contract/check_client', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateClient = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'client_contract/'+id+'/update_client', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateValue = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'client_contract/'+id+'/update_value', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkout = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'client_contract/'+id+'/checkout', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.report = function (data) {
        var deferred = $q.defer();
        $http.get(api + 'client_contract/report', {
            params: data
        }).then(function (response) {
            deferred.resolve(response.data);
        }, function (response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.finishContract = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'client_contract/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
