angular.module('user_role', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('user_role').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('user_role', {
        url         : "/user_role",
        templateUrl : server+"/views/m/user_role",
        controller  : 'user_role.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Cargos'
        }
    })

    .state('user_role_create', {
        url         : "/user_role/create",
        templateUrl : server+"/views/m/user_role/create",
        controller  : 'user_role.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'user_role',
            label: 'Adicionar'
        }
    })

    .state('user_role_show', {
        url         : "/user_role/:id",
        templateUrl : server+"/views/m/user_role/show",
        controller  : 'user_role.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'user_role',
            label: '{{user_role.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('user_role').
controller('user_role.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$user_role', function($scope, $http, $auth, $q, NgTableParams, $user_role) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $user_role.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('user_role').
controller('user_role.create', ['$scope', '$state', '$user_role', function($scope, $state, $user_role) {
    $scope.save = function() {
        $user_role.save($scope.user_role).$promise.then(function(data) {
            $state.go('user_role_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('user_role').
controller('user_role.show', ['$state', '$scope', '$stateParams', '$uibModal', '$user_role', function($state, $scope, $stateParams, $uibModal, $user_role) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $user_role.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.user_role = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$user_role', function($scope, $uibModalInstance, $user_role) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var user_role = $scope.$parent.user_role;

                $scope.confirm = function() {
                    $user_role.update({
                        id: user_role.id
                    }, user_role).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$user_role', function($state, $scope, $uibModalInstance, $user_role) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var user_role = $scope.$parent.user_role;

                $scope.confirm = function() {
                    $user_role.delete({id:user_role.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('user_role');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);

/**
 * Factory
 */
angular.module('user_role').
factory("$user_role", function($resource) {
    var resource = $resource(api + 'user_role/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});
