angular.module('anamnesis_type', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('anamnesis_type').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    
    $stateProvider
    .state('anamnesis_type', {
        url         : "/anamnesis_type",
        templateUrl : server+"/views/m/anamnesis_type",
        controller  : 'anamnesis_type.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'base_data',
            label: 'Modelos de anamnese'
        }
    })

    .state('anamnesis_type_create', {
        url         : "/anamnesis_type/create",
        templateUrl : server+"/views/m/anamnesis_type/create",
        controller  : 'anamnesis_type.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'anamnesis_type',
            label: 'Adicionar'
        }
    })

    .state('anamnesis_type_show', {
        url         : "/anamnesis_type/:id",
        templateUrl : server+"/views/m/anamnesis_type/show",
        controller  : 'anamnesis_type.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'anamnesis_type',
            label: '{{anamnesis_type.id}}'
        }
    })

}]);

angular.module('anamnesis_type').
controller('new_anamnesis_type', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$anamnesis_type', '$suggestion', '$uibModalInstance', '$stateParams', '$client_anamnesis',
function($scope, $http, $auth, $q, NgTableParams, $anamnesis_type, $suggestion, $uibModalInstance, $stateParams, $client_anamnesis) {

    $scope.success_text = 'Salvar';
    $scope.cancel_text = 'Cancelar';
    $scope.types = [];
    $scope.document = {
        client_id :  $stateParams.id,
        answers : []
    };

    $anamnesis_type.query({count:100}).$promise.then(function(data) {
        $scope.types = data.data
    });

    $scope.selectType = function(doc_type){
        $scope.document.answers = doc_type.questions_list;
        $scope.document.type = doc_type;
    }

    $scope.getCidSuggestions = function(){
        $scope.cidSuggestionsList =[];
        $suggestion.query({type: 'cidDental'}).$promise.then(function(response){
            $scope.cidSuggestionsList = response;
        });
    }
    $scope.getCidSuggestions();

    $scope.confirm = function() {
        console.log($scope.document);
        $client_anamnesis.save($scope.document).$promise.then(function(response) {
            $uibModalInstance.close(true);
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

angular.module('anamnesis_type').
controller('anamnesis_type.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$anamnesis_type', 
function($scope, $http, $auth, $q, NgTableParams, $anamnesis_type) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $anamnesis_type.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('anamnesis_type').
controller('anamnesis_type.create', ['$scope', '$state', '$anamnesis_type', function($scope, $state, $anamnesis_type) {

    $scope.addingQuestion = false;
    $scope.options = {
        language: 'pt',
        name: 'clauses'
    };

    $scope.anamnesis_type = {
        questions_list : []
    };
    $scope.newQuestion = {
        position : 0
    }

    $scope.addQuestion = function(type){
        if( type == 'question' ){
            $scope.newQuestion = {
                position : $scope.anamnesis_type.questions_list.length
            }
            $scope.addingQuestion = !$scope.addingQuestion;
        }
        if( type == 'session' ){
            $scope.newQuestion = {
                position : $scope.anamnesis_type.questions_list.length,
                value_type : 'session'
            }
            $scope.addingSession = !$scope.addingSession;
        }
    }

    $scope.saveNewQuestion = function(){
        $scope.anamnesis_type.questions_list.push(
            $scope.newQuestion
        );
        $scope.addQuestion($scope.newQuestion.value_type == 'session' ? 'session' : 'question' );
    }

    $scope.save = function() {
        $scope.waiting = true;
        $anamnesis_type.save($scope.anamnesis_type).$promise.then(function(data) {
            $scope.waiting = false;
            $state.go('anamnesis_type_show', {
                id: data.id
            });
        }, function(error){
            $scope.waiting = false;
        });
    }

    $scope.reorder = function(flow, position){
        if( flow == 'up'){
            if( position == 0 )
                return;
            var indexComponentUp    = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == parseInt(position) - 1);
            var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);

            $scope.anamnesis_type.questions_list[indexComponentUp].position    = parseInt($scope.anamnesis_type.questions_list[indexComponentUp].position) + 1;
            $scope.anamnesis_type.questions_list[indexComponent].position      = parseInt($scope.anamnesis_type.questions_list[indexComponent].position) - 1;
        }
        if( flow == 'down'){
            var indexComponentDown  = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == parseInt(position) + 1);
            var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);

            if( indexComponentDown == -1 )
                return;

            $scope.anamnesis_type.questions_list[indexComponentDown].position = parseInt($scope.anamnesis_type.questions_list[indexComponentDown].position) - 1;
            $scope.anamnesis_type.questions_list[indexComponent].position = parseInt($scope.anamnesis_type.questions_list[indexComponent].position) + 1;
        }

        $scope.anamnesis_type.questions_list =  $scope.anamnesis_type.questions_list.sort(function (a, b) { return a.position < b.position ? -1 : 1; });
    }

    $scope.removeComponent = function(position){
        var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);
        $scope.anamnesis_type.questions_list.forEach((item, i) => {
            if(item.position > position){
                item.position = item.position - 1;
            }
        });
        $scope.anamnesis_type.questions_list.splice(indexComponent, 1);
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('anamnesis_type').
controller('anamnesis_type.show', ['$state', '$scope', '$stateParams', '$uibModal', '$anamnesis_type', function($state, $scope, $stateParams, $uibModal, $anamnesis_type) {

    $scope.editing = false;
    $scope.addingSession = false;
    $scope.addingQuestion = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.addQuestion = function(type){
        if( type == 'question' ){
            $scope.newQuestion = {
                position : $scope.anamnesis_type.questions_list.length
            }
            $scope.addingQuestion = !$scope.addingQuestion;
        }
        if( type == 'session' ){
            $scope.newQuestion = {
                position : $scope.anamnesis_type.questions_list.length,
                value_type : 'session'
            }
            $scope.addingSession = !$scope.addingSession;
        }
    }

    $scope.saveNewQuestion = function(){
        $scope.anamnesis_type.questions_list.push(
            $scope.newQuestion
        );
         $scope.addQuestion($scope.newQuestion.value_type == 'session' ? 'session' : 'question' );
    }

    $anamnesis_type.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.anamnesis_type = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$anamnesis_type', function($scope, $uibModalInstance, $anamnesis_type) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var anamnesis_type = $scope.$parent.anamnesis_type;

                $scope.confirm = function() {
                    $anamnesis_type.update({
                        id: anamnesis_type.id
                    }, anamnesis_type).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$anamnesis_type', function($state, $scope, $uibModalInstance, $anamnesis_type) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var anamnesis_type = $scope.$parent.anamnesis_type;

                $scope.confirm = function() {
                    $anamnesis_type.delete({id:anamnesis_type.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('anamnesis_type');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.reorder = function(flow, position){
        if( flow == 'up'){
            if( position == 0 )
                return;
            var indexComponentUp    = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == parseInt(position) - 1);
            var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);

            $scope.anamnesis_type.questions_list[indexComponentUp].position    = parseInt($scope.anamnesis_type.questions_list[indexComponentUp].position) + 1;
            $scope.anamnesis_type.questions_list[indexComponent].position      = parseInt($scope.anamnesis_type.questions_list[indexComponent].position) - 1;
        }
        if( flow == 'down'){
            var indexComponentDown  = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == parseInt(position) + 1);
            var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);

            if( indexComponentDown == -1 )
                return;

            $scope.anamnesis_type.questions_list[indexComponentDown].position = parseInt($scope.anamnesis_type.questions_list[indexComponentDown].position) - 1;
            $scope.anamnesis_type.questions_list[indexComponent].position = parseInt($scope.anamnesis_type.questions_list[indexComponent].position) + 1;
        }

        $scope.anamnesis_type.questions_list =  $scope.anamnesis_type.questions_list.sort(function (a, b) { return a.position < b.position ? -1 : 1; });
    }

    $scope.removeComponent = function(position){
        var indexComponent      = $scope.anamnesis_type.questions_list.findIndex((row) => row.position == position);
        $scope.anamnesis_type.questions_list.forEach((item, i) => {
            if(item.position > position){
                item.position = item.position - 1;
            }
        });
        $scope.anamnesis_type.questions_list.splice(indexComponent, 1);
    }
}]);


/**
 * Factory
 */
angular.module('anamnesis_type').
factory("$anamnesis_type", function($resource, $http, $q) {
    var resource = $resource(api + 'anamnesis_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});

angular.module('anamnesis_type').
factory("$client_anamnesis", function($resource, $http, $q) {
    var resource = $resource(api + 'client_anamnesis/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.print = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'client_anamnesis/'+id+'/print').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
