angular.module('receipt_type', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('receipt_type').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('receipt_type', {
        url         : "/receipt_type",
        templateUrl : server+"/views/m/receipt_type",
        controller  : 'receipt_type.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'base_data',
            label: 'Tipos de recibos'
        }
    })

    .state('receipt_type_create', {
        url         : "/receipt_type/create",
        templateUrl : server+"/views/m/receipt_type/create",
        controller  : 'receipt_type.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'receipt_type',
            label: 'Adicionar'
        }
    })

    .state('receipt_type_show', {
        url         : "/receipt_type/:id",
        templateUrl : server+"/views/m/receipt_type/show",
        controller  : 'receipt_type.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'receipt_type',
            label: '{{receipt_type.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('receipt_type').
controller('receipt_type.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$receipt_type', function($scope, $http, $auth, $q, NgTableParams, $receipt_type) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $receipt_type.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('receipt_type').
controller('receipt_type.create', ['$scope', '$state', '$receipt_type', function($scope, $state, $receipt_type) {

    $scope.options = {
        language: 'pt',
        name: 'clauses'
    };

    $scope.receipt_type = {
        has_additional_financial : 0,
        number_additional_financial : 1
    };

    $scope.addTag = function(tag){
        var editor = CKEDITOR.instances.clauses;
        editor.insertText(tag);
    }

    $scope.save = function() {
        $receipt_type.save($scope.receipt_type).$promise.then(function(data) {
            $state.go('receipt_type_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('receipt_type').
controller('receipt_type.show', ['$state', '$scope', '$stateParams', '$uibModal', '$receipt_type', function($state, $scope, $stateParams, $uibModal, $receipt_type) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $receipt_type.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.receipt_type = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.addTag = function(tag){
        var editor = CKEDITOR.instances.clauses;
        editor.insertText(tag);
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$receipt_type', function($scope, $uibModalInstance, $receipt_type) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var receipt_type = $scope.$parent.receipt_type;

                $scope.confirm = function() {
                    $receipt_type.update({
                        id: receipt_type.id
                    }, receipt_type).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$receipt_type', function($state, $scope, $uibModalInstance, $receipt_type) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var receipt_type = $scope.$parent.receipt_type;

                $scope.confirm = function() {
                    $receipt_type.delete({id:receipt_type.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('receipt_type');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('receipt_type').
factory("$receipt_type", function($resource) {
    var resource = $resource(api + 'receipt_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
