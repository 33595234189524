angular.module('digital_account', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('digital_account').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('digital_account', {
        url         : "/digital",
        templateUrl : server+"/views/m/digital_account",
        controller  : 'digital_account.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Financeiro'
        }
    })

}]);

/**
* List Controller
*/
angular.module('digital_account')
.controller('digital_account.list', ['$scope', '$state', '$localStorage', '$http', '$auth', '$q', '$uibModal', '$digital_account', '$client', '$users', '_', 'ngToast', '$rootScope', '$company', '$invoice',
function($scope, $state, $localStorage, $http, $auth, $q, $uibModal, $digital_account, $client, $users, _, ngToast, $rootScope, $company, $invoice) {

    $scope.today                = new moment().startOf('month');
    $scope.real_today           = angular.copy($scope.today);
    $scope.dates                = [];
    $scope.filter               = {
        day             : 0,
        payment_type    : null,
        status          : null
    };

    $scope.switchPainel = function(){
        $state.go('financial_entry');
    }

    $scope.getBalance = function(){
        $scope.loading = true;
        var params = {
            date    : $scope.today.toDate()
        };
        if( $scope.filter.payment_type )
            params.payment_type = $scope.filter.payment_type;
        if( $scope.filter.status )
            params.status = $scope.filter.status;
        $company.getBalance(params).then(function(response){
            // $scope.transfers        = response.transfers;
            // $scope.receivables      = response.receivables;
            $scope.transactions     = response.transactions;
            // $scope.totals           = response.totals;

            $scope.createDaysOfMonth();
            $scope.loading = false;
        }, function(error){
            $scope.loading = false;
        });
    }
    $scope.getBalance();
    $scope.selectedMonth = function(int){
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.getBalance();
    }

    $scope.filterEntries = function(data, type) {
        var filtered = _.sortBy(data, function(row){
            return type == 'credit' ? moment(row.transacted_at).format('YYYYMMDD') : moment(row.transfer_date).format('YYYYMMDD');
        });

        if( $scope.filter.day != 0 ){
            filtered = _.filter(filtered, function(row){ return parseInt( type == 'credit' ? moment(row.transacted_at).format('DD') : moment(row.transfer_date).format('DD') ) == $scope.filter.day;});
        }

        return filtered;
    }

    $scope.filterTotal = function(data, bFee){
        return _.reduce(data, function(memo, row){
            if( (row.status == 'succeeded' || row.status == 'pending') && row.amount ){
                return memo + parseFloat(row.amount - (bFee ? row.fees : 0));
            } else {
                return memo;
            }
        }, 0);
    }

    $scope.changeDayFilter = function(day){
        $scope.filter.day = $scope.filter.day == day ? 0 : day;
        $scope.createDaysOfMonth();
    }

    $scope.changeTab = function(){
        $scope.createDaysOfMonth();
    }

    $scope.createDaysOfMonth = function(){
        $scope.today= moment($scope.today);

        $scope.dates = [];
        for (var i = 1; i <= $scope.today.daysInMonth(); i++) {
            switch (i) {
                case parseInt($scope.filter.day):
                    $scope.dates.push({
                        day: i,
                        class: 'selected'
                    });
                break;

                case parseInt($scope.real_today.format('DD')):
                    $scope.dates.push({
                        day: i,
                        class: 'current'
                    });
                break;

                case _.filter($scope.transactions, function(entry){return moment(entry.transacted_at).format('DD') == i}).length > 0 ? i : 0:
                    $scope.dates.push({
                        day: i,
                        class: 'success'
                    });
                break;

                // case _.filter($scope.transfers, function(entry){return moment(entry.transfer_date).format('DD') == i}).length > 0 ? i : 0:
                //     $scope.dates.push({
                //         day: i,
                //         class: 'danger'
                //     });
                // break;

                default:
                    $scope.dates.push({
                        day: i,
                        class: ''
                    });
            }
        }
    }

    $scope.makeTransfer = function(){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'make_transfer.modal.html',
            controller      : 'transfer.create',
            size            : 'sm',
            resolve         : {
                total : $scope.totals.current_balance
            }
        });

        modalInstance.result.then(function() {
            $scope.getBalance();
        });
    }
}]);

angular.module('digital_account').
controller('transfer.create', ['$state', '$scope', '$stateParams', '$uibModal', '$service', '$department', '$localStorage', '$uibModalInstance', '$templateCache', '$company', 'ngToast', 'company',
function($state, $scope, $stateParams, $uibModal, $service, $department, $localStorage, $uibModalInstance, $templateCache, $company, ngToast, company) {

    $scope.waiting = false;

    $scope.title        = 'Transferir';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Salvar';

    $scope.params = {
        account_id      : null,
        password        : '',
        amount          : 0,
        description     : ''
    }

    $scope.company = company;

    $scope.load = function() {
        $scope.waiting = true;
        $company.gatewayBalance($scope.company.id).then(function(response){
            $scope.total = response.current_balance;
            $company.getBanks($scope.company.id).then(function(response){
                if( Array.isArray(response) ) {
                    $scope.banks = response;
                    $scope.params.account_id = response[response.length-1].id;
                }
                // $scope.waiting = false;
            }, function(error) {
                // $scope.waiting = false;
            });
        }, function(error) {
            // $scope.waiting = false;
        });
    }
    $scope.load();


    $scope.confirm = function(){
        $scope.$parent.waiting = true;

        $company.makeTransfer($scope.params, $scope.company.id).then(function(response){
            $scope.$parent.waiting = false;
            ngToast.success({
                content: 'Transferencia realizada',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $uibModalInstance.close();
        }, function(error){
            $scope.$parent.waiting = false;
            ngToast.danger({
                content: 'Transferencia não realizada, entre em contato com o suporte',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

}]);


angular.module('digital_account').
factory("$digital_account", function($resource, $q, $http) {
    var resource = $resource(api + 'digital_account/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});
