angular.module('base_data', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('base_data').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('base_data', {
        url         : "/base_data",
        templateUrl : server+"/views/m/base_data",
        controller  : 'base_data.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Cadastro base'
        }
    });

}]);

angular.module('base_data').
controller('base_data.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$uibModal',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $uibModal) {

}]);