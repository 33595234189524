angular.module('tax_regime_status', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('tax_regime_status').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('tax_regime_status', {
        url         : "/tax_regime_status",
        templateUrl : server+"/views/m/tax_regime_status",
        controller  : 'tax_regime_status.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Situação do Regime tributário'
        }
    })

    .state('tax_regime_status_create', {
        url         : "/tax_regime_status/create",
        templateUrl : server+"/views/m/tax_regime_status/create",
        controller  : 'tax_regime_status.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'tax_regime_status',
            label: 'Adicionar'
        }
    })

    .state('tax_regime_status_show', {
        url         : "/tax_regime_status/:id",
        templateUrl : server+"/views/m/tax_regime_status/show",
        controller  : 'tax_regime_status.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'tax_regime_status',
            label: '{{tax_regime_status.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('tax_regime_status').
controller('tax_regime_status.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$tax_regime_status', function($scope, $http, $auth, $q, NgTableParams, $tax_regime_status) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $tax_regime_status.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('tax_regime_status').
controller('tax_regime_status.create', ['$scope', '$state', '$tax_regime_status', function($scope, $state, $tax_regime_status) {
    $scope.save = function() {
        $tax_regime_status.save($scope.tax_regime_status).$promise.then(function(data) {
            $state.go('tax_regime_status_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('tax_regime_status').
controller('tax_regime_status.show', ['$state', '$scope', '$stateParams', '$uibModal', '$tax_regime_status', function($state, $scope, $stateParams, $uibModal, $tax_regime_status) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $tax_regime_status.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.tax_regime_status = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$tax_regime_status', function($scope, $uibModalInstance, $tax_regime_status) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var tax_regime_status = $scope.$parent.tax_regime_status;

                $scope.confirm = function() {
                    $tax_regime_status.update({
                        id: tax_regime_status.id
                    }, tax_regime_status).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$tax_regime_status', function($state, $scope, $uibModalInstance, $tax_regime_status) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var tax_regime_status = $scope.$parent.tax_regime_status;

                $scope.confirm = function() {
                    $tax_regime_status.delete({id:tax_regime_status.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('tax_regime_status');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('tax_regime_status').
factory("$tax_regime_status", function($resource) {
    var resource = $resource(api + 'tax_regime_status/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
