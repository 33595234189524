angular.module('report', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('report').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('report', {
        url         : "/report",
        templateUrl : server+"/views/m/report",
        controller  : 'report.general',
        abstract    : true,
        resolve     : {
            loginRequired: loginRequired
        }
    })

    .state('report.dashboard', {
        url         : "/dashboard",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/dashboard",
                controller  : 'report.dashboard'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Painel'
        }
    })

    .state('report.clients', {
        url         : "/clients",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/clients",
                controller  : 'report.clients'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de clientes'
        }
    })

    .state('report.protocols', {
        url         : "/protocols",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/protocols",
                controller  : 'report.protocols'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de Ordens de Serviço'
        }
    })

    .state('report.services', {
        url         : "/services",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/services",
                controller  : 'report.services'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de Ordens de Serviço'
        }
    })

    .state('report.contracts', {
        url         : "/contracts",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/contracts",
                controller  : 'report.contracts'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de contratos'
        }
    })

    .state('report.financial', {
        url         : "/financial",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/financial",
                controller  : 'report.financial'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório financeiro'
        }
    })

    .state('report.sale', {
        url         : "/rsale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/sale",
                controller  : 'report.sale'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas'
        }
    })

    .state('report.dialy_sale', {
        url         : "/dsale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/dialy_sale",
                controller  : 'report.dialy_sale'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas diárias'
        }
    })

    .state('report.category_sale', {
        url         : "/csale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/category_sale",
                controller  : 'report.category_sale'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas por categorias'
        }
    })

    .state('report.sale_seller', {
        url         : "/ssale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/sale_seller",
                controller  : 'report.sale_seller'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas por Vendedor'
        }
    })

    .state('report.sale_client', {
        url         : "/clientesale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/sale_client",
                controller  : 'report.sale_client'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas por Cliente'
        }
    })

    .state('report.stock', {
        url         : "/rstock",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/stock",
                controller  : 'report.stock'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Inventário'
        }
    })

    .state('report.payments_sale', {
        url         : "/psale",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/payments_sale",
                controller  : 'report.payments_sale'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de vendas por tipo de pagamento'
        }
    })

    .state('report.invoice', {
        url         : "/rinvoice",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/invoice",
                controller  : 'report.invoice'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de notas'
        }
    })

    .state('report.class_group', {
        url         : "/rclassgroup",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/class_group",
                controller  : 'report.class_group'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de turmas'
        }
    })

    .state('report.sale_seller_commission', {
        url         : "/ssalecommission",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/sale_seller_commission",
                controller  : 'report.sale_seller_commission'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de comissão de vendas por Vendedor'
        }
    })

    .state('report.purchase', {
        url         : "/rpurchase",
        resolve     : {
            loginRequired: loginRequired
        },
        views : {
            reports: {
                templateUrl : server+"/views/m/report/purchase",
                controller  : 'report.purchase'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de compras'
        }
    })

}]);

/**
 * Geral
 */
angular.module('report').
controller('report.general', ['$rootScope','$scope', '$http', '$q', function($rootScope, $scope, $http, $q) {
}]);

/**
 * Dashboard
 */
angular.module('report').
controller('report.dashboard', ['$scope', '$http', '$q', '$process','NgTableParams','$client', function($scope, $http, $q, $process,NgTableParams, $client) {

    $scope.client_status_id = 3;

    $scope.clientParams = new NgTableParams({
        page: 1,
        count: 15,
        filter: {'clients.client_status_id': $scope.client_status_id}
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.toggleClientStatus = function(status)
    {
        $scope.client_status_id = status;
        if(status){
            $scope.clientParams.filter()['clients.client_status_id'] = status;
        } else {
            delete $scope.clientParams.filter()['clients.client_status_id'];
        }

    };

    $scope.exportSpreadsheet = function()
    {
        $http.get(api+'client/export?status='+$scope.client_status_id).then(function(response){
            window.open(response.data,'_blank');
        });
    }

    $scope.loadDashboard = function(){
        $http.get(api+'report/tiles').then(function(response){
            $scope.tiles = response.data;
        });

        // $http.get(api+'report/client_count').then(function(response){
        //     $scope.client_count = response.data;
        // });
    }

    $scope.loadDashboard();
}]);


/**
 * List Controller
 */
angular.module('report').
controller('report.clients', ['$rootScope', '$scope', '$http', '$q', '$process','NgTableParams','$client', '$client_status', '$tax_regime', '$tax_regime_status', 'ngToast', '$localStorage',
function($rootScope, $scope, $http, $q, $process, NgTableParams, $client, $client_status, $tax_regime, $tax_regime_status, ngToast, $localStorage ) {

    $scope.client_status_id = 3;
    $scope.printing = false;

    $scope.filter = {
        client: true,
        client_status_id: '',
        status: 1,
        tax_regime_id: '',
        tax_regime_status_id: ''
    };

    $scope.client_status_id = {
        id: ''
    }

    $scope.tax_regime_id = {
        id: ''
    };

    $scope.tax_regime_status_id = {
        id: ''
    }

    $scope.status = {
        id: 1
    };

    $scope.clientStatusEvents = {
        onItemSelect: function(item)
        {
            $scope.filter.client_status_id = item.id;
        }
    }

    $scope.taxEvents = {
        onItemSelect: function(item)
        {
            $scope.filter.tax_regime_id = item.id;
        }
    }

    $scope.taxStatusEvents = {
        onItemSelect: function(item)
        {
            $scope.filter.tax_regime_status_id = item.id;
        }
    }

    $scope.statusEvents = {
        onItemSelect: function(item)
        {
            $scope.filter.status = item.id;
        }
    }


    $scope.clientStatusConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: true,
        showUncheckAll: false,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.taxConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: true,
        showUncheckAll: false,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.taxStatusConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: true,
        showUncheckAll: false,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.statusConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: true,
        showUncheckAll: true,
        displayProp: 'label',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.clientParams = new NgTableParams({
        page: 1,
        count: 15,
        filter: $scope.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                $scope.loadTotals();
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.loadTotals = function(){
        var params = {
            'table' : 'Client',
            'field' : 'monthly_payment',
            'params'    : '[{"field" : "status","value" : "1"}]'
        }
        $http.get(api+'report/calc_total', {params: params}).then(function(response){
            $scope.calcmonthly = response.data;
        });
    }

    $scope.exportSpreadsheet = function()
    {
        $http.get(api+'client/export',{params:$scope.filter}).then(function(response){
            let data = response.data;
            let blob = new Blob([data], { type: 'text/csv' });
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', $localStorage.company.id+'-Clientes.csv');
            a.click()
        });
    }

    $scope.print = function(){

        $scope.printing = true;
        var filter = angular.copy($scope.filter);
        $http.get(api+'client/print', {params:filter}).then(function(response){
            $scope.printing = false;

            $rootScope.print(response.data);

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $client_status.query({count:1000}).$promise.then(function(response)
    {
        response.data.unshift({
            id:'',
            name: 'Todos'
        });
        $scope.client_status = response.data;
    });

    $tax_regime.query({count:1000}).$promise.then(function(response)
    {
        response.data.unshift({
            id:'',
            name: 'Todos'
        });
        $scope.tax_regime = response.data;
    });

    $tax_regime_status.query({count:1000}).$promise.then(function(response)
    {
        response.data.unshift({
            id:'',
            name: 'Todos'
        });
        $scope.tax_regime_status = response.data;
    });
}]);

/**
 * List Controller
 */
angular.module('report').
controller('report.protocols', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$client', '$users', '$window', '$department', 'ngToast', '$localStorage', '$rootScope',
function($scope, $http, $q, $process, NgTableParams, $client, $users,$window, $department, ngToast, $localStorage, $rootScope) {

    if( !$localStorage.reportProtocol ){
        $localStorage.reportProtocol = {
            page : 1,
            count : 15,
            filter : {
                status: 'finished',
                client: '',
                user: '',
                department : '',
                start_date: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
            }
        }
    }

    $scope.status = $localStorage.reportProtocol.filter.status ? {
        id: $localStorage.reportProtocol.filter.status
    } : {};
    $scope.client       = $localStorage.reportProtocol.filter.client ? {
        id: $localStorage.reportProtocol.filter.client
    } : {};
    $scope.user         = $localStorage.reportProtocol.filter.user ? {
        id: $localStorage.reportProtocol.filter.user
    } : {};
    $scope.department   = $localStorage.reportProtocol.filter.department ? {
        id: $localStorage.reportProtocol.filter.department
    } : {};

    $scope.dates = {
      start_date : moment($localStorage.reportProtocol.filter.start_date).toDate(),
      end_date: moment($localStorage.reportProtocol.filter.end_date).toDate()
    }

    $scope.printing = false;

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.protocol_statuses = [
        {
            id: 'canceled',
            label: 'Canceladas'
        },
        {
            id: 'open',
            label: 'Abertas'
        },
        {
            id: 'in_progress',
            label: 'Em andamento'
        },
        {
            id: 'discount_request',
            label: 'Desconto solicitado'
        },
        {
            id: 'discount_granted',
            label: 'Desconto liberado'
        },
        {
            id: 'discount_refused',
            label: 'Desconto recusado'
        },
        {
            id: 'confirmed',
            label: 'Execução confirmada'
        },
        {
            id: 'finished',
            label: 'Finalizada'
        }
    ];

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.statusConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'label',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $client.query({count:1000, client: true}).$promise.then(function(response){
        $scope.selectClients = response.data;
    });

    $users.query({count:1000}).$promise.then(function(response){
        $scope.selectUsers = response.data;
    });

    $department.query({count:1000}).$promise.then(function(reponse){
        $scope.selectDepartment = reponse.data;
    });

    $scope.protocolParams = new NgTableParams({
        page: $localStorage.reportProtocol.page,
        count: $localStorage.reportProtocol.count,
        filter: $localStorage.reportProtocol.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {

            return $http.get(api+'report/protocols', { params: params.url() }).then(function(response){
                $localStorage.reportProtocol = params._params;
                $scope.users   = response.data.users;
                $scope.clients = response.data.clients;
                $scope.total   = response.data.total[0];

                params.total(response.data.protocols.total);
                return response.data.protocols.data;
            });
        }
    });

    $scope.loadDashboard = function(){
        $localStorage.reportProtocol.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD HH:mm:ss');
        $localStorage.reportProtocol.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD HH:mm:ss');
        if( $scope.status.id ){
            $localStorage.reportProtocol.filter.status = $scope.status.id;
        }else{
            $localStorage.reportProtocol.filter.status = '';
        }
        if( $scope.client.id ){
            $localStorage.reportProtocol.filter.client = $scope.client.id;
        }else{
            $localStorage.reportProtocol.filter.client = '';
        }
        if( $scope.user.id ){
            $localStorage.reportProtocol.filter.user = $scope.user.id;
        }else{
            $localStorage.reportProtocol.filter.user = '';
        }
        if( $scope.department.id ){
            $localStorage.reportProtocol.filter.department = $scope.department.id;
        }else{
            $localStorage.reportProtocol.filter.department = '';
        }

        $scope.protocolParams.reload();
    }

    $scope.print = function(){
        $scope.printing = true;

        $http.get(api+'protocol/report', {params:$localStorage.reportProtocol.filter}).then(function(response){

        $rootScope.print(response.data);

        $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

}]);

/**
 * List Controller
 */
angular.module('report').
controller('report.services', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', 'ngToast', '$localStorage', '$rootScope',
function($scope, $http, $q, $process, NgTableParams, $service,$window, ngToast,$localStorage, $rootScope) {

    $scope.filter = {
        status: 'finished',
        start_date: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
    };

    $scope.dates = {
      start_date: moment().startOf('month').toDate(),
      end_date: moment().endOf('month').toDate()
    }

    $scope.printing = false;

    $scope.status = {
        //id: 'finished'
    };

    $scope.service = {};

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.protocol_statuses = [
        {
            id: 'canceled',
            label: 'Canceladas'
        },
        {
            id: 'in_progress',
            label: 'Em andamento'
        },
        {
            id: 'discount_request',
            label: 'Desconto solicitado'
        },
        {
            id: 'discount_granted',
            label: 'Desconto liberado'
        },
        {
            id: 'discount_refused',
            label: 'Desconto recusado'
        },
        {
            id: 'confirmed',
            label: 'Execução confirmada'
        },
        {
            id: 'finished',
            label: 'Finalizada'
        }
    ];

    $service.query({count:1000}).$promise.then(function(response){
        $scope.services = response.data;
    });

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.statusConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: false,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'label',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.protocolParams = new NgTableParams({
        page: 1,
        count: 15,
        filter: $scope.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {

            return $http.get(api+'report/services', { params: params.url() }).then(function(response){
                $scope.valueTotal = response.data.valueTotal;
                params.total(response.data.protocols.total);
                $scope.total = response.data.total;
                return response.data.protocols.data;
            });
        }
    });
    $scope.protocolParams.reload();

    $scope.loadDashboard = function(){
        $scope.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD');
        $scope.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD');
        if( $scope.status.id ){
            $scope.filter.status = $scope.status.id;
        }else{
            $scope.filter.status = '';
        }
        if( $scope.service.id ){
            $scope.filter.service = $scope.service.id;
        }else{
            $scope.filter.service = '';
        }

        $scope.protocolParams.reload();
    }

    $scope.print = function(){
        $scope.printing = true;

        $http.get(api+'report/servicePrint',{params:$scope.filter}).then(function(response){
            $rootScope.print(response.data);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

}]);

angular.module('report').
controller('report.financial', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$financial_entry', '$financial_account', '$financial_category', '$client', 'ngToast', '$rootScope', 'showInModal', '$uibModalInstance',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $financial_entry, $financial_account, $financial_category, $client, ngToast, $rootScope, showInModal, $uibModalInstance) {

    $scope.showInModal = showInModal;
    $scope.financials = [];
    
    $scope.filter = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate(),
        account : {},
        category : {},
        contact : '',
        type : '',
        description : ''
    };

    $scope.today = moment();
    $scope.printing  = false;
    $scope.waiting   = false;
    $scope.viewChart = $scope.showInModal;
    $scope.today_opened = {
        opened: false
    }

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };


    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.contactConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $financial_account.query({count: 1000}).$promise.then(function(response){
        $scope.accounts = response.data;
    });
    $financial_category.query({count: 1000}).$promise.then(function(response){
        $scope.categories = response.data;
    });
    $client.query({count: 1000}).$promise.then(function(response){
        $scope.contacts = response.data;
    });

    $scope.loadDashboard = function(){
        
        $scope.waiting = true;
        $scope.chartDebit = {
            data : [],
            labels : []
        }
        $scope.chartCredit = {
            data : [],
            labels : []
        }

        $scope.colorsDebit = {
            colors : ['#f50202', '#f57c00', '#e57373', '#ffb74d']
        };

        $scope.colorsCredit = {
            colors : ['#04c421', '#0471c4', '#4fc9db', '#797f80']
        };

        var params = {
            start        : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end          : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            paid         : $scope.filter.paid
        }

        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;
        if( $scope.filter.type != '' )
            params.type                   = $scope.filter.type;
        if( $scope.filter.description != '' )
            params.description = $scope.filter.description;

        $financial_entry.query(params).$promise.then(function(response){
            $scope.financials           = response.data;
            $scope.total                = response.total;
            $scope.total_paid           = response.total_paid;
            $scope.total_debit          = response.total_debit;
            $scope.total_credit         = response.total_credit;
            $scope.total_debit_paid     = response.total_debit_paid;
            $scope.total_credit_paid    = response.total_credit_paid;
            


            params.type = 'D';
            $financial_entry.getCategoryToChart(params).then(function(response){
                
                $scope.dataDebit = response.data;
                $scope.chartDebit.series = $scope.dataDebit.types;
                $scope.chartDebit.labels = $scope.dataDebit.date;
                $scope.chartDebit.data   = $scope.dataDebit.totaisTipo;
                params.type = '';
    
                params.type = 'C';
                $financial_entry.getCategoryToChart(params).then(function(response){
                    $scope.dataCredit = response.data;
                    $scope.chartCredit.series = $scope.dataCredit.types;
                    $scope.chartCredit.labels = $scope.dataCredit.date;
                    $scope.chartCredit.data   = $scope.dataCredit.totaisTipo;
                    params.type = '';
                    $scope.waiting = false;

                    if (response.data) {
                        $scope.renderChart();
                    }
                    
                });
            });  
            
        });
        
        
    }

    $scope.renderChart = function(){
        if ($scope.newChart) {
            $scope.newChart.destroy();
        }
        $scope.chartFinancial = {
            series: [
            {
            name: 'Despesas',
            data: $scope.chartDebit.data[0]
            },
            {
            name: 'Receitas',
            data: $scope.chartCredit.data[0]
            }
            ],
            chart: {
            height: 450,
            width: '100%',
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 15,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            textAnchor: 'middle',
            enabled: true,
            formatter: function (val) {
              return new Intl.NumberFormat(`pt-BR`, {
                currency: `BRL`,
                style: 'currency',
            }).format(val);
            },
            offsetY: -20,
            style: {
                fontSize: '8px',
                colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: $scope.dataCredit.date,
            position: 'top',
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return "R$" + val;
              }
            }
          },
          };
        $scope.newChart = new ApexCharts(document.querySelector("#chartBar"), $scope.chartFinancial);
        $scope.newChart.render().then(()=>{$scope.waiting = false});
    }

    $scope.searchContact = function(obj) {
        $scope.filter.contact = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                $scope.filter.contact.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            }
        }
    }

    $scope.searchCategory = function(obj) {
        $scope.filter.category = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                console.log(obj.originalObject);
                $scope.filter.category.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputCategory');
            }
        }
    }


    $scope.applyFilter = function(filter)
    {
        if( $scope.filter.type != filter ){
            $scope.filter.type = filter;
        } else {
            $scope.filter.type = '';
        }
        $scope.loadDashboard();
    }

    $scope.filterEntries = function(data)
    {
        var filtered = _.sortBy(data, function(row){
            return moment(row.transacted_at).format('YYYYMMDD') + row.description;
        });
        return filtered;
    }

    $scope.showChart = function(){
        $scope.viewChart = !$scope.viewChart;
    }

    $scope.closeFinancialModal = function(){
        $uibModalInstance.close();
    }

    $scope.selectedMonth = function(int){
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.filter.start_date = $scope.today.startOf('month').toDate();
        $scope.filter.end_date = $scope.today.endOf('month').toDate();
        $scope.loadDashboard();
    }

    $scope.print = function(){
        if( $scope.printing ){
            return;
        }

        var params = {
            start   : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end     : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            paid    : $scope.filter.paid
        }

        if( $scope.filter.account.id )
            params.financial_account_id   = $scope.filter.account.id;
        if( $scope.filter.category.id )
            params.financial_category_id  = $scope.filter.category.id;
        if( $scope.filter.contact.id )
            params.contact_id             = $scope.filter.contact.id;
        if( $scope.filter.type != '' )
            params.type                   = $scope.filter.type;
        if( $scope.filter.description != '' )
            params.description = $scope.filter.description;

        $scope.printing = true;
        $http.post(api+'report/financial_print',params).then(function(response){
            $rootScope.print(response.data);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

}]);

angular.module('report').
controller('report.contracts', ['$scope', '$http', '$q', '$process', 'NgTableParams', '$service', '$window', '$client_contract', '$contract_type', '$client', 'ngToast', '$stateParams', '$uibModal',
function($scope, $http, $q, $process, NgTableParams, $service,$window, $client_contract, $contract_type, $client, ngToast, $stateParams, $uibModal) {

    $scope.filter = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate(),
        type : {},
        client : {},
        status : 'closed'
    };

    $scope.selectEvents = {
        onSelectionChanged: function(item) {
            $scope.loadDashboard();
        }
    }

    $scope.printing = false;

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $client.query({count: 1000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $contract_type.query().$promise.then(function(response){
        $scope.types = response.data;
    });

    $scope.searchContact = function(obj) {
        $scope.filter.client = {};
        if( obj ) {
            if( obj.originalObject && obj.originalObject.id ){
                $scope.filter.client.id = obj.originalObject.id;
            } else {
                $scope.$broadcast('angucomplete-alt:clearInput', 'inputContact');
            }
            $scope.loadDashboard();
        } else {
            setTimeout(function () {
                $scope.loadDashboard();
            }, 300);
        }
    }

    $scope.loadDashboard = function(){
        var params = angular.copy($scope.filter);
        params.count = 100000;
        params.start_date   = moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss');
        params.end_date     = moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss');

        if( $scope.filter.type.id )
            params.type_id       = $scope.filter.type.id;
        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        $client_contract.query(params).$promise.then(function(response){
            $scope.contracts = response.data;
        });
    }
    $scope.loadDashboard();

    $scope.getReport = function(){
        $client_contract.report().then(function(response){
            $scope.totalTypes = response.types;
        }, function(error){

        });
    }
    $scope.getReport();
}]);

angular.module('report').
controller('report.sale', ['$rootScope','$scope', '$http', '$q',  '$sale', '$service', '$client', '$users', 'showInModal', '$uibModalInstance', '$uibModal', 'company_id', 'dates', '$localStorage', 'ngToast',
function($rootScope, $scope, $http, $q, $sale, $service, $client, $users, showInModal, $uibModalInstance, $uibModal, company_id, dates, $localStorage, ngToast) {

    $scope.showInModal  = showInModal;
    $scope.title        = 'Vendas';
    $scope.waiting = true;
    $scope.userSaller =  false;
    $scope.decimals = $localStorage.company.decimals_param;

    $scope.user = $localStorage.user;

    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.printing = false;

    $scope.start_date        = { opened: false };
    $scope.end_date          = { opened: false };
    $scope.itemsFormated     = [];
    $scope.filter = {
        start_date  : moment().startOf('month').toDate(),
        end_date    : moment().endOf('month').toDate(),
        service     : {},
        client      : {},
        user_id     : {},
        seller_id   : {},
        orderBy     : 'count'
    }

    if( company_id ){
        $scope.filter.company_id = company_id;
    } else {
        delete $scope.filter.company_id;
    }

    if (dates) {
        $scope.filter.start_date = dates ? dates.start_date.toISOString() : moment().startOf('month').toDate();
        $scope.filter.end_date = dates ? dates.end_date.toISOString(): moment().endOf('month').toDate();
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $client.query({count:10000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $users.query({count:1000}).$promise.then(function(response){
        $scope.disabled = true;
        $scope.users = response.data;
    });

    if( _.find($scope.user.permissions, function(row){ return row.name == "vendedor"; }) ){
        $scope.userSaller =  true;
        $scope.filter.seller_id.id = $scope.user.id;
    }

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    $scope.loadDashboard = function () {
        $scope.waiting = true;
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy
        }


        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.company_id )
            params.company_id = $scope.filter.company_id;


        $sale.salesBalance(params).then(function(response){
            $scope.data = response;
            $scope.waiting = false;
            $scope.data.chartCategory = {
                data : [],
                labels : []
            }

            $scope.data.total = parseFloat(response.sale.total) + parseFloat(response.protocol.total);

            $scope.data.chartCategory.data = _.pluck($scope.data.sale.categories, 'total');
            $scope.data.chartCategory.labels = _.pluck($scope.data.sale.categories, 'name');

            $scope.itemsTop = $scope.data.sale.items;
        }, function(error) {
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao carregar dados',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }
    $scope.loadDashboard();

    $scope.showServicesSale = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/report/modal_show_services_sale",
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$sale',
            function($state, $scope, $uibModalInstance, $sale) {

                $scope.title = 'Produtos comprados na data: ' + moment($scope.filter.start_date).format('DD-MM-YYYY') + ' a ' +  moment($scope.filter.end_date).format('DD-MM-YYYY');
                $scope.sales = $scope.$parent.data;
                $scope.items = [];
                $scope.itemsAll = [];
                
                var sales = _.filter($scope.sales.sales, function(row){ return parseInt(row.client_id) == parseInt(data.client_id);});
                _.each(sales, function(item){
                    $sale.get({id: item.hashid}).$promise.then(function(response){
                        _.each(response.items, function(row){ $scope.items.push(row); });
                    });
                });

                _.each($scope.items, function(row){
                    _.each(row, function(service){
                        $scope.itemsAll.push({
                            'name'       : service.name,
                            'service_id' : service.service_id,
                            'quantity'   : service.quantity,
                            'total'      : service.total
    
                        });
                    })
                })
                
                $scope.items = $scope.itemsAll;
                if(data.saleTotal !== null && data.total){
                    $scope.totalSale = 'Total de produtos R$:'+ data.total;
                }else{
                    $scope.totalSale = 'Total de produtos R$: 0,00';    
                }
                if(data.protocolTotal !== null && data.protocolTotal){
                    $scope.totalProtocol = 'Total de serviços R$:'+ data.protocolTotal;
                }else{
                    $scope.totalProtocol = 'Total de serviços R$: 0,00';    
                }

                $scope.printServicesSale = function(){
                    
                    var params = {
                        start_date    : moment($scope.filter.start_date).format('DD-MM-YYYY'),
                        end_date      : moment($scope.filter.end_date).format('DD-MM-YYYY'),
                        saleTotal     : data.total,
                        protocolTotal : data.protocolTotal ? data.protocolTotal : null,
                        services      : [$scope.itemsAll],
                        client_id     : data.client_id
                    }
                    $http.put(api+'report/print_services_dialy_sale', params).then(function(response){

                        $rootScope.print(response.data); 
                
                        $scope.printing = false;
                    }, function(error) {
                        ngToast.danger({
                            content: 'Sem dados para impressão!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $scope.printing = false;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };
            }]
        });       
    }


    $scope.print = function(origin = null){
        if ($scope.data.sale.count > 0) {
            if (origin == 'clients') {
                var params = {
                    start_date    : moment($scope.filter.start_date).format('DD-MM-YYYY'),
                    end_date      : moment($scope.filter.end_date).format('DD-MM-YYYY'),
                    services      : $scope.data.sale.clients,
                    total         : $scope.data.sale.total,
                    title         : 'Total de vendas por cliente'
                }
            }
            if (origin == 'payments') {
                var params = {
                    start_date    : moment($scope.filter.start_date).format('DD-MM-YYYY'),
                    end_date      : moment($scope.filter.end_date).format('DD-MM-YYYY'),
                    payments      : $scope.data.sale.payments,
                    total         : $scope.data.sale.total,
                    title         : 'Formas de pagamentos'
                }
            }
            if (origin == 'seller') {
                var params = {
                    start_date    : moment($scope.filter.start_date).format('DD-MM-YYYY'),
                    end_date      : moment($scope.filter.end_date).format('DD-MM-YYYY'),
                    payments      : $scope.data.sale.seller,
                    total         : $scope.data.sale.total,
                    title         : 'Total de vendas por vendedor'
                }
            }
            if (origin == 'shipping') {
                var params = {
                    start_date       : moment($scope.filter.start_date).format('DD-MM-YYYY'),
                    end_date         : moment($scope.filter.end_date).format('DD-MM-YYYY'),
                    services         : $scope.data.sale.hasShippingValue,
                    total_shipping   : $scope.data.sale.total_shipping,
                    total            : $scope.data.sale.totals,
                    title            : 'Total de vendas com frete'
                }
                $scope.printing = true;
                $http.put(api+'report/print_shipping_sales', params).then(function(response){
        
                $rootScope.print(response.data);
        
                $scope.printing = false;
                }, function(error) {
                    ngToast.danger({
                        content: 'Sem dados para impressão!',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    $scope.printing = false;
                });
                return;
            }
            $scope.printing = true;
            $http.put(api+'report/print_client_sales',params).then(function(response){
    
            $rootScope.print(response.data);
    
            $scope.printing = false;
            }, function(error) {
                ngToast.danger({
                    content: 'Sem dados para impressão!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.printing = false;
            }); 
        }
    }

    $scope.printTopItems = function(){

        var params = {
            start_date    : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date      : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            print         : true
        }


        $scope.printing = true;

        $sale.salesBalance(params).then(function(response){
            $rootScope.print(response);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }
}]);

angular.module('report').
controller('report.dialy_sale', ['$rootScope','$scope', '$http', '$q',  '$sale', '$service', '$users', '$localStorage', '$uibModal', 'ngToast', '$client',
function($rootScope, $scope, $http, $q, $sale, $service, $users, $localStorage, $uibModal, ngToast, $client) {

    $scope.chart = {
        data : [],
        labels : []
    }
    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.waiting = false;
    $scope.userSaller =  false;
    $scope.user = $localStorage.user;

    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.filter = {
        start_date  : moment().startOf('month').toDate(),
        end_date    : moment().endOf('month').toDate(),
        client      : {},
        service     : {},
        user_id     : {},
        seller_id   : {},
        orderBy     : 'count'
    };

    $scope.printing = false;

    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
    });

    if( _.find($scope.user.permissions, function(row){ return row.name == "vendedor"; }) ){
        $scope.userSaller =  true;
        $scope.filter.seller_id.id = $scope.user.id;
    };

    $client.query({count: 1000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $scope.loadDashboard = function(){
        $scope.waiting = true;
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        $http.get(api+'report/dialy_sales_balance',{params:params}).then(function(response){
            $scope.waiting = false;
            $scope.data = response.data;

            $scope.data.total = parseFloat($scope.data.sale.total) + parseFloat($scope.data.protocol.total);

            $scope.chart.data = _.pluck($scope.data.chart, 'total');
            $scope.chart.labels = _.pluck($scope.data.chart, 'date');
        });

    }
    $scope.loadDashboard();

    $scope.showServicesSale = function(data){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/report/modal_show_services_sale",
            size: 'lg',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Produtos vendidos no dia: '+ data.date;
                if(data.saleTotal !== null){
                    $scope.totalSale = 'Total de produtos R$:'+ data.saleTotal;
                }else{
                    $scope.totalSale = 'Total de produtos R$: 0,00';    
                }
                if(data.protocolTotal !== null){
                    $scope.totalProtocol = 'Total de serviços R$:'+ data.protocolTotal;
                }else{
                    $scope.totalProtocol = 'Total de serviços R$: 0,00';    
                }
                $scope.items = [];
                $scope.items =  data.items;
                
                $scope.printServicesSale = function(){
                    var params = {
                        date : data.date,
                        saleTotal : data.saleTotal,
                        protocolTotal : data.protocolTotal,
                        services : [
                            items = data.items
                        ]
                    }
                    $http.put(api+'report/print_services_dialy_sale', params).then(function(response){

                        $rootScope.print(response.data); 
                
                        $scope.printing = false;
                    }, function(error) {
                        ngToast.danger({
                            content: 'Sem dados para impressão!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $scope.printing = false;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };
            }]
        });       
    }

    $scope.print = function(){
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy,
            print       : true 
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;
            
        $scope.printing = true;

        $http.get(api+'report/dialy_sales_balance', {params}).then(function(response){

        $rootScope.print(response.data);

        $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }
}]);

angular.module('report').
controller('report.category_sale', ['$rootScope','$scope', '$http', '$q',  '$sale', '$service', '$client', '$users', '$service_category_group', 'ngToast', '$localStorage', 'origin',
function($rootScope, $scope, $http, $q, $sale, $service, $client, $users, $service_category_group, ngToast, $localStorage, origin) {

    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.uncategorized = {
        total : 0,
        total_items : 0,
        show : false,
        chart : {},
        items : []
    }

    $scope.printing = false;
    $scope.waiting = false;

    $scope.userSaller =  false;
    $scope.getUser = function(){

        return "false";
    }
    $scope.user = $localStorage.user;

    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.filter = {
        start_date          : moment().startOf('month').toDate(),
        end_date            : moment().endOf('month').toDate(),
        service             : {},
        client              : {},
        user_id             : {},
        seller_id           : {},
        orderBy             : 'count',
        service_group_id    : null
    };

    $scope.filterCategory = -1;
    $scope.showCategory = false;

    $users.query({count:10000}).$promise.then(function(response){
        $scope.users = response.data;
    });

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    if( _.find($scope.user.permissions, function(row){ return row.name == "vendedor"; }) ){
        $scope.userSaller =  true;
        $scope.filter.seller_id.id = $scope.user.id;
    };

    // $scope.filter.seller_id.id = $scope.user.id;
    $service_category_group.query({show_sugestion : 0}).$promise.then(function(response){
        $scope.serviceCategoryGroups = response;
        if( response.length > 0 )
            $scope.filter.service_group_id = response[0].id;

        $scope.loadDashboard();
    });


    $scope.loadDashboard = function(){
        $scope.waiting = true;
        $scope.filterCategory = '';
        var params = {
            start_date          : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date            : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy             : $scope.filter.orderBy,
            service_group_id    : $scope.filter.service_group_id
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.service_group_id )
            params.service_group_id = $scope.filter.service_group_id;

        $sale.salesBalance(params).then(function(response){
            $scope.waiting = false;
            $scope.data = response;
            $scope.data.chartCategory = {
                data : [],
                labels : []
            }

            $scope.uncategorized = {
                total : 0,
                total_items : 0,
                show : false
            }

            $scope.group = $scope.serviceCategoryGroups.find((group, index, array) => group.id === $scope.filter.service_group_id);

            if($scope.data.sale.items.length > 0){
                $scope.uncategorized.items = $scope.data.sale.items.filter(function (element) {   
                    return element.service && element.service.categories.every( function (subElement) {
                        return subElement.group_id !== $scope.filter.service_group_id
                    });   
                });  
            };

            _.each($scope.uncategorized.items, function(item){
                $scope.uncategorized.total =  $rootScope.sumFloat($scope.uncategorized.total, item.total);
                $scope.uncategorized.total_items ++;
                if($scope.uncategorized.total > 0){
                    $scope.uncategorized.chart = {
                        service_category_id     : null,
                        name                    : 'Sem '+ $scope.group.name,
                        total                   : parseFloat($scope.uncategorized.total)
                    }     
                }
            });

            var arrayOthers = $scope.data.sale.categories.slice(9);
            var sumOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.total); }, 0);

            $scope.topFiveCategories = $scope.data.sale.categories.slice(0, 9);
            if( $scope.data.sale.categories.length > 9 )
                $scope.topFiveCategories.push({
                    service_category_id     : 0,
                    name                    : 'Outras categorias',
                    total                   : sumOutros
                });
                if($scope.uncategorized.total > 0 ){
                    $scope.topFiveCategories.push($scope.uncategorized.chart);    
                }
            $scope.data.chartCategory.data = _.pluck($scope.topFiveCategories, 'total');
            $scope.data.chartCategory.labels = _.pluck($scope.topFiveCategories, 'name');
        });
    }

    $scope.setFilterCategory = function(id){
        if($scope.filterCategory == id) {
            $scope.filterCategory = -1;
            $scope.showCategory   = false;
        } else {
            if( id == 0 ) {
                $scope.showCategory     = !$scope.showCategory;
                $scope.filterCategory   = -1;
                return;
            }
            $scope.filterCategory = id;
        }
    }

    $scope.filterCategoriesItems = function(data){
        var categoryItems = data;
        //Aplica o filtro de categoria
        if( $scope.filterCategory > 0 ){
            var arrayOfElements = categoryItems;
            return categoryItems = _.filter(arrayOfElements, function(row){ return row.service_category_id === $scope.filterCategory}); 
        }    
        if( $scope.filterCategory == null ){
            var arrayOfElements = categoryItems;
            return categoryItems = _.filter(arrayOfElements, function(row){ return row.service_category_id === null});
        }
        
        return categoryItems;

    }

    $scope.print = function(){
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy,
            service_group_id    : $scope.filter.service_group_id
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        $scope.printing = true;

        $http.get(api+'report/print_category_sales', {params}).then(function(response){
            $rootScope.print(response.data);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }
}]);

angular.module('report').
controller('report.sale_seller', ['$rootScope','$scope', '$http', '$q',  '$sale', '$users', '$localStorage', '$client',
function($rootScope, $scope, $http, $q, $sale, $users, $localStorage, $client) {

    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.printing = false;
    $scope.waiting  = false;

    $scope.userSaller =  false;
    $scope.user = $localStorage.user;

    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.filter = {
        start_date  : moment().startOf('month').toDate(),
        end_date    : moment().endOf('month').toDate(),
        service     : {},
        client      : {},
        user_id     : {},
        seller_id   : {},
        orderBy     : 'count',
        status      : null
    };

    $scope.filterSeller = -1;
    $scope.showSeller = false;

    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
    });

    $client.query({count: 1000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'pending', title:'Pendente'},
        {id:'succeeded', title:'Fechada'}
    ];

    $scope.loadDashboard = function(print = false){
        $scope.waiting = true;
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.status )
            params.status = $scope.filter.status;

        if( print ){
            params.print = true;
            $scope.printing = true;
        }

        $sale.salesToSeller(params).then(function(response){
            $scope.waiting = false;
            if( print ){
                $rootScope.print(response);
                $scope.printing = false;
                return;
            }
            $scope.data = response;
            $scope.data.chartSeller = {
                data : [],
                labels : []
            }

            $scope.topFiveSellers = $scope.data.sale.seller.slice(0,4);
            $scope.othersSellers = $scope.data.sale.seller.slice(5);
            var arrayOthers = $scope.data.sale.seller.slice(5);
            var sumOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.total); }, 0);
            var sumShippingOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.shipping_total); }, 0);
            var countOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.count); }, 0);

            if( $scope.data.sale.seller.length > 5 )
                $scope.topFiveSellers.push({
                    seller_id               : 0,
                    name                    : 'Outras vendedores',
                    total                   : sumOutros,
                    shipping_total          : sumShippingOutros,
                    count                   : countOutros
                });

            $scope.data.chartSeller.data = _.pluck($scope.topFiveSellers, 'total');
            $scope.data.chartSeller.labels = _.pluck($scope.topFiveSellers, 'name');
        });
    }
    $scope.loadDashboard();


    $scope.setFilterSeller = function(id){
        if($scope.filterSeller == id) {
            $scope.filterSeller = -1;
        } else {
            if( id == 0 ) {
                $scope.showSeller     = !$scope.showSeller;
                $scope.filterSeller   = -1;
                return; 
            }
            $scope.filterSeller = id;
        }
    }

    $scope.filterSellerItems = function(data){
        var sellerItems = data;
        if( $scope.filterSeller > 0 )
            sellerItems = _.filter(data, function(row){return row.seller_id == $scope.filterSeller});
        if( $scope.filterSeller == null )
            sellerItems = _.filter(data, function(row){
                return row.seller_id == null;
            });

        return sellerItems;
    }
}]);

angular.module('report').
controller('report.sale_client', ['$rootScope','$scope', '$http', '$q',  '$sale', '$users', '$localStorage', '$client', '$state',
function($rootScope, $scope, $http, $q, $sale, $users, $localStorage, $client, $state) {

    $scope.data        = {
        sale           : {},
        protocol       : {},
        total          : 0
    }

    $scope.printing    = false;
    $scope.waiting     = false;
    $scope.filterSales = null;
    $scope.showSales   = false;
    
    $scope.user        = $localStorage.user;

    $scope.start_date  = { opened: false };
    $scope.end_date    = { opened: false };
    $scope.filter      = {
        start_date     : moment().startOf('month').toDate(),
        end_date       : moment().endOf('month').toDate(),
        service        : {},
        client_id      : null,
        user_id        : null,
        seller_id      : {},
        orderBy        : 'count',
        status         : null
    };

    $client.query({'filter[type]' : 'client', count: 1000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'opened', title: 'Abertas' },
        {id:'pending', title:'Pendentes'},
        {id:'succeeded', title:'Fechadas'},
        {id:'canceled', title: 'Canceladas'}
    ];

    $scope.loadDashboard = function(print = false){
        $scope.waiting = true;
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy
        }

        if( $scope.filter.client_id )
            params.client_id = $scope.filter.client_id;

        if( $scope.filter.status )
            params.status = $scope.filter.status;

        if( print ){
            params.print = true;
            $scope.printing = true;
            params.payment_later_paid = $scope.paymentLaterPaidTotal;
            params.payment_later_not_paid = $scope.saleNotPaidPaymentLater;
            if ($scope.filterSales) {
                params.payment_later = true;
                params.status_formated = $scope.filterSales;    
            }
        }

        $sale.salesToClient(params).then(function(response){
            $scope.data = response;
            $scope.waiting = false;
            
            if( print ){
                $rootScope.print(response);
                $scope.printing = false;
                setTimeout(() => {
                    $scope.loadDashboard();
                }, 2000);
            }

            var salePaidFail = _.filter($scope.data.sales, function(num){ return num.status === 'opened'; });
            var sumF = _.reduce(salePaidFail, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidFail = sumF;

            var salePaidCanceled = _.filter($scope.data.sales, function(num){ return num.status === 'canceled'; });
            var sumC = _.reduce(salePaidCanceled, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidCanceled = sumC;

            var salePaidOut = _.filter($scope.data.sales, function(num){ return num.status === 'succeeded'; });
            var sum = _.reduce(salePaidOut, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.salePaidOut = parseFloat(sum);

            var salePaidOutTotal = _.filter($scope.data.sales, function(num){ return num.status === 'succeeded'; });
            var sum = _.reduce(salePaidOutTotal, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.totalSalePaidOut = parseFloat(sum) + parseFloat(sumF);

            $scope.salesPaymentLater = _.filter($scope.data.sales, function(num){ return (num.status_formated === 'Posterior' || num.status_formated === 'Posterior/Fechado') && num.status != 'canceled'; });
            var paidsSalesPaymentLater = _.filter($scope.salesPaymentLater, function(item){ return parseFloat(item.payment_later_value) === parseFloat(item.amount)});
            $scope.paymentLaterPaidTotal    = _.reduce(paidsSalesPaymentLater, function(memo, row){return memo + parseFloat(row.payment_later_value); }, 0);
            var sumP = _.reduce($scope.salesPaymentLater, function(memo, row){return memo + parseFloat(row.amount); }, 0);
            $scope.saleNotPaidPaymentLater = parseFloat(sumP) - parseFloat($scope.paymentLaterPaidTotal);

        });
    }
    $scope.loadDashboard();

    $scope.openClient = function(client) {
        if( client )
            $state.go('client_show',{id: client.id});
    }

    $scope.setFilterSales = function(id){
        if($scope.filterSales == id) {
            $scope.filterSales = null;
            $scope.showSales   = false;
        } else {
            if( id == null ) {
                $scope.showSales     = !$scope.showSales;
                $scope.filterSales   = null;
                return;
            }
            $scope.filterSales = id;
        }
    }

    $scope.filterSaleItems = function(data){
        var saleItems = data;
        if( $scope.filterSales != null ){
            var arrayOfElements = saleItems;
            saleItems = _.filter(arrayOfElements, function(row){ return row.status_formated === $scope.filterSales; });
        }    
        if( $scope.filterSales == null ){
            var arrayOfElements = saleItems;
            saleItems = _.filter(arrayOfElements, function(item){ return item.status_formated !== $scope.filterSales; });
        } 
        
        return saleItems;

    }

}]);

angular.module('report').
controller('report.stock', ['$rootScope','$scope', '$http', '$q', '$service', '$users', 'ngToast', '$uibModal', '$purchaseList', '$category', '$service_category_group', '$stateParams', '$localStorage',
function($rootScope, $scope, $http, $q, $service, $users, ngToast, $uibModal, $purchaseList, $category, $service_category_group, $stateParams, $localStorage) {
    $scope.filters = {
        end_date : moment().toDate(),
        provider    : null
    }

    $scope.labelItems = [];
    $scope.company      = $localStorage.company;
    $scope.decimals     = $scope.company.decimals_param;

    $scope.totals = {
        totalPurchaseValue : 0,
        percent : 0,
        totalPriceValue : 0,
        count : 0
    }

    $purchaseList.provider({count:10000}).then(function(response){
        $scope.providers = response;
    });

    $category.query({count:10000}).$promise.then(function(response){
        $scope.categories = response.data;
    });

    $service_category_group.query({service_id : $stateParams.id}).$promise.then(function(response){
        $scope.serviceCategoryGroups = response;
    });

    $scope.getData = function() {
        $scope.waiting = true;
        $scope.filters.category = JSON.stringify(_.pluck(_.filter($scope.serviceCategoryGroups, function(row) { return row.selected; }), 'selected'));
        $service.getStock($scope.filters).then(function(response){
            $scope.waiting = false;
            $scope.stocks = response.data;

            $scope.totals.totalPurchaseValue    = response.totalPurchaseValue;
            $scope.totals.totalPriceValue       = response.totalPriceValue;
            $scope.totals.count                 = response.count;
            $scope.totals.percent               = response.percent;

        }, function(error) {
            $scope.waiting = false;

        });
    }

    $scope.generateFile = function(model){
        var params = {
            model     : model,
            itemList  : $scope.labelItems
        }

        $http.post(api+'service/make_label', params).then(function(response){
            var link = angular.element('<a href="' + response.data + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

        }, function(error) {
            $scope.waiting = false;
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout    : false,
                dismissButton       : true,
            });

        });
    }

    $scope.selectModelLabel = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_model_label.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$company', function($scope, $uibModalInstance, $company) {

                $scope.success_text = 'Confirmar';

                $http.get(api+'company/label').then(function(response){
                    $scope.listModel = response.data;
                }, function(error){

                });

                $scope.confirm = function(){
                    if($scope.selectedModel.label_model === ''){
                        ngToast.danger({
                            content: 'Sem dados de modelo definido',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }else{
                        $scope.generateFile($scope.selectedModel);
                        $uibModalInstance.dismiss('cancel');
                    }

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.selectItemsLabel = function(find = null, qtd = null, origin = null, movement_type = null ){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'xl',
            resolve         : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                },
                movement_type : function (){
                    return movement_type
                }
            }
        });

        modalInstance.result.then(function(data){
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $scope.labelItems.push({
                        'service_id'            : item.service_id,
                        'name'                  : item.name,
                        'ref'                   : item.obj.external_reference,
                        'value'                 : item.obj.value,
                        'quantity'              : item.quantity > 0 ? item.quantity : 1,
                        'ean'                   : item.obj.cEAN,
                        'group'                 : item.obj.service_group_id
                    });
                });
            }else{
                $scope.labelItems.push({
                    'service_id'            : data.service_id,
                    'name'                  : data.name,
                    'ref'                   : data.obj.external_reference,
                    'value'                 : data.obj.value,
                    'quantity'              : data.quantity > 0 ? data.quantity : 1,
                    'ean'                   : data.obj.cEAN,
                    'group'                 : data.obj.service_group_id
                });
            }
            if($scope.labelItems.length > 0){
                $scope.selectModelLabel();
            }
        });
    }

    $scope.print = function() {
        $scope.printing = true;

        var params = $scope.filters;
        params.print = true;
        $service.getStock(params).then(function(response){
            $scope.printing = false;
            $rootScope.print(response);
        }, function(error){
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.exportInventory = function(){
        $scope.exporting = true;
        $scope.filters.category = JSON.stringify(_.pluck(_.filter($scope.serviceCategoryGroups, function(row) { return row.selected; }), 'selected'));
        $service.exportInventory($scope.filters).then(function(response){
            $scope.exporting = false;
            var link = angular.element('<a href="'+response+'"target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para exportação!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.exporting = false;
        });
    }

    $scope.selectService = function(data){
        $scope.showInModal = false;
        $rootScope.selectService(data, $scope.showInModal);
    }

    $scope.filter = [
        {id:'', title:'Todos'},
        {id:'positive', title:'Estoque positivo'},
        {id:'negative', title:'Estoque negativo'}
    ];

}]);

angular.module('report').
controller('report.payments_sale', ['$rootScope','$scope', '$http', '$q',  '$sale', '$service', '$client', '$users', '$service_category_group', 'ngToast', '$localStorage', 'origin',
function($rootScope, $scope, $http, $q, $sale, $service, $client, $users, $service_category_group, ngToast, $localStorage, origin) {

    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.printing = false;
    $scope.waiting = false;

    $scope.userSaller =  false;
    $scope.getUser = function(){

        return "false";
    }
    $scope.user = $localStorage.user;

    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.filter = {
        start_date          : moment().startOf('month').toDate(),
        end_date            : moment().endOf('month').toDate(),
        service             : {},
        client              : {},
        user_id             : {},
        seller_id           : {},
        orderBy             : 'count'
    };

    $scope.filterPayments = -1;
    $scope.showPyments  = false;

    $users.query({count:10000}).$promise.then(function(response){
        $scope.users = response.data;
    });

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    $scope.payments = [
        {id:'', title:'Todos'},
        {id:'money', title:'Dinheiro'},
        {id:'pos_credit', title:'Crédito'},
        {id:'pos_debit', title:'Débito'},
        {id:'boleto', title:'Boleto'},
        {id:'others', title:'Outros'},
        {id:'pix', title:'Pix'},
        {id:'transfer', title:'Transferência'},
        {id:'payment_later', title:'Posterior'},
    ];

    // if( _.find($scope.user.permissions, function(row){ return row.name == "vendedor"; }) ){
    //     $scope.userSaller =  true;
    //     $scope.filter.seller_id.id = $scope.user.id;
    // };

    $scope.filter.seller_id.id = $scope.user.id;
    $scope.loadDashboard = function(){
        $scope.waiting = true;
        var params = {
            start_date          : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date            : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy             : $scope.filter.orderBy
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.payments )
            params.payments = $scope.filter.payments;

        $sale.salesBalance(params).then(function(response){
            $scope.waiting = false;
            $scope.data = response;
            $scope.data.chartPayments = {
                data : [],
                labels : []
            }
            var arrayOthers = $scope.data.sale.payments.slice(5);
            var sumOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.total); }, 0);
            $scope.topFivePayments = $scope.data.sale.payments.slice(0, 5);
            if( $scope.data.sale.payments.length > 5 )
                $scope.topFivePayments.push({
                    id                      : 0,
                    name                    : 'Outros meios de pagamentos',
                    total                   : sumOutros
                });
            $scope.data.chartPayments.data = _.pluck($scope.topFivePayments, 'total');
            $scope.data.chartPayments.labels = _.pluck($scope.topFivePayments, 'method_formated');

        });
    }
    $scope.loadDashboard();

    $scope.setFilterSales = function(id){
        if($scope.filterPayments == id) {
            $scope.filterPayments = -1;
        } else {
            if( id == null ) {
                $scope.showPyments     = !$scope.showPyments;
                $scope.filterPayments   = -1;
                return;
            }
            $scope.filterPayments = id;
        }
    }

    $scope.filterPaymentsSales = function(data){
        var paymentsItems = [];
        if( $scope.filterPayments != -1 ){
            paymentsItems = data.filter(function(row){
                return row.payments.filter(function(payment) {
                    return payment.payment_description == $scope.filterPayments;
                })
            });
        }
        if( $scope.filterPayments == null )
            paymentsItems = [];

        return paymentsItems;
    }

    $scope.print = function(origin = null){

        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            print       : true,
            orderBy     : $scope.filter.orderBy
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.payments )
            params.payments = $scope.filter.payments;

        $scope.printing = true;

        $sale.salesBalance(params).then(function(response){
            $rootScope.print(response);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }
}]);

angular.module('report').
controller('report.invoice', ['$rootScope','$scope', '$http', '$q',  '$invoice', '$service', '$client', '$users', '$suggestion', 'ngToast', '$localStorage', 'NgTableParams',
function($rootScope, $scope, $http, $q, $invoice, $service, $client, $users, $suggestion, ngToast, $localStorage, NgTableParams) {
    $scope.waiting = false;
    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.dates = {
        start_date: moment().startOf('month').toDate(),
        end_date: moment().endOf('month').toDate()
    }

    $scope.enableButtonPrint = false;
    $scope.print  = false; 
    $scope.mod    = null;
    $scope.natOp  = '';
    $scope.status = '';
    $scope.filter = {
        page : 1,
        count : 20,
        start_date  : moment($scope.today).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date    : moment($scope.today).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        natOp       : '',
        mod         : '',
        status      : ''
    };

    $scope.totals = {
        sent : { count : 0, value : 0 },
        devolution : { count : 0, value : 0 },
        no_sent : { count : 0, value : 0 },
        canceled :{ count : 0, value : 0 },
        mod :{ count : 0, value : 0 }

    }

    $scope.params = {
        page : 1,
        count : 20,
        showTotals : true,
        print : false,
        filter : $scope.filter
    }

    $scope.descriptionSuggestionsList =[];
    $suggestion.query({type: 'invoice_natOp'}).$promise.then(function(response){
        $scope.descriptionSuggestionsList = response;
    });

    $scope.printReport = function(){
        $scope.loadInvoces();
        $scope.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.filter.natOp      = $scope.natOp;
        $scope.filter.mod        = $scope.mod;
        $scope.filter.status     = $scope.status;
        
        var params = {
            showTotals: true,
            print : true, 
            filter: $scope.filter,
            company_id: $scope.params.company_id ? $scope.params.company_id  : ''      
        }

        $http.get(api+'invoice/print_report', {params}).then(function(response){
            $rootScope.print(response.data);
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.print = false;
        });
    }

    $scope.loadInvoces = function(){
        $scope.waiting = true;
        $scope.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD HH:mm:ss');
        $scope.filter.natOp      = $scope.natOp;
        $scope.filter.mod        = $scope.mod;
        $scope.filter.status     = $scope.status;
        $scope.tableParams = new NgTableParams({
            page: $scope.params.page,
            count: $scope.params.count,
            showTotals: true,
            print : false, 
            filter: $scope.params.filter,
            company_id: $scope.params.company_id ? $scope.params.company_id  : ''
        }, {
            counts: [],
            filterDelay: 0,
            getData: function(params) {
                return $invoice.query(params.url()).$promise.then(function(response) {   
                    params.total(response.paginate.total);
                    $scope.totals = {
                        sent : { count : 0, value : 0 },
                        devolution : { count : 0, value : 0 },
                        no_sent : { count : 0, value : 0 },
                        canceled :{ count : 0, value : 0 },
                        mod :{ count : 0, value : 0 }
        
                    }
                    $scope.totals.sent.count        = response.sentCount;
                    $scope.totals.sent.value        = response.sentTotal;
        
                    $scope.totals.devolution.count  = response.devolutionCount;
                    $scope.totals.devolution.value  = response.devolutionTotal;
        
                    $scope.totals.no_sent.count     = response.noSentCount;
                    $scope.totals.no_sent.value     = response.noSentTotal;
        
                    $scope.totals.canceled.count    = response.canceledCount;
                    $scope.totals.canceled.value    = response.canceledTotal;

                    $scope.waiting = false;

                    return response.paginate.data;
                });
            }
        });
        
    }

}]);

angular.module('report').
controller('report.class_group', ['$rootScope','$scope', '$http', '$q',  '$invoice', '$service', '$client', '$users', '$suggestion', 'ngToast', '$localStorage', 'NgTableParams', '$class_group', '$client', '$users',
function($rootScope, $scope, $http, $q, $invoice, $service, $client, $users, $suggestion, ngToast, $localStorage, NgTableParams, $class_group, $client, $users) {
    $scope.waiting = false;  

    $scope.print  = false; 
    $scope.id = '';
    $scope.status_id = '';
    $scope.professional_id = '';
    $scope.students = [];
    $scope.params = {};

    $users.get({count: 1000}).$promise.then(function(response){
        $scope.professionals = response.data;
    });

    $class_group.get({count: 1000}).$promise.then(function(response){
        $scope.class_groups = response.data;
    });

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'opened', title:'Aberto'},
        {id:'closed', title:'Fechado'}
    ];

    $scope.loadClassGroup = function(print = false, data, names, professionals){
        $scope.students = [];
        $scope.waiting = true;

        if ($scope.id) {
            $scope.params.group_id = $scope.id;
        }

        if ($scope.status_id) {
            $scope.params.status = $scope.status_id;
        }

        if ($scope.professional_id) {
            $scope.params.professional_id = $scope.professional_id;
        }

        if( print ){
            $scope.params.print = true;
            $scope.params.data = data;
            $scope.params.classNames = names;
            $scope.params.professionals = professionals;
            $scope.params.professionals = _.uniq($scope.params.professionals);
            $scope.printing = true;
        }
    
        $http.put(api+'report/class_group', $scope.params).then(function(response){

            $scope.classGroups = response.data;
            $scope.params = {};
            $scope.classesName = [];
           
            _.each($scope.classGroups, function(row){
                if (row.HasEnrollment) {
                    row.clientenrollment = row.enrollment;
                }
                $scope.classesName.push(row.name);
                _.each(row.clientenrollment, function(item){
                    item.instructor = [];
                    if (item.client) {
                        item.client.age = item.client.birthday ? $scope.calcAge(new Date(item.client.birthday)) : null;
                    }
                    if (row.instructor) {
                        _.each(row.instructor, function(q){
                            item.instructor.push(q.users.name);
                        })
                        $scope.professionalsNames = row.instructor;
                        item.instructor = item.instructor.join();
                    }
                    $scope.students.push(item);
                })
            })

            if( print ){
                setTimeout(() => {
                    $rootScope.print(response.data);
                    $scope.printing = false;
                    $scope.waiting = false;
                    $scope.loadClassGroup();
                    return;
                }, 8000);
            } else {
                $scope.waiting = false;
            }
            
        }, function(error) {
            ngToast.danger({
                content: 'Algo deu errado!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.waiting = false;
        });
    }
    $scope.loadClassGroup();

    $scope.printClassGroup = function(){
        $scope.loadClassGroup(print = true, $scope.students, $scope.classesName, $scope.professionalsNames);
    }

    $scope.calcAge = function(birthday){
        var today = new Date();
        var age = today.getFullYear() - birthday.getFullYear();
        if ( new Date(today.getFullYear(), today.getMonth(), today.getDate()) < 
             new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate()) )
             age--;
        return age;
    }

    

}]);

angular.module('report').
controller('report.sale_seller_commission', ['$rootScope','$scope', '$http', '$q',  '$sale', '$users', '$localStorage', '$client',
function($rootScope, $scope, $http, $q, $sale, $users, $localStorage, $client) {

    $scope.data = {
        sale : {},
        protocol : {},
        total : 0
    }

    $scope.printing = false;
    $scope.waiting  = false;

    $scope.userSaller =  false;
    $scope.user = $localStorage.user;

    $scope.start_date   = { opened: false };
    $scope.end_date     = { opened: false };
    $scope.filter = {
        start_date  : moment().startOf('month').toDate(),
        end_date    : moment().endOf('month').toDate(),
        service     : {},
        client      : {},
        user_id     : {},
        seller_id   : {},
        orderBy     : 'count',
        status      : null
    };

    $scope.filterSeller = -1;
    $scope.showSeller = false;

    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
    });

    $client.query({count: 1000}).$promise.then(function(response){
        $scope.clients = response.data;
    });

    $scope.colors = {
        colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
    };

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'pending', title:'Pendente'},
        {id:'succeeded', title:'Fechada'}
    ];

    $scope.loadDashboard = function(print = false){
        $scope.waiting = true;
        var params = {
            start_date  : moment($scope.filter.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filter.end_date).format('YYYY-MM-DD HH:mm:ss'),
            orderBy     : $scope.filter.orderBy
        }

        if( $scope.filter.service.id )
            params.service_id = $scope.filter.service.id;

        if( $scope.filter.client.id )
            params.client_id = $scope.filter.client.id;

        if( $scope.filter.user_id.id )
            params.user_id = $scope.filter.user_id.id;

        if( $scope.filter.seller_id.id )
            params.seller_id = $scope.filter.seller_id.id;

        if( $scope.filter.status )
            params.status = $scope.filter.status;

        if( print ){
            params.print = true;
            $scope.printing = true;
        }

        $sale.commissionSalesToSeller(params).then(function(response){
            $scope.waiting = false;
            if( print ){
                $rootScope.print(response);
                $scope.printing = false;
                return;
            }
            $scope.data = response;
            $scope.data.chartSeller = {
                data : [],
                labels : []
            }

            $scope.topFiveSellers = $scope.data.sale.seller.slice(0,4);
            $scope.othersSellers = $scope.data.sale.seller.slice(5);
            var arrayOthers = $scope.data.sale.seller.slice(5);
            var sumOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.total); }, 0);
            var sumCommissionOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.totalCommission); }, 0);
            var countOutros = _.reduce(arrayOthers, function(memo, item){ return memo + parseFloat(item.count); }, 0);

            if( $scope.data.sale.seller.length > 5 )
                $scope.topFiveSellers.push({
                    seller_id               : 0,
                    name                    : 'Outros vendedores',
                    total                   : sumOutros,
                    commission_total        : sumCommissionOutros,
                    count                   : countOutros
                });

            $scope.data.chartSeller.data = _.pluck($scope.topFiveSellers, 'total');
            $scope.data.chartSeller.labels = _.pluck($scope.topFiveSellers, 'name');
        });
    }
    $scope.loadDashboard();


    $scope.setFilterSeller = function(id){
        if($scope.filterSeller == id) {
            $scope.filterSeller = -1;
        } else {
            if( id == 0 ) {
                $scope.showSeller     = !$scope.showSeller;
                $scope.filterSeller   = -1;
                return; 
            }
            $scope.filterSeller = id;
        }
    }

    $scope.filterSellerItems = function(data){
        var sellerItems = data;
        if( $scope.filterSeller > 0 )
            sellerItems = _.filter(data, function(row){return row.seller_id == $scope.filterSeller});
        if( $scope.filterSeller == null )
            sellerItems = _.filter(data, function(row){
                return row.seller_id == null;
            });

        return sellerItems;
    }
}]);

angular.module('report').
controller('report.purchase', ['$rootScope','$scope', '$http', '$q',  '$sale', '$users', '$localStorage', '$client', '$state', '$companyInvoice', 'NgTableParams', '$purchaseList',
function($rootScope, $scope, $http, $q, $sale, $users, $localStorage, $client, $state, $companyInvoice, NgTableParams, $purchaseList) {

    $scope.statuses = [
        {id:'waiting', title:'Aguardando'},
        {id:'confirmed', title: 'Confirmada'},
        {id:'in_progress', title: 'Em Andamento'},
        {id:'finished', title: 'Finalizada'}
    ];

    $scope.filter = {
        status: '',
        start_date: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        provider_id: ''
    };

    $scope.start_date = {
        opened: false
    };

    $scope.end_date = {
        opened: false
    };

    $scope.dates = {
      start_date: moment().startOf('month').toDate(),
      end_date: moment().endOf('month').toDate()
    }

    $scope.status = {
        id: ''
    };

    $scope.provider = {
        id: ''
    };

    $purchaseList.provider({count:10000}).then(function(response){
        $scope.providers = response;
    });

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 20,
        filter: $scope.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $http.get(api+'report/purchase', { params: params.url() }).then(function(response){
                
                params.total(response.data.invoices.total);
                $scope.data = response.data.invoices.data;
                $scope.totalAll = response.data.data.totalAll;
                $scope.totalFinish = response.data.data.totaFinish;
                $scope.totalWaiting = response.data.data.totalWaiting;
                $scope.totalConfirmed = response.data.data.totalConfirmed;
                $scope.totalInProgress = response.data.data.totalInProgress;
                
                return response.data.invoices.data;
            });
        }
    });
    $scope.tableParams.reload();

    $scope.loadDashboard = function(){
        $scope.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD');
        $scope.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD');
        if( $scope.status ){
            $scope.filter.status = $scope.status;
        }else{
            $scope.filter.status = '';
        }
        if( $scope.provider.id ){
            $scope.filter.provider_id = $scope.provider.id;
        }else{
            $scope.filter.provider_id = '';
        }

        $scope.tableParams.reload();
    }

}]);

