angular.module('department', ['ui.router', 'confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('department').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

  $stateProvider
    .state('department', {
      url: "/department",
      templateUrl: server + "/views/m/department",
      controller: 'department.list',
      resolve: {
        loginRequired: loginRequired
      },
      ncyBreadcrumb: {
        parent: 'home',
        label: 'Departamentos'
      }
    })

    .state('department_create', {
      url: "/department/create",
      templateUrl: server + "/views/m/department/create",
      controller: 'department.create',
      resolve: {
        loginRequired: loginRequired
      },
      ncyBreadcrumb: {
        parent: 'department',
        label: 'Adicionar'
      }
    })

    .state('department_show', {
      url: "/department/:id",
      templateUrl: server + "/views/m/department/show",
      controller: 'department.show',
      resolve: {
        loginRequired: loginRequired
      },
      ncyBreadcrumb: {
        parent: 'department',
        label: '{{department.name}}'
      }
    })

}]);

/**
 * List Controller
 */
angular.module('department').
controller('department.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$department', function($scope, $http, $auth, $q, NgTableParams, $department) {


  $scope.show_on_app = [{
      id: '0',
      title: 'Não'
    },
    {
      id: '1',
      title: 'Sim'
    }
  ];

  $scope.tableParams = new NgTableParams({
    page: 1,
    count: 10
  }, {
    counts: [],
    filterDelay: 0,
    getData: function(params) {
      return $department.query(params.url()).$promise.then(function(data) {
        params.total(data.total);
        return data.data;
      });
    }
  });
}]);

/**
 * Create Controller
 */
angular.module('department').
controller('department.create', ['$scope', '$state', '$department', '$users', function($scope, $state, $department, $users) {

  $scope.dropdownTranslation = {
    buttonDefaultText: 'Selecione',
    checkAll: 'Todas',
    uncheckAll: 'Nenhuma',
    selectionCount: 'selecionada(s)',
    selectionOf: '/',
    searchPlaceholder: 'Pesquisar',
    dynamicButtonTextSuffix: 'selecionada(s)'
  };

  $scope.dropdownConfig = {
    buttonClasses: 'btn btn-default btn-block',
    scrollable: true,
    scrollableHeight: '200px',
    enableSearch: true,
    styleActive: true,
    showCheckAll: false,
    showUncheckAll: false,
    displayProp: 'name',
    idProp: 'id',
    selectionLimit: 1,
    closeOnSelect: true,
    smartButtonMaxItems: 1
  }

  $scope.department = {
    head_id: {}
  };

  $users.query({
    count: 1000
  }).$promise.then(function(response) {
    $scope.managers = response.data;
  });

  $scope.save = function() {
    $scope.department.head_id = $scope.department.head_id.id;
    $department.save($scope.department).$promise.then(function(data) {
      $state.go('department');
    });
  }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('department').
controller('department.show', ['$state', '$scope', '$stateParams', '$uibModal', '$department', '$users', function($state, $scope, $stateParams, $uibModal, $department, $users) {

  $scope.editing = false;

  $scope.toggleEdit = function() {
    $scope.editing = !$scope.editing;
  }

  $scope.department = {
    head_id: {},
    show_on_app: false
  };

  $users.query({
    count: 1000
  }).$promise.then(function(response) {
    $scope.managers = response.data;
  });

  $scope.dropdownTranslation = {
    buttonDefaultText: 'Selecione',
    checkAll: 'Todas',
    uncheckAll: 'Nenhuma',
    selectionCount: 'selecionada(s)',
    selectionOf: '/',
    searchPlaceholder: 'Pesquisar',
    dynamicButtonTextSuffix: 'selecionada(s)'
  };

  $scope.dropdownConfig = {
    buttonClasses: 'btn btn-default btn-block',
    scrollable: true,
    scrollableHeight: '200px',
    enableSearch: true,
    styleActive: true,
    showCheckAll: false,
    showUncheckAll: false,
    displayProp: 'name',
    idProp: 'id',
    selectionLimit: 1,
    closeOnSelect: true,
    smartButtonMaxItems: 1
  }

  $department.get({
    id: $stateParams.id
  }).$promise.then(function(data) {
    data.head_id = {
      id: data.head_id
    };
    data.show_on_app = (data.show_on_app) ? '1' : '0';
    $scope.department = data;
  }, function(error) {
    if (error.status == 404) {
      $state.go('notFound');
    }
  });

  $scope.confirmUpdate = function() {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: 'confirm.modal.html',
      size: 'sm',
      scope: $scope,
      controller: ['$scope', '$uibModalInstance', '$department', function($scope, $uibModalInstance, $department) {

        $scope.title = 'Deseja atualizar?';
        $scope.cancel_text = 'Cancelar';
        $scope.success_text = 'Concluído';

        var department = $scope.$parent.department;
        department.head_id = department.head_id.id;

        $scope.confirm = function() {
          $department.update({
            id: department.id
          }, department).$promise.then(function(data) {
            $uibModalInstance.dismiss('cancel');
            $scope.$parent.toggleEdit();
          })
        }

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };

      }]
    });
  };

  $scope.confirmDelete = function() {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: 'confirm.modal.html',
      size: 'sm',
      scope: $scope,
      controller: ['$state', '$scope', '$uibModalInstance', '$department', function($state, $scope, $uibModalInstance, $department) {

        $scope.title = 'Deseja remover esse registro?';
        $scope.cancel_text = 'Não';
        $scope.success_text = 'Sim';

        var department = $scope.$parent.department;

        $scope.confirm = function() {

          $department.delete({
            id: department.id
          }).$promise.then(function(data) {
            $uibModalInstance.dismiss('cancel');
            $state.go('department');
          })
        }

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };

      }]
    });
  };

}]);


/**
 * Factory
 */
angular.module('department').
factory("$department", function($resource) {
  var resource = $resource(api + 'department/:id', null, {
    'query': {
      method: 'GET',
      isArray: false
    },
    'update': {
      method: 'PUT'
    }
  });
  return resource;
});