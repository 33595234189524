angular.module('protocol', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('protocol').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('protocol', {
        url         : "/protocol",
        templateUrl : server+"/views/m/protocol",
        controller  : 'protocol.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Ordens de serviço'
        }
    })

    .state('protocol_create', {
        url         : "/protocol/create",
        templateUrl : server+"/views/m/protocol/create",
        controller  : 'protocol.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'protocol',
            label: 'Adicionar'
        }
    })

    .state('protocol_show', {
        url         : "/protocol/:id",
        templateUrl : server+"/views/m/protocol/show",
        controller  : 'protocol.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'protocol',
            label: '{{protocol.number}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('protocol').
controller('protocol.list', ['$scope', '$rootScope', '$localStorage', '$http', '$auth', '$q', 'NgTableParams', '$protocol','$uibModal','$process','$state',
function($scope, $rootScope, $localStorage, $http, $auth, $q, NgTableParams, $protocol, $uibModal, $process,$state) {

    $scope.process_id = {};
    if( !$localStorage.ngParams ){
        $localStorage.ngParams = {
            page : 1,
            count : 15,
            filter : {}
        }
    }

    $process.query({count:1000}).$promise.then(function(response){
        $scope.process = response.data;
    });

    $scope.translation = {
        buttonDefaultText       : 'Nova O.S.',
        checkAll                : 'Todas',
        uncheckAll              : 'Nenhuma',
        selectionCount          : 'selecionada(s)',
        selectionOf             : '/',
        searchPlaceholder       : 'Pesquisar',
        dynamicButtonTextSuffix : 'selecionada(s)'
    };

    $scope.selectConfig = {
        template            : '{{option.cpf_cnpj | brCpfCnpj}} - {{option.name}}',
        buttonClasses       : 'btn btn-default btn-block',
        scrollable          : true,
        scrollableHeight    : '200px',
        enableSearch        : true,
        styleActive         : true,
        showCheckAll        : false,
        showUncheckAll      : false,
        displayProp         : 'name',
        idProp              : 'id',
        selectionLimit      : 1,
        closeOnSelect       : true,
        smartButtonMaxItems : 1,
    }

    $scope.status = [
        {id:'', title:''},
        {id:'all', title:'Todos'},
        {id:'open', title:'Aberta'},
        {id:'canceled', title:'Cancelada'},
        {id:'in_progress', title:'Em andamento'},
        {id:'discount_request', title:'Desconto solicitado'},
        {id:'discount_granted', title:'Desconto liberado'},
        {id:'discount_refused', title:'Desconto recusado'},
        {id:'confirmed', title:'Execução confirmada'},
        {id:'finished', title:'Finalizada'}
    ];

    $scope.createProtocol = function(item)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'os.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$protocol', '$client', '_',
            function($scope, $uibModalInstance, $protocol, $client, _) {

                var protocol = {process_id: $scope.$parent.process_id.id};

                $scope.translation = {
                    buttonDefaultText       : 'Escolha o cliente',
                    checkAll                : 'Todas',
                    uncheckAll              : 'Nenhuma',
                    selectionCount          : 'selecionada(s)',
                    selectionOf             : '/',
                    searchPlaceholder       : 'Pesquisar',
                    dynamicButtonTextSuffix : 'selecionada(s)'
                };

                $scope.title            = 'Confirma a abertura desse protocolo?';
                $scope.cancel_text      = 'Cancelar';
                $scope.success_text     = 'Abrir';
                $scope.client           = {};
                $scope.protocol_type    = 'client';
                $scope.opening          = false;
                $scope.newClient        = {
                    hasNewClient    : false,
                    name            : '',
                    phone           : '',
                    cpf_cnpj        : ''
                };

                $client.query({count:10000, client: true}).$promise.then(function(response){
                    $scope.clients = response.data;
                });

                $scope.changeType = function(type){
                    $scope.protocol_type    = type;
                    $scope.newClient        = {
                        hasNewClient    : false,
                        name            : '',
                        phone           : '',
                        cpf_cnpj        : ''
                    };
                };

                $scope.addClient = function(){

                    var modalInstance = $uibModal.open({
                        animation       : true,
                        templateUrl     : server+"/views/m/client/create",
                        size            : 'lg',
                        controller      : 'client.create',
                        backdrop        : 'static',
                        resolve         : {
                            showInModal : true
                        }
                    });
                    modalInstance.result.then(function(data) {
                        $scope.clients.push(data);
                        $scope.protocol_type    = 'client';
                        $scope.client           = data;
                    });
                }

                $scope.selectNameClient = function(selected){
                    if( selected ){
                        if( selected.originalObject.custom ){
                            $scope.newClient.hasNewClient = true;
                            $scope.newClient.name = selected.title;
                        } else {
                            if( selected.originalObject.id ){
                                $scope.protocol_type    = 'client';
                                $scope.client           = selected.originalObject;
                            }
                        }
                    }
                }

                $scope.selectDocumentClient = function(selected){
                    if( selected ){
                        if( selected.originalObject.custom ){
                            $scope.newClient.hasNewClient = true;
                            $scope.newClient.cpf_cnpj = selected.title;
                        } else {
                            if( selected.originalObject.id ){
                                $scope.protocol_type    = 'client';
                                $scope.client           = selected.originalObject;
                            }
                        }
                    }
                }

                $scope.selectPhoneClient = function(selected) {
                    if( selected ){
                        if( selected.originalObject.custom ){
                            $scope.newClient.hasNewClient = true;
                            $scope.newClient.phone = selected.title;
                        } else {
                            if( selected.originalObject.id ){
                                $scope.protocol_type    = 'client';
                                $scope.client           = selected.originalObject;
                            }
                        }
                    }
                }

                $scope.confirm = function() {
                    $scope.opening = true;
                    if( $scope.newClient )
                        protocol.client =  $scope.newClient;
                    if( $scope.protocol_type == 'client' ){
                        $scope.client = _.where($scope.clients,{id:$scope.client.id})[0];
                        if($scope.client.situation && $scope.client.situation.prevent_os_creation)
                        {
                            alert('Cliente Bloqueado');
                            return;
                        }
                        protocol.client_id = $scope.client.id;
                    }

                    $protocol.save(protocol).$promise.then(function(data) {
                        $state.go('protocol_show', {
                            id: data.number
                        });
                        $scope.opening = false;
                        delete $scope.$parent.process_id.id;
                        $uibModalInstance.dismiss('cancel');
                    }, function(error){
                        $scope.opening = false;
                    });
                }

                $scope.cancel = function() {
                    delete $scope.$parent.process_id.id;
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.tableParams = new NgTableParams({
        page: $localStorage.ngParams.page,
        count: $localStorage.ngParams.count,
        filter: $localStorage.ngParams.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $protocol.query(params.url()).$promise.then(function(data) {
                $localStorage.ngParams = params._params;

                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

angular.module('protocol')
.controller('protocol.show', ['$state', '$rootScope', '$scope', '$stateParams', '$uibModal', '$protocol', '_', '$http','$q', '$invoice', 'ngToast', 'showInModal', '$uibModalInstance', 'id', '$localStorage', '$users',
function($state, $rootScope, $scope, $stateParams, $uibModal, $protocol, _, $http, $q, $invoice, ngToast, showInModal, $uibModalInstance, id, $localStorage, $users) {


    $scope.title            = 'Dados da OS';
    $scope.showInModal      = showInModal;
    $scope.id               = id;
    $scope._ = _;
    $scope.editing = false;
    $scope.totalInstallment = 0;
    $scope.totalRemainder   = 0;
    $scope.installments = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
    $scope.max_installments = 24;
    $scope.transacted = [];
    $scope.user    = $localStorage.user.data;
    $scope.transacted[0] = {
        opened: false
    };
    $scope.payment_methods = [
        {id: 'boleto', label:'Boleto'},
        {id: 'credit_card', label:'Cartão de crédito'},
        {id: 'check', label:'Cheque'},
        {id: 'cash', label:'Dinheiro'},
        {id: 'ted', label:'Transferência'}
    ];
    $scope.payment_terms = [
        {id: 'in_cash', label:'À vista'},
        {id: 'with_entry', label:'Parcelado com entrada'},
        {id: 'installments', label:'Parcelado'}
    ];

    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
        _.each($scope.users, function(row){
            if (row.user_data_id == $scope.user.id) {
                $scope.userSelected = row;
            }
        });
    })

    $scope.getNumber = function(num) {
        var array = [];
        for (var i = 0; i < $scope.max_installments; i++) {
            array.push({id:i+1, label:i+1})
        }
        return array;
    }

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.toggleReopen = function() {
        $scope.updateStatus('in_progress');
    }

    $scope.loadProtocol = function(){
        $protocol.get({
            id: $scope.id ? $scope.id : $stateParams.id
        }).$promise.then(function(data) {
            $scope.protocol            = data;
            $scope.protocol.created_at = new Date($scope.protocol.created_at);
            $scope.protocol.updated_at = new Date($scope.protocol.updated_at);
            $scope.protocol.entry_at   = ( $scope.protocol.entry_at ? new Date($scope.protocol.entry_at) : '' );
            _.each($scope.protocol.comments, function(value, key){
                $scope.protocol.comments[key].created_at = new Date(value.created_at);
                $scope.protocol.comments[key].updated_at = new Date(value.updated_at);
            });

            if( $scope.protocol.installments && JSON.parse($scope.protocol.installments) ){
                var installments = JSON.parse($scope.protocol.installments);
                $scope.protocol.installments = [];
                _.each(installments, function(installment) {
                    installment.transacted_at = new Date(installment.transacted_at);
                    $scope.protocol.installments.push(installment);
                    $scope.transacted[installment.installment] = {
                        opened: false
                    };
                });
                $scope.recalculeInstallments();
            }

        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }

    $scope.loadProtocol();

    $scope.getDiscount = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'discount.modal.html',
            controller     : 'discount',
            size           : 'lg',
            resolve        : {
                protocol: function(){
                    return angular.copy($scope.protocol)
                },
                users: function(){
                    return $scope.userSelected
                }
            }
        });

        modalInstance.result.then(function(protocol) {
            $protocol.update({
                id: protocol.number
            }, protocol).$promise.then(function(data) {
                $scope.loadProtocol();
            })

        });
    }

    $scope.addServiceFrm = function(find = null, qtd = null, origin = 'protocol_os'){
        var modalInstance = $uibModal.open({
            animation       : true,
            ariaLabelledBy  : 'modal-title',
            ariaDescribedBy : 'modal-body',
            templateUrl     : 'add_service.modal.html',
            controller      : 'add_service',
            controllerAs    : '$ctrl',
            size            : 'lg',
            backdrop        : 'static',
            resolve         : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                }
            }
        });

        modalInstance.result.then(function(data) {
            if(Array.isArray(data)){
                _.each(data, function(item){
                    $http.post(api+'protocol/'+$stateParams.id+'/addService', {service_id:item.service_id, quantity: item.quantity, value:item.value, total:item.total}).then(function(response){
                        $scope.loadProtocol();
                    }, function(response) {
                        deferred.reject(response);
                    });
                });
            }else{
                $http.post(api+'protocol/'+$stateParams.id+'/addService', {service_id:data.service, quantity: data.quantity, value:data.value, total:data.total}).then(function(response){
                    $scope.loadProtocol();
                }, function(response) {
                    deferred.reject(response);
                });
            }
        });
    }

    $scope.comment = '';
    $scope.sendComment = function(comment){
        $protocol.comment($stateParams.id, comment).then(function(data) {
            delete $scope.comment;
            $scope.loadProtocol();
        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$protocol', function($scope, $uibModalInstance, $protocol) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var protocol = angular.copy($scope.protocol);
                protocol.installments = JSON.stringify(protocol.installments);

                $scope.confirm = function() {
                    $protocol.update({
                        id: protocol.number
                    }, protocol).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$protocol', function($state, $scope, $uibModalInstance, $protocol) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var protocol = $scope.$parent.protocol;

                $scope.confirm = function() {
                    $protocol.delete({id:protocol.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('protocol');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.updateStatus = function(status, mandatory){

        mandatory = (mandatory == undefined) ? false : mandatory;

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'update_status.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$protocol', function($scope, $uibModalInstance, $protocol) {

                var satus_text = '';
                var bReason    = false;

                switch (status) {
                    case 'open': status_text = 'Aberta'; break;
                    case 'canceled': status_text = 'Cancelada'; bReason = true; break;
                    case 'in_progress': status_text = 'Em andamento' ; break;
                    case 'discount_request': status_text = 'Desconto solicitado'; break;
                    case 'discount_granted': status_text = 'Desconto liberado'; break;
                    case 'discount_refused': status_text = 'Desconto recusado'; break;
                    case 'confirmed': status_text = 'Execução confirmada'; break;
                    case 'finished': status_text = 'Finalizada'; break;
                }

                $scope.title            = 'Atualizar status da O.S para:<br/>';
                $scope.title            += '<h1>'+status_text+'</h1>';
                $scope.bReason          = bReason;
                $scope.cancel_text      = 'Não';
                $scope.success_text     = 'Sim';

                var protocol = angular.copy($scope.$parent.protocol);
                protocol.installments = JSON.stringify(protocol.installments);
                protocol.status = status;

                $scope.confirm = function() {
                    protocol.reason = $scope.protocol.reason;
                    var deferred = $q.defer();
                    $protocol.update({
                        id: protocol.number
                    }, protocol).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.loadProtocol();
                        $rootScope.$broadcast('get-notifications');
                        deferred.resolve();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        console.log(error);
                        deferred.reject(error);
                    });
                    return deferred.promise;
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                    deferred.reject(response);
                };

            }]
        });


    }

    $scope.removeService = function(service){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$protocol', function($scope, $uibModalInstance, $protocol) {

                $scope.title        = 'Deseja remover esse serviço? Essa ação não pode ser desfeita.';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $http.delete(api+'protocol/'+$stateParams.id+'/removeService/'+service.id).then(function(response){
                        $scope.loadProtocol();
                        $uibModalInstance.dismiss('cancel');
                    }, function(response) {
                        deferred.reject(response);
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.createInstallments = function()
    {
        $scope.protocol.installments = [];

        if( $scope.protocol.payment_term == 'in_cash' )
            return;

        if( $scope.protocol.payment_term == 'installments' ){
            $scope.protocol.entry_value = 0;
            $scope.protocol.entry_at = null;
        }

        var total           = parseFloat($scope.protocol.total - $scope.protocol.entry_value);
        var qtd             = parseInt($scope.protocol.qtd_installments);
        var installments    = parseFloat(total/qtd).toFixed(2);
        var remainder       = 0;
        var date            = ( $scope.protocol.entry_at ? $scope.protocol.entry_at : new Date() );
        var year            = date.getFullYear();
        var month           = date.getMonth();
        var day             = date.getDate();

        $scope.totalInstallment = ( qtd * installments );

        for( var i = 1; i<= qtd; i++)
        {
            year    = date.getFullYear();
            month   = date.getMonth();
            day     = date.getDate();
            date    = new Date(year,month+1,day);

            $scope.protocol.installments.push( {
                transacted_at : date,
                value : installments,
                installment : i
            });
        }

        if( qtd * installments != total ){
            remainder = parseFloat(total - $scope.totalInstallment).toFixed(2);
            if( remainder < 0 ){
                remainder = parseFloat( remainder * (-1));
                $scope.protocol.installments[0].value = parseFloat($scope.protocol.installments[0].value - parseFloat(remainder)).toFixed(2);
            } else {
                $scope.protocol.installments[0].value = parseFloat($scope.protocol.installments[0].value) + parseFloat(remainder);
            }
        }
        $scope.recalculeInstallments();
    }

    $scope.recalculeInstallments = function(){

        var total               = parseFloat($scope.protocol.total - $scope.protocol.entry_value);
        var qtd                 = parseInt($scope.protocol.qtd_installments);

        $scope.totalInstallment = 0;
        $scope.totalRemainder   = 0;

        _.each($scope.protocol.installments, function(installment){
            $scope.totalInstallment = $scope.totalInstallment + parseFloat(installment.value);
        });

        $scope.totalRemainder = parseFloat(total - $scope.totalInstallment).toFixed(2);
    }

    $scope.showEntry = function(entry)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'show_entry.modal.html',
            controller     : 'financial_entry.show',
            size           : 'lg',
            resolve        : {
                entry: function(){
                    return angular.copy(entry)
                }
            }
        });

        modalInstance.result.then(function() {

        });
    }

    $scope.makeInvoice = function(entry)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.title = 'Deseja criar nota fiscal para esta venda?';
                $scope.cancel_text = 'Não';
                $scope.optionLeft = {
                    label   : 'NFe | NFSe',
                    value   : 55
                }
                $scope.optionRight = {
                    label   : 'NFCe',
                    value   : 65
                }

                $scope.confirm = function(value) {
                    $uibModalInstance.close(value);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(data) {
            var data = {
                'mod'                   : data,
                "financial_entry_id"    : entry.id,
                'transacted_at'         : entry.transacted_at //Caso seja recorrente, preciso saber referente qual mes estou passando
            };

            $http.post(api+'invoice', data).then(function(response){
                $scope.protocol.financial.invoice_id = response.data.id;
                var modalInstance = $uibModal.open({
                    animation      : true,
                    ariaLabelledBy : 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl    : server+"/views/m/invoice/show",
                    controller     : 'invoice.show',
                    size           : 'lg',
                    resolve        : {
                        invoice_id     : $scope.protocol.financial.invoice_id,
                        showInModal    : true
                    }
                });
            });
        });
    }

    $scope.showInvoice = function(invoiceId)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/invoice/show",
            controller     : 'invoice.show',
            size           : 'lg',
            resolve        : {
                invoice_id     : invoiceId,
                showInModal    : true,
                origin         : function(){
                       return 'protocol'      
                }
            }
        });
    }

    $scope.print = function(num){
        $scope.num = num;
        $scope.printing = true;

        $http.get('protocol/'+$scope.num+'/print').then(function(response){
            $scope.printing = false;

            $rootScope.print(response.data);

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.confirm = function(){
        $uibModalInstance.close($scope.protocol);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('protocol')
.controller('discount', ['$scope', '$state','$uibModalInstance','protocol', '$rootScope', '$users', '$localStorage', 'users', '$uibModal', '$client',
function($scope, $state, $uibModalInstance, protocol, $rootScope, $users, $localStorage, users, $uibModal, $client) {

    $scope.title        = 'Deseja atualizar?';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';
    $scope.pCredntials  = false;
    $scope.discountLiberation = false;
    $scope.discount     = {
        type : 'value',
        percentage : 0
    }

    $scope.user = users;
    $scope.permissionsHeight = [];

    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
        _.each($scope.users, function(row){
            if ( parseFloat($scope.user.discount_limit * 100) < parseFloat(row.discount_limit * 100) ) {
                $scope.permissionsHeight.push(row);
            }
        });
    })


    $scope.protocol = protocol;

    $scope.showReason = $state.current.name == 'cashier' ? false : true;
    $scope.getMax = function(){
        if( $scope.protocol.items ){
            var rTotal = _.reduce(_.filter($scope.protocol.items, (row) => {
                return row.allow_discount == 1 && row.service.value_type != 'blocked';
            }), function(memo, row){ return $rootScope.sumFloat(memo, row.total); }, 0);
        }

        if( $scope.protocol.services )
            var rTotal = _.reduce($scope.protocol.services, function(memo, row){ return $rootScope.sumFloat(memo, row.total); }, 0);

        if ($scope.protocol.manual)
            var rTotal = $scope.protocol.value;

        if (!$scope.discountLiberation) {
            if( $scope.discount.type == 'percentage' ){
                $scope.rLimitDiscount = $scope.user.discount_limit * 100;
            }else{
                $scope.rLimitDiscount = rTotal * $scope.user.discount_limit;
            }
        }
        return rTotal;
    }

    $scope.updateTotal = function(){
        var rTotal = $scope.getMax();
        if( $scope.discount.type == 'value' ){
            if($scope.protocol.discount > 0 && $scope.protocol.discount <= rTotal){
                if ($scope.protocol.reduction_value && $scope.protocol.reduction_value > 0) {
                    $scope.protocol.total = ($scope.protocol.value - $scope.protocol.reduction_value) - $scope.protocol.discount;
                } else {
                    $scope.protocol.total = $scope.protocol.value - $scope.protocol.discount;
                }
            } else {
                $scope.protocol.total = $scope.protocol.value;
            }
        }

        if( $scope.discount.type == 'percentage' ){
            if($scope.discount.percentage > 0 && $scope.discount.percentage <= 100 && ( ($scope.protocol.reduction_value && $scope.protocol.reduction_value == 0) || !$scope.protocol.reduction_value )){
                $scope.protocol.total = ($scope.protocol.value - (rTotal * $scope.discount.percentage)).toFixed(2);
            } else {
                if ($scope.protocol.reduction_value && $scope.protocol.reduction_value > 0) {
                    $scope.protocol.total = ($scope.protocol.itemsValue - (rTotal * $scope.discount.percentage)).toFixed(2);
                } else {
                    $scope.protocol.total = $scope.protocol.value;
                }
            }
            $scope.protocol.discount = (parseFloat($scope.protocol.value) - parseFloat($scope.protocol.total)).toFixed(2);
        }
    }

    $scope.getPermissionDiscount = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', '$http', 'ngToast', function($state, $scope, $uibModalInstance, $client, $http, ngToast) {

                $scope.title = 'Usuários com maiores permissões de desconto!';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Confirmar';

                $scope.pCredntials  = true;
                $scope.credentials ={
                    user_id     : '',
                    password    : ''
                }

                $scope.confirm = function() {
                    $http.post(api+'auth/authenticate_password', $scope.credentials).then(function(response){
                        $scope.passwordConfirm = response.data.password;
                        if ($scope.passwordConfirm == true) {
                            $users.get({ id: $scope.credentials.user_id, checkDiscountPermission: $scope.$parent.rLimitDiscount}).$promise.then(function(data){
                                $scope.user_release_discount = data.data;
                                if( $scope.$parent.discount.type == 'percentage' ){
                                    $scope.$parent.rLimitDiscount = data.data.discount_limit * 100;
                                }else{
                                    $scope.$parent.rLimitDiscount = $scope.$parent.protocol.total * data.data.discount_limit;
                                }
                                $scope.$parent.discountLiberation = true;
                                $scope.getMax($scope.$parent.rLimitDiscount);
                                $uibModalInstance.close();
                            }, function(error) {
                                console.log(error);
                                if (error.status == 404) {
                                    $state.go('notFound');
                                }
                            });
                        }
                    },function(error){
                        ngToast.danger({
                            content: 'Senha inválida',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $uibModalInstance.close();
                    });

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.confirm = function() {
        $scope.protocol.status = 'discount_request';
        if ($scope.protocol.reduction_value && $scope.protocol.reduction_value > 0 && $scope.discount.type == 'percentage') {
            $scope.protocol.discount = $scope.protocol.discount - parseFloat($scope.protocol.reduction_value);
        }
        $uibModalInstance.close($scope.protocol);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('protocol')
.controller('add_service', ['$state', '$scope','$uibModalInstance','$uibModal', 'NgTableParams','$service', 'ngToast', 'find', 'qtd', 'origin','$rootScope', '$serviceGroup', '$stateParams', '$users', '$localStorage', 'type', '$custom_input', '$service_category_group', '$companyInvoice',
function($state, $scope, $uibModalInstance,$uibModal,NgTableParams,$service, ngToast, find, qtd, origin, $rootScope, $serviceGroup, $stateParams, $users, $localStorage, type, $custom_input, $service_category_group, $companyInvoice) {

    var $ctrl = this;
    var list_items_total = 0;
    $scope.company = $localStorage.company;
    $scope.width_table = 870;

    $scope.listService = {
        items           : [],
    }

    $scope.showListServices = true;
    var width = screen.width;

    if(width < 1280){
        $scope.width_table = width;
    }

    $scope.blockConfirm         = false;
    $scope.hasDiscountProtocol  = false;
    $scope.hasDiscountValue     = false;
    $scope.devolution           = false;
    $scope.block_discount       = false;
    $scope.decimals             = $scope.company.decimals_param;
    $scope.title                = 'Adicionar';
    $scope.cancel_text          = 'Cancelar';
    $scope.success_text         = 'Concluído';
    $scope.textButton           = 'Adicionar a venda';
    $scope.selected_service     = false;
    $scope.quantity             = qtd;
    $scope.value                = 0;
    $scope.total                = 0;
    $scope.name                 = '';
    $scope.selectedOffer        = null;
    $scope.allow_discount       = 1;
    $scope.origin               = $state.current.name == 'service' ? 'service' : null;
    $scope.user                 = $localStorage.user.data;
    $scope.show_addItem         = true;
    $scope.label                = false;
    $scope.check_value          = true;
    $scope.quantityProduction   = 0;
    $scope.modelProductionQtd   = 1;
    $scope.setQuantityProduction = false;
    $scope.textInfo              = {
        info : "A capacidade de Produção refere-se a quanto desse insumo"+
        " é capaz de produzir com a quantidade adicionada dele,"+ 
        " Padrão equivale a 1 para 1, indefinido equivale 1 para N,"+
        " e Customizado equivale a 1 para Número a ser escolhido."
    }
    
    
    $users.query({count:1000}).$promise.then(function(response){
        $scope.users = response.data;
        _.each($scope.users, function(row){
            if (row.user_data_id == $scope.user.id) {
                $scope.userSelected = row;
            }
        });
    })
    
    switch (origin) {
		case "movement_stock":
			$scope.hide_params = true;
			if (type == "debit") {
				$scope.check_stock = true;
			} else {
				$scope.check_stock = false;
			}
			$scope.check_value = false;
			$scope.textButton = "Confirmar quantidade";
			break;

		case "service_group_show":
			$scope.check_stock = false;
			$scope.all_label_group = true;
			$scope.textButton = "Confirmar quantidade";
			$scope.hide_params = false;
			$scope.service_group = true;
			break;

		case "print_label":
			$scope.check_stock = false;
			$scope.textButton = "Confirmar quantidade";
			$scope.service_group = false;
			$scope.all_label_group = false;
			$scope.hide_params = true;
			break;

		case "purchase":
			$scope.check_stock = true;
			$scope.all_label_group = true;
			$scope.textButton = "Confirmar quantidade";
			$scope.hide_params = false;
			$scope.purchase_id = true;
			break;

		case "invoice":
			if (type == 1) {
				$scope.show_addItem = false;
				$scope.label = false;
				$scope.check_stock = true;
				$scope.hide_params = false;
			} else {
				$scope.show_addItem = false;
				$scope.label = false;
				$scope.check_stock = false;
				$scope.hide_params = false;
			}
			break;

		case "protocol_os":
			$scope.check_stock = true;
			$scope.hide_params = false;
			$scope.textButton = "Adicionar a venda";
			break;

		case "cashier_devolution":
			$scope.block_discount = true;
			$scope.devolution = true;
			$scope.hide_params = false;
			$scope.textButton = "Adicionar a venda";
			break;

		case "cashier":
			$scope.check_stock = true;
			$scope.hide_params = false;
			$scope.textButton = "Adicionar a venda";
			break;

		case "cashier_looking":
			$scope.check_stock = false;
			$scope.hide_params = true;
			$scope.show_addItem = false;
			$scope.check_value = false;
			$scope.textButton = "Adicionar a venda";
			break;

		case "schedule":
			$scope.show_addItem = false;
			$scope.check_stock = true;
			$scope.check_value = false;
			$scope.textButton = "Adicionar ao agendamento";
			break;

		case "client_evaluation":
			$scope.show_addItem = false;
			$scope.check_value = false;
			$scope.check_stock = true;
			$scope.hide_params = false;
			$scope.textButton = "Adicionar a orçamento";
			break;

		case "users":
			$scope.check_stock = false;
			$scope.textButton = "Adicionar a lista";
			$scope.show_addItem = true;
			$scope.label = false;
			$scope.hide_params = true;
			break;

		case "serviceSupply":
			$scope.check_stock = false;
			$scope.textButton = "Adicionar a lista";
			$scope.show_addItem = true;
			$scope.label = false;
			$scope.hide_params = true;
			$scope.setserviceSupply = true;
			break;

		default:
			$scope.check_stock = true;
			$scope.textButton = "Confirmar quantidade";
			break;
	}

    $rootScope.setFocusID('searchNameService');

    $scope.selectService = function(row){
        $scope.service = row;
        $scope.selected_service = row.id;
        $scope.show_quantity = (row.value_type == 'manual') ? true : false;
        $scope.value = row.value_calculed;
        $scope.name = row.name;

        var copyText = document.getElementById("quantity");
        copyText.select();
        $scope.limitDiscount = parseFloat($scope.value) * $scope.userSelected.discount_limit;

        $scope.allow_discount       = 1;
        $scope.hasDiscountProtocol  = false;
        $scope.hasDiscountValue     = false;
        if(row.offers.length > 0){
            row.offers.forEach((item, i) => {
                if( item.available ){
                    $scope.selectedOffer    = row.offers[0];
                    $scope.selectOffer();
                }
            });
        }else{
            $scope.selectedOffer    = null;
        }
    }

    $scope.getMaxValue = function(){
        if (($scope.service.value_calculed - $scope.value) > $scope.limitDiscount) {
            ngToast.danger({
                content             : 'Usuário sem permissão para tal desconto! ',
                dismissOnTimeout    : true,
                timeout             : 5000
            });
            $scope.blockConfirm        = true;
        }else{
            $scope.blockConfirm        = false;
        }
        if ($scope.service.value_calculed < $scope.value ) {
            $scope.hasDiscountValue    = true;
        }
    }

    $scope.getListInput = function(){
        $custom_input.query({count: 1000, show_in_grid: true}).$promise.then(function(response){
            $scope.inputList = response;
            if($scope.inputList.length > 0){
                _.each($scope.inputList, function(row){
                        $scope.width_table = $scope.width_table + 150;
                })

            }
        });
    }
    $scope.getListInput();

    $scope.getListGroupCategories = function(){
        $service_category_group.query({count:10000, show_in_grid: true}).$promise.then(function(response){
            $scope.groupCategories = response;
            if($scope.groupCategories.length > 0){
                _.each($scope.groupCategories, function(row){
                    $scope.width_table = $scope.width_table + 150;
                })
            }
        });
    }
    $scope.getListGroupCategories();

    $scope.selectOffer = function(){
        if(!$scope.selectedOffer) {
            $scope.value = $scope.service.value_calculed;
            $scope.hasDiscountValue     = false;
            $scope.recalc();
            return;
        }
        if ($scope.service.type == 'E'){
            $scope.value = $scope.selectedOffer.price;
        } else {
            if( $scope.quantity < $scope.selectedOffer.minimum_quantity ){
                ngToast.danger({
                    content             : 'Quantidade minima para essa promoção é '+parseFloat($scope.selectedOffer.minimum_quantity).toFixed(0),
                    dismissOnTimeout    : true,
                    timeout             : 3000
                });
                $scope.selectedOffer = null;
                return;
            }
            $scope.value = $scope.selectedOffer.price_calculed;
        }
        $scope.hasDiscountValue     = true;
        $scope.recalc();
    }

    $scope.recalc = function()
    {
        if(!$scope.service)
            return;

        if (parseFloat($scope.service.value_calculed) !== parseFloat($scope.value) && parseFloat($scope.service.value_calculed) > parseFloat($scope.value)) {
            $scope.allow_discount   = 0;
        } else {
            $scope.allow_discount   = 1;
        }

        if( $scope.selectedOffer && $scope.quantity < $scope.selectedOffer.minimum_quantity ){
            $scope.selectedOffer = null;
            $scope.value = $scope.service.value_calculed;
        }
        $scope.total = $scope.quantity * $scope.value;
    }

    $scope.$watch()

    $scope.$watchGroup(['value', 'quantity'], function(newValues, oldValues, scope) {
        $scope.recalc();
    });

    $scope.params = {}
    if( find )
        $scope.params.find = find;

    if (type && type === 'is_input') {
        $scope.decimals    = 4;
    }


    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 15,
        filter: $scope.params,
        modal: type && type === 'is_input' ? '' : true
    }, {
        counts: [],
        filterDelay: 0,

        getData: function(params) {
            if($scope.service_group){
                return $serviceGroup.get({id: $scope.id ? $scope.id : $stateParams.id, company_id: $scope.company_id}).$promise.then(function(data){
                    $scope.group    = data;
                    $scope.group.services = _.sortBy(data.services, 'name');
                    _.each( $scope.group.services, function(row){
                        row.group = {external_reference : $scope.group.external_reference}
                    })
                    return $scope.group.services;
                });
            }
            if ($scope.purchase_id) {
                return $companyInvoice.query({id: $stateParams.id}).$promise.then(function(data){
                    $scope.services    = data.items;
                    $scope.group       = data;
                    $scope.data = _.sortBy($scope.services, 'name');
                    var items = [];
                    _.each($scope.services, function(row){
                        items.push(row.service);
                    })
                    
                    var itemsT = _.uniq(items, 'id');
                    $scope.group.services = itemsT;
                    
                    return itemsT;
                });
            }else{
                return $service.query(params.url()).$promise.then(function(data) {
                    params.total(data.total);
                    return data.data;
                });
            }
        }
    });

    $scope.check_requirements = function(){
        if($scope.check_stock){
            if( $scope.service.stock_control && parseFloat($scope.service.real_stock) < parseFloat($scope.quantity) && !$scope.devolution){
                ngToast.danger({
                    content             : 'Estoque insuficiente!',
                    dismissOnTimeout    : true,
                    timeout             : 3000
                });
                return false;
            }
        }

        if($scope.check_value){
            if( !$scope.total && !$scope.service.value_calculed ){
                ngToast.danger({
                    content             : 'Defina o valor',
                    dismissOnTimeout    : true,
                    timeout             : 3000
                });
                return false;
            }
        }

        if ($scope.selectedOffer){
            $scope.recalc();
        }

        return true;
    };

    $scope.service_item = function(){
        if($scope.check_requirements()){
            $scope.listService.items.push({
                'service_id'            : $scope.selected_service,
                'name'                  : $scope.name + ($scope.selectedOffer ? ' '+$scope.selectedOffer.description : ''),
                'description'           : $scope.service.description,
                'value'                 : $scope.value,
                'quantity'              : $scope.quantity > 0 ? $scope.quantity : 1,
                'discount'              : 0,
                'obj'                   : $scope.service,
                'note'                  : $scope.note,
                'total'                 : $scope.total > 0 ? $scope.total : $scope.service.value_calculed * $scope.quantity,
                'offer_id'              : $scope.selectedOffer ? $scope.selectedOffer.id : null,
                'allow_discount'        : $scope.selectedOffer ? 0 : $scope.allow_discount,
                'quantityProduction'    : $scope.quantityProduction ,
                'typeProductionQtd'     : $scope.modelProductionQtd
            });
            $scope.list_items_total          = _.reduce($scope.listService.items, function(memo, row){ return parseFloat(memo) + parseFloat(row.total); }, 0);
            $scope.list_items_total_quantity = _.reduce($scope.listService.items, function(memo, row){ return parseFloat(memo) + parseFloat(row.quantity); }, 0);
        };
        $scope.quantity     = qtd;
        $scope.note         = '';
    };

    $scope.add_items = function(){
        if ($scope.blockConfirm)
        return false;
        if(_.where($scope.listService.items, {service_id: $scope.selected_service}).length > 0 ) {
            ngToast.warning({
                content             : 'Este item já foi adicionado <i class="fa fa-exclamation-triangle"/>',
                dismissOnTimeout    : true,
                timeout             : 3000
            });
            return;
        }else{
            $scope.service_item();
        };
        if ($scope.tableParams._params.filter.external_reference) {
            $rootScope.setFocusID('searchCodeService');
        } else{
            $rootScope.setFocusID('searchNameService');
        }

    };

    $scope.add_all_items = function(){
        $scope.group.services.map(function(item){
            if(item.real_stock > 0){
                $scope.listService.items.push({
                    'service_id'            : item.id,
                    'name'                  : item.name,
                    'description'           : item.description,
                    'value'                 : item.value,
                    'quantity'              : parseInt(item.real_stock),
                    'discount'              : 0,
                    'obj'                   : item,
                    'note'                  : '',
                    'total'                 : item.value_calculed * item.real_stock,
                    'offer_id'              : null,
                    'allow_discount'        : 0
                });
            }
            $scope.list_items_total = _.reduce($scope.listService.items, function(memo, row){ return parseFloat(memo) + parseFloat(row.total); }, 0);
        });
    }

    $scope.confirm = function() {
        if ($scope.blockConfirm) {
            return;
        }
        if($scope.check_requirements()){
            if($scope.listService.items.length > 0){
                $uibModalInstance.close(
                    $scope.listService.items
                );
            }else{
                $uibModalInstance.close({
                    service               : $scope.selected_service,
                    quantity              : $scope.quantity,
                    value                 : $scope.value,
                    total                 : $scope.total,
                    note                  : $scope.note,
                    name                  : $scope.name + ($scope.selectedOffer ? ' '+$scope.selectedOffer.description : ''),
                    obj                   : $scope.service,
                    offer_id              : $scope.selectedOffer ? $scope.selectedOffer.id : null,
                    allow_discount        : $scope.selectedOffer ? 0 : $scope.allow_discount,
                    quantityProduction    : $scope.quantityProduction ,
                    typeProductionQtd     : $scope.modelProductionQtd
                    
                });
            };
        };
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.showListServiceModal = function(){
        $scope.classModalListService = !$scope.classModalListService;
        $scope.showListServices = !$scope.showListServices;
    }

    $scope.makeDiscountManual = function(){
        var fakeProtocol = {
            'value' : $scope.service.value_calculed,
            'total' : $scope.service.value_calculed,
            'discount' : 0,
            'manual' : true
        };
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'discount.modal.html',
            controller     : 'discount',
            size           : 'lg',
            resolve        : {
                protocol: function(){
                    return fakeProtocol;
                },
                users: function(){
                    return $scope.userSelected;
                }

            }
        });

        modalInstance.result.then(function(protocol) {
            $scope.value = $scope.value - protocol.discount;
            $scope.hasDiscountProtocol = true;
        });
    }

    document.querySelector('body').addEventListener('keydown', function(event) {
        var tecla = event.keyCode;
    	if(tecla == 27){
            if($scope.listService.items.length > 0){
                $uibModalInstance.close(
                    $scope.listService.items
                );
            }
    	}
    });

    $scope.showStocks = function(stocks) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectList.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.title = 'Estoque';

                $scope.label = false;

                $scope.heads = [
                    {
                        name : 'Loja',
                        field : 'name',
                        relationship : 'company'
                    },
                    {
                        name : 'Estoque',
                        field : 'real_stock'
                    },
                ];

                $scope.list = stocks;

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.showOffers =  function(offers){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectList.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance',
            function($scope, $uibModalInstance) {

                $scope.title = 'Ofertas';

                $scope.label = false;

                $scope.listOffers = [];
                _.each(offers, function(offer){
                    if(offer.available){
                        $scope.listOffers.push({
                            description : offer.description,
                            minimum_quantity : parseFloat(offer.minimum_quantity),
                            price_calculed : parseFloat(offer.price_calculed).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })
                        });
                    }    
                });

                $scope.heads = [
                    {
                        name : 'description',
                        field : 'description'
                    },
                    {
                        name: 'quantity',
                        field: 'minimum_quantity'
                    },
                    {
                        name: 'value',
                        field: 'price_calculed'
                    }
                ];

                $scope.list = $scope.listOffers;

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.validadeOffer = function(offer){
        if(offer.length == 0){
            return false;
        }else{
            let showOffer = false;
            offer.map(function(data){
                if(data.available){
                    showOffer = true;
                }
            }); 
            return showOffer;
        }
        return true;
    }

    $scope.validadeQtdOffer = function(offer){
        if(offer.length == 0){
            return false;
        }else{
            let showOffer = false;
            let count = 0;
            offer.map(function(data){
                if(data.available){
                    count++
                }
            });
            if(count>1){
                showOffer = true;
            }else{
                showOffer = false;
            }
            return showOffer;
        }
        return true;
    }

    $scope.valueOffer = function(offer){
        if(offer.length == 0){
            return false;
        }else{
            let price;
            offer.map(function(data){
                if(data.available)
                    price = data.price;
            });
            return price;
        }
    }

    $scope.selectCapacity = function(){
        if ( $scope.modelProductionQtd === 'custom' ) {
            $scope.setQuantityProduction = true;
        } else {
            $scope.setQuantityProduction = false;
        }
    }
}]);

/**
 * Factory
 */
angular.module('protocol').
factory("$protocol", function($resource, $q, $http) {

    var resource = $resource(api + 'protocol/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.updateService = function(service)
    {
        var deferred = $q.defer();
        $http.put(api+'protocol/updateService', service).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.comment = function(id, comment)
    {
        var deferred = $q.defer();
        $http.post(api+'protocol/'+id+'/comment', {comment:comment}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

        return resource;
});

angular.module('protocol').
factory("$custom_input", function($resource, $q, $http) {
    var resource = $resource(api + 'custom_input', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });
    return resource;
});
