angular.module('document_type', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('document_type').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    
    $stateProvider
    .state('document_type', {
        url         : "/document_type",
        templateUrl : server+"/views/m/document_type",
        controller  : 'document_type.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'base_data',
            label: 'Modelos de documento'
        }
    })

    .state('document_type_create', {
        url         : "/document_type/create",
        templateUrl : server+"/views/m/document_type/create",
        controller  : 'document_type.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'document_type',
            label: 'Adicionar'
        }
    })

    .state('document_type_show', {
        url         : "/document_type/:id",
        templateUrl : server+"/views/m/document_type/show",
        controller  : 'document_type.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'document_type',
            label: '{{document_type.id}}'
        }
    })

}]);

angular.module('document_type').
controller('new_document_type', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$document_type', '$suggestion', '$uibModalInstance', '$stateParams', '$client_document_type',
function($scope, $http, $auth, $q, NgTableParams, $document_type, $suggestion, $uibModalInstance, $stateParams, $client_document_type) {

    $scope.success_text         = 'Avançar';
    $scope.cancel_text          = 'Cancelar';
    $scope.addingPoso           = false;
    $scope.waiting              = false;
    $scope.types                = [];
    $scope.document             = {
        client_id :  $stateParams.id,
        dosageList : []
    };
    $scope.newDosage            = {};
    $scope.dosgeList            = [];
    $scope.cidSuggestionsList   = [];

    $document_type.query({count:100}).$promise.then(function(data) {
        $scope.types = data.data
    });

    $scope.selectType = function(doc_type){
        $scope.document.type = doc_type;
    }

    $scope.getCidSuggestions = function(){
        $scope.cidSuggestionsList =[];
        $suggestion.query({type: 'cidDental'}).$promise.then(function(response){
            $scope.cidSuggestionsList = response;
        });
    }
    $scope.getCidSuggestions();

    $scope.getDosageList = function(){
        $scope.dosgeList =[];
        $suggestion.query({type: 'dosageDental'}).$promise.then(function(response){
            $scope.dosgeList = response;
        });
    }
    $scope.getDosageList();

    $scope.addDosage = function(){
        $scope.newDosage = null;
        $scope.addingDosage = !$scope.addingDosage;
    }

    $scope.saveNewDosage = function(id){
        var dosage = $scope.dosgeList.find((row ) => row.id == id);
        $scope.document.dosageList.push(dosage);
        $scope.addDosage();
    }

    $scope.checkTags = function(tag){
        if(!$scope.document.type || !$scope.document.type.doc)
            return false;

        return $scope.document.type.doc.includes(tag);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.confirm = function() {
        $uibModalInstance.close($scope.document);
    }
}]);

angular.module('document_type').
controller('document_type.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$document_type', 
function($scope, $http, $auth, $q, NgTableParams, $document_type) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $document_type.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('document_type').
controller('document_type.create', ['$scope', '$state', '$document_type', function($scope, $state, $document_type) {

    $scope.options = {
        language: 'pt',
        name: 'clauses'
    };

    $scope.document_type = {
    };

    $scope.addTag = function(tag){
        var editor = CKEDITOR.instances.doc;
        editor.insertText(tag);
    }

    $scope.save = function() {
        $scope.waiting = true;
        $document_type.save($scope.document_type).$promise.then(function(data) {
            $scope.waiting = false;
            $state.go('document_type_show', {
                id: data.id
            });
        }, function(error){
            $scope.waiting = false;
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('document_type').
controller('document_type.show', ['$state', '$scope', '$stateParams', '$uibModal', '$document_type', function($state, $scope, $stateParams, $uibModal, $document_type) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $document_type.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.document_type = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.addTag = function(tag){
        if(CKEDITOR.doc){
            var editor = CKEDITOR.doc.clauses;
            editor.insertText(tag);
        }
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$document_type', function($scope, $uibModalInstance, $document_type) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var document_type = $scope.$parent.document_type;

                $scope.confirm = function() {
                    $document_type.update({
                        id: document_type.id
                    }, document_type).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$document_type', function($state, $scope, $uibModalInstance, $document_type) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var document_type = $scope.$parent.document_type;

                $scope.confirm = function() {
                    $document_type.delete({id:document_type.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('document_type');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };
}]);


/**
 * Factory
 */
angular.module('document_type').
factory("$document_type", function($resource, $http, $q) {
    var resource = $resource(api + 'document_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});

angular.module('document_type').
factory("$client_document_type", function($resource, $http, $q) {
    var resource = $resource(api + 'client_document_type/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.print = function(id) {
        var deferred = $q.defer();
        $http.post(api+'client_document_type/'+id+'/print').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getInitialDocument = function(data) {
        var deferred = $q.defer();
        $http.post(api+'client_document_type/get_initial_document', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
