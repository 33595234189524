(function() {
    var module = angular.module("highcharts", []);

    module.directive('hcMiniChart', function($compile) {
        return {
            restrict: 'E',
            replace: true,
            template: '<div></div>',
            scope: {
                data: '='
            },
            link: function(scope, element) {

                var data = [];

                angular.forEach(scope.data, function(value, key) {
                    data.push([value.name, value.value]);
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        height: 60,
                        width: 190,
                        backgroundColor: null
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        visible: false
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        area: {
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, Highcharts.getOptions().colors[0]],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                ]
                            },
                            marker: {
                                radius: 0
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                    },
                    series: [{
                        type: 'area',
                        data: data
                    }]
                });

                scope.$watch('data', function() {
                    var data = [];
                    angular.forEach(scope.data, function(value, key) {
                        data.push([value.name, value.value]);
                    });
                    chart.series[0].setData(data, true, true);
                });

            }
        };
    });

    module.directive('hcStoreChart', function($compile, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            template: '<div></div>',
            scope: {
                data: '='
            },
            link: function(scope, element) {

                var data = [];

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        height: 250,
                        backgroundColor: null
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        visible: false
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        area: {
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, Highcharts.getOptions().colors[0]],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                ]
                            },
                            marker: {
                                radius: 1
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                    },
                    series: [{
                        type: 'area',
                        data: []
                    }]
                });

                function insertPoint() {
                    var point = Math.floor(Math.random() * (10 - 1000 + 1) + 10);
                    var shift = chart.series[0].data.length > 100;
                    chart.series[0].addPoint([new Date(), point], true, shift);
                    $timeout(function() {
                        insertPoint();
                    }, 500);
                }

                insertPoint();
            }
        };
    });

    module.directive('hcMasterChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];

                angular.forEach(scope.data, function(value, key) {
                    data.push([value.name, value.value]);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        height: 200,
                        width: size,
                        zoomType: 'x'
                    },
                    title: {
                        text: scope.series
                    },
                    xAxis: {
                        type: 'datetime'
                    },
                    yAxis: {
                        title: {
                            text: 'Totais'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: true
                    },
                    plotOptions: {
                        area: {
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, Highcharts.getOptions().colors[0]],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                ]
                            },
                            marker: {
                                radius: 2
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                    },
                    series: [{
                        type: 'area',
                        name: scope.series,
                        data: data
                    }]
                });

                scope.$watch('data', function() {
                    var data = [];
                    angular.forEach(scope.data, function(value, key) {
                        console.log(value.name);
                        data.push([value.name, value.value]);
                    });
                    chart.series[0].setData(data, true);
                });
            }
        };
    });

    module.directive('hcHourChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                sufix: '@',
                size: '@',
                data: '=',
                categories: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 500;

                var data = [];
                var categories = [];

                angular.forEach(scope.data, function(value, key) {
                    categories.push(value.name + scope.sufix);
                    data.push([value.name + scope.sufix, value.value]);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    colors: ['#F44336', '#FFC107', '#4CAF50', '#2196F3', '#673AB7', '#795548'],
                    chart: {
                        height: 400,
                        width: size,
                        // zoomType: 'x',
                        type: 'column',
                        options3d: {
                            enabled: true,
                            alpha: 10,
                            beta: 25,
                            depth: 70
                        }
                    },
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        scale: 3,
                        fallbackToExportServer: false
                    },
                    title: {
                        text: scope.title
                    },
                    subtitle: {
                        text: scope.subtitle ? scope.subtitle : null
                    },
                    xAxis: {
                        categories: categories,
                        //crosshair: true
                    },
                    yAxis: {
                        allowDecimals: false,
                        title: {
                            text: null
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: true
                    },
                    plotOptions: {
                        column: {
                            colorByPoint: true,
                            pointPadding: 0.2,
                            borderWidth: 0,
                            depth: 25
                        }
                    },
                    series: [{
                        type: 'column',
                        name: scope.series,
                        data: data,
                        dataLabels: {
                            enabled: true
                        }
                    }]
                });

                scope.$watch('data', function() {
                    var data = [];
                    var categories = [];
                    angular.forEach(scope.data, function(value, key) {
                        categories.push(value.name + scope.sufix);
                        data.push([value.name + scope.sufix, value.value]);
                    });

                    chart.xAxis[0].setCategories(categories, true, true);
                    chart.series[0].setData(data, true, true);
                });
            }
        };
    });

    module.directive('hcGenderChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                series: '@',
                sufix: '@',
                size: '@',
                categories: '=',
                male: '=',
                female: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 500;

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        height: 200,
                        width: size,
                        zoomType: 'x',
                        type: 'column'
                    },
                    title: {
                        text: scope.title
                    },
                    xAxis: {
                        categories: scope.categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Totais'
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    tooltip: {
                        enabled: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Masculino',
                        data: scope.male,
                        colorByPoint: false,
                        color: '#71ace9'
                    }, {
                        name: 'Feminino',
                        data: scope.female,
                        colorByPoint: false,
                        color: '#e971d1'
                    }]
                });

                scope.$watch('categories', function() {
                    chart.xAxis[0].setCategories(scope.categories, true, true);
                    chart.series[0].setData(scope.male, true);
                    chart.series[1].setData(scope.female, true);
                });
            }
        };
    });

    module.directive('hcRatingChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                series: '@',
                sufix: '@',
                size: '@',
                categories: '=',
                votes: '=',
                rating: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 500;

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        height: 200,
                        width: size,
                        zoomType: 'x',
                        type: 'column'
                    },
                    title: {
                        text: scope.title
                    },
                    xAxis: {
                        categories: scope.categories,
                        // crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Totais'
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    tooltip: {
                        enabled: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Avaliação',
                        data: scope.rating,
                        colorByPoint: false,
                        color: '#ffc107'
                    }, {
                        name: 'Votos',
                        data: scope.votes,
                        colorByPoint: false,
                        color: '#5d4807'
                    }]
                });

                scope.$watch('categories', function() {
                    chart.xAxis[0].setCategories(scope.categories, true, true);
                    chart.series[0].setData(scope.rating, true);
                    chart.series[1].setData(scope.votes, true);
                });
            }
        };
    });

    module.directive('hcPriceTotalChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];
                var categories = [];
                var qtds = [];
                var totals = [];
                var margins = [];

                angular.forEach(scope.data, function(value, key) {
                    totals.push({
                        y: parseFloat(value.total),
                        margem: parseFloat(value.margem)
                    });
                    categories.push(value.name);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        type: 'column',
                        height: 400,
                        width: 550
                    },
                    title: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true,
                        min: 0,
                        max: 4
                    },
                    yAxis: [{
                        min: 0,
                        title: {
                            text: 'Quantidade'
                        }
                    }, {
                        title: {
                            text: 'Valor Total'
                        },
                        opposite: true
                    }],
                    legend: {
                        shadow: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr>' +
                            '<td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>R$ {point.y:.1f}</b></td>' +
                            '</tr>' +
                            '<tr>' +
                            '<td style="color:{series.color};padding:0">Margem: </td>' +
                            '<td style="padding:0"><b>{point.margem:.1f}%</b></td>' +
                            '</tr>',
                        footerFormat: '</table>',
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            // grouping: false,
                            // shadow: false,
                            borderWidth: 0,
                        }
                    },
                    series: [{
                        name: 'Total',
                        color: 'rgba(186,60,61,.9)',
                        data: totals,
                        dataLabels: {
                            enabled: true,
                            format: '{point.margem:.1f}%'
                        }
                    }],
                });

                scope.$watch('data', function() {
                    categories = [];
                    totals = [];
                    angular.forEach(scope.data, function(value, key) {
                        totals.push({
                            y: parseFloat(value.total),
                            margem: parseFloat(value.margem)
                        });
                        categories.push(value.name);
                    });
                    chart.xAxis[0].setCategories(categories, true, true);
                    chart.series[0].setData(totals, true);
                });

            }
        };
    });

    module.directive('hsPriceTotalChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];
                var categories = [];
                var qtds = [];
                var totals = [];
                var margins = [];

                angular.forEach(scope.data, function(value, key) {
                    totals.push({
                        y: parseFloat(value.total),
                        margem: parseFloat(value.margem)
                    });
                    categories.push(value.name);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        type: 'column',
                        height: 400,
                        width: 550
                    },
                    title: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true,
                        min: 0,
                        max: 4,
                        scrollbar: {
                            enabled: true,
                            height: 30
                        },
                    },
                    yAxis: [{
                        min: 0,
                        title: {
                            text: 'Quantidade'
                        }
                    }, {
                        title: {
                            text: 'Valor Total'
                        },
                        opposite: true
                    }],
                    legend: {
                        shadow: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr>' +
                            '<td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>R$ {point.y:.1f}</b></td>' +
                            '</tr>',
                        footerFormat: '</table>',
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            // grouping: false,
                            // shadow: false,
                            borderWidth: 0,
                        }
                    },
                    series: [{
                        name: 'Total',
                        color: 'rgba(186,60,61,.9)',
                        data: totals,
                        dataLabels: {
                            enabled: true,
                            format: 'R${point.y:.2f}'
                        }
                    }]
                });

                scope.$watch('data', function() {
                    categories = [];
                    totals = [];
                    angular.forEach(scope.data, function(value, key) {
                        totals.push({
                            y: parseFloat(value.total),
                            margem: parseFloat(value.margem)
                        });
                        categories.push(value.name);
                    });
                    chart.xAxis[0].setCategories(categories, true, true);
                    chart.series[0].setData(totals, true);
                    chart.redraw();
                });

            }
        };
    });

    module.directive('hsTotalChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];
                var categories = [];
                var qtds = [];
                var totals = [];
                var margins = [];

                angular.forEach(scope.data, function(value, key) {
                    totals.push({
                        y: parseFloat(value.total),
                        margem: parseFloat(value.margem)
                    });
                    categories.push(value.name);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        type: 'column',
                        width: 800,
                        className: 'customHighCharts'
                    },
                    title: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true,
                        min: 0,
                        max: 4,
                        scrollbar: {
                            enabled: true,
                            height: 30
                        },
                    },
                    yAxis: [{
                        min: 0,
                        title: {
                            text: 'Quantidade'
                        }
                    }, {
                        title: {
                            text: 'Valor Total'
                        },
                        opposite: true
                    }],
                    legend: {
                        shadow: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr>' +
                            '<td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>R$ {point.y:.1f}</b></td>' +
                            '</tr>',
                        footerFormat: '</table>',
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            // grouping: false,
                            // shadow: false,
                            borderWidth: 0,
                        }
                    },
                    series: [{
                        name: 'Total',
                        color: 'rgba(186,60,61,.9)',
                        data: totals,
                        dataLabels: {
                            enabled: true,
                            format: 'R${point.y:.2f}'
                        }
                    }]

                });

                scope.$watch('data', function() {
                    categories = [];
                    totals = [];
                    angular.forEach(scope.data, function(value, key) {
                        totals.push({
                            y: parseFloat(value.total),
                            margem: parseFloat(value.margem)
                        });
                        categories.push(value.name);
                    });
                    chart.xAxis[0].setCategories(categories, true, true);
                    chart.series[0].setData(totals, true);
                    chart.redraw();
                });

            }
        };
    });

    module.directive('hcPieChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];
                var categories = [];
                var qtds = [];
                var totals = [];
                var margins = [];

                angular.forEach(scope.data, function(value, key) {
                    totals.push({
                        y: parseFloat(value.total),
                        margem: parseFloat(value.margem)
                    });
                    categories.push(value.name);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        // height:400,
                        // width: 550,
                        type: 'pie'
                    },
                    title: {
                        text: scope.subtitle
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                style: {
                                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                                }
                            }
                        }
                    },
                    series: [{
                        name: 'Total',
                        color: 'rgba(186,60,61,.9)',
                        data: totals,
                        dataLabels: {
                            enabled: true,
                            format: 'R${point.y:,.2f}'
                        }
                    }]
                });

                scope.$watch('data', function() {
                    categories = [];
                    totals = [];
                    angular.forEach(scope.data, function(value, key) {
                        totals.push({
                            y: parseFloat(value.total),
                            name: value.name
                        });
                    });
                    chart.series[0].setData(totals, true);
                });

            }
        };
    });

    module.directive('hsFinancialChart', function() {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div></div>',
            scope: {
                title: '@',
                subtitle: '@',
                series: '@',
                size: '@',
                data: '='
            },
            link: function(scope, element) {

                var size = scope.size ? parseInt(scope.size) : 1150;

                var data = [];
                var categories = [];
                var qtds = [];
                var totals = [];
                var margins = [];

                angular.forEach(scope.data, function(value, key) {
                    totals.push({
                        y: parseFloat(value.total),
                        margem: parseFloat(value.margem)
                    });
                    categories.push(value.name);
                });

                Highcharts.setOptions({
                    lang: {
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                        loading: ['Atualizando o gráfico...aguarde'],
                        contextButtonTitle: 'Exportar gráfico',
                        decimalPoint: ',',
                        thousandsSep: '.',
                        downloadJPEG: 'Baixar imagem JPEG',
                        downloadPDF: 'Baixar arquivo PDF',
                        downloadPNG: 'Baixar imagem PNG',
                        downloadSVG: 'Baixar vetor SVG',
                        printChart: 'Imprimir gráfico',
                        rangeSelectorFrom: 'De',
                        rangeSelectorTo: 'Para',
                        rangeSelectorZoom: 'Zoom',
                        resetZoom: 'Limpar Zoom',
                        resetZoomTitle: 'Voltar Zoom para nível 1:1',
                    }
                });

                var chart = Highcharts.chart(element[0], {
                    chart: {
                        type: 'line',
                        width: 800,
                        className: 'customHighCharts'
                    },
                    title: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true,
                        min: 0,
                        max: 4,
                        scrollbar: {
                            enabled: true,
                            height: 30
                        },
                    },
                    yAxis: [{
                        min: 0,
                        title: {
                            text: 'Quantidade'
                        }
                    }, {
                        title: {
                            text: 'Valor Total'
                        },
                        opposite: true
                    }],
                    legend: {
                        shadow: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr>' +
                            '<td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>R$ {point.y:.1f}</b></td>' +
                            '</tr>',
                        footerFormat: '</table>',
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            // grouping: false,
                            // shadow: false,
                            borderWidth: 0,
                        }
                    },
                    series: [{
                        name: 'Total',
                        color: 'rgba(186,60,61,.9)',
                        data: totals,
                        dataLabels: {
                            enabled: true,
                            format: 'R${point.y:.2f}'
                        }
                    }]

                });

                scope.$watch('data', function() {
                    categories = [];
                    totals = [];
                    angular.forEach(scope.data, function(value, key) {
                        totals.push({
                            y: parseFloat(value.total),
                            margem: parseFloat(value.margem)
                        });
                        categories.push(value.name);
                    });
                    chart.xAxis[0].setCategories(categories, true, true);
                    chart.series[0].setData(totals, true);
                    chart.redraw();
                });

            }
        };
    });
}());
