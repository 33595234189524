angular.module('chat_status', ['ui.router', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('chat_status').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('chat_status', {
        url         : "/chat_status",
        templateUrl : server+"/views/m/chat_status",
        controller  : 'chat_status.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Status de atendimentos'
        }
    })

    .state('chat_status_create', {
        url         : "/chat_status/create",
        templateUrl : server+"/views/m/chat_status/create",
        controller  : 'chat_status.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'chat_status',
            label: 'Adicionar'
        }
    })

    .state('chat_status_show', {
        url         : "/chat_status/:id",
        templateUrl : server+"/views/m/chat_status/show",
        controller  : 'chat_status.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'chat_status',
            label: '{{chat_status.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('chat_status').
controller('chat_status.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$chat_status', function($scope, $http, $auth, $q, NgTableParams, $chat_status) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $chat_status.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('chat_status').
controller('chat_status.create', ['$scope', '$state', '$chat_status', function($scope, $state, $chat_status) {
  $scope.chat_status = {
    'name'        : '',
    'color'       : '',
    'allow_chat'  : ''
  }

    $scope.save = function() {
        $chat_status.save($scope.chat_status).$promise.then(function(data) {
            $state.go('chat_status_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('chat_status').
controller('chat_status.show', ['$state', '$scope', '$stateParams', '$uibModal', '$chat_status','_', function($state, $scope, $stateParams, $uibModal, $chat_status,_) {

    $scope.editing = false;
    $scope._ = _;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $chat_status.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.chat_status = data.chat_status;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$chat_status', function($scope, $uibModalInstance, $chat_status) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var chat_status = $scope.$parent.chat_status;

                $scope.confirm = function() {
                    $chat_status.update({
                        id: chat_status.id
                    }, chat_status).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$chat_status', function($state, $scope, $uibModalInstance, $chat_status) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var chat_status = $scope.$parent.chat_status;

                $scope.confirm = function() {
                    $chat_status.delete({id:chat_status.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('chat_status');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('chat_status').
factory("$chat_status", function($resource) {
    var resource = $resource(api + 'chat_status/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});
