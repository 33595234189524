angular.module('process', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('process').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('process', {
        url         : "/configos",
        templateUrl : server+"/views/m/process",
        controller  : 'process.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Tipo de O.S'
        }
    })

    .state('process_create', {
        url         : "/configos/create",
        templateUrl : server+"/views/m/process/create",
        controller  : 'process.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'process',
            label: 'Adicionar'
        }
    })

    .state('process_show', {
        url         : "/configos/:id",
        templateUrl : server+"/views/m/process/show",
        controller  : 'process.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'process',
            label: '{{process.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('process').
controller('process.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$process', function($scope, $http, $auth, $q, NgTableParams, $process) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $process.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('process').
controller('process.create', ['$scope', '$state', '$process', '$department', '$uibModal', '$service', 'NgTableParams', function($scope, $state, $process, $department, $uibModal, $service, NgTableParams) {

    $scope.process = {
        status: '1',
        services: []
    };

    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $service.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.save = function() {
        $process.save($scope.process).$promise.then(function(data) {
            $state.go('process_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('process').
controller('process.show', ['$state', '$scope', '$stateParams', '$uibModal', '$process', '$department', 'NgTableParams', '$service', function($state, $scope, $stateParams, $uibModal, $process, $department, NgTableParams, $service) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $process.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        var services = [];
        _.each(data.services, function(value, key){
            services[value.id] = true;
        });
        data.services = services;
        $scope.process = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $department.query({count:1000}).$promise.then(function(response){
        $scope.departments = response.data;
    });

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $service.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$process', function($scope, $uibModalInstance, $process) {

                $scope.title        = 'Deseja atualizar?';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Concluído';

                var process = $scope.$parent.process;

                $scope.confirm = function() {
                    $process.update({
                        id: process.id
                    }, process).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$process', function($state, $scope, $uibModalInstance, $process) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var process = $scope.$parent.process;

                $scope.confirm = function() {
                    $process.delete({id:process.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('process');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);

angular.module('process')
.controller('step', ['$scope','$uibModalInstance','step', function($scope, $uibModalInstance, step) {

    var $ctrl = this;

    $ctrl.data = !step ? {} : step;

    $scope.title        = 'Deseja atualizar?';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';

    $ctrl.confirm = function() {
        $uibModalInstance.close($ctrl.data);
    };

    $ctrl.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

/**
 * Factory
 */
angular.module('process').
factory("$process", function($resource) {
    var resource = $resource(api + 'process/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
