angular.module('system_alert', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('system_alert').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('system_alert', {
        url         : "/alert",
        templateUrl : server+"/views/m/system_alert",
        controller  : 'system_alert.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Alertas'
        }
    })

    .state('system_alert_create', {
        url         : "/alert",
        templateUrl : server+"/views/m/system_alert/create",
        controller  : 'system_alert.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'system_alert',
            label: 'Novo alerta'
        }
    })

    .state('system_alert_show', {
        url         : "/alert/:id",
        templateUrl : server+"/views/m/system_alert/show",
        controller  : 'system_alert.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'system_alert',
            label: '{{alert.title}}'
        }
    })

}]);

angular.module('system_alert').
controller('system_alert.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$system_alert', 'ngToast', '$transaction', '$uibModal',
function($scope, $http, $auth, $q, NgTableParams, $system_alert, ngToast, $transaction, $uibModal) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 20
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $system_alert.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

}]);

angular.module('system_alert').
controller('system_alert.create', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$system_alert', 'ngToast', '$transaction', '$uibModal', '$state',
function($scope, $http, $auth, $q, NgTableParams, $system_alert, ngToast, $transaction, $uibModal, $state) {

    $scope.alert = {
        type    : 'alert',
        to      : 'all'
    }

    $scope.save = function() {
        $system_alert.save($scope.alert).$promise.then(function(data) {
            $state.go('system_alert_show', {
                id: data.id
            });
        });
    }
}]);

angular.module('system_alert').
controller('system_alert.show', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', '$system_alert', '$uibModal','Upload', '$city', '$tax_regime','uuid', '$stateParams', '$plans', '$transaction',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $system_alert, $uibModal, Upload, $city, $tax_regime,uuid, $stateParams, $plans, $transaction) {

    $scope.editing = false;

    $system_alert.get({id: $stateParams.id}).$promise.then(function(response){
        response.start_at = moment(response.start_at).toDate();
        response.end_at = moment(response.end_at).toDate();
        $scope.alert = response;
    });

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$system_alert', function($scope, $uibModalInstance, $system_alert) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var alert = $scope.$parent.alert;

                $scope.confirm = function() {
                    $system_alert.update({
                        id: alert.id
                    }, alert).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$system_alert', '$scope', '$uibModalInstance', function($system_alert, $scope, $uibModalInstance) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var alert = $scope.$parent.alert;

                $scope.confirm = function() {
                    $system_alert.delete({id:alert.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('system_alert');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };
}]);

angular.module('system_alert').
factory("$system_alert", function($resource, $q, $http) {
    var resource = $resource(api + 'system_alert/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.showAll = function()
    {
        var deferred = $q.defer();
        $http.get(api+'system_alert/show').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
