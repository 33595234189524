angular.module('managerial', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('managerial').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('managerial', {
        url         : "/managerial",
        templateUrl : server+"/views/m/managerial",
        controller  : 'managerial.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Gerencial'
        }
    });

    $stateProvider
    .state('managerial_dashboard', {
        url         : "/managerial_dashboard",
        templateUrl : server+"/views/m/managerial/dashboard",
        controller  : 'managerial.dashboard',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Dashboard'
        }
    });

}]);

angular.module('managerial').
controller('managerial.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$uibModal',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $uibModal) {
    $transaction.getErrors().then(function(response){
        $scope.erroCompanies = response.data;
    });

    $scope.showCompanies = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'companies.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {

                $scope.title            = 'Empresas';
                $scope.listCompanies    = $scope.$parent.erroCompanies;

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    }
}]);

angular.module('managerial').
controller('managerial.dashboard', ['$rootScope', '$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$company', '$uibModal', '$plans',
function($rootScope, $scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $company, $uibModal, $plans) {

    $scope.filters = {
        startDate : moment().startOf('month').toDate(),
        endDate : moment().endOf('month').toDate(),
        plan_id : null
    }

    $scope.feeTotals = {
        total       : 0,
        noPaid      : 0,
        paid        : 0
    }

    $scope.boletoTotals = {
        total       : 0,
        noPaid      : 0,
        paid        : 0
    }

    $scope.selectedMonth = function(int){
        date = moment($scope.filters.startDate);
        $scope.filters.startDate    = date.month(date.month()+parseInt(int)).toDate();
        $scope.filters.endDate      = moment($scope.filters.startDate).endOf('month').toDate()
        $scope.getData();
    }

    $plans.query({count: 1000}).$promise.then(function(response){
        $scope.plans = response.data;
    });

    $scope.getData = function(){
        var params = {
            start_date  : moment($scope.filters.startDate).startOf('day').format('YYYY-MM-DD HH:mm'),
            end_date    : moment($scope.filters.endDate).endOf('day').format('YYYY-MM-DD HH:mm'),
            plan_id     : $scope.filters.plan_id ? $scope.filters.plan_id : null
        }
        $transaction.getBalance(params).then(function(response){
            $scope.boletoTotals.total     = response.total ? response.total : 0;
            $scope.boletoTotals.noPaid    = response.noPaid ? response.noPaid : 0;
            $scope.boletoTotals.paid      = response.paid ? response.paid : 0;
        }, function(error){
            console.log(error);
        });
        $company.report(params).then(function(response){
            $scope.feeTotals.total     = parseFloat(response.total_master) + parseFloat(response.total_partner) > 0 ? parseFloat(response.total_master) + parseFloat(response.total_partner) : 0;
            $scope.feeTotals.master    = response.total_master ? response.total_master : 0;
            $scope.feeTotals.partner   = response.total_partner ? response.total_partner : 0;
        }, function(error){
            console.log(error);
        });
        $scope.plansReport = {
            labels  : [],
            data    : []
        };
        $plans.report(params).then(function(response){
            $scope.plansReport.labels  = _.pluck(response, 'name');
            $scope.plansReport.data    = _.pluck(response, 'total');
        }, function(error){
            console.log(error);

        });
    }
    $scope.getData();
}]);
