angular.module('category', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('category').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('category', {
        url         : "/category",
        templateUrl : server+"/views/m/category",
        controller  : 'category.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Categoria de venda'
        }
    })

    .state('category_create', {
        url         : "/category/create",
        templateUrl : server+"/views/m/category/create",
        controller  : 'category.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'category',
            label: 'Adicionar'
        }
    })

    .state('category_show', {
        url         : "/category/:id",
        templateUrl : server+"/views/m/category/show",
        controller  : 'category.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'category',
            label: '{{category.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('category').
controller('category.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$category',
function($scope, $http, $auth, $q, NgTableParams, $category) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 15
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $category.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);


/**
 * Create Controller
 */
angular.module('category').
controller('category.create', ['$scope', '$state', '$category', '$department', 'ngToast', '$localStorage',
function($scope, $state, $category, $department, ngToast, $localStorage) {

    $scope.category = {
        name : '',
        description : ''
    }

    $scope.save = function() {
        if( !$scope.category.name )
            return;

        var category = $scope.category;

        $category.save(category).$promise.then(function(data) {
            $state.go('category_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('category').
controller('category.show', ['$state', '$scope', '$stateParams', '$uibModal', '$category', 'id', 'Upload', '$service_category_group', '$uibModalInstance',
function($state, $scope, $stateParams, $uibModal, $category, id, Upload, $service_category_group, $uibModalInstance) {

    $scope.editing = true;
    $scope.categories = [];

    $scope.selectConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'full_name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1
    }

    $scope.dropdownTranslation = {
        buttonDefaultText: 'Sem Pai',
        checkAll: 'Todas',
        uncheckAll: 'Nenhuma',
        selectionCount: 'selecionada(s)',
        selectionOf: '/',
        searchPlaceholder: 'Pesquisar',
        dynamicButtonTextSuffix: 'selecionada(s)'
    };

    $scope.colorOptions = {
        format      : 'hex',
        case        : 'upper',
        inputClass  : 'form-control'
    }

    $service_category_group.query({service_id : $stateParams.id}).$promise.then(function(response){
        $scope.serviceCategoryGroups = response;
    });


    $scope.getData = function(){
        $category.get({
            id: id
        }).$promise.then(function(data) {
            $scope.category = data;
            $scope.category.up_id = data.up_id ? {id : data.up_id} : {};
        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });
    }
    $scope.getData();

    $scope.uploadImage = function(){
        Upload.upload({
            url: api+'service_category/'+$scope.category.id+'/upload_image',
            data: {
                image: $scope.category.image[0]
            }
        }).then(function (response) {
            $scope.category.image_src = response.data.image;
            ngToast.success({
                content: 'Imagem salva com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function (error) {
            ngToast.danger({
                content: 'Erro no formato da imagem',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$category', function($scope, $uibModalInstance, $category) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var category = angular.copy($scope.$parent.category);

                $scope.confirm = function() {
                    category.up_id = category.up_id && category.up_id.id ? category.up_id.id : null;
                    $category.update({
                        id: category.id
                    }, category).$promise.then(function(data) {
                        $uibModalInstance.close();
                        // $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $uibModalInstance.close();
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$category', function($state, $scope, $uibModalInstance, $category) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var category = $scope.$parent.category;

                $scope.confirm = function() {
                    $category.delete({id:category.id}).$promise.then(function(data){
                        $uibModalInstance.close();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $uibModalInstance.close();
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.selectedGroup = function() {
        var group = $scope.serviceCategoryGroups.find((row) => {return row.id = $scope.category.group_id});
        $scope.category.group = group;
    }
}]);


/**
 * Factory
 */
angular.module('category').
factory("$category", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'service_category/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});
