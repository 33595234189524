angular.module('transaction', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('transaction').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $stateProvider
    .state('gateway_transaction', {
        url         : "/gtransaction",
        templateUrl : server+"/views/m/transaction",
        controller  : 'transactions.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Transações'
        }
    })

    .state('transaction', {
        url         : "/transaction",
        templateUrl : server+"/views/m/transaction/transaction",
        controller  : 'transactions.transaction',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'managerial',
            label: 'Transações'
        }
    })

}]);

angular.module('transaction').
controller('transactions.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$uibModal',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $uibModal) {

    $scope.filters = {
        startDate       : moment().startOf('month').toDate(),
        endDate         : moment().endOf('day').toDate(),
        payment_type    : null
    }

    $scope.totals = {
        total                           : 0,
        fees                            : 0,
        fee_value                       : 0,
        internal_anticipation_fee       : 0,
        internal_anticipation_simulator : 0
    }

    $scope.selectedMonth = function(int){
        date = moment($scope.filters.startDate);
        $scope.filters.startDate    = date.month(date.month()+parseInt(int)).toDate();
        $scope.filters.endDate      = moment($scope.filters.startDate).endOf('month').toDate()
        $scope.getData();
    }

    $scope.getData = function(){
        var params = {
            start_date  : moment($scope.filters.startDate).startOf('day').format('YYYY-MM-DD HH:mm'),
            end_date    : moment($scope.filters.endDate).endOf('day').format('YYYY-MM-DD HH:mm')
        }
        if( $scope.filters.payment_type )
            params.payment_type = $scope.filters.payment_type;

        $transaction.queryGateWayTransaction(params).then(function(response){
            $scope.transactions = response;
            $scope.getTotals();
        }, function(error){
            console.log(error);
        });

        $company.gatewayBalance('7').then(function(response){
            $scope.balance = response;
        });
    }
    $scope.getData();

    $scope.getTotals = function(){
        $scope.totals.total                             = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.total); }, 0);
        $scope.totals.repass                            = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.repass); }, 0);
        $scope.totals.fee_value                         = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.fee_total); }, 0);
        $scope.totals.internal_anticipation_fee         = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.internal_anticipation_fee); }, 0);
        $scope.totals.anticipated_value                 = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.anticipated_value); }, 0);
        $scope.totals.anticipated_total                 = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.anticipated_total); }, 0);
    }

    $scope.showTotals = function(company){
        var filter = {
            company_name    : company.company_name,
            company_id      : company.company_id,
            start_date      : moment($scope.filters.startDate).startOf('day').format('YYYY-MM-DD HH:mm'),
            end_date        : moment($scope.filters.endDate).endOf('day').format('YYYY-MM-DD HH:mm')
        }
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'transactions_totals.html',
            controller     : 'transaction.totals',
            size           : 'lg',
            resolve        : {
                filter: function(){
                    return filter
                }
            }
        });

        modalInstance.result.then(function() {
            $scope.getData();
        });
    }
}]);

angular.module('transaction').
controller('transaction.totals', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$uibModalInstance', 'filter', '$anticipation',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $uibModalInstance, filter, $anticipation) {

    $scope.title = filter.company_name;
    $scope.showAll = null;
    $scope.listToAnticipation = [];
    $scope.totals = {
        total                   : 0,
        total_paid              : 0,
        total_noPaid            : 0,
        total_toAnticipation    : 0
    }

    $scope.toggleShowAll = function(show) {
        $scope.showAll = show;
    }

    $anticipation.query(filter).$promise.then(function(response){
        $scope.anticipations = response;
    }, function(error){
        console.log(error);
    });

    $transaction.queryReceivable(filter).then(function(response){
        $scope.receivables                  = response;
        $scope.listToAnticipation           = _.filter($scope.receivables, function(row) {
            if( row.status == 'pending' && row.internal_status == null && moment(row.expected_on) > moment() )
                return row;
        });
        $scope.totals.total                 = _.reduce($scope.receivables, function(memo, row){ return memo + parseFloat(row.gross_amount); }, 0);
        $scope.totals.total_paid            = _.reduce($scope.receivables, function(memo, row){ return memo + (row.internal_status == 'anticipated' || row.internal_status == 'paid' ? parseFloat(row.amount) - row.internal_anticipation_fee : 0); }, 0);
        $scope.totals.total_noPaid          = _.reduce($scope.receivables, function(memo, row){ return memo + (row.status == 'pending' && row.internal_status == null && moment(row.expected_on) > moment() ? parseFloat(row.amount) : 0); }, 0);
        $scope.totals.total_toAnticipation  = _.reduce($scope.receivables, function(memo, row){ return memo + (row.status == 'pending' && row.internal_status == null && moment(row.expected_on) > moment() ? parseFloat(row.amount) - row.internal_anticipation_fee : 0); }, 0);
        console.log($scope.totals);
    }, function(error){
        console.log(error);
    });

    $scope.confirm = function() {
        $anticipation.save(
            {
                toAnticipation  : $scope.listToAnticipation,
                company_id          : filter.company_id
            }
        ).$promise.then(function(response){
            $uibModalInstance.close();
        }, function(error){
            console.log(error);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.close();
    };

}]);

angular.module('transaction').
controller('transactions.transaction', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$company', 'ngToast', '$transaction', '$uibModal',
function($scope, $http, $auth, $q, NgTableParams, $company, ngToast, $transaction, $uibModal) {

    $scope.filters = {
        company_name : ''
    }

    $scope.totals = {
        total           : 0,
        partnerTotal    : 0,
        companyTotal    : 0
    }

    $scope.getData = function(){
        var params = {
            is_client   : true,
            notPaid     : true,
            end_date    : moment().format('YYYY-MM-DD HH:mm')
        }

        if( $scope.filters.company_name != '' )
            params.company_name = $scope.filters.company_name;

        $transaction.query(params).$promise.then(function(response){
            $scope.transactions = response;
            $scope.getTotals();
        }, function(error){
            console.log(error);
        });
    }
    $scope.getData();

    $scope.getTotals = function(){
        $scope.totals.total = _.reduce($scope.transactions, function(memo, row){ return memo + parseFloat(row.amount); }, 0);
        $scope.totals.partnerTotal = _.reduce($scope.transactions, function(memo, row){ return memo + (row.company.total_repass ? parseFloat(row.company.total_repass) : 0); }, 0);
        $scope.totals.companyTotal = $scope.totals.total - $scope.totals.partnerTotal;
    }

    $scope.showBoleto = function(boleto){
        var link = angular.element('<a href="' + boleto.boleto_url + '" target="_blank"></a>');
        angular.element(document.body).append(link);
        link[0].click();
        link.remove();
    }

    $scope.deleteTransaction = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$transaction', 'Upload', '$http', 'ngToast', '$q',
            function($scope, $uibModalInstance, $transaction, Upload, $http, ngToast, $q) {

                $scope.title = 'Deseja realmente deletar transação?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Deletar';

                $scope.confirm = function() {
                    $transaction.delete({id: id}).$promise.then(function(response){
                        ngToast.success({
                            content: 'Transação deletada com sucesso',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro ao deletar transação',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });


                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.getData();
        });
    }
}]);

/**
 * Factory
 */

angular.module('transaction').
factory("$anticipation", function($resource, $q, $http) {
    var resource = $resource(api + 'anticipation/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
});

angular.module('transaction').
factory("$transaction", function($resource, $q, $http) {
    var resource = $resource(api + 'transaction/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getErrors = function()
    {
        var deferred = $q.defer();
        $http.get(api+'transaction/get_errors').then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getBalance = function(params = null)
    {
        var deferred = $q.defer();
        $http.get(api+'transaction/get_balance', {params: params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.queryGateWayTransaction = function(params = null)
    {
        var deferred = $q.defer();
        $http.get(api+'transaction/get_gateway', {params: params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.queryReceivable = function(params = null)
    {
        var deferred = $q.defer();
        $http.get(api+'transaction/get_receivables', {params: params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.payManually = function(id)
    {
        var deferred = $q.defer();
        $http.put(api+'transaction/'+id+'/pay_manually').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
