angular.module('client_status', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('client_status').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('client_status', {
        url         : "/client_status",
        templateUrl : server+"/views/m/client_status",
        controller  : 'client_status.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Situação de cliente'
        }
    })

    .state('client_status_create', {
        url         : "/client_status/create",
        templateUrl : server+"/views/m/client_status/create",
        controller  : 'client_status.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client_status',
            label: 'Adicionar'
        }
    })

    .state('client_status_show', {
        url         : "/client_status/:id",
        templateUrl : server+"/views/m/client_status/show",
        controller  : 'client_status.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'client_status',
            label: '{{client_status.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('client_status').
controller('client_status.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$client_status', function($scope, $http, $auth, $q, NgTableParams, $client_status) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client_status.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('client_status').
controller('client_status.create', ['$scope', '$state', '$client_status', function($scope, $state, $client_status) {
    $scope.status_options = [{
        id: 1,
        label: 'Sim'
    }, {
        id: 0,
        label: 'Não'
    }];
    $scope.save = function() {
        $client_status.save($scope.client_status).$promise.then(function(data) {
            $state.go('client_status_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('client_status').
controller('client_status.show', ['$state', '$scope', '$stateParams', '$uibModal', '$client_status', '$filter', function($state, $scope, $stateParams, $uibModal, $client_status, $filter) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.status_options = [{
        id: 1,
        label: 'Sim'
    }, {
        id: 0,
        label: 'Não'
    }];

    $client_status.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.client_status = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client_status', function($scope, $uibModalInstance, $client_status) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var client_status = $scope.$parent.client_status;

                $scope.confirm = function() {
                    $client_status.update({
                        id: client_status.id
                    }, client_status).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_status', function($state, $scope, $uibModalInstance, $client_status) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client_status = $scope.$parent.client_status;

                $scope.confirm = function() {
                    $client_status.delete({id:client_status.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('client_status');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('client_status').
factory("$client_status", function($resource) {
    var resource = $resource(api + 'client_status/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
