angular.module('check_in_class_group', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('check_in_class_group').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('check_in_class_group', {
        url         : "/check_in_class_group",
        templateUrl : server+"/views/m/check_in_class_group",
        controller  : 'check_in_class_group.check',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Chamadas'
        }
    })

    .state('check_in_class_group_create', {
        url         : "/check_in_class_group/create/:id",
        templateUrl : server+"/views/m/check_in_class_group/create",
        controller  : 'check_in_class_group.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'check_in_class_group',
            label: 'Adicionar'
        }
    })

    .state('check_in_class_group_show', {
        url         : "/check_in_class_group/:id",
        templateUrl : server+"/views/m/check_in_class_group/modal",
        controller  : 'check_in_class_group.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'check_in_class_group',
            label: '{{check_in_class_group.name}}'
        }
    })

}]);

/**
* List Controller
*/
angular.module('check_in_class_group').
controller('check_in_class_group.check', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$check_in_class_group', '$state', '$class_group', '$uibModal', '$uibModalInstance',
function($scope, $http, $auth, $q, NgTableParams, $check_in_class_group, $state, $class_group, $uibModal, $uibModalInstance) {

    var dateNow = new Date();
    var day = dateNow.getDate();
    var month = (dateNow.getMonth() + 1);
    var year = dateNow.getFullYear();
    $scope.date = day + "/" + month + "/" + year;
    $scope.dayWeek = dateNow.getDay();
    $scope.check_in = {
        class_group : []
    };

    $class_group.query().$promise.then(function(response){
        $scope.class_groups = response.data;
    });
    $scope.filter = {
        count : 1000,
        start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    $check_in_class_group.query($scope.filter).$promise.then(function(response){
        $scope.check_in_class_group = response;
        $scope.check_in_class_group.data.map(function(item){
            item.class_group.days_week = item.class_group.schedule;
            item.class_group.days_week.map(function(data){
                if($scope.dayWeek == data.day){
                    $scope.check_in.class_group.push({
                        'data_id'        : data.id,
                        'day_week'       : data.day,
                        'start_hour'     : data.start_hour,
                        'end_hour'       : data.end_hour,
                        'limit_class'    : item.class_group.limit_class,
                        'class_group_id' : item.class_group_id,
                        'name'           : item.class_group.name,
                        'modality'       : item.class_group.modality ? item.class_group.modality.name : '',
                        'modality_id'    : item.class_group.modality ? item.class_group.modality.id : '',
                        'instructor_id'  : $scope.check_in_class_group.user.id,
                        'checked'        : false
                    })
                }
            })
                
        });
        $scope.check_in.class_group.map(function(item){
            $scope.checked = _.find($scope.check_in_class_group.check_in, function(row){ return row.class_group_id == item.class_group_id });
            if($scope.checked)
                item.checked = true;
        });
    });

    $scope.checkInParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $check_in_class_group.query(params.url()).$promise.then(
                function(data) {
                    params.total(data.total);
                    return data.data;
                }
            );
        }
    });

    $scope.checkInAdd = function(id) {
        $state.go('check_in_class_group_create', {
            id: id,
            user:$scope.check_in_class_group.users
        });
    }

    $scope.showCheckIn = function(data) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: server+"/views/m/check_in_class_group/show_modal",
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user',
            function($scope, $uibModalInstance, $user) {
                $scope.title = '';
                $scope.title_name = 'Nome';
                $scope.success_text = 'Confirmar';
                $check_in_class_group.checkInChecked(data).then(function(response){
                    $scope.check_in_checked = response;
                });

                $scope.getCheck = function(check){
                    return check    
                }
                $scope.updateCheck = function(check){
                    return !check    
                }

                $scope.selected_enrollments = [];
                $scope.selectCheck = function(id){
                    if(_.contains($scope.selected_enrollments, id)){
                        var i = _.indexOf($scope.selected_enrollments, id);
                        $scope.selected_enrollments.splice(i,1);
                    } else {
                        $scope.selected_enrollments.push(id);
                    }
                }

                $scope.confirm = function(class_group_data) {
                    $check_in_class_group.update({id: class_group_data.id},class_group_data).then(function(response){
                        $uibModalInstance.close();
                    });
                    
                    
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };
            }]
        });   
    }

}]);

/**
* Create Controller
*/
angular.module('check_in_class_group').
controller('check_in_class_group.create', ['$check_in_class_group', '$state', '$scope', '$stateParams', '$uibModal', '$class_group', 'NgTableParams', '$users', '$localStorage', '$http', 'ngToast', '$rootScope',
function($check_in_class_group, $state, $scope, $check_in_class_groupParams, $uibModal, $class_group, NgTableParams, $users, $localStorage, $http, ngToast, $rootScope) {
    var width = screen.width;

    $scope.showObsInModal = false;
    $scope.smallScreen = false;

    if(width < 600){
        $scope.smallScreen = true;
    }

    $scope.obs_active = [];
    $scope.obsStatus = function(enrollment) {
        if (!$scope.obs_active[enrollment.id]) {
            $scope.obs_active[enrollment.id] = true;
            if(width < 600){
                $scope.showObsInModal = true;
                $scope.enrollmentData = enrollment;
            }         
        }else{
            $scope.obs_active[enrollment.id] = false;
        }
    }
    $scope.closeModal = function(){
        $scope.showObsInModal = false;   
    }

    $scope.selected_enrollments = [];
    $scope.selectEnrollment = function(id){
        if(_.contains($scope.selected_enrollments, id)){
            var i = _.indexOf($scope.selected_enrollments, id);
            $scope.selected_enrollments.splice(i,1);
        } else {
            $scope.selected_enrollments.push(id);
        }
    }

    $scope.check_in_class_group = {instructor_id:$localStorage.user.id};
    $class_group.get({
        id: $check_in_class_groupParams.id
    }).$promise.then(function(data){
        $check_in_class_groupParams.user;
        $scope.instructors = $localStorage.user;
        $scope.enrollments = data.enrollments;
        $scope.enrollments.map(function(item){
            item.client_name = item.client.name;
            item.selected = true;
        });
        $scope.enrollments = _.sortBy($scope.enrollments, 'client_name');
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });
    
    $scope.confirmCheckIn = function()
    {
        $scope.check_in_class_group.class_group_id = $check_in_class_groupParams.id;
        $scope.check_in_class_group.selected_enrollments = $scope.enrollments;
        for (var i = 0; i < $scope.enrollments.length; i++) {
            $scope.enrollments[i]
            if ($scope.check_in_class_group.selected_enrollments[i].selected == true) {
                $scope.check_in_class_group.selected_enrollments[i].check = 1;
            }else {
                $scope.check_in_class_group.selected_enrollments[i].check = 0;
            }
        }
        $check_in_class_group.save($scope.check_in_class_group).$promise.then(function(data) {
            $state.go('check_in_class_group', {
                id: data.id
            });
        });
    }

    $scope.print = function(){
        $scope.printing = true;
        $http.put(api+'class_group/print', {id: $check_in_class_groupParams.id}).then(function(response){
            $rootScope.print(response.data); 
            $scope.printing = false;
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }


}]);

/**
* Show, Edit, Delete Controller
*/
angular.module('check_in_class_group').
controller('check_in_class_group.show', ['$check_in_class_group', '$state', '$scope', '$stateParams', '$uibModal', '$class_group', 'NgTableParams', '$users', '$localStorage', '$uibModalInstance',
function($check_in_class_group, $state, $scope, $check_in_class_groupParams, $uibModal, $class_group, NgTableParams, $users, $localStorage, $uibModalInstance) {
    console.log($check_in_class_groupParams.id);
    $check_in_class_group.get({id: $check_in_class_groupParams.id}).$promise.then(function(data){
        $scope.check_in_class_group = data;  
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });
}]);


/**
* Factory
*/

angular.module('check_in_class_group').
factory("$class_group", function($resource, $filter, $q, $http) {
    var resource = $resource(api + 'class_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
});

angular.module('check_in_class_group').
factory("$check_in_class_group", function($resource, $filter, $q, $http) {
    var resource = $resource(api + 'check_in_class_group/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.checkInChecked = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'check_in_class_group/check_in_checked', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateCheckInChecked = function(data)
    {
        var deferred = $q.defer();
        $http.put(api+'check_in_class_group/update_check_in_checked', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getCheckInClassGroup = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'check_in_class_group/get_check_in_class_group', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
