angular.module('offer', ['satellizer', 'ngStorage', 'ngToast']);

angular.module('offer').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('offer', {
        url         : "/",
        templateUrl : server+"/views/public/offer_index",
        controller  : 'offer.list'
    })

    .state('offer_show', {
        url         : "/oshow/:id",
        templateUrl : server+"/views/public/offer_show",
        controller  : 'offer.show'
    })

    .state('offer_list', {
        url         : "/olist",
        templateUrl : server+"/views/public/offer_list",
        controller  : 'offer.list',
        resolve: {
            existingChallenge: function(){
                return null;
            }
        },
        params : {
            find : null
        }
    })

    .state('whowe', {
        url         : "/whowe",
        templateUrl : server+"/views/public/offer_whowe",
        controller  : 'contact'
    })

    .state('contact', {
        url         : "/contact",
        templateUrl : server+"/views/public/contact",
        controller  : 'contact'
    })

}]);

/**
 * List Controller
 */

angular.module('offer').
controller('contact', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$localStorage', '$offer', '$state', '$sce', 'ngToast',
function($scope, $http, $auth, $q, NgTableParams, $localStorage, $offer, $state, $sce, ngToast) {
    $scope.company      = company;
    $scope.iframeHtml   = $sce.trustAsHtml(iframeHtml);
    $scope.who_weHtml   = $sce.trustAsHtml(who_we);
    $scope.email = {
        name    : '',
        from    : '',
        subject : '',
        message : ''
    }

    $scope.sendEmail = function(valid){
        if(!valid){
            ngToast.danger({
                content: 'Preencha todos os campos',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }

        $offer.sendEmail($scope.email).then(function(response){
            ngToast.success({
                content: 'Email enviado',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.email = {
                name    : '',
                from    : '',
                subject : '',
                message : ''
            }
        }, function(error){
            ngToast.danger({
                content: 'Email não enviado',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    };
}]);

angular.module('offer').
controller('offer.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$localStorage', '$offer', '$state', '$stateParams', '$sale', '$rootScope',
function($scope, $http, $auth, $q, NgTableParams, $localStorage, $offer, $state, $stateParams, $sale, $rootScope) {

    var host = window.location.hostname;
    if( host.includes('falefy') && (window.location.pathname == '/' || window.location.pathname == ''))
            window.location.href = '/home';

    $scope.company = company;
    if ($scope.company) {
        if($scope.company.ecommerce_maintenance == 1){
            window.location.href = '/ecommerce_maintenance';   
        }
    }
        
    $scope.searchEcommerce = '';

    if( $rootScope.filterEcommerce ){
        $scope.filterEcommerce = $rootScope.filterEcommerce;
        $rootScope.filterEcommerce = [];
    } else {
        $scope.filterEcommerce = [];
    }

    if(!$localStorage.cart){
        $localStorage.cart = {
            total : 0,
            items : []
        }
    }
    $scope.$parent.cart = $localStorage.cart;

    $scope.searchOffer = function(){
        var str =  document.getElementById('find').value;
        if (str == '') {
            ngToast.warning({
                content             : 'Insira os dados da busca!',
                dismissOnTimeout    : true,
                timeout             : 5000
            });
            return;
        }
        if(str.length == 0)
            return;

        $stateParams.find = str;
        $scope.loadOffers();
    }

    $scope.loadOffers = function(){
        var params = {
            page : 1
        };

        if ($stateParams) {
            params.search = $stateParams.find;
        }

        if( $scope.filterEcommerce.length > 0 )
            params.filterEcommerce = JSON.stringify($scope.filterEcommerce);
            
        $offer.query(params).$promise.then(function(response){
            $scope.offers       = response.data;
            $scope.current_page = response.current_page;
            $scope.last_page    = response.last_page;
        });
    }
    $scope.loadOffers();

    $scope.changeFilters = function(category){
        var index = _.findIndex($scope.filterEcommerce, {group_id : category.group_id});
        if( index >= 0){
            if( $scope.filterEcommerce[index] && $scope.filterEcommerce[index].id == category.id ){
                $scope.filterEcommerce.splice(index, 1);
            } else {
                $scope.filterEcommerce[index] = category;
            }
        } else {
            $scope.filterEcommerce.push(category);
        }
        $scope.loadOffers();
    }

    $scope.hasFilter = function(id) {
        return _.findIndex($scope.filterEcommerce, {id : id}) >= 0 ? true : false;
    }

    $scope.showMore = function(){
        if( $scope.last_page <= $scope.current_page )
            return;

        var params = {};
        params.page = parseInt($scope.current_page) + 1;
        params.filterEcommerce = $rootScope.filterEcommerce;

        $offer.query(params).$promise.then(function(response){
            _.each(response.data, function(row){
                $scope.offers.push(row);
            });
            $scope.current_page = response.current_page;
            $scope.last_page    = response.last_page;
        });
    }

    $offer.getCategories().then(function(response){
        $scope.categories = response;
    });

    $offer.getMenuCompany().then(function(response){
        $scope.menu_company = JSON.parse(response[0].menu_categories);
    });

    $offer.getBanners().then(function(response){
        $scope.banners = response;
    });

    $scope.openOffer = function(id){
        $state.go('offer_show', {id: id});
    }

}]);

angular.module('offer').
controller('offer.show', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$stateParams', '$offer', '$localStorage', '$rootScope', '$sale', '$sce', 'ngToast', '$state', '$uibModal', '$company',
function($scope, $http, $auth, $q, NgTableParams, $stateParams, $offer, $localStorage, $rootScope, $sale, $sce, ngToast, $state, $uibModal, $company) {

    $scope.company      = company;
    console.log(company);
    $scope.shipping = {
        cep     : '',
        options :[]
    };
    $scope.cep = null;
    if(!$localStorage.cart){
        $localStorage.cart = {
            total : 0,
            items : []
        }
    }
    $scope.$parent.cart = $localStorage.cart;

    $scope.load = function(){
        $offer.get({id:$stateParams.id}).$promise.then(function(response){
            $scope.offer = response;
            console.log($scope.offer);
            $scope.item = {
                id          : $stateParams.id,
                quantity    : 1,
                name        : $scope.offer.name,
                src         : $scope.offer.images.length > 0 ? $scope.offer.images[0].src : 'img/product.jpg',
                value       : $scope.offer.value
            }
        });
    }
    $scope.load();

    $scope.buy = function(offer = null){
        // if(offer && offer.quantity < 1)
        //     return;

        $localStorage.cart.items.push({
            service_id  : $scope.offer.service_id,
            quantity    : $scope.item.quantity,
            name        : $scope.offer.name + (offer ? ' '+offer.description : ''),
            description : $scope.offer.description,
            src         : $scope.offer.images.length > 0 ? $scope.offer.images[0].src : 'img/product.jpg',
            value       : offer ? offer.price_calculed : $scope.offer.value,
            offer_id    : offer ? offer.hashid : null
        });
        $rootScope.$broadcast('cart:item_added');


        $sale.updateEcommerce($localStorage.cart, $localStorage.cart.hashid).then(function(response){
            $localStorage.cart = response;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'continueShopping.modal.html',
                size: 'sm',
                scope: $scope,
                controller: ['$scope', '$uibModalInstance', 'ngToast', '$q',
                function($scope, $uibModalInstance, ngToast, $q) {

                    $scope.continue = function() {
                        $uibModalInstance.close();
                        $state.go('offer');
                    }

                    $scope.checkout = function() {
                        $uibModalInstance.close();
                        $state.go('checkout', {id: $localStorage.cart.hashid});
                    };

                }]
            });

        }, function(error){

            $localStorage.cart = {
                total : 0,
                items : []
            }
            $scope.$parent.cart = $localStorage.cart;

            $rootScope.$broadcast('cart:item_added');
            switch (error.data.status_code) {
                case 501:
                    ngToast.danger({
                        content: 'Não é possivel adicionar plano em carrinho já existente',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    break;
                default:
                    ngToast.danger({
                        content: 'Erro ao adicionar item. Por favor, tente novamente',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
            }

        });
    }

    $scope.selectItem = function(){
        var bConsult    = true;
        var array       = '';
        _.each($scope.offer.params, function(row){
            if(!row.selected)
                bConsult = false;
            array = array + row.selected + ',';
        });
        array = array.substr(0, array.length-1);
        if( bConsult ){
            $scope.waiting = true;
            $offer.getOneOfGroup({selecteds : array}).then(function(response){
                response.params = $scope.offer.params;
                $scope.offer = response;
                $scope.waiting = false;
            }, function(error){
                $scope.waiting = false;
            });
        }
    }

    $scope.calcShipping = function(){
        if( $scope.shipping.cep && $scope.shipping.cep.length == 8 ){
            $scope.waiting = true;
            $offer.calcShipping({cep: $scope.shipping.cep},$stateParams.id).then(function(response){
                $scope.shipping.options = response;
                $scope.shipments = response[2];
                $scope.waiting = false;
            }, function(error){
                ngToast.danger({
                    content: 'Erro ao consultar frete, insira um cep válido!',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.shipping.options = [];
                $scope.waiting = false;
            });
        }
    }

    $scope.changeQtd = function(qtd){
        if(qtd < 1)
            return;

        $scope.item.quantity = qtd;
    }


    $scope.fullImage = function(){

            var modal = document.getElementById("myModal");
            var img = document.getElementById("myImg");
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
                img.onclick = function(){
                  modal.style.display = "block";
                  modalImg.src = this.src;
                  captionText.innerHTML = this.alt;
            }
            var span = document.getElementsByClassName("close")[0];
                span.onclick = function() {
                  modal.style.display = "none";
            }

    }

}]);

angular.module('offer').
factory("$offer", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'offer/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getCategories = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'offer/getCategories', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getMenuCompany = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/get_menu_company').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getBanners = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'company/get_banners').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getOneOfGroup = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'offer/getOneOfGroup', {params : data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.calcShipping = function(data, id)
    {
        var deferred = $q.defer();
        $http.get(api+'offer/'+id+'/calc_shipping', {params : data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.sendEmail = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'company/send_email', {email: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
