angular.module('invoice', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch', 'angularjs-dropdown-multiselect', 'ui.utils.masks']);

angular.module('invoice').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('invoice', {
        url         : "/invoice",
        templateUrl : server+"/views/m/invoice",
        controller  : 'invoice.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Notas'
        }
    })

    .state('invoice_import', {
        url         : "/invoice/imports",
        templateUrl : server+"/views/m/invoice/imports",
        controller  : 'invoice.imports',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'invoice',
            label: 'Notas de entrada'
        }
    })

    .state('invoice_create', {
        url         : "/invoice/create",
        templateUrl : server+"/views/m/invoice/create",
        controller  : 'invoice.create',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'invoice',
            label: 'Adicionar'
        }
    })

    .state('invoice_show', {
        url         : "/invoice/:id",
        templateUrl : server+"/views/m/invoice/show",
        controller  : 'invoice.show',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'invoice',
            label: '{{invoice.nNF}}'
        }
    })

}]);

angular.module('invoice').
controller('invoice.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$invoice', '$rootScope', '$uibModal', '_', 'ngToast', 'company_id', 'showInModal', '$uibModalInstance', '$state',
function($scope, $http, $auth, $q, NgTableParams, $invoice, $rootScope, $uibModal, _, ngToast, company_id, showInModal, $uibModalInstance, $state) {


    $scope.showInModal  = showInModal;
    $scope.title        = 'Notas Fiscais';
    $scope.today = moment();
    $scope.mod = {};
    $scope.status = {};
    $scope.waiting = true;
    $scope.showTotals = false;
    $scope.today_opened = {
        opened: false
    }
    $scope.totals = {
        sent : { count : 0, value : 0 },
        devolution : { count : 0, value : 0 },
        no_sent : { count : 0, value : 0 },
        canceled :{ count : 0, value : 0 },
        mod :{ count : 0, value : 0 }

    }

    $scope.params = {
        page : 1,
        count : 20,
        filter : {
            'date': $scope.today.toISOString(),
            'mod' : ''
        }
    }

    if( company_id ){
        $scope.params.company_id = company_id;
    } else {
        delete $scope.params.company_id;
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.selectedMonth = function(int)
    {
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.params.filter.date = $scope.today.toISOString();
        $scope.waiting = true;
        $scope.tableParams.reload();
        $scope.showInvoiceTotals();
    }

    $scope.tableParams = new NgTableParams({
        page: $scope.params.page,
        count: $scope.params.count,
        filter: $scope.params.filter,
        company_id : $scope.params.company_id ? $scope.params.company_id  : ''
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $invoice.query(params.url()).$promise.then(function(response) {
                params.total(response.paginate.total);
                $scope.waiting = false;
                return response.paginate.data;

            });
        }
    });

    $scope.showInvoiceTotals = function(){
        $scope.showTotals = true;
        var params = {
            page : 1,
            count : 20,
            showTotals: true,
            date: $scope.today.toISOString(),
            company_id: company_id ? company_id : null
        }
        
        $invoice.get(params).$promise.then(function(response) {
            $scope.waiting = true;
            $scope.totals = {
                sent : { count : 0, value : 0 },
                devolution : { count : 0, value : 0 },
                no_sent : { count : 0, value : 0 },
                canceled :{ count : 0, value : 0 },
                mod :{ count : 0, value : 0 }

            }
            $scope.totals.sent.count        = response.sentCount;
            $scope.totals.sent.value        = response.sentTotal;

            $scope.totals.devolution.count  = response.devolutionCount;
            $scope.totals.devolution.value  = response.devolutionTotal;

            $scope.totals.no_sent.count     = response.noSentCount;
            $scope.totals.no_sent.value     = response.noSentTotal;

            $scope.totals.canceled.count    = response.canceledCount;
            $scope.totals.canceled.value    = response.canceledTotal;
            $scope.tableParams.reload();

        });
    }
    $scope.hideInvoiceTotals = function(){
        $scope.showTotals = false;
    }

    $scope.mod = [
        {id:'', title:'Todas'},
        {id:'0', title:'NFSe'},
        {id:'65', title:'NFCe'},
        {id:'55', title:'NFe'}
    ];

    $scope.status = [
        {id:'', title:'Todos'},
        {id:'authorized', title:'Autorizado'},
        {id:'canceled', title:'Cancelado'},
        {id:'disenable', title:'Inutilizado'},
        {id:null , title:'Não enviado'}
    ];

    $scope.show = function(id){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/invoice/show",
            controller     : 'invoice.show',
            size           : 'lg',
            backdrop        : 'static',
            resolve        : {
                invoice_id     : id,
                showInModal    : true
            }
        });

        modalInstance.result.then(function() {
            $scope.tableParams.reload();
        });
    }

    $scope.makeInvoice = function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Qual o tipo de nota que deseja emitir?';
                $scope.cancel_text = 'Cancelar';
                $scope.optionLeft = {
                    label   : 'NFe | NFSe',
                    value   : 55
                }
                $scope.optionRight = {
                    label   : 'NFCe',
                    value   : 65
                }


                $scope.confirm = function(data) {
                    if(data == 65 && !$scope.input){
                        $scope.input = {
                            label   : 'Deseja incluir CPF na nota?',
                            type    : 'text',
                            mask    : 'ui-br-cpf-mask',
                            value   : null
                        }
                        $scope.optionRight.label = 'Continuar';
                        return;
                    }
                    data = {
                        mod : data,
                        cpf : $scope.input ? $scope.input.value : null
                    }
                    $uibModalInstance.close(data);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(data) {
            $scope.waiting = true;
            var params = {
                mod         : data.mod,
                dest_cpf    : data.cpf

            }
            $invoice.storeLoose(data).then(function(response){
                $scope.tableParams.reload();
                $scope.show(response.id);
            });
        });
    }

    $scope.exportInvoices = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'export_invoices.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', '$rootScope', '$localStorage',
            function($scope, $uibModalInstance, $invoice, Upload, $http, $rootScope, $localStorage) {

                $scope.title = 'Selecione o mês para envio';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.exports = {
                    month : moment().month() != 0 ? moment().month() : 12,
                    year : moment().month() != 0 ? moment().year() : moment().year() -1,
                    email: $localStorage.company ? $localStorage.company.accountant_email : '',
                    send_pdf: true,
                }

                if (company_id) {
                    $scope.exports.company_id = company_id;
                }

                $scope.years = [
                    moment().year() -4,
                    moment().year() -3,
                    moment().year() -2,
                    moment().year() -1,
                    moment().year()
                ]

                $scope.confirm = function() {
                    $invoice.export($scope.exports).then(function(response){
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.disenable = function(row) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'update_status.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http',
            function($scope, $uibModalInstance, $invoice, Upload, $http) {

                $scope.title = 'Deseja inutilizar a numeração '+row.nNF+'?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Inutilizar';
                $scope.bReason = true;

                $scope.protocol = {
                    reason : ''
                }

                $scope.confirm = function() {
                    var params = {
                        xJust       : $scope.protocol.reason,
                        invoice_id  : row.id
                    }
                    $invoice.disenable(params).then(function(response){
                        ngToast.success({
                            content: 'Numero inutilizado!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.tableParams.reload();
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: error.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.print = function(){
        $invoice.report($scope.params.filter).then(function(response){
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();

            $scope.printing = false;
        });
    }

}]);

angular.module('invoice').
controller('invoice_imports', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$invoice_import', '$rootScope', '$uibModal', '_', '$stateParams', 'ngToast', 'Upload',
function($scope, $http, $auth, $q, NgTableParams, $invoice_import, $rootScope, $uibModal, _, $stateParams, ngToast, Upload) {

    $scope.today = moment();
    $scope.importing = false;
    $scope.downloading = false;
    $scope.waiting = true;
    $scope.sending = false;
    $scope.imports = {};
    $scope.type = 'authorized';
    $scope.today_opened = {
        opened: false
    }
    $scope.totals = {
        not_found               : { count : 0, list : [] },
        total_authorized        : { count : 0, total : 0 },
        total_noAuthorized      : { count : 0, total : 0 },
        totalOut                : { count : 0, total : 0 },
        sent                    : { count : 0, total : 0 },
        no_sent                 : { count : 0, total : 0 },
        canceled                : { count : 0, total : 0 }
    }

    $scope.selectFilter = function(filter){
        if( filter == $scope.paramsOut.filter.status ){
            $scope.paramsOut.filter.status = null;
        } else {
            $scope.paramsOut.filter.status = filter;
        }
        $scope.reloadAll();
    }

    $scope.params = {
        page : 1,
        count : 20,
        filter : {
            client_id: $stateParams.id,
            date: $scope.today.toISOString(),
            tpNF: '1',
            idDest : 'null',
            finNFe : 'null',
            indFinal : 'null',
            way : 'in'
        }
    }

    $scope.paramsItemn = {
        page : 1,
        count : 20,
        filter : {
            client_id: $stateParams.id,
            date: $scope.today.toISOString(),
            tpNF: '1',
            tax_type : '9999',
            idDest : 2,
            show_services : false,
            way : 'in'
        }
    }

    $scope.paramsOut = {
        page : 1,
        count : 20,
        filter : {
            client_id: $stateParams.id,
            date: $scope.today.toISOString(),
            mod : '55',
            // tpNF: '1',
            way : 'out'
        }

    }

    $scope.changeFilter = function(){
        $scope.importParams.page(1);
        $scope.importParams.reload();
    }

    $scope.changeItemsFilter = function(){
        $scope.itemParams.page(1);
        $scope.itemParams.reload();
    }

    $scope.selectedMonth = function(int, page = null){
        $scope.today.month($scope.today.month()+parseInt(int));
        $scope.params.filter.date = $scope.today.toISOString();
        $scope.paramsOut.filter.date = $scope.today.toISOString();
        $scope.paramsItemn.filter.date = $scope.today.toISOString();
        $scope.reloadAll(page);
    }

    $scope.reloadAll = function(page = null) {
        setTimeout(function () {
            if( page == 'out' ){
                $scope.outNFeParams.page(1);
                //$scope.outNFeParams.reload();
            } else {
                $scope.importParams.page(1);
                //$scope.importParams.reload();
                $scope.itemParams.page(1);
                //$scope.itemParams.reload();
            }
        }, 100);
    }

    $scope.selectImportFile = function(){
        $scope.sending = true;
        var data = {
            client_id : $stateParams.id,
            xmls : $scope.imports.file[0]
        }
        Upload.upload({
            url: api+'invoice_import',
            data: data
        }).then(function (response) {
            $scope.sending = false;
            $scope.outNFeParams.reload();
        }, function(error){
            $scope.sending = false;
        });
    }

    $scope.changeType = function(type) {
        $scope.type = type;
        $scope.importParams.reload();
    }

    $scope.importParams = new NgTableParams({
            page: $scope.params.page,
            count: $scope.params.count,
            filter: $scope.params.filter
        }, {
            counts: [],
            filterDelay: 0,
            getData: function(params) {
                return $invoice_import.query(params.url()).$promise.then(function(response) {
                    $scope.totals.total_authorized = {
                        count : response.list_authorized.total,
                        total : response.value_authorized
                    }
                    $scope.totals.total_noAuthorized = {
                        count : response.list_noAuthorized.total,
                        total : response.value_noAuthorized
                    }

                    if( $scope.type == 'authorized' ) {
                        params.total(response.list_authorized.total);
                        return response.list_authorized.data;
                    } else {
                        params.total(response.list_noAuthorized.total);
                        return response.list_noAuthorized.data;
                    }
                });
            }
    });

    $scope.changeInvoiceType = function(type) {
        $scope.paramsOut.filter.mod = type;
        $invoice_import.checkKeys($scope.paramsOut.filter).then(function(response){
            $scope.totals.not_found.count = response.data.count;
            $scope.totals.not_found.list = response.data.list;
        });
        $scope.reloadAll('out');


    }

    $invoice_import.checkKeys($scope.paramsOut.filter).then(function(response){
        $scope.totals.not_found.count = response.data.count;
        $scope.totals.not_found.list = response.data.list;
    });
    // $scope.changeType('55');

    $scope.outNFeParams = new NgTableParams({
            page: $scope.params.page,
            count: $scope.params.count,
            filter: $scope.paramsOut.filter
        }, {
            counts: [],
            filterDelay: 0,
            getData: function(params) {

                return $invoice_import.query(params.url()).$promise.then(function(response) {
                    params.total(response.list.total);

                    $scope.totals.totalOut = {
                        count : response.sent.total,
                        total : response.value
                    }
                    $scope.totals.sent = response.sent;
                    $scope.totals.no_sent = response.no_sent;
                    $scope.totals.canceled = response.canceled;

                    return response.list.data;
                });
            }
    });

    $scope.itemParams = new NgTableParams({
        page: $scope.paramsItemn.page,
        count: $scope.paramsItemn.count,
        filter: $scope.paramsItemn.filter
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $invoice_import.getItems($stateParams.id,params.url()).then(function(response) {
                params.total(response.total);
                return response.data;
            });
        }
    });

    $scope.show = function(id){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : server+"/views/m/invoice/show",
            controller     : 'invoice.show',
            size           : 'lg',
            resolve        : {
                invoice_id     : id,
                showInModal    : true
            }
        });
    }

    $scope.searchInvoices = function() {
        $scope.importing = true;
        $invoice_import.search($stateParams.id).then(function(response){
            $scope.importing = false;
            $scope.importParams.reload();
            $scope.itemParams.reload();
        }, function(error){
            if( error.data.message.indexOf('No such file or directory') !== -1 ){
                ngToast.danger({
                    content: 'Certificado ou senha inválidos!',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            }
            $scope.importing = false;
        });
    }

    $scope.printInvoice = function(id){
        $http.post(api + 'invoice_import/print/'+id, {}, {responseType: 'arraybuffer'}).then(function(response){
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            // Create a link element on body
            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        });
    }

    $scope.downloadInvoices = function(params) {
        $scope.downloading = true;
        var data = angular.copy(params);
        data.status = $scope.type == 'authorized' ? 'authorized' : 'canceled';
        data.client_id = $stateParams.id;
        $http.get(api + 'invoice_import/download', {params : data},{responseType: 'arraybuffer'}).then(function(response){
            var link = angular.element('<a href="' + response.data + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
            $scope.downloading = false;
        }, function(error) {
            $scope.downloading = false;
        });
    }

    $scope.openGaps = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'invoice_keys.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http',
            function($scope, $uibModalInstance, $invoice, Upload, $http) {

                $scope.title = 'Notas não encontradas';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.gaps = angular.copy($scope.$parent.totals.not_found.list);

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.openProductImport = function(product) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'product_import.modal.html',
            size: 'md',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', '$stateParams',
            function($scope, $uibModalInstance, $invoice, Upload, $http, $stateParams) {

                $scope.title = 'Classificação produto';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.product                          = product;
                $scope.product.invoice_import_item_id   = $scope.product.id;
                $scope.product.client_id                = $stateParams.id;
                $scope.product.mva                      = $scope.product.mva ? $scope.product.mva : 0;
                $scope.product.redBC                    = $scope.product.redBC ? $scope.product.redBC : 0;
                $scope.product.redAliq                  = $scope.product.redAliq ? $scope.product.redAliq : 0;
                $scope.product.uf                       = $scope.product.uf ? $scope.product.uf : $scope.product.emit_uf;

                $scope.confirm = function() {
                    $http.post(api+'product_import', $scope.product).then(function(response){
                        $scope.$parent.itemParams.reload();
                        $uibModalInstance.close();
                    });
                };

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.makeGNRE = function() {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'make_gnre.modal.html',
            controller     : 'invoice_imports.make_gnre',
            size           : 'lg',
            resolve        : {
                today: function(){
                    return angular.copy($scope.today)
                }
            }
        });
    }

}]);

angular.module('invoice').
controller('invoice_imports.make_gnre', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$invoice_import', '$rootScope', '$uibModal', '_', '$stateParams', 'ngToast', 'Upload', '$uibModalInstance', 'today',
function($scope, $http, $auth, $q, NgTableParams, $invoice_import, $rootScope, $uibModal, _, $stateParams, ngToast, Upload, $uibModalInstance, today) {

    $scope.title = 'Calcular impostos';
    $scope.cancel_text = 'Cancelar';
    $scope.success_text = 'Gerar';

    $scope.total = 0;
    $scope.today = moment(today);
    $scope.lastDay = moment($scope.today).endOf('month');

    $scope.filter = {
        start_date : moment($scope.today).startOf('month').toISOString(),
        end_date : moment($scope.today).endOf('month').toISOString(),
        tax_type : null,
        uf : null,
        period : '1',
        way : 'in'
    }

    $scope.selectUF = function(row){
        var index = _.indexOf( $scope.ufs, row);
        var last = _.indexOf( $scope.ufs, $scope.filter.uf);

        if( $scope.filter.uf == $scope.ufs[index].name ){
            $scope.ufs[index].class =  $scope.ufs[index].hasAny ? 'success' : '';
            $scope.filter.uf = null;
        } else {
            $scope.ufs[index].class =  'selected';
            if( last != -1 ) $scope.ufs[last].class =  $scope.ufs[last].hasAny ? 'success' : '';
            $scope.filter.uf = row;
        }

        $scope.changeFilter();
    }

    $scope.changeFilter = function(loadUFs = false){
        if( !$scope.filter.tax_type ){
            $scope.total = 0
            $scope.items = [];
            return
        }

        var filter = angular.copy($scope.filter);
        filter.uf = filter.uf ? filter.uf.name : null;
        if( filter.tax_type == '1568' ){
            if( filter.period == '1' ) {
                filter.start_date = moment($scope.today).startOf('month').toISOString();
                filter.end_date = moment($scope.today).date('15').toISOString();
            } else {
                filter.start_date = moment($scope.today).date('16').toISOString();
                filter.end_date = moment($scope.today).endOf('month').toISOString();
            }
        } else {
            filter.start_date = moment($scope.today).startOf('month').toISOString();
            filter.end_date = moment($scope.today).endOf('month').toISOString();
        }
        $invoice_import.calculeTaxes($stateParams.id, filter ).then(function(response) {
            $scope.total = response.total ? response.total : 0;
            $scope.items = response.list;
            if(loadUFs) $scope.loadUfs();
        });
    }

    $scope.loadUfs = function(){
        $scope.ufs = [
            { class: _.find($scope.items, {emit_uf : 'AC'}) != undefined ? 'success' : '', name : 'AC', hasAny: _.find($scope.items, {emit_uf : 'AC'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'AL'}) != undefined ? 'success' : '', name : 'AL', hasAny: _.find($scope.items, {emit_uf : 'AL'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'AM'}) != undefined ? 'success' : '', name : 'AM', hasAny: _.find($scope.items, {emit_uf : 'AM'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'AP'}) != undefined ? 'success' : '', name : 'AP', hasAny: _.find($scope.items, {emit_uf : 'AP'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'BA'}) != undefined ? 'success' : '', name : 'BA', hasAny: _.find($scope.items, {emit_uf : 'BA'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'CE'}) != undefined ? 'success' : '', name : 'CE', hasAny: _.find($scope.items, {emit_uf : 'CE'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'DF'}) != undefined ? 'success' : '', name : 'DF', hasAny: _.find($scope.items, {emit_uf : 'DF'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'ES'}) != undefined ? 'success' : '', name : 'ES', hasAny: _.find($scope.items, {emit_uf : 'ES'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'GO'}) != undefined ? 'success' : '', name : 'GO', hasAny: _.find($scope.items, {emit_uf : 'GO'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'MA'}) != undefined ? 'success' : '', name : 'MA', hasAny: _.find($scope.items, {emit_uf : 'MA'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'MT'}) != undefined ? 'success' : '', name : 'MT', hasAny: _.find($scope.items, {emit_uf : 'MT'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'MS'}) != undefined ? 'success' : '', name : 'MS', hasAny: _.find($scope.items, {emit_uf : 'MS'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'MG'}) != undefined ? 'success' : '', name : 'MG', hasAny: _.find($scope.items, {emit_uf : 'MG'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'PA'}) != undefined ? 'success' : '', name : 'PA', hasAny: _.find($scope.items, {emit_uf : 'PA'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'PB'}) != undefined ? 'success' : '', name : 'PB', hasAny: _.find($scope.items, {emit_uf : 'PB'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'PR'}) != undefined ? 'success' : '', name : 'PR', hasAny: _.find($scope.items, {emit_uf : 'PR'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'PE'}) != undefined ? 'success' : '', name : 'PE', hasAny: _.find($scope.items, {emit_uf : 'PE'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'PI'}) != undefined ? 'success' : '', name : 'PI', hasAny: _.find($scope.items, {emit_uf : 'PI'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'RN'}) != undefined ? 'success' : '', name : 'RN', hasAny: _.find($scope.items, {emit_uf : 'RN'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'RS'}) != undefined ? 'success' : '', name : 'RS', hasAny: _.find($scope.items, {emit_uf : 'RS'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'RJ'}) != undefined ? 'success' : '', name : 'RJ', hasAny: _.find($scope.items, {emit_uf : 'RJ'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'RO'}) != undefined ? 'success' : '', name : 'RO', hasAny: _.find($scope.items, {emit_uf : 'RO'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'RR'}) != undefined ? 'success' : '', name : 'RR', hasAny: _.find($scope.items, {emit_uf : 'RR'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'SC'}) != undefined ? 'success' : '', name : 'SC', hasAny: _.find($scope.items, {emit_uf : 'SC'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'SP'}) != undefined ? 'success' : '', name : 'SP', hasAny: _.find($scope.items, {emit_uf : 'SP'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'SE'}) != undefined ? 'success' : '', name : 'SE', hasAny: _.find($scope.items, {emit_uf : 'SE'}) != undefined },
            { class: _.find($scope.items, {emit_uf : 'TO'}) != undefined ? 'success' : '', name : 'TO', hasAny: _.find($scope.items, {emit_uf : 'TO'}) != undefined }
        ];
    }

    $scope.confirm = function() {
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : 'make_dar.modal.html',
            size            : 'lg',
            scope           : $scope,
            backdrop        : 'static',
            controller      : ['$scope', '$uibModalInstance', '$invoice', '$http', '$stateParams', '$client', '_',
            function($scope, $uibModalInstance, $invoice, $http, $stateParams, $client, _) {
                $scope.title = 'Gerar DAR';
                $scope.filter = angular.copy($scope.$parent.filter);
                $scope.dar = {
                    tax_type : $scope.filter.tax_type,
                    total : $scope.$parent.total
                };

                $scope.parenteItems = angular.copy($scope.items);
                $scope.invoiceItems = angular.copy(_.uniq($scope.parenteItems, function(item){return item.nNF;}));
                _.each($scope.invoiceItems, function(item){
                    item.tax = 0;
                    _.each($scope.parenteItems, function(row){
                        if( row.nNF == item.nNF)
                            item.tax = item.tax + parseFloat(row.tax);
                    });
                });

                $client.get({id : $stateParams.id}).$promise.then(function(response){
                    $scope.dar.cnpj = response.cpf_cnpj;
                });

                $scope.cancel = function() {
                    var modalInstance = $uibModal.open({
                        animation       : true,
                        templateUrl     : 'confirm_items.modal.html',
                        size            : 'sm',
                        scope           : $scope,
                        backdrop        : 'static',
                        controller      : ['$scope', '$uibModalInstance', '$invoice_import', '$http', '$stateParams', '$client', '_', 'ngToast',
                        function($scope, $uibModalInstance, $invoice_import, $http, $stateParams, $client, _, ngToast) {

                            $scope.title = 'Confirmar';
                            $scope.success_text = 'Confirmo!';
                            $scope.cancel_text = 'Cancelar';

                            $scope.confirm = function() {
                                var data = {
                                    description : $scope.$parent.dar.description,
                                    tax_type    : $scope.$parent.dar.tax_type,
                                    total       : $scope.$parent.dar.total,
                                    items       : $scope.$parent.parenteItems
                                }

                                $invoice_import.confirmItems(data).then(function(response){
                                    ngToast.success({
                                        content: 'Guia confirmada',
                                        dismissOnTimeout: true,
                                        timeout: 5000
                                    });
                                    $uibModalInstance.close();
                                }, function(error){
                                    ngToast.danger({
                                        content: 'Erro ao confirmar geração',
                                        dismissOnTimeout: true,
                                        timeout: 5000
                                    });
                                });
                            }

                            $scope.cancel = function() {
                                $uibModalInstance.close();
                            };
                        }]
                    });
                    modalInstance.result.then(function() {
                        $uibModalInstance.close();
                    });
                };
            }]
        });
        modalInstance.result.then(function() {
            $uibModalInstance.close();
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

angular.module('invoice').
controller('invoice.create', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$invoice', '$stateParams', '$countryState', '$city', '$uibModal', '$state', '$rootScope', '$suggestion',
function($scope, $http, $auth, $q, NgTableParams, $invoice, $stateParams , $countryState, $city, $uibModal, $state, $rootScope, $suggestion) {

    $scope.cities = [];
    $scope.states = [];
    $scope.selected_service = [];
    $scope.invoice = {
        mod : "55",
        dhEmi : moment().toDate(),
        dest : {},
        items: []
    };
    $scope.address = {
        zip_code : '',
        state_id : {},
        city_id : {}
    };
    $scope.dateInput = {
        opened: false
    };

    $scope.pags = [
        {
            id: '01',
            description: 'Dinheiro'
        },
        {
            id: '02',
            description: 'Cheque'
        },
        {
            id: '03',
            description: 'Cartão de Crédito'
        },
        {
            id: '04',
            description: 'Cartão de Débito'
        },
        {
            id: '15',
            description: 'Boleto Bancário'
        },
        {
            id: '99',
            description: 'Outros'
        }
    ];

    $scope.searchCep = function() {
        if($scope.address.zip_code != undefined && $scope.address.zip_code.length == 8){
            $http.get(api+'client/getCEP/'+$scope.address.zip_code).then(function(response){
                $scope.address.line1              = response.data.logradouro;
                $scope.address.neighborhood       = response.data.bairro;

                // Escolher UF
                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                    $scope.states = [];
                    $scope.states.push({
                               id: response.id,
                               name: response.name
                           });
                    $scope.address.state_id = response;
                });

                // Escolher Cidade
                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                    $scope.cities = [];
                    $scope.cities.push({
                        id: response.data[0].id,
                        name: response.data[0].name
                    });
                    $scope.address.city_id = $scope.cities[0];
                });

            });
        }
    }

    $scope.removeService = function(item) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http',
            function($scope, $uibModalInstance, $invoice, Upload, $http) {

                $scope.title = 'Deseja remover este item da nota?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {

                    $invoice.removeService(item.id).then(function(response){
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addService = function(find = null, qtd = null, origin = 'invoice'){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve        : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                }
            }
        });

        modalInstance.result.then(function(data) {
            $scope.invoice.items.push({
                xProd : data.name,
                qCom : data.quantity
            });
        });
    }

    $scope.save = function() {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http',
            function($scope, $uibModalInstance, $invoice, Upload, $http) {

                $scope.title = 'Deseja atualizar as configurações da nota?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var invoice             = angular.copy($scope.$parent.invoice);
                var address             = angular.copy($scope.$parent.address);
                invoice.dest.address            = address;
                invoice.dest.address.city_id    = invoice.dest.address.city_id.id;
                invoice.dest.address.state_id   = invoice.dest.address.state_id.id;


                $scope.confirm = function() {

                    $invoice.update({id: invoice.id}, invoice).$promise.then(function(response){
                        $scope.toggleEdit();
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printInvoice = function(id)
    {
        $invoice.print(id).then(function(response){
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            // Create a link element on body
            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        });
    }


}]);

angular.module('invoice').
controller('invoice.show', ['$localStorage', '$scope', '$http', '$auth', '$q', 'NgTableParams', '$invoice', '$stateParams', '$countryState', '$city', '$uibModal', '$state', '$rootScope', '$uibModalInstance', 'invoice_id', 'ngToast', 'showInModal', 'origin', '$suggestion',
function($localStorage, $scope, $http, $auth, $q, NgTableParams, $invoice, $stateParams , $countryState, $city, $uibModal, $state, $rootScope, $uibModalInstance, invoice_id, ngToast, showInModal, origin, $suggestion) {

    $scope.crt = $localStorage.company ? $localStorage.company.crt : null;
    $scope.title_text           = 'Nota Fiscal';
    $scope.cancel_text          = 'Cancelar';
    $scope.success_text         = 'Ok';
    $scope.showInModal          = showInModal;
    $scope.natOp   = '';
    $scope.cities  = [];
    $scope.states  = [];
    $scope.invoice = {};
    $scope.address = {
        zip_code : '',
        state_id : {},
        city_id : {}
    };
    $scope.editing         = false;
    $scope.waiting         = false;
    $scope.isChangeded     = false;
    $scope.label           = '';

    $scope.origin          = origin ? origin : null;

    $scope.retentions_BC = false;
    $scope.hide_retentions_BC = function() {
        $scope.retentions_BC = !$scope.retentions_BC;
    }

    $scope.dateInput = {
        opened: false
    };

    $scope.pags = [
        {
            id: '01',
            description: 'Dinheiro'
        },
        {
            id: '02',
            description: 'Cheque'
        },
        {
            id: '03',
            description: 'Cartão de Crédito'
        },
        {
            id: '04',
            description: 'Cartão de Débito'
        },
        {
            id: '15',
            description: 'Boleto Bancário'
        },
        {
            id: '99',
            description: 'Outros'
        }
    ];

    $scope.searchCep = function() {
        if($scope.address.zip_code != undefined && $scope.address.zip_code.length == 8){
            $http.get(api+'client/getCEP/'+$scope.address.zip_code).then(function(response){
                $scope.address.line1              = response.data.logradouro;
                $scope.address.neighborhood       = response.data.bairro;

                // Escolher UF
                $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                    $scope.states = [];
                    $scope.states.push({
                               id: response.id,
                               name: response.name
                           });
                    $scope.address.state_id = response;
                });

                // Escolher Cidade
                $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                    $scope.cities = [];
                    $scope.cities.push({
                        id: response.data[0].id,
                        name: response.data[0].name
                    });
                    $scope.address.city_id = $scope.cities[0];
                });

            });
        }
    }

    $scope.descriptionSuggestionsList =[];

    $suggestion.query({type: 'invoice_natOp'}).$promise.then(function(response){
        $scope.descriptionSuggestionsList = response;
    });

    $scope.invoice.id = invoice_id;

    $scope.loadInvoice = function(){
        $invoice.get({id:$scope.invoice.id}).$promise.then(function(response){
            if( response.dest && response.dest.address.length > 0 ){
                response.dest.address[0].state_id = { id: response.dest.address[0].state_id, name: response.dest.address[0].state_id ? response.dest.address[0].state.name : '' };
                response.dest.address[0].city_id = { id: response.dest.address[0].city_id, name: response.dest.address[0].city ? response.dest.address[0].city.name : '' };
                $scope.cities.push({ id: response.dest.address[0].city_id, name: response.dest.address[0].city ? response.dest.address[0].city.name : '' });
                $scope.states.push({ id: response.dest.address[0].state_id, name: response.dest.address[0].state ? response.dest.address[0].state.name : ''});

                $scope.address = response.dest.address[0];

                if( !$scope.address.line1 ) {
                    $scope.searchCep();
                } else {
                    $scope.states = [];
                    $scope.states.push(response.dest.address[0].state);
                    $scope.address.state_id = response.dest.address[0].state;
                    $scope.cities = [];
                    $scope.cities.push(response.dest.address[0].city);
                    $scope.address.city_id = response.dest.address[0].city;
                }
            }else {
                $scope.address = [];
                $scope.cities = [];
                $scope.states = [];
                $scope.address.state_id = [];
                $scope.address.city_id = [];
            }
            response.dhEmi = moment(response.dhEmi).toDate();
            $scope.invoice = response;
            $scope.orignalInvoice = response;
            if ($scope.invoice.sale != null) {
                $scope.label = 'Venda';
            }
            if ($scope.invoice.financial.length > 0) {
                $scope.label = 'OS';
            }
            if ($scope.invoice.sale == null && $scope.invoice.financial.length == 0) {
                $scope.label = 'Nota avulsa';
            }
            if ($scope.invoice.natOp != null || $scope.invoice.natOp != ''){
                $scope.natOp = $scope.invoice.natOp;
            }
            if ($scope.natOp == null || $scope.natOp == ''){
                $scope.natOp = $localStorage.company.invoice_configs.natOp_default;   
            }
        });
    }
    $scope.loadInvoice();

    $scope.editingCfop = false;

    $scope.editCfopTrue = function(){
        $scope.editCfop = '';
        $scope.editingCfop = !$scope.editingCfop;
        $scope.editingCsosn = false;
    }

    $scope.editingCfopExterno = false;

    $scope.editCfopExternoTrue = function(){
        $scope.editCfopExterno = '';
        $scope.editingCfopExterno = !$scope.editingCfopExterno;
        $scope.editingCsosn = false;
    }

    $scope.confirmEditCfop = function(){
        $scope.waiting = true;
        if ($scope.editCfop) {
            var data = {
              items : []
            }
            _.each($scope.invoice.items, function(item){
                if (item.CFOP != $scope.editCfop) {
                    data.items.push({
                      id    : item.id,
                      CFOP  : $scope.editCfop
                  });
                }
            });
            $invoice.updateAllItems(data).then(function(response){
                $scope.waiting = false;
                ngToast.success({
                    content: 'CFOP atualizado com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.loadInvoice();
            }, function(error){
                $scope.waiting = false;
                ngToast.danger({
                    content: 'Erro ao salvar item',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        }
        if ($scope.editCfopExterno) {
            var data = {
              items : []
            }
            _.each($scope.invoice.items, function(item){
                if (item.cfop_external != $scope.editCfopExterno) {
                    data.items.push({
                      id             : item.id,
                      cfop_external  : $scope.editCfopExterno
                  });
                }
            });
            $invoice.updateAllItems(data).then(function(response){
                $scope.waiting = false;
                ngToast.success({
                    content: 'CFOP atualizado com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.loadInvoice();
            }, function(error){
                $scope.waiting = false;
                ngToast.danger({
                    content: 'Erro ao salvar item',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        }
        $scope.editingCfop = false;
        $scope.editingCfopExterno = false;
    }


    $scope.editingCsosn = false;

    $scope.editCsosnTrue = function(){
        $scope.editCsosn = '';
        $scope.editingCsosn = !$scope.editingCsosn;
        $scope.editingCfop = false;
    }

    $scope.confirmEditCsosn = function(){
        $scope.waiting = true;
        if ($scope.editCsosn) {
            var bError = false;
            var queue = [];
            _.each($scope.invoice.items, function(item){
                if (item.csosn != $scope.editCsosn) {
                    item.csosn = $scope.editCsosn;
                    queue.push($invoice.editItem(item, item.id).then(function(response){}, function(error){ bError = true; }));
                }
            });
            setTimeout(function(){
                $q.all( queue ).then(function(response){
                    $scope.waiting = false;
                    if( bError ) {
                        ngToast.danger({
                            content: 'Erro ao salvar item',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                    }
                    $scope.loadInvoice();
                });
            },300);
        }
        $scope.editingCsosn = false;
    }

    $scope.toggleIsChangeded = function() {
        $scope.isChangeded = true;
    }

    $scope.confirmChangeValueItem = function(){
        $scope.waiting = true;
        var data = {
            ids : []
        }
        _.each($scope.invoice.items, function(row){
            data.ids.push(row.id);
        })
        $invoice.changeValueItem(data).then(function(response){
            $scope.loadInvoice();
            $scope.waiting = false;
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao salvar',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.invoice = $scope.orignalInvoice;
        });
    }

    $scope.confirmUpdate = function() {
        if( $scope.isChangeded ){
            $scope.waiting = true;
            var invoice = $scope.invoice;
            var bError = false;
            $invoice.update({id: invoice.id}, invoice).$promise.then(function(response){
                $scope.loadInvoice();
                $scope.waiting = false;
                $scope.isChangeded = false;
            }, function(error){
                $scope.waiting = false;
                $scope.isChangeded = false;
                ngToast.danger({
                    content: 'Erro ao salvar',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.invoice = $scope.orignalInvoice;
            });
        }
    }

    $scope.sendInvoice = function(id) {
        if ($scope.invoice.mod == 55 && !$scope.invoice.dest) {
            ngToast.danger({
                content: 'Adicione um cliente para emitir NFe!',
                dismissOnTimeout: true,
                timeout: 6000
            });
            return;
        }
        if( $scope.invoice.mod == 55 && $scope.invoice.dest && $scope.invoice.dest.address.length == 0 ) {
            ngToast.danger({
                content: 'Preencha o endereço no cadastro do cliente!',
                dismissOnTimeout: true,
                timeout: 6000
            });
            return;
        }

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title = 'Tem certeza que deseja enviar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar';
                $scope.sending  = false;

                if ($scope.$parent.invoice.sale || $scope.$parent.origin == 'protocol') {
                    var params = {
                        movement : false,
                        protocol : true
                    }
                }else{
                    var params = {
                        movement : true
                    }
                }
 
                $scope.confirm = function() {
                    if( $scope.sending )
                        return;
                    $scope.sending = true;
                    $invoice.send(id, params).then(function(response){
                        $scope.sending = false;
                        ngToast.success({
                            content: 'Nota Enviada!',
                            dismissOnTimeout: true,
                            dismissButton   : true,
                            timeout: 5000
                        });
                        $scope.$parent.loadInvoice();
                        $uibModalInstance.close();
                    }, function(error){
                        $scope.sending = false;
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout    : false,
                            dismissButton       : true,
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $scope.sending = false;
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.printInvoice = function(id, mod = null) {
        if (mod == '0' ){
            $invoice.printNFSe(id).then(function(response){
                $rootScope.print(response); 
            });
        } else {
            $invoice.print(id).then(function(response){
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);

                // Create a link element on body
                var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
                // var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');

                angular.element(document.body).append(link);
                link[0].click();
                link.remove();
            });
        }
    }

    $scope.sendEmail = function(invoice) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm_email.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop: 'static',
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title_text = 'Enviar por email?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar';
                $scope.email = invoice.dest ? invoice.dest.email : '';

                $scope.confirm = function() {
                    $scope.$parent.waiting = true;
                    var data = {
                        email : $scope.email
                    };
                    $invoice.sendEmail(invoice.id, data).then(function(response){
                        $scope.$parent.waiting = false;
                        ngToast.success({
                            content: 'Email enviado',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        $scope.$parent.waiting = false;
                        ngToast.danger({
                            content: 'Erro ao enviar email',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.closeInvoiceModal = function() {
        $rootScope.invoice = null;
        $uibModalInstance.close();
    }

    $scope.ccInvoice = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'update_status.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title = 'Carta de correção (CCe)';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Enviar SEFAZ';
                $scope.bReason = true;
                $scope.protocol = {
                    reason : ''
                }

                $scope.confirm = function() {
                    var data = {
                        xCorrecao : $scope.protocol.reason
                    };

                    $invoice.cce(id, data).then(function(response){
                        ngToast.success({
                            content: 'Correção enviada',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.loadInvoice();
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.cancelInvoice = function(invoice) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: invoice.mod == '0' ? 'cancel_nfse.modal.html' : 'update_status.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title = 'Tem certeza que deseja cancelar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';
                $scope.bReason = true;
                $scope.sending  = false;
                $scope.protocol = {
                    reason : ''
                }

                $scope.confirm = function() {
                    if( $scope.sending )
                        return;
                    $scope.sending = true;
                    var data = {
                        xJust : $scope.protocol.reason
                    };

                    $invoice.cancel(invoice.id, data).then(function(response){
                        $scope.sending  = false;
                        ngToast.success({
                            content: 'Nota Cancelada!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $scope.$parent.loadInvoice();
                        $uibModalInstance.close();
                    }, function(error){
                        $scope.sending  = false;
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.devolutionInvoice = function(){
        var invoice  = $scope.invoice;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title = 'Deseja realmente gerar uma nota de devolução?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Gerar';

                $scope.confirm = function() {
                    var data = {
                        refNFe : invoice.infNFe
                    }
                    $invoice.storeDevolution(data).then(function(response){
                        $uibModalInstance.close();
                        var modalInstance = $uibModal.open({
                            animation      : true,
                            ariaLabelledBy : 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            templateUrl    : server+"/views/m/invoice/show",
                            controller     : 'invoice.show',
                            size           : 'lg',
                            resolve        : {
                                invoice_id     : response.id,
                                showInModal    : true
                            }
                        });
                    }, function(error){
                        $scope.sending = false;
                        ngToast.danger({
                            content: error.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $scope.sending = false;
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.duplicateInvoice = function(){
        var id  = $scope.invoice.id;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http', 'ngToast',
            function($scope, $uibModalInstance, $invoice, Upload, $http, ngToast) {

                $scope.title = 'Deseja realmente duplicar nota?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Duplicar';

                $scope.confirm = function() {
                    $invoice.duplicate(id).then(function(response){
                        ngToast.success({
                            content: 'Nota duplicada!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.close();
                        var modalInstance = $uibModal.open({
                            animation      : true,
                            ariaLabelledBy : 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            templateUrl    : server+"/views/m/invoice/show",
                            controller     : 'invoice.show',
                            size           : 'lg',
                            resolve        : {
                                invoice_id     : response.data.id,
                                showInModal    : true
                            }
                        });
                    }, function(error){
                        $scope.sending = false;
                        ngToast.danger({
                            content: error.data.message,
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $scope.sending = false;
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.downloadXml = function(id){
        $invoice.get({id: id}).$promise.then(function(response){
            $scope.xlm = response.xml;
            var blob = new Blob([$scope.xlm], { type: 'text/plain;charset=utf-8;' });
            var link= window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'nota_' + response.nNF +'.xml';
            link.click();
            window.URL.revokeObjectURL(link.href);
        }, function(error){
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.viewPayments = function() {
        var sale = $scope.invoice.sale;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'view_payments.modal.html',
            size: 'lg',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$q',
            function($scope, $uibModalInstance, $client, Upload, $q) {

                $scope.title = "Venda Nº: " + sale.number;
                $scope.sub_title = sale.seller_name ? "Vendedor: " + sale.seller_name : null;
                $scope.sub_title_2 = "Operador: " + sale.user_name;

                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'OK';
                $scope.server = server;

                $scope.sale = sale;
                if ($scope.sale.created_at) {
                    $scope.hour = $scope.sale.created_at.substr(11);
                }

                $scope.copy = function() {
                    var copyText = document.getElementById("linkSale");

                    copyText.select();
                    copyText.setSelectionRange(0, 99999);

                    document.execCommand("copy");
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    }

    $scope.searchClient = function(){
        var invoice = $scope.invoice;

        var modalInstanceClient = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/client",//'os.modal.html',
            size            : 'lg',
            controller      : 'client.list',
            backdrop        : 'static',
            resolve         : {
                showInModal : true
            }
        });
        modalInstanceClient.result.then(function(data) {
            var data ={
                dest_id : data.id
            }
            $invoice.update({id: invoice.id}, data).$promise.then(function(response){
                $scope.loadInvoice();
            }, function(error){
                $uibModalInstance.dismiss('cancel');
            });
        });
    }

    $scope.editClient = function(id){
        var invoice = $scope.invoice;
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/client/modal",//'os.modal.html',
            size            : 'lg',
            controller      : 'client.show',
            backdrop        : 'static',
            resolve         : {
                showInModal     : true,
                id              : id
            }
        });
        modalInstance.result.then(function(data) {
            var data ={
                dest_id : data.id
            }
            $invoice.update({id: invoice.id}, data).$promise.then(function(response){
                $scope.loadInvoice();
            }, function(error){
                $uibModalInstance.dismiss('cancel');
            });
        });
    }

    $scope.editItem = function(item){
        $scope.editingItem = item;
    }

    $scope.cancelItem = function(){
        $scope.editingItem = {};
    }

    $scope.showMoreFiscalFields = false;
    $scope.showMoreFields = function(){
        $scope.showMoreFiscalFields = !$scope.showMoreFiscalFields;
    }

    $scope.addItem = function(find = null, qtd = null, origin = 'invoice') {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'add_service.modal.html',
            controller     : 'add_service',
            controllerAs   : '$ctrl',
            size           : 'lg',
            resolve        : {
                find : function(){
                    return find;
                },
                qtd : function(){
                    return qtd ? qtd : 1;
                },
                origin : function(){
                    return origin;
                },
                type   : function(){
                    return $scope.invoice.tpNF;
                }
            }
        });

        modalInstance.result.then(function(data) {
            var data = {
                invoice_id : $scope.invoice.id,
                service_id : data.service,
                quantity : data.quantity,
                vUnCom : data.value,
                total : data.total
            }
            $invoice.addItem(data).then(function(response){
                $scope.loadInvoice();
            }, function(response) {
                console.log(response);
            });

        });
    }

    $scope.saveItem = function(){
        $invoice.editItem($scope.editingItem, $scope.editingItem.id).then(function(response){
            ngToast.success({
                content: 'Item salvo com sucesso',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.editingItem = {};
            $scope.loadInvoice();
        }, function(error){
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.loadInvoice();
            return;
        });
        $scope.showMoreFiscalFields = false;
    }

    $scope.removeService = function(item) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invoice', 'Upload', '$http',
            function($scope, $uibModalInstance, $invoice, Upload, $http) {

                $scope.title = 'Deseja remover este item da nota?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                $scope.confirm = function() {
                    $invoice.removeService(item.id).then(function(response){
                        $scope.$parent.loadInvoice();
                        $uibModalInstance.close();
                    }, function(error){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.showProtocol = function(number){
        var modalInstance = $uibModal.open({
            animation       : true,
            templateUrl     : server+"/views/m/protocol/show",//'os.modal.html',
            size            : 'lg',
            controller      : 'protocol.show',
            backdrop        : 'static',
            resolve         : {
                showInModal     : true,
                id              : function(){
                    return angular.copy(number);
                }
            }
        });
        modalInstance.result.then(function(data) {
            $scope.protocol = data;
        });
    }
}]);

angular.module('invoice').
factory("$invoice", function($resource, $q, $http) {
    var resource = $resource(api + 'invoice/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.removeService = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'invoice_item/'+id).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.editItem = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'invoice_item/'+id, data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateAllItems = function(data)
    {
        var deferred = $q.defer();
        $http.put(api+'invoice_item/update_all', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.changeValueItem = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice_item/change_value_item', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    resource.addItem = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice_item', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.send = function(id, data = {})
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/send', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.cce = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/cce', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.cancel = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/cancel', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.printNFSe = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'invoice/'+id+'/print_nfse').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.print = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/print',{},{responseType: 'arraybuffer'}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.sendEmail = function(id, data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/send_email', data).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.report = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/report', params, {responseType: 'arraybuffer'}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.export = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/export',data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.duplicate = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/'+id+'/duplicate').then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.storeLoose = function(params = {})
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/store_loose', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.storeDevolution = function(params = {})
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/store_devolution', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    // resource.consultMod = function(params)
    // {
    //     var deferred = $q.defer();
    //     $http.get(api+'invoice/consult_mod', {params: params}).then(function(response){
    //         deferred.resolve(response);
    //     }, function(response) {
    //         deferred.reject(response);
    //     });
    //     return deferred.promise;

    // }

    resource.disenable = function(params)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice/disenable', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response.data);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('invoice').
factory("$invoice_import", function($resource, $q, $http) {
    var resource = $resource(api + 'invoice_import/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.search = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice_import/search', {client_id: id}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkKeys = function(params)
    {
        var deferred = $q.defer();
        $http.get(api+'invoice_import/check_keys', {params: params}).then(function(response){
            deferred.resolve(response);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getItems = function(id, data)
    {
        data.client_id = id;
        var deferred = $q.defer();
        $http.get(api+'invoice_import_item', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getUnconfirmedItems = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'invoice_import_item/get_unconfirmeds', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.confirmItems = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'invoice_import_item/confirm', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.calculeTaxes = function(id, data)
    {
        data.client_id = id;
        var deferred = $q.defer();
        $http.get(api+'invoice_import_item/calcule_taxes', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
