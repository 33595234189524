angular.module('movement', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('movement').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('movement', {
        url         : "/movement",
        templateUrl : server+"/views/m/movement",
        controller  : 'movement.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Produtos & Serviços'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('movement').
controller('movement.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$movement',
function($scope, $http, $auth, $q, NgTableParams, $movement) {
    
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 20
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $movement.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);


angular.module('movement').
controller('movement.create', ['$state', '$scope', '$stateParams', '$uibModal', '$service', '$department', '$localStorage', 'service_id', '$uibModalInstance', '$templateCache', '$movement', '$suggestion', 'ngToast',
function($state, $scope, $stateParams, $uibModal, $service, $department, $localStorage, service_id, $uibModalInstance, $templateCache, $movement, $suggestion, ngToast) {

    $scope.title        = 'Criar movimentação';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Salvar';
    $scope.type         = 'credit';
    $scope.description  = '';
    $scope.company      = $localStorage.company;
    $scope.decimals     = $scope.company.decimals_param;
    $scope.sending  = false;
    
    $scope.newMovement = {
        quantity    : 0,
        service_id  : service_id
    }

    $suggestion.query({type: 'movement_stock'}).$promise.then(function(response){
        $scope.descriptionSuggestionsList = response;
    });

    $scope.selectedDescription = function(selected){
        if (selected) {
            if( selected.originalObject.custom ){
                var data = {
                    description   : selected.title,
                    type          : 'movement_stock'
                };
                $suggestion.post(data).$promise.then(function(response){
                    $scope.descriptionSuggestionsList.push(response);
                    $scope.description = response.description;
                    ngToast.success({
                        content: 'Cadastrado com sucesso!',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                }, function(error){
                    ngToast.danger({
                        content: 'Tipo de movimento já cadastrado.',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.description = selected.title;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputMovementStock');
                }
            }
        }
    }

    $service.get({id : (service_id), company_id: $scope.company.id}).$promise.then(function(data) { 
        $scope.service = data;
    });

    $scope.confirm = function()
    {
        var array = [];
        $scope.sending  = true;
        array.push($scope.newMovement);
        if( $scope.type == 'debit' && $scope.service.stock_control && parseFloat($scope.service.real_stock) < parseFloat($scope.newMovement.quantity)){
            ngToast.danger({
                content             : 'Estoque insuficiente!',
                dismissOnTimeout    : true,
                timeout             : 3000
            });
            return false;
        }
        $movement.save({array: array, type: $scope.type, description: $scope.description}).$promise.then(function(response){
            $scope.sending  = false;
            $uibModalInstance.close();
        });
    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('movement').
controller('movement_many.create', ['$state', '$scope', '$stateParams', '$uibModal', '$service', '$department', '$localStorage', 'service_id', '$uibModalInstance', '$templateCache', '$movement', '$suggestion', 'ngToast', '$serviceGroup',
function($state, $scope, $stateParams, $uibModal, $service, $department, $localStorage, service_id, $uibModalInstance, $templateCache, $movement, $suggestion, ngToast, $serviceGroup) {

    $scope.title        = 'Criar movimentação do grupo';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Salvar';
    $scope.type         = 'credit';
    $scope.description  = '';
    $scope.company      = $localStorage.company;
    $scope.decimals     = $scope.company.decimals_param;
    $scope.sending  = false;
    
    $scope.newMovement = {
        quantity    : 0
    }

    $suggestion.query({type: 'movement_stock'}).$promise.then(function(response){
        $scope.descriptionSuggestionsList = response;
    });

    $scope.selectedDescription = function(selected){
        if (selected) {
            if( selected.originalObject.custom ){
                var data = {
                    description   : selected.title,
                    type          : 'movement_stock'
                };
                $suggestion.post(data).$promise.then(function(response){
                    $scope.descriptionSuggestionsList.push(response);
                    $scope.description = response.description;
                    ngToast.success({
                        content: 'Cadastrado com sucesso!',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                }, function(error){
                    ngToast.danger({
                        content: 'Tipo de movimento já cadastrado.',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                });
            } else {
                if( selected.originalObject.id ){
                    $scope.description = selected.title;
                } else {
                    $scope.$broadcast('angucomplete-alt:clearInput', 'inputMovementStock');
                }
            }
        }
    }

    $scope.loadGroup = function(){
        $serviceGroup.query({id: service_id}).$promise.then(function(response){
            $scope.group    = response;
            $scope.group.services = _.sortBy(response.services, 'name');
            $scope.services = $scope.group.services;
        });
    }
    $scope.loadGroup();

    $scope.confirm = function(){
        if ($scope.services.length > 0) {
            $scope.sending  = true;
            var array = [];
            _.each($scope.services, function(row){
                console.log(row);
                if( $scope.type == 'debit' && row.stock_control && parseFloat(row.real_stock) < parseFloat($scope.newMovement.quantity)){
                    ngToast.danger({
                        content             : 'Estoque insuficiente!',
                        dismissOnTimeout    : true,
                        timeout             : 3000
                    });
                    return false;
                }

                $scope.movement = {
                    quantity   : $scope.newMovement.quantity,
                    service_id : row.id
                } 
                array.push($scope.movement)
                

            })
            $movement.save({array: array, type: $scope.type, description: $scope.description}).$promise.then(function(response){
                $scope.sending  = false;
                $uibModalInstance.close();
            });
        }
    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

}]);


angular.module('movement').
factory("$movement", function($resource, $q, $http, $filter) {

    var resource = $resource(api + 'movement/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.print = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'movement/'+id+'/print').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});
